import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { DataService } from 'src/app/services/data.service';

@Component({
    selector: 'app-providers-list',
    templateUrl: './providers-list.component.html',
    styleUrls: ['./providers-list.component.scss']
})
export class ProvidersListComponent implements OnInit {
    providersList: any = [];
    serviceName: string | null | undefined;
    catId: number | undefined;
    pageNo = 1;
    pop = {
        value: false
    };
    tempProvidersList: any;
    providerTitle:any;
    defaultSortBy= 'distance';
    searchKeyword = '';
    selectedTab = 'both';
    
    
    constructor(private activatedRoute: ActivatedRoute,
        public dataService: DataService) {
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params: any) => {
            this.catId = params.category;
            this.getProvidersList();
        });
        
    }

    getProvidersList = () => {
        const reqObj = { catId: this.catId, pageId: this.pageNo, service_keyword: this.searchKeyword, sort_by: this.defaultSortBy, service_location: this.selectedTab }
        this.dataService.getProviderCategoriesById(reqObj).subscribe((data: any) => {
            if (data) {
                this.tempProvidersList =[];
                this.tempProvidersList = data.results;
                for (let i = 0; i < this.tempProvidersList.length; i++) {
                    this.providersList.push(this.tempProvidersList[i]);
                    this.providerTitle = this.tempProvidersList[i].business_category_title;
                }
            }
        });
    }


    onScrollDown(ev: any) {
        this.pageNo += 1;
        this.getProvidersList();
        console.log("scrolled down!!", ev);

    }

    onScrollUp(ev: any) {
        console.log("scrolled up!", ev);
        this.pageNo += 1;
    }

    ChangeSortByFn = (e: any) => {
        this.defaultSortBy = e;
        this.providersList =[];
        this.pageNo = 1;
        this.getProvidersList();
    }

    onSearch = () => {
        this.providersList =[];
        this.pageNo = 1;
        this.getProvidersList();
    }

    onSelectTabs = (e: any) =>{
        this.selectedTab= e;
        this.providersList =[];
        this.pageNo = 1;
        this.getProvidersList();
    }

}