<div class="container tutor-qualification mt-5">
    <p-tabView>
        <p-tabPanel header="Education Details" [selected]="true">
            <p class="tab-head">Please Enter  Education Details </p>
            <table class="table table-bordered">
                <thead>
                    <tr>

                        <th scope="col">Degree</th>
                        <th scope="col">Specialization</th>
                        <th scope="col">Star year</th>
                        <th scope="col">End Year</th>
                        <th scope="col">Grade</th>
                        <th scope="col">Status</th>
                        <th>Add Education</th>
                    </tr>

                </thead>
                <tbody>
         
                    <ng-container *ngFor="let row of rows; let i=index">
                        <tr>
                            <td data-label="Degree"><input type="text" placeholder="Ex:B-tech" class="form-control" [(ngModel)]="row.col1">
                            </td>
                            <td data-label="Specialization"><input type="text" placeholder="Ex:B-tech" class="form-control" [(ngModel)]="row.col2">
                            </td>
                            <td data-label="Star year"><input type="date" class="form-control" [(ngModel)]="row.col3"></td>
                            <td data-label="End Year"><input type="date" class="form-control" [(ngModel)]="row.col4"></td>

                            <!-- <td><input type="date" [(ngModel)]="row.col5"></td> -->

                            <td data-label="Grade">
                                <ng-select [items]="grades" bindLabel="name" [multple]="false" [(ngModel)]="row.col3"
                                [placeholder]="'Select an option'"> </ng-select>
                            </td>

                            <td data-label="Status"> <ngx-dropzone [config]="config" (change)="onSelect($event)" class="upload-wrap"
                                    [(ngModel)]="row.col4">
                                    <div class="dz-message" ngxDropzoneMessage>
                                        <img src="assets/icons/upload.svg" alt="">
                                    </div>
                                    <div *ngIf="selectedFile">
                                        <p>{{ selectedFile.name }}</p>
                                    </div>
                                </ngx-dropzone></td>
                                <td>
                            <ng-container *ngIf="i === 0">
                                <a (click)="addRow()">Add More</a>
                            </ng-container>
                            <ng-container *ngIf="i > 0">
                                <a (click)="removeRow(i)">Remove</a>
                            </ng-container>
                        </td>
                            <!-- <button (click)="addRow()">Add Row</button>
                      <td><button (click)="removeRow(i)">Remove</button></td> -->
                        </tr>
                    </ng-container>
                    <!-------------------------->



                </tbody>
            </table>
            <button class="save-btn" type="submit">Save</button>
        </p-tabPanel>
        <p-tabPanel header="Skill Set">
            <p class="tab-head">Please add your skills </p>
         

            <div class="force-to-the-bottom">
                <tag-input [ngModel]="['hardocoded-item']">
                    <tag-input-dropdown [autocompleteItems]="items" [showDropdownIfEmpty]="true"
                        [dynamicUpdate]="false">
                    </tag-input-dropdown>
                </tag-input>
            </div>


            <button class="save-btn mt-5" type="submit">Save</button>
        </p-tabPanel>
        <p-tabPanel header=" Certification">
            <p class="tab-head">Please Enter additional Certification </p>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Title</th>
                        <th scope="col">year</th>
                        <th scope="col">Upload</th>
                    </tr>
                    <!---------->

                </thead>
                <tbody>
                    <ng-container *ngFor="let row of rows; let i=index">
                    <tr>
                        <td data-label="Title"><input type="text" placeholder="Ex:Java" class="form-control" [(ngModel)]="row.col1"></td>

                        <td data-label="year"><input type="date" class="form-control" [(ngModel)]="row.col2"></td>
                        <td data-label="Upload">
                            <ngx-dropzone [config]="config" [(ngModel)]="row.col3" (change)="onSelect($event)" class="upload-wrap">
                                <div class="dz-message" ngxDropzoneMessage>
                                    <img src="assets/icons/upload.svg" alt="">
                                </div>
                                <div *ngIf="selectedFile">
                                    <p>{{ selectedFile.name }}</p>
                                </div>
                            </ngx-dropzone>
                        </td>
                        <td>
                            <ng-container *ngIf="i === 0">
                                <a (click)="addRow()">Add More</a>
                            </ng-container>
                            <ng-container *ngIf="i > 0">
                                <a (click)="removeRow(i)">Remove</a>
                            </ng-container>
                        </td>
                    </tr>
                    <!-------------------------->
               

                    <!------------------------------->
                    </ng-container>

                </tbody>
            </table>
            <button class="save-btn mt-5" type="submit">Save</button>
        </p-tabPanel>
        <p-tabPanel header="Professional Experience">
            <p class="tab-head">Please Enter your professonal Experience </p>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Organization Name</th>
                        <th scope="col">Start year</th>
                        <th scope="col">End year</th>
                        <th scope="col">Designation</th>
                        <th scope="col">Upload</th>
                    </tr>
                    <!---------->

                </thead>
                <tbody>
                    <ng-container *ngFor="let row of rows; let i=index">
                    <tr>
                        <td data-label="Organization Name"><input type="text" placeholder="Ex :Accenflair" class="form-control"[(ngModel)]="row.col1"></td>

                        <td data-label="Start year"> <input type="date" class="form-control" [(ngModel)]="row.col2"></td>

                        <td  data-label="End year"><input type="date" class="form-control"></td>
                        <td data-label="Designation"><input type="text" placeholder="Ex :Professor" [(ngModel)]="row.col3"class="form-control"></td>
                        <td data-label="Upload">
                            <ngx-dropzone [config]="config" (change)="onSelect($event)"[(ngModel)]="row.col4" class="upload-wrap">
                                <div class="dz-message" ngxDropzoneMessage>
                                    <img src="assets/icons/upload.svg" alt="">
                                </div>
                                <div *ngIf="selectedFile">
                                    <p>{{ selectedFile.name }}</p>
                                </div>
                            </ngx-dropzone>
                        </td>
                        <td>
                            <ng-container *ngIf="i === 0">
                                <a (click)="addRow()">Add More</a>
                            </ng-container>
                            <ng-container *ngIf="i > 0">
                                <a (click)="removeRow(i)">Remove</a>
                            </ng-container>
                        </td>
                    </tr>
                    <!-------------------------->
                    </ng-container>


                </tbody>
            </table>
            <button class="save-btn" type="submit">Save</button>
        </p-tabPanel>
    </p-tabView>
</div>







<!-- table.component.html -->
<table class="d-none">
    <thead>
        <tr>
            <th scope="col">Degree</th>
            <th scope="col">Specialization</th>
            <th scope="col">Star year</th>
            <th scope="col">End Year</th>
            <th scope="col">Grade</th>
            <th scope="col">Status</th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let row of rows; let i=index">
            <tr>
                <td><input type="text" placeholder="Ex:B-tech" class="form-control" [(ngModel)]="row.col1"></td>
                <td><input type="text" placeholder="Ex:B-tech" class="form-control" [(ngModel)]="row.col2"></td>
                <td><input type="date" class="form-control" [(ngModel)]="row.col3"></td>
                <td><input type="date" class="form-control" [(ngModel)]="row.col4"></td>

                <!-- <td><input type="date" [(ngModel)]="row.col5"></td> -->

                <td>
                    <ng-select [items]="grades" bindLabel="name" [multple]="false" [(ngModel)]="row.col3"
                        [placeholder]="'select grade'"> </ng-select>
                </td>

                <td> <ngx-dropzone [config]="config" (change)="onSelect($event)" class="upload-wrap"
                        [(ngModel)]="row.col4">
                        <div class="dz-message" ngxDropzoneMessage>
                            <img src="assets/icons/upload.svg" alt="">
                        </div>
                        <div *ngIf="selectedFile">
                            <p>{{ selectedFile.name }}</p>
                        </div>
                    </ngx-dropzone></td>
                <ng-container *ngIf="i === 0">
                    <button (click)="addRow()">Add Row</button>
                </ng-container>
                <ng-container *ngIf="i > 0">
                    <button (click)="removeRow(i)">Remove</button>
                </ng-container>
                <!-- <button (click)="addRow()">Add Row</button>
    <td><button (click)="removeRow(i)">Remove</button></td> -->
            </tr>
        </ng-container>
    </tbody>
</table>