<div class="login-container-page row" *ngIf="!showSuccessPage">
  <div class="login-form-section col-lg-6 col-md-6 col-sm-6 col-6">
    <!-- Sign Up Form -->
    <ng-container *ngIf="true">
      <div class="welcome-heading">Welcome to {{productName}}!</div>
      <div class="heading-text">Create an account</div>
      <div class="desc-text">Please enter your details</div>
      <form [formGroup]="signUpForm" (ngSubmit)="onSubmit($event)">
        <div class="input-block">
          <div class="label-text">NAME<sup>*</sup></div>
          <input type="text" placeholder="Please enter NAME" class="input-form" formControlName="name">
          <div class="valid-check">
            <i class="bi bi-check-circle-fill success" *ngIf="nameControls.valid  && formSubmited"></i>
            <i class="bi bi-exclamation-circle-fill error" *ngIf="nameControls.invalid  && formSubmited"></i>
          </div>
          <span class="error-text" *ngIf="nameControls.invalid && formSubmited">Name Required</span>
        </div>
        <div class="input-block">
          <div class="label-text">EMAIL<sup>*</sup></div>
          <input type="email" placeholder="Please enter your your email" class="input-form" formControlName="email">
          <div class="valid-check">
            <i class="bi bi-check-circle-fill success" *ngIf="emailControls.valid  && formSubmited"></i>
            <i class="bi bi-exclamation-circle-fill error" *ngIf="emailControls.invalid  && formSubmited"></i>
          </div>
          <span class="error-text" *ngIf="emailControls.invalid  && formSubmited">Email Required</span>
        </div>
        <div class="input-block">
          <div class="label-text">PHONE NUMBER<sup>*</sup></div>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">{{countryCode}}</div>
            </div>
            <input type="text" placeholder="Please enter your phone number" class="input-form prepend-data"
              formControlName="mobile">
          </div>
          <div class="valid-check">
            <i class="bi bi-check-circle-fill success" *ngIf="phoneNumControls.valid  && formSubmited"></i>
            <i class="bi bi-exclamation-circle-fill error" *ngIf="phoneNumControls.invalid  && formSubmited"></i>
          </div>
          <!-- <span class="error-text" *ngIf="phoneNumControls.invalid && formSubmited">Phone Number required</span> -->
          <span class="error-text" *ngIf="formSubmited && phoneNumControls.mobile.errors?.required">Phone required</span>
          <span class="error-text" *ngIf="formSubmited && phoneNumControls.mobile.errors?.pattern || phoneNumControls.mobile.errors?.maxlength">Phone Number requires minimum 10 Characters</span>
        </div>
        <div class="remebaer-block justify-content-start">
          <label class="check-label-mark">
            <div class="pin-label">I agree to</div>
            <input type="checkbox" formControlName="agree"> 
            <span class="checkmark"></span>
          </label>
          <div class="link-text" [routerLink]="'/terms-conditions'">terms & conditions.</div>
        </div>
        <span class="errorMsg" *ngIf="agreeControls.invalid && formSubmited"  [routerLink]="'/terms-conditions'">Terms & conditions required</span>
        <span class="errorMsg" *ngIf="errMsg">{{errMsg}}</span>
        <button class="sb-primary-btn w-100" type="submit">Create my free account!</button>
        <div class="or-divider">
          <span>OR</span>
        </div>
        <button class="sign-in-with-google mb-3 w-100" type="button" (click)="signInWithGoogle()"><img
            src="assets/icons/login-signup/google.png">Sign in with Google</button>
        <button class="sign-in-with-google w-100 mb-3" type="button" (click)="signInWithFB()"><img
            src="assets/icons/login-signup/fb.png">Sign in with Facebook</button>
        <div class="dont-have-acc">
          <span class="normal-text">Already have an account?</span>
          <a class="link-text" [routerLink]="['../sign-in']">Sign In</a>
        </div>
      </form>
    </ng-container>
  </div>
  <div class="info-block-with-img col-lg-6 col-md-6 col-sm-6 col-6">
    <div class="img-block-wrapper">
      <img src="assets/icons/login-signup/register-bg.png">
      <div class="img-block-wrapper">
        <img src="assets/icons/login-signup/login-bg.png">
        <!-- <div class="content-text-data">
          <div class="title">Want to register your business?</div>
          <button class="sb-primary-btn text-white" [routerLink]="['/register-your-business']">Click Here</button>
          <button class="sb-primary-btn">Sign In Here</button>
        </div> -->
      </div>
    </div>
  </div>
</div> 

<div *ngIf="showSuccessPage">
  <app-thank-you [fromState]="'register'"></app-thank-you>
</div>