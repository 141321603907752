import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteConstants } from "../../model/route-constants";

declare var jQuery: any;
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})

export class BreadcrumbComponent implements OnInit {
  routes: any[] = [
    {
      title: "Home",
      path: RouteConstants.HOME_PAGE
    }];
  id: any;
  title: string = '';
  category = '';
  constructor(private router: Router,    
    private activatedRoute: ActivatedRoute) {
  }
  ngOnInit(): void {
    let category:any=sessionStorage.getItem('SELECTED_BUSSINESS_CATEGORY');
    if(category)
    {
      let jsonCat = JSON.parse(category);
      let catTitle = jsonCat.slug_title.replace(/-/g, " ");
        
      this.routes.push(
        {
          title: catTitle,
          path: `${RouteConstants.PROVIDERS_LIST}/${jsonCat.slug_title}`, 
          qParams:[]        
        });
    }   

    this.activatedRoute.queryParams.subscribe((params: any) => {
      
      if (params.title) {
        this.title = params.title.replace(/-/g, " ");

        this.routes.push(
          {
            title: this.title,
            path: RouteConstants.PROVIDER_DETAILS,
            qParams: params
          });

      }
     
    });
  }
  gotoPath(route: any) {
    jQuery('body, html').animate({ scrollTop: 0 }, 100)
    this.router.navigate([route.path], {
      queryParams: route.qParams
    });
  }

}