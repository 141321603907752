import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ratings-and-reviews',
  templateUrl: './ratings-and-reviews.component.html',
  styleUrls: ['./ratings-and-reviews.component.scss']
})
export class RatingsAndReviewsComponent implements OnInit {
  writingReview: boolean | undefined;
  reviewSuccess: boolean | undefined;
  reviewForm: any;
  catId: any;
  reqId: any;
  providersDetails: any;
  formSubmited = false;
  reviewsList: any = [];
  providerId: any;
  errMsg = '';
  branch_slug_title: any;
  cutomerId: any;
  branchId: any;
  fromState: any;
  reviewId:any;
  userDetails: any;
  constructor(public dataService: DataService, private activatedRoute: ActivatedRoute, private storageService: StorageService) {
  }

  ngOnInit(): void {
    this.cutomerId = JSON.parse(<string>this.storageService.getItem('user_info')).customer_id
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.writingReview = params.wrt === "true" ? true : false;
      this.reqId = params.reqId;
      this.branch_slug_title = params.branch_slug_title;
      this.providerId = params.providerId;
      this.branchId = params.branchId
    });
    this.reviewForm = new FormGroup({
      review: new FormControl('', [Validators.required]),
      rating: new FormControl('', [Validators.required]),
    })
    this.getProvidersDetails();
    this.userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));

  }

  get reviewControls() { return this.reviewForm.get('review'); }
  get ratingControls() { return this.reviewForm.get('rating'); }


  getProvidersDetails = () => {
    const req = {
      providerId: this.providerId,
      branch_slug_title: this.branch_slug_title
    }
    this.dataService.getServiceCategoryDetails(req).subscribe((data: any) => {
      if (data) {
        this.providersDetails = data;
        this.getReviewsList();
      }
    })
  }

  saveFeedback = (event: any) => {
    this.errMsg = '';
    event.preventDefault();
    this.formSubmited = true;
    if (this.reviewForm.valid) {
      const req = {
        service_request_id: this.reqId,
        rating: this.reviewForm.value.rating,
        review: this.reviewForm.value.review
      }
      if (this.fromState === 'edit') {
        this.editFeedback();
      } else {
        this.dataService.createReview(req).subscribe((res: any) => {
          if (res && res?.error === undefined) {
            this.reviewSuccess = true;
            this.writingReview = false;
            this.getReviewsList();
          } else {
            this.errMsg = res?.error?.Error;
          }
        });
      }
    }
  }

  getReviewsList = () => {
    this.dataService.getReviewsList(this.branchId).subscribe((data: any) => {
      if (data) {
        this.reviewsList = data.results;
        console.log("review list:", this.reviewsList)
      }
    })
  }

  editFeedback = () => {
    this.errMsg = '';
    if (this.reviewForm.valid) {
      const req = {
        service_request_id: this.reqId,
        rating: this.reviewForm.value.rating,
        review: this.reviewForm.value.review,
        review_id: this.reviewId
      }
      this.dataService.updateReview(req).subscribe((res: any) => {
        if (res && res?.error === undefined) {
          this.reviewSuccess = true;
          this.writingReview = false;
          this.getReviewsList();
        } else {
          this.errMsg = res?.error?.Error;
        }
      });
    }
  }

  deleteFeedback = (data: any) => {
    const req = {
      review_id: data.branch_review_id
    }
    this.dataService.deleteReview(req).subscribe((res: any) => {
      if (res && res?.error === undefined) {
        Swal.fire({
          text: 'Review deleted Successfully',
          icon: 'success',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((res: any) => {
          if (res.isConfirmed) {
            this.getReviewsList();
            this.reviewForm.reset();
            this.formSubmited = false;
            this.reviewSuccess = false;
            this.writingReview = true;
          }
        })

      } else {
        this.errMsg = res?.error?.Error;
      }
    });
  }

  onEdit = (data: any) => {
    if (data) {
      this.reviewSuccess = false;
      this.writingReview = true;
      this.reviewForm.setValue({
        rating: data.rating,
        review: data.review
      });
      this.fromState = 'edit';
      this.reviewId= data.branch_review_id;
    }
  }
}
