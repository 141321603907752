<div class="bg-color-of-pages">
  <app-breadcrumb></app-breadcrumb>
  <div class="container">
    <div class="available-container">
      <div class="heading-page">Schedule your booking</div>
      <div class="available-list-data-parent">
        <div *ngFor="let cObj of cartItems;let i= index;">
        <div class="availble-list-card">
          <!--  *ngFor="let pObj of cObj.numbers;" -->
          <div class="left-sec">
            <div class="title">{{cObj?.service?.title}}</div>
            <div class="sub-text">Place of service : {{cObj?.address?.address_type === null ? 'Center' :
              cObj?.address?.address_type}}
              <!-- <span class="change-link" data-toggle="modal" data-target="#serviceModal" (click)="openPopup(cObj)">Change</span> -->
            </div>
            <!-- <div class="sub-text">Scheduled on : {{cObj?.booking_date  | date :'shortDate'}}</div> -->
            <!-- <div class="sub-text">Scheduled on : {{cObj?.booking_date | date :'shortDate'}}</div> By Mohitha -->
            <div class="prefered-optional">
              <div class="text_info">Preferred Staff (Optional):</div>
              <div class="sb-dropdonws" ngbDropdown placement="bottom" (click)="getStaffList(cObj)">
                <button class="dropdown-input" ngbDropdownToggle>{{cObj.service.lead_staff == undefined ? 'Select' :
                  cObj.service.lead_staff}}<i class="bi bi-chevron-down"></i></button>
                <ul class="dropdown-menu" ngbDropdownMenu>
                  <li ngbDropdownItem *ngFor="let staffObj of staffList" (click)="ChangeStaffList(staffObj, cObj)">
                    <a class="dropdown_item" *ngIf="staffObj">{{staffObj?.user?.first_name + ' ' +
                      staffObj?.user?.last_name}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="right-sec">
            <div class="date-calendar">
              <div class="label-text">Date</div>
              <div class="d-flex">
                <input placeholder="DD-MM-YYYY" id="birthday" name="birthday"
                  [(ngModel)]="cObj.service.schedule_date_temp" ngbDatepicker #d="ngbDatepicker" [placement]="'bottom'"
                  [positionTarget]="buttonEl" [markDisabled]="markDisabled"
                  [minDate]="minDate" [maxDate]="maxDate" (dateSelect)="onBookingDateChange(cObj)"
                  (blur)="onFocusFn(cObj, $event)" />
                <button #buttonEl class="btn btn-outline-selead_staffcondary calendar" (click)="d.toggle()"
                  type="button"></button>
                <!-- <input type="date" id="birthday" name="birthday" [(ngModel)]="cObj.service.schedule_date" [min]="minDate" (ngModelChange)="onBookingDateChange(cObj)"> -->
              </div>
            </div>
            <div class="hours-time">
              <div class="label-text">Available Slots</div>
              <div class="sb-dropdonws" ngbDropdown placement="bottom">
                <button class="dropdown-input pr-2" ngbDropdownToggle>{{cObj?.service?.schedule_time === undefined ?
                  'HH:MM': cObj?.service?.schedule_time }}</button>
                <ul class="dropdown-menu" ngbDropdownMenu>
                  <li ngbDropdownItem *ngFor="let slotObj of timeSlotsList" (click)="ChangeSlotList(slotObj, cObj)">
                    <a class="dropdown_item">{{slotObj}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
        
      <div class="protip-info">We don't promise to provide you the staff you have preferred based on their
        availability & gender. But you can let us know your preferred staff so that we could do our best to make
        them available.
      </div>

        <div class="text-right" id="availability-continue-btn">
          <button class="sb-primary-btn w-25" (click)="onContinue()">Continue</button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>


<!--service selection-->

<div class="modal fade model-custom w-600 service-selection-dialog" id="serviceModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <i class="bi bi-arrow-left-short" (click)="backFn()" *ngIf="!showServicesPopup"></i>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
          <i class="bi bi-x"></i>
        </button>
      </div>
      <!--service selection-->
      <div class="modal-body pt-0" *ngIf="showServicesPopup">
        <div class="title">Where do you want to get this service at?</div>
        <!-- <div class="service-title">{{serviceListObj.title}}</div> -->
        <div class="services">
          <div class="at-location radio-custom-sb" data-toggle="modal" (click)="getBranchAddressList()">
            <input type="radio" id="at-location" name="type" value="location" class="radio-custom">
            <label for="at-location" class="radio-custom-label">Service At Center</label>
          </div>
          <div class="at-home radio-custom-sb" data-toggle="modal" (click)="getCustomerAddressList()">
            <input type="radio" id="at-home" name="type" value="home" class="radio-custom">
            <label for="at-home" class="radio-custom-label">Service At Home</label>
          </div>
        </div>
      </div>
      <!--branch model-->
      <div class="modal-body pt-0" *ngIf="showBranchModalPopup">
        <div class="title mb-1">Showing branches near your location</div>
        <div class="desc-text-title">Based on your location provided</div>
        <div class="sb-dropdonws mb-3" ngbDropdown placement="bottom">
          <button class="dropdown-input" ngbDropdownToggle></button>
          <i class="seach-icon bi bi-search"></i>
          <input placeholder="Search Services" type="text" class="search-data">
        </div>
        <div class="location-address-selection" *ngIf="branchList">
          <ng-container *ngFor="let item of branchList">
            <div class="location-branch" [class.checkd-address]="item.selected"
              (click)="branchAddressSelection(item?.branch?.address?.address_id)">
              <div class="check-loaction-icon" *ngIf="item.selected">
                <i class="bi bi-check-circle-fill"></i>
              </div>
              <div class="details-address">
                {{item?.branch?.address?.address1}}, {{item?.branch?.address?.address2}},
                {{item?.branch?.address?.land_mark}}, {{item?.branch?.address?.city}},
                {{item?.branch?.address?.state}},
                {{item?.branch?.address?.country}}, {{item?.branch?.address?.post_code}}
              </div>
            </div>
          </ng-container>
        </div>
        <button class="sb-primary-btn w-100" data-dismiss="modal" (click)="showItemCount()">Done</button>
      </div>
      <!--at home model-->
      <div class="modal-body" *ngIf="showHomeModalPopup">
        <div class="title">Select Your Preffered Location</div>
        <div class="location-info mb-3">
          <i class="bi bi-geo-alt-fill"></i>
          <span class="loaction-long-lat">{{location}}</span>
        </div>
        <div class="address-suggestions">
          <div class="saved">Saved Addresses</div>
          <div class="add-new">Add New</div>
        </div>

        <div class="location-address-selection serviceat-home mt-1" *ngIf="addressList">
          <ng-container *ngFor="let item of addressList;let i = index;">
            <div class="location-branch" [class.checkd-address]="item.selected"
              (click)="homeAddressSelection(item?.address?.address_id, addressList[i].address_type)">
              <div class="check-loaction-icon" *ngIf="item.selected">
                <i class="bi bi-check-circle-fill"></i>
              </div>
              <div class="details-address">
                <!-- <div class="name">Arjun Kumar</div> -->
                <div class="home-address-title mb-2">{{addressList[i].address_type}}</div>
                <div class="address-gray">{{item?.address?.address1}}</div>
                <div class="address-gray">{{item?.address?.address2}}</div>
                <div class="address-gray">{{item?.address?.land_mark}}</div>
                <div class="address-gray">{{item?.address?.city}}</div>
                <div class="address-gray">{{item?.address?.state}}, {{item?.address?.country}},
                  {{item?.address?.post_code}}</div>
              </div>
            </div>
          </ng-container>
        </div>
        <button class="sb-primary-btn w-100" data-dismiss="modal" (click)="showItemCount()">Done</button>
      </div>
    </div>
  </div>
</div>