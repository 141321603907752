import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressPopupComponent } from 'src/app/components/address-popup/address-popup.component';
import { RouteConstants } from 'src/app/model/route-constants';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import { BroadcastService } from '../../services/broadcast.service';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment = require('moment');

export interface Pop {
  value: any;
}
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-providers-service-list-card',
  templateUrl: './providers-service-list-card.component.html',
  styleUrls: ['./providers-service-list-card.component.scss']
})
export class ProviderServiceListCardComponent implements OnInit, AfterViewInit {
  counter = 1
  itemCount: boolean;
  isClicked: boolean;
  addressList: any = [];
  addressList1:any=[];
  addressList2:any=[];
  branchList: any = [];
  location: any;
  showBranchModalPopup = false;
  showHomeModalPopup = false;
  showServicesPopup = false;
  showAddAddress = false;
  address_type: any;
  address_id: any;
  isSubmitted  = false;
  currencyCode = environment.currencyCode;
  sidebarVisible: boolean = false;

  showMoreAddresses:boolean=false;

  showAddRecurring: boolean = false;
  recurringForm!: any;
  date = new Date();
  addDaysToDate = () => {
    var res = new Date();
    res.setDate(res.getDate() + 15);
    return res;
  }
  minDate = { "year": this.date.getFullYear(), "month": this.date.getMonth() + 1, "day": this.date.getDate() };
  maxDate = { "year": this.addDaysToDate().getFullYear(), "month": this.addDaysToDate().getMonth() + 1, "day": this.addDaysToDate().getDate() };
  markDisabled: any;
  weekList: any;
  staffList: any;
  fromState: any;
  isRecurringIntervalAvail: any;
  catId: string | undefined;
  branch_slug_title: string | undefined;
  providerId: any;
  branchId: any;

  @Input() serviceListObj: any;
  @Input() serviceProviderDetails: any;
  @Input() branchDetails: any;
  @Output() updateSelection = new EventEmitter();


  constructor(private bs: BroadcastService, private router: Router,
    public dataService: DataService, public storageService: StorageService, public authService: AuthService,
    private mapsAPILoader: MapsAPILoader, private modalService: NgbModal, private activatedRoute: ActivatedRoute,
    private ngZone: NgZone) {
    this.itemCount = false
    this.isClicked = false;
  }

  ngOnInit(): void {
    this.userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
    this.serviceListObj.isSelected = false;
    this.location = sessionStorage.getItem('location');
    if (this.serviceListObj.quantities) {
      this.serviceListObj.isSelected = true;
    }

    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.catId = params.title;
      this.providerId = params.providerId;
      this.branch_slug_title = params.branch_slug_title;
      this.branchId = params.bId;
      this.fromState = params.fromState;
    });

   

    this.recurringForm = new FormGroup({
      staff: new FormControl(null),
      preferredTime: new FormControl(null, [Validators.required]),
      startDate: new FormControl('', [Validators.required]),
      selectedRecurringInterval: new FormControl(null)
    });
  
      this.recurringForm?.get('startDate').valueChanges.subscribe(() => this.onBookingDateChange());
      this.recurringForm?.get('staff').valueChanges.subscribe(() => this.onChangeStaffSelection());

      let cartItems: any = this.storageService.getItem('cartItems');
      cartItems = (cartItems != null && cartItems != undefined) ? JSON.parse(cartItems).results : [];

      this.isRecurringIntervalAvail = this.serviceListObj?.plans.filter((obj: any) => {
        return obj.recurring_interval === null 
    });

     if(cartItems?.length > 0 && this.fromState === 'cart'){
        for(let i = 0 ; i<cartItems?.length; i++){
          console.log(cartItems[i].service?.service_id)
          if(cartItems[i].service?.service_id === this.serviceListObj.service_id || 
            cartItems[i]?.plan?.service?.service_id === this.serviceListObj.service_id){
            this.showAddRecurring = true;
            this.schedule_date = cartItems[i].booking_date;
            // this.selectBookAService = false;

            this.selectedPlan =  this.serviceListObj.plans.find((obj: any) => {
              if(obj.plan_id === cartItems[i]?.plan?.plan_id){
                return obj;
              }
          });
            if(this.serviceListObj?.plans && this.serviceListObj.plans?.length > 0){
              this.recurringForm.setValue({
                staff: cartItems[i]?.staff !== null || cartItems[i]?.staff !== undefined ?  null :cartItems[i]?.staff?.employee_id,
                preferredTime: cartItems[i]?.booking_time,
                startDate: { "year": new Date(cartItems[i].booking_date).getFullYear(), "month": new Date(cartItems[i].booking_date).getMonth() + 1, "day": new Date(cartItems[i].booking_date).getDate() },
                selectedRecurringInterval: this.isRecurringIntervalAvail && this.isRecurringIntervalAvail?.length > 0 ? this.selectedPlan.recurring_service_plan : null
              });
            }else{
              // this.selectBookAService = true;
              console.log(cartItems[i])
              this.recurringForm.setValue({
                staff: cartItems[i]?.staff !== null || cartItems[i]?.staff !== undefined ?  null :cartItems[i]?.staff?.employee_id,
                preferredTime: cartItems[i]?.booking_time,
                startDate: { "year": new Date(cartItems[i].booking_date).getFullYear(), "month": new Date(cartItems[i].booking_date).getMonth() + 1, "day": new Date(cartItems[i].booking_date).getDate() },
                selectedRecurringInterval: ''
              });
            }
          }
        }
     }

  }

  decrement = () => {
    this.router.navigate([RouteConstants.CART]);


  }
  selectedPlan: any;
  selectBookAService:any = true;
  showItemCount = () => {
    // if (!this.address_id) {
    //   Swal.fire({
    //     text: "Please select one address",
    //     icon: 'warning',
    //     confirmButtonText: 'Ok',
    //     allowOutsideClick: false,
    //     allowEscapeKey: false
    //   });
    //   return;
    // }
 
    if (this.recurringForm.valid) {
      let providerId: any = this.serviceProviderDetails?.provider_slug_itle;
      let branchId: any = this.serviceListObj.branch.branch_id;
      let providerName: any = this.serviceProviderDetails.providerName;
      let isServiceAdded = true;
      let isPlanAdded = true;
      let cartItems: any = this.storageService.getItem('cartItems');
      let exitingProviderName = '';
      let msg = "You are allowed to subscribe one service at a time. DO you want to discard the selection and add plan";

      cartItems = cartItems !== null && cartItems !== undefined ? JSON.parse(cartItems).results : [];
      for (var i = 0; i < cartItems.length; i++) {
        branchId = branchId === 0 ? +'1' : branchId;
        let cartItem = cartItems[i];
        if (cartItem.service && (cartItem.service.service_provider?.slug_legal_name != providerId ||
          cartItem.service.serviceprovider_branch.branch_id != branchId)) {
          exitingProviderName = cartItem.service.service_provider?.legal_name;
          msg = `Your cart contains services from "${exitingProviderName}". Do you want to discard the selection and add services from "${providerName}" ?`
            isServiceAdded = false;
        }else if (cartItem.service && this.selectedPlan) {
          isServiceAdded = false;
        } else if (cartItem.service === null && cartItem.plan) {
          isPlanAdded = false;
        }
        
      }
      if (isServiceAdded && isPlanAdded) {
        this.addCart();
      } else {
        Swal.fire({
          title: 'Replace cart item?',
          text: msg,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((res: any) => {
          if (res.isConfirmed) {
            this.deleteTotalCart();
          }
        });
      }
    }else{
      Swal.fire({
        text: 'Please fill mandatory fields',
        icon: 'error',
        showCancelButton: false,
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    }

  }

  checkQuantity = (emitObj: any) => {
    let val = 1;
    let cartItems: any = this.storageService.getItem('cartItems');
    cartItems = cartItems !== null && cartItems !== undefined ? JSON.parse(cartItems).results : [];
    for (var i = 0; i < cartItems.length; i++) {
      if (cartItems[i]?.booking_time === this.recurringForm.value.preferredTime &&
        cartItems[i]?.booking_date === this.schedule_date  ) {
        val = cartItems[i]?.quantity + 1
        return val;
      }
    }
    return val;
  }

  addCart = () => {

    const itemObj = {
      quantity: this.checkQuantity(this.serviceListObj.quantity),
      service: this.selectedPlan && !this.selectBookAService ? null : this.serviceListObj.service_id,
      address: null,
      customer: JSON.parse(<string>this.storageService.getItem("user_info")).customer_id,
      plan: this.selectedPlan && !this.selectBookAService ? this.selectedPlan.plan_id : null,
      booking_time: this.recurringForm.value.preferredTime,
      booking_date: this.schedule_date,
      staff: this.recurringForm.value.staff || null
    };

    // let itemObj = {
    //   value: true,
    //   price: this.serviceListObj.min_charge,
    //   serviceName: this.serviceListObj.title,
    //   quantity: this.serviceListObj.quantity,
    //   id: this.serviceListObj.service_id,
    //   address_id: this.address_id,
    //   // address_type: this.address_type
    // }
    this.updateSelection.emit(itemObj);
    this.showAddRecurring = false;
    this.router.navigate([RouteConstants.PROVIDER_DETAILS], {
      queryParams: {        
        title: this.catId,
        providerId: this.providerId,
        bId: this.branchId,
        branch_slug_title: this.branch_slug_title
      }
    })

    // this.bs.broadcast('SHOW_BOTTOM_CART', itemObj);
    // this.serviceListObj.quantity = this.serviceListObj.quantity + 1;
    // this.serviceListObj.isSelected = true;
    // $('test' + this.serviceListObj.service_id).modal('hide');
    // this.showServicesPopup = false;
    // this.showBranchModalPopup = false;
    // this.showHomeModalPopup = false;
    // this.showAddAddress = false;
    // $('.modal-backdrop').remove();
  }

  deleteTotalCart = () => {
    this.dataService.deleteTotalCart().subscribe((data: any) => {
      if (data) {
        this.storageService.removeItem('cartItems');
        this.bs.broadcast('REFRESH_CART', 'count');
        this.addCart();
      }
    })
  }

  homeAddressSelection = (address_id: any, address_type: any) => {
    let index = -1;
    this.addressList.forEach((p: any, i: any) => {
      if (+p?.address?.address_id == +address_id) {
        index = i;
      }
    });
    this.addressList.forEach((item: any) => {
      item.selected = false
    });
    this.addressList[index].selected = true;
    this.address_id = address_id;
    this.address_type = address_type;
  }

  // branchAddressSelection = (address_id: any) => {
  //   let index = -1;
  //   this.branchList.forEach((p: any, i: any) => {
  //     if (+p?.branch?.address?.address_id == +address_id) {
  //       index = i;
  //     }
  //   });
  //   this.branchList.forEach((item: any) => {
  //     item.selected = false
  //   });
  //   this.branchDetails.selected = true;
  //   this.address_id = address_id;
  //   this.address_type = '';
  // }

  gotoServiceDetailsPage = () => {
    jQuery('body, html').animate({ scrollTop: 0 }, 100)
    this.storageService.setItem('serviceListObj', {
      title : this.catId,
      providerId : this.providerId,
      branch_slug_title : this.branch_slug_title,
      bId : this.branchId
    });
    this.router.navigate([`${RouteConstants.SERVICE_INFO + '/' + this.serviceListObj.service_id}`]);
  }

  getCustomerAddressList = (fromState?: any) => {
    this.showBranchModalPopup = false;
    this.showHomeModalPopup = true;
    this.showServicesPopup = false;
    this.showAddAddress = false;
    const userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
    if (userDetails) {
      this.dataService.getCustomerAddressList(userDetails?.customer_id).subscribe((res: any) => {
        if (res) {
          this.addressList = res.address;
          if(this.addressList?.length === 0 && fromState === 'no_address'){
            this.openLocationPopup();
          }
          this.addressList1 = res.address.slice(0,4);
          this.addressList2 = res.address.slice(4,res.address.length);
        }
      });
    }
  }

  getBranchAddressList = () => {
    this.showBranchModalPopup = true;
    this.showHomeModalPopup = false;
    this.showAddAddress = false;
    this.showServicesPopup = true;
    this.branchDetails.selected = true;
    this.address_id = this.branchDetails.address_id;
    // this.dataService.getBranchAddressList(this.serviceProviderDetails?.provider_slug_itle).subscribe((res: any) => {
    //   if (res) {
    //     this.branchList = res.results;
    //   }
    // });
  }

  openPopup = () => {
    this.showAddAddress = false;
    if (!this.authService.isAuthenticated()) {
      this.storageService.setItem("redirectURL", JSON.stringify(this.router.url))
      this.router.navigate(['sign-in']);
      return;
    }
    if (this.serviceListObj.quote_only) {
      this.router.navigate([RouteConstants.REQUEST_QUOTE], {
        queryParams: {
          providerId: this.serviceProviderDetails?.providerId,
          serviceId: this.serviceListObj.service_id,
          branchId: this.serviceListObj.branch.branch_id
        }
      });
      const data = {
        providerName: this.serviceProviderDetails.providerName,
        providerLogoUrl: this.serviceProviderDetails.logo_url,
        serviceName: this.serviceListObj.title,
        serviceLogoUrl: this.serviceListObj.image,
        availability: this.serviceListObj.avail_at,
        price: this.serviceListObj.min_charge,
        contact: this.serviceListObj.avail_at,
        branchAddress: this.serviceProviderDetails.address
      };
      this.storageService.setItem("quoteDetails", JSON.stringify(data));
    // } else if (this.serviceListObj.avail_at === 'home_only') {
    //   this.getCustomerAddressList();
    // } else if (this.serviceListObj.avail_at === 'center_only') {
    //   this.getBranchAddressList();
    } else {
      // this.showBranchModalPopup = false;
      // this.showHomeModalPopup = false;
      // this.showServicesPopup = true;

      this.showAddRecurring = true;
    }
  }

  backFn = () => {
    this.showAddAddress = false;
    if (this.serviceListObj.avail_at === 'home_only') {
      this.showHomeModalPopup = true;
      this.showServicesPopup = false;
      this.showBranchModalPopup = false;
    } else if (this.showBranchModalPopup) {
      this.showHomeModalPopup = false;
      this.showServicesPopup = true;
      this.showBranchModalPopup = false;
    } else if (this.showHomeModalPopup) {
      this.showHomeModalPopup = false;
      this.showBranchModalPopup = false;
      this.showServicesPopup = true;
    } else {
      this.showAddAddress = false;
      this.showHomeModalPopup = true;
      this.showServicesPopup = false;
      this.showBranchModalPopup = false;
    }
  }

  @ViewChild('mapSearchFiled3', { static: true }) searchFiled: ElementRef | undefined;
  userDetails: any
  lat = 0;
  lng = 0;
  zoom = 12;
  userLocation = "";
  geoCoder: any;
  selectedAddress: any;
  addressLabel: any = '';
  cityName: any;
  showAccount: any = false;
  // constructor(  ) { }


  ngAfterViewInit() {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;

      const showPos = (position: any) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.getAddress();

      }
      let self = this;
      if (navigator.geolocation) {
        // @ts-ignore
        navigator.geolocation.getCurrentPosition(showPos);

      }


      // @ts-ignore
      let autocomplete = new google.maps.places.Autocomplete(this.searchFiled?.nativeElement, {});
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {

          let place: any = autocomplete.getPlace();
          this.userLocation = place.formatted_address;
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.selectedAddress = this.parse_place(place);
          this.updateLocationInSession()
        });
      });
    });
  }

  getAddress() {
    this.geoCoder.geocode({
      'location': {
        lat: this.lat,
        lng: this.lng
      }
    }, (results: { formatted_address: string; }[], status: string) => {
      if (status === 'OK') {
        if (results[0]) {
          this.userLocation = results[0].formatted_address;
          this.selectedAddress = this.parse_place(results[0]);
          this.updateLocationInSession();
        } else {
          Swal.fire({
            text: 'No results found',
            icon: 'warning',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
        }
      } else {
        // Swal.fire({
        //   text: 'Geocoder failed due to: ' + status,
        //   icon: 'error',
        //   confirmButtonText: 'Ok',
        //   allowOutsideClick: false,
        //   allowEscapeKey: false
        // });
      }
    });
  }

  updateLocationInSession() {
    sessionStorage.setItem('location', this.userLocation);
    sessionStorage.setItem('latitude', String(this.lat));
    sessionStorage.setItem('longitude', String(this.lng));
  }

  markerDragEnd(e: any) {
    this.lat = e.latLng.lat();
    this.lng = e.latLng.lng();
    this.zoom = 16;
    this.getAddress();
  }

  getStatus(add: any) {
    return add.selected;
  }

  saveAddress = () => {
    this.isSubmitted  = false;
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['sign-in']);
      return;
    }
    if(this.addressLabel === ""){
      this.isSubmitted  = true;
      return;
    }
    if (this.selectedAddress !== undefined && this.selectedAddress !== null) {

      const x = {
        customer_id: this.userDetails.customer_id,
        address: {
          address1: this.selectedAddress.address1,
          address2: this.selectedAddress.address2,
          post_code: this.selectedAddress.post_code,
          land_mark: this.selectedAddress.landmark,
          latitude: this.lat,
          longitude: this.lng,
          city: this.selectedAddress.city,
          state: this.selectedAddress.state,
          country: this.selectedAddress.country,
          address_type: this.addressLabel
        }
      }

      this.dataService.createCustomer(x).subscribe((res: any) => {
        if (res && res.error === undefined) {
          Swal.fire({
            text: res.status,
            icon: 'success',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((data: any) => {
            if (data.isConfirmed) {
              this.showAddAddress = false;
              this.showHomeModalPopup = true;
              this.showServicesPopup = false;
              this.showBranchModalPopup = false;
              this.getCustomerAddressList();
            }
          });
        }else if (res && res.error){
          Swal.fire({
            text: res.error.Error,
            icon: 'error',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }
      });
    }
  }

  parse_place = (place: any) => {
    let location: any = [];
    for (var ac = 0; ac < place.address_components.length; ac++) {
      var component = place.address_components[ac];
      switch (component.types[0]) {
        case 'locality':
          location['city'] = component.long_name;
          this.cityName = component.long_name;
          break;
        case 'administrative_area_level_1':
          location['state'] = component.long_name;
          break;
        case 'country':
          location['country'] = component.long_name;
          break;
        case 'postal_code':
          location['post_code'] = component.long_name;
          break;
        case 'political':
          location['address1'] = component.long_name;
          break;
        case 'premise' || 'neighborhood':
          location['address2'] = component.long_name;
          break;
        case 'landmark':
          location['landmark'] = component.long_name;
          break;
      }
    };
    return location;
  }

  openLocationPopup = () =>{
    // this.showAddAddress = true;
    this.showHomeModalPopup=false;
    this.addressLabel = '';
    const modalRef = this.modalService.open(AddressPopupComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    modalRef.componentInstance.addressObj = {fromState: 'new'};

    modalRef.result.then((result: any) => {
      if (result) {
        this.getCustomerAddressList();
        $('.modal-backdrop').remove();
      }
    });
  }


  onBookingDateChange = () => {
    let schedule_date = this.recurringForm.get('startDate').value;
   
    let year = schedule_date.year;
    let month = schedule_date.month <= 9 ? '0' + schedule_date.month : schedule_date.month;
    let day = schedule_date.day <= 9 ? '0' + schedule_date.day : schedule_date.day;;
    let finalDate =  year + "-" +  month + "-" + day; // YYYY-MM-dd
    this.schedule_date = finalDate;
    if(this.serviceListObj?.plans && this.serviceListObj.plans?.length === 0){
     this.getTimeSlots();
    //  this.recurringForm.controls['preferredTime'].setValue(null);
    //  this.recurringForm.get('preferredTime').updateValueAndValidity();
     
    }
    
  }

  onChangeStaffSelection = () => {
    this.selectedStaff = this.recurringForm.get('staff').value;
    if(this.serviceListObj?.plans && this.serviceListObj.plans?.length === 0){
      this.getTimeSlots();
     }
  }

  
  schedule_date: any;
  selectedStaff: any;
  timeSlotsList: any;
  getTimeSlots = () => {
    this.timeSlotsList = [];
    console.log("Time Slots:", this.timeSlotsList)
    const reqObj = {
      branchId: this.serviceListObj.branch.branch_id,
      bookingDate: this.schedule_date,
      staffId: this.selectedStaff === undefined  || this.selectedStaff === null ? 0 : this.selectedStaff
    }
    this.dataService.getAvailableTimeSlots(reqObj).subscribe((res: any) => {
      if (res) {
        const timeSlotsList = res.available_slots;

        let momentTimeSlots: any = [];
        let today = moment();
        if (today.format('YYYY-MM-DD') === this.schedule_date) {
          for (let timeObj of timeSlotsList) {
            let time = moment(timeObj, "hh:mm a").format('hh:mm a');
            let time1 = moment(time, "hh:mm a").format('HH');
            if (this.checkTime(time1)) {
              momentTimeSlots.push(time);
            }
          }
          this.timeSlotsList = momentTimeSlots
        } else {
          this.timeSlotsList = res.available_slots
        }

      }
    })

  }

  checkTime = (a: any) => {
    const now = moment();
    const hourToCheck = +a;
    const dateToCheck = now.hour(hourToCheck).minute(30);
    return moment().isBefore(dateToCheck);
  }

  getStaffList = () => {
    console.log("Staff list:", this.getStaffList);
    const req = {
      serviceId: this.serviceListObj.service_id,
      branchId: this.serviceListObj.serviceprovider_branch.branch_id
    }
    let apiName :any;
    if(this.serviceListObj?.plans && this.serviceListObj.plans?.length === 0){
      apiName = this.dataService.getStaffDetails(req);
     }else{
      apiName =  this.dataService.getStaffList(req);
     }
    apiName.subscribe((res: any) => {
      if (res) {
        this.staffList = res.results;
        console.log("Staff List:", this.staffList)
      }
    });
  }

  closePopup = () => {
    this.showAddRecurring = false;
  }
}
