<div class="tutor-page">
    <div class="header-session container">
<!-- <h1>MyTutor</h1> -->
    </div>
    <div class="tutor-signup container">
        <div class="row">
            <div class="col-md-3">
                <img src="assets/icons/arrow-left-t.svg" alt="" class="arrow-back" [routerLink]="['/tutor-login']">
            </div>
            <div class="col-md-6">
               <h2> Sign up</h2>
            </div>
            <div class="col-md-3">
               <button class="sign-btn" type="button" [routerLink]="['/tutor-login']">Login</button>
            </div> 
        </div>
        <div class="mt-3">      
              <button class="btn-tutor" type="button" [routerLink]="['/parent/signup']">I'm a Parent</button>
        </div>

        <div class="mt-3">      
            <button class="btn-tutor" type="button" [routerLink]="['/student/age']">I'm a Student</button>
            
      </div>
      <p [routerLink]="['/tutor/application/process']">Apply to become a tutor</p>
    </div>
   <p class="need-wrap"> Need help? Call us on <b>+44 (0) 203 773 6020 </b>or <b>email us</b></p>
</div>
