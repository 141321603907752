import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-student-change-password',
  templateUrl: './student-change-password.component.html',
  styleUrls: ['./student-change-password.component.scss']
})
export class StudentChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup | undefined;
  passwordForm: FormGroup;
  showPwdLabel: any | undefined;



  constructor(private fb: FormBuilder) {
  
 
   
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/)]],
      confirmPassword: ['', Validators.required]
    }, { validator: this.passwordsMatchValidator });
    
   }
 
  ngOnInit(): void {
 
  
  }
  // passwordsMatchValidator(form: FormGroup) {
  //   const password = form.get('password')!.value;
  //   const confirmPassword = form.get('confirmPassword')!.value;
  //   return password === confirmPassword ? null : { passwordsDoNotMatch: true };
  // }
  passwordsMatchValidator(form: FormGroup) {
    const password = form.get('password')!.value;
    const confirmPassword = form.get('confirmPassword')!.value;
    return password === confirmPassword ? null : { passwordsDoNotMatch: true };
  }
 
  onSubmit() {
   
  }
}
