import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { RouteConstants } from 'src/app/model/route-constants';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent implements OnInit {
  cartItems: any;
  timeSlotsList: any;
  location: any;
  addressList: any = [];
  branchList: any = [];
  staffList: any = [];
  showBranchModalPopup = false;
  showHomeModalPopup = false;
  showServicesPopup = false;
  address_id: any;
  address_type: any;
  selectedObj: any;
  date = new Date();
  addDaysToDate = () => {
    var res = new Date();
    res.setDate(res.getDate() + 15);
    return res;
  }
  minDate = { "year": this.date.getFullYear(), "month": this.date.getMonth() + 1, "day": this.date.getDate() };
  maxDate = { "year": this.addDaysToDate().getFullYear(), "month": this.addDaysToDate().getMonth() + 1, "day": this.addDaysToDate().getDate() };
  isSelectStaff = false;
  isSelectSlots = false;
  isSelectBookingDate = false;
  markDisabled: any;
  weekList: any;
  constructor(private dataService: DataService, private storageService: StorageService, public bs: BroadcastService,
    public router: Router, public config: NgbDatepickerConfig, public calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    // days that don't belong to current month are not visible
    config.outsideDays = 'hidden';
    //call your service

  }

  ngOnInit(): void {
    this.location = sessionStorage.getItem('location');
    this.getCartItems();
  }

  getCartItems = () => {
    let cartItems: any = this.storageService.getItem('cartItems');
    cartItems = cartItems !== null && cartItems !== undefined ? JSON.parse(cartItems).results : [];
    let tempList: any = [];
    this.getWeekSchedule(cartItems[0]);
    for (var i = 0; i < cartItems.length; i++) {
      if (cartItems[i]?.service?.schedule_date_temp) {
        delete cartItems[i]?.service?.schedule_date_temp;
      }
      if (cartItems[i]?.service?.schedule_time) {
        delete cartItems[i]?.service?.schedule_time;
      }
      if (cartItems[i]?.quantity === 1) {
        tempList.push(JSON.parse(JSON.stringify(cartItems[i])));
      }

    }
    let servicesWithMoreQty = cartItems.filter((item: any) => item.quantity > 1);

    if (servicesWithMoreQty && servicesWithMoreQty.length > 0) {
      for (let item of servicesWithMoreQty) {
        let count = item.quantity //-1;	
        item.quantity = 1;
        for (let i = 0; i < count; i++) {
          item.quantity = 1;
          tempList.push(JSON.parse(JSON.stringify(item)));
        }
      }
    }
    this.cartItems = tempList;

  }

  getTimeSlots = (cObj: any) => {
    this.timeSlotsList = [];
    const reqObj = {
      branchId: cObj?.service?.serviceprovider_branch?.branch_id,
      bookingDate: cObj.service.schedule_date,
      staffId: cObj?.service.lead_staff_id === undefined ? 0 : cObj?.service.lead_staff_id
    }
    this.dataService.getAvailableTimeSlots(reqObj).subscribe((res: any) => {
      if (res) {
        const timeSlotsList = res.available_slots;

        let momentTimeSlots: any = [];
        let today = moment();
        if (today.format('YYYY-MM-DD') === cObj.service.schedule_date) {
          for (let timeObj of timeSlotsList) {
            let time = moment(timeObj, "hh:mm a").format('hh:mm a');
            let time1 = moment(time, "hh:mm a").format('HH');
            if (this.checkTime(time1)) {
              momentTimeSlots.push(time);
            }
          }
          this.timeSlotsList = momentTimeSlots
        }else{
         this.timeSlotsList = res.available_slots 
        }
       
      }
    })

  }

  checkTime = (a: any) => {
    const now = moment();
    const hourToCheck = +a;
    const dateToCheck = now.hour(hourToCheck).minute(30);
    return moment().isBefore(dateToCheck);
  }



  homeAddressSelection = (address_id: any, address_type: any) => {
    let index = -1;
    this.addressList.forEach((p: any, i: any) => {
      if (+p?.address?.address_id == +address_id) {
        index = i;
      }
    });
    this.addressList.forEach((item: any) => {
      item.selected = false
    });
    this.addressList[index].selected = true;
    this.address_id = address_id;
    this.address_type = address_type;
  }

  branchAddressSelection = (address_id: any) => {
    let index = -1;
    this.branchList.forEach((p: any, i: any) => {
      if (+p?.branch?.address?.address_id == +address_id) {
        index = i;
      }
    });
    this.branchList.forEach((item: any) => {
      item.selected = false
    });
    this.branchList[index].selected = true;
    this.address_id = address_id;
    this.address_type = '';
  }

  getCustomerAddressList = () => {
    this.showBranchModalPopup = false;
    this.showHomeModalPopup = true;
    this.showServicesPopup = false;
    const userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
    if (userDetails) {
      this.dataService.getCustomerAddressList(userDetails?.customer_id).subscribe((res: any) => {
        if (res) {
          this.addressList = res.address;
        }
      });
    }
  }

  getBranchAddressList = () => {
    this.showBranchModalPopup = true;
    this.showHomeModalPopup = false;
    this.showServicesPopup = false;
    this.dataService.getBranchAddressList(this.selectedObj?.service?.service_provider?.slug_legal_name).subscribe((res: any) => {
      if (res) {
        this.branchList = res.results;
      }
    });
  }

  getStaffList = (cObj: any) => {
    const req = {
      serviceId: cObj?.service?.service_id,
      branchId: cObj?.service?.serviceprovider_branch?.branch_id
    }
    this.dataService.getStaffList(req).subscribe((res: any) => {
      if (res) {
        this.staffList = res.results;
      }
    });
  }

  showItemCount = () => {
    const reqObj = {
      service: this.selectedObj.service.service_id,
      address: this.address_id,
      address_type: this.address_type,
      quantity: this.selectedObj?.quantity,
      customer: JSON.parse(<string>this.storageService.getItem("user_info")).customer_id
    }
    this.dataService.addToCart(reqObj).subscribe((data: any) => {
      if (data) {
        this.getCart();
      }
    })
  }

  backFn = () => {
    if (this.showBranchModalPopup) {
      this.showHomeModalPopup = false;
      this.showServicesPopup = true;
      this.showBranchModalPopup = false;
    } else if (this.showHomeModalPopup) {
      this.showHomeModalPopup = false;
      this.showBranchModalPopup = false;
      this.showServicesPopup = true;
    }
  }

  openPopup = (selectedObj: any) => {
    this.showBranchModalPopup = false;
    this.showHomeModalPopup = false;
    this.showServicesPopup = true;
    this.selectedObj = selectedObj;
  }

  getCart = () => {
    this.dataService.cartItems().subscribe(() => {
      this.getCartItems()
      this.bs.broadcast('REFRESH_CART', 'count');
    }
    );
  }

  onContinue = () => {
    if (!this.isSelectBookingDate || !this.isSelectSlots) {
      Swal.fire({
        text: 'Select Date & Available Slots',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        allowEscapeKey: false
      });
      return;
    } else
      if (this.isSelectBookingDate && this.isSelectSlots) {
        this.storageService.setItem('cartItems', JSON.stringify({ results: this.cartItems.length === 0 ? [] : this.cartItems }));
        this.router.navigate([RouteConstants.PAYMENT]);
      }
  }

  ChangeSlotList = (event: any, currentCartObj?: any) => {
    currentCartObj.service.schedule_time = event;
    let convertedTime = moment(event, 'hh:mm A').format('HH:mm')
    currentCartObj.service.schedule_time_hhmm = convertedTime;
    currentCartObj.service.lead_staff_id = currentCartObj.service.lead_staff_id === undefined ? 0 : currentCartObj.service.lead_staff_id;
    this.isSelectSlots = true;
  }

  ChangeStaffList = (event: any, currentCartObj?: any) => {
    this.isSelectStaff = true;
    currentCartObj.service.lead_staff = event?.user?.first_name + ' ' + event?.user?.last_name;
    currentCartObj.service.lead_staff_id = event?.employee_id;
    this.getTimeSlots(currentCartObj);
  }

  onBookingDateChange = (currentCartObj?: any, id?: any) => {
    let year = currentCartObj.service.schedule_date_temp.year;
    let month = currentCartObj.service.schedule_date_temp.month <= 9 ? '0' + currentCartObj.service.schedule_date_temp.month : currentCartObj.service.schedule_date_temp.month;
    let day = currentCartObj.service.schedule_date_temp.day <= 9 ? '0' + currentCartObj.service.schedule_date_temp.day : currentCartObj.service.schedule_date_temp.day;;
    let finalDate = day + "-" + month + "-" + year; // dd-MM-YYYY
    currentCartObj.service.schedule_date = year + "-" + month + "-" + day; // YYYY-MM-dd
    // currentCartObj.service.schedule_date_temp= finalDate;
    currentCartObj.service.schedule_time = undefined;
    this.getTimeSlots(currentCartObj);
    this.isSelectBookingDate = true;
  }
  getWeekSchedule = (cObj: any) => {
    const req = {
      branchId: cObj?.service?.serviceprovider_branch?.branch_id
    }
    this.dataService.getWeekSchedule(req).subscribe((res: any) => {
      if (res) {
        this.weekList = res.week_schedule;
        this.markDisabled = (date: NgbDate) => {
          let currentDay = this.calendar.getWeekday(date);
          switch (currentDay) {
            case 1: return !this.weekList.Monday.isOpen;
              break
            case 2: return !this.weekList.Tuesday.isOpen;
              break;
            case 3: return !this.weekList.Wednesday.isOpen;
              break;
            case 4: return !this.weekList.Thursday.isOpen;
              break;
            case 5: return !this.weekList.Thursday.isOpen;
              break;
            case 6: return !this.weekList.Saturday.isOpen;
              break;
            case 7: return !this.weekList.Sunday.isOpen;
              break;
            default: return []
          }
        };
      }
    });
  }

  onFocusFn = (currentCartObj: any, e: any) => {
    const CurrentDate = new Date();
    const fDate = e.target.value.split('-')
    const finalDate = fDate[2] + '-' + fDate[1] + '-' + fDate[0];
    let GivenDate: any = new Date(finalDate);
    if (GivenDate > CurrentDate) {
      currentCartObj.service.schedule_date = finalDate;
      currentCartObj.service.schedule_time = undefined;
      this.getTimeSlots(currentCartObj);
      this.isSelectBookingDate = true;
      GivenDate = '';
    } else {
      Swal.fire({
        text: e.target.value === '' ? 'Invalid date' : 'Given date is greater than the current date.',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        allowEscapeKey: false
      });
    }
  }

}
