<div class="bg-color-of-pages">
    <!-- <app-breadcrumb></app-breadcrumb> -->
    <div class="container mt-5">
        <div class="service-details-data" *ngIf="serviceDetails">
            <!-- Repeat Data -->
            <ng-container>
                <div class="service-card-data" *ngIf="false">
                    <div class="top-sec">
                        <div class="img-blcok">
                            <img [src]="serviceDetails.image">
                            <!-- <span>JK</span> -->
                        </div>
                        <div class="content-block">
                            <div class="sb-dropdonws mb-2" #myDrop ngbDropdown placement="bottom-left">
                                <button class="dropdown-input" ngbDropdownToggle
                                    (click)="changeDropdownValue()">{{serviceDetails?.service_provider?.register_address?.address1}}
                                    <i class="bi bi-caret-down-fill ml-2"></i></button>
                                <ul class="dropdown-menu" ngbDropdownMenu>
                                    <li ngbDropdownItem *ngFor="let item of branchList">
                                        <a class="dropdown_item"
                                            (click)="changeDropdownValue()">{{item?.branch?.address?.address1}} <div
                                                class="kminfo"><img src="assets/icons/map-location.svg">
                                                {{item?.distance}} miles</div></a>
                                    </li>
                                </ul>
                            </div>
                            <div class="service-name">{{serviceDetails?.title}}</div>
                            <div class="km-info"><img alt="" src="assets/icons/map-location.svg">{{distance}} miles</div>
                            <div class="book-now-block">
                                <button class="add-btn" [attr.data-target]="'#test'+serviceDetails.service_id"
                                    data-toggle="modal" (click)="openPopup()" *ngIf="!serviceDetails.isSelected">
                                    <i class="bi bi-plus-circle"></i>
                                    <span>{{serviceDetails?.quote_only ? 'GET QUOTE': 'ADD TO CART'}}</span>
                                </button>
                                <div class="btn-add-remove" *ngIf="serviceDetails?.isSelected">
                                    <i class="bi bi-trash" (click)="decrement()"></i>
                                    <span class="count">{{serviceDetails?.quantities}}</span>
                                    <i class="bi bi-plus-circle" [attr.data-target]="'#test'+serviceDetails?.service_id"
                                        data-toggle="modal" (click)="openPopup()"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-sec">
                        <div class="left-sec">
                            <div class="price-tag" *ngIf="!serviceDetails?.quote_only">Price :
                                {{serviceDetails?.min_charge | currency: currencyCode:'symbol':'1.0-0' }}</div>
                            <div class="price-tag" *ngIf="serviceDetails?.quote_only">Starts from :
                                {{serviceDetails?.min_charge | currency: currencyCode:'symbol':'1.0-0' }}</div>
                            <div class="tab-name-click">
                                <span *ngIf="serviceDetails?.avail_at === 'home_only'">Home</span>
                                <span
                                    *ngIf="serviceDetails?.avail_at !== 'home_only'">{{serviceDetails?.avail_at}}</span>
                            </div>
                            <!-- <div class="tab-name-click">Home</div>
                            <div class="tab-name-click">Center</div> -->
                        </div>
                        <!-- <div class="right-sec">
                            <div class="explore-more">
                                <span>Explore services and more</span>
                                <i class="bi bi-arrow-right-short"></i>
                            </div>
                        </div> -->
                    </div>
                </div>

                <!-- New Design starts -->
                <div class="main-s-details-wrapper">
                    <div class="inner-s-details-wrapper">
                        <div class="serice-img-blcok">
                            <img [src]="serviceDetails.image">

                            <!-- <div class="service-type-wrapper">
                                <span *ngIf="serviceDetails?.avail_at === 'home_only'">Home</span>
                                <span
                                    *ngIf="serviceDetails?.avail_at !== 'home_only'">{{serviceDetails?.avail_at}}</span>
                            </div> -->
                        </div>
                    </div>
                    <div class="inner-s-details-wrapper" id="inner-s-desc-wrapper">
                        <div class="s-title-btn">
                            <div class="s-title-section">
                                <div class="s-name">{{serviceDetails?.title}}</div>
                                <p class="avail-at-text mt-2" *ngIf="serviceDetails?.avail_at == 'home'">Avail at
                                    Door-step</p>
                                <p class="avail-at-text mt-2" *ngIf="serviceDetails?.avail_at == 'center_only'">Avail at
                                    Center</p>
                                <p class="avail-at-text mt-2" *ngIf="serviceDetails?.avail_at == 'both'">Avail at
                                    Door-step & Center</p>
                            </div>
                            <div class="b-a-service text-right" *ngIf="false">
                                <div class="book-now-block">
                                    <button class="add-btn" [attr.data-target]="'#test'+serviceDetails.service_id"
                                        data-toggle="modal" (click)="openPopup()" *ngIf="!serviceDetails.isSelected">
                                        <!-- <i class="bi bi-plus-circle"></i> -->
                                        <span>{{serviceDetails?.quote_only ? 'GET QUOTE': 'BOOK A SERVICE'}}</span>
                                    </button>
                                    <div class="btn-add-remove" *ngIf="serviceDetails?.isSelected">
                                        <i class="bi bi-trash" (click)="decrement()"></i>
                                        <span class="count">{{serviceDetails?.quantities}}</span>
                                        <i class="bi bi-plus-circle"
                                            [attr.data-target]="'#test'+serviceDetails?.service_id" data-toggle="modal"
                                            (click)="openPopup()"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="distance-wrapper mt-3">
                            <div class="km-info pt-1">
                                <img alt="" src="assets/icons/distance.svg">
                                {{distance}} miles
                            </div>
                            <div class="sb-dropdonws mb-2" #myDrop ngbDropdown placement="bottom-left">
                                <button class="dropdown-input" ngbDropdownToggle
                                    (click)="changeDropdownValue()">{{serviceDetails?.serviceprovider_branch?.service_branch_name}}
                                    <i class="bi bi-caret-down-fill ml-2"></i></button>
                                <ul class="dropdown-menu" ngbDropdownMenu>
                                    <li ngbDropdownItem *ngFor="let item of branchList">
                                        <a class="dropdown_item"
                                            (click)="changeDropdownValue()">{{item?.branch?.address?.address1}} <div
                                                class="kminfo"><img src="assets/icons/map-location.svg">
                                                {{item?.distance}} miles</div></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="review-wrapper mt-2" *ngIf="providersDetails">
                            <div class="rating-wrapper">
                                <div class="rating-sec" (click)="gotoReviewPage()">
                                    <i class="bi bi-star-fill disable-rating"
                                        [ngClass]="{'disable-rating' :  1 <= providersDetails?.avg_rating}"></i>
                                    <i class="bi bi-star-fill disable-rating"
                                        [ngClass]="{'disable-rating' :  2 <= providersDetails?.avg_rating}"></i>
                                    <i class="bi bi-star-fill disable-rating"
                                        [ngClass]="{'disable-rating' :  3 <= providersDetails?.avg_rating}"></i>
                                    <i class="bi bi-star-fill disable-rating"
                                        [ngClass]="{'disable-rating' :  4 <= providersDetails?.avg_rating}"></i>
                                    <i class="bi bi-star-fill disable-rating"
                                        [ngClass]="{'disable-rating' :  5 <= providersDetails?.avg_rating}"></i>
                                    <span class="rate-number">{{providersDetails?.avg_rating}}</span>
                                </div>
                            </div>
                            <div class="v-divider"> | </div>
                            <div class="reviews-wrapper" (click)="gotoReviewPage()">
                                {{providersDetails?.review_count}} Reviews
                            </div>
                        </div>
                        <div class="s-price-wrapper mt-3">
                            <div class="s-price-inner-item">
                                <div class="price-tag" *ngIf="!serviceDetails?.quote_only">
                                    <!-- <span class="grey-text">
                                        Price 
                                    </span> -->
                                    <span class="s-price-main" *ngIf="!selectedPlan">
                                        {{ serviceDetails?.min_charge | currency: currencyCode:'symbol':'1.0-0' }}
                                    </span>
                                    <div class="price-tag">
                                        <span class="s-price-main" *ngIf="selectedPlan">
                                            {{ selectedPlan.amount | currency: currencyCode:'symbol':'1.0-0' }}
                                        </span>
                                    </div>

                                </div>
                                <div class="price-tag" *ngIf="serviceDetails?.quote_only">
                                    <!-- <span class="grey-text">
                                        Starts from 
                                    </span> -->

                                    <span class="s-price-main">
                                        {{serviceDetails?.min_charge | currency: currencyCode:'symbol':'1.0-0' }}
                                    </span>
                                </div>
                            </div>
                            <!-- <div class="s-price-inner-item">
                                <div class="book-now-block">
                                    <button class="add-btn" [attr.data-target]="'#test'+serviceDetails.service_id"
                                        data-toggle="modal" (click)="openPopup()" *ngIf="!serviceDetails.isSelected">
                                        <span>{{serviceDetails?.quote_only ? 'GET QUOTE': 'BOOK A SERVICE'}}</span>
                                    </button>
                                    <div class="btn-add-remove" *ngIf="serviceDetails?.isSelected">
                                        <i class="bi bi-trash" (click)="decrement()"></i>
                                        <span class="count">{{serviceDetails?.quantities}}</span>
                                        <i class="bi bi-plus-circle"
                                            [attr.data-target]="'#test'+serviceDetails?.service_id" data-toggle="modal"
                                            (click)="openPopup()"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="s-price-inner-item">
                                <button class="sub-service-btn" (click)="showAddRecurring = true">Subscribe</button>
                            </div> -->
                        </div>
                        <div class="s-section" *ngIf="serviceDetails.plans?.length > 0">
                            <h5 class="sub-text mb-3">Subscribe</h5>

                            <!-- <div class="subscription-on-wrapper">
                                <div class="radio-card" *ngFor="let item of subscriptionList"
                                    [ngClass]="{ 'selected': item === selectedSubscription }"
                                    (click)="selectSubscription(item)">
                                    <div class="radio-card-content text-center" (click)="showAddRecurring = true">
                                        <div class="radio-card-title">{{ item }}</div>
                                    </div>
                                </div>
                            </div> -->

                            <div class="subscription-on-wrapper">
                                <div class="radio-card" *ngFor="let plan of serviceDetails.plans"
                                    [ngClass]="{ selected: plan === selectedPlan }"
                                    (click)="selectPlan(plan);">
                                    <div class="radio-card-content text-center">
                                        <div class="radio-card-title">
                                            <span class="plan-name"> {{plan.name}}
                                                <span class="tenure">
                                                    ({{ plan.expire_in_months }} month)
                                                </span>
                                                <br>
                                                <span class="save-price">
                                                    (Save {{ plan.discount_percentage }}%)
                                                </span>
                                            </span>

                                            <span class="plan-name"
                                                *ngIf="plan.recurring_interval !== 'daily' && plan.recurring_interval !== 'weekly' && plan.recurring_interval !== 'monthly'">
                                                {{ plan.name }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                           



                            <!-- {{selectedSubscription}} -->

                            <div class="offers-section" *ngIf="false">
                                <span>
                                    <img src="assets/icons/offer-icon.svg" alt="">
                                </span>
                                <span class="offer-text">
                                    You can save
                                    <span class="saving-amt">
                                        £25 - £50
                                        <span class="text-dark">
                                            (30% OFF)
                                        </span>
                                    </span>
                                    with subscription.
                                </span>
                            </div>


                        </div>

                        <div>
                                <button class="s-add-btn mt-2" (click)="bookService()">
                                    <span>{{serviceDetails?.quote_only ? 'GET QUOTE': 'BOOK A SERVICE'}}</span>
                                </button>
                        </div>
                        <!-- <div class="divider"></div> -->
                    </div>
                </div>
                <div class="s-desc-wrapper">
                    <h5 class="s-desc-title">Description</h5>
                    <span class="s-desc-data" [innerHTML]="sanitizeHTML(serviceDetails?.description)"></span>
                </div>
                <!-- New Design starts -->

            </ng-container>
            <!-- <div class="heading-text">Service Description</div> -->
            <!-- <div class="desc-text">{{serviceDetails?.description}}</div> -->
            <!-- <div class="desc-text" [innerHTML]="sanitizeHTML(serviceDetails?.description)"></div> -->
        </div>
    </div>
</div>


<!--service selection-->
<ng-template #modelTemplate let-modal>
    <div class="location-modal-1" [attr.id]="'test'+serviceDetails?.service_id">
        <div class="">
            <div class="no-padding-modal">
                <div class="modal-header">
                    <!-- <i class="bi bi-arrow-left-short" (click)="backFn()" *ngIf="!showServicesPopup"></i>
                <i *ngIf="showServicesPopup"></i> -->
                    <i (click)="backFn()" *ngIf="!showServicesPopup"></i>
                    <i *ngIf="showServicesPopup"></i>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
                        (click)="closeModal">
                        <i class="bi bi-x"></i>
                    </button>
                </div>
                <!--service selection-->
                <div class="modal-body pt-0">
                    <ng-container>
                        <div class="title">Where do you want to get this service at?</div>
                        <div class="modal-service-info mt-3 mb-3">
                            <div class="img-modal">
                                <img [src]="serviceDetails.image">
                            </div>
                            <div class="s-name-modal">
                                <div class="service-title mt-3">{{serviceDetails?.title}}</div>
                                <div class="price-title mt-2">Price : {{serviceDetails?.min_charge | currency:
                                    currencyCode }}</div>
                            </div>
                        </div>
                        <div class="services">
                            <div class="at-location radio-custom-sb" (click)="getBranchAddressList()">
                                <input type="radio" id="at-location" name="type" value="location" class="radio-custom"
                                    [checked]="serviceDetails.avail_at === 'center_only'">
                                <label for="at-location" class="radio-custom-label">Service At Center</label>
                            </div>
                            <div class="at-home radio-custom-sb"
                                (click)="serviceDetails?.avail_at === 'center_only' ? '' :getCustomerAddressList()">
                                <input type="radio" id="at-home" name="type" value="home" class="radio-custom"
                                    [disabled]="serviceDetails.avail_at === 'center_only'">
                                <label for="at-home" class="radio-custom-label">Service At Home</label>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="showBranchModalPopup">
                        <div class="location-address-selection">
                            <div class="location-branch" [class.checkd-address]="serviceDetails?.selected">
                                <div class="check-loaction-icon" *ngIf="serviceDetails?.selected">
                                    <i class="bi bi-check-circle-fill"></i>
                                </div>
                                <div class="details-address">
                                    {{serviceDetails?.serviceprovider_branch.address?.address1}},
                                    {{serviceDetails?.serviceprovider_branch.address?.address2}},
                                    {{serviceDetails?.serviceprovider_branch.address?.land_mark}},
                                    {{serviceDetails?.serviceprovider_branch.address?.city}},
                                    {{serviceDetails.serviceprovider_branch.address?.state}},
                                    {{serviceDetails?.serviceprovider_branch.address?.country}},
                                    {{serviceDetails?.serviceprovider_branch.address?.post_code}}
                                </div>
                            </div>
                        </div>
                        <button class="sb-primary-btn w-100" (click)="showItemCount()">Done</button>
                    </ng-container>
                    <ng-container *ngIf="showAddAddress">
                        <div class="map">
                            <div class="search-map-container in-line">
                                <i class="bi bi-search"></i>
                                <input #mapSearchFiled2 class="search-map" type="text" />
                            </div>
                            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
                                <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true"
                                    (dragEnd)="markerDragEnd($event)"></agm-marker>
                            </agm-map>
                        </div>
                        <div class="content-right">
                            <div class="current-location"><i class="bi bi-geo-alt-fill"></i>{{userLocation}}
                            </div>
                            <div class="input">
                                <label>Name Your Address</label><br>
                                <input type="text" [(ngModel)]="addressLabel" />
                                <span class="error-text" *ngIf="addressLabel === '' && isSubmitted">Address label
                                    required</span>
                            </div>

                            <button class="save btn btn-primary mt-2" (click)="saveAddress()">Save</button>
                        </div>
                    </ng-container>

                    <!--at home model-->
                    <ng-container *ngIf="showHomeModalPopup">
                        <!-- <div class="title mt-3 mb-3">Continue with current location</div>
                <div class="location-info mb-3">
                    <i class="bi bi-geo-alt-fill"></i>
                    <span class="loaction-long-lat">{{location}}</span>
                </div> -->
                        <div class="address-suggestions mt-4">
                            <div class="addres-left">
                                <p class="saved">Saved Addresses</p>
                            </div>
                            <div class="addres-right">
                                <button class="add-new" (click)="openLocationPopup()">Add New</button>
                            </div>
                        </div>

                        <div class="location-address-selection serviceat-home mt-2" *ngIf="addressList">
                            <ng-container *ngFor="let item of addressList;let i = index;">
                                <div class="location-branch" [class.checkd-address]="item.selected"
                                    (click)="homeAddressSelection(item?.address?.address_id, addressList[i].address_type)">
                                    <div class="check-loaction-icon" *ngIf="item.selected">
                                        <i class="bi bi-check-circle-fill"></i>
                                    </div>
                                    <div class="details-address">
                                        <!-- <div class="name">Arjun Kumar</div> -->
                                        <div class="home-address-title mb-2">{{addressList[i].address_type}}</div>
                                        <div class="address-gray">
                                            {{item?.address?.address1}}, {{item?.address?.address2}},
                                            {{item?.address?.land_mark}}, {{item?.address?.city}},
                                            {{item?.address?.state}}, {{item?.address?.country}},
                                            {{item?.address?.post_code}}
                                        </div>
                                        <!-- <div class="address-gray">{{item?.address?.address2}}</div>
                                <div class="address-gray">{{item?.address?.land_mark}}</div>
                                <div class="address-gray">{{item?.address?.city}}</div>
                                <div class="address-gray">{{item?.address?.state}}, {{item?.address?.country}},
                                    {{item?.address?.post_code}}</div> -->
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <button class="sb-primary-btn w-100" (click)="showItemCount()">Done</button>
                    </ng-container>

                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="close-icon-mb-view text-center" *ngIf="showAddRecurring">
    <!-- <img src="assets/icons/addr-bar-close.svg" alt="" (click)="closeRecurringPopup()"> -->
    <img src="assets/icons/cross-right.svg" alt="" (click)="closeRecurringPopup()">
</div>


<div class="add-recurring-section" *ngIf="showAddRecurring">
       <app-schedule-popup [serviceDetails]="serviceDetails" [showAddRecurring]="showAddRecurring" 
       [selectBookAService]="selectBookAService" (close)="closePopup()"></app-schedule-popup>
</div>

<div class="mt-5">
    <app-footer></app-footer>
</div>
<section *ngIf="pop.value">
    <app-cart-pop [cartDetails]="pop"></app-cart-pop>
</section>