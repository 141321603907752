<div class="main-wrapper">
    <section class="hero-section">
        <nav>
            <div class="logo">My Logo</div>
            <input type="checkbox" id="click">
            <label for="click" class="menu-btn">
                <i class="fas fa-bars"></i>
            </label>
            <ul>
                <li><a href="#">Find a tutor</a></li>
                <li><a href="#">How it Works</a></li>
                <li><a href="#">Prices</a></li>
                <li><a href="#">Become a tutor</a></li>
                <li><a href="#">Blog</a></li>
            </ul>
            <div class="cart-wrapper">
                <!-- <a href="#" class="cart-sec"><img src="assets/icons/cart-icon.svg" alt=""></a> -->
                <!-- <button class="sign-up" type="button">Signup</button> -->
                <ul>
                    <a href="#" class="login-btn">Login</a>
                    <a href="#" class="sign-up">Signup</a>
                </ul>
            </div>
        </nav>

    </section>
</div>
<div class="container-fluid student-profile-wrapper">
    <h1 class="page-title">Welcome STUDENT</h1>
</div>