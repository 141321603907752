import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sports-based-them',
  templateUrl: './sports-based-them.component.html',
  styleUrls: ['./sports-based-them.component.scss']
})
export class SportsBasedThemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
