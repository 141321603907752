import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-limited-offers',
  templateUrl: './limited-offers.component.html',
  styleUrls: ['./limited-offers.component.scss']
})
export class LimitedOffersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
