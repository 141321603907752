import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutor-add-qualification',
  templateUrl: './tutor-add-qualification.component.html',
  styleUrls: ['./tutor-add-qualification.component.scss']
})
export class TutorAddQualificationComponent implements OnInit {
  showTextInput: boolean = false;
  selectedOption: string | undefined;
  constructor() { }

  ngOnInit(): void {
  }
  grade:any=[
    {name:'A+'},
    {name:'A'},
    {name:'B+'},
    {name:'B'},
    {name:'C+'},
    {name:'C'}
  ];
  options = [
    { label: 'Option 1', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Option 3', value: 'option3' },
  ];
}
