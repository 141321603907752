<div class="container breadcrumb m-t-100">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item c-p"><span>Home</span></li>
      <li class="breadcrumb-item c-p"><span>Plans & Pricing</span></li>
      <li class="breadcrumb-item active" aria-current="page">Register</li>
    </ol>
  </nav>
</div>
<section>

  <div class="container">
    <ng-wizard [config]="config" (stepChanged)="stepChanged($event)">
      <ng-wizard-step [title]="'Step 1  '" [description]="'Company Information'"
                      [canEnter]="isValidTypeBoolean" [canExit]="isValidFunctionReturnsBoolean.bind(this)">
        <section class="step-1">
          <div class="main-div">
            <div class="title">To get started, Let us know about your company.</div>
            <div class="sub-title">Please fill in the below details so that we could get in touch with you company.
            </div>

            <div class="input-cols">

              <div class="input-container">
                <div class="label">Company Name*</div>
                <input type="text" class="input-field">
              </div>
              <div class="input-container">
                <div class="label">Website (Optinal)</div>
                <input type="text" class="input-field">
              </div>
            </div>
            <div class="col2">

              <div class="input-container">
                <div class="label">Phone*</div>


                <input type="text" class="input-field">
              </div>
              <div class="input-container">
                <div class="label">Specialization</div>
                <input type="text" class="input-field">
              </div>
            </div>

            <div class="address-title">Address Details</div>
            <div class="address-cols">

              <div class="input-container">
                <div class="label">Address*</div>
                <input type="text" class="input-field">
              </div>
              <div class="input-container">
                <div class="label">Landmark</div>
                <input type="text" class="input-field">
              </div>
            </div>
            <div class="col2">

              <div class="input-container">
                <div class="label">City*</div>
                <input type="text" class="input-field">
              </div>
              <div class="input-container">
                <div class="label">PIN Code</div>
                <input type="text" class="input-field">
              </div>
            </div>
            <div class="col2">

              <div class="input-container">
                <div class="label">State</div>
                <input type="text" class="input-field">
              </div>

            </div>
            <button class="btn btn-primary m-t-30 continue f-s-20" (click)="showNextStep()">Continue</button>
          </div>
        </section>
      </ng-wizard-step>

      <ng-wizard-step [title]="'Step 2'" [description]="'Personal Information'">
        <section class="step-2">
          <div class="main-div">
            <div class="title">Now, tell us more about yourself.</div>
            <div class="sub-title">Please fill in the below details so that we could get in touch with you company.</div>

            <div class="input-cols">

              <div class="input-container">
                <div class="label">Full Name *</div>
                <input type="text" class="input-field">
              </div>
              <div class="input-container">
                <div class="label">Personal Email *</div>
                <input type="text" class="input-field">
              </div>
            </div>
            <div class="col2">

              <div class="input-container">
                <div class="label">Phone*</div>
                <input type="text" class="input-field">
              </div>

            </div>

            <div class="address-title">Address Details</div>
            <div class="address-cols">

              <div class="input-container">
                <div class="label">Address*</div>
                <input type="text" class="input-field">
              </div>
              <div class="input-container">
                <div class="label">Landmark</div>
                <input type="text" class="input-field">
              </div>
            </div>
            <div class="col2">

              <div class="input-container">
                <div class="label">City*</div>
                <input type="text" class="input-field">
              </div>
              <div class="input-container">
                <div class="label">PIN Code</div>
                <input type="text" class="input-field">
              </div>
            </div>
            <div class="col2">

              <div class="input-container">
                <div class="label">State</div>
                <input type="text" class="input-field">
              </div>

            </div>
            <div class="buttons-block">
              <button class=" m-t-30 back f-s-20" (click)="showPreviousStep()">Back</button>
              <button class="btn btn-primary m-t-30 continue f-s-20" (click)="showNextStep()">Continue</button>
            </div>


          </div>
        </section>
      </ng-wizard-step>


      <ng-wizard-step [title]="'Step 3'" [description]="'Confirmation'">
        <section class="step-3">
          <div class="success">
          <div class="success-content">
            <div class="head"><i class="bi bi-info-circle"></i>You’ve started your free trial.</div>
            <div class="content">Remaining : 30 Days <br>Please feel free to review your experience during this period.<br> <br>Mail us at : <a href="mailto:contact@productname.in" >contact@productname.in </a></div>
          </div>
          </div>



        </section>
      </ng-wizard-step>
    </ng-wizard>
  </div>


  <!--  <div class="container">-->
  <!--    <div class="content-box">-->

  <!--      <div class="content" *ngIf="!status.stepOne">-->
  <!--        <img src="./assets/images/Ellipse%2047.png " alt="icon">-->
  <!--        <span class="info">-->
  <!--    <div class="steps">-->
  <!--      Step 1/4-->
  <!--    </div>-->
  <!--    <div class="head">Company Information</div>-->
  <!--  </span>-->
  <!--        <div class="line"></div>-->

  <!--      </div>-->
  <!--      <div class="content" *ngIf="status.stepOne">-->
  <!--        <img src="./assets/images/success%20check.svg " alt="icon">-->
  <!--        <span class="info">-->
  <!--    <div class="steps completed">-->
  <!--      Completed-->
  <!--    </div>-->
  <!--    <div class="head">Company Information</div>-->
  <!--  </span>-->
  <!--        <div class="line"></div>-->

  <!--      </div>-->
  <!--&lt;!&ndash;      step2&ndash;&gt;-->
  <!--      <div class="content"*ngIf="!status.stepTwo" >-->
  <!--        <img src="./assets/images/Ellipse%2047.png " alt="icon">-->
  <!--        <span class="info">-->
  <!--    <div class="steps">-->
  <!--      Step 2/4-->
  <!--    </div>-->
  <!--    <div class="head">Personal Information</div>-->
  <!--  </span>-->
  <!--        <div class="line"></div>-->

  <!--      </div>-->
  <!--      <div class="content"  *ngIf="status.stepTwo">-->
  <!--        <img src="./assets/images/success%20check.svg " alt="icon">-->
  <!--        <span class="info">-->
  <!--    <div class="steps completed">-->
  <!--    Completed-->
  <!--    </div>-->
  <!--    <div class="head">Personal Information</div>-->
  <!--  </span>-->
  <!--        <div class="line"></div>-->

  <!--      </div>-->

  <!--&lt;!&ndash;      step 3&ndash;&gt;-->

  <!--      <div class="content" *ngIf="!status.stepThree">-->
  <!--        <img src="./assets/images/Ellipse%2047.png " alt="icon">-->
  <!--        <span class="info">-->
  <!--    <div class="steps">-->
  <!--      Step 3/4-->
  <!--    </div>-->
  <!--    <div class="head">Booking Information</div>-->
  <!--  </span>-->
  <!--        <div class="line"></div>-->

  <!--      </div>-->
  <!--      <div class="content" *ngIf="status.stepThree">-->
  <!--        <img src="./assets/images/success%20check.svg " alt="icon">-->
  <!--        <span class="info">-->
  <!--    <div class="steps completed">-->
  <!--      Completed-->
  <!--    </div>-->
  <!--    <div class="head">Booking Information</div>-->
  <!--  </span>-->
  <!--        <div class="line"></div>-->

  <!--      </div>-->


  <!--      <div class="content">-->
  <!--        <img src="./assets/images/Ellipse%2047.png " alt="icon">-->
  <!--        <span class="info">-->
  <!--    <div class="steps">-->
  <!--      Step 4/4-->
  <!--    </div>-->
  <!--    <div class="head">Confirmation</div>-->
  <!--  </span>-->


  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</section>


<app-footer></app-footer>
