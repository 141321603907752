import {  Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { RouteConstants } from 'src/app/model/route-constants';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-provider-page',
  templateUrl: './provider-page.component.html',
  styleUrls: ['./provider-page.component.scss']
})
export class ProviderPageComponent implements OnInit {
  servicesList: any;
  currencyCode = environment.currencyCode;

  constructor(public dataService: DataService, 
    public router: Router) {
  }

  
  ngOnInit(): void {
    this.getProviderServiceList();
  }


  getProviderServiceList = () => {
    const reqObj = {
      providerId: sessionStorage.getItem("SERVICE_PROVIDER"),
      topServices: 4
    }
    this.dataService.getTopFourServicesList(reqObj).subscribe((data: any) => {
      if (data) {
        this.servicesList = data.results;
      }
    });
  }

  gotoServiceDetailsPage = (serviceObj:any) => {
    this.router.navigate([RouteConstants.SERVICE_DETAILS, serviceObj.service_category, serviceObj.slug_title]);
  }

}
