import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class DomainInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Add the domain name to the request headers or parameters
    const domain = window.location.hostname;
    request = request.clone({
      setHeaders: {
        'X-Domain': domain,
      },
    });
    return next.handle(request);
  }
}
