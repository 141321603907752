import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stay-home',
  templateUrl: './stay-home.component.html',
  styleUrls: ['./stay-home.component.scss']
})
export class StayHomeComponent implements OnInit {
  val2: number = 3;
  constructor() { }

  ngOnInit(): void {
  }

}
