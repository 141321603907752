<div class="container choose-wrap">
    <h3><img src="assets/icons/arrow-left-t.svg" alt="" [routerLink]="['/student/signup']"> &nbsp;In which<span class="s-wrap"> subject</span> do you require assistance?</h3>
    
    <!-- <form class="nosubmit">
      <input class="nosubmit" type="search" placeholder="Search..." [(ngModel)]="searchTerm" (input)="onInputChange()">
     
    </form>  -->
<div class="s-subject">    <ng-select [items]="subjects" bindLabel="name" [multiple]="true" [placeholder]="'Search....'"></ng-select>
</div>

    <!-- <form [formGroup]="searchForm">
      <input formControlName="searchInput" type="text" placeholder="Search">
    </form>
    <ul *ngIf="filteredSuggestions.length > 0">
      <li *ngFor="let suggestion of filteredSuggestions">{{ suggestion }}</li>
    </ul>
    <div *ngIf="searchInput.invalid && searchInput.touched">
      Please enter a valid search term. -->
    <!-- </div> -->
    <!-- <ul *ngIf="suggestedNames?.length">
      <li *ngFor="let name of suggestedNames">{{ name }}</li>
    </ul> -->

    <div class="grid-container">
        <div *ngFor="let box of boxes; let i = index" class="box" [class.selected]="isSelected(i)" (click)="toggleSelection(i)">
          {{ box.label }}
          <img src="assets/icons/subject-check.svg" alt="" *ngIf="isSelected(i)" class="tick-i">
        </div>
      </div>
   
  <div class="container n-wrapper">
    <div class="skip-wrap">
      <a href="#">Skip</a>
    </div>
  <div class="b-wrap">

 <button class="next-btn" type="button"[routerLink]="['/student/signup/success']">Submit </button>
    </div>
    
  </div>
      
</div>


<!-- <form>
  <input type="text" placeholder="Search..." [(ngModel)]="searchTerm" (input)="onInputChange()">
  <ul *ngIf="showSuggestions">
    <li *ngFor="let suggestion of suggestions" (click)="onSuggestionClick(suggestion)">
      {{ suggestion }}
    </li>
  </ul>
</form> -->
