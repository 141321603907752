import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-book-lessons',
  templateUrl: './book-lessons.component.html',
  styleUrls: ['./book-lessons.component.scss']
})
export class BookLessonsComponent implements OnInit {

  personalDetails!: FormGroup;
  addressDetails!: FormGroup;
  educationalDetails!: FormGroup;
  personal_step = false;
  address_step = false;
  education_step = false;
  step = 1;
  selectedTutor: any;
  selectedSubject: any;
  selectedBoard:any;
  selectedPrice:any;
  router: any;

  text1 = 'Text 1';
  inputValue: string | undefined;
  selectedOption: string = '';
  isSelected1: boolean = false;
  isSelected2: boolean = false;
  isSelected3: boolean = false;

  showOption1 = true;
  showOption2 = false;
  textInput: string | undefined;
  showText() {
    this.showOption1 = true;
    this.showOption2 = false;
  }

  showTextInput() {
    this.showOption1 = false;
    this.showOption2 = true;
  }

  changeOption(option: string) {
    this.selectedOption = option;
  }
  selectedOption1: string = '';

changeOption1(option: string) {
  this.selectedOption = option;
}
changeBox1() {
  this.isSelected1 = true;
  this.isSelected2 = false;
  this.isSelected3 = false;
}

changeBox2() {
  this.isSelected1 = false;
  this.isSelected2 = true;
  this.isSelected3 = false;
}

changeBox3() {
  this.isSelected1 = false;
  this.isSelected2 = false;
  this.isSelected3 = true;
}




  constructor(private formBuilder: FormBuilder) {
   
  
   }
  ngOnInit() {
    this.personalDetails = this.formBuilder.group({
      // tutor: ['', Validators.required],
      subject: ['', Validators.required],
      // name: ['', Validators.required],
      // email: ['', Validators.required],
      // phone: ['',Validators.required]
    });
    this.addressDetails = this.formBuilder.group({
      day: ['', Validators.required],
      dayone:['',Validators.required],
      time: ['', Validators.required],
      // city: ['', Validators.required],
      // address: ['', Validators.required],
      // pincode: ['',Validators.required]
    });
    this.educationalDetails = this.formBuilder.group({
      highest_qualification: ['', Validators.required],
      university: ['', Validators.required],
      total_marks: ['', Validators.required]
    });

    
  }

  TutorsList: any = [
    { id: '1', name: 'John Dee' },
    { id: '2', name: 'Anastasia B' },
    { id: '3', name: 'Johnson Roy' }
  ]

  subjectsList: any = [
    { id: '1', name: 'History of Art - A Level' },
    { id: '2', name: 'English Literature - GCSE' },
    { id: '3', name: 'Drama - GCSE' }
  ]
  boardList: any = [
    { id: '1', name: 'State' },
    { id: '2', name: 'Central' },
    { id: '3', name: 'Others' }
  ]

  get personal() { return this.personalDetails.controls; }
  get education() { return this.educationalDetails.controls; }
  get address() { return this.addressDetails.controls; }
  next() {
    if (this.step == 1) {
      this.personal_step = true;
      if (this.personalDetails.invalid) { return }
      this.step++
    }
    if (this.step == 2) {
      this.address_step = true;
      if (this.addressDetails.invalid) { return }
      this.step++;
    }
  }
  previous() {
    this.step--
    if (this.step == 1) {
      this.personal_step = false;
    }
    if (this.step == 2) {
      this.education_step = false;
    }
  }
  submit() {
    if (this.step == 3) {
      this.education_step = true;
      if (this.educationalDetails.invalid) { return }
    }
  }
}
function onOptionSelected(event: Event | undefined, any: any) {
  throw new Error('Function not implemented.');
}

