<div class="bg-color-of-pages">
    <div class="container mt-5">
        <div class="service-details-data" *ngIf="serviceDetails">
            <!-- Repeat Data -->
            <ng-container>
               <div class="mb-2">
                     <button class="sb-back-btn mr-2 col-12 col-sm-4 col-md-3 col-lg-2" (click)="backFn()" type="button"> 
                 <i class="bi bi-arrow-left-short"></i> Back</button>
               </div>
                <!-- New Design starts -->
                <div class="main-s-details-wrapper">
                    <div class="inner-s-details-wrapper">
                        <div class="serice-img-blcok">
                            <img [src]="serviceDetails.image">
                        </div>
                    </div>
                    <div class="inner-s-details-wrapper" id="inner-s-desc-wrapper">
                        <div class="s-title-btn">
                            <div class="s-title-section">
                                <div class="s-name">{{serviceDetails?.title}}</div>
                                <p class="avail-at-text mt-2" *ngIf="serviceDetails?.avail_at == 'home'">Avail at
                                    Door-step</p>
                                <p class="avail-at-text mt-2" *ngIf="serviceDetails?.avail_at == 'center_only'">Avail at
                                    Center</p>
                                <p class="avail-at-text mt-2" *ngIf="serviceDetails?.avail_at == 'both'">Avail at
                                    Door-step & Center</p>
                            </div>

                        </div>
                        <div class="review-wrapper mt-2" *ngIf="serviceDetails">
                            <div class="rating-wrapper">
                                <div class="rating-sec" (click)="gotoReviewPage()">
                                    <i class="bi bi-star-fill disable-rating"
                                        [ngClass]="{'disable-rating' :  1 <= serviceDetails?.avg_rating}"></i>
                                    <i class="bi bi-star-fill disable-rating"
                                        [ngClass]="{'disable-rating' :  2 <= serviceDetails?.avg_rating}"></i>
                                    <i class="bi bi-star-fill disable-rating"
                                        [ngClass]="{'disable-rating' :  3 <= serviceDetails?.avg_rating}"></i>
                                    <i class="bi bi-star-fill disable-rating"
                                        [ngClass]="{'disable-rating' :  4 <= serviceDetails?.avg_rating}"></i>
                                    <i class="bi bi-star-fill disable-rating"
                                        [ngClass]="{'disable-rating' :  5 <= serviceDetails?.avg_rating}"></i>
                                    <span class="rate-number">{{serviceDetails?.avg_rating}}</span>
                                </div>
                            </div>
                            <div class="v-divider"> | </div>
                            <div class="reviews-wrapper" (click)="gotoReviewPage()">
                                {{serviceDetails?.review_count}} Reviews
                            </div>
                        </div>
                        <div class="s-price-wrapper mt-3">
                            <div class="s-price-inner-item">
                                <div class="price-tag" *ngIf="!serviceDetails?.quote_only">
                                    <span class="s-price-main" *ngIf="!selectedPlan">
                                        {{ serviceDetails?.min_charge | currency: currencyCode:'symbol':'1.0-0' }}
                                    </span>
                                    <div class="price-tag">
                                        <span class="s-price-main" *ngIf="selectedPlan">
                                            {{ selectedPlan.amount | currency: currencyCode:'symbol':'1.0-0' }}
                                        </span>
                                    </div>
    
                                </div>
                                <div class="price-tag" *ngIf="serviceDetails?.quote_only">
                                    <span class="s-price-main">
                                        {{serviceDetails?.min_charge | currency: currencyCode:'symbol':'1.0-0' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="distance-wrapper mt-3">
                            <div class="km-info pt-1">
                                <img alt="" src="assets/icons/distance.svg">
                                {{distance}} miles
                            </div>
                            <div class="sb-dropdonws mb-2" #myDrop ngbDropdown placement="bottom-left">
                                <button class="dropdown-input" ngbDropdownToggle
                                    (click)="changeDropdownValue()">{{serviceDetails?.serviceprovider_branch?.service_branch_name}}
                                    <i class="bi bi-caret-down-fill ml-2"></i></button>
                                <ul class="dropdown-menu" ngbDropdownMenu>
                                    <li ngbDropdownItem *ngFor="let item of branchList">
                                        <a class="dropdown_item"
                                            (click)="changeDropdownValue()">{{item?.branch?.address?.address1}} <div
                                                class="kminfo"><img src="assets/icons/map-location.svg">
                                                {{item?.distance}} miles</div></a>
                                    </li>
                                </ul>
                            </div> -->
                    </div>
                  


                </div>
                <div class="s-desc-wrapper">
                    <h5 class="s-desc-title">Description</h5>
                    <span class="s-desc-data" [innerHTML]="sanitizeHTML(serviceDetails?.description)"></span>
                </div>

                <!-- <div class="heading-text">Service Description</div> -->
                <!-- <div class="desc-text">{{serviceDetails?.description}}</div> -->
                <!-- <div class="desc-text" [innerHTML]="sanitizeHTML(serviceDetails?.description)"></div> -->
            </ng-container>
        </div>
    </div>
</div>