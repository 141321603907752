<app-tutor-header></app-tutor-header>
<div class="container book-lesson-wrapper">
    <div class="row mt-5">
        <div class="col-md-12 col-md-offset-3">
            <form [formGroup]="personalDetails" id="msform">
                <!-- progressbar -->
                <ul id="progressbar">
                    <li [ngClass]="step>=1 ? 'active ' : 'inactive'">Subject-Level and Book The Session</li>
                    <li [ngClass]="step>=2 ? 'active' : 'inactive'">Day and time</li>
                    <li [ngClass]="step==3 ? 'active' : 'inactive'">Preview</li>
                </ul>
                <!-- fieldsets -->
                
    
                <fieldset *ngIf="step == 1">
                    <h2 class="fs-title mt-3 mb-5">How can Avinash Jain help?</h2>

                    <!-- <label for="">Tutor</label>
                    <ng-select
                            [items]="TutorsList"
                            bindLabel="name"
                            [multiple]="false"
                            [(ngModel)]="selectedTutor"
                            formControlName="tutor"
                            [ngClass]="{ 'is-invalid': personal_step && personal.tutor.errors }"
                            >
                    </ng-select>

                    <div *ngIf="personal_step && personalDetails.controls.tutor.errors" class="error-text">
                        <div *ngIf="personal.tutor.errors?.required">Please select tutor</div>
                    </div> -->

                    <label for="" class="mt-3">Subject and level</label>
                    <ng-select
                            [items]="subjectsList"
                            bindLabel="name"
                            [multiple]="false"
                            [(ngModel)]="selectedSubject"
                            formControlName="subject"
                            [ngClass]="{ 'is-invalid': personal_step && personal.subject.errors }"
                            >
                    </ng-select>
                    <label for="" class="mt-3">Exam Board</label>
                    <ng-select
                            [items]="boardList"
                            bindLabel="name"
                            [multiple]="false"
                            [(ngModel)]="selectedBoard"
                            formControlName="subject"
                            [ngClass]="{ 'is-invalid': personal_step && personal.subject.errors }"
                            >
                    </ng-select>
                    

                    <div *ngIf="personal_step && personalDetails.controls.subject.errors" class="error-text">
                        <div *ngIf="personal.subject.errors?.required">Please select subject and level</div>
                    </div>
                    
             <!-- <label for="" class="mt-3">Price</label>
  
                    
                    <input type="text" formControlName="name"
                        [ngClass]="{ 'is-invalid': personal_step && personal.name.errors }" placeholder="Enter the Price " />
                    <div *ngIf="personal_step && personalDetails.controls.name.errors" class="error-text">
                        <div *ngIf="personal.name.errors?.required">price is required</div>
                    </div> -->
                   <!--  <input type="text" formControlName="email" name="email" placeholder="Email" />
                    <div *ngIf="personal_step && personalDetails.controls.email.errors" class="error-text">
                        <div *ngIf="personal.email.errors?.required">Email is required</div>
                    </div>
                    <input type="text" formControlName="phone" name="phone" placeholder="Phone" />
                    <div *ngIf="personal_step && personalDetails.controls.phone.errors" class="error-text">
                        <div *ngIf="personal.phone.errors?.required">Phone is required</div>
                    </div> -->
                    <div class="text-left mt-4">
                        <input (click)="next()" type="button" name="next" class="next action-button" value="Next" />
                    </div>
                </fieldset>
            </form>
            <form [formGroup]="addressDetails" id="msform">
                <fieldset *ngIf="step == 2">
                    <h2 class="fs-title mt-3">What type of booking do you want?</h2>


                     <!-- radio Cards -->
                     <div class="booking-type">
                        <div class="grid-wrapper grid-col-auto row">
                            <div class="col-md-6 col-12">
                          <label for="radio-card-1" class="radio-card">
                            <input type="radio" name="radio-card" id="radio-card-1"   (click)="showText()"checked/>
                
                       
                            <div class="card-content-wrapper">
                              <span class="check-icon"></span>
                              <div class="card-content">
                                <h3 class="card-heading">Regular Sessions</h3>

                                <ul>
                                    <li><span>Have lessons once a week, twice a week, or fortnightly</span></li>
                                    <li><span>Reserve your favourite slots with Anastasia</span></li>
                                    <li><span>Reschedule or cancel any time</span></li>
                                </ul>
                              </div>
                            </div>
                          </label>
                        </div>
                          <!-- /.radio-card -->
                          <div class="col-md-6 col-12">
                          <label for="radio-card-2" class="radio-card">
                            <input type="radio"(click)="showTextInput()"  name="radio-card" id="radio-card-2" />
                            <div class="card-content-wrapper">
                              <span class="check-icon"></span>
                              <div class="card-content">
                                <h3 class="card-heading">One-off-Session</h3>
                                <ul>
                                    <li><span>Handy for last minute revision - or if you haven’t got a regular schedule</span></li>
                                    <li><span>Reschedule or cancel any time</span></li>
                                </ul>
                              </div>
                            </div>
                          </label>
                          </div>
                        </div>
                      </div>
                 



                      <h2 class="fs-title mt-3">Select the time and duration of the course.</h2>
                      <div *ngIf="showOption1">
                      <div class="row mt-5">
                        <div class="col-md-4">
<h4 class="picker-date">From</h4>
<input type="date" formControlName="day">
<div *ngIf="address_step && addressDetails.controls.day.errors" class="error-text-d">
    <div *ngIf="address.day.errors?.required"> star date  is required</div>
</div>
                        </div>
                        <div class="col-md-4">
                            <h4 class="picker-date">To</h4>
                            <input type="date" formControlName="dayone">
                            <div *ngIf="address_step && addressDetails.controls.dayone.errors" class="error-text-d">
                                <div *ngIf="address.dayone.errors?.required">end date  is required</div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <h4 class="picker-date">Select Time</h4>
                            <input type="time" formControlName="time">
                            <div *ngIf="address_step && addressDetails.controls.time.errors" class="error-text-d">
                                <div *ngIf="address.time.errors?.required">Time is required</div>
                            </div>
                        </div>
                      </div>
                 
                      <div class="days-wrapper">
                     <div>
                             <input type="checkbox" id="checkbox1">
                                <label for="checkbox1" class="check-label">Mon</label>
                       </div>
                        <div>
                            <input type="checkbox" id="checkbox1">
                            <label for="checkbox1" class="check-label">Tue</label>
                        </div>
                        <div>
                            <input type="checkbox" id="checkbox1">
                            <label for="checkbox1" class="check-label">Wed</label>
                        </div>
                        <div>
                            <input type="checkbox" id="checkbox1">
                            <label for="checkbox1" class="check-label">Thu</label>
                        </div>
                        <div>
                            <input type="checkbox" id="checkbox1">
                            <label for="checkbox1" class="check-label">Fri</label>
                        </div>
                        <div>
                            <input type="checkbox" id="checkbox1">
                            <label for="checkbox1" class="check-label">Sat</label>
                        </div>
                        <div>
                            <input type="checkbox" id="checkbox1">
                            <label for="checkbox1" class="check-label">Sat</label>
                        </div>
                      </div>
                      <div class="row book-time d-none">
                        <table class="table table-bordered mt-5">
                            <thead class="text-center">
                                <tbody class="text-center tutor-availability">
                                    <tr>
                             
                                      <td><input type="checkbox" checked>Monday</td>
                                   <td>Monday</td>
                                   <td>    <div>
                                    <input type="time" formControlName="time">
                                    <div *ngIf="address_step && addressDetails.controls.time.errors" class="error-text-d">
                                        <div *ngIf="address.time.errors?.required">Time is required</div>
                                    </div>
                                  </div></td>
                                      <!-- <td>
                                          <input type="date" formControlName="day">
                                          <div *ngIf="address_step && addressDetails.controls.day.errors" class="error-text-d">
                                              <div *ngIf="address.day.errors?.required">date  is required</div>
                                          </div></td>
                                      <td>  
                                          <input type="time" formControlName="time">
                                          <div *ngIf="address_step && addressDetails.controls.time.errors" class="error-text-d">
                                              <div *ngIf="address.time.errors?.required">Time is required</div>
                                          </div></td> -->
                                    </tr>
                               
                                     <tr>
                                      <td><input type="checkbox" checked></td>
                                      <td>Tuesday</td>
                                      <td>    <div>
                                        <input type="time" formControlName="time">
                                        <div *ngIf="address_step && addressDetails.controls.time.errors" class="error-text-d">
                                            <div *ngIf="address.time.errors?.required">Time is required</div>
                                        </div>
                                      </div></td>
                                    </tr>
                                    <tr>
                                      
                                      <td><input type="checkbox" checked></td>
                                   <td>Wednesday</td>
                                   <td>    <div>
                                    <input type="time" formControlName="time">
                                    <div *ngIf="address_step && addressDetails.controls.time.errors" class="error-text-d">
                                        <div *ngIf="address.time.errors?.required">Time is required</div>
                                    </div>
                                  </div></td>
                                    </tr>
                                    <tr>
                                      
                                        <td><input type="checkbox" checked></td>
                                     <td>Thrusday</td>
                                     <td>    <div>
                                      <input type="time" formControlName="time">
                                      <div *ngIf="address_step && addressDetails.controls.time.errors" class="error-text-d">
                                          <div *ngIf="address.time.errors?.required">Time is required</div>
                                      </div>
                                    </div></td>
                                      </tr>
                                      <tr>
                                      
                                        <td><input type="checkbox" checked></td>
                                     <td>Friday</td>
                                     <td>    <div>
                                      <input type="time" formControlName="time">
                                      <div *ngIf="address_step && addressDetails.controls.time.errors" class="error-text-d">
                                          <div *ngIf="address.time.errors?.required">Time is required</div>
                                      </div>
                                    </div></td>
                                      </tr>
                                      <tr>
                                      
                                        <td><input type="checkbox" checked></td>
                                     <td>Saturday</td>
                                     <td>    <div>
                                      <input type="time" formControlName="time">
                                      <div *ngIf="address_step && addressDetails.controls.time.errors" class="error-text-d">
                                          <div *ngIf="address.time.errors?.required">Time is required</div>
                                      </div>
                                    </div></td>
                                      </tr>
                                      <tr>
                                      
                                        <td><input type="checkbox" checked></td>
                                     <td>Sunday</td>
                                     <td>    <div>
                                      <input type="time" formControlName="time">
                                      <div *ngIf="address_step && addressDetails.controls.time.errors" class="error-text-d">
                                          <div *ngIf="address.time.errors?.required">Time is required</div>
                                      </div>
                                    </div></td>
                                      </tr>
                                  </tbody>
                                                                
                        </table>
              
                      </div>
                      </div>
             
                      <div *ngIf="selectedOption === 'text'">
                        <h2 class="fs-title mt-3 mb-4">How often do you want lessons?</h2>
                        <!---------------->
                        <div class="">
                        <div class="grid-wrapper grid-col-auto">
                            <div class="row d-none">
                                <div class="col-md-4">
                            <label for="radio-card-01" class="radio-card">
                                <input type="radio" name="radio-card" id="radio-card-01" checked />
                                <div class="card-content-wrapper">
                                    <span class="check-icon"></span>
                                    <div class="card-content">
                                   
                                      <h4>Lorem ipsum dolor.</h4>
                                      <h5>Lorem ipsum dolor sit amet, consectetur.</h5>
                                    </div>
                                </div>
                            </label>
                                </div>
                                <div class="col-md-4">                            <label for="radio-card-02" class="radio-card">
                                <input type="radio" name="radio-card" id="radio-card-02" />
                                <div class="card-content-wrapper">
                                  <span class="check-icon"></span>
                                  <div class="card-content">
                              
                                    <h4>Lorem ipsum dolor.</h4>
                                    <h5>Lorem ipsum dolor sit amet, consectetur.</h5>
                                  </div>
                                </div>
                              </label>
                                </div>
                                <div class="col-md-4">
                              <label for="radio-card-03" class="radio-card">
                                <input type="radio" name="radio-card" id="radio-card-03" />
                                <div class="card-content-wrapper">
                                  <span class="check-icon"></span>
                                  <div class="card-content">
                              
                                    <h4>Lorem ipsum dolor.</h4>
                                    <h5>Lorem ipsum dolor sit amet, consectetur.</h5>
                                  </div>
                                </div>
                              </label>
                                </div>
                            </div>
<div class="row select-cart">
    <div class="col-md-4">
        <div class="box" (click)="changeBox1()" [ngClass]="{ 'selected': isSelected1 }"value="text" (change)="changeOption('text')"> Once A Week</div>
       
     
    </div>
    <div class="col-md-4">
        <div class="box" (click)="changeBox2()" [ngClass]="{ 'selected': isSelected2 }"value="text" (change)="changeOption('text')">Twice A week</div>
    </div>
    <div class="col-md-4">
        <div class="box" (click)="changeBox3()" [ngClass]="{ 'selected': isSelected3 }"value="text" (change)="changeOption('text')">Thrice A week</div>
    </div>
</div>
                        </div>
                        </div>
                        
                        <!----------------->
                      </div>
                      
                      
                      <div *ngIf="selectedOption === 'textinput'">
                        
                    <h2 class="fs-title mt-3 mb-4">Choose date and time</h2>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="">Pick a day</label>
                                <input type="date" formControlName="day">
                                <div *ngIf="address_step && addressDetails.controls.day.errors" class="error-text">
                                    <div *ngIf="address.day.errors?.required">City is required</div>
                                </div>
                      </div>
                      <div class="col-md-6">
                        <label for="">Pick a time</label>
                        <input type="time" formControlName="time">
                        <div *ngIf="address_step && addressDetails.controls.day.errors" class="error-text">
                            <div *ngIf="address.day.errors?.required">City is required</div>
                        </div>

                      </div>
                        </div>
                      </div>
                    <!-- radio cards -->

                    <!-- <h2 class="fs-title mt-3 mb-4">Choose date and time</h2> -->

                   


                  
                      
                      <div *ngIf="showOption2">
                        <div class="row mt-5">
                            <div class="col-md-6">
    <h4 class="picker-date">From</h4>
    <input type="date" formControlName="day">
    <div *ngIf="address_step && addressDetails.controls.day.errors" class="error-text-d">
        <div *ngIf="address.day.errors?.required"> star date  is required</div>
    </div>
                            </div>
                            <!-- <div class="col-md-4">
                                <h4 class="picker-date">To</h4>
                                <input type="date" formControlName="dayone">
                                <div *ngIf="address_step && addressDetails.controls.dayone.errors" class="error-text-d">
                                    <div *ngIf="address.dayone.errors?.required">end date  is required</div>
                                </div>
                            </div> -->
                            <div class="col-md-6">
                                <h4 class="picker-date">Select Time</h4>
                                <input type="time" formControlName="time">
                                <div *ngIf="address_step && addressDetails.controls.time.errors" class="error-text-d">
                                    <div *ngIf="address.time.errors?.required">Time is required</div>
                                </div>
                            </div>
                          </div>
                          <!-- <p class="shedule-w">Pick the days for the session's schedule.</p> -->
                      
                      </div>

                      
                    <!-- <input type="text" formControlName="city" placeholder="City" />
                    <div *ngIf="address_step && addressDetails.controls.city.errors" class="error-text">
                        <div *ngIf="address.city.errors?.required">City is required</div>
                    </div>
                    <textarea rows="5" cols="5" formControlName="address" placeholder="address"></textarea>
                    <div *ngIf="address_step && addressDetails.controls.address.errors" class="error-text">
                        <div *ngIf="address.address.errors?.required">Address is required</div>
                    </div>
                    <input type="text" formControlName="pincode" placeholder="Pincode" />
                    <div *ngIf="address_step && addressDetails.controls.pincode.errors" class="error-text">
                        <div *ngIf="address.pincode.errors?.required">Pincode is required</div>
                    </div> -->
                    <div class="text-left">
                        <input (click)="previous()" type="button" name="previous" class="previous action-button-previous"
                        value="Previous" />
                    <input (click)="next()" type="button" name="next" class="next action-button" value="Next" />
                    </div>
                </fieldset>
            </form>
            <form [formGroup]="addressDetails" id="msform">
                <fieldset *ngIf="step == 3">
                    <h2 class="fs-title mt-3 mb-4">View your details</h2>

                    
                    <div class="view-details-wrapper">
                        <div class="row">
                            <div class="col-md-6">
                                <div>
                                <p class="d-label text-left">Tutor:
                                <span class="selected-value text-left">Anastasia B</span></p>
                            </div>
                            <div>                                <p class="d-label text-left">Student:
                                <span class="selected-value text-left">NAVEEN K</span></div>
                            <div class="mt-3">                                <p class="d-label text-left">Subject :
                            <span class="selected-value text-left">English Literature - GCSE</span></p>

                        </div>
                        <div class="mt-3">                                <p class="d-label text-left">Exam Board :
                            <span class="selected-value text-left">English Literature - GCSE</span>
                        </div>
                        
                            <div class="mt-3">
                                <p class="d-label text-left">Total :
                                <span class="selected-value text-left">35$</span>
                            </div>
                        </div>
                            <div class="col-md-6">
                             
                                <p class="d-label text-left">Star Date :
                             <span class="selected-value text-left">13/02//2023</span></p>
                             <p class="d-label text-left">End date :
                                <span class="selected-value text-left">23/02//2023</span></p>
                                <p class="d-label text-left">Time:
                                    <span class="selected-value text-left">6:00PM</span></p>
                                <p class="selected-value text-left"></p>
                                <div class="row">
                                    <div class="col-md-4 col-4">
                                        <p class="week-d">Mon</p>
                                        <p class="week-d">Thu</p>
                                        <p class="week-d">Sun</p>
                                    </div>
                                    <div class="col-md-4 col-4">
                                        <p class="week-d"> Tue </p>
                                        <p class="week-d">Fri</p>
                                    </div>
                                    <div class="col-md-4 col-4">
                                        <p class="week-d">  Wed</p>
                                        <p class="week-d">Sat</p>
                                    </div>
                                </div>
                            </div>
                 
                            <!-- <div class="col-md-4">
                           
                            </div> -->
                            <!-- <div class="col-md-4">
                   
                            </div> -->
                            <!-- <div class="col-md-4">
                                <p class="d-label text-left">Price</p>
                                <p class="selected-value text-left">35$</p>
                            </div> -->
                        </div>
                    </div>

                    <!-- <input type="text" formControlName="highest_qualification" placeholder="Highest Qualification" />
                    <div *ngIf="education_step && educationalDetails.controls.highest_qualification.errors" class="error-text">
                        <div *ngIf="education.highest_qualification.errors?.required">Highest qualification is required
                        </div>
                    </div>
                    <input type="text" formControlName="university" placeholder="University" />
                    <div *ngIf="education_step && educationalDetails.controls.university.errors" class="error-text">
                        <div *ngIf="education.university.errors?.required">University is required</div>
                    </div>
                    <input type="text" formControlName="total_marks" placeholder="Total Marks" />
                    <div *ngIf="education_step && educationalDetails.controls.total_marks.errors" class="error-text">
                        <div *ngIf="education.total_marks.eyrrors?.required">Total marks is required</div>
                    </div> -->
                    <div class="text-left mt-3">
                        <input (click)="previous()" type="button" name="previous" class="previous action-button-previous"
                        value="Previous" />
                    <input [routerLink]="['/student/confirmation']" type="submit" name="submit" class="submit action-button" value="Confirm" />
                    </div>
                </fieldset>
            </form>
    
        </div>
    </div>
</div>