<!-- <div class="service-box-container row" *ngIf="serviceListObj">
  <div class="card-colum w-100">
    <div class="inner-data-details">
      <div class="img-block-top">
        <img [src]="serviceListObj.image" (click)="gotoServiceDetailsPage()">
      </div>
      <div class="main-title" (click)="gotoServiceDetailsPage()">{{serviceListObj.title}}</div>
      <div class="rating-sec" routerLink="/ratings-and-reviews">
        <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  1 <= serviceListObj?.avg_rating}"></i>
        <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  2 <= serviceListObj?.avg_rating}"></i>
        <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  3 <= serviceListObj?.avg_rating}"></i>
        <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  4 <= serviceListObj?.avg_rating}"></i>
        <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  5 <= serviceListObj?.avg_rating}"></i>
        <span>{{serviceListObj.avg_rating}} ({{serviceListObj.review_count}} Reviews)</span>
      </div>
      <button class="sb-primary-btn" *ngIf="!serviceListObj.isSelected" [attr.data-target]="'#test'+serviceListObj.service_id" data-toggle="modal">ADD</button>
      <button class="sb-remove-btn" *ngIf="serviceListObj.isSelected">
        <i class="bi bi-plus-circle" [attr.data-target]="'#test'+serviceListObj.service_id" data-toggle="modal"></i>
        <span class="count">1</span>
        <i class="bi bi-trash" (click)="decrement()"></i>
      </button>
      <div class="footer-data">
        <div class="availability">Availability : {{serviceListObj.service_option}}</div>
        <div class="amount">{{serviceListObj.price | currency : currencyCode}}</div>
      </div>
    </div>
  </div>
</div> -->


<div class="service-box-container row" *ngIf="serviceListObj">
  <div class="card-colum w-100">
    <div class="inner-data-details">
      <!-- <div class="whish-list-item">
        <div class="title">ADD TO WISHLIST</div>
        <div class="icon-block-wish">
          <i class="bi bi-heart"></i>
          <i class="bi bi-heart-fill"></i>
        </div>
      </div> -->
      <div class="img-block-top" (click)="gotoServiceDetailsPage()">
        <img [src]="serviceListObj.image">
      </div>
      <div class="main-title" (click)="gotoServiceDetailsPage()">{{serviceListObj.title}}</div>
      <!-- <div class="service-logo-img">
        <div class="img-sec">
          <img src="assets/icons/thumbln.png">
        </div>
        <div class="service-name" (click)="gotoServiceDetailsPage()">{{serviceProviderDetails.providerName}}</div>
      </div> -->
      <div class="btn-blocks" *ngIf="!serviceListObj.isSelected">
        <button class="add-btn" [attr.data-target]="'#test'+serviceListObj.service_id" data-toggle="modal"
          (click)="openPopup()">
          <i class="bi bi-plus-circle"></i> <span>
            {{serviceListObj.quote_only ? 'GET QUOTE': 'ADD TO CART'}}</span></button>
      </div>
      <div class="btn-blocks-add-remove" *ngIf="serviceListObj.isSelected">
        <div class="btn-add-remove">
          <i class="bi bi-trash" (click)="decrement()"></i>
          <span class="count">{{serviceListObj.quantities}}</span>
          <i class="bi bi-plus-circle" [attr.data-target]="'#test'+serviceListObj.service_id" data-toggle="modal"
            (click)="openPopup()"></i>
        </div>
      </div>
      <div class="footer-data">
        <div class="availability">
          <ng-container *ngIf="serviceListObj?.quote_only">
            <div>Starting from : {{serviceListObj.min_charge | currency : currencyCode}}</div>
          </ng-container>
          <ng-container *ngIf="!serviceListObj?.quote_only">Availability :
            <span *ngIf="serviceListObj.avail_at === 'home_only'">Home</span>
            <span *ngIf="serviceListObj.avail_at === 'center_only'">Center</span>
            <span *ngIf="serviceListObj.avail_at === 'both'">Both</span>
          </ng-container>
        </div>
        <div class="amount" *ngIf="!serviceListObj?.quote_only">{{serviceListObj.min_charge | currency : currencyCode}}</div>

      </div>
    </div>
  </div>
</div>

<!-- service selection -->

<div class="modal fade model-custom service-selection-dialog location-modal-1"                 
 [attr.id]="'test'+serviceListObj.service_id"
  *ngIf="!serviceListObj.quote_only && showServicesPopup || showBranchModalPopup || showAddAddress || showHomeModalPopup">
  <div class="modal-dialog modal-xl modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <i class="bi bi-arrow-left-short" (click)="backFn()" *ngIf="!showServicesPopup"></i>
        <i *ngIf="showServicesPopup"></i>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
          <i class="bi bi-x"></i>
        </button>
      </div>
      <!--service selection-->
      <div class="modal-body pt-0"> 
        <ng-container *ngIf="showServicesPopup">
          <!-- <div class="title">Where do you want to get this service at?</div> -->

          <div class="row">
            <div class="col-md-6">
              <div class="title">Where do you want to get this service at?</div>
            </div>
            <div class="col-md-6 text-right">
              <button class="sb-primary-btn address-done-btn" (click)="showItemCount()">Done</button>
            </div>
          </div>

          <div class="service-title">{{serviceListObj.title}}</div>
          <div class="services">
            <div class="at-location radio-custom-sb" (click)="getBranchAddressList()">
              <input type="radio" id="at-location" name="type" value="location" class="radio-custom"
              [checked]="serviceListObj.avail_at === 'center_only'">
              <label for="at-location" class="radio-custom-label">Service At Center</label>
            </div>
            <div class="at-home radio-custom-sb"
            (click)="serviceListObj.avail_at === 'center_only' ? '' :getCustomerAddressList('no_address')">
              <input type="radio" id="at-home" name="type" value="home" class="radio-custom"
              [disabled]="serviceListObj.avail_at === 'center_only'">
              <label for="at-home" class="radio-custom-label">Service At Home</label>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="showBranchModalPopup">
          <div class="location-address-selection" id="location-pop-at-center" *ngIf="branchDetails">
            <div class="location-branch" [class.checkd-address]="branchDetails.selected">
              <div class="check-loaction-icon" *ngIf="branchDetails.selected">
                <i class="bi bi-check-circle-fill"></i>
              </div>
              <div class="details-address">
                {{branchDetails?.address1}}, {{branchDetails?.address2}},
                {{branchDetails?.land_mark}}, {{branchDetails?.city}}, {{branchDetails?.state}},
                {{branchDetails?.country}}, {{branchDetails?.post_code}}
              </div>
            </div>
          </div>
          <!-- <button class="sb-primary-btn w-100" (click)="showItemCount()">Done</button> -->
        </ng-container>
        
        <ng-container *ngIf="showAddAddress">
          <div class="map">
            <div class="search-map-container in-line">
              <i class="bi bi-search"></i>
              <input #mapSearchFiled3 class="search-map" type="text"/>
            </div>
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
              <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true"
              (dragEnd)="markerDragEnd($event)"></agm-marker>
            </agm-map>
          </div>
          <div class="content-right">
            <div class="current-location"><i class="bi bi-geo-alt-fill"></i>{{userLocation}}
            </div>
            <div class="input">
              <label>Name Your Address</label><br>
              <input type="text" [(ngModel)]="addressLabel" />
                    <span class="error-text" *ngIf="addressLabel === '' && isSubmitted">Address label required</span>
            </div>
           
            <button class="save btn btn-primary mt-2" (click)="saveAddress()">Save</button>
          </div>
        </ng-container>
      
      <!--at home model-->
      <ng-container *ngIf="showHomeModalPopup">

        <!-- <div class="row">
          <div class="col-md-6 col-6">
            <div class="title">Continue with current location</div>
          </div>
          <div class="col-md-6 col-6 text-right">
            <button class="sb-primary-btn address-done-btn" (click)="showItemCount()">Done</button>
          </div>
        </div> -->



<div class="top-wrapper">
  <div class="left-tittle">
    <p> Continue with current location</p>
  </div>
<div id="mybutton">
<button class="feedback" (click)="showItemCount()">Done</button>
</div>

</div>


        <div class="location-info mt-3">
          <i class="bi bi-geo-alt-fill"></i>
          <span class="loaction-long-lat">{{location}}</span>
        </div>
        <div class="address-suggestions mt-3">
          <div class="saved">Saved Addresses</div>
          <!-- <div class="add-new" id="add-new-address" (click)="showAddAddress = true;showHomeModalPopup=false;">Add New</div> -->
          <div class="add-new" id="add-new-address" (click)="openLocationPopup()">Add New</div>
        </div>
        <br>

        <div class="location-address-selection serviceat-home mt-1" *ngIf="addressList">
          
          <ng-container *ngFor="let item of addressList1;let i = index;">
            <div class="location-branch" [class.checkd-address]="item.selected"
            (click)="homeAddressSelection(item?.address?.address_id, addressList1[i].address_type)">
              <div class="check-loaction-icon" *ngIf="item.selected">
                <i class="bi bi-check-circle-fill"></i>
              </div>
              <div class="details-address">
                <div class="home-address-title font-weight-bold mb-2">{{addressList[i].address_type}}</div>
                <div class="address-gray">{{item?.address?.address1}}</div>
                <div class="address-gray">{{item?.address?.address2}}</div>
                <div class="address-gray">{{item?.address?.land_mark}}</div>
                <div class="address-gray">{{item?.address?.city}}</div>
                <div class="address-gray">{{item?.address?.state}}, {{item?.address?.country}},
                  {{item?.address?.post_code}}
                </div>
              </div>
            </div>
          </ng-container>

        </div>
        
        <div *ngIf="showMoreAddresses" class="location-address-selection serviceat-home mt-1">
          <ng-container *ngFor="let item of addressList2;let i = index;">
            <div class="location-branch" [class.checkd-address]="item.selected"
              (click)="homeAddressSelection(item?.address?.address_id, addressList2[i].address_type)">
              <div class="check-loaction-icon" *ngIf="item.selected">
                <i class="bi bi-check-circle-fill"></i>
              </div>
              <div class="details-address">
                <div class="home-address-title font-weight-bold mb-2">{{addressList2[i].address_type}}</div>
                <div class="address-gray">{{item?.address?.address1}}</div>
                <div class="address-gray">{{item?.address?.address2}}</div>
                <div class="address-gray">{{item?.address?.land_mark}}</div>
                <div class="address-gray">{{item?.address?.city}}</div>
                <div class="address-gray">{{item?.address?.state}}, {{item?.address?.country}},
                  {{item?.address?.post_code}}</div>
              </div>
            </div> 
          </ng-container>
        </div>


        <div class="text-right mt-3">
          <button *ngIf="addressList.length>4" class="view_more_pop-button"
            (click)="showMoreAddresses=!showMoreAddresses">
            {{showMoreAddresses?'Show Less':'Show More'}}
          </button>
        </div>
        <!-- <button class="sb-primary-btn w-100" (click)="showItemCount()">Done</button> -->
      </ng-container>
  </div>
</div>
</div>
</div>



<div class="add-recurring-section" *ngIf="showAddRecurring">
  <app-schedule-popup [serviceDetails]="serviceListObj" [showAddRecurring]="showAddRecurring" [selectBookAService]="selectBookAService"(close)="closePopup()"></app-schedule-popup>
</div>