<div class="bg-color-of-pages">
    <div class="container">
        <div class="inner-datachange-password">
            <div class="heading-page">Change Password</div>
            <div class="box-profile-form" id="box-password-form">
                <!-- New Design -->
                <form (ngSubmit)="onSubmit($event)" [formGroup]="changePWDForm">


                    <!-- <div>
                        <label for="" class="p-label mt-4">Current Password</label><span class="req-text"> *</span> <br>
                        <p-password [feedback]="false" formControlName="currentPWD"
                            placeholder="Current password"></p-password>
                        <div class="error-text" *ngIf="pwdControls.currentPWD.errors?.required  && formSubmited">
                            Current Password required
                        </div>
                    </div> -->

                    <div>
                        <label for="currentPWD" class="p-label mt-4">Current Password</label><span class="req-text">
                            *</span> <br>
                        <!-- <p-password [feedback]="false" formControlName="currentPWD"
                            placeholder="Current password"></p-password> -->

                            <input type="password" formControlName="currentPWD"
                                placeholder="Current password">

                        <div class="error-text" *ngIf="pwdControls.currentPWD.errors?.required  && formSubmited">
                            Current Password required
                        </div>
                        <div class="error-text" *ngIf="formSubmited && changePasswordFailed">
                            Current Password is incorrect. Please try again.
                        </div>
                    </div>



                    <div class="mt-4">
                        <div>
                            <label for="" class="p-label mt-4">New Password</label><span class="req-text"> *</span>
                        </div>
                        <!-- <input type="password" placeholder="Password" name="password" 
                        <label for="" class="mt-4 pb-3">New Password</label>
                        <input type="password" placeholder="Password" name="password" 
                        formControlName="password" form="form-control"
                         class="form-control form-input-border"> -->

                        <!-- <p-password formControlName="newPWD" placeholder="New password" [toggleMask]="true"></p-password> -->

                        <div class="input-with-icon">
                            <input type="{{ isPasswordVisibleNew ? 'text' : 'password' }}" formControlName="newPWD"
                                placeholder="New password">
                            <i class="ng-tns-c126-1 pi"
                                [ngClass]="{'pi-eye': !isPasswordVisibleNew, 'pi-eye-slash': isPasswordVisibleNew}"
                                (click)="togglePasswordVisibility('new')"></i>
                        </div>

                        <div class="error-text" *ngIf="pwdControls.newPWD?.errors?.required  && formSubmited">New
                            Password required</div>
                        <div class="error-text" *ngIf="pwdControls.newPWD?.errors?.minlength  && formSubmited">New
                            Password
                            should be
                            atleast 6</div>
                    </div>
                    <div class="mt-4">

                        <div>
                            <label for="" class="p-label mt-3">Confirm New Password</label><span class="req-text">
                                *</span>
                        </div>

                        <!-- <p-password formControlName="cnfrmPWD" placeholder="Confirm password"></p-password> -->

                        <div class="input-with-icon">
                            <input type="{{ isPasswordVisibleCnfrm ? 'text' : 'password' }}" formControlName="cnfrmPWD"
                                placeholder="Confirm password">
                            <i class="ng-tns-c126-1 pi"
                                [ngClass]="{'pi-eye': !isPasswordVisibleCnfrm, 'pi-eye-slash': isPasswordVisibleCnfrm}"
                                (click)="togglePasswordVisibility('cnfrm')"></i>
                        </div>

                        <!-- <input type="password" name="confirmpassword" id="" placeholder="confirmpassword" 
            
                        <label for="" class="mt-3 pb-3">Confirm New Password</label>
                        <input type="password" name="confirmpassword" id="" placeholder="confirmpassword" 
            
                          form="form-control"
                            class="form-control form-input-border"> -->


                        <div class="error-text" *ngIf="pwdControls.cnfrmPWD.errors?.required  && formSubmited">Confirm
                            New Password
                            required</div>
                        <div class="error-text" *ngIf="pwdControls.cnfrmPWD.errors?.mismatch  && formSubmited">Password
                            and confirm not
                            match</div>
                        <!-- <div class="err_msg" *ngIf="Submitted && pswrd['password'].value!=pswrd['confirmpassword'].value">Password and Confirm
                            password didn't match</div> -->
                    </div>


                    <button type="submit" class="sb-primary-btn btn-block mt-4">Change Password</button>



                </form>
                <!-- New Design -->


                <!-- <form [formGroup]="changePWDForm" (ngSubmit)="onSubmit($event)">
                    <div class="form-data-profile">
                        <div class="hading-text">Change Password</div>
                        <div class="col-md-9 col-lg-9 col-sm-12 col-12 row-form-inputs p-0">
                            <div class="sg-input-text col-md-6 col-sm-6 col-lg-6 col-12 mb-4 p-0">
                                <div class="label-text">Current Password<sup>*</sup></div>
                                <input type="password" placeholder="Current Password" class="input-text" formControlName="currentPWD">
                                <span class="error-text" *ngIf="currentPWDControls?.invalid  && formSubmited">Current Password required</span>
                            </div>
                            <div class="sg-input-text col-md-6 col-sm-6 col-lg-6 col-12 mb-4 p-0">
                                <div class="label-text">New Password<sup>*</sup></div>
                                <input type="password" placeholder="New Password" class="input-text" formControlName="newPWD">
                                <span class="error-text" *ngIf="newPWDControls?.invalid  && formSubmited">New Password required</span>
                            </div>
                            <div class="sg-input-text col-md-6 col-sm-6 col-lg-6 col-12 mb-4 p-0">
                                <div class="label-text">Confirm New Password<sup>*</sup></div>
                                <input type="password" placeholder="Confirm New Password" class="input-text"  formControlName="cnfrmPWD">
                                <span class="error-text" *ngIf="cnfrmPWDControls?.errors?.required  && formSubmited">Confirm New Password required</span>
                                <span class="error-text"  *ngIf="cnfrmPWDControls?.errors?.mismatch  && formSubmited">Password and confirm not match</span>
                            </div>
                            <div class="col-md-4 col-sm-6 col-lg-4 col-12 p-0">
                                <button class="sb-primary-btn w-100" type="submit">Update Changes</button>
                            </div>
                        </div>
                    </div>
                </form> -->
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>



<!-- <h5>Password Meter</h5>
<p-password [(ngModel)]="value2"></p-password> -->