<div class="bg-color-of-pages">
    <div class="container">
        <div class="breadcrumb-data">
            <li class="breadcrumb-item" [routerLink]="'/home'">Home</li>
            <li class="breadcrumb-item">Get Started</li>
        </div>
    </div>

    <div class="resgiter-your-business-container">
        <div class="container">
            <div class="registrations-steps-data">
                <div class="step-item" (click)="currentTabIndex=0">
                    <div class="step-active completed-status" *ngIf="showTab0CompleteIcon">
                        <div class="icon-block">
                            <div class="steps-done">
                                <img src="assets/icons/register/check-white.svg">
                            </div>
                            <img src="assets/icons/register/price-tag.svg" class="default-img">
                        </div>
                    </div>
                    <div class="icon-block" *ngIf="!showTab0CompleteIcon" [ngClass]="{'circle-active' : showPlanTab}">
                        <div class="steps-done">
                            <img src="assets/icons/register/check-white.svg">
                        </div>
                        <img src="assets/icons/register/price-tag.svg" class="default-img">
                    </div>
                    <div class="content-data">
                        <div class="step-title" [ngClass]="{'green' : showTab0CompleteIcon, 'blue': showPlanTab}">
                            {{!showTab0CompleteIcon ? 'Step 1/4' : 'Completed'}}</div>
                        <div class="info-title">Choose Plan </div>
                    </div>
                </div>

                <!---------------------->
                <div class="step-item" (click)="currentTabIndex=1">

                    <div class="step-active completed-status" *ngIf="showTab1CompleteIcon">
                        <div class="icon-block">
                            <div class="steps-done">
                                <img src="assets/icons/register/check-white.svg">
                            </div>
                            <img src="assets/icons/register/briefcase.svg" class="default-img">
                        </div>
                    </div>
                    <div class="icon-block" *ngIf="!showTab1CompleteIcon"
                        [ngClass]="{'circle-active' : showCategoryTab}">
                        <div class="steps-done">
                            <img src="assets/icons/register/check-white.svg">
                        </div>
                        <img src="assets/icons/register/briefcase.svg" class="default-img">
                    </div>
                    <div class="content-data">
                        <div class="step-title" [ngClass]="{'green' : showTab1CompleteIcon, 'blue': showCategoryTab}">
                            {{!showTab1CompleteIcon ? 'Step 2/4' : 'Completed'}}</div>
                        <div class="info-title">Choose Category</div>
                    </div>
                </div>

                <div class="step-item" (click)="currentTabIndex=2">
                    <div class="step-active completed-status" *ngIf="showTab3CompleteIcon">
                        <div class="icon-block" [ngClass]="{'circle-active' : showBusinessTab}">
                            <div class="steps-done">
                                <img src="assets/icons/register/check-white.svg">
                            </div>
                            <img src="assets/icons/register/user.svg" class="default-img">
                        </div>
                    </div>
                    <div class="icon-block" *ngIf="!showTab3CompleteIcon"
                        [ngClass]="{'circle-active' : showBusinessTab}">
                        <div class="steps-done">
                            <img src="assets/icons/register/check-white.svg">
                        </div>
                        <img src="assets/icons/register/user.svg" class="default-img">
                    </div>
                    <div class="content-data">
                        <div class="step-title" [ngClass]="{'green' : showTab3CompleteIcon, 'blue' : showBusinessTab}">
                            {{!showTab3CompleteIcon ? 'Step 3/4' : 'Completed'}}</div>
                        <div class="info-title">Business Details</div>
                    </div>
                </div>

                <div class="step-item">
                    <div class="icon-block" [ngClass]="{'circle-active' : showSuccessTab}">
                        <div class="steps-done">
                            <img src="assets/icons/register/check-white.svg">
                        </div>
                        <img src="assets/icons/register/smile.svg" class="default-img">
                    </div>
                    <div class="content-data">
                        <div class="step-title" [ngClass]="{'blue' : showSuccessTab}">Step 4/4</div>
                        <div class="info-title">Confirmation</div>
                    </div>

                </div>


            </div>
        </div>

        <div class=" steps-tabs-container-data">

            <div class="steps-inputs-data" *ngIf="currentTabIndex==1">
                <div class="main-heading">Choose your business category.</div>
                <div class="info-title">please choose your business category so that we could get more <br />information
                    about your business</div>
                <div class="popular-services" *ngIf="serviceCategoryList">
                    <div class="inner-padding" *ngFor="let cat of serviceCategoryList">
                        <input type="radio" class="radio-active" name="cat" id="{{cat.title}}" (click)="cardClick(cat)"
                            [checked]="cat.business_category_id === businessCatId" [value]="cat.business_category_id" />
                        <div class="card-service-p">
                            <span class="checked-item">
                                <i class="bi bi-check-circle-fill"></i>
                            </span>
                            <div class="img-block">
                                <img [src]="cat.image">
                            </div>
                            <div class="service-title" title="{{cat.title}}">{{cat.title}}</div>
                        </div>
                    </div>
                </div>
                <div class="protip-data">We charge 10% commission on bookings.<br />10% a lot? You can negotiate
                    with our saled team? <a target="_blank" [routerLink]="'/contact-us'">Conatct Us</a></div>
                <!-- <div class="col-12 p-0 btn-continue">
                    <button class="sb-primary-btn col-12 col-sm-4 col-md-3 col-lg-2"
                        (click)="onContinueFn()"  type="button">Continue</button>
                </div> -->
                <div class="col-12 btn-continue p-0">
                    <button class="sb-back-btn mr-2 col-12 col-sm-4 col-md-3 col-lg-2" (click)="onBackFn('tab-1')"
                        type="button">Back</button>
                    <button class="sb-primary-btn col-12 col-sm-4 col-md-3 col-lg-2" (click)="onContinueFn('tab-2')"
                        type="submit">Continue</button>
                </div>
            </div>

            <!----------tabs-content-->
            <div class="steps-inputs-data" *ngIf="currentTabIndex==2">
                <form [formGroup]="registerYourBusinessForm" (ngSubmit)="onSubmit($event)">
                    <div class="main-heading">To get started, Let us know about your company.</div>
                    <div class="info-title">Please fill in the below details so that we could get in touch with you
                        company.
                    </div>
                    <div class="upload-log-sec col-md-10 col-xs-12 col-lg-10 col-12 pl-0 pr-1">
                        <div class="title-text">Company Logo</div>
                        <!-- <div class="drog-and-drop-upload-logo">
                        <input type="file" id="fileInput">
                        <i class="bi bi-cloud-arrow-up-fill"></i>
                        <div class="title">Drag & Drop</div>
                        <div class="info-kb">(Recommended size : 1080pX1080p)</div>
                    </div> -->


                        <!-- ========================= Dropzone ======================== -->
                        <div class="file-area">
                            <form [formGroup]="myForm">
                                <input formControlName="file" id="file" type="file" multiple accept=".png"
                                    class="form-control" (change)="onFileChange($event)">

                                <!-- <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelect($event)"> -->
                                <div class="custom-dropzone" ngx-dropzone (change)="onSelect($event)">
                                    <ngx-dropzone-label>
                                        <div>
                                            <img src="assets/icons/file-icon.png" alt="" class="file-upload-img">
                                            <div class="title-drog-drop pt-3">Drag & Drop or Browse your files</div>
                                        </div>
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                        *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
                                        <!-- <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label> -->
                                    </ngx-dropzone-image-preview>
                                </div>
                            </form>
                        </div>
                        <span class="error-text" *ngIf="formSubmited &&  !fileName">Company Logo required</span>

                        <!-- ========================= Dropzone ======================== -->


                        <!-- <div class="file-area">
                            <form [formGroup]="myForm">
                                <input formControlName="file" id="file" type="file" multiple accept=".png"
                                    class="form-control" (change)="onFileChange($event)">
                                <div class="file-dummy">
                                    <i class="bi bi-cloud-arrow-up-fill"></i>
                                    <div class="title-drog-drop">Drag & Drop</div>
                                    <div class="success">
                                        <span class="file-name">{{fileName}}</span>
                                        <span class="remove" (click)="removefile()" *ngIf="fileName">Remove</span>
                                    </div>
                                    <div class="default">(Recommended size : 256pxX256px)</div>
                                </div>
                            </form>
                        </div> -->
                        <!-- <span class="error-text" *ngIf="formSubmited &&  !fileName">Company Logo required</span> -->
                    </div>
                    <div class="inputs-block row">
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="label-text">Business Name(Legal Name)<sup>*</sup></div>
                            <input type="" class="form-input" placeholder="Business Name(Legal Name)"
                                formControlName="legal_name">
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.legal_name.errors?.required">Business
                                Name(Legal Name) required</span>
                        </div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="label-text">Contact Name<sup>*</sup></div>
                            <input type="" class="form-input" placeholder="Contact Name" formControlName="name">
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.name.errors?.required">Contact Name
                                required</span>
                        </div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="label-text">Phone<sup>*</sup></div>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">{{countryCode}}</div>
                                </div>
                                <input type="" class="form-input" placeholder="Phone Number" formControlName="mobile">
                            </div>
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.mobile.errors?.required">Phone
                                required</span>
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.mobile.errors?.pattern || regYourBusinessFormControls.mobile.errors?.maxlength">Phone
                                Number
                                requires minimum 10 Characters</span>
                        </div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="label-text">EMAIL<sup>*</sup></div>
                            <input type="" class="form-input" placeholder="Email" formControlName="email">
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.email.invalid">EMAIL
                                required</span>
                        </div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="label-text">Website</div>
                            <input type="url" class="form-input" placeholder="Website" formControlName="website">
                        </div>
                        <div class="address-details col-12">Address Details</div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="label-text">Address<sup>*</sup></div>
                            <input type="" class="form-input" placeholder="Address" formControlName="address">
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.address.errors?.required">Address
                                required</span>
                        </div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="label-text">Landmark<sup>*</sup></div>
                            <input type="" class="form-input" placeholder="Landmark" formControlName="landmark">
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.landmark.errors?.required">Landmark
                                required</span>
                        </div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="label-text">City<sup>*</sup></div>
                            <input type="" class="form-input" placeholder="City" formControlName="city">
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.city.errors?.required">City
                                required</span>
                        </div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="label-text">{{pinCodeLabelName}}<sup>*</sup></div>
                            <input type="" class="form-input" [placeholder]="pinCodeLabelName"
                                formControlName="pincode">
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.pincode.errors?.required">{{pinCodeLabelName}}
                                required</span>
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.pincode.errors?.pattern">{{pinCodeLabelName}}
                                requires minimum 6 Characters</span>
                        </div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="sb-dropdonws" ngbDropdown>
                                <div class="label-text">Country</div>
                                <button class="dropdown-input" ngbDropdownToggle
                                    type="button">{{selectedCountry}}</button>
                                <ul class="dropdown-menu" ngbDropdownMenu>
                                    <li ngbDropdownItem *ngFor="let country of countryList"
                                        (click)="ChangeStateCountry(country, 'country')">
                                        <a class="dropdown_item">{{country}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="sb-dropdonws" ngbDropdown #d="ngbDropdown">
                                <div class="label-text">State</div>
                                <button class="dropdown-input" ngbDropdownToggle
                                    type="button">{{selectedState}}</button>
                                <ul class="dropdown-menu" ngbDropdownMenu>
                                    <li ngbDropdownItem *ngFor="let state of stateList"
                                        (click)="ChangeStateCountry(state, 'state')">
                                        <a class="dropdown_item">{{state}}</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <span class="error-text" *ngIf="errMsg">{{errMsg}}</span>
                        <div class="col-12 btn-continue">
                            <button class="sb-back-btn mr-2 col-12 col-sm-4 col-md-3 col-lg-2"
                                (click)="onBackFn('tab-2')" type="button">Back</button>
                            <button class="sb-primary-btn col-12 col-sm-4 col-md-3 col-lg-2"
                                type="submit">Continue</button>
                            <!-- (click)="onContinueFn('tab-2')" -->
                        </div>
                    </div>
                </form>
            </div>
            <div class="last-step-success" *ngIf="currentTabIndex==3">
                <div class="thanks-page-container-data">
                    <div class="thanks-message-info">
                        <div class="img-block">
                            <img src="assets/icons/success-gif.gif">
                        </div>
                        <div class="content-block">
                            <div class="title">Thank You! Please check your email.</div>
                            <div class="info-text">We have sent a random password to your email ID, you can login with
                                your random password and you can reset it after login.</div>
                            <!-- <div class="back-link" [routerLink]="'/sign-in'">Login</div> -->
                            <div class="back-link" (click)="goToAdminLogin()">Login</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pricing_tabs" *ngIf="currentTabIndex==0">
                <p-tabView>
                    <p-tabPanel header="Monthly">

                        <div class="content-wrapper">
                            <div class="column-wrapper-one table-column-wrapper">
                                <img src="assets/images/Mask-Group.png" class="img-wrapper" alt="">
                                <!-- <p class="odd_line">Included bookings </p>
                        <p class="even_line"></p> -->
                                <table class="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>Included bookings </td>
                                        </tr>
                                        <tr>
                                            <td>Employees
                                            <td>
                                                <!-- <td>Custom Features</td> -->
                                        </tr>
                                        <!-- <tr>
                                            <td>Users/Providers*</td>
                                        </tr>
                                        <tr>
                                            <td>Admin App </td>
                                        </tr>
                                        <tr>
                                            <td>Client App </td>
                                        </tr>
                                        <tr>
                                            <td>Booking Websites</td>
                                        </tr>
                                        <tr>
                                            <td>Booking Widgets </td>
                                        </tr>
                                        <tr>
                                            <td>Directory Listing </td>
                                        </tr>
                                        <tr>
                                            <td>Coupons & Gift Cards</td>
                                        </tr>
                                        <tr>
                                            <td>Sales (POS)</td>
                                        </tr>
                                        <tr>
                                            <td> Link Removal</td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>



                            <!--------------------------------------------------------------->

                            <!----------------trail-->
                            <div class="column-wrapper d-none">
                                <input type="radio" class="radio-active" name="plan" id="{{'basic'}}"
                                    (click)="createSubscription(trailPlan)" [checked]="trailPlan.priceId === planId"
                                    [value]="trailPlan.priceId" />
                                <div class="row  table-column-wrapper mt-2">
                                    <div class="col-md-4 pr-5">
                                        <img src="assets/icons/free trial.svg" class="icon_free">
                                    </div>
                                    <div class="col-md-8">
                                        <p class="top-heading">Free trail</p>
                                        <p> <b class="price-wrap">{{'0' | currency: currencyCode:'symbol':'1.0-0'
                                                }}{{trailPlan.validity}}</b><span
                                                class="month-wrapper">/{{trailPlan.validityInDays}}</span></p>
                                    </div>

                                </div>

                                <button type="button" class="trial-btn"
                                    (click)="createSubscription(trailPlan, 'trail')"> Star Free Trial</button>
                                <table class="table table-striped trial-wrapper mt-5">
                                    <tbody>
                                        <tr>
                                            <td>{{trailPlan.bookingLimit}} </td>
                                        </tr>
                                        <tr>
                                            <td>{{trailPlan.empCount}}</td>
                                        </tr>
                                        <!-- <tr>
                                            <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>

                            <!-- New Free trail code -->
                            <div class="column-wrapper" [class.selected]="trailPlan.priceId === planId"
                                (click)="createSubscription(trailPlan)">
                                <div class="row table-column-wrapper mt-2">
                                    <div class="col-md-4 pr-5">
                                        <img src="assets/icons/free trial.svg" class="icon_free">
                                    </div>
                                    <div class="col-md-8">
                                        <p class="top-heading">Free trail</p>
                                        <p> <b class="price-wrap">{{'0' | currency: currencyCode:'symbol':'1.0-0'
                                                }}{{trailPlan.validity}}</b><span
                                                class="month-wrapper">/{{trailPlan.validityInDays}}</span></p>
                                    </div>
                                </div>
                                <button type="button" class="trial-btn"
                                    (click)="createSubscription(trailPlan, 'trail')"> Start Free Trial</button>
                                <table class="table table-striped trial-wrapper mt-5">
                                    <tbody>
                                        <tr>
                                            <td>{{trailPlan.bookingLimit}} </td>
                                        </tr>
                                        <tr>
                                            <td>{{trailPlan.empCount}}</td>
                                        </tr>
                                        <!-- <tr>
                                            <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>

                            <!-- New Free trail code -->
                            <!-------------------Basic card-->
                            <div class="column-wrapper d-none">
                                <input type="radio" class="radio-active" name="plan" id="{{'basic'}}"
                                    (click)="createSubscription(basicMonthly)"
                                    [checked]="basicMonthly.priceId === planId" [value]="basicMonthly.priceId" />

                                <div class="row  table-column-wrapper mt-2">
                                    <div class="col-md-4 pr-5">
                                        <img src="assets/icons/Basic icon.svg" class="icon_free">
                                    </div>
                                    <div class="col-md-8">
                                        <p class="top-heading">Basic</p>
                                        <p><b class="price-wrap">{{basicMonthly.money | currency:
                                                currencyCode:'symbol':'1.0-0' }}</b><span
                                                class="month-wrapper">/Month</span></p>
                                    </div>

                                </div>

                                <button type="button" class="trial-btn" (click)="createSubscription(basicMonthly)">Get
                                    Started</button>
                                <table class="table table-striped trial-wrapper mt-5">
                                    <tbody>
                                        <tr>
                                            <td>{{basicMonthly.bookingLimit}} </td>
                                        </tr>
                                        <tr>
                                            <td>{{basicMonthly.empCount}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- New Basic code -->
                            <div class="column-wrapper" [class.selected]="basicMonthly.priceId === planId"
                                (click)="createSubscription(basicMonthly)">
                                <div class="row table-column-wrapper mt-2">
                                    <div class="col-md-4 pr-5">
                                        <img src="assets/icons/Basic icon.svg" class="icon_free">
                                    </div>
                                    <div class="col-md-8">
                                        <p class="top-heading">Basic</p>
                                        <p><b class="price-wrap">{{basicMonthly.money | currency:
                                                currencyCode:'symbol':'1.0-0' }}</b><span
                                                class="month-wrapper">/Month</span></p>
                                    </div>
                                </div>
                                <button type="button" class="trial-btn" (click)="createSubscription(basicMonthly)">Get
                                    Started</button>
                                <table class="table table-striped trial-wrapper mt-5">
                                    <tbody>
                                        <tr>
                                            <td>{{basicMonthly.bookingLimit}} </td>
                                        </tr>
                                        <tr>
                                            <td>{{basicMonthly.empCount}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- New basic code -->
                            <!---------Standard------------->
                            <div class="column-wrapper d-none">
                                <input type="radio" class="radio-active" name="plan" id="{{'basic'}}"
                                    (click)="createSubscription(standardMonthly)"
                                    [checked]="standardMonthly.priceId === planId" [value]="standardMonthly.priceId" />
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="bestSellerRgt">Popular</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <img src="assets/icons/standard-icon.svg" class="icon_free">
                                    </div>
                                    <div class="col-md-8">
                                        <p class="top-heading-standard">Standard</p>
                                        <p> <b class="price-wrap-standard">{{standardMonthly.money | currency:
                                                currencyCode:'symbol':'1.0-0' }}</b><span
                                                class="month_wrapper-standard">/Month</span></p>
                                    </div>

                                </div>


                                <button type="button" class="trial-btn"
                                    (click)="createSubscription(standardMonthly)">Get Started</button>
                                <table class="table table-striped trial-wrapper mt-5">
                                    <tbody>
                                        <tr>
                                            <td>{{standardMonthly.bookingLimit}} </td>
                                        </tr>
                                        <tr>
                                            <td>{{standardMonthly.empCount}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- New Standard  -->
                            <div class="column-wrapper popular-plan"
                                [class.selected]="standardMonthly.priceId === planId"
                                (click)="createSubscription(standardMonthly)">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="bestSellerRgt">Popular</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <img src="assets/icons/standard-icon.svg" class="icon_free">
                                    </div>
                                    <div class="col-md-8">
                                        <p class="top-heading-standard">Standard</p>
                                        <p> <b class="price-wrap-standard">{{standardMonthly.money | currency:
                                                currencyCode:'symbol':'1.0-0' }}</b><span
                                                class="month_wrapper-standard">/Month</span></p>
                                    </div>
                                </div>
                                <button type="button" class="trial-btn"
                                    (click)="createSubscription(standardMonthly)">Get Started</button>
                                <table class="table table-striped trial-wrapper mt-5">
                                    <tbody>
                                        <tr>
                                            <td>{{standardMonthly.bookingLimit}} </td>
                                        </tr>
                                        <tr>
                                            <td>{{standardMonthly.empCount}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- New Standard -->

                            <!----------------premium-->
                            <div class="column-wrapper d-none">
                                <input type="radio" class="radio-active" name="plan" id="{{'basic'}}"
                                    (click)="createSubscription(premiumMonthly)"
                                    [checked]="premiumMonthly.priceId === planId" [value]="premiumMonthly.priceId" />
                                <div class="row  table-column-wrapper mt-2">
                                    <div class="col-md-4 pr-5">
                                        <img src="assets/icons/premium-icon.svg" class="icon_free">
                                    </div>
                                    <div class="col-md-8">
                                        <p class="top-heading">Premium</p>
                                        <p> <b class="price-wrap">{{premiumMonthly.money | currency:
                                                currencyCode:'symbol':'1.0-0' }}</b><span
                                                class="month-wrapper">/Month</span></p>
                                    </div>

                                </div>
                                <button type="button" class="trial-btn" (click)="createSubscription(premiumMonthly)">Get
                                    Started</button>
                                <table class="table table-striped trial-wrapper mt-5">
                                    <tbody>
                                        <tr>
                                            <td>{{premiumMonthly.bookingLimit}} </td>
                                        </tr>
                                        <tr>
                                            <td>{{premiumMonthly.empCount}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- New Premium  -->
                            <div class="column-wrapper" [class.selected]="premiumMonthly.priceId === planId"
                                (click)="createSubscription(premiumMonthly)">
                                <div class="row table-column-wrapper mt-2">
                                    <div class="col-md-4 pr-5">
                                        <img src="assets/icons/premium-icon.svg" class="icon_free">
                                    </div>
                                    <div class="col-md-8">
                                        <p class="top-heading">Premium</p>
                                        <p> <b class="price-wrap">{{premiumMonthly.money | currency:
                                                currencyCode:'symbol':'1.0-0' }}</b><span
                                                class="month-wrapper">/Month</span></p>
                                    </div>
                                </div>
                                <button type="button" class="trial-btn" (click)="createSubscription(premiumMonthly)">Get
                                    Started</button>
                                <table class="table table-striped trial-wrapper mt-5">
                                    <tbody>
                                        <tr>
                                            <td>{{premiumMonthly.bookingLimit}} </td>
                                        </tr>
                                        <tr>
                                            <td>{{premiumMonthly.empCount}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- New Premium -->
                            <!-------------------->
                            <div class="column-wrapper">
                                <!-- <input type="radio" class="radio-active" name="cat" id="{{'basic'}}" 
                                 (click)="createSubscription('contactus')" [checked]="'contactus' === planId" [value]="'contactus'"/> -->
                                <div class="enterprise-content">
                                    Enterprise
                                </div>
                                <button type="button" class="trial-btn" (click)="naviagteContact()">Contact Us</button>
                                <table class="table table-striped trial-wrapper mt-5">
                                    <tbody>
                                        <tr>
                                            <td>Unlimited </td>
                                        </tr>
                                        <tr>
                                            <td>Unlimited</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-------------->
                        </div>
                        <!----------------------code for mobile view-->
                        <!--------------------free-trail---------->
                        <div class="row  mobile-top-wrapper mt-5">
                            <div class="col-md-12">
                                <p class="mobile-free-trail-wrapper">Free trail</p>
                                <p class="plan__header-price-value">£ &nbsp;0
                                    <span class="plan-header-month">/Month</span>

                                </p>
                            </div>
                        </div>
                        <div class="mobile-content-wrapper">
                            <div class="mobile-view-content-wrapper">
                                <table class="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>Included bookings </td>
                                        </tr>
                                        <tr>
                                            <td>Custom Features</td>
                                        </tr>
                                        <tr>
                                            <td>Users/Providers*</td>
                                        </tr>
                                        <tr>
                                            <td>Admin App </td>
                                        </tr>
                                        <tr>
                                            <td>Client App </td>
                                        </tr>
                                        <tr>
                                            <td>Booking Websites</td>
                                        </tr>
                                        <tr>
                                            <td>Booking Widgets </td>
                                        </tr>
                                        <tr>
                                            <td>Directory Listing </td>
                                        </tr>
                                        <tr>
                                            <td>Coupons & Gift Cards</td>
                                        </tr>
                                        <tr>
                                            <td>Sales (POS)</td>
                                        </tr>
                                        <tr>
                                            <td> Link Removal</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div class="mobile-view-mobile">
                                <table class="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>50/month</td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                        <!-------------------------Mobileview------------->
                        <!--------------------basic-trail--------->
                        <div class="row  mobile-top-wrapper">
                            <div class="col-md-12">
                                <p class="mobile-free-trail-wrapper">Basic</p>
                                <p class="plan__header-price-value">£ &nbsp;700
                                    <span class="plan-header-month">/Month</span>

                                </p>
                            </div>
                        </div>
                        <div class="mobile-content-wrapper">
                            <div class="mobile-view-content-wrapper">
                                <table class="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>Included bookings </td>
                                        </tr>
                                        <tr>
                                            <td>Custom Features</td>
                                        </tr>
                                        <tr>
                                            <td>Users/Providers*</td>
                                        </tr>
                                        <tr>
                                            <td>Admin App </td>
                                        </tr>
                                        <tr>
                                            <td>Client App </td>
                                        </tr>
                                        <tr>
                                            <td>Booking Websites</td>
                                        </tr>
                                        <tr>
                                            <td>Booking Widgets </td>
                                        </tr>
                                        <tr>
                                            <td>Directory Listing </td>
                                        </tr>
                                        <tr>
                                            <td>Coupons & Gift Cards</td>
                                        </tr>
                                        <tr>
                                            <td>Sales (POS)</td>
                                        </tr>
                                        <tr>
                                            <td> Link Removal</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div class="mobile-view-mobile">
                                <table class="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>100/month</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>15</td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                        <!--------Standard mobile-wrapper-------->
                        <div class="row  mobile-top-wrapper">
                            <div class="col-md-12">
                                <p class="mobile-free-trail-wrapper">Standard</p>
                                <p class="plan__header-price-value">£ &nbsp;1499
                                    <span class="plan-header-month">/Month</span>

                                </p>
                            </div>
                        </div>
                        <div class="mobile-content-wrapper">
                            <div class="mobile-view-content-wrapper">
                                <table class="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>Included bookings </td>
                                        </tr>
                                        <tr>
                                            <td>Custom Features</td>
                                        </tr>
                                        <tr>
                                            <td>Users/Providers*</td>
                                        </tr>
                                        <tr>
                                            <td>Admin App </td>
                                        </tr>
                                        <tr>
                                            <td>Client App </td>
                                        </tr>
                                        <tr>
                                            <td>Booking Websites</td>
                                        </tr>
                                        <tr>
                                            <td>Booking Widgets </td>
                                        </tr>
                                        <tr>
                                            <td>Directory Listing </td>
                                        </tr>
                                        <tr>
                                            <td>Coupons & Gift Cards</td>
                                        </tr>
                                        <tr>
                                            <td>Sales (POS)</td>
                                        </tr>
                                        <tr>
                                            <td> Link Removal</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div class="mobile-view-mobile">
                                <table class="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>500/month</td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                        </tr>
                                        <tr>
                                            <td>25</td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                        <!------------------mobile-view-premium-->
                        <div class="row  mobile-top-wrapper">
                            <div class="col-md-12">
                                <p class="mobile-free-trail-wrapper">Premium</p>
                                <p class="plan__header-price-value">£ &nbsp;2499
                                    <span class="plan-header-month">/Month</span>

                                </p>
                            </div>
                        </div>
                        <div class="mobile-content-wrapper">
                            <div class="mobile-view-content-wrapper">
                                <table class="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>Included bookings </td>
                                        </tr>
                                        <tr>
                                            <td>Custom Features</td>
                                        </tr>
                                        <tr>
                                            <td>Users/Providers*</td>
                                        </tr>
                                        <tr>
                                            <td>Admin App </td>
                                        </tr>
                                        <tr>
                                            <td>Client App </td>
                                        </tr>
                                        <tr>
                                            <td>Booking Websites</td>
                                        </tr>
                                        <tr>
                                            <td>Booking Widgets </td>
                                        </tr>
                                        <tr>
                                            <td>Directory Listing </td>
                                        </tr>
                                        <tr>
                                            <td>Coupons & Gift Cards</td>
                                        </tr>
                                        <tr>
                                            <td>Sales (POS)</td>
                                        </tr>
                                        <tr>
                                            <td> Link Removal</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div class="mobile-view-mobile">
                                <table class="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>2000/month</td>
                                        </tr>
                                        <tr>
                                            <td>Unlimited</td>
                                        </tr>
                                        <tr>
                                            <td>5o</td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                        <!------------------------------------>
                    </p-tabPanel>
                    <!--------------------------------------->
                    <p-tabPanel header="Annual">
                        <div class="content-wrapper" id="pricing-plan-wrapper">
                            <div class="column-wrapper-one table-column-wrapper">
                                <img src="assets/images/Mask-Group.png" class="img-wrapper" alt="">
                                <!-- <p class="odd_line">Included bookings </p>
                        <p class="even_line"></p> -->
                                <table class="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>Included bookings </td>
                                        </tr>
                                        <!-- <tr>
                                            <td>Custom Features</td>
                                        </tr> -->
                                        <tr>
                                            <td>Employees</td>
                                            <!-- <td>Users/Providers*</td> -->
                                        </tr>
                                        <!-- <tr>
                                            <td>Admin App </td>
                                        </tr>
                                        <tr>
                                            <td>Client App </td>
                                        </tr>
                                        <tr>
                                            <td>Booking Websites</td>
                                        </tr>
                                        <tr>
                                            <td>Booking Widgets </td>
                                        </tr>
                                        <tr>
                                            <td>Directory Listing </td>
                                        </tr>
                                        <tr>
                                            <td>Coupons & Gift Cards</td>
                                        </tr>
                                        <tr>
                                            <td>Sales (POS)</td>
                                        </tr>
                                        <tr>
                                            <td> Link Removal</td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                            <!----------------trail-->
                            <!-- <div class="column-wrapper">
                                <div class="row  table-column-wrapper mt-2">
                                    <div class="col-md-4 pr-5">
                                        <img src="assets/icons/free trial.svg" class="icon_free">
                                    </div>
                                    <div class="col-md-8">
                                        <p class="top-heading">Free trail</p>
                                        <p> <b class="price-wrap">{{trailPlan.validity}}</b><span class="month-wrapper">/{{trailPlan.validityInDays}}</span></p>
                                    </div>
        
                                </div>
        
                                <button type="button" class="trial-btn"> Star Free Trial</button>
                                <table class="table table-striped trial-wrapper mt-5">
                                    <tbody>
                                        <tr>
                                            <td>{{trailPlan.bookingLimit}} </td>
                                        </tr>
                                        <tr>
                                            <td>{{trailPlan.empCount}}</td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> -->
                            <!-------------------Basic card-->
                            <div class="column-wrapper" [class.selected]="basicYearly.priceId === planId">
                                <input type="radio" class="radio-active" name="plan" id="{{'basicYearly'}}"
                                    (click)="createSubscription(basicYearly)" [checked]="basicYearly.priceId === planId"
                                    [value]="basicYearly.priceId" />

                                <div class="row  table-column-wrapper mt-2">
                                    <div class="col-md-4 pr-5">
                                        <img src="assets/icons/Basic icon.svg" class="icon_free">
                                    </div>
                                    <div class="col-md-8">
                                        <p class="top-heading">Basic</p>
                                        <p><b class="price-wrap">{{basicYearly.money | currency:
                                                currencyCode:'symbol':'1.0-0' }}</b><span
                                                class="month-wrapper">/Year</span></p>
                                    </div>

                                </div>

                                <button type="button" class="trial-btn" (click)="createSubscription(basicYearly)">Get
                                    Started</button>
                                <table class="table table-striped trial-wrapper mt-5">
                                    <tbody>
                                        <tr>
                                            <td>{{basicYearly.bookingLimit}} </td>
                                        </tr>
                                        <tr>
                                            <td>{{basicYearly.empCount}}</td>
                                        </tr>
                                        <!-- <tr>
                                            <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                        </tr> -->

                                    </tbody>
                                </table>
                            </div>
                            <!---------Standard------------->
                            <div class="column-wrapper" [class.selected]="standardYearly.priceId === planId">
                                <input type="radio" class="radio-active" name="plan" id="{{'basicYearly'}}"
                                    (click)="createSubscription(standardYearly)"
                                    [checked]="standardYearly.priceId === planId" [value]="standardYearly.priceId" />
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="bestSellerRgt">Popular</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <img src="assets/icons/standard-icon.svg" class="icon_free">
                                    </div>
                                    <div class="col-md-8">
                                        <p class="top-heading-standard">Standard</p>
                                        <p> <b class="price-wrap-standard">{{standardYearly.money | currency:
                                                currencyCode:'symbol':'1.0-0' }}</b><span
                                                class="month_wrapper-standard">/Year</span></p>
                                    </div>

                                </div>


                                <button type="button" class="trial-btn" (click)="createSubscription(standardYearly)">Get
                                    Started</button>
                                <table class="table table-striped trial-wrapper mt-5">
                                    <tbody>
                                        <tr>
                                            <td>{{standardYearly.bookingLimit}} </td>
                                        </tr>
                                        <tr>
                                            <td>{{standardYearly.empCount}}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <!----------------premium-->
                            <div class="column-wrapper"  [class.selected]="premiumYearly.priceId === planId">
                                <input type="radio" class="radio-active" name="plan" id="{{'basicYearly'}}"
                                    (click)="createSubscription(premiumYearly)"
                                    [checked]="premiumYearly.priceId === planId" [value]="premiumYearly.priceId" />
                                <div class="row  table-column-wrapper mt-2">
                                    <div class="col-md-4 pr-5">
                                        <img src="assets/icons/premium-icon.svg" class="icon_free">
                                    </div>
                                    <div class="col-md-8">
                                        <p class="top-heading">Premium</p>
                                        <p> <b class="price-wrap">{{premiumYearly.money | currency:
                                                currencyCode:'symbol':'1.0-0' }}</b><span
                                                class="month-wrapper">/Year</span></p>
                                    </div>

                                </div>
                                <button type="button" class="trial-btn" (click)="createSubscription(premiumYearly)">Get
                                    Started</button>
                                <table class="table table-striped trial-wrapper mt-5">
                                    <tbody>
                                        <tr>
                                            <td>{{premiumYearly.bookingLimit}} </td>
                                        </tr>
                                        <tr>
                                            <td>{{premiumYearly.empCount}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-------------->
                            <div class="column-wrapper">
                                <!-- <input type="radio" class="radio-active" name="plan" id="{{'basicYearly'}}"  (click)="createSubscription('Enterprise')" [checked]="'Enterprise' === planId" [value]="'Enterprise'"/> -->
                                <div class="row  mt-2 d-none">
                                    <div class="col-md-12 entenprise-wrap">
                                        <!-- <img src="assets/icons/premium-icon.svg" class="icon_free"> -->
                                        <!-- </div>
                                    <div class="col-md-8"> -->
                                        <p class="top-heading-enterprise">Enterprise</p>
                                        <!-- <p> <b class="price-wrap">£2,499</b><span class="month-wrapper">/Month</span></p> -->
                                    </div>

                                </div>
                                <!-- <p class="top-heading-enterprise">Enterprise</p> -->
                                <div class="enterprise-content">
                                    Enterprise
                                </div>
                                <button type="button" class="trial-btn" (click)="naviagteContact()">Contact Us</button>
                                <table class="table table-striped trial-wrapper mt-5">
                                    <tbody>
                                        <tr>
                                            <td>Unlimited </td>
                                        </tr>
                                        <tr>
                                            <td>Unlimited</td>
                                        </tr>
                                        <!-- <tr>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                        </tr>
                                        <tr>
                                            <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                            <!------------>
                        </div>
                        <!---------Annual -------------------------->
                    </p-tabPanel>

                </p-tabView>
                <div class="col-12 p-0 btn-continue">
                    <button class="sb-primary-btn col-12 col-sm-4 col-md-3 col-lg-2" (click)="onContinueFn('tab-1')"
                        type="button">Continue</button>
                </div>
            </div>


            <!-- </div>-->
        </div>


    </div>


    <!------------pricing-plans-->


    <!-- </div> -->



    <app-footer></app-footer>
    <div id="main-page-wrapper">

    </div>