
<app-tutor-header></app-tutor-header>
<div class="container-fluid student-profile-wrapper">
    <h1 class="page-title">Personal Details</h1>

    <div class="student-details-wrapper">

        <div class="s-details-inner">
            <form>
                <h5 class="g-details mb-3">
                    General details
                </h5>

                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="">First name</label>
                            <input type="text" class="form-control" id="" placeholder="Enter first name">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="">Last name</label>
                            <input type="text" class="form-control" id="" placeholder="Enter last name">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="">Email address</label>
                            <input type="email" class="form-control" id="" placeholder="Enter email">
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="">Phone number</label>
                            <input type="number" class="form-control" id="" placeholder="Enter phone number" value="">
                        </div>
                    </div>
                </div>

                <h5 class="g-details mt-3 mb-3">
                    Billing address
                </h5>
                <p class="text-secondary">In order to pay for an lesson you must add your billing address</p>

                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="">Country</label>
                            <ng-select [items]="countries" bindLabel="name" [multiple]="false"
                                [(ngModel)]="defaultCountry">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="">Search street</label>
                            <input type="text" class="form-control" id="" placeholder="Search street">
                        </div>
                    </div>
                </div>

                <div class="enter-manually">
                    <!-- Enter Manually -->
                    <a (click)="openAddressForm = true" class="add-manually">Enter street address or postcode
                        manually</a>

                    <p-sidebar [(visible)]="openAddressForm" position="left" [baseZIndex]="10000">


                        <div class="row add-panel-close">
                            <div class="col-md-10">
                                <h5 class="add-student-heading">Add address manually</h5>
                            </div>
                            <div class="col-md-2 text-right">
                                <a (click)="openAddressForm = false">
                                    <img src="assets/icons/cross.svg" alt="">
                                </a>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="">Address 1</label>
                                    <input type="text" class="form-control" id="" placeholder="">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="">Address 2</label>
                                    <input type="text" class="form-control" id="" placeholder="">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="">Town/City</label>
                                    <input type="text" class="form-control" id="" placeholder="">
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="">Country</label>
                                    <input type="text" class="form-control" id="" placeholder="" value="">
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="">Postcode</label>
                                    <input type="text" class="form-control" id="" placeholder="" value="">
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group">
                                    <button class="btn btn-block" id="add-student-button">Save Changes</button>
                                </div>
                            </div>

                        </div>


                    </p-sidebar>
                    <!-- Enter Manually -->
                </div>
                <div class="submit-btn mb-5">
                    <button type="submit" class="btn">Submit</button>
                </div>
            </form>
        </div>

    </div>

</div>