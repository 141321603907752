<div class="parent-signup-wrapper container">
        <img src="assets/images/t-pic.png" alt="" class="t-img">
        <h1>Are you well enough to tutor ?</h1>
       <p>You must be physically and mentally fit to tutor on our platform as required by government regulations. If you believe you meet these requirements, please affirm this by confirming below.</p>
       <div class="row">
        <div class="col-md-6">
<button type="button" class="y-btn" [routerLink]="['/tutor/persoal-details']">Yes</button>

        </div>
        <div class="col-md-6">
                <button type="button" class="n-btn">No</button>
        </div>
       </div>
        </div>
    
                         
        