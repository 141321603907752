import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TutorLoginFormService {
  private optionSubject = new BehaviorSubject<string>('parent');
  public option$ = this.optionSubject.asObservable();

  setOption(option: string) {
    this.optionSubject.next(option);
  }
  constructor() {
    
   }
}
