import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-age',
  templateUrl: './student-age.component.html',
  styleUrls: ['./student-age.component.scss']
})
export class StudentAgeComponent implements OnInit {
  showOption1: boolean | undefined;
  showOption2: boolean | undefined;
  showNextButton = true;
  isUnder18Selected = true;
  constructor() { }

  ngOnInit(): void {
  }
  showText() {
    this.isUnder18Selected = true;
  }

  showTextInput() {
    this.isUnder18Selected = false;
  }

  goBack() {
    // do something when the back button is clicked
  }

  goToNextStep() {
    // do something when the continue button is clicked
  }

  // define the continue method
  continue() {
    this.goToNextStep();
  }
}

