import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { catchError, map } from 'rxjs/operators';
import { of, Subject, throwError } from 'rxjs';
import { StorageService } from './storage.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  // this api end point is to creat Services
  private CreateServiceCategoryUrl: string = environment.apiUrl + 'service/category/create/';

  private CreateServicesUrl: string = environment.apiUrl + 'service/create';
  private serviceUpdateUrl: string = environment.apiUrl + 'service/update/'

  constructor(private http: HttpClient, private storageService: StorageService) {
  }

  CreateServices(services: any) {
    return new Promise(resolve => {
      this.http.post(this.CreateServicesUrl, services).subscribe((resp: any) => {
        return resolve(resp);
      },
        (err: HttpErrorResponse) => {
          return resolve(err);
        }
      );
    });
  }

  CreateServiceCategory(services: any) {
    return new Promise(resolve => {
      this.http.post(this.CreateServiceCategoryUrl, services).subscribe((resp: any) => {
        return resolve(resp);
      },
        (err: HttpErrorResponse) => {
          return resolve(err);
        }
      );
    });
  }

  ServicesList(id: any) {
    return new Promise(resolve => {

      let ServicesListUrl = environment.apiUrl + 'service/catlog' + '?service_provider_id=' + id;
      return this.http.get(ServicesListUrl).subscribe((resp) => {
        return resolve(resp);
      },
        (err: HttpErrorResponse) => {
          return resolve(err);
        })
    })
  }

  AllServicesList(id: any) {
    return new Promise(resolve => {

      let ServicesListUrl = environment.apiUrl + 'service/list' + '?service_provider_id=' + id;
      return this.http.get(ServicesListUrl).subscribe((resp) => {
        return resolve(resp);
      },
        (err: HttpErrorResponse) => {
          return resolve(err);
        })
    })
  }

  servicesByCategory(name: string, page: any) {
    return new Promise(resolve => {
      let ServicesListUrl = environment.apiUrl + 'service/catlog/' + encodeURIComponent(name) + '?&page=' + page;
      return this.http.get(ServicesListUrl).subscribe((resp) => {
        return resolve(resp);
      },
        (err: HttpErrorResponse) => {
          return resolve(err);
        })
    })
  }

  servicesListByName(name: string) {
    return new Promise(resolve => {
      let ServicesListUrl = environment.apiUrl + 'service/catlog?q=' + encodeURIComponent(name);
      return this.http.get(ServicesListUrl).subscribe((resp) => {
        return resolve(resp);
      },
        (err: HttpErrorResponse) => {
          return resolve(err);
        })
    })
  }


  getCustomerReviews<T>(id: any, page: number) {
    let CustomersReviewUrl = environment.apiUrl + 'serviceprovider/customer/reviews' + '?service_provider_id=' + id + '&page=' + page;
    return this.http.get<T>(CustomersReviewUrl);
  }

  getServiceproviderDetails(legal_name: string) {

    console.log('service provider details')
    return new Promise(resolve => {
      //construct Update  User Profile URL
      let getServiceDetailsUrl = environment.apiUrl + 'serviceprovider/' + legal_name
      this.http.get(getServiceDetailsUrl).subscribe((resp) => {
        return resolve(resp);
      },
        (err: HttpErrorResponse) => {
          return resolve(err);
        }
      );
    });

  }

  getServicedetails(service_id: string) {

    console.log("servicename in Booknow", service_id);
    return new Promise(resolve => {
      //construct Update  User Profile URL
      let getServiceDetailsUrl = environment.apiUrl + 'service/details/' + service_id
      this.http.get(getServiceDetailsUrl).subscribe((resp) => {
        return resolve(resp);
      },
        (err: HttpErrorResponse) => {
          return resolve(err);
        }
      );
    });

  }

  getServiceCategorydetails(service_id: string) {

    console.log("servicename in Booknow", service_id);
    return new Promise(resolve => {
      //construct Update  User Profile URL
      let getServiceDetailsUrl = environment.apiUrl + 'service/category/details/' + service_id
      this.http.get(getServiceDetailsUrl).subscribe((resp) => {
        return resolve(resp);
      },
        (err: HttpErrorResponse) => {
          return resolve(err);
        }
      );
    });

  }

  updateServices(services: any) {
    return new Promise(resolve => {
      this.http.put(this.serviceUpdateUrl, services).subscribe((resp: any) => {
        return resolve(resp);
      },
        (err: HttpErrorResponse) => {
          return resolve(err);
        }
      );
    });
  }

  updateServiceCategory(services: any, category_id: number) {
    console.log("CATEGARY LSIT", services);

    let serviceCategoryUpdateUrl: string = environment.apiUrl + 'service/category/update/' + category_id;

    return new Promise(resolve => {
      this.http.put(serviceCategoryUpdateUrl, services).subscribe((resp: any) => {
        return resolve(resp);
      },
        (err: HttpErrorResponse) => {
          return resolve(err);
        }
      );
    });
  }


  getServiceList() {
    return new Promise(resolve => {
      let targetUrl = environment.apiUrl + 'service/catlog';
      this.http.get(targetUrl).subscribe((resp: any) => {
        return resolve(resp);
      },
        (err: HttpErrorResponse) => {
          return resolve(err);
        }
      );
    })

  }


  getotherService(serviceName: any) {
    return new Promise(resolve => {
      let targetUrl = environment.apiUrl + 'serviceprovider/' + serviceName + '/services';
      this.http.get(targetUrl).subscribe((resp: any) => {
        return resolve(resp);
      },
        (err: HttpErrorResponse) => {
          return resolve(err);
        }
      );
    })

  }

  getfeedbackList(serviceName: any) {
    return new Promise(resolve => {
      let targetUrl = environment.apiUrl + 'serviceprovider/' + serviceName + '/feedback';
      this.http.get(targetUrl).subscribe((resp: any) => {
        return resolve(resp);
      },
        (err: HttpErrorResponse) => {
          return resolve(err);
        }
      );
    })

  }


  deleteService(service_id: number) {
    console.log("delete id", service_id);
    return new Promise(resolve => {
      //construct Update  User Profile URL
      let getServiceDetailsUrl = environment.apiUrl + 'service/delete/' + service_id
      this.http.delete(getServiceDetailsUrl).subscribe((resp) => {
        return resolve(resp);
      },
        (err: HttpErrorResponse) => {
          return resolve(err);
        }
      );
    });

  }

  enableService(service_id: number) {
    return new Promise(resolve => {
      //construct Update  User Profile URL
      let enableServiceUrl = environment.apiUrl + 'service/enable/' + service_id
      this.http.post(enableServiceUrl, service_id).subscribe((resp) => {
        return resolve(resp);
      },
        (err: HttpErrorResponse) => {
          return resolve(err);
        }
      );
    });

  }


  deleteCategory(service_id: number) {
    console.log("delete id", service_id);
    return new Promise(resolve => {
      //construct Update  User Profile URL
      let getServiceDetailsUrl = environment.apiUrl + 'service/category/delete/' + service_id
      this.http.delete(getServiceDetailsUrl).subscribe((resp) => {
        return resolve(resp);
      },
        (err: HttpErrorResponse) => {
          return resolve(err);
        }
      );
    });

  }

  activateCategory(service_id: number) {
    console.log("delete id", service_id);
    return new Promise(resolve => {
      //construct Update  User Profile URL
      let getServiceDetailsUrl = environment.apiUrl + 'service/category/activate/' + service_id
      this.http.put(getServiceDetailsUrl, {}).subscribe((resp) => {
        return resolve(resp);
      },
        (err: HttpErrorResponse) => {
          return resolve(err);
        }
      );
    });

  }
  //This method is used to get service details by service_id
  getServiceDetail(service_id: any) {
    let targetUrl = environment.apiUrl +'service/' + service_id;
    return this.http.get<[]>(targetUrl).pipe(
      map((response: any) => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  getServiceDetailByServiceTitle(service_title: string, legal_name: string) {
    console.log('service provider details')
    return new Promise(resolve => {
      //construct Update  User Profile URL
      let getServiceDetailByServiceTitleUrl = environment.apiUrl + 'service/details?service_title=' + encodeURIComponent(service_title) + '&legal_name=' + encodeURIComponent(legal_name);
      this.http.get(getServiceDetailByServiceTitleUrl).subscribe((resp) => {
        return resolve(resp);
      },
        (err: HttpErrorResponse) => {
          return resolve(err);
        }
      );
    });

  }


  getCategories() {
    let targetUrl = environment.apiUrl + 'super_admin/business_category/popular';
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  getProviderCategoriesById = (reqObj: any) => {
    let httpParams = new HttpParams()
    httpParams = httpParams.append('latitude', JSON.parse(<string>this.storageService.getItem("latitude")))
    httpParams = httpParams.append('longitude', JSON.parse(<string>this.storageService.getItem("longitude")))
    // httpParams = httpParams.append('latitude', "17.5172484")
    // httpParams = httpParams.append('longitude', "78.3975795")
    httpParams = httpParams.append('business_category', reqObj.catId)
    httpParams = httpParams.append('page', reqObj.pageId)
    if (reqObj.service_keyword !== '') {
      httpParams = httpParams.append('service_keyword', reqObj.service_keyword)
    }
    if (reqObj.service_location !== '' && reqObj.service_location !== 'both') {
      httpParams = httpParams.append('service_location', reqObj.service_location)
    }
    httpParams = httpParams.append('sort_by', reqObj.sort_by)


    let targetUrl = environment.apiUrl + `serviceprovider/nearby_branches`;
    return this.http.get<[]>(targetUrl, {
      params: httpParams
    }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  getServiceCategoryDetails = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `branch/details/${reqObj.providerId}/${reqObj.branch_slug_title}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  getProviderServicesList = (reqObj: any) => {
    let httpParams = new HttpParams()
    if (reqObj.query !== '') {
      httpParams = httpParams.append('query', reqObj.query)
    }
    if (reqObj.sort !== '') {
      httpParams = httpParams.append('sort', reqObj.sort)
    }
    if (reqObj.availability !== '') {
      httpParams = httpParams.append('availability', reqObj.availability)
    }

    let targetUrl = environment.apiUrl + `serviceprovider/${reqObj.providerId}/${reqObj.branch_slug_title}/popular/services`;
    return this.http.get<[]>(targetUrl, { params: httpParams }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  // Top 4 services
  getTopFourServicesList = (reqObj: any) => {
    let httpParams = new HttpParams()
    let targetUrl = environment.apiUrl + `serviceprovider/popular/services?top=${reqObj.topServices}`
    return this.http.get<[]>(targetUrl, { params: httpParams }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  // Custom Provider services
  getCustomProviderServicesList = (reqObj: any) => {
    let httpParams = new HttpParams()
    let targetUrl = environment.apiUrl + `serviceprovider/popular/services`
    return this.http.get<[]>(targetUrl, { params: httpParams }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  getProviderCategoriesList = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `serviceprovider/${reqObj.providerId}/${reqObj.branch_slug_title}/popular/service_category`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  addToCart = (reqObj: any) => {
    let targetUrl = environment.apiUrl + 'customer/add_cart';
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  deleteCart = (reqObj: any) => {
    const params = {
      cart_id: reqObj
    }
    let targetUrl = environment.apiUrl + 'customer/delete_cartitem';
    return this.http.put<[]>(targetUrl, params).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }


  deleteTotalCart = () => {
    let targetUrl = environment.apiUrl + 'customer/delete_totalcart';
    return this.http.put<[]>(targetUrl, {}).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }


  cartItems = () => {
    let targetUrl = environment.apiUrl + `customer/cartdetails`;
    return this.http.get<[]>(targetUrl).pipe(
      map((response: any) => {
        this.storageService.setItem('cartItems', JSON.stringify({ results: response.length === 0 ? [] : response }));
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  getCustomerAddressList = (customerId: any) => {
    let targetUrl = environment.apiUrl + `customer/${customerId}/address/list`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  setAsDefaultAddress = (payload: any) => {
    let targetUrl = environment.apiUrl + `customer/change_default_address`;
    return this.http.put<any>(targetUrl, payload).pipe(
      map(response => {
        return response;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }


  getBranchAddressList = (serviceId: any) => {
    let httpParams = new HttpParams()
    httpParams = httpParams.append('latitude', JSON.parse(<string>this.storageService.getItem("latitude")))
    httpParams = httpParams.append('longitude', JSON.parse(<string>this.storageService.getItem("longitude")))
    // httpParams = httpParams.append('latitude', "17.5172484")
    // httpParams = httpParams.append('longitude', "78.3975795")
    let targetUrl = environment.apiUrl + `branch/address/by_service_provider/${serviceId}`;
    return this.http.get<[]>(targetUrl, {
      params: httpParams
    }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  createCustomer = (reqObj: any) => {
    let targetUrl = environment.apiUrl + 'customer/address';
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }


  servicerequest = (reqObj: any) => {
    // let targetUrl = environment.apiUrl + 'servicerequest/';
    let targetUrl = environment.apiUrl + 'booking/';
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  createReview = (reqObj: any) => {
    let targetUrl = environment.apiUrl + 'booking/rating';
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  updateReview = (reqObj: any) => {
    let targetUrl = environment.apiUrl + 'booking/update_rating';
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  deleteReview = (reqObj: any) => {
    let targetUrl = environment.apiUrl + 'booking/delete_rating';
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getReviewsList = (serviceId: any) => {
    let targetUrl = environment.apiUrl + `branch/${serviceId}/reviews`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }


  getServiceDetails = (reqObj: any) => {
    let httpParams = new HttpParams()
    httpParams = httpParams.append('latitude', JSON.parse(<string>this.storageService.getItem("latitude")))
    httpParams = httpParams.append('longitude', JSON.parse(<string>this.storageService.getItem("longitude")))
    // httpParams = httpParams.append('latitude', "17.5172484")
    // httpParams = httpParams.append('longitude', "78.3975795")
    let targetUrl = environment.apiUrl + `service/details/${reqObj.categoryId}/${reqObj.serviceTitle}`;
    return this.http.get<[]>(targetUrl, {
      params: httpParams,
    }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  getAvailableTimeSlots = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `booking/available_slots?branch_id=${reqObj.branchId}&booking_date=${reqObj.bookingDate}&lead_staff=${reqObj.staffId}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  getStaffList = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `employee/staff/by_branch/${reqObj.branchId}?service_id=${reqObj.serviceId}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  getBookingsList = (reqObj: any) => {
    let httpParams = new HttpParams()
    httpParams = httpParams.append('query', reqObj.query)
    httpParams = httpParams.append('page', reqObj.pageId)
    httpParams = httpParams.append('start', reqObj.startDate)
    httpParams = httpParams.append('end', reqObj.endDate)

    let targetUrl = environment.apiUrl + `booking/list/by_customer`;
    return this.http.get<[]>(targetUrl, {
      params: httpParams
    }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  getCustomerProfile = (customerId: any) => {
    let targetUrl = environment.apiUrl + `customer/${customerId}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  updateCustomerProfile = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `customer/update`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  changePassword = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `auth/changepassword`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  uploadFile = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `upload/file`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  saveRegisterYourBusiness = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `serviceprovider/`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  cancelBooking = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `booking/cancel`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  cancelBookingBySelectedServices = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `booking/cancel_selected_services`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  createPaymentOrder = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `transaction/razorpay/order_creation`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  verifyPaymentCheckout = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `transaction/razor_pay/verfiy_payment_checkout`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }


  getWalletBalance = () => {
    let targetUrl = environment.apiUrl + `customer/wallet_balance`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  // getMyTransctions = (pageId: any) => {
  //   let targetUrl = `${environment.apiUrl}transaction/wallet_transactions?page=${pageId}`;
  //   let targetUrl = `${environment.apiUrl}transaction/wallet_transactions?page=${reqObj.pageId}&startDate=${reqObj.startDate}&endDate=${reqObj.endDate}`;
  //   return this.http.get<[]>(targetUrl).pipe(
  //     map(response => {
  //       return response
  //     }),
  //     catchError(err => {
  //       return throwError(err);
  //     }))
  // }

  getMyTransctions = (reqObj: any) => {
    let targetUrl = `${environment.apiUrl}transaction/wallet_transactions?page=${reqObj.pageId}&start=${reqObj.startDate}&end=${reqObj.endDate}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  getWalletTransctions = (pageId: any) => {
    let targetUrl = `${environment.apiUrl}customer/wallet_transactions?page=${pageId}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  paymentRefund = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `transaction/razor_pay/${reqObj.paymentId}/refund`;
    return this.http.post<[]>(targetUrl, { amount: reqObj.amount }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }


  stripePayment = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `transaction/stripe/payment_intent`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  confirmStripePayment = (reqObj: any) => {
    const httpOptions = {
      responseType: 'text' as 'json'
    };
    let targetUrl = environment.apiUrl + `transaction/stripe/confirm_payment_intent/${reqObj.paymentId}`;
    return this.http.post<[]>(targetUrl, { 'payment_method': reqObj.paymentMethod }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  stripePaymentRefund = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `transaction/stripe/refund`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  subscribeUs = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `customer/subscribeus`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  requestQuote = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `inquiries/new`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }



  getStaffDetails = (reqObj: any) => {
    // let targetUrl = environment.apiUrl + `employee/by_branch/${reqObj}`;
    let targetUrl = environment.apiUrl + `employee/by_branch/${reqObj.branchId}?role=Staff`;
    return this.http.get<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getWeekSchedule = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `branch/week_schedule/${reqObj.branchId}`;
    return this.http.get<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  submitRecurringBooking(payload: any) {
    const targetUrl = environment.apiUrl + `booking/recurring`;

    return this.http.post<any>(targetUrl, payload).pipe(
      map(response => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getBusinessCategory = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `super_admin/business_category/autocomplete?query=${reqObj.keyword}`;
    return this.http.get<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  checkoutSession = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `transaction/stripe/checkout_session`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }


  handleError(error: any) {
    let errorMessage: any;
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = {
        errorCode: `${error.error.status_code}`,
        message: `${error.error.message}`
      }

    }
    console.log(errorMessage);
    return of(errorMessage);
  }

  getLocalDateTime = (dt: any) => {
    let dtp = moment(dt).utc().local().format('DD-MM-YYYY  hh:mm A')
    return dtp;
  }

  deleteCustomerAddress(addressId: any) {
    let targetUrl = environment.apiUrl + `customer/address/delete/${addressId}`;
    return this.http.put<any>(targetUrl, {}).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  editCustomerAddress(reqObj: any) {
    let targetUrl = environment.apiUrl + `customer/address/update`;
    return this.http.put<any>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  saveContact = (reqObj: any) => {
    let targetUrl = environment.apiUrl + 'cms/contactus/save';
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getHolidayList = (reqObj: any) => {
    let httpParams = new HttpParams()
    if (reqObj.query !== undefined && reqObj.query !== '') {
      httpParams = httpParams.append('query', reqObj.query)
    }
    if (reqObj.page !== undefined && reqObj.page !== '') {
      httpParams = httpParams.append('page', reqObj.page)
    }
    let targetUrl = environment.apiUrl + `branch/holidaylist/${reqObj.branchId}`;
    return this.http.get<[]>(targetUrl, {
      params: httpParams
    }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  invoiceServiceRequest = (reqObj: any) => {
    let httpParams = new HttpParams();
    
    let headers = new HttpHeaders();
    headers= headers.append('content-type', 'application/pdf');

    if (reqObj.service_request !== undefined && reqObj.service_request !== '') {
      httpParams = httpParams.append('service_request', reqObj.service_request)
    }
    let targetUrl = environment.apiUrl + `invoice/by_service_request`;
    return this.http.get<[]>(targetUrl, {
      params: httpParams,
      headers: headers,
    }).pipe(map((data: any) => {
      console.log(data)
      let blob = new Blob([data], {
          type: 'application/pdf' // must match the Accept type
          // type: 'application/octet-stream' // for excel 
      });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'samplePDFFile.pdf';
      link.click();
      window.URL.revokeObjectURL(link.href);

  }),
    // .pipe(
      // map(response => {
      //   return response
      // }),
      catchError(err => {
        console.log(err)
        return of(err);
      }))
  }
  

  createSubscription = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `subscription/create_subscription`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  updateSubscription = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `subscription/update_subscription`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  

  
  getServiceDetailsById = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `service/${reqObj.serviceId}`;
    return this.http.get<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }
}

