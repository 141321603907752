export class RouteConstants {
  static HOME_PAGE = "home"
  static RATINGS_AND_REVIEWS = "ratings-and-reviews"
  static REGISTER_YOUR_BUSINESS = "register-your-business"
  static TOP_SERVICES = "popular-services"
  static SERVICE_RATING = "service-ratings"
  static FREE_TRAIL = "register-free-trail"
  static MY_BOOKINGS = "my-bookings"
  static CART = "cart"
  static CANCEL_BOOKINGS = "cancel-bookings"
  static AVAILABILITY = "availability"
  static PAYMENT = "payment"
  static CANCELLED = 'cancelled'
  static SIGNIN = 'sign-in'
  static SIGNUP = 'sign-up'
  static FORGOTPIN = 'forgot-pin'
  static PROVIDER_DETAILS = "provider"
  static PROVIDERS_LIST = "providers"
  static SERVICE_DETAILS = "service-info"
  static PROFILE = "profile"
  static CHANGEPASSWORD = "password"
  static WISHLIST = "wishlist"
  static WALLET = "wallet"
  static MYTRANSACTIONS = "my-transactions"
  static PAYMENT_SUCCESS = "payment-success"
  static PAYMENT_FAIL = "payment-fail"
  static REQUEST_QUOTE = "request-quote"
  static ABOUTUS = 'about-us'
  static CAREERS = 'careers'
  static CONTACTUS = 'contact-us'
  static TERMSANDCONDITIONS = 'terms-conditions'
  static PRIVACYPOLICY = 'privacy-policy'
  static PLANS = 'plans'
  static SPORTSBASEDTHEME = 'sports-based-theme'
  static BEAUTYTHEME = 'beauty-theme'
  static BOOKINGTHEME = 'booking-theme'
  static ONLINETUTOR = 'tutor-theme'
  static VIEWTUTOR = 'view-tutors'
  static TUTORPROFILE = 'tutor-profile'
  static TUTORSIGNUP = 'tutor-signup'
  static STUDENTPROFILE = 'studentprofile'
  static STUDENTMESSGAES = 'student/messages'
  // static TUTORPROFILE ='tutor-profile'
  // static TUTORSIGNUP ='tutor-signup'
  static TUTORLOGIN = 'tutor-login'
  static TUTORLOGINPAGE = 'tutor/login'
  static MYTUTORSOGNUP = 'mytutor/signup'
  static STUDENTBOOKINGS = 'student-bookings'
  static TUTORBOOKINGS = 'tutor-bookings'
  static BOOKLESSONS = 'book-lessons'
  static MYTUTORSIGNUP = 'tutor/signup'
  static PARENTSSIGNUP = 'parent/signup'
  static STUDENTSIGNUP = 'student/signup'
  static MYTUTORSIGNUPFORM = 'tutor/signup/form'
  static MYTUTOR_LOGINPAGE = 'mytutor/login/page'
  static TUTOR_APPLICATION_PROCESS = 'tutor/application/process'
  static TUTORQUALIFICATION = 'tutor/qualification'
  static TUTOR_S_QUALIFICATION = 'tutor/school/qualification'
  static TUTORSUBJECT = 'tutor/subject/level'
  static TUTORADD_QUALIFICATION = 'tutor/additional/qualification'
  static TUTOR_SUBJECT_LEVEL = 'tutor/study/subjects'
  static TUTOR_GRADUATION_CERTIFICATE = 'tutor/graduation/certificates'
  static MYTUTOR_PERSONAL_DETAILS = 'tutor/persoal-details'
  static TUTOR_ENOUGH_DETAILS = 'tutor/enough/details'
  static TUTOR_APPLICTION = 'tutor/application'
  static TUTOR_APPOINMENT='tutor/appointment'
  static PARENTPROFILE = 'parent/profile'
  static STUDENTSLIST = 'parents/students-list'
  static CONTACTTUTOR = 'student/confirmation'
  static TUTORHEADER='tutor/header'
  static PARENTHOME = 'parent/home'
  static STUDENTHOME = 'student/home'
  static WINDOWHOME = 'window/home'
  static STUDENTCHECKOUT='student-checkout'
  static TUTORADDSUBJECT='tutor-add-subject'
  static TUTORDASHBOARD='tutor-dashboard'
  static TUTORFORMSTEPS='tutor-form-steps'
  static PERSONALDETAILS='personal-details'
  static STUDENTPERSONALDETAILS='student_personal_details'
  static STUDENTSIGNUPStatus='student/signup/success'
  static STUDENTCHANGEPASSWORD='student/Changepassword'
  static CHOOSESUBJECT='choose/subject'
  static CHOOSELEVEL='choose/level'
  static STUDENTAGE='student/age'
  static PRIMARYSTUDENTSIGNUP='primary/student/signup'
  static ALL_SERVICES = 'services'
  static SERVICE_INFO = "s-info"
  }
