import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-service',
  templateUrl: './about-service.component.html',
  styleUrls: ['./about-service.component.scss']
})

export class AboutServiceComponent implements OnInit {

  writingReview: boolean | undefined;
  reviewSuccess:boolean | undefined;

  constructor() {
    this.reviewSuccess=false;

  }


  ngOnInit(): void {
  }

  showSuccess() {
    this.reviewSuccess = true;
    this.writingReview=false;
  }


}

