<app-breadcrumb></app-breadcrumb>
<div class="container">
    <div class="outer-box">
        <div class="title">Top {{selectedCategory}}</div>
        <div class="row m-0" *ngIf="serviceCategoryList">
            <div class="grid-p-cat" *ngFor="let cat of serviceCategoryList">
                <app-popular-category-card [cardObj]="cat"></app-popular-category-card>
            </div>
        </div>
        <!-- <div class="popular-services" *ngIf="serviceCategoryList">
            <div class="inner-padding" *ngFor="let cat of serviceCategoryList" (click)="cardClick()">
                <div class="card-service-p">
                    <div class="img-block">
                        <img [src]="cat.image">
                    </div>
                    <div class="service-title" title="{{cat.title}}">{{cat.title}}</div>
                </div>
            </div>
        </div> -->
    </div>
</div>

<app-footer></app-footer>