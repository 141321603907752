<div class="bg-color-of-pages">
  <app-breadcrumb></app-breadcrumb>
  <!-- <app-provider-card></app-provider-card> -->
  <div class="container provider-details-cards" *ngIf="providersDetails">
    <div class="service-provider-details">
      <div class="img-block">
        <img [src]="providersDetails?.service_provider?.logo_url">
      </div>
      <div class="content-sec">
        <div class="service-name">{{providersDetails?.legal_name}}</div>
        <div class="branch-name">{{providersDetails?.service_branch_name}}</div>
        <div class="rating-sec">
          <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  1 <= providersDetails?.avg_rating}"></i>
          <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  2 <= providersDetails?.avg_rating}"></i>
          <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  3 <= providersDetails?.avg_rating}"></i>
          <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  4 <= providersDetails?.avg_rating}"></i>
          <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  5 <= providersDetails?.avg_rating}"></i>
          <span>{{providersDetails?.avg_rating}} ({{providersDetails?.review_count}}
            Reviews)</span>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="reviews-list-block">
      <div class="write-user-review" *ngIf="writingReview">
        <form [formGroup]="reviewForm" (ngSubmit)="saveFeedback($event)">
          <div class="review-input">
            <div class="title-max-text">
              <div class="label-text">Your Review <sup>*</sup></div>
              <div class="text-max-count">Maximum 2000 Characters.</div>
            </div>
            <div class="text-area-block mb-2">
              <textarea class="write-review-input" formControlName="review"></textarea>
              <span class="error-text" *ngIf="reviewControls.invalid  && formSubmited">Review required</span>
            </div>
            <div class="review-rating">
              <div class="label-text">Your Ratings</div>
              <div class="progress-ratings f-s-25">
                <div>
                  <ngx-star-rating formControlName="rating" id='rating9'></ngx-star-rating>
                </div>
                <div>
                  <span class="error-text" *ngIf="ratingControls.value === 1 || ratingControls.value === 2">Bad
                    Experience 😔</span>
                  <span class="success-text" *ngIf="ratingControls.value === 3">Average 😔</span>
                  <span class="success-text" *ngIf="ratingControls.value === 4">Good <i class="bi bi-check-circle-fill"></i></span>
                  <span class="success-text" *ngIf="ratingControls.value === 5">Excellent <i class="bi bi-check-circle-fill"></i></span>
                </div>
              </div>
              <span class="error-text" *ngIf="ratingControls.invalid  && formSubmited">Rating required</span>


            </div>
            <div *ngIf="errMsg" class="errMsg">{{errMsg}}</div>
            <button class="sb-primary-btn" type="submit">Submit your review</button>
          </div>
        </form>
      </div>
      <div class="user-send-success-review" *ngIf="reviewSuccess">
        <div class="img-block-check">
          <img src="assets/icons/reviews/successcheck.svg">
        </div>
        <div class="thanks-text">Thank You! we appreciate your time,<br /> Your review has been submitted.</div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="reviewsList">
    <div class="review-card-component" *ngIf="reviewsList?.length > 0">
      <ng-container *ngFor="let obj of reviewsList">
      <div class="inner-data-reviews">
        <div class="img-block">
          <img [src]="obj.customer.profile_pic" alt="Avatar1"
            *ngIf="obj.customer.profile_pic!=null && obj.customer.profile_pic!= '' && obj.customer.profile_pic!= undefined">
          
          <ng-container *ngIf="obj.customer.gender !== null">
            <img
              *ngIf="obj.customer.gender === 'Male' && (obj.customer.profile_pic === undefined || obj.customer.profile_pic === null || obj.customer.profile_pic === '')"
              src="assets/icons/reviews/avatar-profile.png" alt="Male Avatar">
            <img
              *ngIf="obj.customer.gender === 'Female' && (obj.customer.profile_pic === undefined || obj.customer.profile_pic === null || obj.customer.profile_pic === '')"
              src="assets/icons/reviews/avatar-rating.png" alt="Female Avatar">
          </ng-container>

        </div>

        
        <div class="content-block">
          <div class="rating-data-with-name">
            <div class="rating-sec">
              <!-- <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill disabled-rate"></i> -->
              <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  1 <= obj?.rating}"></i>
              <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  2 <= obj?.rating}"></i>
              <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  3 <= obj?.rating}"></i>
              <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  4 <= obj?.rating}"></i>
              <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  5 <= obj?.rating}"></i>
            </div>
            <div class="u-name">{{obj?.customer?.user?.first_name +' '+ obj?.customer?.user?.last_name }}</div>
            <div class="time">- {{dataService.getLocalDateTime(obj?.datetime)}}</div>
          </div>
          <div class="review-desc">{{obj?.review}}</div>
          <div class="edit-delete-icons" *ngIf="+obj?.customer?.customer_id === +cutomerId && writingReview">
            <div class="edit-review" (click)="onEdit(obj)">Edit Review</div>
            <div class="delete-review"  (click)="deleteFeedback(obj)">Delete</div>
          </div>
        </div>
      </div>
      <div class="reply-desc">
        <div class="d-flex justify-content-between mb-2">
            <div class="b-name">{{obj?.serviceprovider_branch?.branch_manager?.first_name + '' +obj?.serviceprovider_branch?.branch_manager?.last_name}}</div>
            <div class="time">{{obj?.reply_given_on | date:'dd/MM/YYYY'}}</div>
        </div>
        {{obj?.reply}}
      </div>
    </ng-container>
    </div>
  </div>
  <div class="container text-center" *ngIf="reviewsList?.length == 0">
    No data available
  </div>
</div>
<!-- <app-provider-card></app-provider-card> -->

<app-footer></app-footer>