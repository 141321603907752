import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cancel-booking',
  templateUrl: './cancel-booking.component.html',
  styleUrls: ['./cancel-booking.component.scss']
})
export class CancelBookingComponent implements OnInit {

  orderId: any;
  orderCount:any;
  serviceNames: any;
  constructor(private activatedRoute: ActivatedRoute, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.orderId = params.orderId;
      this.orderCount = +params.orderCount;
      this.serviceNames = params.sn;
      console.log(this.serviceNames)
      console.log(typeof this.serviceNames)
      this.cdr.detectChanges();
    });
  }


}
