import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-choose-level',
  templateUrl: './choose-level.component.html',
  styleUrls: ['./choose-level.component.scss']
})
export class ChooseLevelComponent implements OnInit {
  selectedBox: string | undefined;
  inputVisible: boolean | undefined;

  constructor() { }
  selectBox(box: string) {
    this.selectedBox = box;
  }
  hideInput() {
    this.inputVisible = false;
  }

  showInput() {
    this.inputVisible = true;
  }
  ngOnInit(): void {
  }

}
