<div class="parent-signup-wrapper ">
    <div class="header-session">
     </div>
     <div class="tutor-signup container">
        <img src="assets/images/t-parent.png" alt="">
         <h1>We have received your application</h1>
         <p>We're examining your application and will send you an email within the next two work days. If you need help, just let us know. </p>
        
      
             
<!--              
             <div class="button-wrapper mt-5">
                 <button class="btn-next" type="button" Next [routerLink]="['/tutor/subject/level']">Next
                 <!-- <img src="assets/icons/arrow-right-n.svg" alt="">     -->
                 <!-- </button>
             </div>  -->
       
 
 
 
                   
               
        
 
        
         </div>
     </div>
 
                      
     