import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-tutors',
  templateUrl: './view-tutors.component.html',
  styleUrls: ['./view-tutors.component.scss']
})
export class ViewTutorsComponent implements OnInit {
  date2: Date | undefined;
  cities: any; 
  selectedLevel= 'Masters';
  selectedSubjects='Physics';
  selectedprice='select price';
  selectedGender='male';
  icon: any;
  liked: any;
  isFavorited = false;
  showNavbar!: true;
  toggleFavorite() {
    this.isFavorited = !this.isFavorited;
  }

  toggleFavorite1() {
    this.isFavorited = !this.isFavorited;
  }
  constructor(private el: ElementRef) { 
    
    this.icon = el.nativeElement.querySelector('.icon');

  }

  changeIcon() {
    // this.icon.innerHTML = '<i class="fas fa-check"></i>';

}

  ngOnInit(): void {
  }
  subjects : any = [
    {name: 'Physics'},
    {name: 'Mathematics'},
    {name:'Biology'},
    {name:'Computing'}
  ]
  gender:any=[
    {name:'Male'},
    {name:'Female'},
    {name:'All Gender'}
  ]
  levels:any=[
    {name:'Degree'},
    {name:'PHD'},
    {name:'Masters'}
  ]
  price:any=[
    {name:'$0'},
    {name:'$200'},
    {name:'$300'},
    {name:'$100'}
  ]
  tutorsList= [
    {tutorimg: 'assets/images/tutor-p.jpg', tutorname: 'John.S', tutoreducation: 'History V100 (with German module) (Bachelors) - Durham University',qualification:'Hi! My name’s Will and I’m studying an MPhil in European History at Cambridge, specializing in the history of Germany',tutorfee:'T.£37 - £39', perhr: '/hr', ratingicon: 'assets/icons/star.svg', rating: '5', review: '612 reviews',lesson_completed:'assets/icons/memo-pad.svg',lessons:'completed lessons'},

    {tutorimg: 'assets/images/tutor-two.jpg',tutorname: 'Khatija.k', tutoreducation: 'Psychology (Bachelors) - Glasgow University',tutorfee:'T.£25 - £28', perhr: '/hr', ratingicon: 'assets/icons/star.svg', rating: '5', review: '216 reviews',lesson_completed:'assets/icons/memo-pad.svg',lessons:'completed lessons'},

    {tutorimg: 'assets/images/tutor-p.jpg', tutorname: 'Davina P.', tutoreducation: 'Psychology (Bachelors) - Glasgow University',tutorfee:'T.£37 - £39', perhr: '/hr', ratingicon: 'assets/icons/star.svg', rating: '5', review: '60 reviews',lesson_completed:'assets/icons/memo-pad.svg',lessons:'completed lessons'},

    {tutorimg: 'assets/images/tutor-pone.jpg', tutorname: 'John.S', tutoreducation: 'History V100 (with German module) (Bachelors) - Durham University',tutorfee:'T.£37 - £39', perhr: '/hr', ratingicon: 'assets/icons/star.svg', rating: '5', review: '56 reviews',lesson_completed:'assets/icons/memo-pad.svg',lessons:'completed lessons'},

  ]
}
