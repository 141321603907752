<header *ngIf="isHeaderVisible">
  <div class="header-container"
    *ngIf="currentRoute !== '/sign-in' && currentRoute !== '/sign-up' && currentRoute !== '/forgot-pin'">
    <div class="top-bar container d-none">
      <div class="location-info">
        <i class="bi bi-geo-alt-fill"></i>
        <span class="loaction-long-lat">{{userLocation}}</span>
      </div>
      <div class="contact-mail">
        <i class="bi bi-envelope"></i>
        <span class="mail-id">contact@scheduleitnow.co.uk</span>
      </div>
      <div class="call-whats-app-info">
        <div class="whatsapp">
          <i class="bi bi-whatsapp"></i>
        </div>
        <div class="call">
          <i class="bi bi-telephone"></i>
          <span class="call-number">+91 99382776323</span>
        </div>
      </div>
    </div>
    <div class="bottom-bar">
      <div class="container d-flex align-items-center">
        <div class="logo-sec col-md-2 p-0" routerLink="/home">
          <!-- <img src="assets/icons/login-signup/logo-serve.svg"> -->
          <img src="{{siteLogo}}" alt="site logo" />
        </div>

        <div class="menu-items col-md-4 p-0">
          <ul>
            <li><a routerLink="/home">Home</a></li>
            <li *ngIf="isServiceProvider"><a routerLink="/services">Services</a></li>
            <li><a routerLink="/about-us">About Us</a></li>
            <!-- <li><a routerLink="/services">Gallery</a></li> -->
            <li><a routerLink="/contact-us">Contact Us</a></li>
          </ul>
        </div>

        <!-- <div class="location-sec col-md-4 p-0">
          <div *ngIf="isHome" ngxHideOnScroll  data-toggle="modal" data-target="#locationModal"
          [propertyUsedToHide]="'transform'"
          [valueWhenHidden]="'translateY(0%)'"
          [valueWhenShown]="'translateY(-120%)'"
          class="location-info" [title]="userLocation">
            <i class="bi bi-geo-alt-fill"></i>
            <span class="loaction-long-lat">{{userLocation}}</span>
          </div>
          <div *ngIf="!isHome" 
          class="location-info" [title]="userLocation">
            <i class="bi bi-geo-alt-fill"></i>
            <span class="loaction-long-lat">{{userLocation}}</span>
          </div>
        </div> -->
        <div class="right-sec col-7 p-0">
          <!-- <div class="sb-dropdonws d-none" ngbDropdown placement="bottom">
            <button class="dropdown-input" ngbDropdownToggle></button>
            <i class="seach-icon bi bi-search"></i>
            <input ngbDropdownToggle (keyup)="showResults($event)" placeholder="Search for Services" type="text" class="search-data">
            <ul class="dropdown-menu" ngbDropdownMenu>
              <li *ngFor="let item of searchResults" ngbDropdownItem>
                <a class="dropdown_item">{{item.item}}</a>
              </li>
            </ul>
          </div> -->
          <!-- <div class="register-business-link" (click)="goToRegisterBusiness()" *ngIf="!showAccount">Register Your Business</div> -->
          <button *ngIf="!showAccount" class="sb-primary-btn" id="login-signup-btn" [routerLink]="['sign-in']"><i
              class="bi bi-person"></i> Login / Sign Up</button>
          <div *ngIf="showAccount" class="sb-dropdonws after-login-info" ngbDropdown placement="bottom-right">
            <button class="dropdown-input" ngbDropdownToggle>
              <div class="img-block">
                <img src="assets/icons/user.svg">
              </div>
              <div class="name-user">{{userDetails?.name}}</div>
              <i class="bi bi-caret-down-fill"></i>
            </button>
            <ul calss="dropdown-menu" ngbDropdownMenu id="profile-dropdown">
              <li ngbDropdownItem>
                <a class="dropdown_item" [routerLink]="'/profile'">
                  <div class="icon-block my-profile">
                    <img src="assets/icons/user.svg">
                  </div>
                  <div class="list-name">My Profile</div>
                </a>
              </li>
              <li ngbDropdownItem>
                <a class="dropdown_item" [routerLink]="'/my-bookings'">
                  <div class="icon-block bookings">
                    <img src="assets/icons/calendar.svg">
                  </div>
                  <div class="list-name">My Bookings</div>
                </a>
              </li>
              <li ngbDropdownItem>
                <a class="dropdown_item" [routerLink]="'/password'">
                  <div class="icon-block changepassword">
                    <img src="assets/icons/key.svg">
                  </div>
                  <div class="list-name">Change Password</div>
                </a>
              </li>
              <li ngbDropdownItem>
                <a class="dropdown_item" [routerLink]="'/my-transactions'">
                  <div class="icon-block transactions">
                    <img src="assets/icons/transactions.svg">
                  </div>
                  <div class="list-name">My Transactions</div>
                </a>
              </li>
              <!-- <li ngbDropdownItem>
                <a class="dropdown_item" [routerLink]="'/wishlist'">
                  <div class="icon-block settings">
                    <img src="assets/icons/settings.svg">
                  </div>
                  <div class="list-name">My Wishlist</div>
                </a>
              </li> -->
              <li ngbDropdownItem>
                <a class="dropdown_item" (click)="logout()">
                  <div class="icon-block log-out">
                    <img src="assets/icons/log-in.svg">
                  </div>
                  <div class="list-name">Log Out</div>
                </a>
              </li>
            </ul>
          </div>
          <div class="cart-data" (click)="goToCartPage()" *ngIf="showAccount">
            <img src="assets/icons/cart.svg">
            <span class="cart-count">{{cartCount}}</span>
          </div>
          <div class="wallet-info" [routerLink]="'/wallet'" *ngIf="showAccount">
            <i class="bi bi-wallet-fill"></i>
            <span class="w-amount">{{+walletAmt | currency: currencyCode:'symbol':'1.0-0'}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="mobile-header"
    *ngIf="currentRoute !== '/sign-in' && currentRoute !== '/sign-up' && currentRoute !== '/forgot-pin'">
    <div class="top-header-info">
      <!-- <div class="logo-name" routerLink="/home">LOGO</div> -->

      <div class="signin-logo">
        <a [routerLink]="['/home']">
          <!-- <img src="assets/icons/login-signup/logo-serve.svg" alt="" class="logo-wrapper"> -->
          <img src="{{siteLogo}}" alt="site logo" class="logo-wrapper" />
        </a>
      </div>
      <div class="righ-sec-mobile">
        <!-- <div class="sb-dropdonws" ngbDropdown placement="bottom">
          <button class="dropdown-input" ngbDropdownToggle></button>
          <i class="seach-icon bi bi-search"></i>
          <input ngbDropdownToggle (keyup)="showResults($event)" placeholder="Search for Services" type="text" class="search-data">
          <ul class="dropdown-menu" ngbDropdownMenu>
            <li *ngFor="let item of searchResults" ngbDropdownItem>
              <a class="dropdown_item">{{item.item}}</a>
            </li>
          </ul>
        </div> -->


        <!-- <div class="wallet-info m-0" [routerLink]="'/wallet'" *ngIf="showAccount">
          <i class="bi bi-wallet-fill"></i> 
          <span class="w-amount">{{+walletAmt | currency : currencyCode}}</span>
        </div> -->
        <div class="cart-data" (click)="goToCartPage()" *ngIf="showAccount">
          <img src="assets/icons/cart.svg">
          <span class="cart-count">{{cartCount}}</span>
        </div>
        <!--  -->
        <div class="my-profile-menu" (click)="profileMenu()">
          <img src="assets/icons/user.svg">
        </div>
        <!--  -->
        <div class="hamberger-menu" (click)="toggleMenu()">
          <p [hidden]="isMainMenuOpen">
            <img src="assets/icons/open-menu.svg" alt="">
          </p>
          <p [hidden]="!isMainMenuOpen">
            <img src="assets/icons/close-menu.svg" alt="">
          </p>
        </div>
        <!--  -->
      </div>
    </div>
    <div id="demo" class="collapse down-inner-data" [ngClass]="{ 'collapse': !isMainMenuOpen }">
      <div class="mobile-main-menu">
        <ul>
          <li><a (click)="navigateTo('/home')">Home</a></li>
          <li><a (click)="navigateTo('/services')" *ngIf="isServiceProvider">Services</a></li>
          <li><a (click)="navigateTo('/about-us')">About Us</a></li>
          <li><a (click)="navigateTo('/contact-us')">Contact Us</a></li>
        </ul>
      </div>
    </div>

    <div id="profile-main-menu" class="collapse down-inner-data" [ngClass]="{ 'collapse': !isProfileMenuOpen }">
      <div class="location-info d-none" [title]="userLocation" data-toggle="modal" data-target="#locationModal">
        <i class="bi bi-geo-alt-fill"></i>
        <span class="loaction-long-lat">{{userLocation}}</span>
      </div>
      <div class="divider" *ngIf="showAccount"></div>
      <div class="wallet-bal-wrapper">
        <div class="wallet-bal-wrapper-inner">
          <p class="wb-heading" *ngIf="showAccount">
            Wallet Balance
          </p>
        </div>
        <div class="wallet-bal-wrapper-inner text-right">
          <div class="wallet-info m-0" [routerLink]="'/wallet'" *ngIf="showAccount">
            <i class="bi bi-wallet-fill"></i>
            <span class="w-amount">{{+walletAmt | currency : currencyCode}}</span>
          </div>
        </div>
      </div>
      <div class="divider" *ngIf="showAccount"></div>
      <div class="d-flex align-items-center flex-column justify-content-between border-divide">
        <!-- <div class="register-business-link text-center" (click)="hideHamburgerMenu();goToRegisterBusiness()" *ngIf="!showAccount">Register Your Business</div> -->
        <button *ngIf="!showAccount" class="sb-primary-btn btn-block mt-2" id="login-signup-btn"
          [routerLink]="['sign-in']">Login / Sign Up</button>
        <ul *ngIf="showAccount" class="mobile-action-profiles">
          <li>
            <a class="dropdown_item" (click)="navigateTo('/profile')">
              <div class="icon-block my-profile">
                <img src="assets/icons/user.svg">
              </div>
              <div class="list-name">My Profile</div>
            </a>
          </li>
          <li>
            <a class="dropdown_item" (click)="navigateTo('/my-bookings')">
              <div class="icon-block bookings">
                <img src="assets/icons/calendar.svg">
              </div>
              <div class="list-name">My Bookings</div>
            </a>
          </li>
          <li>
            <a class="dropdown_item" (click)="navigateTo('/password')">
              <div class="icon-block settings">
                <img src="assets/icons/key.svg">
              </div>
              <div class="list-name">Change Password</div>
            </a>
          </li>
          <li>
            <a class="dropdown_item" (click)="navigateTo('/my-transactions')">
              <div class="icon-block transactions">
                <img src="assets/icons/transactions.svg">
              </div>
              <div class="list-name">My Transactions</div>
            </a>
          </li>
          <!-- <li>
            <a class="dropdown_item" [routerLink]="'/wishlist'">
              <div class="icon-block settings">
                <img src="assets/icons/settings.svg">
              </div>
              <div class="list-name">My Wishlist</div>
            </a>
          </li> -->
          <li>
            <a class="dropdown_item" (click)="logout()">
              <div class="icon-block log-out">
                <img src="assets/icons/log-in.svg">
              </div>
              <div class="list-name">Log Out</div>
            </a>
          </li>
        </ul>
      </div>
      <!-- <div class="contact-mail">
        <i class="bi bi-envelope"></i>
        <span class="mail-id">contact@scheduleitnow.co.uk</span>
      </div>
      <div class="call-whats-app-info">
        <div class="whatsapp">
          <i class="bi bi-whatsapp"></i>
        </div>
        <div class="call">
          <i class="bi bi-telephone"></i>
          <span class="call-number">+91 99382776323</span>
        </div>
      </div> -->
    </div>
  </div>
</header>

<!-- <div class="fixed-scroll-top" *ngIf="currentRoute !== '/sign-in' && currentRoute !== '/sign-up'">
  <img src="assets/icons/scroll-top.svg" (click)="onScroll()">
</div> -->