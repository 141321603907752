import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RouteConstants } from 'src/app/model/route-constants';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

declare var Razorpay: any;
declare var Stripe: any;
declare var $: any;
@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {
  walletList: any = [];
  walletAmt: any;
  pageNo = 1;
  hideWalletScreen = false;
  formSubmited = false;
  addWalletForm: any;
  tempWalletList: any;
  stripe: any;
  currencyCode = environment.currencyCode;
  constructor(public storageService: StorageService, public dataService: DataService, public router: Router,
    private bs: BroadcastService, private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    // this.stripe = Stripe(environment.stripKey);
    this.walletAmt = parseInt(JSON.parse(<string>this.storageService.getItem('walletAmt')));
    this.getWalletTransctions();
    this.addWalletForm = new FormGroup({
      amount: new FormControl('', [Validators.required, Validators.pattern(new RegExp("[0-9 ]$"))]),
    });
  }

  get walletFormControls() {
    return this.addWalletForm.controls;
  }

  getWalletTransctions = () => {
    this.dataService.getWalletTransctions(this.pageNo).subscribe((res: any) => {
      if (res) {
        this.tempWalletList = res.results;
        for (let i = 0; i < this.tempWalletList.length; i++) {
          this.tempWalletList[i].amount = Math.round(this.tempWalletList[i].amount);
          this.walletList.push(this.tempWalletList[i]);
        }
      }
    })
  }

  onScrollDown(ev: any) {
    this.pageNo += 1;
    this.getWalletTransctions();
    console.log("scrolled down!!", ev);

  }

  onScrollUp(ev: any) {
    console.log("scrolled up!", ev);
    this.pageNo += 1;
  }

  onSubmit = (event: any) => {
    event.preventDefault();
    this.formSubmited = true;
    if (this.addWalletForm.valid) {
      if (environment.paymentMode === 'stripe') {
        this.stripePaymentSuccess();
      } else {
        this.createRzpayOrder();
      }
    }
  }

  // call api to create order_id
  createRzpayOrder() {
    const reqObj = {
      amount: parseInt(this.addWalletForm.value.amount),
      for_wallet: true
    }
    this.dataService.createPaymentOrder(reqObj).subscribe((res: any) => {
      if (res && res.Error === undefined) {
        this.payWithRazor(res);
      }
    })

  }

  payWithRazor = (val: any) => {
    const payOptions: any = {
      'key': environment.razorPayKey,
      'amount': this.addWalletForm.value.amount * 100, // 2000 paise = INR 20
      'name': 'ScheduleItNow',
      'description': 'Quein Wallet',
      'image': 'assets/icons/login-signup/logo-serve.svg',
      'handler': (response: any) => {
        this.completeTransaction(response);
      },
      'order_id': val.order_id,
      'prefill': {
        'name': JSON.parse(<string>this.storageService.getItem('user_info')).name,
        'email': JSON.parse(<string>this.storageService.getItem('user_info')).email,
        'contact': JSON.parse(<string>this.storageService.getItem('user_info')).mobile,
        'method': 'wallet'
      },
      'theme': {
        'color': '#1fa1ed'
      }
    };

    var rzp1 = new Razorpay(payOptions);
    rzp1.open();

  }

  completeTransaction = (data: any) => {
    if (data) {
      const reqObj = {
        pg_order_id: data.razorpay_order_id,
        pg_payment_id: data.razorpay_payment_id,
        pg_signature: data.razorpay_signature
      }
      this.dataService.verifyPaymentCheckout(reqObj).subscribe((res: any) => {
        if (res) {
          this.goToSuccessPage();
        }
      })
    }
  }

  goToSuccessPage = () => {
    $('#stripePopup').modal('hide');
    Swal.fire({
      title: 'Money Added',
      text: `Added £${this.addWalletForm.value.amount} in wallet, check you email for receipt`,
      icon: 'success',
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((res: any) => {
      if (res.isConfirmed) {
        this.bs.broadcast('REFRESH_CART', 'wallet');
        this.walletAmt = parseInt(JSON.parse(<string>this.storageService.getItem('walletAmt'))) + +this.addWalletForm.value.amount;
        this.tempWalletList = [];
        this.walletList = [];
        this.pageNo = 1;
        this.getWalletTransctions();
        this.addWalletForm.reset();
        this.formSubmited = false;
      }
    });
  }

  stripePaymentSuccess() {
    const reqObj = {
      amount: parseInt(this.addWalletForm.value.amount),
      for_wallet: true,
      domain: window.location.hostname,
      customer_id: JSON.parse(<string>this.storageService.getItem("user_info")).customer_id
    }
    this.dataService.checkoutSession(reqObj).subscribe((res1: any) => {
      if (res1 && res1.Error === undefined) {
        window.location.href = res1.url;
      }
    });
  }



}
