<app-tutor-header></app-tutor-header>
<div class="container">
  <div class="dashboard-wrapper">
    <div class="d-one">
      <!-- <h3 class="day-w">Total Courses</h3> -->
      <div class="row">
        <div class="col-md-3 col-3">
          <img src="assets/icons/contact/graduation-cap.svg" class="d-icon" alt="">
        </div>
        <div class="col-md-9 col-9">
          <h3>3</h3>
          <h5>Total Courses</h5>
        </div>
      </div>

    </div>
    <div class="d-one">
      <!-- <h3 class="day-w">Total Courses</h3> -->
      <div class="row">
        <div class="col-md-3 col-3">
          <img src="assets/icons/contact/users-alt.svg" class="d-icon" alt="">
        </div>
        <div class="col-md-9 col-9">
          <h3>20</h3>
          <h5>Total Enrollments</h5>
        </div>
      </div>

    </div>
    <div class="d-one">
      <!-- <h3 class="day-w">Total Courses</h3> -->
      <div class="row">
        <div class="col-md-3 col-3">
          <img src="assets/icons/contact/book-open-cover.svg" class="d-icon" alt="">
        </div>
        <div class="col-md-9 col-9">
          <h3>3</h3>
          <h5>Total Bookings</h5>
        </div>
      </div>

    </div>
    <div class="d-one">
      <!-- <h3 class="day-w">Total Courses</h3> -->
      <div class="row">
        <div class="col-md-3 col-3">
          <img src="assets/icons/contact/pound.svg" class="d-icon" alt="">
        </div>
        <div class="col-md-9 col-9">
          <h3>3</h3>
          <h5>Total Earnings</h5>
        </div>
      </div>

    </div>
  </div>

  <h4 class="mt-5">Earning And Spending Hours Graph</h4>

  <div class="select-box">


    <div class=" box" [ngClass]="{'selected': selectedBox === 'box1', 'not-selected': selectedBox !== 'box1'}"
      (click)="selectBox('box1')" (click)="hideInput()">Today</div>


    <div class="box" [ngClass]="{'selected': selectedBox === 'box2', 'not-selected': selectedBox !== 'box2'}"
      (click)="selectBox('box2')" (click)="hideInput()">This Week</div>

    <div class="box" [ngClass]="{'selected': selectedBox === 'box3', 'not-selected': selectedBox !== 'box3'}"
      (click)="selectBox('box3')" (click)="hideInput()">This Year</div>
    <div class="box" [ngClass]="{'selected': selectedBox === 'box4', 'not-selected': selectedBox !== 'box4'}"
      (click)="selectBox('box4')" (click)="showInput()">

      <div class="row">
        <div class="col-md-8 col-8">
          Custom
        </div>
        <div class="col-md-4 col-4">
          <img src="assets/icons/d-calendar.svg" alt="" class="cal-in">
        </div>
      </div>


    </div>
  </div>

  <div class="container  container-wrapper">

    <input type="text" class="form-control " id="dateRange" *ngIf="inputVisible" formControlName="dateRange"
      placeholder="mm/dd/yyyy - mm/dd/yyyy" #daterangepicker="bsDaterangepicker" bsDaterangepicker
      [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showWeekNumbers: false }">
    <!-- <s class="input-group-append"> -->

    <!-- </div> -->
  </div>
<div class="container bar-chart-wrap mt-5">
    <p-tabView>
      <p-tabPanel header="Total Courses">
        <div class="pic-c">
          <div class="left">
            <p-chart type="bar" [data]="data" class="bar-chat-wrap"></p-chart>
          </div>
          <div class="right">
            <p-chart type="pie" [data]="datapie" class="pic-chat-wrap" [options]="chartOptions"></p-chart>
          </div>
        </div>

      </p-tabPanel>
      <p-tabPanel header="Total Enrollments">
        <div class="pic-c">
          <div class="left">
            <p-chart type="bar" [data]="dataenrollment"class="bar-chat-wrap"></p-chart>
          </div>
          <div class="right">
            <p-chart type="pie" [data]="datapie" class="pic-chat-wrap" [options]="chartOptions"></p-chart>
          </div>
        </div>

      </p-tabPanel>
      <p-tabPanel header="Total Bookings">
        <div class="pic-c">
          <div class="left">
            <p-chart type="bar" [data]=" dataBookings" class="bar-chat-wrap"></p-chart>
          </div>
          <div class="right">
            <p-chart type="pie" [data]="datapie" class="pic-chat-wrap" [options]="chartOptions"></p-chart>
          </div>
        </div>


      </p-tabPanel>
      <p-tabPanel header="Total Earnings">

        <div class="pic-c">
          <div class="left">

            <p-chart type="line" [data]="basicDataone" class="bar-chat-wrap"></p-chart>
          </div>
          <div class="right">
            <p-chart type="pie" [data]="datapie" class="pic-chat-wrap"[options]="chartOptions"></p-chart>
          </div>
        </div>

      </p-tabPanel>
    </p-tabView>
  </div>
</div>