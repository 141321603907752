import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError } from 'rxjs/operators';
import { Router } from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { AuthService } from "../services/auth.service";
import { StorageService } from "../services/storage.service";

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private auth: AuthService,
        private router: Router,
        private storageService: StorageService
    ) { }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401) {
            console.log('401 error');
            this.auth.logout();
            this.router.navigate(['sign-in']);
            return of(err.message);
        }
        return throwError(err);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const userInfo = JSON.parse(<string>this.storageService.getItem("user_info"));
        let headers = req.headers;
        
        if (userInfo) {
            const _token = userInfo.access;
            if (_token) {
                headers = req.headers.set('Authorization', `Bearer ${_token}`);
            }
        }

        const requestClone = req.clone({ headers });

        return next
            .handle(requestClone)
            .pipe(catchError((err) => this.handleAuthError(err)));
    }
}