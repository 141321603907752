<div class="bg-color-of-pages">
    <!-- <app-breadcrumb></app-breadcrumb> -->
    <!-- <app-provider-card></app-provider-card> -->

    <div class="providerd-top-details container-fluid mb-5">

        <img src="https://safaiwale.in/wp-content/themes/clenix/assets/img/banner.jpg" alt="" style="width: 100%;">

        <h6 class="our-services-title text-center mt-5">Our Services</h6>
        <h3 class="under-os text-center">The best window cleaning company</h3>

        <div class="booking-providers-list-cards-info mt-5 mb-5">
            <div class="tab-content">
                <div id="description" class="tab-pane fade show active">
                    <div class="main-service-wrapper">
                        <div class="service-item-inner-card" *ngFor="let item of servicesList">
                            <img [src]="item.image" alt="" (click)="gotoServiceDetailsPage(item)">
                            <p class="s-name" (click)="gotoServiceDetailsPage(item)">{{item.title}}</p>
                            <div class="divider"></div>

                            <div class="bottom-section" *ngIf="!item?.quote_only">
                                <div class="left">
                                    <p>Price </p>
                                </div>
                                <div class="right text-right pr-4">
                                    <p>{{ item.min_charge | currency: currencyCode:'symbol':'1.0-0' }}</p>
                                </div>
                            </div>

                            <div class="bottom-section" *ngIf="item?.quote_only">
                                <div class="left">
                                    <p>Starts from </p>
                                </div>
                                <div class="right text-right pr-4">
                                    <p>{{item.min_charge | currency: currencyCode:'symbol':'1.0-0' }}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>


<app-footer></app-footer>
<!-- <section *ngIf="pop.value">
    <app-cart-pop [cartDetails]="pop"></app-cart-pop>
</section> -->