import { AfterViewInit,  ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { RouteConstants } from 'src/app/model/route-constants';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import * as moment from 'moment';
​
declare var Razorpay: any;
declare var Stripe: any;
declare var $: any;
@Component({
  selector: 'app-my-transactions',
  templateUrl: './my-transactions.component.html',
  styleUrls: ['./my-transactions.component.scss']
})
export class MyTransactionsComponent implements OnInit {
  MyTransactionDates: any;
  walletList: any = [];
  walletAmt: any;
  pageNo = 1;
  hideWalletScreen = false;
  formSubmited = false;
  addWalletForm: any;
  transactions: any[]=[];
  stripe: any;
  date = new Date();
  minDate:any;
  maxDate:any;
  startDate:any;
  endDate:any;
  endDateValue: any;
  tempStartDate: any;
  tempEndDate: any;
  currencyCode = environment.currencyCode;
  addDaysToDate = () => {
    var res = new Date();
    res.setDate(res.getDate() + 15);
    return res;
  }
  // minDate = { "year": this.date.getFullYear(), "month": this.date.getMonth() + 1, "day": this.date.getDate() };
  // maxDate = { "year": this.addDaysToDate().getFullYear(), "month": this.addDaysToDate().getMonth() + 1, "day": this.addDaysToDate().getDate() };
  minDate2:any;
  constructor(public storageService: StorageService,  public dataService: DataService, public router: Router,
    private bs: BroadcastService, private cd: ChangeDetectorRef, authService: AuthService) {

      // this.MyTransactionDates = new FormGroup(
      //   {
      //     fromDate: new FormControl(""),
      //     toDate: new FormControl("")
      //   },
      //   [Validators.required, this.dateRangeValidator]
      // );
  }

  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const fromDate = this.MyTransactionDates && this.MyTransactionDates.get("fromDate").value;
    const toDate = this.MyTransactionDates && this.MyTransactionDates.get("toDate").value;
    if (fromDate && toDate) {
      invalid = (new Date(fromDate)).valueOf() > (new Date(toDate)).valueOf();
    }
    return invalid ? { invalidRange: { fromDate, toDate } } : null;
  };

  

  onFromDateSelect(value:any, fromState: any)
  {
    // console.log("onFromDateSelect", value,fromState);
    // let year = value.year;
    // let month = value.month <= 9 ? '0' + value.month : value.month;
    // let day = value.day <= 9 ? '0' + value.day : value.day;
    // if(fromState === 'start'){
    // console.log("onFromDateSelect", this.tempStartDate);
    // this.tempStartDate = year + "-" + month + "-" + day;
    // }else  if(fromState === 'end'){
    // this.tempEndDate = year + "-" + month + "-" + day;
    // console.log("onFromDateSelect", this.tempEndDate);
    // }
    
    
    this.minDate2 = value;
  }
  
  ngOnInit(): void {
    let today=moment();
    let previousMonthDate=moment().subtract(1,'month');
    let mdate = moment().subtract(365,'days');
    let currentMonthDate = moment().subtract(1,'month');
    this.startDate={ year: currentMonthDate.year(), month: currentMonthDate.month()+1, day: currentMonthDate.date() };
    this.endDate={ year: today.year(), month: today.month()+1, day: today.date() };
    console.log("startDate: ", this.startDate);
    
    this.minDate={ year: mdate.year(), month: mdate.month()+1, day: mdate.date()};
    this.minDate2 = this.minDate;
    this.maxDate = { year: today.year(), month: today.month()+1, day: today.date() };
    console.log("mindate:", this.minDate, this.maxDate, currentMonthDate.format('DD-MM-YYYY'));
    //this.stripe = Stripe(environment.stripKey);
    this.walletAmt = parseInt(JSON.parse(<string>this.storageService.getItem('walletAmt')));
    this.getMyTransctions();
    this.addWalletForm = new FormGroup({
      amount: new FormControl('', [Validators.required, Validators.pattern(new RegExp("[0-9 ]$"))]),
    });

    this.MyTransactionDates = new FormGroup({
      fromDate: new FormControl(previousMonthDate.format('DD-MM-YYYY'),[Validators.required]),
      toDate: new FormControl('',[Validators.required])
    });

  }

  


  get fromDateControls() { return this.MyTransactionDates.get('fromDate'); }
  get toDateControls() { return this.MyTransactionDates.get('toDate'); }

  

  get walletFormControls() {
    return this.addWalletForm.controls;
  }
 
  // getMyTransctions = () => {
  //   this.dataService.getMyTransctions(this.pageNo).subscribe((res: any) => {
  //     if (res) {
  //       console.log("resp:", res);
  //       if(res.results.length>0)
  //       {
  //         this.transactions.push(...res.results);
  //       }
       
  //     }
  //   })
  // }

  // getMyTransctions = () => {
  //   const reqObj = {
  //     pageId: this.pageNo,
  //     startDate: moment().subtract(this.endDateValue, 'days').format('YYYYMMDD'),
  //     endDate: this.startDate
  //   }
  //   this.dataService.getMyTransctions(reqObj).subscribe((res: any) => {
  //     if (res) {
  //       console.log("resp:", res);
  //       if(res.results.length>0)
  //       {
  //         this.transactions.push(...res.results);
  //       }
  //     }
  //   })
  // }
  
  getMyTransctions = (page:number=1) => {
    const reqObj = {
      pageId: page,
      startDate:  `${this.startDate.year}${this.startDate.month}${this.startDate.day}`,
      endDate: `${this.endDate.year}${this.endDate.month}${this.endDate.day}`
    }
    console.log (reqObj)
    this.dataService.getMyTransctions(reqObj).subscribe((res: any) => {
      if (res) {
        if(res.results.length === 0){
          this.transactions =[];
        }
        console.log("resp:", res);
        if(res.results.length>0)
        {
          if(page>1)
          this.transactions.push(...res.results);
          else
          this.transactions=res.results;
        }
      }
    })
  }

  onScrollDown(ev: any) {
    this.pageNo += 1;
    this.getMyTransctions(this.pageNo);
    console.log("scrolled down!!", ev);

  }

  onScrollUp(ev: any) {
    console.log("scrolled up!", ev);
    this.pageNo += 1;
  }

  onSubmit = (event: any) => {
    this.pageNo = 1;
    this.formSubmited = true;
    this.getMyTransctions();
  }
}