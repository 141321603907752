<div class="main-wrapper">
    <section class="hero-section">
        <nav>
            <div class="logo">My Logo</div>
            <input type="checkbox" id="click">
            <label for="click" class="menu-btn">
                <i class="fas fa-bars"></i>
            </label>
            <ul>
                <li><a class="active" href="#">Home</a></li>
                <li><a href="#">My Bookings</a></li>
                <li><a href="#">Reviews</a></li>
                <li><a href="#">Blog</a></li>
            </ul>
            <div class="cart-wrapper">
                <a href="#" class="cart-sec"><img src="assets/icons/cart-icon.svg" alt=""></a>
            </div>
        </nav>
        <div class="container hero-content">
            <h1 class="hero-heading">Your Website Title</h1>
            <button class="book-now-btn mt-5">Book Now</button>
        </div>
    </section>

    <div class="main-content-wrapper">
        <div class="container">
            <div class="first-row">
                <div class="first-row-inner">
                    <h4>Opening Hours</h4>

                    <table class="table text-center mt-4">
                        <tr>
                            <td>Mon</td>
                            <td class="td-bold">09:00 - 18:00</td>
                        </tr>
                        <tr>
                            <td>Tue</td>
                            <td>09:00 - 18:00</td>
                        </tr>
                        <tr>
                            <td>Wed</td>
                            <td>09:00 - 18:00</td>
                        </tr>
                        <tr>
                            <td>thu</td>
                            <td>09:00 - 18:00</td>
                        </tr>
                        <tr>
                            <td>Fri</td>
                            <td>09:00 - 18:00</td>
                        </tr>
                        <tr class="closed-rows">
                            <td>Sat</td>
                            <td>Closed</td>
                        </tr>
                        <tr class="closed-rows">
                            <td>Sun</td>
                            <td>Closed</td>
                        </tr>
                    </table>
                </div>
                <div class="first-row-inner" id="first-row-right">
                    <h3>Our Services</h3>
                    <div class="inner-service-card-wrapper">
                        <div class="inner-card-item">
                            <img src="assets/images/s-one.png" alt="">
                            <div class="card-details">
                                <div class="card-d-left">
                                    <p class="s-name">Haircut</p>
                                </div>
                                <div class="card-d-right">
                                    <button class="book-now-button">Book</button>
                                </div>
                            </div>
                        </div>
                        <div class="inner-card-item">
                            <img src="assets/images/s-two.png" alt="">

                            <div class="card-details">
                                <div class="card-d-left">
                                    <p class="s-name">Facial</p>
                                </div>
                                <div class="card-d-right">
                                    <button class="book-now-button">Book</button>
                                </div>
                            </div>

                        </div>
                        <div class="inner-card-item">
                            <img src="assets/images/s-three.png" alt="">

                            <div class="card-details">
                                <div class="card-d-left">
                                    <p class="s-name">Wax</p>
                                </div>
                                <div class="card-d-right">
                                    <button class="book-now-button">Book</button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="view-more-section text-right pt-3">
                        <a href="">view more &nbsp; <img src="assets/icons/right-arrow.svg" alt=""> </a>
                    </div>
                </div>
            </div>

            <div class="second-row mb-5">
                <div class="second-row-inner-left">
                    <div class="inner-card-item">
                        <h4 class="text-center">Contact Us</h4>
                        <div class="contact-methods">
                            <p><img src="assets/icons/phone-icon.svg" alt=""><span class="pl-3">+91 9988776655</span>
                            </p>
                            <p><img src="assets/icons/message-icon.svg" alt=""><span class="pl-3">saleswe@we.com</span>
                            </p>
                        </div>
                    </div>
                    <div class="inner-card-item mt-4 pb-5">
                        <h4 class="text-center mb-2">Contact Us</h4>

                        <div class="map-section text-center">
                            <img src="assets/images/map.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="second-row-inner-right">
                    <div class="inner-card-item">
                        <h3>About Our Page</h3>
                        <p class="pt-3">this is demo booking page for your theme.Custom features used for setup:</p>
                        <div class="row pt-3 pb-4">
                            <div class="col-md-6">
                                <ul>
                                    <li> <span>Service Categories</span></li>
                                    <li> <span>Html Description Field for Events</span></li>
                                    <li> <span>Terms and Conditions</span></li>
                                    <li> <span>News</span></li>
                                    <li> <span>Photo Gallery</span></li>
                                </ul>
                            </div>
                            <div class="col-md-6">
                                <ul>
                                    <li> <span>Intake Forms</span></li>
                                    <li> <span>Contact Widget</span></li>
                                    <li> <span>Any Employee Selector</span></li>
                                    <li> <span>Facebook Bot</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <hr>
            <div class="footer-wrapper">
                <div class="inner-footer-left">
                    <p>&copy; Copyright 2023. Powered by <a href="#">Accenflair IT Solutions (P) Ltd.</a></p>
                </div>
                <div class="inner-footer-right">
                    <ul>
                        <li>Privacy policy</li>
                        <li>Terms of Service</li>
                        <li>Refund policy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>