import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TutorLoginFormService } from 'src/app/tutor-login-form.service';

@Component({
  selector: 'app-tutor-login',
  templateUrl: './tutor-login.component.html',
  styleUrls: ['./tutor-login.component.scss']
})
export class TutorLoginComponent implements OnInit {
  parentLabel: string = 'Parent';
  studentLabel: string = 'Student';
  tutorLabel: string = 'Tutor';
  optionLabel: string | undefined;
  setOption:any;
  constructor(private router: Router,private TutorLoginService:TutorLoginFormService) { }

  goToLoginPage(option: string) {
    this.router.navigate(['/tutor/login'], { queryParams: { option: option } });
  }



  
  ngOnInit(): void {
    this.TutorLoginService.option$.subscribe(option => {
      if (option === 'parent') {
        this.optionLabel = 'Parent Login';
      } else if (option === 'student') {
        this.optionLabel = 'Student Login';
      } else if (option === 'tutor') {
        this.optionLabel = 'Tutor Login';
      }
    });
  }
  }




