<div class="parent-signup-wrapper">

    <div class="tutor-signup container">
        <div class="row">
            <div class="col-md-3 col-3">
                <img src="assets/icons/arrow-left-t.svg" alt="" class="arrow-back" [routerLink]="['/tutor-login']">
            </div>
            <div class="col-md-6 col-6">
                <h2> Parent Sign up</h2>
            </div>
            <div class="col-md-3 col-3">
                <button class="sign-btn" type="button" [routerLink]="['/tutor-login']">Login</button>
            </div>
        </div>
     
   
        <div class="login-container-page" *ngIf="!showSuccessPage">
            <div class="login-form-section">
              <form (ngSubmit)="submitForm()" class="login-container-page">
                <div class="row">
                  <div class="col-md-6 input-block">
                  <div class="label-text"> First Name</div>
                  <input type="text" id="firstName" name="firstName" [(ngModel)]="firstName" class="form-control" required>
                  <div *ngIf="formSubmitted && !firstName" class="error-text">
                    First Name is required.
                  </div>
                </div>
                <div class="col-md-6 input-block">
                  <div class="label-text"> <label for="lastName">Last Name</label></div>
                  <input type="text" id="lastName" name="lastName" [(ngModel)]="lastName"class="form-control" required>
                  <div *ngIf="formSubmitted && !lastName" class="error-text">
                    Last Name is required.
                  </div>
                </div>
              
                <div class="col-md-6 input-block">
                  <div class="label-text">    <label for="email">Email</label></div>
                  <input type="email" id="email" name="email" [(ngModel)]="email"class="form-control" required>
                  <div *ngIf="formSubmitted && !email"class="error-text">
                    Email is required.
                  </div>
                </div>
              
                <div class="col-md-6 input-block">
                  <div class="label-text">  <label for="phone">Phone</label></div>
                  <input type="tel" id="phone" name="phone" [(ngModel)]="phone"class="form-control" required>
                  
                  <div *ngIf="formSubmitted && !phone"class="error-text">
                    Phone is required.
                  </div>
                </div>
              
                <div class="col-md-6 input-block">
                  <div class="label-text">   <label for="studentFirstName">Student First Name</label></div>
                  <input type="text" id="studentFirstName" name="studentFirstName" class="form-control"[(ngModel)]="studentFirstName" required>
                  <div *ngIf="formSubmitted && !studentFirstName"class="error-text"class="error-text">
                    Student First Name is required.
                  </div>
                </div>
              
                <div class="col-md-6 input-block">
                  <div class="label-text">   <label for="studentLastName">Student Last Name</label></div>
                  <input type="text" id="studentLastName" name="studentLastName" [(ngModel)]="studentLastName"class="form-control" required>
                  <div *ngIf="formSubmitted && !studentLastName"class="error-text">
                    Student Last Name is required.
                  </div>
                </div>
              
                <div class="col-md-6 input-block">
                  <div class="label-text">   <label for="educationLevel">Education Level</label></div>
                  <select id="educationLevel" name="educationLevel"class="form-control" [(ngModel)]="educationLevel" required>
                    <option value="">Select Education Level</option>
                    <option value="Elementary">Elementary</option>
                    <option value="Middle School">Middle School</option>
                    <option value="High School">High School</option>
                  </select>
                  <div *ngIf="formSubmitted && !educationLevel"class="error-text">
                    Education Level is required.
                  </div>
                </div>
                <div class="input-block col-md-6">
                  <div class="label-text">Gender</div>
                  <ng-select [items]="gender" bindLabel="name" [(ngModel)]="selectedGender"
                      [multiple]="false"></ng-select>
              </div>
                      
              </div>
                <!-- <button type="submit" class="signup-wrap">Submit</button> -->
                <div class="row">
                                           
                  <div class="col-md-6"> <button class="btn-back" type="button" class="back-wrap" [routerLink]="['/student/age']">
                      Back</button>
              </div>
                   <div class="col-md-6">   
            <button class="c-wrap" type="submit" >Signup</button>
                   </div>
          </div>
              </form>
           
            </div>
        </div>
    </div>
</div>

