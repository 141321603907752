<!-- <div class="bg-color-of-pages">
    <div class="container d-none">
        <div class="breadcrumb-data">
            <li class="breadcrumb-item">My Wallet</li>
        </div>
    </div>

    <div class="container" id="main-container">
        <p class="my-transactions-title">My Transactions</p>

        <form>
            <div class="date-selection-card">
                <div class="form-date-label pt-2">From date : </div>
                <div class="form-date-sec">
                    <div class="input-group">
                        <input class="form-control" placeholder="10-06-2022"
                               name="dp" ngbDatepicker #fd="ngbDatepicker" required>
                        <button class="btn btn-outline-secondary calendar" (click)="fd.toggle()" type="button"></button>
                    </div>
                </div>
                <div class="to-date-label pt-2">To date : </div>
                <div class="to-date-sec">
                    <div class="input-group">
                        <input class="form-control" placeholder="16-06-2022"
                               name="dp" ngbDatepicker #td="ngbDatepicker" required>
                        <button class="btn btn-outline-secondary calendar" (click)="td.toggle()" type="button"></button>
                    </div>
                </div>
                <div class="" id="">
                    <button class="sb-primary-btn">Submit</button>
                </div>
            </div>
        </form>

    </div>
</div> -->



<div class="bg-color-of-pages">
    <!-- <div class="container">
        <div class="breadcrumb-data">
            <li class="breadcrumb-item">My Transactions</li>
        </div>
    </div> -->

    <div class="container" id="main-container">
        <p class="my-transactions-title">My Transactions</p>

        <form>
            <div class="date-selection-card">
                <div class="form-date-label pt-2">From date : </div>
                <div class="form-date-sec">
                    <div class="input-group">
                        <input [(ngModel)]="startDate" readonly (dateSelect)="onFromDateSelect($event, 'start')" class="form-control" 
                        placeholder="DD-MM-YYYY" name="fd1" ngbDatepicker #fd1="ngbDatepicker" [minDate]="minDate" [maxDate]="maxDate">
                        <button class="btn btn-outline-secondary calendar" (click)="fd1.toggle()" type="button"></button>
                    </div>
                    <!-- <span class="error-text" *ngIf="fromDateControls?.invalid  && formSubmited">From Date required</span> -->
                </div>
                <div class="to-date-label pt-2">To date : </div>
                <div class="to-date-sec">
                    <div class="input-group">
                        <input [(ngModel)]="endDate" readonly (dateSelect)="onFromDateSelect($event, 'end')" 
                        class="form-control" placeholder="DD-MM-YYYY" name="dp" ngbDatepicker #td="ngbDatepicker" [minDate]="startDate" 
                        [maxDate]="maxDate">
                        <button class="btn btn-outline-secondary calendar" (click)="td.toggle()" type="button"></button>
                    </div>
                    <!-- <span class="error-text" *ngIf="toDateControls?.invalid  && formSubmited">To Date required</span> -->
                </div>
                <p *ngIf="MyTransactionDates.touched && MyTransactionDates.invalid" class="text-danger">From date sholud be less than to date</p>
                <div class="" id="">
                    <button class="sb-primary-btn" (click)="onSubmit($event)">Submit</button>
                </div>
            </div>
        </form>

    </div>

    <div class="container">
        <div class="inner-data-wallet pt-0">
            <div></div>
            <!-- <div class="heading-page">{{!hideWalletScreen ? 'My' : 'Recharge' }} Wallet</div> -->
            <div class="wallet-total-balance d-none">
                <ng-container *ngIf="!hideWalletScreen">
                    <div class="wallet-data-with-img">
                        <div class="wallet-img">
                            <img src="assets/icons/wallet.png">
                        </div>
                        <div class="wallet-info-content">
                            <div class="title">Total Wallet Balance</div>
                            <div class="balance-wallet">{{walletAmt | currency: currencyCode:'symbol':'1.0-0' }}</div>
                        </div>
                    </div>
                    <div class="btn-recharge" (click)="hideWalletScreen=true;">Recharge Wallet</div>
                    <!-- <div class="download-statment">
                        <div class="download-btn">Download Statement</div>
                    </div> -->
                </ng-container>
                <ng-container *ngIf="hideWalletScreen">
                    <form [formGroup]="addWalletForm" (ngSubmit)="onSubmit($event)">
                        <div class="input-block col-12 row m-0 p-0">
                            <div class="label-text col-12 p-0">Enter amount to wallet<sup>*</sup></div>
                            <div class="sg-input-text col-md-5 col-sm-5 col-lg-5 col-6 pl-0">
                                <input type="text" placeholder="Enter amount to wallet" class="input-text"
                                    formControlName="amount">
                                <span class="error-text"
                                    *ngIf="formSubmited && walletFormControls.amount.errors?.required">Amount
                                    required</span>
                                <span class="error-text"
                                    *ngIf="formSubmited && walletFormControls.amount.errors?.pattern">Enter valid
                                    Number</span>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-4 col-6 p-0">
                                <button class="sb-primary-btn w-100" type="submit">Add Money</button>
                            </div>
                            <div class="balance-wallet col-12 p-0 mt-1">Available balance: {{walletAmt | currency: currencyCode:'symbol':'1.0-0' }}</div>
                        </div>
                    </form>
                </ng-container>
            </div>

            <div class="recent-wallet-transc-block">
                <div class="title">All Transactions</div>
                <div infinite-scroll (scrolled)="onScrollDown($event)" (scrolledUp)="onScrollUp($event)"
                    [immediateCheck]="false">
                    <div class="row col-12 transcation-recent-row" *ngFor="let transaction of transactions">
                        <div class="col-8 left-column-data">
                            <div class="primary-text"><span class="my_transaction_id font-weight-bold">#{{transaction.transaction_id }}</span> &nbsp; {{transaction.transaction_type}}</div>
                            <div class="secondary-text global-time-text">{{dataService.getLocalDateTime(transaction.transaction_date)}}</div>
                        </div>
                        <div class="col-4 right-column-data">
                            <div class="credit-amount" *ngIf="transaction.transaction_type.toLowerCase() === 'debit'">-{{transaction.amount | currency: currencyCode:'symbol':'1.0-0' }}
                            </div>
                            <div class="debit-amount"
                            *ngIf="transaction.transaction_type.toLowerCase() === 'credit' || transaction.transaction_type.toLowerCase() === 'topup' ||  transaction.transaction_type.toLowerCase() === 'refund'">
                                +{{transaction.amount | currency: currencyCode:'symbol':'1.0-0' }}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="transactions?.length === 0" class="text-center">
                    No Data Available
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>