import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-students-list',
  templateUrl: './students-list.component.html',
  styleUrls: ['./students-list.component.scss']
})
export class StudentsListComponent implements OnInit {

  openStudentForm: any;

  studentDetails = [
    { firstname: 'Arjun', lastname: 'Kumar', email: 'arjun@yahoo.com', status: 'Registered', buttontext: 'Remove' },
    { firstname: 'Adithya', lastname: 'Agarwal', email: 'adithya@yahoo.com', status: 'Registered', buttontext: 'Remove' },
    { firstname: 'Vikram', lastname: 'Adithya', email: 'vikram@yahoo.com', status: 'Registered', buttontext: 'Remove' },
    { firstname: 'Rakesh', lastname: 'Misra', email: 'rakesh@yahoo.com', status: 'Registered', buttontext: 'Remove' },
    { firstname: 'Arjun', lastname: 'Kumar', email: 'arjun@yahoo.com', status: 'Registered', buttontext: 'Remove' },
    { firstname: 'Adithya', lastname: 'Agarwal', email: 'adithya@yahoo.com', status: 'Registered', buttontext: 'Remove' },
    { firstname: 'Vikram', lastname: 'Adithya', email: 'vikram@yahoo.com', status: 'Registered', buttontext: 'Remove' },
    { firstname: 'Rakesh', lastname: 'Misra', email: 'rakesh@yahoo.com', status: 'Registered', buttontext: 'Remove' },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
