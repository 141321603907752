<div class="parent-signup-wrapper ">

    <h1>What are your school leaving qualifications ?</h1>

    <div class="tutor-signup container">
 
       
        <!-- <div class="card"> -->
            <div class="input-block mt-5">
                <div class="label-text">Qualification type</div>
                <ng-select
                [items]="qualifications"
                bindLabel="name"
                [multiple]="false">

                </ng-select>
            </div>
            
            
       


            <div class="row button-wrapper mt-4">
                <div class="col-md-6">
                    <button class="btn-back" type="button"[routerLink]="['/tutor/qualification']">Back </button>
                </div>
                <div class="col-md-6">
                    <button class="btn-next" type="button"[routerLink]="['/tutor/subject/level']" >Next </button>
                </div>
       
            </div>
                  
              
       

       
        </div>
    </div>

                     
    