import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-checkout',
  templateUrl: './student-checkout.component.html',
  styleUrls: ['./student-checkout.component.scss']
})
export class StudentCheckoutComponent implements OnInit {
  showText = false;
  constructor() { }

  ngOnInit(): void {
  }

}
