import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutor-appointment',
  templateUrl: './tutor-appointment.component.html',
  styleUrls: ['./tutor-appointment.component.scss']
})
export class TutorAppointmentComponent implements OnInit {

  categories = ['Science', 'Math', 'History'];
  subjects = [];
  topics = [];
  showMore = false;
  text = 'Hello and welcome to Verbling! My name is Tom and  qualified English teacher from London.I teach modern British english and British Culture.<br>I want to help you improve your confidence with the English language so you can sound more natural, connect with more people in your life and improve your opportunities. My lessons are fun, relaxed and conversational.';
  displayedText: string | undefined;
  videoUrl = 'https://example.com/video.mp4';
  isFavorited: any;
  selectCategory= 'selectCategory';
  selectedSubjects='selectSubjects';
  selectedTopics='selectedTopics';
  checkbox1Selected: boolean | undefined;
  checkbox2Selected = false;
  showButton = false;

  rating: number = 3;
  constructor(){
  {
    this.displayedText = this.text.substr(0, 100);
  }  }

  ngOnInit(): void {
  }
  toggleReadMore() {
    this.showMore = !this.showMore;
    this.displayedText = this.showMore ? this.text : this.text.substr(0, 100) + '...';
  }
  toggleFavorite() {
    this.isFavorited = !this.isFavorited;
  }
  updateSelection(option: number) {
    if (option === 1) {
      this.checkbox2Selected = false;
    } else if (option === 2) {
      this.checkbox1Selected = false;
    }
  }
// updateSelection(value: number) {
//   if (value === 1) {
//     this.checkbox1Selected = !this.checkbox1Selected;
//   }
// }
category: any = [
    {   name: 'John Dee'},
    {  name: 'Anastasia B'},
    {   name: 'Johnson Roy'}
  ]
 subject:any=[
    {name:'content marketing'},
    {name:'First Aid'},
    {name:'Fitness'}
  ]
topic:any=[
   { name:'yoga'},
   {name:'fitness'},
   {name:'marketing'}
  ]

}
