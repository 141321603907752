import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-tutor',
  templateUrl: './contact-tutor.component.html',
  styleUrls: ['./contact-tutor.component.scss']
})
export class ContactTutorComponent implements OnInit {

  defaultLocation = 'Mumbai';

  constructor() { }

  ngOnInit(): void {
  }

  schoolsLocation: any = [
    {name: 'Hyderabad'},
    {name: 'Mumbai'},
    {name: 'Delhi'}
  ]

}

