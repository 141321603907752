<app-tutor-header></app-tutor-header>
<div class="container-fluid student-profile-wrapper">
    <div class="main-header-section">
        <div class="inner-page-title">
            <h3>Bookings</h3>
        </div>
        <div class="right-btns">

        </div>
    </div>

    <div class="booking-main-content">
        <p-tabView>
            <p-tabPanel header="Upcoming">
                <div class="booking-tab-content">
                    <div class="booking-status-dropdown w-25 mt-3">
                        <ng-select [items]="upcomingStatusList" bindLabel="name" [searchable]="false"
                            [(ngModel)]="defaultUpcomingStatus" [clearable]="false">
                        </ng-select>
                    </div>

                    <h5 class="day-wrap">Today</h5>
                    <div class="booking-card-wrapper">
                        <div class="bookings-card-view mt-3" *ngFor="let booking of bookingsList; let i = index;">
                            <div class="tutor-header">
                                <div class="t-header-inner">
                                    <img  class="s-img"src="assets/images/tutor-p.jpg" alt="">
                                </div>
                                <div class="t-header-inner">
                                    <h6 class="t-name">{{booking.tutorname}}</h6>
                                    <p class="s-subject">{{booking.subject}}</p>

                                </div>
                                <div class="t-header-inner text-right d-none">
                                    <button class="btn btn-block btn-edit text-right menu">
                                        <img src="assets/icons/menu-dot.svg" alt="" class="dotted-menu menu">
                                        <div class="menu-dropdown">
                                            <a (click)="openEditBookingPanel = true" class="text-left">Reschedule
                                                lesson</a>
                                            <a (click)="openCancelBookingPanel = true" class="text-left">Cancel
                                                lesson</a>
                                        </div>
                                    </button>
                                </div>
                                <div class="t-price">
                    
                              
                                    <Span> <img class="icon-b" [src]="booking.meetingicon" alt=""></Span><span class="c-type">{{booking.meeting}}</span>
                                  
                              
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-md-6 col-6">
                                    <p class="s-label">Course Duration</p>
                                    <p class="s-date">{{booking.date}}</p>
                                    <p class="s-time">{{booking.day}}</p>
                                </div>
                                <div class="col-md-6  col-6 buk-wrap">
                                    <div class="time-wrap"> <span class="s-label">Time :</span><span
                                            class="s-date">{{booking.time}}</span></div>
                                     <!-- <p class="s-label">Status</p> -->
                                     <p class="s-date">
                                        <span><img [src]="booking.statusicon" alt=""></span>
                                        <span class="{{booking.statustext}}" id="b-status">{{booking.status}}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-6 col-12">
                                    <button class="btn btn-block btn-edit"
                                        (click)="openCancelBookingPanel = true">Cancel</button>

                                    <!-- <button class="btn btn-block btn-edit text-center menu">
                                        Edit
                                        <div class="menu-dropdown">
                                            <a (click)="openEditBookingPanel = true" class="text-left">Reschedule
                                                lesson</a>
                                            <a (click)="openCancelBookingPanel = true" class="text-left">Cancel
                                                lesson</a>
                                        </div>
                                    </button> -->
                                </div>
                                <div class="col-md-6 col-12">
                                    <button class="btn btn-block btn-accept"
                                        (click)="openEditBookingPanel = true">Reschedule</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>
                    <h5 class="day-wrap">Tomorrow</h5>
                    <div class="booking-card-wrapper">
                        <div class="bookings-card-view mt-3" *ngFor="let booking of bookingsList; let i = index;">
                            <div class="tutor-header">
                                <div class="t-header-inner">
                                    <img  class="s-img"src="assets/images/tutor-p.jpg" alt="">
                                </div>
                                <div class="t-header-inner">
                                    <h6 class="t-name">{{booking.tutorname}}</h6>
                                    <p class="s-subject">{{booking.subject}}</p>

                                </div>
                                <div class="t-header-inner text-right d-none">
                                    <button class="btn btn-block btn-edit text-right menu">
                                        <img src="assets/icons/menu-dot.svg" alt="" class="dotted-menu menu">
                                        <div class="menu-dropdown">
                                            <a (click)="groupopenEditBookingPanel = true" class="text-left">Reschedule
                                                lesson</a>
                                            <a (click)="openCancelBookingPanel = true" class="text-left">Cancel
                                                lesson</a>
                                        </div>
                                    </button>
                                </div>
                                <div class="t-price">
                    
                              
                                    <Span> <img class="icon-b" [src]="booking.meetingicon" alt=""></Span><span class="c-type">{{booking.meeting}}</span>
                                  
                              
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-md-6 col-6">
                                    <p class="s-label">Course Duration</p>
                                    <p class="s-date">{{booking.date}}</p>
                                    <p class="s-time">{{booking.day}}</p>
                                </div>
                                <div class="col-md-6  col-6 buk-wrap">
                                    <div class="time-wrap"> <span class="s-label">Time :</span><span
                                            class="s-date">{{booking.time}}</span></div>
                                     <!-- <p class="s-label">Status</p> -->
                                     <p class="s-date">
                                        <span><img [src]="booking.statusicon" alt=""></span>
                                        <span class="{{booking.statustext}}" id="b-status">{{booking.status}}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-6">
                                    <button class="btn btn-block btn-edit"
                                        (click)="openCancelBookingPanel = true">Cancel</button>

                                    <!-- <button class="btn btn-block btn-edit text-center menu">
                    Edit
                    <div class="menu-dropdown">
                        <a (click)="openEditBookingPanel = true" class="text-left">Reschedule
                            lesson</a>
                        <a (click)="openCancelBookingPanel = true" class="text-left">Cancel
                            lesson</a>
                    </div>
                </button> -->
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-block btn-accept"
                                        (click)="groupopenEditBookingPanel = true">Reschedule</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <h5 class="day-wrap">Rest of the Week</h5>
                    <div class="booking-card-wrapper">
                        <div class="bookings-card-view mt-3" *ngFor="let booking of bookingsList; let i = index;">
                            <div class="tutor-header">
                                <div class="t-header-inner">
                                    <img class="s-img" src="assets/images/tutor-p.jpg" alt="">
                                </div>
                                <div class="t-header-inner">
                                    <h6 class="t-name">{{booking.tutorname}}</h6>
                                    <p class="s-subject">{{booking.subject}}</p>

                                </div>
                                <div class="t-header-inner text-right d-none">
                                    <button class="btn btn-block btn-edit text-right menu">
                                        <img src="assets/icons/menu-dot.svg" alt="" class="dotted-menu menu">
                                        <div class="menu-dropdown">
                                            <a (click)="openEditBookingPanel = true" class="text-left">Reschedule
                                                lesson</a>
                                            <a (click)="openCancelBookingPanel = true" class="text-left">Cancel
                                                lesson</a>
                                        </div>
                                    </button>
                                </div>
                                <div class="t-price">
                    
                              
                                    <Span> <img class="icon-b" [src]="booking.meetingicon" alt=""></Span><span class="c-type">{{booking.meeting}}</span>
                                  
                              
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-md-6 col-6">
                                    <p class="s-label">Course Duration</p>
                                    <p class="s-date">{{booking.date}}</p>
                                    <p class="s-time">{{booking.day}}</p>
                                </div>
                                <div class="col-md-6  col-6 buk-wrap">
                                    <div class="time-wrap"> <span class="s-label">Time :</span><span
                                            class="s-date">{{booking.time}}</span></div>
                                     <!-- <p class="s-label">Status</p> -->
                                     <p class="s-date">
                                        <span><img [src]="booking.statusicon" alt=""></span>
                                        <span class="{{booking.statustext}}" id="b-status">{{booking.status}}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-6">
                                    <button class="btn btn-block btn-edit"
                                        (click)="openCancelBookingPanel = true">Cancel</button>

                                    <!-- <button class="btn btn-block btn-edit text-center menu">
                    Edit
                    <div class="menu-dropdown">
                        <a (click)="openEditBookingPanel = true" class="text-left">Reschedule
                            lesson</a>
                        <a (click)="openCancelBookingPanel = true" class="text-left">Cancel
                            lesson</a>
                    </div>
                </button> -->
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-block btn-accept"
                                        (click)="openEditBookingPanel = true">Reschedule</button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </p-tabPanel>
            <p-tabPanel header="Previous">
                <div class="booking-tab-content">
                    <div class="booking-status-dropdown w-25 mt-3">
                        <ng-select [items]="previousStatusList" bindLabel="name" [searchable]="false"
                            [(ngModel)]="defaultPreviousStatus" [clearable]="false">
                        </ng-select>
                    </div>

                    <div class="booking-card-wrapper">
                        <div class="bookings-card-view mt-3" *ngFor="let booking of bookingsList; let i = index;">
                            <div class="tutor-header">
                                <div class="t-header-inner">
                                    <img class="s-img" src="assets/images/tutor-p.jpg" alt="">
                                </div>
                                <div class="t-header-inner">
                                    <h6 class="t-name">{{booking.tutorname}}</h6>
                                    <p class="s-subject">{{booking.subject}}</p>
                                </div>
                                <div class="t-header-inner text-right d-none">
                                    <button class="btn btn-block btn-edit text-right menu">
                                        <img src="assets/icons/menu-dot.svg" alt="" class="dotted-menu menu">
                                        <div class="menu-dropdown">
                                            <a (click)="openEditBookingPanel = true" class="text-left">Reschedule
                                                lesson</a>
                                            <a (click)="openCancelBookingPanel = true" class="text-left">Cancel
                                                lesson</a>
                                        </div>
                                    </button>
                                </div>
                                <div class="t-price">
                                    <span class="s-label">Price:</span><span class="s-date">$30</span>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-md-6 col-6">
                                    <p class="s-label">Course Duration</p>
                                    <p class="s-date">{{booking.date}}</p>

                                    <p class="s-time">{{booking.day}}</p>
                                </div>
                                <div class="col-md-6 col-6 buk-wrap">
                                    <div class="time-wrap">
                                        <span class="t-time">Time :</span><span class="s-date">{{booking.time}}</span>
                                    </div>
                                    <!-- <p class="s-label">Status</p> -->

                                    <p class="s-date">
                                        <span><img src="assets/icons/contact/success.svg" alt=""></span>
                                        <span class="text-success" id="b-status">Completed</span>
                                    </p>
                                </div>
                            </div>
                            <div class="row mt-4 d-none">
                                <div class="col-md-6">
                                    <button class="btn btn-block btn-edit"
                                        (click)="openCancelBookingPanel = true">Cancel</button>

                                    <!-- <button class="btn btn-block btn-edit text-center menu">
                                        Edit
                                        <div class="menu-dropdown">
                                            <a (click)="openEditBookingPanel = true" class="text-left">Reschedule
                                                lesson</a>
                                            <a (click)="openCancelBookingPanel = true" class="text-left">Cancel
                                                lesson</a>
                                        </div>
                                    </button> -->
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-block btn-accept"
                                        (click)="openEditBookingPanel = true">Reschedule</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>


<!-- Reschedule Booking  -->
<div class="reschedule-booking-wrapper">
    <p-sidebar [(visible)]="openEditBookingPanel" position="right" [baseZIndex]="10000">
        <div class="row add-panel-close">
            <div class="col-md-10 col-10">
                <h5 class="add-student-heading">free meeting rescheduling</h5>
            </div>
            <!------------------------------->

            <div class="col-md-2 text-right col-2">
                <a (click)="openEditBookingPanel = false">
                    <img src="assets/icons/cross.svg" alt="">
                </a>
            </div>
        </div>
        <div>
            <label for="">Who will attend the free meeting?</label>

            <input type="text" [(ngModel)]="inputValue" class="form-control">
        </div>
        <!--------------------------->
        <div class="mt-3">
            <!-- <a (click)="showBasicDialog()" icon="pi pi-external-link"  class="student-wrap">Add new Student</a> -->
            <div class="add">
                <a class="add-student" (click)="toggleInput()">Add Student</a>
                <div *ngIf="oneshowInput" class="add-student-w">
                    <label for="#">Student Name</label>
                    <input type="text" class="form-control">
                    <div>
                        <label for="#">Email</label>
                        <input type="email" class="form-control">
                    </div>
                    <button type="submit" class="add-btn">Add Student</button>
                </div>
            </div>
        </div>
        <div class="row">

            <!-- <p-dialog header="Add Your Child" [(visible)]="displayBasic" [style]="{width: '30vw'}" [baseZIndex]="10000" icon="pi pi-times"  (click)="displayBasic=false" >
<form>
    <div class="row">
        <div class="col-md-6">
            <label for="">First Name</label>
            <input type="text">
        </div>
        <div class="col-md-6">
            <label for="">Second Name</label>
            <input type="text">
        </div>
    </div>
<div>
    <label for="">Email</label>
    <input type="Email">
</div>
</form>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="displayBasic=false" label="Yes" styleClass="p-button-text"></p-button>
        <p-button icon="pi pi-times" (click)="displayBasic=false" label="No"></p-button>
    </ng-template>
</p-dialog> -->
        </div>
        <!-- <p-dialog header="Add Your Child" [(visible)]="displayBasic"[style]="{width: '30vw'}" [baseZIndex]="10000">
    <p>For your child to join this free meeting, create an account.</p>
    <form (ngSubmit)="onSubmit()">
      <div>
        <label for="first-name">First Name</label>
      
        <input  class="form-control" pInputText type="text"  [(ngModel)]="submittedValue" name="submittedValue">
      </div>
      <div>
        <label for="last-name">Last Name</label>
        <input id="last-name" pInputText type="text" [(ngModel)]="lastName" name="lastName" class="form-control">
      </div>
      <div>
        <label for="email">Email</label>
        <input id="email"  class="form-control" pInputText type="email" [(ngModel)]="email" name="email">
      </div>
      <div  class="mt-3">
        <button pButton type="submit"class="add-btn">Add Student</button>
       
      </div>
   <p class="msg-wrap">Your student will receive an email with these details.</p>
    </form>
  </p-dialog> -->









        <!---------------->
        <div class="mt-5">
            <h5>Offer a fresh time proposal to Troy-Francis B.</h5>
            <div class="row booking-reschedule mt-5 d-none">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Pick a day</label>
                        <input type="date" class="form-control mt-2" id="" placeholder="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Pick a time</label>
                        <input type="time" class="form-control mt-2" id="" placeholder="">
                    </div>
                </div>

            </div>
            <div class="row r-shedule mt-5">
                <div class="col-md-6">
                    <h4 class="picker-date">From</h4>
                    <input type="date" formControlName="day">

                </div>
                <div class="col-md-4 d-none">
                    <h4 class="picker-date">To</h4>
                    <input type="date" formControlName="dayone">

                </div>
                <div class="col-md-6">
                    <h4 class="picker-date">Select Time</h4>
                    <input type="time" formControlName="time">

                </div>
            </div>
            <!-- <div class="days-wrapper mt-3">
                     
                <div>
                
                        <input type="checkbox" id="checkbox1">
                        <label for="checkbox1" class="check-label">Mon</label>

                 
                </div>
                <div>
                    <input type="checkbox" id="checkbox1">
                    <label for="checkbox1" class="check-label">Tue</label>
                </div>
                <div>
                    <input type="checkbox" id="checkbox1">
                    <label for="checkbox1" class="check-label">Wed</label>
                </div>
                <div>
                    <input type="checkbox" id="checkbox1">
                    <label for="checkbox1" class="check-label">Thu</label>
                </div>
                <div>
                    <input type="checkbox" id="checkbox1">
                    <label for="checkbox1" class="check-label">Fri</label>
                </div>
                <div>
                    <input type="checkbox" id="checkbox1">
                    <label for="checkbox1" class="check-label">Sat</label>
                </div>
                <div>
                    <input type="checkbox" id="checkbox1">
                    <label for="checkbox1" class="check-label">Sat</label>
                </div>
              </div> -->
            <div class="container">
                <div class="row radio-selection">
                    <!-- Radio -->
                    <div class="grid-wrapper grid-col-auto d-none">
                        <label for="radio-card-1" class="radio-card">
                            <input type="radio" name="radio-card" id="radio-card-1" checked />
                            <div class="card-content-wrapper">
                                <span class="check-icon"></span>
                                <div class="card-content ">
                                    <h5 class="text-center">ONLY RESCHEDULE THIS LESSON</h5>
                                </div>
                            </div>
                        </label>
                        <!-- /.radio-card -->

                        <label for="radio-card-2" class="radio-card d-none">
                            <input type="radio" name="radio-card" id="radio-card-2" />
                            <div class="card-content-wrapper">
                                <span class="check-icon"></span>
                                <div class="card-content">
                                    <h5 class="text-center">RESCHEDULE ALL LESSONS FROM THU 9 FEB 14:30 ONWARDS</h5>
                                </div>
                            </div>
                        </label>

                    </div>
                </div>
            </div>

            <div class="row booking-reschedule mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="">Send Tutor a message (optional)</label>
                        <textarea name="" id="" cols="30" rows="4"></textarea>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <button class="btn btn-block btn-accept">Update Request</button>
                    </div>
                </div>

            </div>

        </div>
    </p-sidebar>
</div>
<!-- Reschedule Booking -->


<!-- Cancel Booking  -->
<div class="reschedule-booking-wrapper">
    <p-sidebar [(visible)]="openCancelBookingPanel" position="right" [baseZIndex]="10000">
        <div class="row add-panel-close">
            <div class="col-md-10 col-10">
                <h5 class="add-student-heading">Cancel booking</h5>
            </div>
            <div class="col-md-2 text-right col-2">
                <a (click)="openCancelBookingPanel = false">
                    <img src="assets/icons/cross.svg" alt="">
                </a>
            </div>
        </div>

        <div class="mt-5">
            <h5>Are you sure you want to cancel?</h5>
            <div class="confirm-delete mt-4 mb-3">

                <div class="row">
                    <div class="col-md-6 col-6">
                        <label for="" class="delete-item-label">Tutor</label>
                        <p class="delete-item">Anastasia B.</p>
                    </div>
                    <div class="col-md-6 text-right col-6">
                        <label for="" class="delete-item-label">Date & Time</label>
                        <p class="delete-item">Thu 9 Feb, 14:30</p>
                    </div>
                    <div class="col-md-6 col-6">
                        <label for="" class="delete-item-label">Subject</label>
                        <p class="delete-item">History of Art</p>
                    </div>
                    <div class="col-md-6 text-right col-6">
                        <label for="" class="delete-item-label">Level</label>
                        <p class="delete-item">A Level</p>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row radio-selection">
                    <!-- Radio -->
                    <div class="grid-wrapper grid-col-auto">
                        <label for="radio-card-1" class="radio-card">
                            <input type="radio" name="radio-card" id="radio-card-1" checked />
                            <div class="card-content-wrapper">
                                <span class="check-icon"></span>
                                <div class="card-content">
                                    <h5 class="text-center">ONLY CANCEL THIS LESSON</h5>
                                </div>
                            </div>
                        </label>
                        <!-- /.radio-card -->

                        <label for="radio-card-2" class="radio-card">
                            <input type="radio" name="radio-card" id="radio-card-2" />
                            <div class="card-content-wrapper">
                                <span class="check-icon"></span>
                                <div class="card-content">
                                    <h5 class="text-center">CANCEL ALL LESSONS FROM THU 9 FEB 14:30 ONWARDS</h5>
                                </div>
                            </div>
                        </label>

                    </div>
                </div>
            </div>

            <div class="row booking-reschedule mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="">Reason for cancellation (optional)</label>
                        <textarea name="" id="" cols="30" rows="4"></textarea>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <button class="btn btn-block btn-danger btn-cancel">Cancel lesson</button>
                    </div>
                </div>

            </div>

        </div>
    </p-sidebar>
</div>
<!-- Cancel Bookingy -->



<!---------------------------for group-->




<!-- Reschedule Booking  -->
<div class="reschedule-booking-wrapper">
    <p-sidebar [(visible)]="groupopenEditBookingPanel" position="right" [baseZIndex]="10000">
        <div class="row add-panel-close">
            <div class="col-md-10 col-10">
                <h5 class="add-student-heading">Rescheduling Booking</h5>
            </div>
            <!------------------>

            <div class="col-md-2 text-right col-2">
                <a (click)="groupopenEditBookingPanel = false">
                    <img src="assets/icons/cross.svg" alt="">
                </a>
            </div>
        </div>

        <input type="text" [(ngModel)]="inputValue" class="form-control d-none">

        <!---------------->
        <div class="mt-5">
            <h5>Present another meeting time to Aidan.</h5>
            <div class="row booking-reschedule mt-5 d-none">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Pick a day</label>
                        <input type="date" class="form-control mt-2" id="" placeholder="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Pick a time</label>
                        <input type="time" class="form-control mt-2" id="" placeholder="">
                    </div>
                </div>

            </div>
            <div class="r-shedule  mt-5" *ngIf="showTextFlag">
                <!-- <div class="col-md-4">
<h4 class="picker-date">From</h4>
<input type="date" formControlName="day">

                </div> -->
                <p>only this session your going to Reshedule</p>
                <div class="row">
                    <div class="col-md-6">
                        <h4 class="picker-date">Select Date</h4>
                        <input type="date" formControlName="dayone">

                    </div>
                    <div class="col-md-6">
                        <h4 class="picker-date">Select Time</h4>
                        <input type="time" formControlName="time">
                    </div>
                </div>
            </div>
            <div class="r-shedule  mt-5" *ngIf="showInputFlag">
                <p>This will apply to all Sessions in this slot</p>
                <div class="row">
                    <div class="col-md-4">
                        <h4 class="picker-date">From</h4>
                        <input type="date" formControlName="day">

                    </div>
                    <div class="col-md-4">
                        <h4 class="picker-date">Select Date</h4>
                        <input type="date" formControlName="dayone">

                    </div>
                    <div class="col-md-4">
                        <h4 class="picker-date">Select Time</h4>
                        <input type="time" formControlName="time">

                    </div>
                </div>
            </div>
            <!-- <div class="days-wrapper mt-3">
                     
                <div>
                
                        <input type="checkbox" id="checkbox1">
                        <label for="checkbox1" class="check-label">Mon</label>

                 
                </div>
                <div>
                    <input type="checkbox" id="checkbox1">
                    <label for="checkbox1" class="check-label">Tue</label>
                </div>
                <div>
                    <input type="checkbox" id="checkbox1">
                    <label for="checkbox1" class="check-label">Wed</label>
                </div>
                <div>
                    <input type="checkbox" id="checkbox1">
                    <label for="checkbox1" class="check-label">Thu</label>
                </div>
                <div>
                    <input type="checkbox" id="checkbox1">
                    <label for="checkbox1" class="check-label">Fri</label>
                </div>
                <div>
                    <input type="checkbox" id="checkbox1">
                    <label for="checkbox1" class="check-label">Sat</label>
                </div>
                <div>
                    <input type="checkbox" id="checkbox1">
                    <label for="checkbox1" class="check-label">Sat</label>
                </div>
              </div> -->
            <div class="container">
                <div class="row radio-selection">
                    <!-- Radio -->
                    <div class="grid-wrapper grid-col-auto">
                        <label for="radio-card-1" class="radio-card">
                            <input type="radio" name="radio-card" id="radio-card-1" (click)="showText()" checked />
                            <div class="card-content-wrapper">
                                <span class="check-icon"></span>
                                <div class="card-content ">
                                    <h5 class="text-center">ONLY RESCHEDULE THIS LESSON</h5>
                                </div>
                            </div>
                        </label>
                        <!-- /.radio-card -->

                        <label for="radio-card-2" class="radio-card ">
                            <input type="radio" name="radio-card" id="radio-card-2" (click)="showInput()" />
                            <div class="card-content-wrapper">
                                <span class="check-icon"></span>
                                <div class="card-content">
                                    <h5 class="text-center">RESCHEDULE ALL LESSONS FROM THU 9 FEB 14:30 ONWARDS</h5>
                                </div>
                            </div>
                        </label>

                    </div>
                </div>
            </div>

            <div class="row booking-reschedule mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="">Send Tutor a message (optional)</label>
                        <textarea name="" id="" cols="30" rows="4"></textarea>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <button class="btn btn-block btn-accept">Request new time</button>
                    </div>
                </div>

            </div>

        </div>
    </p-sidebar>
</div>
<!-- Reschedule Booking -->