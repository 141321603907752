<div class="parent-signup-wrapper">
    <h1>Kindly submit a copy of your graduation certificate</h1>
    <p>Your graduation certificate is required for the verification process</p>

    <div class="tutor-signup container">
        <!-- <div class="card"> -->
        <div class="input-block">


            <!-- <form #fileUploadForm="ngForm" (ngSubmit)="uploadFile(fileUploadForm)">
              
                <input type="file" [(ngModel)]="selectedFile" name="selectedFile" required>
               
              </form> -->
            <div class="form-group">
                <!-- <label class="col-form-label
                        mt-2">Upload Image (max size 0.5MB)
                        <span class="required-symbol">*</span></label> -->
                <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" [expandable]="true"
                    (change)="onFileChange($event)">
                    <ngx-dropzone-label>
                        <div>
                            <img src="assets/icons/upload.svg" alt="">
                            <h6 class="mt-3
                                    mb-2">Drop your image here.</h6>
                            <!-- <p>Or</p>
                            <h6 class="mt-1" style="color:rgb(22,
                                    114, 235);">Browse Files
                            </h6> -->
                        </div>
                    </ngx-dropzone-label>
                    <ngx-dropzone-image-preview #nationalDropZone ngProjectAs="ngx-dropzone-preview" *ngFor="let f of
                            fileList" [file]="f" [removable]="true" (removed)="onRemove(f)">
                    </ngx-dropzone-image-preview>
                </div>
                <!--   <input type="file"  (change)='onFileChange($event)'> -->
            </div>



        </div>
        <div class="row">
            <div class="col-md-6">
                <button class="btn-back" type="button" [routerLink]="['/tutor/persoal-details']">Back</button>
            </div>
            <div class="col-md-6">
                <button class="btn-next" type="button" [routerLink]="['/tutor/application']">Upload
                    <!-- <img src="assets/icons/arrow-right-n.svg" alt="">     -->
                </button>
            </div>
        </div>
    </div>
</div>