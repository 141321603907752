import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';

import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-parents-signup',
  templateUrl: './parents-signup.component.html',
  styleUrls: ['./parents-signup.component.scss']
})
export class ParentsSignupComponent implements OnInit {
  signUpForm: any;
  formSubmited = false;
 
  errMsg: any;
  showPwdLabel = "password";
  showSuccessPage = false;
  countryCode = environment.countryCode;
  changePWDForm: any;
  selectedGender='female';
  registrationForm: any;

  submitted = false;
  myForm: any;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  studentFirstName: string | undefined;
  studentLastName: string | undefined;
  educationLevel: string | undefined;
  formSubmitted = false;

  constructor(private fb: FormBuilder,
    public router: Router,
    private socialAuthService: SocialAuthService) { }
    gender:any=[
      {name:'Male'},
      {name:'Female'},
      {name:'Others'}
    ]
    ngOnInit(): void {
      this.registrationForm = this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phoneNumber: ['', Validators.required],
        studentFirstName: ['', Validators.required],
        studentLastName: ['', Validators.required],
        educationLevel: ['', Validators.required]
      });
    }
    
  //     this.signUpForm = new FormGroup({
  //       email: new FormControl('', [Validators.required, Validators.email]),
  //       mobile: new FormControl('', [Validators.required, Validators.pattern(new RegExp("[0-9 ]{10}")), Validators.maxLength(10)]),
  //       pwd: new FormControl('', [Validators.required]),
  //       fname: new FormControl('', [Validators.required]),
  //       lname: new FormControl('', [Validators.required]),
  //       sfname: new FormControl('', [Validators.required]),
  //       slname: new FormControl('', [Validators.required]),
  //       sstandard:new FormControl('',[Validators.required]),
  //       agree: new FormControl(false, [Validators.requiredTrue])
  //     });
      
  //   }
  //   get emailControls() { return this.signUpForm.get('email'); }
  // get phoneNumControls() { return this.signUpForm.controls;}
  // // get pwdControls() { return this.changePWDForm.controls; }
  // private passwordsShouldMatch(fGroup: FormGroup) {
  //   return this?.changePWDForm?.get('newPWD')?.value === this.changePWDForm?.get('cnfrmPWD')?.value
  //     ? null : { 'mismatch': true };
  // } 
  // isVisible = () => {
  //   this.showPwdLabel = this.showPwdLabel === 'password' ? 'text' : 'password';
  // }
  // get pwdControls() { return this.signUpForm.get('pwd'); }
  // get fnameControls() { return this.signUpForm.get('fname'); }
  // get lnameControls() { return this.signUpForm.get('lname'); }
  // get studentfnameControls() { return this.signUpForm.get('sfname'); }
  // get studentlnameControls() { return this.signUpForm.get('slname'); }
  // get agreeControls() { return this.signUpForm.get('agree'); }
  // get studyControl(){return this.signUpForm.get('sstandard')}

  // // onSubmit = (event: any) => {
  // //   event.preventDefault();
  // //   this.formSubmited = true;
  // //   this.errMsg ='';  
  // //   if (this.signUpForm.valid) {
  // //     this.signUpForm.value.phoneNum = environment.countryCode+this.signUpForm.value.mobile;
   
  // //   }

  // onSubmit = (event: any) => {
  //   event.preventDefault();
  //   this.formSubmited = true;
  //   this.errMsg ='';
  
  //   if (this.signUpForm.valid) {
  //     this.signUpForm.value.phoneNum = environment.countryCode+this.signUpForm.value.mobile;
  //     // Perform form submission here
  //     // ...
  //     // Navigate to the next page after successful form submission
  //     this.router.navigate(['/view-tutors']);
  //   }

  // }

  submitForm() {
    this.formSubmitted = true;

    if (this.firstName && this.lastName && this.email && this.phone && this.studentFirstName && this.studentLastName && this.educationLevel) {
      // form is valid, navigate to another page
      this.router.navigate(['/tutor-login']);
    }
  }
}

    // form is valid, navigate to next screen
    // ...
  




