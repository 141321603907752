import { Component, ElementRef, Input, NgZone } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { StorageService } from './services/storage.service';
import * as jQuery from 'jquery';
import * as CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';
import { NgcCookieConsentService, NgcInitializationErrorEvent, NgcInitializingEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription }   from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { CookieService } from "ngx-cookie-service";
import { environment } from 'src/environments/environment';
// declare var $: any;
// declare var jQuery: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent { 

  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;

  title = 'servu-web';
  currentRoute: any;
  userInfo: any;
  showHeader: any = true;
  SERVICE_PROVIDER_ID:any="-1";
  multiProviderMode:boolean=true;

  constructor(public authService: AuthService, private router: Router,
    private elementRef:ElementRef,
    private mapsAPILoader: MapsAPILoader, 
    private ngZone: NgZone,
    private ccService: NgcCookieConsentService,
    private cookieSvc: CookieService,
    private http: HttpClient,
    public storageService: StorageService) {      
    // debugger;
    this.multiProviderMode = window.location.hostname.toLowerCase()==environment.cookieDomain.toLowerCase();
    // if(this.SERVICE_PROVIDER_ID!=-1)
    // {
    //   sessionStorage.setItem("SERVICE_PROVIDER",this.SERVICE_PROVIDER_ID);
    // }

    this.router.events.pipe(filter((event: any) => event instanceof NavigationStart)).subscribe(
      (event: any) => {
        
        jQuery('body, html').animate({ scrollTop: 0 }, 100)
        this.userInfo = JSON.parse(<string>this.storageService.getItem("user_info"));
        if (this.userInfo) {
          if (event.url === '/profile' && this.userInfo?.is_facebook_google && (this.userInfo?.email === null || this.userInfo?.email === undefined || this.userInfo?.email !== '') && (this.userInfo?.mobile === null || this.userInfo?.mobile === undefined || this.userInfo?.mobile === '')) {
            this.showHeader = false;
          } else {
            this.showHeader = true;
          }
        }
      }
    );
    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(
      (event: any) => {
        // jQuery('#demo').collapse("hide");
        this.currentRoute = event.url;
      }
    );
    this.authService.onUserLogin.subscribe((data: any) => {
      this.showHeader = true;
      this.userInfo = JSON.parse(<string>this.storageService.getItem("user_info"));
      if (this.userInfo) {
        if (this.currentRoute === '/profile' && this.userInfo?.is_facebook_google && (this.userInfo?.email === null || this.userInfo?.email === undefined || this.userInfo?.email !== '') && (this.userInfo?.mobile === null || this.userInfo?.mobile === undefined || this.userInfo?.mobile === '')) {
          this.showHeader = false;
        }
      }
    });
    this.getLocation();
  }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        if (position) {
          console.log("Latitude: " + position.coords.latitude +
            "Longitude: " + position.coords.longitude);
          let lat = position.coords.latitude;
          let lng = position.coords.longitude;
          sessionStorage.setItem('latitude', String(lat));
          sessionStorage.setItem('longitude', String(lng));
        }
      },
        (error: any) => {
           console.log(error)
           sessionStorage.setItem('latitude', String('17.432576'));
           sessionStorage.setItem('longitude', String('78.4498688'));
        });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }
  ngOnInit() {
    
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.initializingSubscription = this.ccService.initializing$.subscribe(
      (event: NgcInitializingEvent) => {
        // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
        console.log(`initializing: ${JSON.stringify(event)}`);
      });
    
    this.initializedSubscription = this.ccService.initialized$.subscribe(
      () => {
        // the cookieconsent has been successfully initialized.
        // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
        console.log(`initialized: ${JSON.stringify(event)}`);
      });

    this.initializationErrorSubscription = this.ccService.initializationError$.subscribe(
      (event: NgcInitializationErrorEvent) => {
        // the cookieconsent has failed to initialize... 
        console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
  
}
