<div class="bg-color-of-pages">

    <div class="providerd-top-details container-fluid mb-5">

        <img src="https://safaiwale.in/wp-content/themes/clenix/assets/img/banner.jpg" alt="" style="width: 100%;">

        <h6 class="our-services-title text-center mt-5">Our Services</h6>
        <h1 class="under-os text-center">The best window cleaning company</h1>

        <div class="booking-providers-list-cards-info mt-5 mb-5">
            <div class="tab-content">
                <div id="description" class="tab-pane fade show active">
                    <div class="main-service-wrapper">
                        <div class="service-item-inner-card" *ngFor="let item of servicesList">
                            <img [src]="item.image" alt="" (click)="gotoServiceDetailsPage(item)">
                            <p class="s-name" (click)="gotoServiceDetailsPage(item)">{{item.title}}</p>
                            <div class="divider"></div>

                            <div class="bottom-section" *ngIf="!item?.quote_only">
                                <div class="left">
                                    <p>Price </p>
                                </div>
                                <div class="right text-right pr-4">
                                    <p>{{ item.min_charge | currency: currencyCode:'symbol':'1.0-0' }}</p>
                                </div>
                            </div>

                            <div class="bottom-section" *ngIf="item?.quote_only">
                                <div class="left">
                                    <p>Starts from </p>
                                </div>
                                <div class="right text-right pr-4">
                                    <p>{{item.min_charge | currency: currencyCode:'symbol':'1.0-0' }}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Temporary content -->
        <div class="about-provider" id="about">
            <h6 class="text-center">The best window cleaning company</h6>
            <h3 class="text-center text-bold mt-2">About G S Window Cleaning</h3>

            <div class="row mt-5">
                <div class="col-md-6">
                    <img src="assets/images/gsw.png" alt="" style="width: 100%;">
                </div>
                <div class="col-md-6 gs-right-sec">
                    <p>When it comes to maintaining your property it is important to choose a trusted company to make
                        sure the cleaning tasks are done properly. We pride ourselves on being safe contractors with all
                        the relevant training and insurances, so you can have peace of mind that we will do a great job.
                        We are your local external cleaning company and have been established for over 15 years.</p>
                    <p>We are best known for our 5* window cleaning in St Neots. We provide regular and reliable window
                        cleaning in St Neots to a long list of happy customers, take a look at our reviews to see for
                        yourself what our customers have to say. We use the latest water fed pole technology with pure
                        water when window cleaning in St Neots, we can clean your windows safely from the ground and
                        keep them sparkling all year round.</p>
                    <p class="d-none">We also offer a whole range of external cleaning services such as gutter cleaning,
                        roof cleaning, jet washing, etc. We are your one stop shop when it comes to taking care of your
                        property. We welcome both domestic and commercial clients and have experience cleaning all
                        different sorts of buildings. We offer fantastic rates for all of our services, if you would
                        like a quote, please get in touch and we would be happy to give you a price for our work.</p>
                    
                    <div class="cert-wrapper">
                        <div class="cert-item text-center">
                            <img src="assets/images/cert-one.png" alt="">
                        </div>
                        <div class="cert-item text-center">
                            <img src="assets/images/cert-two.jpg" alt="">
                        </div>
                        <div class="cert-item text-center">
                            <img src="assets/images/cert-three.jpg" alt="">
                        </div>
                        <div class="cert-item text-center">
                            <img src="assets/images/cert-four.png" alt="">
                        </div>
                    </div>
                </div>
            </div>

            <!-- why choose us -->
            <div class="why-choose-wrapper">
                <h6 class="text-center">A NAME YOU CAN TRUST</h6>
                <h3 class="text-center text-bold mt-2">Why you should choose us</h3>

                <div class="choose-card-wrapper">
                    <div class="choose-card">
                        <div class="text-center">
                            <img src="assets/images/rate.png" alt="">
                        </div>
                        <h5 class="text-center">We are the experts</h5>
                        <p class="text-center">
                            We have over 15 years experience in the industry, our cleaning team are highly trained and use the latest equipment for all of our cleaning tasks.
                        </p>
                    </div>
                    <div class="choose-card">
                        <div class="text-center">
                            <img src="assets/images/shopping.png" alt="">
                        </div>
                        <h5 class="text-center">Competitive prices</h5>
                        <p class="text-center">
                            We pride ourselves on offering the best value out there and aim to always offer the best price. For a tailored quote please give us a call.
                        </p>
                    </div>
                    <div class="choose-card">
                        <div class="text-center">
                            <img src="assets/images/rating.png" alt="">
                        </div>
                        <h5 class="text-center">5* reviews</h5>
                        <p class="text-center">
                            Customer service is really important to us, we want our customers to be 100% happy with our work. Take a look at our reviews!
                        </p>
                    </div>
                </div>

            </div>



        </div>
        <!-- Temporary content -->

    </div>
</div>


<app-footer></app-footer>
<!-- <section *ngIf="pop.value">
    <app-cart-pop [cartDetails]="pop"></app-cart-pop>
</section> -->