import { MapsAPILoader } from '@agm/core';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { AddressPopupComponent } from 'src/app/components/address-popup/address-popup.component';
import { AuthService } from 'src/app/services/auth.service';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  landmark: any;
  address1: any;
  showMyContainer: boolean = false;

  // addressType:string;
  addressLabelsList: any = [
    {
      name: 'Home',
      id: 1,
      icon: 'bi-house-door'
    },
    {
      name: 'Work',
      id: 2,
      icon: 'bi-briefcase'
    },
    {
      name: 'Other',
      id: 3,
      icon: 'bi bi-geo-alt'
    }
  ]

  // selectedAddressType(type:any)
  // {
  //   this.addressType=type;
  // }
  selectAddressLabel(item: any) {
    let eleIndex = this.addressLabelsList.findIndex((add: any) => add.id == item.id);
    this.addressLabelsList.forEach((address: any) => address['selected'] = false);
    this.addressLabelsList[eleIndex].selected = true;
    this.addressLabel = item.name;
  }

  @ViewChild('mapSearchFiled_4', { static: true }) searchFiled: ElementRef | undefined;
  userDetails: any
  lat = 0;
  lng = 0;
  zoom = 12
  userLocation = "";
  geoCoder: any;
  selectedAddress: any;
  addressLabel: any = '';
  cityName: any;
  showAccount: any = false;
  addressList: any;
  isSubmitted = false;
  keyword: any;
  catList: any;
  addresssidepanel: any;
  addresses: any = [];
  address_type: any;
  address_id: any;
  public input$ = new Subject<any>();
  constructor(public authService: AuthService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public dataService: DataService,
    public storageService: StorageService,
    public router: Router,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private bs: BroadcastService) { }

  ngOnInit(): void {
    this.authService.onUserLogin.subscribe((data: any) => {
      this.showAccount = data;
      this.userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));;
    });
    this.getAddresses();
    this.showAccount = this.authService.isAuthenticated();
    this.userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));

    this.input$.subscribe((newTerm) => {
      this.keyword = newTerm;
      this.getBusinessCategory();
    });
  }

  ngAfterViewInit() {
   this.getGPSLocation();

  }

  gotoManageAddresses()
  {
    // this.router.navigate(["/profile"],{queryParams:{tab:'2'}});

    // Route with Reload
    this.router.navigate(['/profile'],{queryParams:{tab:'2'}})
    .then(() => {
      window.location.reload();
    });
  }

  getGPSLocation = () => {
    this.showLocation = false;
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;

      const showPos = (position: any) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.getAddress();

      }
      let self = this;
      if (navigator.geolocation) {
        // @ts-ignore
        navigator.geolocation.getCurrentPosition(showPos);

      }


      //       // @ts-ignore
      //       let autocomplete = new google.maps.places.Autocomplete(this.searchFiled?.nativeElement, {});
      //       autocomplete.addListener("place_changed", () => {
      //         this.ngZone.run(() => {
      // console.log('cLLED')
      //           let place: any = autocomplete.getPlace();
      //           this.userLocation = place.formatted_address;
      //           this.lat = place.geometry.location.lat();
      //           this.lng = place.geometry.location.lng();
      //           this.selectedAddress = this.parse_place(place);
      //           this.updateLocationInSession()
      //         });

      let autoLocComplete = new google.maps.places.Autocomplete(this.searchFiled?.nativeElement, {
        // componentRestrictions: { country: "in" },
        // fields: ["address_components", "geometry", "icon", "name"],
        // strictBounds: false,
        // types: ["(cities)"],
      });
      autoLocComplete.setFields(["address_components", "formatted_address", "geometry"]);
      autoLocComplete.addListener("place_changed", () => {
        let autoplace:any = autoLocComplete.getPlace();
        let autouserLocation: any = autoplace.address_components;
        let autoaddress = '';
        autoaddress = autouserLocation[0].long_name
        this.cityName = autoaddress;
        this.userLocation = autoplace.formatted_address;
        this.lat = autoplace.geometry.location.lat();
        this.lng = autoplace.geometry.location.lng();
        this.selectedAddress = this.parse_place(autoplace);
        this.bs.broadcast('userLocation',  this.userLocation)
        this.updateLocationInSession()
      });
    });
  }
  showLocation = false;
  getCurrentLocation = () => {
    this.storageService.removeItem('latitude');
     this.storageService.removeItem('longitude');
    this.getGPSLocation();
    this.showLocation = true;
  }

  getAddress() {
    let lat:any =  this.storageService.getItem('latitude');
    let lng: any = this.storageService.getItem('longitude');

    lat = JSON.parse(lat) ;
    lng =  JSON.parse(lng) ;

    if(lat !== null && lat !== undefined){
      this.lat = lat;
    }
    if(lng !== null && lng !== undefined){
      this.lng = lng;
    }
    this.geoCoder.geocode({
      'location': {
        lat: this.lat,
        lng: this.lng
      }
    }, (results: { formatted_address: string; }[], status: string) => {
      if (status === 'OK') {
        if (results[0]) {
          this.userLocation = results[0].formatted_address;
          this.selectedAddress = this.parse_place(results[0]);
          this.updateLocationInSession();
        } else {
          Swal.fire({
            text: 'No results found',
            icon: 'warning',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
        }
      } else {
        Swal.fire({
          text: 'Geocoder failed due to: ' + status,
          icon: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        });
      }
    });
  }

  buildAddress(address: any) {
    let result: string = '';
    if (address !== null && address !== undefined && address !== '') {
      if (address.address1) {
        result = address.address1 + ", ";
      }

      if (address.address2) {
        result += address.address2 + ", ";
      }

      if (address.land_mark) {
        result += address.land_mark + ", ";
      }

      if (address.post_code) {
        result += address.post_code + ", ";
      }

      if (address.state) {
        result += address.state + ", ";
      }

      if (address.city) {
        result += address.city + ", ";
      }

      if (address.country) {
        result += address.country + ", ";
      }

      // Remove the last comma and space from the result
      if (result.length > 0) {
        result = result.slice(0, -2);
      }

      return result;
    }
    return
  }
  deleteAddress = (address: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to delete your address",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        this.dataService.deleteCustomerAddress(address.address.address_id).subscribe((resp: any) => {
          if (resp.success) {
            Swal.fire({
              text: resp.status,
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then((res: any) => {
              if (res.isConfirmed) {
                this.getAddresses();
              }
            });
          } else {
            Swal.fire({
              text: resp.error,
              icon: 'error',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            });
          }
        });
      }
    });
  }

  editAddress(address?: any) {
    this.addresssidepanel = false;
    const modalRef = this.modalService.open(AddressPopupComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    modalRef.componentInstance.addressObj = {address: address.address, fromState: address ? 'Edit' :'new '};

    modalRef.result.then((result) => {
      if (result) {
        this.getAddresses();
        this.addresssidepanel = true;
      }
    });
  }

  getAddresses() {
    const userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
    this.dataService.getCustomerAddressList(userDetails?.customer_id).subscribe((resp: any) => {
      this.addresses = resp.address;
    });
  }

  updateLocationInSession() {
    this.bs.broadcast('userLocation',  this.userLocation)
    this.storageService.setItem('location', this.userLocation);
    this.storageService.setItem('latitude', JSON.stringify(this.lat));
    this.storageService.setItem('longitude', JSON.stringify(this.lng));
  }


  markerDragEnd(e: any) {
    this.lat = e.latLng.lat();
    this.lng = e.latLng.lng();
    this.zoom = 16;
    this.getAddress();
  }

  getStatus(add: any) {
    return add.selected;
  }


  parse_place = (place: any) => {
    let location: any = [];
    for (var ac = 0; ac < place.address_components.length; ac++) {
      var component = place.address_components[ac];
      switch (component.types[0]) {
        case 'locality':
          location['city'] = component.long_name;
          this.cityName = component.long_name;
          break;
        case 'administrative_area_level_1':
          location['state'] = component.long_name;
          break;
        case 'country':
          location['country'] = component.long_name;
          break;
        case 'postal_code':
          location['post_code'] = component.long_name;
          break;
        case 'political':
          location['address1'] = component.long_name;
          break;
        case 'premise' || 'neighborhood':
          location['address2'] = component.long_name;
          break;
        case 'landmark':
          location['landmark'] = component.long_name;
          break;
      }
    };
    return location;
  }

  checkUserLogin = () => {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['sign-in']);
      return;
    } else {
      this.getCustomerAddressList();
      const modalRef = this.modalService.open(AddressPopupComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
      modalRef.componentInstance.addressObj = { fromState: 'new' };

      modalRef.result.then((result: any) => {
        if (result) {
          this.getCustomerAddressList();
        }
      });
    }
  }

  getCustomerAddressList = () => {
    const userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
    if (userDetails) {
      this.dataService.getCustomerAddressList(userDetails?.customer_id).subscribe((res: any) => {
        if (res) {
          this.addressList = res.address;
        }
      });
    }
  }

  getBusinessCategory = () => {
    this.dataService.getBusinessCategory({ keyword: this.keyword }).subscribe((res: any) => {
      if (res) {
        this.catList = res;
        this.cdr.detectChanges();
      }
    });
  }

  // Selected Address
  onSelectAddress(address: any) {
    this.selectedAddress = address;
    console.log(this.selectedAddress)
  }
}
