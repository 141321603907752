<div class="main-wrapper d-none">
    <section class="hero-section">
        <nav>
            <div class="logo">My Logo</div>
            <input type="checkbox" id="click">
            <label for="click" class="menu-btn">
                <i class="fas fa-bars"></i>
            </label>
            <ul>
                <li><a href="#">Find a tutor</a></li>
                <li><a href="#">How it Works</a></li>
                <li><a href="#">Prices</a></li>
                <li><a href="#">Become a tutor</a></li>
                <li><a href="#">Blog</a></li>
            </ul>
            <div class="cart-wrapper">
                <!-- <a href="#" class="cart-sec"><img src="assets/icons/cart-icon.svg" alt=""></a> -->
                <!-- <button class="sign-up" type="button">Signup</button> -->
                <ul>
                    <a href="#" class="login-btn">Login</a>
                    <a href="#" class="sign-up">Signup</a>
                </ul>
            </div>
        </nav>

    </section>
</div>
<app-tutor-header></app-tutor-header>
<div class="container-fluid student-profile-wrapper">

    <div class="row">
        <div class="col-md-6">
            <h1 class="page-title">Your students</h1>
        </div>
        <div class="col-md-6" id="add-cchild-wrapper">
            <div class="add-student-btn text-right">
                <a (click)="openStudentForm = true">Add Student</a>
            </div>
            <p-sidebar [(visible)]="openStudentForm" position="right" [baseZIndex]="10000">


                <div class="row add-panel-close">
                    <div class="col-md-10">
                        <h5 class="add-student-heading">Add your child</h5>
                        <p class="add-student-desc">Create an account for your child to launch sessions, view tutors and
                            send
                            messages. You stay in control of payment and are copied in to all contact.</p>
                    </div>
                    <div class="col-md-2 text-right">
                        <a (click)="openStudentForm = false">
                            <img src="assets/icons/cross.svg" alt="">
                        </a>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="">First name</label>
                            <input type="text" class="form-control" id="" placeholder="Enter first name">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="">Last name</label>
                            <input type="text" class="form-control" id="" placeholder="Enter last name">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="">Email address</label>
                            <input type="email" class="form-control" id="" placeholder="Enter email">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <button class="btn btn-block" id="add-student-button">Add Student</button>
                        </div>
                    </div>
                </div>

                <p class="note-text">Your student will receive an email with these details.</p>

            </p-sidebar>
        </div>
    </div>

    <div class="student-details-wrapper">

        <div class="students-list-wrapper mt-4">
            <div class="students-item-card mt-3" *ngFor="let student of studentDetails; let i = index;">
                <div class="row">
                    <div class="col-md-6">
                        <p class="data-title">First name</p>
                        <p class="data-text">{{student.firstname}}</p>
                    </div>
                    <div class="col-md-6 text-right">
                        <p class="data-title">Last name</p>
                        <p class="data-text">{{student.lastname}}</p>
                    </div>
                    <div class="col-md-6 mt-3">
                        <p class="data-title">Email</p>
                        <p class="data-text">{{student.email}}</p>
                    </div>
                    <div class="col-md-6 mt-3 text-right">
                        <p class="data-title">Status</p>
                        <p class="data-text">{{student.status}}</p>
                    </div>
                    <div class="col-md-12 mt-3">
                        <p class="data-text">
                            <button class="btn btn-block">{{student.buttontext}}</button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>