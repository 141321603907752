import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutor-subject',
  templateUrl: './tutor-subject.component.html',
  styleUrls: ['./tutor-subject.component.scss']
})
export class TutorSubjectComponent implements OnInit {
  showTextInput: boolean = false;
  displayBasic: boolean | undefined;
  constructor() { }

  ngOnInit(): void {
  }
  qualifications : any = [
    {name: 'Biology'},
    {name: 'Science'},
    {name:'Music'},
    {name:'Urdu'}
  ]
  grade:any=[
    {name:'A+'},
    {name:'A'},
    {name:'B+'},
    {name:'B'},
    {name:'C+'},
    {name:'C'}
  ]
  showBasicDialog() {
    this.displayBasic = true;
}

}
