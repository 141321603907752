import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parent-profile',
  templateUrl: './parent-profile.component.html',
  styleUrls: ['./parent-profile.component.scss']
})
export class ParentProfileComponent implements OnInit {

  defaultCountry = 'India';
  openAddressForm: any;

  constructor() { }

  ngOnInit(): void {
  }

  countries = [
    { name: 'India' },
    { name: 'UK' },
    { name: 'USA' },
    { name: 'UAE' },
    { name: 'South Africa' }
  ]

}

