<div class="model-custom location-modal" id="locationModal-home">
  <div class="modal-header">
    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close('ok')">
      <i class="bi bi-x"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="map">
      <div class="search-map-container in-line">
        <i class="bi bi-search"></i>
        <input #mapSearchFiled1 class="search-map" type="text" />
      </div>
      <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
        <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true" (dragEnd)="markerDragEnd($event)">
        </agm-marker>
      </agm-map>
    </div>
    <div class="content-right pt-0">
      <div class="current-location"><i class="bi bi-geo-alt-fill"></i>{{userLocation}}</div>
      <div class="add-more-details">
        <label for="">ADD MORE DETAILS</label><br>
        <input type="text" placeholder="Door / Flat No." [(ngModel)]="address1">
        <input type="text" placeholder="Landmark" [(ngModel)]="landmark">
        <div class="address-type-list">
          <div *ngFor="let address of addressLabelsList" class="type c-p mt-1" (click)="selectAddressLabel(address)"
            [class.selected]="getStatus(address)">
            <i class="bi {{address.icon}}"></i>
            {{address.name}}
          </div>
        </div>
        <input type="text" placeholder="Dad’s home, my man cave" [(ngModel)]="otherAddress"
          *ngIf="addressLabel === 'Other'" maxlength="20" />
        <button class="save btn btn-block mt-2" id="address-save-btn" (click)="saveAddress()">SAVE ADDRESS &
          PROCEED</button>
        <button class="save btn btn-block mt-2" id="view-saved-btn" (click)="gotoManageAddresses()">VIEW SAVED
          ADDRESS</button>
      </div>
    </div>
  </div>
</div>