<app-tutor-header></app-tutor-header>
<div class="container subject-wrap">
    <h3>Add Subject</h3>
    <div class="form">
        <div><label class="label-text">Choose Plan</label></div>
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="row">
                    <div class="col-md-6 col-6">
                  
                          <label  class="label-text">
                            <input type="radio" name="free" value="free-demo"> Free
                          </label>
                    </div>
                    <div class="col-md-6 col-6">
                     
                        
                          
                          <label  class="label-text">
                            <input type="radio" name="free" value="free-demo"> Paid
                          </label>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="row">
                    <div class="col-md-7 col-6">
                        <label  class="label-text">
                            <input type="radio" name="session" value="One-of-one"> One-Of-session
                          </label>
                     
                    </div>
                    <div class="col-md-5 col-6">
                        <label  class="label-text">
                            <input type="radio" name="session" value="One-of-one" checked> Group Session
                          </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div>
                <label for="#" class="label-text">Course Title</label>
                <input type="text" class="form-control" placeholder="course Title">
            </div>
            <div>
                <label for="#" class="label-text">Course Category</label>
                <!-- <input type="text" class="form-control"> -->
                <ng-select [items]="category" bindLabel="name" [multiple]=true
                    [placeholder]="'Select one or more Category'" class="my-select"></ng-select>
            </div>
            <div>
                <label for="#" class="label-text">Topics</label>
                <ng-select [items]="topics" bindLabel="name" [multiple]=true [placeholder]="'Select one or more Topics'"
                    class="my-select"></ng-select>
            </div>

        </div>
        <div class="col-md-6">
            <div>
                <label for="#" class="label-text">Price($)</label>
                <input type="number" id="quantity" name="quantity" placeholder="$20" class="form-control" min="0"
                    max="20">
            </div>
            <div>
                <label for="#" class="label-text">Subjects </label>
                <ng-select [items]="subjects" bindLabel="name" [multiple]="true" [(ngModel)]="selectedSubjects"
                    [placeholder]="'Select one or more subjects'" class="my-select"></ng-select>
            </div>
            <div>
                <label for="#" class="label-text">Course Grade</label>
                <ng-select [items]="grades" bindLabel="name" [multiple]=false class="my-select"
                    [placeholder]="'Select grade'"> </ng-select>
            </div>
        </div>
    </div>




    <div class="row">
        <div class="col-md-6">
            <label for="#" class="label-text">Course Image</label>
            <div class="form-group">
            
                <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" [expandable]="true"
                    (change)="onFileChange($event)">
                    <ngx-dropzone-label>
                        <div>
                            <img src="assets/icons/upload.svg" alt="">
                            <h6 class="mt-3
                                mb-2">Drop your image here.</h6>
                    
                        </div>
                    </ngx-dropzone-label>
                    <ngx-dropzone-image-preview #nationalDropZone ngProjectAs="ngx-dropzone-preview" *ngFor="let f of
                        fileList" [file]="f" [removable]="true" (removed)="onRemove(f)">
                    </ngx-dropzone-image-preview>
                </div>
              
            </div>
        </div>
        <div class="col-md-6">
            <label for="#" class="label-text">Course Demo url</label>

            <input type="text" class="form-control" placeholder="Enter vedio Url">

        </div>
    </div>
    <div class="row subject-quill">
        <div class="col-md-12">
            <label class="label-text">Description</label>
            <p-editor [(ngModel)]="text1" styleClass="my-editor" placeholder="Type your content here"></p-editor>
        </div>

    </div>
    <button class="add-btn" type="butt
"> Add Course</button>


</div>