<div class="parent-signup-wrapper">
    <h1>What did you study?</h1>
    <div class="tutor-signup container">

        <div class="input-block">
            <div class="label-text">Qualification</div>
            <ng-select [items]="qualifications" bindLabel="name" [multiple]="false"
                [placeholder]="'please select qualification'" class="my-select">

            </ng-select>
        </div>
        <div class="input-block">
            <div class="label-text">Course title</div>
            <input type="text" placeholder="enter the course" class="input-form" formControlName="name">
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-block">
                    <div class="label-text">Start Year</div>
                    <p-calendar [(ngModel)]="date10" view="year" dateFormat="yy" inputId="yearpicker"></p-calendar>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-block">
                <div class="label-text">End Year</div>
                <p-calendar [(ngModel)]="date11" view="year" dateFormat="yy" inputId="yearpicker"></p-calendar>
            </div>
            </div>
        </div>
        <a (click)="showTextInput = !showTextInput" >Add Education</a>


        <div class="input-block" *ngIf="showTextInput">
            <div class="label-text">Qualification</div>
            <ng-select [items]="qualifications" bindLabel="name" [multiple]="false"
                [placeholder]="'please select qualification'" class="my-select">

            </ng-select>
        </div>
        <div class="input-block" *ngIf="showTextInput">
            <div class="label-text">Course title</div>
            <input type="text" placeholder="enter the course" class="input-form" formControlName="name">
        </div>
        <div class="row" *ngIf="showTextInput">
            <div class="col-md-6">
                <div class="input-block">
                    <div class="label-text">Start Year</div>
                    <p-calendar [(ngModel)]="date12" view="year" dateFormat="yy" inputId="yearpicker"></p-calendar>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-block">
                <div class="label-text">End Year</div>
                <p-calendar [(ngModel)]="date13" view="year" dateFormat="yy" inputId="yearpicker"></p-calendar>
            </div>
        </div>
        </div>
        <div class="row button-wrapper mt-4">
            <div class="col-md-6">
                <button class="btn-back" type="button" [routerLink]="['/tutor/application/process']">Back </button>
            </div>
            <div class="col-md-6">
                <button class="btn-next" type="button" [routerLink]="['/tutor/school/qualification']">Next </button>
            </div>
   
        </div>
        <!-- <div class="row mt-5">
            <div class="col-md-6">
<button class="btn" type="submit">Back</button>
            </div>
            <div class="col-md-6">
                <button class="btn" type="submit">Next</button>
            </div>
        </div> -->
    </div>
</div>
<!--------------------->
