<div class=" container choose-level">
    <h3> &nbsp; <img src="assets/icons/arrow-left-t.svg" alt="" [routerLink]="['/choose/subject']">
        Which <span class="s-wrap">level</span> are you searching for?"</h3>
    <div class="select-box">


        <div class=" box" [ngClass]="{'selected': selectedBox === 'box1', 'not-selected': selectedBox !== 'box1'}"
          (click)="selectBox('box1')" (click)="hideInput()">A Level</div>
    
    
        <div class="box" [ngClass]="{'selected': selectedBox === 'box2', 'not-selected': selectedBox !== 'box2'}"
          (click)="selectBox('box2')" (click)="hideInput()">GCSE</div>
    
        <div class="box" [ngClass]="{'selected': selectedBox === 'box3', 'not-selected': selectedBox !== 'box3'}"
          (click)="selectBox('box3')" (click)="hideInput()">University</div>
  
</div>
<div class="row">
    <div class="col-md-6 skip-wrap">
      <a href="#">Skip</a>
    </div>
  <div class="col-md-6">

      <button class="next-btn" type="button"[routerLink]="['/student/signup/success']">Submit</button>
    </div>
    
  </div>
</div>
