import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-choose-subject',
  templateUrl: './choose-subject.component.html',
  styleUrls: ['./choose-subject.component.scss']
})
export class ChooseSubjectComponent implements OnInit {
  items: string[] = ['Option 1', 'Option 2', 'Option 3'];
  searchText: string = '';
  selectedBoxes: number[] = [];
  faCheck:any;
  searchForm: any;
  suggestedNames: any;
  searchService: any;
  searchTerm: string = '';
  suggestions: string[] = [];
  showSuggestions: boolean = false;
  constructor() {
    // const searchForm = new FormGroup({
    //   searchInput: new FormControl('', Validators.required)
    // }); 
   }
   isSelected(index: number): boolean {
    return this.selectedBoxes.includes(index);
  }
  boxes = [
    { id: 1, label: 'Maths', selected: false, icon: null },
    { id: 2, label: 'Physics', selected: false, icon: null },
    { id: 3, label: 'Biology', selected: false, icon: null },
    { id: 4, label: 'English', selected: false, icon: null },
    { id: 5, label: 'Geography', selected: false, icon: null },
    { id: 6, label: 'Geology', selected: false, icon: null },
    { id: 7, label: 'Philosophy', selected: false, icon: null },
    { id: 8, label: 'Sanskrit', selected: false, icon: null },
  ]

  ngOnInit(): void {
  }
  customSearchFn(term: string, item: string) {
    return item.toLowerCase().includes(term.toLowerCase());
  }
  toggleBoxSelection(box: { selected: boolean }) {
    box.selected = !box.selected;
  }
  toggleSelection(index: number): void {
    if (this.isSelected(index)) {
      this.selectedBoxes = this.selectedBoxes.filter(i => i !== index);
    } else {
      this.selectedBoxes.push(index);
    }
  }
  
  // suggestions: string[] = ['apple', 'banana', 'cherry', 'date', 'elderberry'];

  // get searchInput() {
  //   return this.searchForm.get('searchInput');
  // }

  // get filteredSuggestions(): string[] {
  //   return this.suggestions.filter(suggestion => suggestion.toLowerCase().includes(this.searchInput.value.toLowerCase()));
  // }
  // search() {
  //   this.searchService.getSuggestedNames(this.searchText).subscribe((names: any) => {
  //     this.suggestedNames = names;
  //   });
  // }
  onInputChange() {
    // Call API to get suggestions based on searchTerm
    // Set suggestions array based on API response
    this.suggestions = ['Java', 'Urdu', 'Farsi'];
    this.showSuggestions = true;
  }

  onSuggestionClick(suggestion: string) {
    this.searchTerm = suggestion;
    this.showSuggestions = false;
  }
  onSubmit() {
    // Handle form submission
  }
  subjects:any=[
    {name:'Java'},
    {name:'Astrology'},
    {name:'franch'}
  ]
}


