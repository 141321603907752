import { NgModule } from '@angular/core';

import { RouterModule, ROUTER_CONFIGURATION, Routes } from '@angular/router';
import { HomePageComponent } from "./pages/home-page/home-page.component";
import { RouteConstants } from "./model/route-constants";
import { RatingsAndReviewsComponent } from "./pages/ratings-and-reviews/ratings-and-reviews.component";
import { RegisterYourBusinessComponent } from "./pages/register-your-business/register-your-business.component";
import { ProviderDetailsComponent } from "./pages/provider-details/provider-details.component";
import { ProvidersListComponent } from "./pages/providers-list/providers-list.component";
import { TopServicesComponent } from "./pages/top-services/top-services.component";
import { AboutServiceComponent } from "./shared/about-service/about-service.component";
import { ServiceRatingsComponent } from "./pages/service-ratings/service-ratings.component";
import { RegisterFreeTrailComponent } from "./pages/register-free-trail/register-free-trail.component";
import { MyBookingsComponent } from "./pages/my-bookings/my-bookings.component";
import { CartComponent } from "./pages/cart/cart.component";
import { CancelBookingComponent } from "./pages/cancel-booking/cancel-booking.component";
import { AvailabilityComponent } from "./pages/availability/availability.component";
import { PaymentComponent } from "./pages/payment/payment.component";
import { CancelledComponent } from "./pages/cancelled/cancelled.component";
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ServiceDetailsComponent } from './pages/service-details/service-details.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { WishlistComponent } from './pages/wishlist/wishlist.component';
import { WalletComponent } from './pages/wallet/wallet.component';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { RequestQuoteComponent } from './pages/request-quote/request-quote.component';
import { AboutusComponent } from './pages/footer/aboutus/aboutus.component';
import { CareersComponent } from './pages/footer/careers/careers.component';
import { ContactUsComponent } from './pages/footer/contact-us/contact-us.component';
import { TermsAndConditionsComponent } from './pages/footer/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './pages/footer/privacy-policy/privacy-policy.component';
import { PaymentFailComponent } from './pages/payment-fail/payment-fail.component';
import { MyTransactionsComponent } from './pages/my-transactions/my-transactions.component';
import { PlansComponent } from './components/plans/plans.component';
import { SportsBasedThemComponent } from './components/sports-based-them/sports-based-them.component';
import { BeautyBasedThemeComponent } from './components/beauty-based-theme/beauty-based-theme.component';
import { BookingThemeComponent } from './components/booking-theme/booking-theme.component';
import { OnlineTutorComponent } from './components/online-tutor/online-tutor.component';
import { ViewTutorsComponent } from './components/view-tutors/view-tutors.component';
import { TutorProfileComponent } from './components/tutor-profile/tutor-profile.component';
import { TutorSignupComponent } from './components/tutor-signup/tutor-signup.component';
import { StudentProfileComponent } from './components/student-profile/student-profile.component';
import { StudentMessagesComponent } from './components/student-messages/student-messages.component';
import { TutorLoginComponent } from './components/tutor-login/tutor-login.component';
import { TutorLoginPageComponent } from './components/tutor-login-page/tutor-login-page.component';
import { StudentBookingsComponent } from './components/student-bookings/student-bookings.component';
import { BookLessonsComponent } from './components/book-lessons/book-lessons.component';
import { MytutorSignupComponent } from './components/mytutor-signup/mytutor-signup.component';
import { ParentsSignupComponent } from './components/parents-signup/parents-signup.component';
import { StudentSignupComponent } from './components/student-signup/student-signup.component';
import { TutorSignupFormComponent } from './components/tutor-signup-form/tutor-signup-form.component';
import { TutorQualificationComponent } from './components/tutor-qualification/tutor-qualification.component';
import { SchoolQualificationComponent } from './components/school-qualification/school-qualification.component';
import { TutorSubjectComponent } from './components/tutor-subject/tutor-subject.component';
import { TutorAddQualificationComponent } from './components/tutor-add-qualification/tutor-add-qualification.component';
import { TutorSubjectLevelComponent } from './components/tutor-subject-level/tutor-subject-level.component';
import { TutorGraducationCertificateComponent } from './components/tutor-graducation-certificate/tutor-graducation-certificate.component';
import { MyTutorLoginPageComponent } from './components/my-tutor-login-page/my-tutor-login-page.component';
import { MyTutorPersonalDetailsComponent } from './components/my-tutor-personal-details/my-tutor-personal-details.component';
import { TutorEnoughDetailsComponent } from './components/tutor-enough-details/tutor-enough-details.component';
import { RecivedApplicationComponent } from './components/recived-application/recived-application.component';
import { TutorApplicationProcessComponent } from './components/tutor-application-process/tutor-application-process.component';
import { TutorAppointmentComponent } from './components/tutor-appointment/tutor-appointment.component';
import { ParentProfileComponent } from './components/parent-profile/parent-profile.component';
import { StudentsListComponent } from './components/students-list/students-list.component';
import { ContactTutorComponent } from './components/contact-tutor/contact-tutor.component';
import { TutorHeaderComponent } from './components/tutor-header/tutor-header.component';
import { ParentHomeComponent } from './components/parent-home/parent-home.component';
import { StudentHomeComponent } from './components/student-home/student-home.component';
import { TutorBookingsComponent } from './components/tutor-bookings/tutor-bookings.component';
import { StudentCheckoutComponent } from './components/student-checkout/student-checkout.component';
import { TutorAddSubjectComponent } from './components/tutor-add-subject/tutor-add-subject.component';
import { TutorDashboardComponent } from './components/tutor-dashboard/tutor-dashboard.component';
import { TutorFormSetpsComponent } from './components/tutor-form-setps/tutor-form-setps.component';

import { PersonalDetailsComponent } from './components/personal-details/personal-details.component';
import { StudentPersonalDetailsComponent } from './components/student-personal-details/student-personal-details.component';
import { StudentSignupSubmitComponent } from './components/student-signup-submit/student-signup-submit.component';
import { StudentChangePasswordComponent } from './components/student-change-password/student-change-password.component';
import { ChooseSubjectComponent } from './components/choose-subject/choose-subject.component';
import { ChooseLevelComponent } from './components/choose-level/choose-level.component';
import { StudentAgeComponent } from './components/student-age/student-age.component';
import { PrimaryStudentSignupComponent } from './components/primary-student-signup/primary-student-signup.component';
import { ServicesComponent } from './shared/services/services.component';
import { ProviderPageComponent } from './pages/provider-page/provider-page.component';
import { environment } from 'src/environments/environment';
import { ServiceInfoComponent } from './pages/service-info/service-info.component';

const routes: Routes = [

  {
    pathMatch: 'full',
    path: RouteConstants.HOME_PAGE,
    component:  (window.location.hostname.toLowerCase()==environment.cookieDomain.toLowerCase()) ? HomePageComponent:ProviderPageComponent,
    // component:  HomePageComponent,
    data: {
      title: 'Home',
      breadcrumb: [
        {
          label: 'Home',
          url: ''
        }
      ]
    },

  },
  {
    path: `${RouteConstants.PROVIDERS_LIST}/:category`,
    component: ProvidersListComponent,
    data: {
      title: 'Providers',
      breadcrumb: [
        {
          label: '{{category}}',
          url: `/${RouteConstants.PROVIDERS_LIST}/:category`
        }
      ]
    },
  },
  {
    path: RouteConstants.ALL_SERVICES,
    component: ServicesComponent
  },
  {
    path: RouteConstants.RATINGS_AND_REVIEWS,
    component: RatingsAndReviewsComponent
  },
  {
    path: RouteConstants.REGISTER_YOUR_BUSINESS,
    component: RegisterYourBusinessComponent,
  },
  {
    path: RouteConstants.PROVIDER_DETAILS,
    component: ProviderDetailsComponent,

  },
  {
    path: RouteConstants.TOP_SERVICES,
    component: TopServicesComponent
  },

  {
    path: RouteConstants.SERVICE_RATING,
    component: ServiceRatingsComponent
  },
  {
    path: RouteConstants.FREE_TRAIL,
    component: RegisterFreeTrailComponent
  },
  {
    path: RouteConstants.MY_BOOKINGS,
    component: MyBookingsComponent
  },
  {
    path: RouteConstants.CART,
    component: CartComponent
  },
  {
    path: RouteConstants.PARENTHOME,
    component: ParentHomeComponent
  },
  {
    path: RouteConstants.STUDENTHOME,
    component: StudentHomeComponent
  },
  {
    path: RouteConstants.CANCEL_BOOKINGS,
    component: CancelBookingComponent
  },
  {
    path: RouteConstants.AVAILABILITY,
    component: AvailabilityComponent
  },
  {
    path: RouteConstants.PAYMENT,
    component: PaymentComponent
  },
  {
    path: RouteConstants.CANCELLED,
    component: CancelledComponent
  },

  {
    path: RouteConstants.SIGNIN,
    component: SignInComponent
  },

  {
    path: RouteConstants.SIGNUP,
    component: SignUpComponent
  },
  {
    path: RouteConstants.CONTACTTUTOR,
    component: ContactTutorComponent
  },

  {
    path: RouteConstants.FORGOTPIN,
    component: ForgotPasswordComponent
  },
  {
    path: RouteConstants.SERVICE_DETAILS + "/:categoryId/:serviceTitle" ,
    component: ServiceDetailsComponent
  },
  {
    path: RouteConstants.PROFILE,
    component: ProfileComponent
  }, {
    path: RouteConstants.CHANGEPASSWORD,
    component: ChangePasswordComponent
  }, {
    path: RouteConstants.WISHLIST,
    component: WishlistComponent
  },
  {
    path: RouteConstants.WALLET,
    component: WalletComponent
  },
  {
    path: RouteConstants.MYTRANSACTIONS,
    component: MyTransactionsComponent
  },
  {
    path: RouteConstants.PAYMENT_SUCCESS,
    component: PaymentSuccessComponent
  },
  {
    path: RouteConstants.REQUEST_QUOTE,
    component: RequestQuoteComponent
  },
  {
    path: RouteConstants.ABOUTUS,
    component: (window.location.hostname.toLowerCase()==environment.cookieDomain.toLowerCase()) ? AboutServiceComponent : AboutusComponent
  },
  {
    path: RouteConstants.CAREERS,
    component: CareersComponent
  },
  {
    path: RouteConstants.CONTACTUS,
    component: ContactUsComponent
  },
  {
    path: RouteConstants.BEAUTYTHEME,
    component: BeautyBasedThemeComponent
  },
  {
    path: RouteConstants.TUTORPROFILE,
    component: TutorProfileComponent
  },
  {
    path: RouteConstants.TUTORSIGNUP,
    component: TutorSignupComponent
  },
  {
    path: RouteConstants.BOOKINGTHEME,
    component: BookingThemeComponent
  },
  {
    path: RouteConstants.STUDENTPROFILE,
    component: StudentProfileComponent
  },
  {
    path: RouteConstants.STUDENTBOOKINGS,
    component: StudentBookingsComponent
  },
  {
    path: RouteConstants.TUTORBOOKINGS,
    component: TutorBookingsComponent
  },
  {
    path: RouteConstants.BOOKLESSONS,
    component: BookLessonsComponent
  },
  {
    path: RouteConstants.STUDENTMESSGAES,
    component: StudentMessagesComponent
  },
  {
    path: RouteConstants.TERMSANDCONDITIONS,
    component: TermsAndConditionsComponent
  },
  {
    path: RouteConstants.PRIVACYPOLICY,
    component: PrivacyPolicyComponent
  },
  {
    path: RouteConstants.PAYMENT_FAIL,
    component: PaymentFailComponent
  },
  {
    path: RouteConstants.PLANS,
    component: PlansComponent
  },
  {
    path: RouteConstants.SPORTSBASEDTHEME,
    component: SportsBasedThemComponent
  },
  {
    path: RouteConstants.ONLINETUTOR,
    component: OnlineTutorComponent
  },
  {
    path: RouteConstants.VIEWTUTOR,
    component: ViewTutorsComponent
  },
  {
    path: RouteConstants.TUTORLOGIN,
    component: TutorLoginComponent
  },
  {
    path: RouteConstants.TUTORLOGINPAGE,
    component: TutorLoginPageComponent
  },
  {
    path: RouteConstants.MYTUTORSOGNUP,
    component: MytutorSignupComponent
  },
  {
    path: RouteConstants.PARENTSSIGNUP,
    component: ParentsSignupComponent
  },
  {
    path: RouteConstants.STUDENTSIGNUP,
    component: StudentSignupComponent
  },
  {

    path: RouteConstants.MYTUTORSIGNUPFORM,
    component: TutorSignupFormComponent
  },
  {
    path: RouteConstants.TUTORQUALIFICATION,
    component: TutorQualificationComponent
  },
  {
    path: RouteConstants.PARENTPROFILE,
    component: ParentProfileComponent
  },
  {
    path: RouteConstants.TUTOR_S_QUALIFICATION,
    component: SchoolQualificationComponent
  },
  {
    path: RouteConstants.STUDENTSLIST,
    component: StudentsListComponent
  },
  {
    path: RouteConstants.TUTORSUBJECT,
    component: TutorSubjectComponent
  },
  {
    path: RouteConstants.TUTORADD_QUALIFICATION,
    component: TutorAddQualificationComponent
  },
  {
    path: RouteConstants.TUTOR_SUBJECT_LEVEL,
    component: TutorSubjectLevelComponent
  },
  {
    path: RouteConstants.TUTOR_GRADUATION_CERTIFICATE,
    component: TutorGraducationCertificateComponent
  },
  {
    path: RouteConstants.MYTUTOR_LOGINPAGE,
    component: MyTutorLoginPageComponent
  },
  {
    path: RouteConstants.MYTUTOR_PERSONAL_DETAILS,
    component: MyTutorPersonalDetailsComponent
  },
  {
    path: RouteConstants.TUTOR_ENOUGH_DETAILS,
    component: TutorEnoughDetailsComponent
  },
  {
    path: RouteConstants.TUTOR_APPLICTION,
    component: RecivedApplicationComponent
  },
  {
    path: RouteConstants.TUTOR_APPLICATION_PROCESS,
    component: TutorApplicationProcessComponent
  },
  {
path:RouteConstants.TUTOR_APPOINMENT,
component:TutorAppointmentComponent
},
{
path:RouteConstants.TUTORHEADER,
component:TutorHeaderComponent
},
{
  path: RouteConstants.WINDOWHOME,
  component: ProviderPageComponent
},
{
path:RouteConstants.STUDENTCHECKOUT,
component:StudentCheckoutComponent
},
{
path:RouteConstants.TUTORADDSUBJECT,
component:TutorAddSubjectComponent
},
{
path:RouteConstants.TUTORDASHBOARD,
component:TutorDashboardComponent
},
{
path:RouteConstants.TUTORFORMSTEPS,
component:TutorFormSetpsComponent
},


  {
    path: RouteConstants.PERSONALDETAILS,
    component: PersonalDetailsComponent
  },
  {
    path: RouteConstants.STUDENTPERSONALDETAILS,
    component: StudentPersonalDetailsComponent
  },
  {
    path: RouteConstants.STUDENTSIGNUPStatus,
    component: StudentSignupSubmitComponent
  }
  ,
  {
    path: RouteConstants.STUDENTCHANGEPASSWORD,
    component: StudentChangePasswordComponent
  },
  {
    path: RouteConstants.CHOOSESUBJECT,
    component: ChooseSubjectComponent
  },
  {
    path: RouteConstants.CHOOSELEVEL,
    component: ChooseLevelComponent
  },
  {
    path: RouteConstants.STUDENTAGE,
    component: StudentAgeComponent
  },
  {
    path: RouteConstants.PRIMARYSTUDENTSIGNUP,
    component: PrimaryStudentSignupComponent

  },
  {
    path: RouteConstants.SERVICE_INFO + "/:id" ,
    component: ServiceInfoComponent
  },
  {
    path: "**",
    redirectTo: RouteConstants.HOME_PAGE
  },
 


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
