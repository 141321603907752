<app-tutor-header></app-tutor-header>


<div class="container">
    <video controls [src]="videoUrl"></video>

</div>
<div class="tutor-profile container">
    <div class="wrap">
        <img src="assets/images/tutor-p.jpg" alt="" class="img-tutor">
    </div>
    <div class="profile-wrap">
        <h5 class="name-m">John.J</h5>
        <ngx-star-rating class="rating-x" formControlName="rating" [id]="'rating'"></ngx-star-rating><br>
        <div class="s-wrap">
            United Kingdom of Great Britain and Northern Ireland
        </div>
        <p>British English</p>
        <div>
            <!-- <i class="fi fi-rs-heart"></i> -->
            <i class="fa fa-heart fav-wra" (click)="toggleFavorite()" [ngClass]="{'favorited': isFavorited}"></i>
        </div>

    </div>
    <div class="about-w">
        <p class="a-one">About Me</p>
        <!-- <p class="t-tutor">Hello and welcome to Verbling! My name is Tom and I'm a CELTA-qualified English teacher from London. I teach modern British English and British culture!</p> -->
        <p [innerHTML]="displayedText" class="text-wr"></p>
        <a (click)="toggleReadMore()">{{ showMore ? 'Read less' : 'Read more' }}</a>
    </div>
</div>

<div class="container check-wrap d-none">
    <!-- <input type="checkbox" [(ngModel)]="checkbox1Selected " (ngModelChange)="updateSelection(1)"  [(ngModelshow)]="showButton" (change)="showButton = !showButton"> -->
    <input type="checkbox" [(ngModel)]="checkbox1Selected" (ngModelChange)="updateSelection(1)">

    <label>Paid Class</label>&nbsp; &nbsp;

    <input type="checkbox" [(ngModel)]="checkbox2Selected" (ngModelChange)="updateSelection(2)">
    <label>Free class</label>
</div>
<div class="container">
    <h3>Group Classes</h3>
    <div class="c-group ">
        <div class="g-one">
            <img src="assets/images/v-one.png" alt="" class="v-one">
        <div class="footer-session">
            <div class="img-w">
        
                <div class="img">
                    <img src="assets/images/tutor-p.jpg" alt="" class="img-tutor">
                </div>
                <div class="name">
                    <p class="t-name">Tom Tokes</p>
                </div>
        
                <div id="star-rating-container">
                    <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                        <i *ngIf="star <= rating" class="fas fa-star"></i>
                        <i *ngIf="star > rating" class="far fa-star"></i>
                    </ng-container>
        
        
        
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-6">
                    <img src="assets/icons/calendar-t.svg" alt=""><span class="c-time">feb 03,2023</span>
                            </div>
                            <div class="col-md-6 time-c">
                                <img src="assets/icons/clock-t.svg" alt="" ><span class="c-time">14:30</span>
                            </div>
            </div>
            <div class="t-text">Ultimate Adobe Photoshop Training: From Beginner to Pro Mast...</div>
            <div class="n-page"> MASSIVE GIVEAWAY: OVER 300 PREMIUM PSD FILES FOR... <span class="r-more">Read more...</span>  </div>
        </div>
        <div class="row s-session">
            <div class="col-md-6">
                <button class="btn-book">Book Now</button>
            </div>
            <div class="col-md-6">
              <P class="d-price">  $20</P>
            </div>
        </div>
        </div>
<!----------------->
<div class="g-one">
    <img src="assets/images/v-two.jpg" alt="" class="v-one">
<div class="footer-session">
    <div class="img-w">

        <div class="img">
            <img src="assets/images/tutor-p.jpg" alt="" class="img-tutor">
        </div>
        <div class="name">
            <p class="t-name">Tom Tokes</p>
        </div>

        <div id="star-rating-container">
            <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                <i *ngIf="star <= rating" class="fas fa-star"></i>
                <i *ngIf="star > rating" class="far fa-star"></i>
            </ng-container>



        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <img src="assets/icons/calendar-t.svg" alt=""><span class="c-time">feb 03,2023</span>
                    </div>
                    <div class="col-md-6 time-c">
                        <img src="assets/icons/clock-t.svg" alt="" ><span class="c-time">14:30</span>
                    </div>
    </div>
    <div class="t-text">Ultimate Adobe Photoshop Training: From Beginner to Pro Mast...</div>
    <div class="n-page"> MASSIVE GIVEAWAY: OVER 300 PREMIUM PSD FILES FOR... <span class="r-more">Read more...</span>  </div>
</div>
<div class="row s-session">
    <div class="col-md-6">
        <button class="btn-book">Book Now</button>
    </div>
    <div class="col-md-6">
      <P class="d-price">  $20</P>
    </div>
</div>
</div>
<!------------>
<div class="g-one">
    <img src="assets/images/v-one.png" alt="" class="v-one">
<div class="footer-session">
    <div class="img-w">

        <div class="img">
            <img src="assets/images/tutor-p.jpg" alt="" class="img-tutor">
        </div>
        <div class="name">
            <p class="t-name">Tom Tokes</p>
        </div>

        <div id="star-rating-container">
            <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                <i *ngIf="star <= rating" class="fas fa-star"></i>
                <i *ngIf="star > rating" class="far fa-star"></i>
            </ng-container>



        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <img src="assets/icons/calendar-t.svg" alt=""><span class="c-time">feb 03,2023</span>
                    </div>
                    <div class="col-md-6 time-c">
                        <img src="assets/icons/clock-t.svg" alt="" ><span class="c-time">14:30</span>
                    </div>
    </div>
    <div class="t-text">Ultimate Adobe Photoshop Training: From Beginner to Pro Mast...</div>
    <div class="n-page"> MASSIVE GIVEAWAY: OVER 300 PREMIUM PSD FILES FOR... <span class="r-more">Read more...</span>  </div>
</div>
<div class="row s-session">
    <div class="col-md-6">
        <button class="btn-book">Book Now</button>
    </div>
    <div class="col-md-6">
      <P class="d-price">  $20</P>
    </div>
</div>
</div>
    </div>
    <h3>Tutor Courses</h3>
    <div class="c-group ">
        <div class="g-one">
            <img src="assets/images/c-img.png" alt="" class="v-one">
        <div class="footer-session">
            <div class="img-w">
        
                <div class="img">
                    <img src="assets/images/tutor-p.jpg" alt="" class="img-tutor">
                </div>
                <div class="name">
                    <p class="t-name">Tom Tokes</p>
                </div>
        
                <div id="star-rating-container">
                    <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                        <i *ngIf="star <= rating" class="fas fa-star"></i>
                        <i *ngIf="star > rating" class="far fa-star"></i>
                    </ng-container>
        
        
        
                </div>
            </div>
            <div class="row d-none">
                <div class="col-md-6">
                    <img src="assets/icons/calendar-t.svg" alt=""><span class="c-time">feb 03,2023</span>
                            </div>
                            <div class="col-md-6 time-c">
                                <img src="assets/icons/clock-t.svg" alt="" ><span class="c-time">14:30</span>
                            </div>
            </div>
            <div class="mt-3 ">
                <button class="btn-it" type="button">IT Software </button></div>
                <div class="t-text mt-5">
           Ultimate Adobe Photoshop Training: From Beginner to Pro Mast...</div>
            <div class="n-page"> MASSIVE GIVEAWAY: OVER 300 PREMIUM PSD FILES FOR... <span class="r-more">Read more...</span>  </div>
        </div>
        <div class="row s-session">
            <div class="col-md-6">
                <button class="btn-book">Book Now</button>
            </div>
            <div class="col-md-6">
              <P class="d-price">  $20</P>
            </div>
        </div>
        </div>
<!----------------->
<div class="g-one">
    <img src="assets/images/c-img.png" alt="" class="v-one">
<div class="footer-session">
    <div class="img-w">

        <div class="img">
            <img src="assets/images/tutor-p.jpg" alt="" class="img-tutor">
        </div>
        <div class="name">
            <p class="t-name">Tom Tokes</p>
        </div>

        <div id="star-rating-container">
            <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                <i *ngIf="star <= rating" class="fas fa-star"></i>
                <i *ngIf="star > rating" class="far fa-star"></i>
            </ng-container>



        </div>
    </div>
    <div class="row d-none">
        <div class="col-md-6">
            <img src="assets/icons/calendar-t.svg" alt=""><span class="c-time">feb 03,2023</span>
                    </div>
                    <div class="col-md-6 time-c">
                        <img src="assets/icons/clock-t.svg" alt="" ><span class="c-time">14:30</span>
                    </div>
    </div>
    <div class="mt-3 ">
        <button class="btn-it" type="button">Teaching</button></div>
        <div class="t-text mt-5">
   Ultimate Adobe Photoshop Training: From Beginner to Pro Mast...</div>
    <div class="n-page"> MASSIVE GIVEAWAY: OVER 300 PREMIUM PSD FILES FOR... <span class="r-more">Read more...</span>  </div>
</div>
<div class="row s-session">
    <div class="col-md-6">
        <button class="btn-book">Book Now</button>
    </div>
    <div class="col-md-6">
      <P class="d-price">  $20</P>
    </div>
</div>
</div>
<!------------>
<div class="g-one">
    <img src="assets/images/c-img.png" alt="" class="v-one">
<div class="footer-session">
    <div class="img-w">

        <div class="img">
            <img src="assets/images/tutor-p.jpg" alt="" class="img-tutor">
        </div>
        <div class="name">
            <p class="t-name">Tom Tokes</p>
        </div>

        <div id="star-rating-container">
            <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                <i *ngIf="star <= rating" class="fas fa-star"></i>
                <i *ngIf="star > rating" class="far fa-star"></i>
            </ng-container>



        </div>
    </div>
    <div class="row d-none">
        <div class="col-md-6">
            <img src="assets/icons/calendar-t.svg" alt=""><span class="c-time">feb 03,2023</span>
                    </div>
                    <div class="col-md-6 time-c">
                        <img src="assets/icons/clock-t.svg" alt="" ><span class="c-time">14:30</span>
                    </div>
    </div>
    <div class="mt-3 ">
        <button class="btn-it" type="button">Teaching</button></div>
        <div class="t-text mt-5">
   Ultimate Adobe Photoshop Training: From Beginner to Pro Mast...</div>
    <div class="n-page"> MASSIVE GIVEAWAY: OVER 300 PREMIUM PSD FILES FOR... <span class="r-more">Read more...</span>  </div>
</div>
<div class="row s-session">
    <div class="col-md-6">
        <button class="btn-book">Book Now</button>
    </div>
    <div class="col-md-6">
      <P class="d-price">  $20</P>
    </div>
</div>
</div>
    </div>
</div>