


<div class="top-bookings">
  <div class="container">
    <div class="limited-border">
      <div class="header-titles">
        <div class="title-main">Top Bookings Since Last Month</div>
        <div class="sub-title">Terms & Conditions Apply*</div>
      </div>
      <div class="offers-container col-12 row">
        <div class="card-offers-limited col-sm-6 col-md-4 col-lg-4 col-12">
          <div class="img-block">
            <img src="assets/icons/top-bookings/t-b-1.PNG">
          </div>
        </div>
        <div class="card-offers-limited col-sm-6 col-md-4 col-lg-4 col-12">
          <div class="img-block">
            <img src="assets/icons/top-bookings/t-b-2.PNG">
          </div>
        </div>
        <div class="card-offers-limited col-sm-6 col-md-4 col-lg-4 col-12">
          <div class="img-block">
            <img src="assets/icons/top-bookings/t-b-3.PNG">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>