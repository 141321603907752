import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-tutor-add-subject',
  templateUrl: './tutor-add-subject.component.html',
  styleUrls: ['./tutor-add-subject.component.scss']
})
export class TutorAddSubjectComponent implements OnInit {
  text1: string = '';
  selectedFile: File | null = null;
  imageName: string | null = null;
  imageUrl: string | null = null;
  showPreview = false;
  url!: string | ArrayBuffer | null;
  format!: string;
  event:any;
  selectedSubjects='Physics';
  imageSrc:any;
  videoSrc:any;
  selectedImageFile: File | undefined;
selectedVideoFile: File | undefined;

filenames: string = '';
  constructor(private http: HttpClient) { 
    
  }

  // onFileSelected(event: Event): void {
  //   const fileInput: HTMLInputElement = event.target as HTMLInputElement;
  //   const fileList: FileList | null = fileInput.files;
  
  //   if (fileList) {
  //     const files: File[] = [];
  //     const iterator: Iterator<File> = fileList[Symbol.iterator]();
  
  //     while (true) {
  //       const result: IteratorResult<File> = iterator.next();
  //       if (result.done) {
  //         break;
  //       }
  //       files.push(result.value);
  //     }
  
  //     if (files.length > 0) {
  //       const file: File = files[0];
  //       // do something with the file
  //     }
  //   }
  // }


  ngOnInit(): void {
  }
  category:any=[
{name:'Finance'},
{name:'IT Software'},
{name:'acconting'},
{name:'marketing'},
{name:'design'}
  ]
  subjects:any=[
    {name:'Mobile Developement'},
    {name:'JAVA'},
    {name:'Web Design'},
    {name:'Game Design'},
    {name:'Database Design'}
  ]
  topics:any=[
    {name:'Collections'},
    {name:'IOS Swift'},
    {name:'Graphical Design master class'},
    {name:'Carrer Hacking'}
  ]
  grades:any=[
    {name:'Level-1'},
    {name:'Grade-1'},
    {name:'Grade-2'},
    {name:'Grade-3'},
    {name:'Grade-4'},
    {name:'Grade-5'}
  ]


  // onFileSelected(event: Event) {
  //   const input = event.target as HTMLInputElement;
  //   if (input.files?.length) {
  //     this.selectedFile = input.files[0];
  //     this.imageName = this.selectedFile.name;
  //     const reader = new FileReader();
  //     reader.readAsDataURL(this.selectedFile);
  //     reader.onload = () => {
  //       this.imageUrl = reader.result as string;
  //     };
  //   }
  // }
  

  // onSubmit() {
  //   if (!this.selectedFile) return;

  //   const formData = new FormData();
  //   formData.append('image', this.selectedFile, this.selectedFile.name);

  //   // TODO: send formData to server using HTTP request

  //   this.showPreview = true;
  // }


  // onSelectFile(event: { target: { files: any[]; }; }) {
  //   const file = event.target.files && event.target.files[0];
  //   if (file) {
  //     var reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     if(file.type.indexOf('image')> -1){
  //       this.format = 'image';
  //     } else if(file.type.indexOf('video')> -1){
  //       this.format = 'video';
  //     }
  //     reader.onload = (event) => {
  //       this.url = (<FileReader>event.target).result;
  //     }
  //   }
  // }
  // onVideoSelected(event: any) {
  //   this.selectedVideoFile = event.target.files[0];
  // }
  /*
  onImageSelected(event: any) {
    this.selectedImageFile = event.target.files[0];
  }
  
  onVideoSelected(event: any) {
    this.selectedVideoFile = event.target.files[0];
  }
  onSubmit() {
    const formData = new FormData();
  
    if (this.selectedImageFile) {
      formData.append('image', this.selectedImageFile, this.selectedImageFile.name);
    }
  
    if (this.selectedVideoFile) {
      formData.append('video', this.selectedVideoFile, this.selectedVideoFile.name);
    }
  
    this.http.post('/api/upload-files', formData).subscribe(
      (response) => console.log('Upload successful'),
      (error) => console.log('Upload failed', error)
    );
  }
}
*/

onImageSelected(event: any) {
  this.selectedImageFile = event.target.files[0];
  this.updateFilenames();
}

onVideoSelected(event: any) {
  this.selectedVideoFile = event.target.files[0];
  this.updateFilenames();
}

updateFilenames() {
  let filenames = '';
  if (this.selectedImageFile) {
    filenames += this.selectedImageFile.name;
  }
  if (this.selectedVideoFile) {
    if (filenames.length > 0) {
      filenames += ', ';
    }
    filenames += this.selectedVideoFile.name;
  }
  this.filenames = filenames;
}
formData: FormData = new FormData();
fileList: any[] = [];
maxFileSize = 0.5 * 1024 * 1024; // modify this to your desired max file size
onFileChange(eve: any) {
  if(eve && eve.addedFiles.length>0){   
  if(eve.addedFiles[0].size > this.maxFileSize)
  {
    Swal.fire('','Image size should not exceed 0.5MB');
    return;
  }
  this.fileList = eve.addedFiles;
  console.log(this.fileList);
  let file: File = this.fileList[0];
  this.formData.set("image", file);
  // console.log(this.formData.get(this.serviceObj.image));
}
}
//Method to remove files
onRemove(event: any) {
  console.log(event);
  //event.File.splice(event.File.indexOf(event), 1);
  this.fileList.splice(this.fileList.indexOf(event), 1);
}

}