import { MapsAPILoader } from '@agm/core';
import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-address-popup',
  templateUrl: './address-popup.component.html',
  styleUrls: ['./address-popup.component.scss']
})
export class AddressPopupComponent implements OnInit {


  showMyContainer: boolean = false;

  // addressType:string;
  addressLabelsList: any = [
    {
      name: 'Home',
      id: 1,
      icon: 'bi-house-door'
    },
    {
      name: 'Work',
      id: 2,
      icon: 'bi-briefcase'
    },
    {
      name: 'Other',
      id: 3,
      icon: 'bi bi-geo-alt'
    }
  ]

  selectAddressLabel(item: any) {
    let eleIndex = this.addressLabelsList.findIndex((add: any) => add.id == item.id);
    this.addressLabelsList.forEach((address: any) => address['selected'] = false);
    this.addressLabelsList[eleIndex].selected = true;
    this.addressLabel = item.name;
  }

  @ViewChild('mapSearchFiled1', { static: true }) searchFiled: ElementRef | undefined;
  userDetails: any
  lat = 0;
  lng = 0;
  zoom = 12;
  userLocation = "";
  geoCoder: any;
  selectedAddress: any;
  addressLabel: any = '';
  cityName: any;
  showAccount: any = false;
  addressList: any;
  isSubmitted = false;
  landmark: any;
  address1: any;
  addresses: any = [];
  otherAddress: any;
  public input$ = new Subject<any>();
  constructor(public authService: AuthService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public dataService: DataService,
    public storageService: StorageService,
    public router: Router,
    private cdr: ChangeDetectorRef,
    public activeModal: NgbActiveModal) {
  }
  @Input() addressObj: any;
  ngOnInit(): void {
    this.userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
    if (this.addressObj && this.addressObj.fromState === 'Edit') {
      this.address1 = this.addressObj.address.address1;
      this.landmark = this.addressObj.address.land_mark;
      this.otherAddress = this.addressObj.address.address_type;
      let eleIndex = this.addressLabelsList.findIndex((add: any) => add.name === this.addressObj.address.address_type);
      this.selectAddressLabel(this.addressLabelsList[eleIndex === -1 ? 2 : eleIndex])
    }
    this.getAddresses();
    
  }

  ngAfterViewInit() {
    this.getGPSLocation();
  }

  getGPSLocation = () => {
    this.showLocation = false;
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;

      const showPos = (position: any) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.getAddress();

      }
      let self = this;
      if (navigator.geolocation) {
        // @ts-ignore
        navigator.geolocation.getCurrentPosition(showPos);

      }


      // @ts-ignore
      let autocomplete = new google.maps.places.Autocomplete(this.searchFiled?.nativeElement, {});
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {

          let place: any = autocomplete.getPlace();
          this.userLocation = place.formatted_address;
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.selectedAddress = this.parse_place(place);
          this.updateLocationInSession()
        });
      });
    });
  }
  showLocation = false;
  getCurrentLocation = () => {
    this.getGPSLocation();
    this.showLocation = true;
  }

  getAddress() {
    this.geoCoder.geocode({
      'location': {
        lat: this.lat,
        lng: this.lng
      }
    }, (results: { formatted_address: string; }[], status: string) => {
      if (status === 'OK') {
        if (results[0]) {
          this.userLocation = results[0].formatted_address;
          this.selectedAddress = this.parse_place(results[0]);
          this.updateLocationInSession();
        } else {
          Swal.fire({
            text: 'No results found',
            icon: 'warning',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
        }
      } else {
        Swal.fire({
          text: 'Geocoder failed due to: ' + status,
          icon: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        });
      }
    });
  }

  gotoManageAddresses()
  {
    // this.router.navigate(["/profile"],{queryParams:{tab:'2'}});

    // Route with Reload
    this.router.navigate(['/profile'],{queryParams:{tab:'2'}})
    .then(() => {
      window.location.reload();
    });
  }

  

  updateLocationInSession() {
    sessionStorage.setItem('location', this.userLocation);
    sessionStorage.setItem('latitude', String(this.lat));
    sessionStorage.setItem('longitude', String(this.lng));
  }


  markerDragEnd(e: any) {
    this.lat = e.latLng.lat();
    this.lng = e.latLng.lng();
    this.zoom = 16;
    this.getAddress();
  }

  getStatus(add: any) {
    return add.selected;
  }

  // saveAddress = () => {
  //   this.isSubmitted = false;
  //   if (!this.authService.isAuthenticated()) {
  //     this.router.navigate(['sign-in']);
  //     return;
  //   }
  //   if (this.addressLabel === "") {
  //     this.isSubmitted = true;
  //     return;
  //   }

  //   let apiName: any;
  //   const reqObj: any = {
  //     customer_id: this.userDetails.customer_id,
  //     address: {
  //       address1: this.address1 ? this.address1 : this.selectedAddress.address1,
  //       address2: this.selectedAddress.address2,
  //       post_code: this.selectedAddress.post_code,
  //       land_mark: this.landmark ? this.landmark : this.selectedAddress.landmark,
  //       latitude: this.lat,
  //       longitude: this.lng,
  //       city: this.selectedAddress.city,
  //       state: this.selectedAddress.state,
  //       country: this.selectedAddress.country,
  //       address_type: this.addressLabel === 'Other' ? this.otherAddress : this.addressLabel,
  //     }
  //   }
  //   if (this.addressObj && this.addressObj.fromState === 'Edit') {
  //     reqObj.address_id = this.addressObj.address.address_id;
  //     apiName = this.dataService.editCustomerAddress(reqObj);
  //   } else {
  //     apiName = this.dataService.createCustomer(reqObj);
  //   }

  //   apiName.subscribe((res: any) => {
  //     if (res && res.error === undefined) {
  //       Swal.fire({
  //         text: res.status,
  //         icon: 'success',
  //         confirmButtonText: 'Ok',
  //         allowOutsideClick: false,
  //         allowEscapeKey: false
  //       }).then((res: any) => {
  //         if (res.isConfirmed) {
  //           this.activeModal.close('ok');
  //         }
  //       });
  //     } else if (res && res.error) {
  //       Swal.fire({
  //         text: res.error.Error,
  //         icon: 'error',
  //         confirmButtonText: 'Ok',
  //         allowOutsideClick: false,
  //         allowEscapeKey: false
  //       })
  //     }
  //   });
  // }

  saveAddress = () => {
    this.isSubmitted = false;
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['sign-in']);
      return;
    }
    if (this.addressLabel === "") {
      this.isSubmitted = true;
      return;
    }
  
    let apiName: any;
    const reqObj: any = {
      customer_id: this.userDetails.customer_id,
      address: {
        address1: this.address1 ? this.address1 : this.selectedAddress.address1,
        address2: this.selectedAddress.address2,
        post_code: this.selectedAddress.post_code,
        land_mark: this.landmark ? this.landmark : this.selectedAddress.landmark,
        latitude: this.lat,
        longitude: this.lng,
        city: this.selectedAddress.city,
        state: this.selectedAddress.state,
        country: this.selectedAddress.country,
        address_type: this.addressLabel === 'Other' ? this.otherAddress : this.addressLabel,
        is_default: this.selectedAddress ? true : false // Assign true if isDefaultAddress is true, otherwise false
      }
    };
    
    if (this.addressObj && this.addressObj.fromState === 'Edit') {
      reqObj.address_id = this.addressObj.address.address_id;
      apiName = this.dataService.editCustomerAddress(reqObj);
    } else {
      apiName = this.dataService.createCustomer(reqObj);
    }
  
    apiName.subscribe((res: any) => {
      if (res && res.error === undefined) {
        Swal.fire({
          text: res.status,
          icon: 'success',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((res: any) => {
          if (res.isConfirmed) {
            this.activeModal.close('ok');
          }
        });
      } else if (res && res.error) {
        Swal.fire({
          text: res.error.Error,
          icon: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        });
      }
    });
  };
  

  parse_place = (place: any) => {
    let location: any = [];
    for (var ac = 0; ac < place.address_components.length; ac++) {
      var component = place.address_components[ac];
      switch (component.types[0]) {
        case 'locality':
          location['city'] = component.long_name;
          this.cityName = component.long_name;
          break;
        case 'administrative_area_level_1':
          location['state'] = component.long_name;
          break;
        case 'country':
          location['country'] = component.long_name;
          break;
        case 'postal_code':
          location['post_code'] = component.long_name;
          break;
        case 'political':
          location['address1'] = component.long_name;
          break;
        case 'premise' || 'neighborhood':
          location['address2'] = component.long_name;
          break;
        case 'landmark':
          location['landmark'] = component.long_name;
          break;
      }
    };
    return location;
  }

  getCustomerAddressList = () => {
    const userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
    if (userDetails) {
      this.dataService.getCustomerAddressList(userDetails?.customer_id).subscribe((res: any) => {
        if (res) {
          this.addressList = res.address;
        }
      });
    }
  }


  buildAddress(address: any) {
    let result: string = '';
    if (address.address1) {
      result = result + address.address1 + ",";
    }
    if (address.address2) {
      result = result + address.address2 + ",";
    }
    if (address.land_mark) {
      result =
        result + address.land_mark + ",";
    }
    if (address.post_code) {
      result =
        result + address.post_code + ",";
    }
    if (address.state) {
      result = result + address.state + ",";
    }
    if (address.city) {
      result = result + address.city + ",";
    }
    if (address.country) {
      result = result + address.country + ",";
    }
    return result;
  }


  getAddresses() {
    const userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
    this.dataService.getCustomerAddressList(userDetails?.customer_id).subscribe((resp: any) => {
      this.addresses = resp.address;
    });
  }

}
