<div class="parent-signup-wrapper">
    <!-- <h1>MyTutor</h1> -->
    <div class="tutor-signup container">
        <div class="row">
            <div class="col-md-3">
                <img src="assets/icons/arrow-left-t.svg" alt="" class="arrow-back" [routerLink]="['/tutor-login']">
            </div>
            <div class="col-md-6">
                <h2> Student Sign up</h2>
            </div>
            <div class="col-md-3">
                <button class="sign-btn" type="button" [routerLink]="['/tutor-login']">Login</button>
            </div>
        </div>


        <div class="login-container-page" *ngIf="!showSuccessPage">
            <div class="login-form-section">

                <ng-container *ngIf="true">

                    <form [formGroup]="signUpForm" (ngSubmit)="onSubmit($event)">
                        <div class="row">
                      <div class="input-block col-md-6">
                        <div class="label-text">First name<sup>*</sup></div>
                        <input type="text" placeholder="Ex:John" class="input-form" formControlName="fname">
                        <div class="valid-check">
                          <i class="bi bi-check-circle-fill success" *ngIf="fnameControls.valid  && formSubmited"></i>
                          <i class="bi bi-exclamation-circle-fill error" *ngIf="fnameControls.invalid  && formSubmited"></i>
                        </div>
                        <span class="error-text" *ngIf="fnameControls.invalid && formSubmited">First name Required</span>
                      </div>
                      <div class="input-block col-md-6">
                        <div class="label-text">Last Name<sup>*</sup></div>
                        <input type="text" placeholder="Ex:Oliver" class="input-form" formControlName="lname">
                        <div class="valid-check">
                          <i class="bi bi-check-circle-fill success" *ngIf="lnameControls.valid  && formSubmited"></i>
                          <i class="bi bi-exclamation-circle-fill error" *ngIf="lnameControls.invalid  && formSubmited"></i>
                        </div>
                        <span class="error-text" *ngIf="lnameControls.invalid && formSubmited">Last name Required</span>
                      </div>

                      <div class="input-block col-md-6">
                        <div class="label-text">Date Of Birth<sup>*</sup></div>
                        <input type="date" placeholder="Ex:Oliver" class="input-form" formControlName="lname">
                        <div class="valid-check">
                          <i class="bi bi-check-circle-fill success" *ngIf="lnameControls.valid  && formSubmited"></i>
                          <i class="bi bi-exclamation-circle-fill error" *ngIf="lnameControls.invalid  && formSubmited"></i>
                        </div>
                        <span class="error-text" *ngIf="lnameControls.invalid && formSubmited">Last name Required</span>
                      </div>
                      <div class="input-block col-md-6">
                        <div class="label-text">Gender</div>
                        <ng-select [items]="gender" bindLabel="name" [(ngModel)]="selectedGender"
                            [multiple]="false"></ng-select>
                    </div>
                    <div class="input-block col-md-12">
                        <div class="label-text">Education Level<sup>*</sup></div>
                        <input type="text" placeholder="Ex:6th Standard" class="input-form" formControlName="sstandard">
                        <div class="valid-check">
                          <i class="bi bi-check-circle-fill success" *ngIf="studyControl.valid  && formSubmited"></i>
                          <i class="bi bi-exclamation-circle-fill error" *ngIf="studyControl.invalid  && formSubmited"></i>
                        </div>
                        <span class="error-text" *ngIf="studyControl.invalid && formSubmited">This field is  Required</span>
                      </div>
                        </div>
                        <div class="row">
                            <div class="input-block col-md-6">
                              <div class="label-text">Parent  First name<sup>*</sup></div>
                              <input type="text" placeholder="Ex:	Arthur" class="input-form" formControlName="sfname">
                              <div class="valid-check">
                                <i class="bi bi-check-circle-fill success" *ngIf="studentfnameControls.valid  && formSubmited"></i>
                                <i class="bi bi-exclamation-circle-fill error" *ngIf="studentfnameControls.invalid  && formSubmited"></i>
                              </div>
                              <span class="error-text" *ngIf="studentfnameControls.invalid && formSubmited"> First name Required</span>
                            </div>
                            <div class="input-block col-md-6">
                              <div class="label-text">Parent  Last Name<sup>*</sup></div>
                              <input type="text" placeholder="Ex:Oliver" class="input-form" formControlName="slname">
                              <div class="valid-check">
                                <i class="bi bi-check-circle-fill success" *ngIf="studentlnameControls.valid  && formSubmited"></i>
                                <i class="bi bi-exclamation-circle-fill error" *ngIf="studentlnameControls.invalid  && formSubmited"></i>
                              </div>
                              <span class="error-text" *ngIf="studentlnameControls.invalid && formSubmited">Last name Required</span>
                            </div>
                              </div>
                        <div class="row">
                      <div class="input-block col-md-6">
                        <div class="label-text">Email<sup>*</sup></div>
                        <input type="email" placeholder="Ex:oliver@gmail.com" class="input-form" formControlName="email">
                        <div class="valid-check">
                          <i class="bi bi-check-circle-fill success" *ngIf="emailControls.valid  && formSubmited"></i>
                          <i class="bi bi-exclamation-circle-fill error" *ngIf="emailControls.invalid  && formSubmited"></i>
                        </div>
                        <span class="error-text" *ngIf="emailControls.invalid  && formSubmited">Email is Required</span>
                      </div>
                      <div class="input-block col-md-6">
                        <div class="label-text">Phone Number<sup>*</sup></div>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text">{{countryCode}}</div>
                          </div>
                          <input type="text" placeholder="Ex:+41" class="input-form prepend-data"
                            formControlName="mobile">
                        </div>
                        <div class="valid-check">
                          <i class="bi bi-check-circle-fill success" *ngIf="phoneNumControls.valid  && formSubmited"></i>
                          <i class="bi bi-exclamation-circle-fill error" *ngIf="phoneNumControls.invalid  && formSubmited"></i>
                        </div>
                        <!-- <span class="error-text" *ngIf="phoneNumControls.invalid && formSubmited">Phone Number required</span> -->
                        <span class="error-text" *ngIf="formSubmited && phoneNumControls.mobile.errors?.required">Phone Number is required</span>
                        <span class="error-text" *ngIf="formSubmited && phoneNumControls.mobile.errors?.pattern || phoneNumControls.mobile.errors?.maxlength">Phone Number requires minimum 10 Characters</span>
                      </div>
                        </div>
                   
           
                  
                        <div class="row">
                                           
                            <div class="col-md-6"> <button class="btn-back" type="button" class="back-wrap" [routerLink]="['/student/age']">
                                Back</button>
                        </div>
                             <div class="col-md-6">   
                      <button class="c-wrap" type="submit" >Signup</button>
                             </div>
                    </div>
                    </form>
                  </ng-container>
            </div>
        </div>
    </div>
</div>
