import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressPopupComponent } from 'src/app/components/address-popup/address-popup.component';
import { RouteConstants } from 'src/app/model/route-constants';
import { AuthService } from 'src/app/services/auth.service';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import moment = require('moment');
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-service-info',
  templateUrl: './service-info.component.html',
  styleUrls: ['./service-info.component.scss']
})
export class ServiceInfoComponent implements OnInit {

  @ViewChild('modelTemplate', { static: false }) modelTemplate: ElementRef;
  @ViewChild('mapSearchFiled2', { static: true }) searchFiled: ElementRef | undefined;
  userDetails: any
  lat = 0;
  lng = 0;
  zoom = 12;
  userLocation = "";
  geoCoder: any;
  selectedAddress: any;
  addressLabel: any = '';
  cityName: any;
  showAccount: any = false;
  serviceId: any;
  branchId: any;
  serviceDetails: any;
  branchList: any;
  showBranchModalPopup = false;
  showHomeModalPopup = false;
  showServicesPopup = false;
  showMainModalPopup = false;
  address_type: any;
  address_id: any;
  addressList: any;
  location: any;
  pop: any = {
    value: false
  };
  distance: any;
  showAddAddress = false;
  isSubmitted = false;
  categoryId: any;
  serviceTitle: any;
  currencyCode = environment.currencyCode;
  providersDetails: any;
  catId: string | undefined;
  branch_slug_title: string | undefined;
  providerId: any;
  showSelectedData: boolean = false;
  showAddRecurring: boolean = false;
  recurringForm!: any;
  dateSelected: any;
  selectedServiceOn: any;
  selectedRepeat: any;
  customRepeatText: string | undefined;
  customEndText: string | undefined;
  selectedEnd: any;
  repeatsEvery!: number;
  numServices!: number;
  selectedCustomRepeat: any;
  selectedDays = new Set<any>();
  staffList: any = [];
  // employees: any[];
  selectedStaff: any;
  timeSlotsList: any;
  isSelectSlots = false;
  formGroup: any;
  selectedTimeSlot!: string;
  // timeSlotsList: string[] = [];

  selectedSubscription: any;
  recurringIntervals: any[] = [];
  selectedPlan: any;
  selectBookAService = false;
  fromState: any;
  holidayList: any;


  date = new Date();
  addDaysToDate = () => {
    var res = new Date();
    res.setDate(res.getDate() + 15);
    return res;
  }
  minDate = { "year": this.date.getFullYear(), "month": this.date.getMonth() + 1, "day": this.date.getDate() };
  maxDate = { "year": this.addDaysToDate().getFullYear(), "month": this.addDaysToDate().getMonth() + 1, "day": this.addDaysToDate().getDate() };
  markDisabled: any;
  weekList: any;
  recuringIntervalList = [
    {
        name: "Monthly Plan",
        recurring_interval: "monthly"
    },
    {
        name: "Weekly Plan",
        recurring_interval: "weekly"
    },
    {
        name: "Daily Plan",
        recurring_interval: "daily"
    }
]

  // selectSubscription(item: any) {
  //   this.selectedSubscription = item;
  // }

  // showAddRecurring = false; // Initially hide the recurring interval

  constructor(private bs: BroadcastService, private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public dataService: DataService, public storageService: StorageService,
    public authService: AuthService,
    private cdr: ChangeDetectorRef,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private fb: FormBuilder,
    public calendar: NgbCalendar,
    private modalService: NgbModal, private sanitizer: DomSanitizer) {
    // Initialize the property
    this.modelTemplate = {} as ElementRef;

  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((routeObj: any) => {
      this.serviceId = routeObj.params.id;
      this.getServiceDetails();
    });
  

   
  }

 


  sanitizeHTML(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getServiceDetails() {
    this.dataService.getServiceDetailsById({ serviceId: this.serviceId}).subscribe(
      (res: any) => {
        if (res) {
          this.serviceDetails = res;
        } else {
          console.error('Unexpected API response format:', res);
        }
      },
      (error) => {
        console.error('Error fetching service details:', error);
      }
    );
  
  }



  ngAfterViewInit() {

  }

 

  gotoReviewPage = (fromState?: any) => {
    jQuery('body, html').animate({ scrollTop: 0 }, 100)
    this.router.navigate([RouteConstants.RATINGS_AND_REVIEWS], {
      queryParams: {
        title: this.catId,
        providerId: this.providerId,
        branch_slug_title: this.branch_slug_title,
        wrt: "false",
        branchId: this.providersDetails.branch_id
      }
    })
  }


  backFn = () => {
    const serviceListObj = JSON.parse(<string>this.storageService.getItem("serviceListObj"));
    this.router.navigate([RouteConstants.PROVIDER_DETAILS], {
      queryParams: {        
        title: serviceListObj.title,
        providerId: serviceListObj.providerId,
        bId: serviceListObj.bId,
        branch_slug_title: serviceListObj.branch_slug_title
      }
    });
    this.storageService.removeItem("serviceListObj")
  }

}
