<app-tutor-header></app-tutor-header>
<div class="container-fluid student-profile-wrapper">
    <div class="main-header-section">
        <div class="inner-page-title">
            <h3>Bookings</h3>
        </div>
        <div class="right-btns">

        </div>
    </div>

    <div class="booking-main-content">
        <p-tabView>
            <p-tabPanel header="Upcoming">
                <div class="booking-tab-content">
                    <div class="row">
                        <div class="col-md-4 booking-status-dropdown w-25 mt-3">                    
                       
                        <ng-select [items]="upcomingStatusList" bindLabel="name" [searchable]="false"
                            [(ngModel)]="defaultUpcomingStatus" [clearable]="false">
                        </ng-select>
                   
                    </div>
                    <div class="col-md-8 text-right mt-3">
<input type="date" class="form-control" id="dat-wrap">
                    </div>

                    </div>
                    <h5 class="day-wrap">Today</h5>
                    <div class="booking-card-wrapper">
                        <div class="bookings-card-view mt-3" *ngFor="let booking of bookingsList; let i = index;">
                            <div class="tutor-header">
                                <div class="t-header-inner">
                                    <img  class="s-img" src="assets/images/tutor-p.jpg" alt="">
                                </div>
                                <div class="t-header-inner">
                                    <h6 class="t-name">{{booking.studentname}}
                                     
                                    </h6>
                                    <p class="s-subject">{{booking.subject}}</p>
                                    
                                </div>
                                <div class="t-header-inner text-right">
                                    <button class="btn btn-block btn-reject text-right menu">
                                        <img src="assets/icons/menu-dot.svg" alt="" class="dotted-menu menu">
                                        <div class="menu-dropdown">
                                            <a (click)="openEditBookingPanel = true" class="text-left">Reschedule
                                                lesson</a>
                                               
                                            <a (click)="openCancelBookingPanel = true" class="text-left">Cancel
                                                lesson</a>
                                        </div>
                                    </button>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-md-6 col-6">
                                    <p class="s-label">Course Duration</p>
                                    <p class="s-date">{{booking.date}}</p>
                                    <p class="s-time">{{booking.day}}</p>
                                </div>
                                <div class="col-md-6  col-6 buk-wrap">
                                    <span class="s-label">Time :</span><span class="s-date">{{booking.time}}</span>
                                    <!-- <p class="b-type">   {{booking.bookingtype}}</p> -->

                                    <div class="s-price">
                    
                              
                                        <Span> <img class="icon-b" [src]="booking.meetingicon" alt=""></Span><span class="c-type">{{booking.bookingtype}}</span>
                                      
                                  
                                    </div>
                                    <p class="s-date">
                                        <span><img [src]="booking.statusicon" alt=""></span>
                                        <span class="{{booking.statustext}}" id="b-status">{{booking.status}}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-6">
                                    <button class="btn btn-block btn-edit"
                                      (click)="openCancelBookingPanel = true">Reject</button>

                                    <!-- <button class="btn btn-block btn-edit text-center menu">
                                        Edit
                                        <div class="menu-dropdown">
                                            <a (click)="openEditBookingPanel = true" class="text-left">Reschedule
                                                lesson</a>
                                            <a (click)="openCancelBookingPanel = true" class="text-left">Cancel
                                                lesson</a>
                                        </div>
                                    </button> -->
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-block btn-accept"
                                        (click)="accepetLessonBooking()">Accept</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h5 class="day-wrap">Tomarrow</h5>
                    <div class="booking-card-wrapper">
                        <div class="bookings-card-view mt-3" *ngFor="let booking of bookingsList; let i = index;">
                            <div class="tutor-header">
                                <div class="t-header-inner">
                                    <img class="s-img" src="assets/images/tutor-p.jpg" alt="">
                                </div>
                                <div class="t-header-inner">
                                    <h6 class="t-name">{{booking.studentname}}
                                     
                                    </h6>
                                    <p class="s-subject">{{booking.subject}}</p>
                                    
                                </div>
                                <div class="t-header-inner text-right">
                                    <button class="btn btn-block btn-reject text-right menu">
                                        <img src="assets/icons/menu-dot.svg" alt="" class="dotted-menu menu">
                                        <div class="menu-dropdown">
                                            <a (click)="groupopenEditBookingPanel = true" class="text-left">Reschedule
                                                lesson</a>
                                            <a (click)="openCancelBookingPanel = true" class="text-left">Cancel
                                                lesson</a>
                                        </div>
                                    </button>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-md-6 col-6">
                                    <p class="s-label">Course Duration</p>
                                    <p class="s-date">{{booking.date}}</p>
                                    <p class="s-time">{{booking.day}}</p>
                                </div>
                                <div class="col-md-6  col-6 buk-wrap">
                                    <span class="s-label">Time :</span><span class="s-date">{{booking.time}}</span>
                                 
                                    <div class="s-price">
                    
                              
                                        <Span> <img class="icon-b" [src]="booking.meetingicon" alt=""></Span><span class="c-type">{{booking.bookingtype}}</span>
                                      
                                  
                                    </div>
                                    <p class="s-date">
                                        <span><img [src]="booking.statusicon" alt=""></span>
                                        <span class="{{booking.statustext}}" id="b-status">{{booking.status}}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-6">
                                    <button class="btn btn-block btn-edit"
                                      (click)="openCancelBookingPanel = true">Reject</button>

                                    <!-- <button class="btn btn-block btn-edit text-center menu">
                                        Edit
                                        <div class="menu-dropdown">
                                            <a (click)="openEditBookingPanel = true" class="text-left">Reschedule
                                                lesson</a>
                                            <a (click)="openCancelBookingPanel = true" class="text-left">Cancel
                                                lesson</a>
                                        </div>
                                    </button> -->
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-block btn-accept"
                                        (click)="accepetLessonBooking()">Accept</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 class="day-wrap">Rest of the Week</h5>
                    <div class="booking-card-wrapper">
                        <div class="bookings-card-view mt-3" *ngFor="let booking of bookingsList; let i = index;">
                            <div class="tutor-header">
                                <div class="t-header-inner">
                                    <img  class="s-img" src="assets/images/tutor-p.jpg" alt="">
                                </div>
                                <div class="t-header-inner">
                                    <h6 class="t-name">{{booking.tutorname}}</h6>
                                    <p class="s-subject">{{booking.subject}}</p>
                                </div>
                                <div class="t-header-inner text-right">
                                    <button class="btn btn-block btn-reject text-right menu">
                                        <img src="assets/icons/menu-dot.svg" alt="" class="dotted-menu menu">
                                        <div class="menu-dropdown">
                                            <a (click)="openEditBookingPanel = true" class="text-left">Reschedule
                                                lesson</a>
                                            <a (click)="openCancelBookingPanel = true" class="text-left">Cancel
                                                lesson</a>
                                        </div>
                                    </button>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-md-6 col-6">
                                    <p class="s-label">Course Duration</p>
                                    <p class="s-date">{{booking.date}}</p>
                                    <p class="s-time">{{booking.day}}</p>
                                </div>
                                <div class="col-md-6  col-6 buk-wrap">
                                    <span class="s-label">Time :</span><span class="s-date">{{booking.time}}</span>
                                 
                                    <div class="s-price">
                    
                              
                                        <Span> <img class="icon-b" [src]="booking.meetingicon" alt=""></Span><span class="c-type">{{booking.bookingtype}}</span>
                                      
                                  
                                    </div>
                                    <p class="s-date">
                                        <span><img [src]="booking.statusicon" alt=""></span>
                                        <span class="{{booking.statustext}}" id="b-status">{{booking.status}}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-6">
                                    <button class="btn btn-block btn-edit"
                                      (click)="openCancelBookingPanel = true">Reject</button>

                                    <!-- <button class="btn btn-block btn-edit text-center menu">
                                        Edit
                                        <div class="menu-dropdown">
                                            <a (click)="openEditBookingPanel = true" class="text-left">Reschedule
                                                lesson</a>
                                            <a (click)="openCancelBookingPanel = true" class="text-left">Cancel
                                                lesson</a>
                                        </div>
                                    </button> -->
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-block btn-accept"
                                        (click)="accepetLessonBooking()">Accept</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </p-tabPanel>
            <p-tabPanel header="Previous">
                <div class="booking-tab-content">
                    <div class="booking-status-dropdown w-25 mt-3">
                        <ng-select [items]="previousStatusList" bindLabel="name" [searchable]="false"
                            [(ngModel)]="defaultPreviousStatus" [clearable]="false">
                        </ng-select>
                    </div>

                    <div class="booking-card-wrapper">
                        <div class="bookings-card-view mt-3" *ngFor="let booking of bookingsList; let i = index;">
                            <div class="tutor-header">
                                <div class="t-header-inner">
                                    <img class="s-img" src="assets/images/tutor-p.jpg" alt="">
                                </div>
                                <div class="t-header-inner">
                                    <h6 class="t-name">{{booking.tutorname}}</h6>
                                    <p class="s-subject">{{booking.subject}}</p>
                                </div>
                                <div class="t-header-inner text-right d-none">
                                    <img src="assets/icons/delete-icon.svg" alt="" (click)="deleteBookingLesson()"
                                        title="Delete Lesson" class="delete-booking">
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-md-6 col-6">
                                    <p class="s-label">Course Duration</p>
                                    <p class="s-date">{{booking.date}}</p>
                                    <p class="s-time">{{booking.day}}</p>
                                </div>
                                <div class="col-md-6 text-right col-6">
                                    <span class="s-label">Time :</span><span class="s-date">{{booking.time}}</span>
                                    <p class="s-label">Status</p>
                                    <p class="s-date">
                                        <span><img src="assets/icons/contact/success.svg" alt=""></span>
                                        <span class="text-success" id="b-status">Completed</span>
                                    </p>
                                </div>
                            </div>
                            <div class="row mt-4 d-none">
                                <div class="col-md-6">
                                    <button class="btn btn-block btn-edit">Edit</button>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-block btn-accept"
                                        (click)="accepetLessonBooking()">Accept</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>


<!-- Reschedule Booking  one-of session -->
<div class="reschedule-booking-wrapper">
    <p-sidebar [(visible)]="openEditBookingPanel" position="right" [baseZIndex]="10000">
        <div class="row add-panel-close">
            <div class="col-md-10 col-10">
                <h5 class="add-student-heading">Reschedule booking</h5>
                <p>English Literature </p>
            </div>
            <div class="col-md-2 text-right col-2">
                <a (click)="openEditBookingPanel = false">
                    <img src="assets/icons/cross.svg" alt="">
                </a>
            </div>
        </div>

        <div class="mt-5">
            <h5>Suggest a new time to Student</h5>
            <div class="row booking-reschedule mt-5 d-none">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Pick a day</label>
                        <input type="date" class="form-control mt-2" id="" placeholder="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Pick a time</label>
                        <input type="time" class="form-control mt-2" id="" placeholder="">
                    </div>
                </div>

            </div>
            <div class="row r-shedule mt-5">
                <!-- <div class="col-md-4">
<h4 class="picker-date">From</h4>
<input type="date" formControlName="day">

                </div> -->
                <div class="col-md-6">
                    <h4 class="picker-date">Select Date</h4>
                    <input type="date" formControlName="dayone">
                 
                </div>
                <div class="col-md-6">
                    <h4 class="picker-date">Select Time</h4>
                    <input type="time" formControlName="time">
                
                </div>
              </div>
           
          

            <div class="row booking-reschedule mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="">Send Tutor a message (optional)</label>
                        <textarea name="" id="" cols="30" rows="4"></textarea>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <button class="btn btn-block btn-accept">Request new time</button>
                    </div>
                </div>

            </div>

        </div>
    </p-sidebar>
</div>
<!-- Reschedule Booking -->


<!-- Reject Booking  -->
<div class="reschedule-booking-wrapper">
    <p-sidebar [(visible)]="openCancelBookingPanel" position="right" [baseZIndex]="10000">
        <div class="row add-panel-close">
            <div class="col-md-10">
                <h5 class="add-student-heading">Reject booking</h5>
            </div>
            <div class="col-md-2 text-right">
                <a (click)="openCancelBookingPanel = false">
                    <img src="assets/icons/cross.svg" alt="">
                </a>
            </div>
        </div>

        <div class="mt-5">
            <h5>Are you sure you want to reject?</h5>
            <div class="confirm-delete mt-4 mb-3">
                <div class="row">
                    <div class="col-md-6">
                        <label for="" class="delete-item-label">Student</label>
                    <p class="delete-item">Will</p>
                    </div>
                    <div class="col-md-6 text-right">
                        <label for="" class="delete-item-label">Course Duration</label>
                    <p class="delete-item">Thu 9 Feb, 14:30</p>
                    </div>
                    <div class="col-md-6">
                        <label for="" class="delete-item-label">Subject</label>
                        <p class="delete-item">History of Art</p>
                    </div>
                    <div class="col-md-6 text-right">
                        <label for="" class="delete-item-label">Level</label>
                    <p class="delete-item">A Level</p>
                    </div>
                </div>
            </div>

            <div class="container d-none">
                <div class="row radio-selection">
                    <!-- Radio -->
                    <div class="grid-wrapper grid-col-auto">
                        <label for="radio-card-1" class="radio-card">
                            <input type="radio" name="radio-card" id="radio-card-1" checked />
                            <div class="card-content-wrapper">
                                <span class="check-icon"></span>
                                <div class="card-content">
                                    <h5 class="text-center">ONLY CANCEL THIS LESSON</h5>
                                </div>
                            </div>
                        </label>
                        <!-- /.radio-card -->

                        <label for="radio-card-2" class="radio-card">
                            <input type="radio" name="radio-card" id="radio-card-2" />
                            <div class="card-content-wrapper">
                                <span class="check-icon"></span>
                                <div class="card-content">
                                    <h5 class="text-center">CANCEL ALL LESSONS FROM THU 9 FEB 14:30 ONWARDS</h5>
                                </div>
                            </div>
                        </label>

                    </div>
                </div>
            </div>

            <div class="row booking-reschedule mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="">Reason for cancellation (optional)</label>
                        <textarea name="" id="" cols="30" rows="4"></textarea>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <button class="btn btn-block btn-danger btn-cancel">Reject lesson</button>
                    </div>
                </div>

            </div>

        </div>
    </p-sidebar>
</div>
<!-- Reject Bookingy -->



<!-- Reschedule Booking  Group session -->
<div class="reschedule-booking-wrapper">
    <p-sidebar [(visible)]="groupopenEditBookingPanel" position="right" [baseZIndex]="10000">
        <div class="row add-panel-close">
            <div class="col-md-10 col-10">
                <h5 class="add-student-heading">Reschedule booking </h5>
            </div>
            <div class="col-md-2 text-right col-2">
                <a (click)="groupopenEditBookingPanel = false">
                    <img src="assets/icons/cross.svg" alt="">
                </a>
            </div>
        </div>
        <p>Subject:English Literature</p>

        <div class="mt-5">
            <!-- <h5>Suggest a new time to Student</h5> -->
            <div class="row booking-reschedule mt-5 d-none">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Pick a day</label>
                        <input type="date" class="form-control mt-2" id="" placeholder="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Pick a time</label>
                        <input type="time" class="form-control mt-2" id="" placeholder="">
                    </div>
                </div>

            </div>
            <div class="r-shedule  mt-5"*ngIf="showTextFlag">
                <!-- <div class="col-md-4">
<h4 class="picker-date">From</h4>
<input type="date" formControlName="day">

                </div> -->
                <p>only this session your going to Reshedule</p>
                <div class="row">               
                <div class="col-md-6">
                    <h4 class="picker-date">Select Date</h4>
                    <input type="date" formControlName="dayone">
                 
                </div>
                <div class="col-md-6">
                    <h4 class="picker-date">Select Time</h4>
                    <input type="time" formControlName="time">
                
                </div>
                </div>
              </div>
              <div class=" r-shedule  mt-5" *ngIf="showInputFlag">
                <p>This will apply to all Sessions in this slot</p>
               <div class="row">

          
                <div class="col-md-4">
<h4 class="picker-date">From</h4>
<input type="date" formControlName="day">

                </div>
                <div class="col-md-4">
                    <h4 class="picker-date">Select Date</h4>
                    <input type="date" formControlName="dayone">
                 
                </div>
                <div class="col-md-4">
                    <h4 class="picker-date">Select Time</h4>
                    <input type="time" formControlName="time">
                
                </div>
              </div>
              </div>
              <div class="container">
                <div class="row radio-selection">
                    <!-- Radio -->
                    <div class="grid-wrapper grid-col-auto">
                        <label for="radio-card-1" class="radio-card">
                            <input type="radio" name="radio-card" id="radio-card-1" (click)="showText()"checked>
                            <div class="card-content-wrapper">
                                <span class="check-icon"></span>
                                <div class="card-content ">
                                    <h5 class="text-center">ONLY RESCHEDULE THIS LESSON</h5>
                                </div>
                            </div>
                        </label>
                        <!-- /.radio-card -->

                        <label for="radio-card-2" class="radio-card ">
                            <input type="radio" name="radio-card" id="radio-card-2" (click)="showInput()">
                            <div class="card-content-wrapper">
                                <span class="check-icon"></span>
                                <div class="card-content">
                                    <h5 class="text-center">RESCHEDULE ALL LESSONS FROM THU 9 FEB 14:30 ONWARDS</h5>
                                </div>
                            </div>
                        </label>

                    </div>
                </div>
            </div>

            <div class="row booking-reschedule mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="">Send Tutor a message (optional)</label>
                        <textarea name="" id="" cols="30" rows="4"></textarea>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <button class="btn btn-block btn-accept">Request new time</button>
                    </div>
                </div>

            </div>

        </div>
    </p-sidebar>
</div>
<!-- Reschedule Booking -->
<!-- Cancel Booking  -->
<div class="reschedule-booking-wrapper">
    <p-sidebar [(visible)]="openCancelBookingPanel" position="right" [baseZIndex]="10000">
        <div class="row add-panel-close">
            <div class="col-md-10 col-10">
                <h5 class="add-student-heading">Cancel booking</h5>
            </div>
            <div class="col-md-2 text-right col-2">
                <a (click)="openCancelBookingPanel = false">
                    <img src="assets/icons/cross.svg" alt="">
                </a>
            </div>
        </div>

        <div class="mt-5">
            <h5>Are you sure you want to cancel?</h5>
            <div class="confirm-delete mt-4 mb-3">

                <div class="row">
                    <div class="col-md-6 col-6">
                        <label for="" class="delete-item-label">Student</label>
                    <p class="delete-item">Anastasia B.</p>
                    </div>
                    <div class="col-md-6 text-right col-6">
                        <label for="" class="delete-item-label">Date & Time</label>
                    <p class="delete-item">Thu 9 Feb, 14:30</p>
                    </div>
                    <div class="col-md-6 col-6">
                        <label for="" class="delete-item-label">Subject</label>
                        <p class="delete-item">History of Art</p>
                    </div>
                    <div class="col-md-6 text-right col-6">
                        <label for="" class="delete-item-label">Session Type</label>
                    <p class="delete-item">Group Session</p>
                    </div>
                </div>
            </div>

            <div class="container d-none">
                <div class="row radio-selection">
                    <!-- Radio -->
                    <div class="grid-wrapper grid-col-auto">
                        <label for="radio-card-1" class="radio-card">
                            <input type="radio" name="radio-card" id="radio-card-1" checked />
                            <div class="card-content-wrapper">
                                <span class="check-icon"></span>
                                <div class="card-content">
                                    <h5 class="text-center">ONLY CANCEL THIS LESSON</h5>
                                </div>
                            </div>
                        </label>
                        <!-- /.radio-card -->

                        <label for="radio-card-2" class="radio-card">
                            <input type="radio" name="radio-card" id="radio-card-2" />
                            <div class="card-content-wrapper">
                                <span class="check-icon"></span>
                                <div class="card-content">
                                    <h5 class="text-center">CANCEL ALL LESSONS FROM THU 9 FEB 14:30 ONWARDS</h5>
                                </div>
                            </div>
                        </label>

                    </div>
                </div>
            </div>

            <div class="row booking-reschedule mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="">Reason for cancellation (optional)</label>
                        <textarea name="" id="" cols="30" rows="4"></textarea>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <button class="btn btn-block btn-danger btn-cancel">Cancel lesson</button>
                    </div>
                </div>

            </div>

        </div>
    </p-sidebar>
</div>
<!-- Cancel Bookingy -->