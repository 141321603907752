import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({

  selector: 'app-tutor-signup-form',
  templateUrl: './tutor-signup-form.component.html',
  styleUrls: ['./tutor-signup-form.component.scss']
})
export class TutorSignupFormComponent implements OnInit {
  activeIndex1: number = 0;
  activeIndex:number=0;
  activeIndex2: number = 0;
  scrollableTabs: any[] = Array.from({ length: 50 }, (_, i) => ({ title: `Tab ${i + 1}`, content: `Tab ${i + 1} Content` }));
  myDateValue:any;
  signUpForm: any;
  formSubmited = false;
  errMsg: any;
  showPwdLabel = "password";
  showSuccessPage = false;
  countryCode = environment.countryCode;
  changePWDForm: any;
  navbar: any;
  header: any;
   
  constructor(public authService: AuthService,
    public router: Router,

    private socialAuthService: SocialAuthService) { }


    ngOnInit(): void {
      
      this.signUpForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        mobile: new FormControl('', [Validators.required, Validators.pattern(new RegExp("[0-9 ]{10}")), Validators.maxLength(10)]),
        pwd: new FormControl('', [Validators.required]),
        name: new FormControl('', [Validators.required]),
        agree: new FormControl(false, [Validators.requiredTrue]),
 
          });
          // this.myDateValue = new Date(); 
         
          // this.renderer.setStyle(this.header.nativeElement, 'display', 'none');
    }
    // ngAfterViewInit() {
    //   this.header.nativeElement.style.display = 'none';
    // }

    get emailControls() { return this.signUpForm.get('email'); }
    get phoneNumControls() { return this.signUpForm.controls;}
    // get pwdControls() { return this.changePWDForm.controls; }
    private passwordsShouldMatch(fGroup: FormGroup) {
      return this?.changePWDForm?.get('newPWD')?.value === this.changePWDForm?.get('cnfrmPWD')?.value
        ? null : { 'mismatch': true };
    } 
    isVisible = () => {
      this.showPwdLabel = this.showPwdLabel === 'password' ? 'text' : 'password';
    }
    get pwdControls() { return this.signUpForm.get('pwd'); }
    get nameControls() { return this.signUpForm.get('name'); }
    get agreeControls() { return this.signUpForm.get('agree'); }
  
    onSubmit = (event: any) => {
      event.preventDefault();
      this.formSubmited = true;
      this.errMsg ='';  
      if (this.signUpForm.valid) {
        this.signUpForm.value.phoneNum = environment.countryCode+this.signUpForm.value.mobile;
        this.authService.signUp(this.signUpForm.value).subscribe((res: any) => {
          if (res && res.Error === undefined) {
            this.showSuccessPage = true;
          } else {
            this.errMsg = res.Error;
          }
        });
      }
    }
  
    signInWithGoogle = () => {
      this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data: any) => {
        if (data) {
          const reqObj = {
            name: data.name,
            email: data.email,
            google_token: data.authToken,
            google_id: data.id,
            gender: '',
            profile_pic: data.photoUrl,
            provider: data.provider
          }
          this.authService.signInWithGoogle_FB(reqObj).subscribe((res: any) => {
            if (res && !res.errorCode) {
              console.log('success', res);
              this.router.navigate(['/home']);
            } else {
              console.log('fail');
              this.errMsg = res.message;
            }
          })
          console.log(data);
        }
      });
    }
  
  
  signInWithFB =() =>  {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((data: any) =>{
      if (data) {
        const reqObj = {
          name: data.name,
          email: data.email,
          google_token: data.authToken,
          google_id: data.id,
          gender: '',
          profile_pic: data.photoUrl,
          provider: data.provider
        }
        this.authService.signInWithGoogle_FB(reqObj).subscribe((res: any) => {
          if (res && !res.errorCode) {
            console.log('success', res);
            this.router.navigate(['/home']);
          } else {
            console.log('fail');
            this.errMsg = res.message;
          }
        })
        console.log(data);
      }
    });
  }
  
}
