
<app-tutor-header></app-tutor-header>
    <div class="main-content-wrapper">
        <div class="container">
            <div class="first-row">

                <div class="first-row-inner" id="first-row-right">
                    <h3>Popular subjects</h3>

                    <div class="inner-service-card-wrapper">
                        <div class="inner-card-item" [routerLink]="['/view-tutors']">

                            Mathematics
                        </div>
                        <div class="inner-card-item" [routerLink]="['/view-tutors']">

                            Physics
                        </div>
                        <div class="inner-card-item" [routerLink]="['/view-tutors']">

                            Chemistry
                        </div>
                        <div class="inner-card-item" [routerLink]="['/view-tutors']">
                            <!-- <img src="assets/images/T-two.jpg" alt=""> -->
                            Biology
                        </div>
                        <div class="inner-card-item" [routerLink]="['/view-tutors']">

                            History
                        </div>
                        <div class="inner-card-item" [routerLink]="['/view-tutors']">

                            Music
                        </div>
                        <div class="inner-card-item" [routerLink]="['/view-tutors']">

                            Psychology
                        </div>
                        <div class="inner-card-item" [routerLink]="['/view-tutors']">
                            <!-- <img src="assets/images/T-two.jpg" alt=""> -->
                            All subjects
                        </div>
                    </div>
  
                </div>
            </div>

            <div class="picked-session">
                <div class="picked-right">
                    <h2>Handpicked tutors from £20/hour</h2>
                    <p>We're very (very) picky about who we let tutor on our platform - just 1 in 8 who apply make the
                        cut. They're experts in over 30 subjects from KS2 up to GCSE and A Level. Because they're from
                        UK unis, they studied (and aced) the same courses as your teen in the last few years. So they
                        explain tricky concepts in a way teens understand - and they double as cool older role models.
                    </p>
                </div>
                <div class="picked-left">
                    <img src="assets/images/tutor-one.svg" alt="" class="img-tutor">
                </div>
            </div>

              <div class="truested-session">
                <div class="trust-left">
                    <img src="assets/images/tutor-two.svg" alt="">
                </div>
                <div class="trust-right mt-5">
                    <h2>Trusted by parents & teachers</h2>
                    <p>MyTutor is the UK's most trusted tutoring platform by parents. We're rated 4.7/5 on Trustpilot from the million (and counting!) lessons we’ve delivered so far.
                    </p>
                    <p>And because our tutors get such good results, schools use them to support their teaching. We work with 650+ across the UK, targeting learning gaps and helping teens everywhere achieve their goals.</p>
                </div>
             
            </div>
        </div>


        <div class="container">
            <hr>
            <div class="footer-wrapper">
                <div class="inner-footer-left">
                    <p>&copy; Copyright 2023. Powered by <a href="#">Accenflair IT Solutions (P) Ltd.</a></p>
                </div>
                <div class="inner-footer-right">
                    <ul>
                        <li>Privacy policy</li>
                        <li>Terms of Service</li>
                        <li>Refund policy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
