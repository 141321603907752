
<div class="tutor-page">
    <div class="header-session container-fluid">
        <div class="row">
            <div class="col-md-3 col-3">
                <img src="assets/icons/t-arrow-left.svg" alt="" [routerLink]="['/tutor-login']">
            </div>
            <div class="col-md-6 col-6">
                <h1>Tutor log in</h1>
            </div>
            <div class="col-md-3 col-3">
                <img src="assets/icons/t-cross.svg" alt="" [routerLink]="['/onlinetutor-theme']">
            </div>
        </div>
    </div>
    <div class="login-wrapper container">
        <div class="left-wrap">
            <img src="assets/images/t-pic.png" alt="" class="t-img">
            <h2>I am a Tutor</h2>
            <p>Give lessons or manage bookings with your customers</p>
        </div>
        <div class="right-wrap">
<!-- <div>
            <label>Email address</label>
            <input type="text" placeholder="Type your email address" class="input-form" 
              formControlName="name">
            
            </div> -->



                            <ng-container *ngIf="true">


                                <form [formGroup]="signUpForm" (ngSubmit)="onSubmit($event)">
                                
                                    <div id="input-wrap">
                                        <div class="input-block">
                                            <div><label>Email address</label><sup>*</sup></div>
                                            <input type="email" placeholder="Please enter your your email"
                                                class="input-form" formControlName="email">
                                            <div class="valid-check">

                                            </div>
                                            <span class="error-text"
                                                *ngIf="emailControls.invalid  && formSubmited">Email
                                                is Required
                                            </span>
                                        </div>
                                    



                                     

                                 
                                      
                                    </div>

                                    <span class="errorMsg" *ngIf="errMsg">{{errMsg}}</span>
                                    <button class="btn-sign-up" type="submit" [routerLink]="['/tutor/login']"> Login with password</button>

                                </form>
                            </ng-container>
            
              

<!-- <div class="mt-3">
    <button  class="btn-sign-up" type="button">
        Login with magic  link
    </button>
</div> -->
        </div>
    </div>
</div>