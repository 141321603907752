import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-customers-say',
  templateUrl: './what-customers-say.component.html',
  styleUrls: ['./what-customers-say.component.scss']
})
export class WhatCustomersSayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
