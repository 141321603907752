import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment = require('moment');
import { ActionReducer } from 'ngx-bootstrap/mini-ngrx';
import { ToastrService } from 'ngx-toastr';
import { AddressPopupComponent } from 'src/app/components/address-popup/address-popup.component';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-schedule-popup',
  templateUrl: './schedule-popup.component.html',
  styleUrls: ['./schedule-popup.component.scss']
})
export class SchedulePopupComponent implements OnInit {
  @Input()serviceDetails: any;
  @Output() close  = new EventEmitter();
  @Input() showAddRecurring= false;
  @Input() selectBookAService = false;
  addressId: any;

  recurringForm!: any;
  date = new Date();
  addDaysToDate = () => {
    var res = new Date();
    res.setDate(res.getDate() + 15);
    return res;
  }
  minDate = { "year": this.date.getFullYear(), "month": this.date.getMonth() + 1, "day": this.date.getDate() };
  maxDate = { "year": this.addDaysToDate().getFullYear(), "month": this.addDaysToDate().getMonth() + 1, "day": this.addDaysToDate().getDate() };
  markDisabled: any;
  weekList: any;
  isRecurringIntervalAvail :any;
  selectedPlan: any;
  recuringIntervalList = [
    {
        name: "Monthly Plan",
        recurring_interval: "monthly"
    },
    {
        name: "Weekly Plan",
        recurring_interval: "weekly"
    },
    {
        name: "Daily Plan",
        recurring_interval: "daily"
    }
];
pop: any = {
  value: false
};
onChangeStaffSelection = () => {
  console.log('serviceDetails', this.serviceDetails)
  this.selectedStaff = this.recurringForm.get('staff').value;
  if(this.selectBookAService){
    this.getTimeSlots();
   }
}
fromState: any;
tempCartDetails: any;
holidayList: any;
staffList: any = [];
  selectedStaff: any;
  timeSlotsList: any;
  schedule_date: any;

  branchId: any;
  catId: any;
  providerId: any;
  branch_slug_title: any;
  provider_slug_title: any;
  disabledList: any = {
    disableWeeks: [],
    disabledDates: []
  };

  
  constructor(public dataService: DataService,  
    public calendar: NgbCalendar,public storageService: StorageService, private activatedRoute: ActivatedRoute,
    private bs: BroadcastService, 
    private router: Router,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    console.log(this.serviceDetails)
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.fromState = params.fromState;
    });

    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.providerId = params.catId;
      this.provider_slug_title = params.title;
      this.branch_slug_title = params.branch_slug_title;
      this.branchId = +params.bId;
    });

    this.serviceDeliveryAddress = this.serviceDetails?.serviceprovider_branch?.address;

    this.recurringForm = new FormGroup({
      staff: new FormControl(null),
      preferredTime: new FormControl(null, [Validators.required]),
      startDate: new FormControl('', [Validators.required]),
      selectedRecurringInterval: new FormControl(null,[])
    });

    this.recurringForm?.get('startDate').valueChanges.subscribe(() => this.onBookingDateChange());
    this.recurringForm?.get('staff').valueChanges.subscribe(() => this.onChangeStaffSelection());

    if (this.serviceDetails.avail_at === 'both') {
      this.addressId = -1;
    } else if (this.serviceDetails.avail_at === 'center_only') {
      this.addressId  = this.serviceDetails?.serviceprovider_branch?.address.address_id;
    } 


    if(this.serviceDetails?.plans && this.serviceDetails.plans?.length > 0 && !this.selectBookAService){

      this.isRecurringIntervalAvail = this.serviceDetails.plans.filter((obj: any) => {
          return obj.recurring_interval === null 
      });

      if(this.isRecurringIntervalAvail && this.isRecurringIntervalAvail?.length > 0){
        this.recurringForm = new FormGroup({
          staff: new FormControl(null),
          preferredTime: new FormControl(null, [Validators.required]),
          startDate: new FormControl('', [Validators.required]),
          selectedRecurringInterval: new FormControl(null,[Validators.required])
        });
      }
     
          //  Time Slots
        const startTime = 0;
        const endTime = 24 * 60;
        const interval = 15;
        this.timeSlotsList = [];
        for (let time = startTime; time < endTime; time += interval) {
          const hours = Math.floor(time / 60);
          const minutes = time % 60;
          const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
          this.timeSlotsList.push(formattedTime);
        }
        console.log(this.timeSlotsList)
    }else{
      this.timeSlotsList =[];
    }

    this.getHolidayList();
    this.getStaffList();
    this.getAddresses();
    

    let cartItems: any = this.storageService.getItem('cartItems');
      cartItems = (cartItems != null && cartItems != undefined) ? JSON.parse(cartItems).results : [];

     if(cartItems?.length > 0 && this.fromState === 'cart'){
        for(let i = 0 ; i<cartItems?.length; i++){
          if(cartItems[i].service?.service_id === this.serviceDetails.service_id || 
            cartItems[i]?.plan?.service?.service_id === this.serviceDetails.service_id){
            this.showAddRecurring = true;
            this.schedule_date = cartItems[i].booking_date;
            this.selectBookAService = false;
            this.tempCartDetails = {...cartItems[i]}
            this.addressId = cartItems[i].address?.address_id;
            this.selectedPlan =  this.serviceDetails.plans.find((obj: any) => {
              if(obj.plan_id === cartItems[i]?.plan?.plan_id){
                return obj;
              }
          });
            if(this.serviceDetails?.plans && this.serviceDetails.plans?.length > 0){
              this.recurringForm.setValue({
                staff: cartItems[i]?.staff?.employee_id || '',
                preferredTime: cartItems[i]?.booking_time,
                startDate: { "year": new Date(cartItems[i].booking_date).getFullYear(), "month": new Date(cartItems[i].booking_date).getMonth() + 1, "day": new Date(cartItems[i].booking_date).getDate() },
                selectedRecurringInterval: this.isRecurringIntervalAvail && this.isRecurringIntervalAvail?.length > 0 ? this.selectedPlan.recurring_service_plan : null
              });
            }else{
              this.selectBookAService = true;
              this.recurringForm.setValue({
                staff: cartItems[i]?.staff?.employee_id || '',
                preferredTime: cartItems[i]?.booking_time,
                startDate: { "year": new Date(cartItems[i].booking_date).getFullYear(), "month": new Date(cartItems[i].booking_date).getMonth() + 1, "day": new Date(cartItems[i].booking_date).getDate() },
                selectedRecurringInterval: ''
              });
            }
          }
        }
     }

   
  }

  
  onBookingDateChange = () => {
    let schedule_date = this.recurringForm.get('startDate').value;
   
    let year = schedule_date.year;
    let month = schedule_date.month <= 9 ? '0' + schedule_date.month : schedule_date.month;
    let day = schedule_date.day <= 9 ? '0' + schedule_date.day : schedule_date.day;;
    let finalDate =  year + "-" +  month + "-" + day; // YYYY-MM-dd
    this.schedule_date = finalDate;
    if(this.selectBookAService){
     this.getTimeSlots();
    }
    
  }
  
  getTimeSlots = () => {
    this.timeSlotsList = [];
    console.log("Time Slots:", this.timeSlotsList)
    const reqObj = {
      branchId: this.serviceDetails?.serviceprovider_branch?.branch_id || this.branchId,
      bookingDate: this.schedule_date,
      staffId: this.selectedStaff === undefined ? 0 : this.selectedStaff
    }
    this.dataService.getAvailableTimeSlots(reqObj).subscribe((res: any) => {
      if (res) {
        const timeSlotsList = res.available_slots;

        let momentTimeSlots: any = [];
        let today = moment();
        if (today.format('YYYY-MM-DD') === this.schedule_date) {
          for (let timeObj of timeSlotsList) {
            let time = moment(timeObj, "hh:mm a").format('hh:mm a');
            let time1 = moment(time, "hh:mm a").format('HH');
            if (this.checkTime(time1)) {
              momentTimeSlots.push(time);
            }
          }
          this.timeSlotsList = momentTimeSlots
        } else {
          this.timeSlotsList = res.available_slots
        }

      }
    })

  }

  
  checkTime = (a: any) => {
    const now = moment();
    const hourToCheck = +a;
    const dateToCheck = now.hour(hourToCheck).minute(30);
    return moment().isBefore(dateToCheck);
  }

  getStaffList = () => {
    console.log("Staff list:", this.getStaffList);
    const req = {
      serviceId: this.serviceDetails.service_id,
      branchId: this.serviceDetails?.serviceprovider_branch?.branch_id || this.branchId
    }
    let apiName :any;
    if(this.selectBookAService){
      apiName = this.dataService.getStaffDetails(req);
     }else{
      apiName =  this.dataService.getStaffList(req);
     }
    apiName.subscribe((res: any) => {
      if (res) {
        this.staffList = res.results;
        console.log("Staff List:", this.staffList)
      }
    });
  }

  getWeekSchedule = () => {
    const req = {
      branchId: this.serviceDetails?.serviceprovider_branch?.branch_id || this.branchId
    }
    this.dataService.getWeekSchedule(req).subscribe((res: any) => {
      if (res) {
        this.weekList = res.week_schedule;
        this.disabledList.disabledDates = [];
        this.disabledList.disableWeeks = [];
        if (!this.weekList.Monday.isOpen) {
          this.disabledList.disableWeeks.push(1);
        }
        if (!this.weekList.Tuesday.isOpen) {
          this.disabledList.disableWeeks.push(2);
        }
        if (!this.weekList.Wednesday.isOpen) {
          this.disabledList.disableWeeks.push(3);
        }
        if (!this.weekList.Thursday.isOpen) {
          this.disabledList.disableWeeks.push(4);
        }
        if (!this.weekList.Friday.isOpen) {
          this.disabledList.disableWeeks.push(5);
        }
        if (!this.weekList.Saturday.isOpen) {
          this.disabledList.disableWeeks.push(6);
        } 
        if (!this.weekList.Sunday.isOpen) {
          this.disabledList.disableWeeks.push(7);
        }
        
        for(let i =0 ;i <this.holidayList?.length; i++){
          const disabledDate: any =new Date(this.holidayList[i].date) ;
          this.disabledList.disabledDates.push( { year: disabledDate.getFullYear(), month: disabledDate.getMonth() + 1, day: disabledDate.getDate() })
        }

        this.markDisabled = (
          date: NgbDateStruct
          //current: { day: number; month: number; year: number }
        ) => {
          return this.disabledList.disabledDates.find(
            (x: any) =>
              new NgbDate(x.year, x.month, x.day).equals(date) ||
              this.disabledList.disableWeeks.includes(
                this.calendar.getWeekday(new NgbDate(date.year, date.month, date.day))
              )
          )
            ? true
            : false;
        };
      }
    });
  }

   
   getHolidayList = () => {
    this.dataService.getHolidayList({ branchId: this.serviceDetails?.serviceprovider_branch?.branch_id || this.branchId }).subscribe((res: any) => {
      if (res && res.error === undefined) {
        this.holidayList = res.results;
        this.getWeekSchedule();
      }
    });
  }

  showItemCount = () => {
    if (this.recurringForm.valid) {
      if(this.addressId === -1){
        Swal.fire({
          text: 'Please select one address',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        });
        return;
      }
      let providerId: any = this.serviceDetails?.service_provider?.slug_legal_name || this.provider_slug_title;
      let branchId: any = this.serviceDetails?.serviceprovider_branch?.branch_id || this.branchId;
      let providerName: any = this.serviceDetails?.service_provider?.legal_name || this.provider_slug_title;
      let isServiceAdded = true;
      let isPlanAdded = true;
      let cartItems: any = this.storageService.getItem('cartItems');
      let exitingProviderName = '';
      let msg = "You are allowed to subscribe one service at a time. DO you want to discard the selection and add plan";

      cartItems = cartItems !== null && cartItems !== undefined ? JSON.parse(cartItems).results : [];
      for (var i = 0; i < cartItems.length; i++) {
        branchId = branchId === 0 ? +'1' : branchId;
        let cartItem = cartItems[i];
        if (cartItem.service && (cartItem.service.service_provider?.slug_legal_name != providerId ||
          cartItem.service.serviceprovider_branch.branch_id != branchId)) {
          exitingProviderName = cartItem.service.service_provider?.legal_name;
          msg = `Your cart contains services from "${exitingProviderName}". Do you want to discard the selection and add services from "${providerName}" ?`
            isServiceAdded = false;
        }else if (cartItem.service && this.selectedPlan) {
          isServiceAdded = false;
        } else if (cartItem.service === null && cartItem.plan) {
          isPlanAdded = false;
        }
        
      }
      if (isServiceAdded && isPlanAdded) {
        if(this.fromState === 'cart' && this.tempCartDetails !== undefined && this.tempCartDetails !== null && this.tempCartDetails !== ''){
          console.log(this.tempCartDetails)
          const itemObj = {
            quantity: 0,
            service: this.tempCartDetails.service.service_id,
            address: this.tempCartDetails?.address?.address_id  || null,
            customer: JSON.parse(<string>this.storageService.getItem("user_info")).customer_id,
            plan: this.tempCartDetails.plan,
            booking_time: this.tempCartDetails.booking_time,
            booking_date: this.tempCartDetails.booking_date,
            staff: this.tempCartDetails?.staff?.employee_id || null
          };
          this.dataService.addToCart(itemObj).subscribe((data: any) => {});
        }
        this.addToCart();
      } else {
        Swal.fire({
          title: 'Replace cart item?',
          text: msg,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((res: any) => {
          if (res.isConfirmed) {
            this.deleteTotalCart();
          }
        });
      }
    }else{
      Swal.fire({
        text: 'Please fill mandatory fields',
        icon: 'error',
        showCancelButton: false,
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    }

  }

  addToCart = () => {
    debugger;
    // const tempList: any = [];
    // if (!this.selectedPlan && this.serviceDetails.plans?.length > 0) {
    //   console.error('No plan found for the selected recurring interval');
    //   return;
    // }

    const selectedRecurringInterval = this.recurringForm.value.selectedRecurringInterval;
    // this.selectedPlan = this.serviceDetails.plans.find((plan: any) => plan.plan_id === selectedRecurringInterval);

    const itemObj = {
      // value: true,
      quantity: this.checkQuantity(),
      service: this.selectedPlan && !this.selectBookAService ? null : this.serviceDetails.service_id,
      address: this.addressId !== -1 && this.addressId  ?  this.addressId : null,
      customer: JSON.parse(<string>this.storageService.getItem("user_info")).customer_id,
      plan: this.selectedPlan && !this.selectBookAService ? this.selectedPlan.plan_id : null,
      booking_time: this.recurringForm.value.preferredTime,
      booking_date: this.schedule_date,
      staff: this.recurringForm.value.staff || null
    };


    this.dataService.addToCart(itemObj).subscribe((data: any) => {
      if (data) {
        this.toastr.success('service added to cart.');
        this.serviceDetails.quantity = this.serviceDetails.quantity === undefined ? 1 : this.serviceDetails.quantity + 1;
        this.serviceDetails.isSelected = true;
        // this.bs.broadcast("SHOW_BOTTOM_CART", 'pop');
        this.dataService.cartItems().subscribe(() => {
          this.bs.broadcast('REFRESH_CART', 'count');
          this.refreshQuantiy();
          
          // let selectedPlan:any;
          // let selectedPlanFromStorage: any = this.storageService.getItem('selectedPlan');
          // selectedPlanFromStorage = JSON.parse(selectedPlanFromStorage);
 
          // if(selectedPlanFromStorage !== undefined  && selectedPlanFromStorage !== null && selectedPlanFromStorage !== ''){
          //    if(selectedPlanFromStorage.serviceId === this.serviceDetails.service_id){
          //     this.storageService.removeItem('selectedPlan');
          //    }
          //   }
          // if (this.isRecurringIntervalAvail && this.isRecurringIntervalAvail?.length > 0) {
          //    const selectedRecurringInterval = this.recurringForm.value.selectedRecurringInterval;
          //    selectedPlan = this.serviceDetails.plans.find((plan: any) => plan.plan_id === selectedRecurringInterval);
          // } else {
          //   selectedPlan = this.selectedPlan;
          // }
          //   this.storageService.setItem('selectedPlan', JSON.stringify({
          //     lead_staff : this.recurringForm.value.staff || null,
          //     schedule_start_datetime : this.schedule_date,
          //     preferred_time : this.recurringForm.value.preferredTime || null,
          //     recurring_service_plan: selectedPlan?.plan_id,
          //     serviceId: this.serviceDetails.service_id
          //   }));
              this.router.navigate(['/cart']);
        });
       



        // tempList.push({
        //   serviceName: this.serviceDetails.title,
        //   quantity: this.serviceDetails.quantity,
        //   price: this.serviceDetails.min_charge
        // });
        // this.pop = {
        //   selectedList: tempList,
        //   value: true
        // };
        this.cdr.detectChanges();
        // $('#test' + this.serviceDetails.service_id).removeClass('show');
        // $('#test' + this.serviceDetails.service_id).modal('hide');
        // this.showServicesPopup = false;
        // this.showBranchModalPopup = false;
        // this.showHomeModalPopup = false;
        // this.showAddAddress = false;
        // $('.modal-backdrop').remove();
        // $('#test' + this.serviceDetails.service_id).modal('hide');


      }
    });
  }


  deleteTotalCart = () => {
    this.dataService.deleteTotalCart().subscribe((data: any) => {
      if (data) {
        this.storageService.removeItem('cartItems');
        this.bs.broadcast('REFRESH_CART', 'count');
        this.addToCart();
      }
    })
  }

  checkQuantity = () => {
    let val = 1;
    let cartItems: any = this.storageService.getItem('cartItems');
    cartItems = cartItems !== null && cartItems !== undefined ? JSON.parse(cartItems).results : [];
    for (var i = 0; i < cartItems.length; i++) {
      if (cartItems[i]?.booking_time === this.recurringForm.value.preferredTime &&
        cartItems[i]?.booking_date === this.schedule_date && 
        cartItems[i]?.address?.address_id === this.addressId && 
        cartItems[i]?.staff?.employee_id === this.recurringForm.value.staff  ) {
        val = cartItems[i]?.quantity + 1;
        return val;
      }
    }
    return val;
  }

  refreshQuantiy = () => {
    // added quantity count
    const tempList: any = [];
    let cartItems: any = this.storageService.getItem('cartItems');
    cartItems = cartItems !== null && cartItems !== undefined ? JSON.parse(cartItems).results : [];
    if (cartItems.length !== 0) {
      let count = 0;
      cartItems.forEach((data: any) => {
        if (data.service) {
          if (data?.service?.service_id === this.serviceDetails?.service_id) {
            count = count + data?.quantity;
            this.serviceDetails.quantities = count;
            this.serviceDetails.quantity = data?.quantity;
            tempList.push({
              serviceName: data?.service?.title,
              quantity: data?.quantity,
              price: data?.service?.min_charge,
            });
          }
        }

        if (this.serviceDetails.quantity) {
          this.pop = {
            selectedList: tempList,
            value: true,
            serviceName: this.serviceDetails?.title
          }
        }
      });
    } else {
      this.serviceDetails.quantity = '';
    }

    this.serviceDetails.isSelected = false;
    if (this.serviceDetails.quantity) {
      this.serviceDetails.isSelected = true;
    }
  }

  buildAddress(address: any) {
    let result: string = '';
    if (address !== null && address !== undefined && address !== '') {
      if (address.address1) {
        result = address.address1 + ", ";
      }

      if (address.address2) {
        result += address.address2 + ", ";
      }

      if (address.land_mark) {
        result += address.land_mark + ", ";
      }

      if (address.post_code) {
        result += address.post_code + ", ";
      }

      if (address.state) {
        result += address.state + ", ";
      }

      if (address.city) {
        result += address.city + ", ";
      }

      if (address.country) {
        result += address.country + ", ";
      }

      // Remove the last comma and space from the result
      if (result.length > 0) {
        result = result.slice(0, -2);
      }

      return result;
    }
    return
  }

  showCustomerAddressList = false;
  addresses: any;
  serviceDeliveryAddress: any;
  selectedAddress: any = null;
  isServiceAddressSelected: any;

  openAddressPopup = () => {
    this.showCustomerAddressList = true;
    this.getAddresses();
  }

  closeCustomerAddressBar() {
    this.showCustomerAddressList = false;
  }

  editAddress(address?: any) {
    this.showCustomerAddressList = false;
    this.showAddRecurring = false;
    const modalRef = this.modalService.open(AddressPopupComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    modalRef.componentInstance.addressObj = { address: address?.address, fromState: address ? 'Edit' : 'new ' };

    modalRef.result.then((result) => {
      if (result) {
        this.getAddresses();
        this.showCustomerAddressList = true;
        this.showAddRecurring = true;
      }
    });
  }

  getAddresses() {
    const userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
    this.dataService.getCustomerAddressList(userDetails?.customer_id).subscribe((resp: any) => {
      this.addresses = resp.address;
      if(this.serviceDetails.avail_at === 'home_only' ){
        let tempAddressList = this.addresses.find((obj: any) => {
          if (obj?.address && obj?.address?.is_default) {
            return obj
          }
        });
        this.addressId  = tempAddressList?.address?.address_id;
      }
      
    });
  }

  // addAddressToCartObj = (addressObj: any) => {
  //   let cartItems: any = this.storageService.getItem('cartItems');
  //   cartItems = JSON.parse(cartItems);
  //   if (cartItems?.results?.length > 0) {
  //     cartItems?.results?.forEach((data: any) => {
  //       if (data) {
  //         data.address = addressObj;
  //       }
  //     })
  //   }
  //   this.storageService.setItem('cartItems', cartItems);
  // }
  onSelectDeliveryAddress(address: any) {
    if(address === 'address'){
      this.serviceDeliveryAddress = this.serviceDetails?.serviceprovider_branch?.address;
      this.addressId  = this.serviceDetails?.serviceprovider_branch?.address.address_id;
      this.isServiceAddressSelected = true;

    }else{
      this.serviceDeliveryAddress = address?.address;
      this.addressId  =address.address.address_id;
      this.isServiceAddressSelected = false;
      this.selectedAddress = address;
      // this.addAddressToCartObj(address)
    }
  }
  setAsDefault(address: any) {
    const payload = {
      new_address_id: address.address.address_id
    };

    this.dataService.setAsDefaultAddress(payload).subscribe(
      (response: any) => {
        if (response) {
          console.log('Address set as default:', response);

          const userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
          this.dataService.getCustomerAddressList(userDetails?.customer_id).subscribe((resp: any) => {
            this.addresses = resp.address;


            Swal.fire({
              text: 'Address has been successfully set as the default.',
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then((res: any) => {
                if(res){
                  this.showCustomerAddressList = false;
                }
            });
          });
        }
      },
      (error: any) => {
        console.error('Error setting address as default:', error);

        Swal.fire({
          text: 'Error setting address as default.',
          icon: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        });
      }
    );
  }

  deleteAddress = (address: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to delete your address",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        this.dataService.deleteCustomerAddress(address.address.address_id).subscribe((resp: any) => {
          if (resp.success) {
            Swal.fire({
              text: resp.status,
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then((res: any) => {
              if (res.isConfirmed) {
                this.getAddresses();
              }
            });
          } else {
            Swal.fire({
              text: resp.error,
              icon: 'error',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            });
          }
        });
      }
    });
  }


  closePopup = () => {
    this.showAddRecurring = false;
    this.close.emit();
   }

}
