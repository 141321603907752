<app-tutor-header></app-tutor-header>

<div class="container-fluid main-page-wrapper">
  <div class="profile-main-wrapper">
    <div class="profile-left-wrapper">
      <iframe src="https://www.youtube.com/embed/2stZ_dlD1Tw?rel=0" class="utube-wrap"></iframe>
      <div class="profile-first-wrapper">
        <div class="inner-item">
          <div class="tutor-img-wrapper">
            <img src="https://cdn.mytutor.co.uk/images/tutor-profiles/26009485.360_1-1_8.jpg?v=2" alt=""
              class="tutor-img">
            <div class="edit-profile-icon d-none">
              <img src="assets/icons/contact/edit-profile-icon.svg" alt="">
            </div>
          </div>
        </div>
        <div class="inner-item" id="edit-profile-wrapper">
          <div class="row">
            <div class="col-sm-9">
              <h2 class="tutor-name">Avinash Jain
                <a (click)="editProfile = true" class="edit-profile ml-1">
                  <span>Edit</span>
                </a>
              </h2>

              <p-sidebar [(visible)]="editProfile" position="right" [baseZIndex]="10000">
                <div class="row add-panel-close">
                  <div class="col-md-10 col-10">
                    <h5 class="add-student-heading">Edit Profile</h5>
                  </div>
                  <div class="col-md-2  col-2 text-right">
                    <a (click)="editProfile = false">
                      <img src="assets/icons/cross.svg" alt="">
                    </a>
                  </div>
                </div>

                <div class="edit-profile-main-wrapper mt-5">
                  <div class="row">
                    <div class="col-md-4 text-center col-4">
                      <div class="tutor-img-wrapper mt-2">
                        <img src="https://cdn.mytutor.co.uk/images/tutor-profiles/26009485.360_1-1_8.jpg?v=2" alt=""
                          class="tutor-img">
                        <div class="edit-profile-icon">
                          <img src="assets/icons/contact/edit-profile-icon.svg" alt="">
                        </div>
                      </div>
                    </div>

                    <div class="col-md-8 col-8">
                      <div class="col-md-12 col-12">
                        <div class="form-group">
                          <label for="">Name</label>
                          <input type="text" class="form-control" id="" placeholder="" value="Avinash Jain">
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Designation</label>
                          <input type="text" class="form-control" id="" placeholder=""
                            value="Management with Finance (Bachelors) - Warwick University">
                        </div>
                      </div>
                      <div class="col-md-12">

                        <div class="form-group">
                          <label for="">Youtube Demo Url</label>
                          <input type="number" class="form-control" id="" placeholder="Enter Demo Url">
                        </div>



                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">About me</label>
                        <textarea class="form-control" id="" rows="6">
                          Hello Everyone. My name is Andreas Mitllos and I was born in Cyprus.
                          It hasn't been too long since I graduated school, so I absolutely understand the struggle of students during these final years of their Secondary Education. I am able to help you further understand the subjects you are being taught in school, and together we will make sure that you become experts in your fields.
                        </textarea>
                        <p class="char-count text-right">0/500 characters</p>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Teaching and Tutor Experience</label>
                        <textarea class="form-control" id="" rows="4">
                          Throughout the first lessons, I try to understand the strong and weak areas of the students, and create an understanding of how to structure future lessons. Afterwards, the lesson plan is adapted according to this in order to properly improve skills.
                        </textarea>
                        <p class="char-count text-right">0/500 characters</p>
                      </div>

                      <div class="button-wrap-p">
                        <div class="">
                          <button class="p-btn-save"> Save</button>
                        </div>
                        <div class="">
                          <button class="p-cancel-btn" (click)="editProfile = false">Cancel</button>
                        </div>


                      </div>

                    </div>
                  </div>

                </div>

              </p-sidebar>


              <p class="tutor-designation mt-3">Management with Finance (Bachelors) - Warwick University</p>
              <div class="text-left">
                <ngx-star-rating formControlName="rating" [id]="'rating'"></ngx-star-rating>
                <span class="review-count">96 Reviews</span>
              </div>
              <div class="mt-3">
                <span><img src="assets/icons/school.svg" alt=""></span>
                <span class="t-school mt-3">Trusted by schools</span>
              </div>
              <div class="mt-3">
                <span class="c-lessions">58</span>
                <span class="c-lessions-text">completed lessons</span>
              </div>
            </div>
            <div class="col-sm-3 text-right">
              <h4 class="tutor-fee">$28 - £30 /hr</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="tutor-about-wrapper">
        <div class="row">
          <div class="col-md-6">
            <h6>About me</h6>
          </div>
          <div class="col-md-6 text-right">
            <div class="save-profile-btn">
              <a href="#">
                <span><img src="assets/icons/bookmark.svg" alt=""></span>
                <span class="s-profile"> Save Profile</span>
              </a>
            </div>
          </div>
          <div class="col-md-12">
            <p class="mt-3">Hello Everyone. My name is Andreas Mitllos and I was born in Cyprus.</p>
            <p class="pt-1">It hasn't been too long since I graduated school, so I absolutely understand the struggle of
              students during these final years of their Secondary Education. I am able to help you further understand
              the subjects you are being taught in school, and together we will make sure that you become experts in
              your fields.</p>
            <p class="pt-1">I am able to assist you with GCSE or A-Level courses depending on what you are studying. I
              can solve any exercises you have questions with, I will provide you with additional questions as well as
              providing you with the correct intuition for solving exercises in your exams that look a little bit
              different than what you have previously seen.</p>
            <h6>Teaching and Tutor Experience</h6>
            <p class="pt-3">Every student is different, therefore every lesson must also be adapted to suit the needs of
              each of them.</p>
            <p class="pt-1">Throughout the first lessons, I try to understand the strong and weak areas of the students,
              and create an understanding of how to structure future lessons. Afterwards, the lesson plan is adapted
              according to this in order to properly improve skills.</p>
            <p class="pt-1">Additionally, its essential and crucial that students become familiar with proper
              examination style questions, which is why I mainly use them for practice throughout the lessons. I attempt
              to make all lessons engaging and interactive.</p>
          </div>

        </div>
      </div>

      <hr>
      <div class="card">

        <p-tabView>

          <!-- All Courses -->
          <div class="d-none">
            <p-tabPanel header="Courses">
              <div class="row">
                <div class="col-md-6">
                  <h6 class="t-heading-text pt-3 pb-3">One-On-One Courses</h6>
                </div>
                <div class="col-md-6" id="new-subject">
                  <div class="add-subject">
                    <a (click)="addOneOnOne = true" class="add-manually mb-5">Add Course</a>
                  </div>

                  <p-sidebar [(visible)]="addOneOnOne" position="right" [baseZIndex]="10000">


                    <div class="row add-panel-close">
                      <div class="col-md-10">
                        <h5 class="add-student-heading">Add Course</h5>
                      </div>
                      <div class="col-md-2 text-right">
                        <a (click)="addOneOnOne = false">
                          <img src="assets/icons/cross.svg" alt="">
                        </a>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Cource Title</label>
                          <input type="text" class="form-control" id="" placeholder="Ex:Java">
                        </div>
                      </div>



                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Course Grade</label>
                          <input type="text" class="form-control" id="" placeholder="">
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Price ($32)</label>
                          <input type="text" class="form-control" id="" placeholder="">
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Course Image</label>
                          <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" [expandable]="true"
                            (change)="onFileChange($event)">
                            <ngx-dropzone-label>
                              <div>
                                <img src="assets/icons/upload.svg" alt="">
                                <h6 class="mt-3
                    mb-2">Drop your image here.</h6>

                              </div>
                            </ngx-dropzone-label>
                            <ngx-dropzone-image-preview #nationalDropZone ngProjectAs="ngx-dropzone-preview" *ngFor="let f of
                fileList" [file]="f" [removable]="true" (removed)="onRemove(f)">
                            </ngx-dropzone-image-preview>
                          </div>

                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="#" class="label-text">Course Demo url</label>

                          <input type="text" class="form-control" placeholder="Enter vedio Url">
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <button class="btn btn-block" id="add-student-button">Save</button>
                        </div>
                      </div>

                    </div>
                  </p-sidebar>


                </div>
              </div>

              <div class="container">
                <div class="subjects-chips">
                  <div class="subject-chip-item" *ngFor="let item of subjectsList; let i = index;">
                    {{item.subject}}
                  </div>
                </div>
              </div>

              <div class="container">
                <div class="row mt-5">
                  <div class="col-md-6">
                    <h6 class="t-heading-text pt-3 pb-3">Group Courses</h6>
                  </div>
                  <div class="col-md-6" id="new-subject">


                  </div>

                </div>
                <div class="row">
                  <div class="col-md-5">
                    <img src="assets/icons/clock-ts.svg" class="icon-i" alt="">
                    <span class="time-w"> 5.50PM to 7.00PM(UK-Time)</span>
                  </div>
                  <div class="col-md-5">
                    <img src="assets/icons/calendar-day.svg" class="icon-i" alt="">
                    <span class="time-w">Mon 13th Feb - Fri 17th Feb</span>
                  </div>
                  <div class="col-md-5">
                    <img src="assets/icons/repeat-y.svg" class="icon-i" alt="">
                    <span class="time-w">5 sessions total</span>
                  </div>
                  <div class="col-md-5">
                    <img src="assets/icons/calendar-ts.svg" class="icon-i" alt="">
                    <span class="time-w">Daily</span>
                  </div>
                  <div class="col-md-5">
                    <i class="fa-light fa-stopwatch"></i>
                    <span class="time-w">7.5 hours of lessons</span>
                  </div>
                  <div class="col-md-5">
                    <img src="assets/icons/users-t.svg" class="icon-i" alt="">
                    <span class="time-w">6 students</span>
                  </div>
                </div>
              </div>
            </p-tabPanel>
          </div>
          <!-- All Courses -->

          <p-tabPanel header="Subjects offered">
            <div class="table-slide">
              <div class="row">
                <div class="col-md-6">
                  <h6 class="t-heading-text pt-3 pb-3">One-on-One Session</h6>
                </div>
                <div class="col-md-6" id="new-subject">
                  <div class="add-subject">
                    <a (click)="addNewSubject = true" class="add-manually">Add Subject</a>
                  </div>

                  <p-sidebar [(visible)]="addNewSubject" position="right" [baseZIndex]="10000">


                    <div class="row add-panel-close">
                      <div class="col-md-10">
                        <h5 class="add-student-heading">Add subject</h5>
                      </div>
                      <div class="col-md-2 text-right">
                        <a (click)="addNewSubject = false">
                          <img src="assets/icons/cross.svg" alt="">
                        </a>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Subject</label>
                          <input type="text" class="form-control" id="" placeholder="">
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Level</label>
                          <input type="text" class="form-control" id="" placeholder="">
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Price ( £ )</label>
                          <input type="text" class="form-control" id="" placeholder="">
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <button class="btn btn-block" id="add-student-button">Save</button>
                        </div>
                      </div>

                    </div>


                  </p-sidebar>

                </div>
              </div>

              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col" >Subject</th>
                    <th scope="col" >Levels</th>
                    <th scope="col" >Price</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td  data-label="Subject">Accounting</td>
                    <td  data-label="Levels">A-level </td>
                    <td data-label="Price">$42 /hr</td>
                    <td data-label="Actions">
                      <span class="status-icons">
                        <img src="assets/icons/edit-icon.svg" alt="" (click)="addNewSubject = true">
                      </span>
                      <span class="status-icons pl-3">
                        <img src="assets/icons/delete-icon.svg" (click)="deleteSubject()" alt="" class="del-icon">
                      </span>
                    </td>
                  </tr>
                  <tr>



                    <td  data-label="Subject">Maths</td>
                    <td  data-label="Levels">A-level </td>
                    <td data-label="Price">$30 /hr</td>
                    <td data-label="Actions">
                
                      <span class="status-icons">
                        <img src="assets/icons/edit-icon.svg" alt="" (click)="addNewSubject = true">
                      </span>
                      <span class="status-icons pl-3">
                        <img src="assets/icons/delete-icon.svg" (click)="deleteSubject()" alt=""class="del-icon">
                      </span>
                    </td>
                  </tr>
                  <tr>
            
                    <td  data-label="Subject">Science</td>
                    <td  data-label="Levels">A-level </td>
                    <td data-label="Price">$65/hr</td>
                    <td data-label="Actions">
              
                  
                      <span class="status-icons">
                        <img src="assets/icons/edit-icon.svg" alt="" (click)="addNewSubject = true">
                      </span>
                      <span class="status-icons pl-3">
                        <img src="assets/icons/delete-icon.svg" (click)="deleteSubject()" alt="" class="del-icon">
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>


              <!-- Group Classes -->
              <div class="row">
                <div class="col-md-6">
                  <h6 class="t-heading-text pt-3 pb-3">Group Sessions</h6>
                </div>
                <div class="col-md-6" id="new-qualification">
                  <div class="add-subject">
                    <a (click)="addNewSubject = true" class="add-manually">Add Subject</a>
                  </div>

                  <p-sidebar [(visible)]="addNewSubject" position="right" [baseZIndex]="10000">


                    <div class="row add-panel-close">
                      <div class="col-md-10">
                        <h5 class="add-student-heading">Add subject</h5>
                      </div>
                      <div class="col-md-2 text-right">
                        <a (click)="addNewSubject = false">
                          <img src="assets/icons/cross.svg" alt="">
                        </a>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Subject Title</label>
                          <input type="text" class="form-control" id="" placeholder="Ex:Java">
                        </div>
                      </div>



                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Subject Grade</label>
                          <!-- <input type="text" class="form-control" id="" placeholder=""> -->

                          <ng-select [items]="grades" bindLabel="name" [multple]="false"
                            [placeholder]="'select grade'"></ng-select>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Price ($32)</label>
                          <input type="text" class="form-control" id="" placeholder="Ex:10$">
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Course Image</label>
                          <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" [expandable]="true"
                            (change)="onFileChange($event)">
                            <ngx-dropzone-label>
                              <div>
                                <img src="assets/icons/upload.svg" alt="">
                                <h6 class="mt-3
                      mb-2">Drop your image here.</h6>

                              </div>
                            </ngx-dropzone-label>
                            <ngx-dropzone-image-preview #nationalDropZone ngProjectAs="ngx-dropzone-preview" *ngFor="let f of
                  fileList" [file]="f" [removable]="true" (removed)="onRemove(f)">
                            </ngx-dropzone-image-preview>
                          </div>

                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="#" class="label-text">Course Demo url</label>

                          <input type="text" class="form-control" placeholder="Enter vedio Url">
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <button class="btn btn-block" id="add-student-button">Save</button>
                        </div>
                      </div>



                    </div>


                  </p-sidebar>

                </div>
              </div>

              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Subject</th>
                    <th scope="col">Levels</th>
                    <th scope="col">Price</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Subject">Accounting</td>
                    <td data-label="Levels">A-level </td>
                    <td data-label="Price">$42 /hr</td>
                    <td data-label="Actions">
                      <span class="status-icons">
                        <img src="assets/icons/edit-icon.svg" alt="" (click)="addNewSubject = true">
                      </span>
                      <span class="status-icons pl-3">
                        <img src="assets/icons/delete-icon.svg" (click)="deleteSubject()" alt=""class="del-icon">
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Subject">Maths</td>
                    <td data-label="Levels">A-level </td>
                    <td data-label="Price">$30 /hr</td>
                    <td data-label="Actions">
                      <span class="status-icons">
                        <img src="assets/icons/edit-icon.svg" alt="" (click)="addNewSubject = true">
                      </span>
                      <span class="status-icons pl-3">
                        <img src="assets/icons/delete-icon.svg" (click)="deleteSubject()" alt=""class="del-icon">
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Subject">Science</td>
                    <td data-label="Levels">A-level </td>
                    <td data-label="Price">$65 /hr</td>
                    <td data-label="Actions">
                      <span class="status-icons">
                        <img src="assets/icons/edit-icon.svg" alt="" (click)="addNewSubject = true">
                      </span>
                      <span class="status-icons pl-3">
                        <img src="assets/icons/delete-icon.svg" (click)="deleteSubject()" alt=""class="del-icon">
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-md-5">
                  <img src="assets/icons/clock-ts.svg" class="icon-i" alt="">
                  <span class="time-w"> 5.50PM to 7.00PM(UK-Time)</span>
                </div>
                <div class="col-md-5">
                  <img src="assets/icons/calendar-day.svg" class="icon-i" alt="">
                  <span class="time-w">Mon 13th Feb - Fri 17th Feb</span>
                </div>
                <div class="col-md-5">
                  <img src="assets/icons/repeat-y.svg" class="icon-i" alt="">
                  <span class="time-w">5 sessions total</span>
                </div>
                <div class="col-md-5">
                  <img src="assets/icons/calendar-ts.svg" class="icon-i" alt="">
                  <span class="time-w">Daily</span>
                </div>
                <div class="col-md-5">
                  <i class="fa-light fa-stopwatch"></i>
                  <span class="time-w">7.5 hours of lessons</span>
                </div>
                <div class="col-md-5">
                  <img src="assets/icons/users-t.svg" class="icon-i" alt="">
                  <span class="time-w">6 students</span>
                </div>
              </div>
              <!-- Group Classes -->




            </div>
          </p-tabPanel>
          <p-tabPanel header="Availability">
            <h6 class="t-heading-text pt-3 pb-3">Availability</h6>
            <div class="avaliability-wrap">
            <table class="table table-bordered">
              <thead class="text-center">
                <tr>
                  <th scope="col">Time</th>
                  <th scope="col">Mon</th>
                  <th scope="col">Tue</th>
                  <th scope="col">Wed</th>
                  <th scope="col">Thu</th>
                  <th scope="col">Fri</th>
                  <th scope="col">Sat</th>
                  <th scope="col">Sun</th>
                </tr>
              </thead>
              <tbody class="text-center tutor-availability">
                <tr>
                  <th class="text-left">Pre 12pm</th>
                  <td data-label="Mon"><input type="checkbox" checked></td>
                  <td data-label="Tue"><input type="checkbox" checked></td>
                  <td data-label="Wed"> <input type="checkbox" checked></td>
                  <td data-label="Thu"><input type="checkbox" checked></td>
                  <td data-label="Fri"><input type="checkbox" checked></td>
                  <td data-label="Sat"><input type="checkbox"></td>
                  <td data-label="Sun"><input type="checkbox"></td>
                </tr>
                <tr>
                  <th  class="text-left"> 12 - 5pm</th>
                  <td data-label="Mon"><input type="checkbox" checked></td>
                  <td data-label="Tue"><input type="checkbox" checked></td>
                  <td data-label="Wed"><input type="checkbox" checked></td>
                  <td data-label="Thu"><input type="checkbox" checked></td>
                  <td data-label="Fri"><input type="checkbox" checked></td>
                  <td data-label="Sat"><input type="checkbox" checked></td>
                  <td data-label="Sun"><input type="checkbox" checked></td>
                </tr>
                <tr>
                  <th class="text-left"> After 5pm</th>
                  <td data-label="Mon"><input type="checkbox" checked></td>
                  <td data-label="Tue"><input type="checkbox" checked></td>
                  <td data-label="Wed"><input type="checkbox" checked></td>
                  <td data-label="Thu"><input type="checkbox" checked></td>
                  <td data-label="Fri"><input type="checkbox" checked></td>
                  <td data-label="Sat"><input type="checkbox"></td>
                  <td data-label="Sun"><input type="checkbox"></td>
                </tr>
              </tbody>
            </table>
          </div>
          </p-tabPanel>
          <p-tabPanel header="Qualifications">
            <div class="row">
              <div class="col-md-6">
                <h6 class="t-heading-text pt-3 pb-3">Qualifications</h6>
              </div>

              <div class="col-md-6">
                <div class="add-subject">
                  <a (click)="addQualification = true" class="add-manually mb-5">Add Qualification</a>
                </div>




              </div>
            </div>
            <div class="table-wrapper">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Qualification</th>
                    <th scope="col">Specialization</th>
                    <th scope="col">Acedamic Year</th>
                    <th scope="col">Upload</th>
                    <th scope="col">Grade</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Qualification">Bsc</td>
                    <td data-label="Specialization">Computer Science</td>
                    <td data-label="Acedamic Year">2015-2018</td>
                    <td class="file-Up" data-label="Upload"> <ngx-dropzone [config]="config" (change)="onSelect($event)" class="file-u"
                        [options]="{ dictDefaultMessage: 'Drag and drop files here or click to upload.' }">
                        <div class="dz-message" ngxDropzoneMessage>
                          <!-- <img src="assets/icons/upload.svg" alt=""> -->
                        </div>
                        <div *ngIf="selectedFile">
                          <p class="f-upload">{{ selectedFile.name }}</p>
                        </div>
                      </ngx-dropzone>
                    </td>
                    <td data-label="Grade">A*</td>
                    <td data-label="Status">
                      <span class="status-icons">
                        <img src="assets/icons/edit-icon.svg" alt="" (click)="addQualification = true">
                      </span>
                      <span class="status-icons pl-3">
                        <img src="assets/icons/delete-icon.svg" (click)="deleteQualification()" alt=""class="del-icon">
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Qualification">BBA</td>
                    <td data-label="Specialization">Marketing</td>
                    <td data-label="Acedamic Year">2015-2018</td>
                    <td class="file-Up" data-label="Upload"> <ngx-dropzone [config]="config" (change)="onSelect($event)" class="file-u"
                        [options]="{ dictDefaultMessage: 'Drag and drop files here or click to upload.' }">
                        <div class="dz-message" ngxDropzoneMessage>
                          <!-- <img src="assets/icons/upload.svg" alt=""> -->
                        </div>
                        <div *ngIf="selectedFile">
                          <p class="f-upload">{{ selectedFile.name }}</p>
                        </div>
                      </ngx-dropzone>
                    </td>
                    <td  data-label="Grade">A*</td>
                    <td data-label="Status">
                      <span class="status-icons">
                        <img src="assets/icons/edit-icon.svg" alt="" (click)="addQualification = true">
                      </span>
                      <span class="status-icons pl-3">
                        <img src="assets/icons/delete-icon.svg" (click)="deleteQualification()" alt="" class="del-icon">
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Qualification">BBA</td>
                    <td data-label="Specialization">Marketing</td>
                    <td data-label="Acedamic Year">2015-2018</td>
                    <td class="file-Up" data-label="Upload"> <ngx-dropzone [config]="config" (change)="onSelect($event)" class="file-u"
                        [options]="{ dictDefaultMessage: 'Drag and drop files here or click to upload.' }">
                        <div class="dz-message" ngxDropzoneMessage>
                          <!-- <img src="assets/icons/upload.svg" alt=""> -->
                        </div>
                        <div *ngIf="selectedFile">
                          <p class="f-upload">{{ selectedFile.name }}</p>
                        </div>
                      </ngx-dropzone>
                    </td>
                    <td  data-label="Grade">A*</td>
                    <td data-label="Status">
                      <span class="status-icons">
                        <img src="assets/icons/edit-icon.svg" alt="" (click)="addQualification = true">
                      </span>
                      <span class="status-icons pl-3">
                        <img src="assets/icons/delete-icon.svg" (click)="deleteQualification()" alt="" class="del-icon">
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-md-6">
                <h6 class="t-heading-text pt-3 pb-3">Tutor Additional Courses</h6>
              </div>
              <div class="col-md-6">
                <div class="add-subject">
                  <a (click)="addCourse = true" class="add-manually mb-5">Add Course</a>
                </div>


              </div>
            </div>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Course</th>

                  <th scope="col">Year</th>
                  <th scope="col">Upload File</th>

                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Course">Accenflair</td>


                  <td data-label="Year">2022</td>
                  <td class="file-Up" data-label="Upload File"> <ngx-dropzone [config]="config" (change)="onSelect($event)" class="file-u"
                      [options]="{ dictDefaultMessage: 'Drag and drop files here or click to upload.' }">
                      <div class="dz-message" ngxDropzoneMessage>
                        <!-- <img src="assets/icons/upload.svg" alt=""> -->
                      </div>
                      <div *ngIf="selectedFile">
                        <p class="f-upload">{{ selectedFile.name }}</p>
                      </div>
                    </ngx-dropzone>
                  </td>
                  <td data-label="Actions">
                    <span class="status-icons">
                      <img src="assets/icons/edit-icon.svg" alt="" (click)="addCourse = true">
                    </span>
                    <span class="status-icons pl-3">
                      <img src="assets/icons/delete-icon.svg" (click)="deleteQualification()" alt="" class="del-icon">
                    </span>
                  </td>
                </tr>
                <tr>
                  <td data-label="Course">Google</td>

                  <td data-label="Year">2015-2018</td>
                  <td class="file-Up" data-label="Upload File"> <ngx-dropzone [config]="config" (change)="onSelect($event)" class="file-u"
                      [options]="{ dictDefaultMessage: 'Drag and drop files here or click to upload.' }">
                      <div class="dz-message" ngxDropzoneMessage>
                        <!-- <img src="assets/icons/upload.svg" alt=""> -->
                      </div>
                      <div *ngIf="selectedFile">
                        <p class="f-upload">{{ selectedFile.name }}</p>
                      </div>
                    </ngx-dropzone>
                  </td>

                  <td data-label="Actions">
                    <span class="status-icons">
                      <img src="assets/icons/edit-icon.svg" alt="" (click)="addQualification = true">
                    </span>
                    <span class="status-icons pl-3">
                      <img src="assets/icons/delete-icon.svg" (click)="deleteQualification()" alt="" class="del-icon">
                    </span>
                  </td>
                </tr>
                <tr>
                  <td data-label="Course">Accenflair</td>


                  <td data-label="Year">2022</td>
                  <td class="file-Up" data-label="Upload File"> <ngx-dropzone [config]="config" (change)="onSelect($event)" class="file-u"
                      [options]="{ dictDefaultMessage: 'Drag and drop files here or click to upload.' }">
                      <div class="dz-message" ngxDropzoneMessage>
                        <!-- <img src="assets/icons/upload.svg" alt=""> -->
                      </div>
                      <div *ngIf="selectedFile">
                        <p class="f-upload">{{ selectedFile.name }}</p>
                      </div>
                    </ngx-dropzone>
                  </td>
                  <td data-label="Actions">
                    <span class="status-icons">
                      <img src="assets/icons/edit-icon.svg" alt="" (click)="addCourse = true">
                    </span>
                    <span class="status-icons pl-3">
                      <img src="assets/icons/delete-icon.svg" (click)="deleteQualification()" alt="" class="del-icon">
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </p-tabPanel>
          <p-tabPanel header="Skills and Experience">
            <div class="row">
              <div class="col-md-6">
                <h6 class="t-heading-text pt-3 pb-3">Tutor Skills</h6>
              </div>
              <div class="col-md-6">
                <div class="add-subject">
                  <a (click)="addSkills = true" class="add-manually">Add Skills</a>
                </div>


              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="Skills-w">

                  Angular <span class="status-icons pl-3">
                    <img src="assets/icons/skill-x.svg" (click)="deleteSkill()" alt="" class="del-s">
                  </span></div>
              </div>
              <div class="col-md-3">
                <div class="Skills-w">

                  Angular <span class="status-icons pl-3">
                    <img src="assets/icons/skill-x.svg" (click)="deleteSkill()" alt="" class="del-s">
                  </span></div>
              </div>
              <!-- <div class="col-md-2">
                  <div class="Skills-w">
                    
            Power Apps <span class="status-icons pl-3">
                <img src="assets/icons/skill-x.svg" (click)="deleteQualification()" alt="">
              </span></div>
                </div> -->
              <div class="col-md-3">
                <div class="Skills-w">

                  Automation <span class="status-icons pl-3">
                    <img src="assets/icons/skill-x.svg" (click)="deleteSkill()" alt="" class="del-s">
                  </span></div>
              </div>
              <div class="col-md-3">
                <div class="Skills-w">

                  .Net <span class="status-icons pl-3">
                    <img src="assets/icons/skill-x.svg" (click)="deleteSkill()" alt="" class="del-s">
                  </span></div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <h6 class="t-heading-text pt-3 pb-3">Tutor Experience</h6>
              </div>
              <div class="col-md-6">
                <div class="add-subject">
                  <a (click)="addExperience = true" class="add-manually">Add Experience</a>
                </div>


              </div>
            </div>

            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Organization</th>
                  <th scope="col">Designation</th>
                  <th scope="col">Year</th>
                  <th scope="col">File</th>

                  <th  scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Organization">Accenflair</td>
                  <td data-label="Designation">Front End Developer</td>

                  <td data-label="Year">2022</td>
                  <td class="file-Up" data-label="File"> <ngx-dropzone [config]="config" (change)="onSelect($event)" class="file-u"
                      [options]="{ dictDefaultMessage: 'Drag and drop files here or click to upload.' }">
                      <div class="dz-message" ngxDropzoneMessage>
                        <!-- <img src="assets/icons/upload.svg" alt=""> -->
                      </div>
                      <div *ngIf="selectedFile">
                        <p>{{ selectedFile.name }}</p>
                      </div>
                    </ngx-dropzone>
                  </td>
                  <td data-label="Actions">
                    <span class="status-icons">
                      <img src="assets/icons/edit-icon.svg" alt="" (click)="addQualification = true">
                    </span>
                    <span class="status-icons pl-3">
                      <img src="assets/icons/delete-icon.svg" (click)="deleteQualification()" alt=""class="del-icon">
                    </span>
                  </td>
                </tr>
                <tr>
                  <td  data-label="Organization">BBA</td>
                  <td data-label="Designation">Marketing</td>
                  <td data-label="Year">2015-2018</td>
                  <td class="file-Up" data-label="File"> <ngx-dropzone [config]="config" (change)="onSelect($event)" class="file-u"
                      [options]="{ dictDefaultMessage: 'Drag and drop files here or click to upload.' }">
                      <div class="dz-message" ngxDropzoneMessage>
                        <!-- <img src="assets/icons/upload.svg" alt=""> -->
                      </div>
                      <div *ngIf="selectedFile">
                        <p class="f-upload">{{ selectedFile.name }}</p>
                      </div>
                    </ngx-dropzone>
                  </td>

                  <td data-label="Actions">
                    <span class="status-icons">
                      <img src="assets/icons/edit-icon.svg" alt="" (click)="addExperience = true">
                    </span>
                    <span class="status-icons pl-3">
                      <img src="assets/icons/delete-icon.svg" (click)="deleteQualification()" alt="" class="del-icon">
                    </span>
                  </td>
                </tr>
                <tr>
                  <td data-label="Organization">Engineering</td>
                  <td data-label="Designation">Mechanical Engineering</td>
                  <td data-label="Year">2012-2016</td>
                  <td class="file-Up" data-label="File"> <ngx-dropzone [config]="config" (change)="onSelect($event)" class="file-u"
                      [options]="{ dictDefaultMessage: 'Drag and drop files here or click to upload.' }">
                      <div class="dz-message" ngxDropzoneMessage>
                        <!-- <img src="assets/icons/upload.svg" alt=""> -->
                      </div>
                      <div *ngIf="selectedFile">
                        <p class="f-upload">{{ selectedFile.name }}</p>
                      </div>
                    </ngx-dropzone>
                  </td>

                  <td data-label="Actions">
                    <span class="status-icons">
                      <img src="assets/icons/edit-icon.svg" alt="" (click)="addQualification = true">
                    </span>
                    <span class="status-icons pl-3">
                      <img src="assets/icons/delete-icon.svg" (click)="deleteQualification()" alt="" class="del-icon">
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </p-tabPanel>

        </p-tabView>
      </div>


    </div>
    <div class="profile-right d-none">
      <h5 class="text-center mb-4">Send Avinash a message</h5>
      <div class="row bulb-section">
        <div class="col-sm-1">
          <div class="text-center"><img src="assets/icons/lightbulb.svg" alt=""></div>
        </div>
        <div class="col-sm-11">
          <p class="pl-3">A free meeting is a great next step. Just ask Andreas below!</p>
        </div>
      </div>
      <div class="col-md-12">
        <textarea name=""
          id="">Hi Andreas, I’m looking for a tutor. Are you available for a free meeting? I’d like to find out more about how you work. I’m looking forward to your reply!</textarea>

        <label for="" class="mt-2">Subject and level</label>

        <ng-select [items]="subjects" bindLabel="name" [multiple]="false" [(ngModel)]="selectedSubject">
        </ng-select>

        <div class="s-message">
          <button class="btn btn-block">Send a message</button>
        </div>

        <p class="e-responce text-center">Expected response time: 24h</p>

      </div>
    </div>

    <!-- After student login -->
    <div class="session">
      <div class="profile-right-after-login">
        <div class="g-in-touch text-center">
          <img src="assets/icons/contact/comment.svg" alt="">
        </div>
        <p class="touch-with-tutor text-center mt-4">
          Book the course with Avinash
        </p>
        <p class="above-btn-text">
          Have a chat with Avinash and see how (and when!) they can help.
        </p>

        <div class="get-touch-btn text-center">
          <button type="submit" class="btn btn-block" [routerLink]="['/book-lessons']">Book the Course</button>

        </div>

      </div>
      <div class="tutor-subject mt-4">
        <a [routerLink]="['/view-tutors']">More Maths A Level tutors</a>
      </div>
      <div class="tutor-subject">
        <a [routerLink]="['/view-tutors']">More Biology tutors</a>
      </div>
      <div class="tutor-subject">
        <a [routerLink]="['/view-tutors']">More Chemistry tutors</a>
      </div>



      <div class="book-course d-none">
        <p class="p-price">£135 <span class="lessons-H">For 7.5 hours of lessons</span></p>

        <p class="above-btn-text">
          Book now and we'll send you an email with links to the courses.
        </p>

        <!-- <div class="text-center"> -->
        <!-- <button type="submit"  (click)="addOneOnOne = true" class="book-btn" >Book Course</button> -->

        <div class="col-md-12" id="new-subject">
          <!-- <div class="add-subject">
                        <a (click)="addOneOnOne = true" class="add-manually mb-5">Book Group Course</a> -->
          <button (click)="addOneOnOne = true" class="buk-course-wrap">Book Group Course</button>
          <!-- </div> -->

          <p-sidebar [(visible)]="addOneOnOne" position="right" [baseZIndex]="10000">

            <div class="add-panel-close">

              <div class="row">

                <div class="col-md-12 text-right">
                  <a (click)="addOneOnOne = false">
                    <img src="assets/icons/cross.svg" alt="">
                  </a>
                </div>
              </div>

              <h2 class="card-heder">Cart</h2>




              <div class="cart-wrapper">
                <div>
                  <img src="https://cdn.mytutor.co.uk/images/tutor-profiles/26009485.360_1-1_8.jpg?v=2" alt=""
                    class="t-img">
                </div>
                <div>
                  <p class="tutor-a">A-Level Physics exam prep </p>
                  <p class="name-w">With Zaynah H</p>
                  <div>
                    <img src="assets/icons/clock-ts.svg" class="icon-i" alt="">
                    <span class="time-tutor">2:30pm - 4:00pm</span>
                  </div>
                  <div>
                    <img src="assets/icons/calendar-day.svg" class="icon-i" alt="">
                    <span class="time-tutor"> Mon 13th Feb - Fri 17th Feb</span>
                  </div>
                </div>

                <div>
                  <p class="group-class-p">£135.00</p>
                  <p class="remove-wrap">Remove</p>
                  <!-- <p *ngIf="showText">This is some text that will appear when the label is clicked.</p> -->
                </div>

              </div>
              <div class="footer">
                <div class="row ">
                  <div class="col-md-6">
                    <h2 class="card-heder">Total</h2>
                  </div>
                  <div class="col-md-6">
                    <p class="group-class">£135.00</p>
                  </div>
                </div>
                <div class="row">

                  <div class="col-md-6">
                    <button class="pay-cash-wrapper" [routerLink]="['/view-tutors']">View More Courses</button>
                  </div>
                  <div class="col-md-6">
                    <button class="pay-cash" [routerLink]="['/student-checkout']">Purchase</button>
                  </div>
                </div>
                <!-- <div class="col-md-12">
                            <div class="form-group">
                              <button class="btn btn-block" id="add-student-button">Save</button>
                            </div>
                          </div> -->

              </div>
            </div>
          </p-sidebar>

        </div>

      </div>

    </div>
    <!-- After student login -->

  </div>
  <!-- </div> -->



  <div id="new-qualification">

    <p-sidebar [(visible)]="addQualification" position="right" [baseZIndex]="10000">


      <div class="row add-panel-close">
        <div class="col-md-10">
          <h5 class="add-student-heading">Add Qualification</h5>
        </div>
        <div class="col-md-2 text-right">
          <a (click)="addQualification = false">
            <img src="assets/icons/cross.svg" alt="">
          </a>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-12">
          <div class="form-group">
            <label for="">Qualification Type</label>
            <input type="text" class="form-control" id="" placeholder="">
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="">Specialization</label>
            <input type="text" class="form-control" id="" placeholder="">
          </div>
        </div>

        <div class="col-md-6">
          <label for="">Star Year</label>
          <input type="date" class="form-control">

        </div>
        <div class="col-md-6">

          <label for="">End Year</label>
          <input type="date" class="form-control">
        </div>




        <div class="col-md-12 mt-3">

          <label for="">Grade</label>
          <ng-select bindLabel="name" [items]="grade" placeholder="'Enter the Grade"></ng-select>
        </div>
        <div class="col-md-12 mt-3 upload-drop">
          <label for="">Upload Document</label>
          <ngx-dropzone [config]="config" (change)="onSelect($event)" class="uplaod-ws"
            [options]="{ dictDefaultMessage: 'Drag and drop files here or click to upload.' }">
            <div class="dz-message" ngxDropzoneMessage>
              <img src="assets/icons/upload.svg" alt="" class="upload-w">
            </div>
            <div *ngIf="selectedFile">
              <p class="f-upload">{{ selectedFile.name }}</p>
            </div>
          </ngx-dropzone>
        </div>

        <div class="col-md-12 mt-5">
          <div class="form-group">
            <button class="btn btn-block" id="add-student-button">Save</button>
          </div>
        </div>

      </div>
    </p-sidebar>
  </div>
  <div id="new-skills">
    <p-sidebar [(visible)]="addSkills" position="right" [baseZIndex]="10000">


      <div class="row add-panel-close">
        <div class="col-md-10">
          <h5 class="add-student-heading">Add Skills</h5>
        </div>
        <div class="col-md-2 text-right">
          <a (click)="addSkills = false">
            <img src="assets/icons/cross.svg" alt="">
          </a>
        </div>
      </div>
      <div class="force-to-the-bottom">
        <tag-input [ngModel]="['hardocoded-item']">
          <tag-input-dropdown [autocompleteItems]="items" [showDropdownIfEmpty]="true" [dynamicUpdate]="false">
          </tag-input-dropdown>
        </tag-input>
      </div>

      <div class="col-md-12 mt-5">
        <div class="form-group">
          <button class="btn btn-block" id="add-student-button">Save</button>
        </div>
      </div>
    </p-sidebar>
  </div>
  <div id="new-skills">
    <p-sidebar [(visible)]="addCourse" position="right" [baseZIndex]="10000">


      <div class="row add-panel-close">
        <div class="col-md-10">
          <h5 class="add-student-heading">Add Course</h5>
        </div>
        <div class="col-md-2 text-right">
          <a (click)="addCourse = false">
            <img src="assets/icons/cross.svg" alt="">
          </a>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-12">
          <div class="form-group">
            <label for="">Title</label>
            <input type="text" class="form-control" id="" placeholder="Ex:Python">
          </div>
        </div>


        <div class="col-md-12">
          <label for=""> Year</label>
          <input type="date" class="form-control">

        </div>
        <div class="col-md-12 mt-3 upload-drop">
          <label for="">Upload Document</label>
          <ngx-dropzone [config]="config" (change)="onSelect($event)" class="uplaod-ws"
            [options]="{ dictDefaultMessage: 'Drag and drop files here or click to upload.' }">
            <div class="dz-message" ngxDropzoneMessage>
              <img src="assets/icons/upload.svg" alt="" class="upload-w">
            </div>
            <div *ngIf="selectedFile">
              <p class="f-upload"> {{ selectedFile.name }}</p>
            </div>
          </ngx-dropzone>
        </div>

        <div class="col-md-12 mt-5">
          <div class="form-group">
            <button class="btn btn-block" id="add-student-button">Save</button>
          </div>
        </div>

      </div>
    </p-sidebar>
  </div>
  <div id="new-skills">
    <p-sidebar [(visible)]="addExperience" position="right" [baseZIndex]="10000">


      <div class="row add-panel-close">
        <div class="col-md-10">
          <h5 class="add-student-heading">Add Experience</h5>
        </div>
        <div class="col-md-2 text-right">
          <a (click)="addExperience = false">
            <img src="assets/icons/cross.svg" alt="">
          </a>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-12">
          <div class="form-group">
            <label for="">Organization</label>
            <input type="text" class="form-control" id="" placeholder="Ex:Accenflair">
          </div>
        </div>


        <div class="col-md-12">
          <label for="">star Year</label>
          <input type="date" class="form-control">

        </div>

        <div class="col-md-12 mt-3">
          <label for="">End Year</label>
          <input type="date" class="form-control">

        </div>
        <div class="col-md-12 mt-3 upload-drop">
          <label for="">Upload Document</label>
          <ngx-dropzone [config]="config" (change)="onSelect($event)" class="uplaod-ws"
            [options]="{ dictDefaultMessage: 'Drag and drop files here or click to upload.' }">
            <div class="dz-message" ngxDropzoneMessage>
              <img src="assets/icons/upload.svg" alt="" class="upload-w">
            </div>
            <div *ngIf="selectedFile">
              <p class="f-upload">{{ selectedFile.name }}</p>
            </div>
          </ngx-dropzone>

        </div>

        <div class="col-md-12 mt-5">
          <div class="form-group">
            <button class="btn btn-block" id="add-student-button">Save</button>
          </div>
        </div>


      </div>
    </p-sidebar>
  </div>





