import {Injectable}      from '@angular/core'
import { Subject } from 'rxjs';

// Custom EventEmitter class implements Publisher & Subscriber Event Communication Model
// Use this emitter to share data between Angular Components without using @INPUT and @OUTPUT
// No need to decorate selector with events
@Injectable({
  providedIn: 'root'
})
export class CustomEventEmitter {

  // Observable Event Publisher
  // ONLY STRING MESSAGES CAN BE PUBLISHED
  private _eventPublisher = new Subject<string>(); 

  // Subscribing components need to use this object and subscribe for messages
  EventPublisher$ = this._eventPublisher.asObservable();
  constructor() {}

  // Method to publish Message to Subscrivers
  publish(message: string) {
    this._eventPublisher.next(message);
  }  
}