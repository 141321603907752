<div class="stay-home-container ">

  <div class="container">
    <div class="limited-border">
      <div class="header-titles" *ng=false>
        <div class="title-main">Stay Home, Stay Safe</div>
        <div class="sub-title">To ensure you stay safe, We also provide services like “Clean & Disinfect” which
          eliminates viruses like COVID-19 and other bacterias</div>
      </div>
      <div class="covid-info-img" *ng=false>
        <img src="assets/icons/stayhome/covid.PNG">
      </div>
      <div class="offers-container col-12 row">
        <div class="card-offers-limited col-sm-6 col-md-3 col-lg-3 col-12">
          <div class="img-block">
            <img src="assets/icons/stayhome/s-h-1.PNG">
          </div>
          <div class="title">Sanitise Surfaces</div>
          <div class="offer-info">Santise surfaces with our exclusive santising services</div>
          <div class="know-more-abt">Know more about this</div>
        </div>
        <div class="card-offers-limited col-sm-6 col-md-3 col-lg-3 col-12">
          <div class="img-block">
            <img src="assets/icons/stayhome/s-h-2.PNG">
          </div>
          <div class="title">Bathroom Cleaning</div>
          <div class="offer-info">Santise surfaces with our exclusive santising services</div>
          <div class="know-more-abt">Know more about this</div>
        </div>
        <div class="card-offers-limited col-sm-6 col-md-3 col-lg-3 col-12">
          <div class="img-block">
            <img src="assets/icons/stayhome/s-h-3.PNG">
          </div>
          <div class="title">Indoor Cleaning</div>
          <div class="offer-info">Santise surfaces with our exclusive santising services</div>
          <div class="know-more-abt">Know more about this</div>
        </div>
        <div class="card-offers-limited col-sm-6 col-md-3 col-lg-3 col-12">
          <div class="img-block">
            <img src="assets/icons/stayhome/s-h-4.PNG">
          </div>
          <div class="title">Upholstery Cleaning</div>
          <div class="offer-info">Santise surfaces with our exclusive santising services</div>
          <div class="know-more-abt">Know more about this</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="stay-home-wrapper container d-none">
  <div class="first-session-wrap">

    <div class="img-session-wrap">
      <div>
        <img src="assets/images/tony-img.png" alt="">
      </div>
      <div>
        <p class="tony-content-wrap">Tony & Guy</p>
        <p class="address-wrap">Ameerpet</p>
      </div>
      <div>
        <img src="assets/icons/blue-tick-icon.svg" alt="" class="tick-icon-wrap">
      </div>
    </div>
    <img src="assets/icons/rating-wrap.svg" alt="">
    <span class="rating-content"> 4.5k ratings</span>
    <div class="line-wrap mt-3"></div>
    <p class="description-wrap">Description of services they are providing to the users</p>
    <div class="line-wrap mt-3"></div>
    <!-- <div class="Background-wrap">
  <div>
<img src="assets/icons/protection.svg" alt=""><span class="checked-wrap">Background Checked</span>
  </div>
  <div>
   <img src="assets/icons/customer-review.svg" alt=""> <span class="checked-wrap">Hundred of Reviews</span>
  </div>
</div> -->
    <div class="row mt-3">
      <div class="col-md-1">
        <img src="assets/icons/protection.svg" alt="">
      </div>
      <div class="col-md-11">
        <p class="checked-wrap">Background Checked</p>
      </div>
    </div>


    <div class="row mt-1">
      <div class="col-md-1">
        <img src="assets/icons/customer-review.svg" alt="">
      </div>
      <div class="col-md-11">
        <p class="checked-wrap">Hundred of Reviews</p>
      </div>
    </div>
    <p class="know-content">Know exatly who’s comming and when!</p>
  </div>

  <div class="first-session-wrap">

    <div class="img-session-wrap">
      <div>
        <img src="assets/images/tony-img.png" alt="">
      </div>
      <div>
        <p class="tony-content-wrap">Tony & Guy</p>
        <p class="address-wrap">Ameerpet</p>
      </div>
      <div>
        <img src="assets/icons/blue-tick-icon.svg" alt="" class="tick-icon-wrap">
      </div>
    </div>
    <img src="assets/icons/rating-wrap.svg" alt="">
    <span class="rating-content"> 4.5k ratings</span>
    <div class="line-wrap mt-3"></div>
    <p class="description-wrap">Description of services they are providing to the users</p>
    <div class="line-wrap mt-3"></div>
    <!-- <div class="Background-wrap">
      <div>
    <img src="assets/icons/protection.svg" alt=""><span class="checked-wrap">Background Checked</span>
      </div>
      <div>
       <img src="assets/icons/customer-review.svg" alt=""> <span class="checked-wrap">Hundred of Reviews</span>
      </div>
    </div> -->
    <div class="row mt-3">
      <div class="col-md-1">
        <img src="assets/icons/protection.svg" alt="">
      </div>
      <div class="col-md-11">
        <p class="checked-wrap">Background Checked</p>
      </div>
    </div>


    <div class="row mt-1">
      <div class="col-md-1">
        <img src="assets/icons/customer-review.svg" alt="">
      </div>
      <div class="col-md-11">
        <p class="checked-wrap">Hundred of Reviews</p>
      </div>
    </div>
    <p class="know-content">Know exatly who’s comming and when!</p>
  </div>



  <div class="first-session-wrap">
    <div class="img-session-wrap">
      <div>
        <img src="assets/images/tony-img.png" alt="">
      </div>
      <div>
        <p class="tony-content-wrap">Tony & Guy</p>
        <p class="address-wrap">Ameerpet</p>
      </div>
      <div>
        <img src="assets/icons/blue-tick-icon.svg" alt="" class="tick-icon-wrap">
      </div>
    </div>
    <img src="assets/icons/rating-wrap.svg" alt="">
    <span class="rating-content"> 4.5k ratings</span>
    <div class="line-wrap mt-3"></div>
    <p class="description-wrap">Description of services they are providing to the users</p>
    <div class="line-wrap mt-3"></div>
    <!-- <div class="Background-wrap">
          <div>
        <img src="assets/icons/protection.svg" alt=""><span class="checked-wrap">Background Checked</span>
          </div>
          <div>
           <img src="assets/icons/customer-review.svg" alt=""> <span class="checked-wrap">Hundred of Reviews</span>
          </div>
        </div> -->
    <div class="row mt-3">
      <div class="col-md-1">
        <img src="assets/icons/protection.svg" alt="">
      </div>
      <div class="col-md-11">
        <p class="checked-wrap">Background Checked</p>
      </div>
    </div>


    <div class="row mt-1">
      <div class="col-md-1">
        <img src="assets/icons/customer-review.svg" alt="">
      </div>
      <div class="col-md-11">
        <p class="checked-wrap">Hundred of Reviews</p>
      </div>
    </div>
    <p class="know-content">Know exatly who’s comming and when!</p>
  </div>
</div>
<!----------------------------->





<div class="customer-reviews-container">
  <div class="container">
    <div class="inner-data-sec">



      <!-- Customer Carousel Wrapper -->
      <div id="multi-item-example" class="carousel slide carousel-multi-item" data-ride="carousel">

        <!-- Controls -->
        <div class="controls-top d-none">
          <a class="btn-floating" href="#multi-item-example" data-slide="prev"><i class="fa fa-chevron-left"></i></a>
          <a class="btn-floating" href="#multi-item-example" data-slide="next"><i class="fa fa-chevron-right"></i></a>
        </div>
        <!--/.Controls-->

        <!--Indicators-->
        <ol class="carousel-indicators">
          <li data-target="#multi-item-example" data-slide-to="0" class="active"></li>
          <li data-target="#multi-item-example" data-slide-to="1"></li>
          <li data-target="#multi-item-example" data-slide-to="2"></li>
        </ol>
        <!--/.Indicators-->

        <!--Slides-->
        <div class="carousel-inner" role="listbox">
          <!--First slide-->
          <div class="carousel-item active">

            <!-- <div class="row row-feedback"> -->
            <div class="stay-home-wrapper">



              <div class="first-session-wrap">

                <div class="img-session-wrap">
                  <div>
                    <img src="assets/images/tony-img.png" alt="">
                  </div>
                  <div>
                    <p class="tony-content-wrap">Tony & Guy</p>
                    <p class="address-wrap">Ameerpet</p>
                  </div>
                  <div>
                    <img src="assets/icons/blue-tick-icon.svg" alt="" class="tick-icon-wrap">
                  </div>
                </div>
                <img src="assets/icons/rating-wrap.svg" alt="">
                <span class="rating-content"> 4.5k ratings</span>
                <div class="line-wrap mt-3"></div>
                <p class="description-wrap">Description of services they are providing to the users</p>
                <div class="line-wrap mt-3"></div>

                <!-- <div class="row mt-3">
                    <div class="col-md-1 col-6">
                      <img src="assets/icons/protection.svg" alt="">
                    </div>
                    <div class="col-md-11">
                      <p class="checked-wrap">Background Checked</p>
                    </div>
                  </div> -->
                <div class="check-line-wrapper mt-3">
                  <div class="img">
                    <img src="assets/icons/protection.svg" alt="" class="check-img">
                  </div>
                  <div class="check">
                    <p class="checked-wrap">Background Checked</p>
                  </div>
                </div>

                <div class="check-line-wrapper mt-2">
                  <div class="img">
                    <img src="assets/icons/customer-review.svg" alt="" class="check-img">
                  </div>
                  <div class="check">
                    <p class="checked-wrap">Hundred of Reviews</p>
                  </div>
                </div>
                <p class="know-content">Know exatly who’s comming and when!</p>
              </div>

              <div class="clearfix d-none d-md-block first-session-wrap">

                <div class="img-session-wrap">
                  <div>
                    <img src="assets/images/tony-img.png" alt="">
                  </div>
                  <div>
                    <p class="tony-content-wrap">Tony & Guy</p>
                    <p class="address-wrap">Ameerpet</p>
                  </div>
                  <div>
                    <img src="assets/icons/blue-tick-icon.svg" alt="" class="tick-icon-wrap">
                  </div>
                </div>
                <img src="assets/icons/rating-wrap.svg" alt="">
                <span class="rating-content"> 4.5k ratings</span>
                <div class="line-wrap mt-3"></div>
                <p class="description-wrap">Description of services they are providing to the users</p>
                <div class="line-wrap mt-3"></div>

                <div class="check-line-wrapper mt-3">
                  <div class="img">
                    <img src="assets/icons/protection.svg" alt="" class="check-img">
                  </div>
                  <div class="check">
                    <p class="checked-wrap">Background Checked</p>
                  </div>
                </div>

                <div class="check-line-wrapper mt-2">
                  <div class="img">
                    <img src="assets/icons/customer-review.svg" alt="" class="check-img">
                  </div>
                  <div class="check">
                    <p class="checked-wrap">Hundred of Reviews</p>
                  </div>
                </div>
                <p class="know-content">Know exatly who’s comming and when!</p>
              </div>

              <div class="clearfix d-none d-md-block first-session-wrap">

                <div class="img-session-wrap">
                  <div>
                    <img src="assets/images/tony-img.png" alt="">
                  </div>
                  <div>
                    <p class="tony-content-wrap">Tony & Guy</p>
                    <p class="address-wrap">Ameerpet</p>
                  </div>
                  <div>
                    <img src="assets/icons/blue-tick-icon.svg" alt="" class="tick-icon-wrap">
                  </div>
                </div>
                <img src="assets/icons/rating-wrap.svg" alt="">
                <span class="rating-content"> 4.5k ratings</span>
                <div class="line-wrap mt-3"></div>
                <p class="description-wrap">Description of services they are providing to the users</p>
                <div class="line-wrap mt-3"></div>

                <div class="check-line-wrapper mt-3">
                  <div class="img">
                    <img src="assets/icons/protection.svg" alt="" class="check-img">
                  </div>
                  <div class="check">
                    <p class="checked-wrap">Background Checked</p>
                  </div>
                </div>

                <div class="check-line-wrapper mt-2">
                  <div class="img">
                    <img src="assets/icons/customer-review.svg" alt="" class="check-img">
                  </div>
                  <div class="check">
                    <p class="checked-wrap">Hundred of Reviews</p>
                  </div>
                </div>
                <p class="know-content">Know exatly who’s comming and when!</p>
              </div>
            </div>

          </div>

          <!--/.First slide-->







          <!--Second slide-->
          <div class="carousel-item">

            <div class="stay-home-wrapper">



              <div class="first-session-wrap">

                <div class="img-session-wrap">
                  <div>
                    <img src="assets/images/tony-img.png" alt="">
                  </div>
                  <div>
                    <p class="tony-content-wrap">Tony & Guy</p>
                    <p class="address-wrap">Ameerpet</p>
                  </div>
                  <div>
                    <img src="assets/icons/blue-tick-icon.svg" alt="" class="tick-icon-wrap">
                  </div>
                </div>
                <img src="assets/icons/rating-wrap.svg" alt="">
                <span class="rating-content"> 4.5k ratings</span>
                <div class="line-wrap mt-3"></div>
                <p class="description-wrap">Description of services they are providing to the users</p>
                <div class="line-wrap mt-3"></div>

                <div class="check-line-wrapper mt-3">
                  <div class="img">
                    <img src="assets/icons/protection.svg" alt="" class="check-img">
                  </div>
                  <div class="check">
                    <p class="checked-wrap">Background Checked</p>
                  </div>
                </div>

                <div class="check-line-wrapper mt-2">
                  <div class="img">
                    <img src="assets/icons/customer-review.svg" alt="" class="check-img">
                  </div>
                  <div class="check">
                    <p class="checked-wrap">Hundred of Reviews</p>
                  </div>
                </div>
                <p class="know-content">Know exatly who’s comming and when!</p>
              </div>

              <div class="clearfix d-none d-md-block first-session-wrap">

                <div class="img-session-wrap">
                  <div>
                    <img src="assets/images/tony-img.png" alt="">
                  </div>
                  <div>
                    <p class="tony-content-wrap">Tony & Guy</p>
                    <p class="address-wrap">Ameerpet</p>
                  </div>
                  <div>
                    <img src="assets/icons/blue-tick-icon.svg" alt="" class="tick-icon-wrap">
                  </div>
                </div>
                <img src="assets/icons/rating-wrap.svg" alt="">
                <span class="rating-content"> 4.5k ratings</span>
                <div class="line-wrap mt-3"></div>
                <p class="description-wrap">Description of services they are providing to the users</p>
                <div class="line-wrap mt-3"></div>
                <div class="check-line-wrapper mt-3">
                  <div class="img">
                    <img src="assets/icons/protection.svg" alt="" class="check-img">
                  </div>
                  <div class="check">
                    <p class="checked-wrap">Background Checked</p>
                  </div>
                </div>

                <div class="check-line-wrapper mt-2">
                  <div class="img">
                    <img src="assets/icons/customer-review.svg" alt="" class="check-img">
                  </div>
                  <div class="check">
                    <p class="checked-wrap">Hundred of Reviews</p>
                  </div>
                </div>
                <p class="know-content">Know exatly who’s comming and when!</p>
              </div>

              <div class="clearfix d-none d-md-block first-session-wrap">

                <div class="img-session-wrap">
                  <div>
                    <img src="assets/images/tony-img.png" alt="">
                  </div>
                  <div>
                    <p class="tony-content-wrap">Tony & Guy</p>
                    <p class="address-wrap">Ameerpet</p>
                  </div>
                  <div>
                    <img src="assets/icons/blue-tick-icon.svg" alt="" class="tick-icon-wrap">
                  </div>
                </div>
                <img src="assets/icons/rating-wrap.svg" alt="">
                <span class="rating-content"> 4.5k ratings</span>
                <div class="line-wrap mt-3"></div>
                <p class="description-wrap">Description of services they are providing to the users</p>
                <div class="line-wrap mt-3"></div>
                <div class="check-line-wrapper mt-3">
                  <div class="img">
                    <img src="assets/icons/protection.svg" alt="" class="check-img">
                  </div>
                  <div class="check">
                    <p class="checked-wrap">Background Checked</p>
                  </div>
                </div>

                <div class="check-line-wrapper mt-2">
                  <div class="img">
                    <img src="assets/icons/customer-review.svg" alt="" class="check-img">
                  </div>
                  <div class="check">
                    <p class="checked-wrap">Hundred of Reviews</p>
                  </div>
                </div>
                <p class="know-content">Know exatly who’s comming and when!</p>
              </div>
            </div>
          </div>
          <!--/.Second slide-->

          <!--Third slide-->
          <div class="carousel-item">
            <div class="stay-home-wrapper">



              <div class="first-session-wrap">

                <div class="img-session-wrap">
                  <div>
                    <img src="assets/images/tony-img.png" alt="">
                  </div>
                  <div>
                    <p class="tony-content-wrap">Tony & Guy</p>
                    <p class="address-wrap">Ameerpet</p>
                  </div>
                  <div>
                    <img src="assets/icons/blue-tick-icon.svg" alt="" class="tick-icon-wrap">
                  </div>
                </div>
                <img src="assets/icons/rating-wrap.svg" alt="">
                <span class="rating-content"> 4.5k ratings</span>
                <div class="line-wrap mt-3"></div>
                <p class="description-wrap">Description of services they are providing to the users</p>
                <div class="line-wrap mt-3"></div>

                <div class="check-line-wrapper mt-3">
                  <div class="img">
                    <img src="assets/icons/protection.svg" alt="" class="check-img">
                  </div>
                  <div class="check">
                    <p class="checked-wrap">Background Checked</p>
                  </div>
                </div>

                <div class="check-line-wrapper mt-2">
                  <div class="img">
                    <img src="assets/icons/customer-review.svg" alt="" class="check-img">
                  </div>
                  <div class="check">
                    <p class="checked-wrap">Hundred of Reviews</p>
                  </div>
                </div>
                <p class="know-content">Know exatly who’s comming and when!</p>
              </div>

              <div class="clearfix d-none d-md-block first-session-wrap">

                <div class="img-session-wrap">
                  <div>
                    <img src="assets/images/tony-img.png" alt="">
                  </div>
                  <div>
                    <p class="tony-content-wrap">Tony & Guy</p>
                    <p class="address-wrap">Ameerpet</p>
                  </div>
                  <div>
                    <img src="assets/icons/blue-tick-icon.svg" alt="" class="tick-icon-wrap">
                  </div>
                </div>
                <img src="assets/icons/rating-wrap.svg" alt="">
                <span class="rating-content"> 4.5k ratings</span>
                <div class="line-wrap mt-3"></div>
                <p class="description-wrap">Description of services they are providing to the users</p>
                <div class="line-wrap mt-3"></div>

                <div class="check-line-wrapper mt-3">
                  <div class="img">
                    <img src="assets/icons/protection.svg" alt="" class="check-img">
                  </div>
                  <div class="check">
                    <p class="checked-wrap">Background Checked</p>
                  </div>
                </div>

                <div class="check-line-wrapper mt-2">
                  <div class="img">
                    <img src="assets/icons/customer-review.svg" alt="" class="check-img">
                  </div>
                  <div class="check">
                    <p class="checked-wrap">Hundred of Reviews</p>
                  </div>
                </div>
                <p class="know-content">Know exatly who’s comming and when!</p>
              </div>

              <div class="clearfix d-none d-md-block first-session-wrap">

                <div class="img-session-wrap">
                  <div>
                    <img src="assets/images/tony-img.png" alt="">
                  </div>
                  <div>
                    <p class="tony-content-wrap">Tony & Guy</p>
                    <p class="address-wrap">Ameerpet</p>
                  </div>
                  <div>
                    <img src="assets/icons/blue-tick-icon.svg" alt="" class="tick-icon-wrap">
                  </div>
                </div>
                <img src="assets/icons/rating-wrap.svg" alt="">
                <span class="rating-content"> 4.5k ratings</span>
                <div class="line-wrap mt-3"></div>
                <p class="description-wrap">Description of services they are providing to the users</p>
                <div class="line-wrap mt-3"></div>

                <div class="check-line-wrapper mt-3">
                  <div class="img">
                    <img src="assets/icons/protection.svg" alt="" class="check-img">
                  </div>
                  <div class="check">
                    <p class="checked-wrap">Background Checked</p>
                  </div>
                </div>

                <div class="check-line-wrapper mt-2">
                  <div class="img">
                    <img src="assets/icons/customer-review.svg" alt="" class="check-img">
                  </div>
                  <div class="check">
                    <p class="checked-wrap">Hundred of Reviews</p>
                  </div>
                </div>
                <!--               
<div>
  <p-rating [(ngModel)]="val2" [cancel]="false"></p-rating>
 </div> -->
                <p class="know-content">Know exatly who’s comming and when!</p>
              </div>
            </div>
          </div>
          <!--/.Third slide-->
        </div>
        <!--/.Slides-->
      </div>
      <!--/.Carousel Wrapper-->

    </div>
  </div>
</div>