import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-personal-details',
  templateUrl: './student-personal-details.component.html',
  styleUrls: ['./student-personal-details.component.scss']
})
export class StudentPersonalDetailsComponent implements OnInit {
  selectedGender='select Gender';
  selectedBoard='Select Board'
  selectedEducation='select Education Level';
  selectedOption='select Subject';
  selectedEducationLevel='select Education Level';
  showPwdLabel = "password";
  items:any;
  placeholderText = 'Enter new Subject';
  
  constructor() { }
  updatePlaceholderText(newText: string) {
    this.placeholderText = newText;
  }
  ngOnInit(): void {
  }
  gender:any=[
    {name:'Male'},
    {name:'Female'},
    {name:'Others'}
  ]
  educationlevel:any=[
{name:'Primary'},
{name:'Secondary'},
{name:'Graduation'}
  ]
  examboard:any=[
    {name:'CBSE'},
    {name:'GCSE'}

  ]

  educationLevels = [
    { name: 'Primary', options: ['Social Maths'] },
    { name: 'Secondary', options: ['Science', 'Physics', 'Biology'] },
    { name: 'Graduation', options: ['C++', 'Java', 'Python'] }
  ];

  primaryOptions = [
    { name: 'Maths' },
    { name: 'Hindi' }
  ];
  
  secondaryOptions = [
    { name: 'Physics' },
    { name: 'Biology' }
  ];
  
  graduationOptions = [
    { name: 'C++' },
    { name: 'Java' },
    { name: 'Python' }
  ];
  isVisible = () => {
    this.showPwdLabel = this.showPwdLabel === 'password' ? 'text' : 'password';
  }

  
  get options() {
    switch (this.selectedEducationLevel) {
      case 'Primary':
        return this.primaryOptions;
      case 'Secondary':
        return this.secondaryOptions;
      case 'Graduation':
        return this.graduationOptions;
      default:
        return [];
    }
  }

  
}
