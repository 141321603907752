<div class="container mt-5 mb-5">
    <section id="bg-white">
        <section id="blueCard">
            <div class="blue-card">
                <h3 class="main-top-page text-center">We are ScheduleItNow</h3>
                <h4 class="main-top-title">Helping service providers to easily manage their diary and improve cash flow.</h4>
                <!-- <hr class="small-dash" /> -->
                <p class="mt-4">ScheduleItNow is the trading style of VenturesSky Ltd who since 2006 have helped 100's of restaurants, takeaways etc saving on commissions and improving efficiency and profits.
                </p>
                <p>Service providers like gardeners, window cleaners, cleaners etc still rely on pen and paper to manage their diary. 
                </p>
                <p>
                    This is inefficient when it comes to  managing the bookings,predicting income, and also collecting money.
      
                </p>
                <p>
                    With ScheduleItNow we help you to solve these problems. Manage your bookings easily through web and mobile and easily setup subscriptions to collect 
                    money automatically. At the press of a button know where you need to go next, how many bookings you have for the days and weeks ahead and plan your marketing 
                    activities
                </p>
               
            </div>
            <div class="unique-cards mt-5 mb-3">
                <h3 class="main-top-title">The uniqueness of ScheduleItNow</h3>
                <div class="card-hold">
                    <div class="acard mt-4">
                        <!-- <img src="assets/images/bird.png" alt=""> -->
                        <p>
                            <li>A leading UK-based total online home, office, and commercial solutions provider.</li>
                        </p>
                    </div>
                    <div class="acard">
                        <!-- <img src="assets/images/custom.png" alt=""> -->
                        <p>
                            <li>Dealing with a broad range of services.</li>
                        </p>
                    </div>
                    <div class="acard">
                        <!-- <img src="assets/images/coins.png" alt=""> -->
                        <p>
                            <li>Special concession for returning customers.</li>
                        </p>
                    </div>
                    <div class="acard">
                        <!-- <img src="assets/images/human-net.png" alt=""> -->
                        <p>
                            <li>We treat each client as a unique customer</li>
                        </p>
                    </div>
                    <div class="acard">
                        <!-- <img src="assets/images/price-tag.png" alt=""> -->
                        <p>
                            <li>Competitive pricing that suits your pocket.</li>
                        </p>
                    </div>
                    <div class="acard">
                        <!-- <img src="assets/images/man-hat.png" alt=""> -->
                        <p>
                            <li>Well qualified professionals & experts.</li>
                        </p>
                    </div>
                    <div class="acard">
                        <!-- <img src="assets/images/mouse.png" alt=""> -->
                        <p>
                            <li>One-stop-solution at your call or click.</li>
                        </p>
                    </div>
                    <div class="acard">
                        <!-- <img src="assets/images/settings-hnd.png" alt=""> -->
                        <p>
                            <li>Highly customized services offered as per your needs.</li>
                        </p>
                    </div>
                </div>
            </div>
            <div class="usps">
                <h3 class="main-top-title mt-5 mb-4">
                    Additional USPs
                </h3>
                <p><li>24/7 Customer Support</li></p>
                <p><li>Instant response to your needs</li></p>
                <p><li>Easy booking of services (Call or
                    Click)</li></p>
                <p><li>Easy Online Payment System</li></p>
                <p><li>Confidentiality Guarantee</li></p>
                <p><li>100% Satisfaction Guarantee</li></p>
                <!-- <img src="assets/images/bird.png" alt=""> &nbsp;&nbsp;<span> 24/7 Customer Support</span><br>
                <img src="assets/images/bird.png" alt=""> &nbsp;&nbsp;<span> Instant response to your needs</span><br>
                <img src="assets/images/bird.png" alt=""> &nbsp;&nbsp;<span> Easy booking of services (Call or
                    Click)</span><br>
                <img src="assets/images/bird.png" alt=""> &nbsp;&nbsp;<span> Easy Online Payment System</span><br>
                <img src="assets/images/bird.png" alt=""> &nbsp;&nbsp;<span> Confidentiality Guarantee</span><br>
                <img src="assets/images/bird.png" alt=""> &nbsp;&nbsp;<span> 100% Satisfaction Guarantee</span> -->
            </div>
        </section>
        <section id="blue">
            <div class="blue-card2">
                <div class="vmc">
      
                    <h3 class="main-top-title mt-5 mb-4">Our Vision</h3>
                    <p>Our vision is to become the leading brand as a home, office, and commercial service provider in the UK. We strive for facilitating a safe, secure, seamless home-stay for our clientele by offering cutting-edge home, office, and commercial
                        services that put in value and provides a better personal and professional experience in life.
                    </p>
      
                    <h3 class="main-top-title mt-5 mb-4">Our Mission</h3>
                    <p>Our mission is to help households, offices, and commercial entities in meeting their needs for specific home, office, and commercial services by availing of our wide-ranging services that are delivered by experienced professionals in their
                        respective fields. We endeavor to accomplish this by providing end-to-end, fool-proof, and state-of-the-art solutions at the quickest possible timeframe and affordable price.</p>
      
                    <h3 class="main-top-title mt-5 mb-4">Our Core Values</h3>
                    <p><b>ScheduleItNow</b> follows the core values of offering high-quality customer service, sensible service fees, and delivering A+ quality work each & every time.</p>
                    <!-- <hr class="small-dash"> -->
                </div>
            </div>
      
        </section>
        <section id="whiteCard">
            <h3 class="main-top-title mt-5 mb-4">Why ScheduleItnow?</h3>
            <!-- <hr class="small-dash2"> -->
            <div class="scard-hld">
                <div class="scard">
                    <h6>Background Verified</h6>
                    <p><b>ScheduleItNow</b> employs trained professionals whose background, where meticulously verified, minutely checked to guarantee safety, security, and service integrity.</p>
                </div>
                <div class="scard">
                    <h6>Qualified professional</h6>
                    <p>The professionals are highly skilled with years of experience, exposure, and expertise in providing respective services in their localities. They are trained & sensitized to exhibit professional respect, graceful etiquette, and attentiveness.</p>
                </div>
                <div class="scard">
                    <h6>Reliable, Professional & Transparent</h6>
                    <p>Our focus is to provide fool-proof & reliable home, office, and commercial services in a transparent manner keeping high standards of professionalism, ethics, and service integrity.We measure our achievement with the level of customer
                        contentment.</p>
                </div>
                <div class="scard">
                    <h6>Quick & Timely Service</h6>
                    <p>Our response time is very quick and we act instantly after getting a call for service. We ensure timely service delivery so that our customers won't have to wait or call us again & again.
                    </p>
                </div>
                <div class="scard">
                    <h6>Moneyback guarantee</h6>
                    <p><b>ScheduleItNow</b> is always looking at 100% customer satisfaction and provides a money-backguarantee. But in case you are not satisfied with any of our services then you are eligible to getback the service charge by showing your genuine
                        discontent.</p>
                </div>
                <div class="scard">
                    <h6>Reasonable & Affordable Pricing</h6>
                    <p>The services of <b>ScheduleItNow</b> are one of the best in the market. The service charge is reasonably priced to suit your pocket. We also come up with offers and discounts for both regular customers and commoners throughout the year.</p>
                </div>
            </div>
            <div class="hiw">
                <h3 class="main-top-title mt-5 mb-4">How It Works?</h3>
                <!-- <hr class="small-dash"> -->
                <p>You can place your order in a click of the mouse or via a phone call or through the mobilephone app. You will be liked with matching with a committed Home and Commercial Services provider after you request a quote from us. The Home, office,
                    and Commercial Services provider will take care of your project from the initiation to the end. The process includes the screening of home, office and commercial service providers, collection of bids,scheduling the services, and make sure
                    that the services are delivered effectively within the stipulated deadlines.</p>
            </div>
            <div class="pro-map">
                <h6>Share your project details</h6>
                <p>Provide your service requirement details in the provided form to initiate the process to get our services.
                </p>
                <div class="vr"></div>
      
                <h6>Review your booking</h6>
                <p>You will get a follow-up call from us within 24 hours of booking service with us. A matching service provider will be in touch with you with a quotation.</p>
                <div class="vr"></div>
      
                <h6>Let ScheduleItNow do it!</h6>
                <p>ScheduleItNow home, office and commercial Services will coordinate and monitor with the pre-approved, verified service provider to undertake your project.</p>
            </div>
      
            <div class="leadership-team">
                <div class="row">
                    <div class="col-lg-12 pg2">
                        <div class="mvml">
                            <h1 class="main-top-title mt-5 mb-4">Meet Our<br>Leadership Team</h1>
                            <p>At <b>ScheduleItNow</b>, We believe that our success depends on the benefits of our clients receive.
                            </p>
                        </div>
                        <!-- first slider -->
                        <div class="card-contain">
                            <div class="card-hld">
                                <div class="mcard">
                                    <img src="assets/team/anjitha.jpg" alt="">
                                    <h5 class="emp-name">Anjitha Sakthidharan <span class="desg">, Founder, CEO</span></h5>
                                    <!-- <p>Founder, CEO</p> -->
                                    <!-- <a target="_blank" href="https://www.facebook.com/anjitha.sakthidharan"> <i class="fab fa-facebook "></i></a>
                                    <a href="#"> <i class="fab fa-twitter "></i></a>
                                    <a href="#"> <i class="fab fa-instagram "></i></a> -->
      
                                </div>
                                <!-- <div class="mcard ">
                                    <img src="assets/images/pair.jpg" alt="">
                                    <h5>Savio</h5>
                                    <p>Customer Support</p>
                                    <a href="#"> <i class="fab fa-facebook "></i></a>
                                    <a href="#"> <i class="fab fa-twitter "></i></a>
                                    <a href="#"> <i class="fab fa-instagram "></i></a>
                                </div> -->
                                <div class="mcard ">
                                    <img src="assets/team/jim.png" alt="">
                                    <h5 class="emp-name">Jim Bowen <span class="desg">, Head of Marketing</span></h5>
                                    <!-- <p>Head of Marketing</p> -->
                                    <!-- <a href="#"> <i class="fab fa-facebook "></i></a>
                                    <a href="#"> <i class="fab fa-twitter "></i></a>
                                    <a href="#"> <i class="fab fa-instagram "></i></a> -->
                                </div>
                                <div class="mcard ">
                                    <img src="assets/team/anand.png" alt="">
                                    <h5 class="emp-name">Anand Thurubatla <span class="desg">, Chief Architect</span></h5>
                                    <!-- <p>Chief Architect</p> -->
                                    <!-- <a target="_blank" href="https://www.facebook.com/anand.thurubatla/"> <i class="fab fa-facebook "></i></a>
                                    <a target="_blank" href="https://twitter.com/AnandThurubatla"> <i class="fab fa-twitter "></i></a>
                                    <a target="_blank" href="https://www.instagram.com/anandthurubatla/"> <i class="fab fa-instagram "></i></a> -->
                                </div>
                                <div class="mcard ">
                                    <img src="assets/team/enig.png" alt="">
                                    <h5 class="emp-name">Enicar <span class="desg">, Customer Success Manager</span></h5>
                                    <!-- <p>Customer Success Manager</p> -->
                                    <!-- <a target="_blank" href="#"> <i class="fab fa-facebook "></i></a>
                                    <a target="_blank" href="#"> <i class="fab fa-twitter "></i></a>
                                    <a target="_blank" href="#"> <i class="fab fa-instagram "></i></a> -->
                                </div>
                                <div class="mcard ">
                                    <img src="assets/team/gayathri.jpg" alt="">
                                    <h5 class="emp-name">Gayathri <span class="desg">, Operations Manager</span></h5>
                                    <!-- <p>Operations Manager</p> -->
                                    <!-- <a target="_blank" href="#"> <i class="fab fa-facebook "></i></a>
                                    <a target="_blank" href="#"> <i class="fab fa-twitter "></i></a>
                                    <a target="_blank" href="#"> <i class="fab fa-instagram "></i></a> -->
                                </div>
                            </div>
      
                        </div>
                    </div>
      
                </div>
            </div>
      <!-- 
            <div class="client-review">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="mvml">
                            <h1>Our <br>Client Reviews</h1>
                            <p>At <b>ScheduleItNow</b>, We believe that our success depends on the benefits of our clients receive.
                            </p>
                        </div>
                        <div class="card-contain ">
                            <div class="card-hld2">
                                <div class="mcard2">
                                    <div>
                                        <img class="d-inline-block" src="assets/images/ram.jpg" alt="">
                                        <div class="cl-dtls d-inline-block">
                                            <h5>Andrew Wayne</h5>
                                            <p>Founder & CEO</p>
                                            <p>HT IT Solutions</p>
                                            <p>UK</p>
                                        </div>
                                    </div>
                                    <p class="float-right">Great Service! They are the best, booked window cleaning Service!
                                    </p>
      
                                    <div class="icons">
                                        <a href="#"> <i class="fab fa-facebook "></i></a>
                                        <a href="#"> <i class="fab fa-twitter "></i></a>
                                        <a href="#"> <i class="fab fa-instagram "></i></a>
                                    </div>
      
      
                                </div>
                                <div class="mcard2">
                                    <div>
                                        <img class="d-inline-block" src="assets/images/anu.jpg" alt="">
                                        <div class="cl-dtls d-inline-block">
                                            <h5>Sasha Carassi</h5>
                                            <p>Founder & CEO</p>
                                            <p>Hunk Software</p>
                                            <p>UK</p>
                                        </div>
      
                                    </div>
                                    <p class="float-right">Recommended! They are the best, booked carpet cleaning Service!
                                    </p>
                                    <div class="icons">
                                        <a href="#"> <i class="fab fa-facebook "></i></a>
                                        <a href="#"> <i class="fab fa-twitter "></i></a>
                                        <a href="#"> <i class="fab fa-instagram "></i></a>
                                    </div>
      
                                </div>
                                <div class="mcard2">
                                    <div>
                                        <img class="d-inline-block" src="assets/images/anu.jpg" alt="">
                                        <div class="cl-dtls d-inline-block">
                                            <h5>Sasha Carassi</h5>
                                            <p>Founder & CEO</p>
                                            <p>Hunk Software</p>
                                            <p>UK</p>
                                        </div>
      
                                    </div>
                                    <p class="float-right">Recommended! They are the best, booked carpet cleaning Service!
                                    </p>
                                    <div class="icons">
                                        <a href="#"> <i class="fab fa-facebook "></i></a>
                                        <a href="#"> <i class="fab fa-twitter "></i></a>
                                        <a href="#"> <i class="fab fa-instagram "></i></a>
                                    </div>
      
                                </div>
                            </div>
      
                        </div>
      
      
                    </div>
      
                </div>
            </div> -->
      
            <div class="wcu">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="wcu-h">
                            <h3 class="main-top-title mt-5 mb-4">We Believe in 4 Things</h3>
                            <p>At <b>ScheduleItNow</b>, We believe that our success depends on the benefits of our clients receive.
                            </p>
                        </div>
      
      
                        <div class="capsules">
                            <div>
                                <div class="capsule">
                                    <img class="float-left" src="assets/images/loyalty-image.png" alt="">
      
                                    <div class="cap-data">
                                        <h5 class="float-title">Loyalty</h5>
                                        <p>Customers are the central point of our business. We always strive to offer the bestquality on-demand services through booking. We love and respect our customers as we exist only for them. We keep on serving our customers
                                            and establish a long-standing relationship with them.
                                        </p>
                                    </div>
                                </div>
                                <div class="capsule">
                                    <img class="float-left" src="assets/images/commitment-image.png" alt="">
      
                                    <div class="cap-data">
                                        <h5 class="float-title">Commitment</h5>
                                        <p>We are committed to high-quality services.The platform would offer the best facilities to the service providers and the customers so that demand and supply of on demand services take place.
                                        </p>
                                    </div>
                                </div>
                            </div>
      
                            <div>
                                <div class="capsule">
                                    <img class="float-left" src="assets/images/innovation-image.png" alt="">
      
                                    <div class="cap-data">
                                        <h5 class="float-title">Innovation</h5>
                                        <p>We are a highly innovative company. We use the latest technologies to make the experience of customers seamless.
                                        </p>
                                    </div>
                                </div>
                                <div class="capsule">
                                    <img class="float-left" src="assets/images/customersatisfacttion-image.png" alt="">
      
                                    <div class="cap-data">
                                        <h5 class="float-title">Satisfaction</h5>
                                        <p>Customers' satisfaction is our ultimate goal.We try to ensure all kinds of services are of high quality. Our 24/7 customer supporting supplement in this regard.
                                        </p>
                                    </div>
                                </div>
                            </div>
      
      
      
                        </div>
                    </div>
                </div>
            </div>
      
        </section>
      
        <section id="percent" hidden>
            <div class="row ">
                <div class="col-lg-3">
                    <h1>88%</h1>
                    <p>People that trust online reviews as Much as personal recommendations</p>
                </div>
                <div class="col-lg-3">
                    <h1>72%</h1>
                    <p>People that trust online reviews as Much as personal recommendations</p>
                </div>
                <div class="col-lg-3">
                    <h1>98%</h1>
                    <p>People that trust online reviews as Much as personal recommendations</p>
                </div>
                <div class="col-lg-3">
                    <h1>84%</h1>
                    <p>People that trust online reviews as Much as personal recommendations</p>
                </div>
            </div>
        </section>
      </section>
</div>

<app-footer></app-footer>