import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-tutor-form-setps',
  templateUrl: './tutor-form-setps.component.html',
  styleUrls: ['./tutor-form-setps.component.scss']
})
export class TutorFormSetpsComponent implements OnInit {
  formData: any;
  selectedFile: any;
  config:any;
  // items = ['Pizza', 'Pasta', 'Parmesan'];
  items:any;
max:string | undefined;
inputs: {
  visible: boolean; value: string, id: number 
}[] = [];
numRows = 1;
// rows = [{}];

id:any;
  selectedSkills: string[] = [];
 skills: string[] = ['HTML', 'CSS', 'JavaScript','Angular','MongoDb','Python','Java'];
 degree: string | undefined;
 specialization: string | undefined;
 startYear: string | undefined;
 endYear: string | undefined;
 grade: string | undefined;
 status: string | undefined;
 educationRecords: MutationRecord[] = [];
 i!: number;
 rows = [
  { col1: '', col2: '', col3: '', col4: '', col5: '' }
];
  isFirstRowAdded: boolean | undefined;

  constructor(private formBuilder: FormBuilder) {
    
   }

  ngOnInit(): void {
    
  }

  grades:any=[
   {name:'A+'},
   {name:'A'},
   {name:'B'},
   {name:'B+'} 
  ]
 
  onSelect(event: { addedFiles: any[]; }) {
    this.selectedFile = event.addedFiles[0];
  }
  addRow() {
    this.rows.push({ col1: '', col2: '', col3: '', col4: '', col5: '' });
    if (!this.isFirstRowAdded) {
      this.isFirstRowAdded = true;
    }
  }
  // removeRow(index: number) {
  //   this.rows.splice(index, 1);
  // }
  removeRow(index: number) {
    this.rows.splice(index, 1);
    if (this.rows.length === 0) {
      this.isFirstRowAdded = false;
    }
  }





  addSkill(skill: string) {
    if (!this.selectedSkills.includes(skill)) {
      this.selectedSkills.push(skill);
    }
  }
  removeSkill(skill: string) {
    const index = this.selectedSkills.indexOf(skill);
    if (index >= 0) {
      this.selectedSkills.splice(index, 1);
    }
  }
  onTagAdded(event: any) {
    console.log('Tag added:', event.value);
  }

  onTagRemoved(event: any) {
    console.log('Tag removed:', event.value);
  }


  addInput() {
    this.inputs.push({
      visible: false,
      value: '',
      id: 0
    });
  }


  removeInput(index: number) {
    this.inputs.splice(index, 1);
  }

}


