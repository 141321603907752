<div class="bg-color-of-pages">
    <!-- <div class="container">
        <div class="breadcrumb-data">
            <li class="breadcrumb-item">My Wallet</li>
        </div>
    </div> -->
    <div class="container mt-5">
        <div class="inner-data-wallet pt-0">
            <div></div>
            <div class="heading-page">{{!hideWalletScreen ? 'My' : 'Recharge' }} Wallet</div>
            <div class="wallet-total-balance">
                <ng-container *ngIf="!hideWalletScreen">
                    <div class="wallet-data-with-img">
                        <div class="wallet-img">
                            <img src="assets/icons/wallet.png">
                        </div>
                        <div class="wallet-info-content">
                            <div class="title">Total Wallet Balance</div>
                            <div class="balance-wallet">{{walletAmt | currency: currencyCode:'symbol':'1.0-0'}}</div>
                        </div>
                    </div>
                    <div class="btn-recharge" (click)="hideWalletScreen=true;">Recharge Wallet</div>
                    <!-- <div class="download-statment">
                        <div class="download-btn">Download Statement</div>
                    </div> -->
                </ng-container>
                <ng-container *ngIf="hideWalletScreen">
                    <form [formGroup]="addWalletForm" (ngSubmit)="onSubmit($event)">
                        <div class="input-block col-12 row m-0 p-0">
                            <div class="label-text col-12 p-0">Enter amount to wallet<sup>*</sup></div>
                            <div class="sg-input-text col-md-5 col-sm-5 col-lg-5 col-6 pl-0">
                                <input type="text" placeholder="Ex. 500" class="input-text"
                                    formControlName="amount">
                                <span class="error-text"
                                    *ngIf="formSubmited && walletFormControls.amount.errors?.required">Amount
                                    required</span>
                                <span class="error-text"
                                    *ngIf="formSubmited && walletFormControls.amount.errors?.pattern">Enter valid
                                    Number</span>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-4 col-6 p-0">
                                <button class="sb-primary-btn w-100" type="submit">Add Money</button>
                            </div>
                            <div class="balance-wallet col-12 p-0 mt-1">Available balance: {{walletAmt | currency: currencyCode:'symbol':'1.0-0' }}</div>
                        </div>
                    </form>
                </ng-container>
            </div>
            <div class="recent-wallet-transc-block">
                <div class="title">Recent Transactions</div>
                <div infinite-scroll (scrolled)="onScrollDown($event)" (scrolledUp)="onScrollUp($event)"
                    [immediateCheck]="false">
                    <div class="row col-12 transcation-recent-row" *ngFor="let item of walletList">
                        <div class="col-8 left-column-data">
                            <div class="primary-text">{{item.transaction_type}}</div>
                            <div class="secondary-text">{{dataService.getLocalDateTime(item.transaction_date)}}</div>
                        </div>
                        <div class="col-4 right-column-data">
                            <div class="credit-amount" *ngIf="item.transaction_type.toLowerCase() === 'debit'">-{{item.amount | currency: currencyCode:'symbol':'1.0-0' }}
                            </div>
                            <div class="debit-amount"
                            *ngIf="item.transaction_type.toLowerCase() === 'credit' || item.transaction_type.toLowerCase() === 'topup' ||  item.transaction_type.toLowerCase() === 'refund'">
                                +{{item.amount | currency: currencyCode:'symbol':'1.0-0' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>