<!-- <app-breadcrumb></app-breadcrumb> -->

<div class="container-fluid cart-data-container" *ngIf="cartItems?.length !== 0">

  <p class="page-title">Cart</p>

  <!-- <div id="change-address-mb">
    <div class="choose-location-wrapper">
      <div class="c-address-start-inner text-center">
        <img src="assets/icons/default-location-icon.svg" alt="">
      </div>
      <div class="c-address-middle-inner">
        <p class="d-location"><p class="d-location">Service Delivery Location</p>
        <p class="main-default-address">{{ buildAddress(addresses[0]?.address) }}</p>
        <p class="main-default-address">{{ buildAddress(serviceDeliveryAddress?.address) }}</p>
      </div>
      <div class="c-address-right-inner text-center">
        <p class="change-location" (click)="showCustomerAddressList = true">Change</p>
      </div>
    </div>
  </div> -->


  <!-- new design starts here -->
  <div class="cart-nd-main-wrapper" *ngIf="cartItems">

    <div class="cart-nd-inner-left-wrapper">
      <div class="cart-nd-sub-inner-left-wrapper" *ngFor="let cObj of cartItems">
        <div class="cart-nd-services">
          <div *ngIf="cObj.service!=null" class="image-container">
            <img alt="" [src]="cObj.service.image" class="s-image">
            <!-- <div class="s-type">
              <span class="sub-title pt-2" *ngIf="cObj.service.avail_at === 'home_only'">
                <i class="bi bi-geo-alt-fill"></i> Home
              </span>
              
              <span class="sub-title pt-2" *ngIf="cObj.service.avail_at !== 'home_only'">{{cObj.service.avail_at}}</span>
            </div> -->

          </div>
          <div *ngIf="cObj.plan!=null" class="image-container">
            <img alt="" [src]="cObj.plan.service.image" class="s-image">
            <!-- <div class="s-type">
              <span class="sub-title pt-2" *ngIf="cObj?.plan.service.avail_at === 'home_only'">
                <i class="bi bi-geo-alt-fill"></i> Home
              </span>
              
              <span class="sub-title pt-2" *ngIf="cObj.plan.service.avail_at !== 'home_only'">{{cObj.plan.service.avail_at}}</span>
            </div> -->

          </div>
        </div>

        <div *ngIf="cObj.service!=null" class="cart-nd-services">
          <h4 class="s-title">
            {{cObj?.service?.title}}
          </h4>
          <h5 class="s-price">
            {{cObj?.service?.min_charge | currency: currencyCode:'symbol':'1.0-0' }}
          </h5>
          <div class="schedule-dates" *ngIf="!cObj.service.Edit">
            <span class="text-with-icon">{{cObj?.booking_date | date :'dd-MM-YYYY'}} , {{cObj?.booking_time}}</span>
          </div>
          <!-- <div class="" *ngIf="cObj.service.Edit">
            <input type="datetime-local" id="birthdaytime" name="birthdaytime" [(ngModel)]="cObj.service.schedule_date_temp"
            (ngModelChange)="onDateChange(cObj)">
          </div> -->
        </div>
        <div *ngIf="cObj.plan!=null" class="cart-nd-services">
          <h4 class="s-title">
            {{cObj.plan.service.title}}
          </h4>
          <div class="schedule-dates" *ngIf="!cObj.plan.planSelection">
            <span class="text-with-icon">
              {{cObj.plan.name}} for {{cObj.plan.expire_in_months}} Months
            </span>
            <!-- <i class="bi bi-pencil" (click)="onChangePlanSelection(cObj)"></i> -->
          </div>
          <div class="" *ngIf="cObj.plan.planSelection">
            <ng-select [items]="planList" [(ngModel)]="cObj.selectedPlan" [multiple]="false" bindLabel="name"
              [searchable]="false" [clearable]="false" placeholder="Select Plan" class="mt-1"
              (ngModelChange)="setPlanSelection(cObj)">
            </ng-select>
          </div>
          <h5 class="s-price">
            {{cObj.plan.amount | currency: currencyCode:'symbol':'1.0-0' }}
          </h5>
        </div>
        <div class="cart-nd-services text-right">
          <img src="assets/icons/delete-cart-item.svg" alt="" title="Delete" class="remove-service  mr-2"
            (click)="deleteCart(cObj?.cart_id)">
          <a>

            <img src="assets/icons/editicon.svg" (click)="gotoServiceDetailsPage(cObj)" alt="" title="Edit">
            <!-- <i class="bi bi-pencil" style="font-size: 20px;cursor: pointer;"
              (click)="gotoServiceDetailsPage(cObj)"></i> -->
            </a>

          <div class="qty-sec mt-3">
            <div class="minus" (click)="addToCart(cObj, 'delete')">
              <!-- <i class="bi bi-dash"></i> -->
              <img src="assets/icons/minus-square.svg" alt="">
            </div>
            <div class="text-center">
              <input type="number" [value]="cObj.quantity" (blur)="onFocusFn(cObj, $event)" min="0" max="5"
                oninput="validity.valid||(value='');">
            </div>
            <div class="plus" (click)="addToCart(cObj, 'add')">
              <!-- <i class="bi bi-plus"></i> -->
              <img src="assets/icons/plus-square.svg" alt="">
            </div>
          </div>

          <div *ngIf="cObj.service!=null" class="service-total">
            <p>Total &nbsp; <span>{{cObj.service?.min_charge * cObj.quantity | currency:
                currencyCode:'symbol':'1.0-0'}}</span></p>
          </div>
          <div *ngIf="cObj.plan!=null" class="service-total">
            <p>Total &nbsp; <span>{{cObj.plan.amount * cObj.quantity | currency:
                currencyCode:'symbol':'1.0-0'}}</span></p>
          </div>

        </div>
      </div>
    </div>

    <div class="cart-nd-inner-right-wrapper">

      <div id="change-address-dt" *ngIf="false">
        <div class="choose-location-wrapper">
          <div class="c-address-start-inner text-center">
            <img src="assets/icons/default-location-icon.svg" alt="">
          </div>
          <div class="c-address-middle-inner">
            <p class="d-location">Service Delivery Location</p>
            <p class="main-default-address">{{ buildAddress(serviceDeliveryAddress?.address) }}</p>
          </div>
          <div class="c-address-right-inner text-center">
            <p class="change-location" (click)="showCustomerAddressList = true">Change</p>
          </div>
        </div>
      </div>


      <div class="table-footer-section" id="order-summary">
        <!-- <div class="table-inner-left">
          <button class="sb-continue-btn" [routerLink]="['home']"><i class="bi bi-arrow-left"></i> Continue
            Shopping</button>
        </div> -->
        <div class="table-inner-right">
          <p class="o-summary">Order Summary</p>

          <div class="check-out-sec mt-4">
            <div class="check-inner-left">
              <p class="prop">Sub Total</p>
            </div>
            <div class="check-inner-right">
              <p class="value">{{total | currency: currencyCode:'symbol':'1.0-0'}}</p>
            </div>
          </div>

          <div class="check-out-sec">
            <div class="check-inner-left">
              <p class="prop">Tax</p>
            </div>
            <div class="check-inner-right">
              <p class="value">{{taxAmt | currency: currencyCode:'symbol':'1.0-0'}}</p>
            </div>
          </div>

          <div class="check-out-sec">
            <div class="check-inner-left">
              <p class="prop">Total</p>
            </div>
            <div class="check-inner-right">
              <p class="value">{{taxAmt + total | currency: currencyCode:'symbol':'1.0-0'}}</p>
            </div>
          </div>

          <!-- <div class="coupon-code-section">
            <div class="coupon-section-inner">
              <input type="text" [placeholder]="'Enter Coupon Code'">
            </div>
            <div class="coupon-section-inner">
              <button>Apply</button>
            </div>
          </div> -->

          <div class="summary-footer mt-3">
            <div class="summary-inner-footer">
              <button class="sb-continue-btn" (click)="gotoServicesPage()" *ngIf="cartItems[0]?.plan === null">Add
                Service</button>
            </div>
            <div class="summary-inner-footer-right d-flex justify-content-end">
              <button (click)="onCheckout()" class="sb-proceed-btn" [routerLink]="'/payment'">Proceed To
                Checkout</button>
            </div>
          </div>

          <!-- <div class="table-inner-left">
          <button class="sb-continue-btn" [routerLink]="['home']"><i class="bi bi-arrow-left"></i> Continue
            Shopping</button>
        </div> -->

          <!-- <div class="btn-proceed text-right mt-3">
            <button [disabled]="!isCartValid" class="sb-proceed-btn" [routerLink]="'/payment'">Proceed To Checkout <i
                class="bi bi-arrow-right"></i></button>
          </div> -->

        </div>
      </div>
    </div>
  </div>
  <!-- new design starts here -->

  <div class="content-data" *ngIf="false">
    <div class="heading-block">
      <div class="title">Your Cart</div>
      <div class="close-icon text-danger" (click)="deleteTotalCart()">
        <img src="assets/icons/delete-cart.svg" alt="">
        Empty
      </div>
    </div>
    <div class="cart-table">
      <div class="table-header">
        <div class="title-header pl-0">Service(s)</div>
        <div class="title-header">Price</div>
        <div class="title-header p-l-59">Qty</div>
        <div class="title-header">Total</div>
        <div class="title-header">Select Date</div>
        <div class="title-header">Preferred Staff (optional)</div>
        <div class="title-header">Available Slots</div>
      </div>
      <ng-container *ngIf="cartItems">
        <div class="" *ngFor="let cObj of cartItems">

          <div *ngIf="cObj.service!=null" class="table-boddy">
            <div class="service-sec">
              <div class="remove-cart">
                <i class="bi bi-x-lg"></i>
              </div>
              <div class="img-block">
                <img [src]="cObj?.service?.image">
              </div>
              <div>
                <div class="service-title">{{cObj?.service?.title}}</div>
                <div class="sub-title pt-2" *ngIf="cObj?.address?.address_type !== null"><i
                    class="bi bi-geo-alt-fill"></i> {{cObj?.address?.address_type}}</div>
                <div class="sub-title pt-2" *ngIf="cObj?.address?.address_type === null"><i
                    class="bi bi-geo-alt-fill"></i>
                  {{cObj?.service?.serviceprovider_branch?.service_branch_name}}
                </div>

              </div>
            </div>

            <div class="price-title">{{cObj?.service?.min_charge | currency: currencyCode:'symbol':'1.0-0' }}</div>

            <div class="qty-sec">
              <div class="minus" (click)="addToCart(cObj, 'delete')">
                <i class="bi bi-dash"></i>
              </div>
              <input type="number" [value]="cObj.quantity" (blur)="onFocusFn(cObj, $event)" min="0" max="5"
                oninput="validity.valid||(value='');">
              <div class="plus" (click)="addToCart(cObj, 'add')">
                <i class="bi bi-plus"></i>
              </div>
            </div>

            <div class="total-title">{{cObj?.service?.min_charge * cObj.quantity | currency:
              currencyCode:'symbol':'1.0-0'
              }}</div>
            <div class="date-calendar">
              <div class="d-flex">
                <!-- <input placeholder="DD-MM-YYYY" id="birthday" name="birthday"
                   ngbDatepicker #d="ngbDatepicker" [placement]="'bottom'" />
                <button #buttonEl class="btn btn-outline-selead_staffcondary calendar" (click)="d.toggle()"
                  type="button"></button> -->

                <input placeholder="DD-MM-YYYY" id="birthday" name="birthday"
                  [(ngModel)]="cObj.service.schedule_date_temp" ngbDatepicker #d="ngbDatepicker" [placement]="'bottom'"
                  [positionTarget]="buttonEl" [markDisabled]="markDisabled" [minDate]="minDate" [maxDate]="maxDate"
                  (dateSelect)="onBookingDateChange(cObj)" autocomplete="off" />
                <button #buttonEl class="btn btn-outline-selead_staffcondary calendar" (click)="d.toggle()"
                  type="button"></button>

                <!-- <input type="date" id="birthday" name="birthday" [(ngModel)]="cObj.service.schedule_date" [min]="minDate" (ngModelChange)="onBookingDateChange(cObj)"> -->
              </div>
            </div>
            <div class="">
              <div class="prefered-optional prefered-staff-opt">
                <div class="sb-dropdonws" ngbDropdown placement="bottom" (click)="getStaffList(cObj)">
                  <button class="dropdown-input" ngbDropdownToggle>{{cObj.service.lead_staff == undefined ? 'Select' :
                    cObj.service.lead_staff}}<i class="bi bi-chevron-down"></i></button>
                  <ul class="dropdown-menu" ngbDropdownMenu>
                    <li ngbDropdownItem *ngFor="let staffObj of staffList" (click)="ChangeStaffList(staffObj, cObj)">
                      <a class="dropdown_item" *ngIf="staffObj">{{staffObj?.user?.first_name + ' ' +
                        staffObj?.user?.last_name}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="">
              <div class="hours-time">
                <div class="sb-dropdonws" ngbDropdown placement="bottom">
                  <button class="dropdown-input pr-2" ngbDropdownToggle>{{cObj?.service?.schedule_time === undefined ?
                    'HH:MM': cObj?.service?.schedule_time }}</button>
                  <ul class="dropdown-menu" ngbDropdownMenu>
                    <li ngbDropdownItem *ngFor="let slotObj of timeSlotsList" (click)="ChangeSlotList(slotObj, cObj)">
                      <a class="dropdown_item">{{slotObj}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="cObj.plan!=null" class="table-boddy">
            <div class="service-sec">
              <div class="remove-cart">
                <i class="bi bi-x-lg"></i>
              </div>
              <div class="img-block">
                <img [src]="cObj?.service?.image">
              </div>
              <div>
                <div class="service-title">{{cObj?.plan?.title}}</div>
                <div class="sub-title pt-2" *ngIf="cObj?.address?.address_type !== null"><i
                    class="bi bi-geo-alt-fill"></i> {{cObj?.address?.address_type}}</div>
                <div class="sub-title pt-2" *ngIf="cObj?.address?.address_type === null"><i
                    class="bi bi-geo-alt-fill"></i>
                  {{cObj?.plan?.serviceprovider_branch?.service_branch_name}}
                </div>

              </div>
            </div>

            <div class="price-title">{{cObj?.plan.amount | currency: currencyCode:'symbol':'1.0-0' }}</div>

            <div class="qty-sec">
              <div class="minus" (click)="addToCart(cObj, 'delete')">
                <i class="bi bi-dash"></i>
              </div>
              <input type="number" [value]="cObj.quantity" (blur)="onFocusFn(cObj, $event)" min="0" max="5"
                oninput="validity.valid||(value='');">
              <div class="plus" (click)="addToCart(cObj, 'add')">
                <i class="bi bi-plus"></i>
              </div>
            </div>

            <div class="total-title">{{cObj?.plan?.amount | currency:
              currencyCode:'symbol':'1.0-0'
              }}</div>
            <div class="date-calendar">
              <div class="d-flex">
                <!-- <input placeholder="DD-MM-YYYY" id="birthday" name="birthday"
                   ngbDatepicker #d="ngbDatepicker" [placement]="'bottom'" />
                <button #buttonEl class="btn btn-outline-selead_staffcondary calendar" (click)="d.toggle()"
                  type="button"></button> -->

                <input placeholder="DD-MM-YYYY" id="birthday" name="birthday"
                  [(ngModel)]="cObj.service.schedule_date_temp" ngbDatepicker #d="ngbDatepicker" [placement]="'bottom'"
                  [positionTarget]="buttonEl" [markDisabled]="markDisabled" [minDate]="minDate" [maxDate]="maxDate"
                  (dateSelect)="onBookingDateChange(cObj)" autocomplete="off" />
                <button #buttonEl class="btn btn-outline-selead_staffcondary calendar" (click)="d.toggle()"
                  type="button"></button>

                <!-- <input type="date" id="birthday" name="birthday" [(ngModel)]="cObj.service.schedule_date" [min]="minDate" (ngModelChange)="onBookingDateChange(cObj)"> -->
              </div>
            </div>
            <!-- <div class="">
              <div class="prefered-optional prefered-staff-opt">
                <div class="sb-dropdonws" ngbDropdown placement="bottom" (click)="getStaffList(cObj)">
                  <button class="dropdown-input" ngbDropdownToggle>{{cObj.service.lead_staff == undefined ? 'Select' :
                    cObj.service.lead_staff}}<i class="bi bi-chevron-down"></i></button>
                  <ul class="dropdown-menu" ngbDropdownMenu>
                    <li ngbDropdownItem *ngFor="let staffObj of staffList" (click)="ChangeStaffList(staffObj, cObj)">
                      <a class="dropdown_item" *ngIf="staffObj">{{staffObj?.user?.first_name + ' ' +
                        staffObj?.user?.last_name}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> -->
            <div class="">
              <div class="hours-time">
                <div class="sb-dropdonws" ngbDropdown placement="bottom">
                  <button class="dropdown-input pr-2" ngbDropdownToggle>{{cObj?.service?.schedule_time === undefined ?
                    'HH:MM': cObj?.service?.schedule_time }}</button>
                  <ul class="dropdown-menu" ngbDropdownMenu>
                    <li ngbDropdownItem *ngFor="let slotObj of timeSlotsList" (click)="ChangeSlotList(slotObj, cObj)">
                      <a class="dropdown_item">{{slotObj}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="recurring-data-main-wrapper" *ngIf="false">
            <!-- Add Recurring -->
            <div class="r-inner-data"></div>
            <div class="r-inner-data">
              <div class="recurring-wrapper text-right">
                <div class="planner-address-inner" id="recurring-btn-main" *ngIf="!showSelectedData">
                  <p class="add-recurring-btn" (click)="showAddRecurring = true">
                    <img src="assets/icons/recurring.svg" alt="">
                    &nbsp; Add Recurring
                  </p>
                </div>
              </div>

              <div class="show-selected-recurring-data" id="recurring-main-wrapper" *ngIf="showSelectedData">
                <div class="recurring-inner">
                  <p class="r-main-title">Recurring</p>
                </div>
                <div class="recurring-inner text-right">
                  <a href="javascript:void(0)" (click)="showAddRecurring = true">Edit</a>
                </div>
                <div class="recurring-inner">
                  <p class="grey-text-recurring" *ngIf="selectedServiceOn"><span>Repeats On :</span> {{
                    selectedServiceOn.value }}
                  </p>
                  <p class="grey-text-recurring" *ngIf="selectedRepeat !== 'Custom'"><span>Repeats :</span> {{
                    selectedRepeat }}
                  </p>
                  <p class="grey-text-recurring" *ngIf="selectedRepeat === 'Custom'"><span>Repeats :</span> Repeats
                    Every
                    {{
                    repeatsEvery }} {{ selectedCustomRepeat }}</p>
                </div>
                <div class="recurring-inner text-right">
                  <p class="grey-text-recurring"
                    *ngIf="selectedEnd === 'Never' && selectedEnd !== 'On' && selectedEnd !== 'After'"><span>Ends
                      :</span>
                    {{
                    selectedEnd }}</p>
                  <p class="grey-text-recurring" *ngIf="selectedEnd === 'On'"><span>Ends :</span> On {{ dateSelected |
                    date:'mediumDate' }}</p>
                  <p class="grey-text-recurring" *ngIf="selectedEnd === 'After'"><span>Ends :</span> After {{
                    numServices
                    }}
                    services</p>
                </div>
              </div>
            </div>
            <!-- Add Recurring -->
          </div>
        </div>
      </ng-container>

      <hr>

      <!--  -->
      <div class="table-footer-section mt-4">
        <div class="table-inner-left">
          <button class="sb-continue-btn" [routerLink]="['home']"><i class="bi bi-arrow-left"></i> Continue
            Shopping</button>
        </div>
        <div class="table-inner-right">
          <!-- <p class="o-summary">Order Summary</p> -->

          <div class="check-out-sec">
            <div class="check-inner-left">
              <p class="prop">Sub Total</p>
            </div>
            <div class="check-inner-right">
              <p class="value">{{total | currency: currencyCode:'symbol':'1.0-0'}}</p>
            </div>
          </div>

          <div class="check-out-sec">
            <div class="check-inner-left">
              <p class="prop">Tax</p>
            </div>
            <div class="check-inner-right">
              <p class="value">{{taxAmt | currency: currencyCode:'symbol':'1.0-0'}}</p>
            </div>
          </div>

          <div class="check-out-sec">
            <div class="check-inner-left">
              <p class="prop">Total</p>
            </div>
            <div class="check-inner-right">
              <p class="value">{{taxAmt + total | currency: currencyCode:'symbol':'1.0-0'}}</p>
            </div>
          </div>

          <!-- <div class="coupon-code-section">
            <div class="coupon-section-inner">
              <input type="text" [placeholder]="'Enter Coupon Code'">
            </div>
            <div class="coupon-section-inner">
              <button>Apply</button>
            </div>
          </div> -->

          <div class="btn-proceed text-right mt-3">
            <button [disabled]="!isCartValid" class="sb-proceed-btn" [routerLink]="'/payment'">Proceed To Checkout <i
                class="bi bi-arrow-right"></i></button>
          </div>

        </div>
      </div>
      <!--  -->

      <div class="total-count-footer d-none">
        <div class="left-sec-total">
          <div class="info-text">
            <div class="title">Sub Total:</div>
            <div class="amount">{{total | currency : currencyCode }}</div>
          </div>
          <div class="info-text">
            <div class="title">TAX:</div>
            <div class="amount">{{taxAmt | currency : currencyCode}}</div>
          </div>
          <div class="info-text">
            <div class="title">Total:</div>
            <div class="amount">{{taxAmt + total | currency : currencyCode}}</div>
          </div>
        </div>
        <div class="btn-proceed">
          <button class="sb-primary-btn" [routerLink]="'/availability'">Proceed To Checkout<i
              class="bi bi-arrow-right"></i></button>
        </div>
      </div>
    </div>
  </div>

  <div class="mobile-cart" *ngIf="false">
    <div class="main-title">Your Cart</div>
    <div class="cart-cards-container">
      <div class="cart-mobile-card" *ngFor="let cObj of cartItems">
        <!-- <div class="remove-cart" (click)="deleteCart(cObj?.cart_id)">
          <i class="bi bi-x-lg"></i>
        </div> -->
        <!-- <div class="input-block">
          <i class="bi bi-dash" (click)="addToCart(cObj, 'delete')"></i>
          <input type="number" [value]="cObj.quantity" (blur)="onFocusFn(cObj, $event)" min="0" oninput="validity.valid||(value='');">
          <i class="bi bi-plus" (click)="addToCart(cObj, 'add')"></i>
        </div> -->
        <div class="img-block">
          <img [src]="cObj?.service?.image">
        </div>
        <div class="content-info-block">
          <div class="title">{{cObj?.service?.title}}</div>
          <!-- <div class="amount">{{cObj?.service?.min_charge | currency : currencyCode}}</div> -->
        </div>
        <div class="input-block">
          <i class="bi bi-dash" (click)="addToCart(cObj, 'delete')"></i>
          <input type="number" [value]="cObj.quantity" (blur)="onFocusFn(cObj, $event)" min="0"
            oninput="validity.valid||(value='');">
          <i class="bi bi-plus" (click)="addToCart(cObj, 'add')"></i>
        </div>
        <div class="amount-block">
          <div class="amount">{{cObj?.service?.min_charge | currency : currencyCode }}</div>
        </div>
      </div>
      <div class="cart-info-total">
        <div class="left-title-info">Sub Total</div>
        <div class="right-title-info">{{total | currency : currencyCode}}</div>
      </div>
      <div class="cart-info-total">
        <div class="left-title-info">TAX</div>
        <div class="right-title-info">{{taxAmt | currency : currencyCode}}</div>
      </div>
      <div class="cart-info-total all-total">
        <div class="left-title-info">Total</div>
        <div class="right-title-info">{{taxAmt + total | currency : currencyCode}}</div>
      </div>
      <div class="mt-3 text-center">
        <button class="sb-primary-btn w-100" [routerLink]="'/availability'">Proceed To Checkout<i
            class="bi bi-arrow-right ml-2"></i></button>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="cartItems?.length == 0">

  <div class="container no-data-wrapper text-center">
    <div class="empty-cart-img text-center">
      <img src="assets/icons/empty_cart.svg" alt="">
    </div>
    <div class="text-center no-data-text">No data available in Cart</div>
  </div>

</ng-container>

<div class="close-icon-mb-view text-center" *ngIf="showCustomerAddressList">
  <!-- <img src="assets/icons/addr-bar-close.svg" alt="" (click)="closeCustomerAddressBar()"> -->
  <img src="assets/icons/cross-right" alt="" (click)="closeCustomerAddressBar()">
</div>
<!-- Customer address list sidebar -->
<div class="add-address-section">
  <p-sidebar [(visible)]="showCustomerAddressList" position="bottom">
    <div class="address-bar-header">
      <div class="a-bar-inner-left">
        <div class="close-icon">
          <img src="assets/icons/close-mark.svg" alt="" (click)="closeCustomerAddressBar()">
        </div>
      </div>
      <div class="a-bar-center-inner">
        <p class="s-address-title">Select Service Delivery Address</p>
      </div>
      <div class="a-bar-inner-right">
        <div class="add-address" (click)="editAddress()">+ Add Address</div>
      </div>
    </div>

    <div class="customer-all-address">
      <div class="c-address-list mt-4">
        <div *ngFor="let address of addresses" class="manage-address-card-main-wrapper" [class.selected-card]="address === selectedAddress">
          <div (click)="onSelectDeliveryAddress(address)"
            class="manage-address-card">
            <div class="address-card-inner text-center">
              <div class="type-of-address-icon">
                <img *ngIf="address.address_type=='Home'" src="assets/icons/home-icon.svg" alt="Home">
                <img *ngIf="address.address_type=='Work'" src="assets/icons/briefcase.svg" alt="Work">
                <img *ngIf="address.address_type!='Home' && address.address_type!='Work'"
                  src="assets/icons/location-icon.svg" alt="Other">
              </div>
            </div>
            <div class="address-card-inner ml-3">
              <div class="manage-address-title font-weight-bold">{{address.address_type}}
              </div>
              <div class="manage-complete-address">{{buildAddress(address?.address)}}</div>
            </div>

            <div class="address-card-inner text-right">
              <div class="sad">
                <p class="set-as-default-main" *ngIf="!address.address.is_default" (click)="setAsDefault(address)">
                  Set as default
                </p>
              </div>
              <p class="set-as-default" *ngIf="address.address.is_default">
                Default
              </p>
            </div>
          </div>

          <div class="address-footer-actions mt-4">
            <div class="a-footer-left-actions text-center">
              <p class="address-edit" (click)="editAddress(address)">
                <!-- <img src="assets/icons/edit-address.svg" alt=""> -->
                Edit
              </p>
            </div>
            <div class="vertical-divider mt-2"></div>
            <div class="a-footer-actions text-center">
              <p class="address-remove" (click)="deleteAddress(address)">
                <!-- <img src="assets/icons/delete-cart-item.svg" alt=""> -->
                Remove
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>

  </p-sidebar>
</div>
<!-- Customer address list sidebar -->



<!-- footer checkout -->
<!-- <div class="mobile-footer-view">
  <div class="footer-checkout">
    <div class="footer-checkout-inner">
      <p class="sub-total">
        {{taxAmt + total | currency : currencyCode}}
      </p>
      <a href="cart#order-summary" class="go-to-summary">
        view details
      </a>
    </div>
    <div class="footer-checkout-inner text-right">
      <button [disabled]="!isCartValid" class="sb-proceed-btn" [routerLink]="'/payment'">Proceed To
        Checkout</button>
    </div>
  </div>
</div> -->
<!-- footer checkout -->

<app-footer></app-footer>