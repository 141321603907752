import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { of, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, map, tap } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import * as moment from "moment";
import { StorageService } from '../services/storage.service';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class AuthService {
  isLoggedIn(): boolean {
    throw new Error('Method not implemented.');
  }
  userInfo: any;
  // private tokenSubject: BehaviorSubject<UserLoginResponse>;
  public onUserLogin = new Subject();

  constructor(
    private httpService: HttpClient,
    private cookieService: CookieService,
    private storageService: StorageService,
    private router: Router
  ) {

    if (this.storageService.rememberMe()) {
      this.userInfo = JSON.parse(<string>this.storageService.getItem('userInfo'));
    }
  }

  //Method to call RestAPI for login and generate access and refresh tokens
  signIn = (reqObj: any) => {
    // return new Promise((resolve, reject) => {
    const params = {
      username: reqObj.email,
      password: reqObj.pwd
    }
    //login Url
    let targetUrl = environment.apiUrl + `token/`;
    return this.httpService.post(targetUrl, params).pipe(

      map((resp: any) => {
        // console.log('auth service: login:', resp);
        //This conditions is using for showing and hiding nav-bars based on roles
        if (typeof (resp.customer_id != undefined) && resp.customer_id != null) {
          resp.role = "CUSTOMER";
        }
        if (typeof (resp.service_provider_id != undefined) && resp.service_provider_id != null) {
          resp.role = "SERVICE_PROVIDER";
        }
        if (typeof (resp.employee_id != undefined) && resp.employee_id != null) {
          if (resp.emp_or_user_role == "serviceattender") {
            resp.role = "EMPLOYEE";
            //this.userInfo.role = "EMPLOYEE"
          } else if (resp.emp_or_user_role == "backofficeteam") {
            resp.role = "USER";
            //this.userInfo.role = "USER"
          }
        }
        if (typeof (resp.employee_id != undefined) && resp.employee_id != null) {
          if (resp.emp_or_user_role == "backofficeteam") {
            resp.role = "USER";
            //this.userInfo.role = "EMPLOYEE"
          }
        }

        if (resp.customer_id == undefined && resp.service_provider_id == undefined && resp.employee_id == undefined) {
          resp.role = "SUPERADMIN";
        }

        // To set two dates to two variables
        let currentDate = moment();
        // console.log('authService.userInfo: ', this.authService.userInfo);
        let expiryDate = moment(resp.expiry_date);
        // console.log("currentDate", currentDate);
        // console.log("expiryDate", expiryDate);

        //Storing token in cookies
        this.cookieService.set("User_Access_Token", resp.access);
        this.cookieService.set("User_Refresh_Token", resp.refresh);

        // this.storageService.setItem("isRememberme", `${req.rememberMe}`);
        //if(req.rememberMe())
        // this.storageService.setRememberMe();
        // this.cookieService.set("Logged_In_User_Id",resp.user_id);
        console.log("login resp", resp);

        resp.subscriptionExpireInDays = expiryDate.diff(currentDate, 'days');
        console.log('days difff: ', resp.subscriptionExpireInDays);
        if (resp.subscriptionExpireInDays == 0) {
          let hoursDiff = expiryDate.diff(currentDate, 'hours');
          console.log('hours diff: ', hoursDiff);
          if (hoursDiff > 0) {
            resp.subscriptionExpireInDays = 1;
          }

        }

        this.storageService.setItem("user_info", JSON.stringify(resp));

        this.userInfo = JSON.parse(<string>this.storageService.getItem("user_info"));

        //  this.service_provier = resp.service_provider_id
        //   console.log("userInfo in authservice", this.userInfo);
        if(resp.role !== "CUSTOMER"){
          this.userInfo = null;
    
          this.cookieService.delete("User_Access_Token");
          this.cookieService.delete("User_Refresh_Token");
          this.isAuthenticated();
          this.storageService.clearAll();
          this.onUserLogin.next(null);
        }

        this.onUserLogin.next(this.userInfo);
        return resp;
      }),
      catchError(this.handleError)

    )
    // });
  }

  signInWithGoogle_FB = (params: any) => {
    //console.log("social model request", loginReq);
    // return new Promise((resolve) => {
    //let reqBody = JSON.stringify(loginReq);
    let targetUrl = environment.apiUrl + `customer/facebook_google_signin`;
    return this.httpService.post(targetUrl, params).pipe(
      map((resp: any) => {
        if (typeof (resp.customer_id != undefined) && resp.customer_id != null) {
          resp.role = "CUSTOMER";
        }
        // sessionStorage.setItem('currentUser', JSON.stringify(token));
        this.cookieService.set("User_Access_Token", resp.access);
        this.cookieService.set("User_Refresh_Token", resp.refresh);

        this.storageService.setItem("user_info", JSON.stringify(resp));
        this.userInfo = JSON.parse(<string>this.storageService.getItem("user_info"));
        // this.tokenSubject.next(resp.access);
        this.onUserLogin.next(this.userInfo);
        return resp;
      }),
      catchError(this.handleError)
    );
    // });
  }

  // facebookLogin(loginReq: FacebookLoginRequest) {
  //   return new Promise(resolve => {
  //     this.httpService.post(this.FB_LOGIN_URL, loginReq).subscribe(async (resp: any) => {

  //       //Storing token in cookies
  //       this.cookieService.set("User_Access_Token", resp.access);
  //       this.cookieService.set("User_Refresh_Token", resp.refresh);
  //       localStorage.setItem('userInfo', JSON.stringify(resp));
  //       this.userInfo = JSON.parse(localStorage.getItem('userInfo'));

  //       //  this.service_provier = resp.service_provider_id
  //       console.log("userInfo in authservice", this.userInfo.service_provider_id);
  //       return resolve(resp);

  //     },

  //       (err: HttpErrorResponse) => {
  //         return resolve(err);
  //       }

  //     );
  //   });

  // }

  isRememberMeFn() {
    let isRememberme = localStorage.getItem("isRememberme");
    // let isRememberme = isRememberme == 'true' ? true : false
    return isRememberme == "true" ? true : false;
  }

  //Method stands for Change Password
  forgotPwd = (reqObj: any) => {
    const params = {
      email: reqObj.email
    }
    // Construct Change Password URL
    //  let message: string = 'Password has been changed succesfully';
    let targetUrl = environment.apiUrl + `auth/passwordrecovery`;
    return this.httpService.put<[]>(targetUrl, params).pipe(
      map(response => {
        return response
      }),
      catchError(this.handleError)
    );
    //return resolve(message);
    // promise end
  }

  logout() {
    //console.log('Logged out...');
    this.userInfo = null;
    
    this.cookieService.delete("User_Access_Token");
    this.cookieService.delete("User_Refresh_Token");
    this.isAuthenticated();
    this.storageService.clearAll();
    this.onUserLogin.next(null);
    this.router.navigate(['home']);
  }

  //Method is used to identify user is logged-in or not
  isAuthenticated() {
    return this.storageService.isAuthenticated();
  }

  validateMyPreviousSession() {
    console.log('validating previous session..');
    if (this.storageService.rememberMe()) {
      console.log('validating previous session..remember me set');
      this.refreshToken();
    }
  }

  //Method is to send refresh token and get the access token(Trigged only when access token is expired)
  refreshToken() {
    // let userRefreshToken = this.cookieService.get('User_Refresh_Token')
    let userRefreshToken = this.userInfo.refresh;
    //console.log("Refresh token in auth service---",userRefreshToken);
    let refreshTokenUrl = environment.apiUrl + "token/refresh/";
    let refreshObject = {
      refresh: userRefreshToken,
    };

    return this.httpService.post(refreshTokenUrl, refreshObject).pipe(
      tap((resp: any) => {
        if (resp instanceof HttpErrorResponse) {
          if (resp.status == 400) {
            this.router.navigate(["/signup/login"]);
          }
          else if (resp.status == 401) {
            this.router.navigate(['/signup/login'])
          }
        }
        this.cookieService.set("User_Access_Token", resp.access);
        this.cookieService.set("User_Refresh_Token", resp.refresh);

      })
    );
  }

  //Method recover the password
  passwordRecovery(email: any) {
    console.log("Email object in authservice", email);
    return new Promise((resolve) => {
      //construct password recovery url
      let passwordRecoveryUrl = environment.apiUrl + "auth/passwordrecovery";
      this.httpService.post(passwordRecoveryUrl, email).subscribe(
        (resp: any) => {
          return resolve(resp);
        },
        (err: HttpErrorResponse) => {
          return resolve(err);
        }
      );
    });
  }

  signUp = (reqObj: any) => {
    const params = {
      name: reqObj.name,
      email: reqObj.email,
      mobile: reqObj.phoneNum
    }
    let targetUrl = environment.apiUrl + `customer/signup`;
    return this.httpService.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(this.handleError)
    )
  }


  handleError(error: any) {
    console.log(error);
    let errorMessage: any;
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = {
        errorCode: `${error.error.status_code}`,
        message: `${error.error.message || error.error.Error}`
      }

    }
    console.log(errorMessage);
    return of(errorMessage);
  }
}

