<div class="bg-color-of-pages">
  <app-breadcrumb></app-breadcrumb>
  <div class="container">
    <div class="payment-summery-data row col-12 mb-5">
      <div class="col-md-6 col-sm-12 col-lg-6 col-12 left-side-sec">
        <div class="inner-block-content">
          <div class="title-main">Booking Summary</div>

          <div class="row-items" *ngFor="let cObj of cartItems">
            <div *ngIf="cObj.service!=null" class="item-name">{{cObj.service.title}}</div>
            <div *ngIf="cObj.plan!=null" class="item-name">{{cObj.plan.service.title}}</div>
            <div *ngIf="cObj.plan!=null" class="plan-info">{{cObj.plan.name}} for {{cObj.plan.expire_in_months}} Months
            </div>
            <div class="item-count">
              <div class="count-item">{{cObj.quantity}}</div>
            </div>
            <div *ngIf="cObj.service!=null" class="amount-price">{{cObj.service.min_charge * cObj.quantity | currency:
              currencyCode:'symbol':'1.0-0'}}</div>
            <div *ngIf="cObj.plan!=null" class="amount-price">{{cObj.plan.amount | currency:
              currencyCode:'symbol':'1.0-0'}}</div>
          </div>
        </div>
        <!--  -->
        <div class="booking-total-data mt-2">
          <div class="order-details-data">
            <div class="left-title bold-text">Order Total</div>
            <div class="amount-title">{{total | currency: currencyCode:'symbol':'1.0-0' }}</div>
          </div>
          <div class="order-details-data">
            <div class="left-title">TAX ({{taxPercent}}%)</div>
            <div class="amount-title">{{taxAmt | currency: currencyCode:'symbol':'1.0-0' }}</div>
          </div>
          <div class="order-details-data mt-4">
            <div class="left-title bold-text">Total Payable</div>
            <div class="amount-title">{{total + taxAmt | currency: currencyCode:'symbol':'1.0-0' }}</div>
          </div>
          <div *ngIf="depositAmt>0" class="order-details-data">
            <div class="left-title">Deposit Amount</div>
            <div class="amount-title">{{depositAmt | currency: currencyCode:'symbol':'1.0-0' }}</div>
          </div>
        </div>
        <!--  -->
        <div class="booking-container">

          <div class="booking-container-inner">
            <div class="booking-method-data">
              <input (click)="paymentOption('Full')" type="radio" name="paymentmethod" id="payfull" value="location"
                class="radio-custom" [checked]='true'>
              <label for="payfull" class="radio-custom-label">&nbsp; Pay Full</label>
            </div>
          </div>

          <div class="booking-container-inner" *ngIf="depositAmt > 0">
            <div class="booking-method-data">
              <input (click)="paymentOption('Deposit')" type="radio" name="paymentmethod" id="paydeposit"
                value="location" class="radio-custom">
              <label for="paydeposit" class="radio-custom-label">&nbsp; Pay Deposit</label>
            </div>
          </div>

        </div>

        <!--  -->
      </div>



      <div class="col-md-6 col-sm-12 col-lg-6 col-12 right-side-sec">
        <div class="inner-data-sec">
          <div class="radio-data-block">
            <div class="radio-custom-sb" [ngClass]="{'blurEffect': !showWallet}">
              <input type="radio" [(ngModel)]="paymentSource" name="payonlinevist" id="Wallet" value="wallet"
                class="radio-custom" [disabled]="hideWallet">
              <label for="Wallet" class="radio-custom-label">Pay Through Wallet</label>
              <div class="wallet-info">
                <i class="bi bi-wallet-fill"></i>
                <span class="w-amount">{{+walletAmt | currency: currencyCode:'symbol':'1.0-0' }}</span>
              </div>
            </div>

          </div>

          <div class="radio-data-block">
            <div class="radio-custom-sb">
              <input type="radio" [(ngModel)]="paymentSource" name="payonlinevist" id="payonline" value="online"
                class="radio-custom" checked="hideWallet">
              <label for="payonline" class="radio-custom-label">Pay Online</label>
            </div>
          </div>

          <div class="declaration-section">
            <label>
              <input type="checkbox" name="declaration" [(ngModel)]="agreeTermsAndCondition">
            </label>
            <a title="Click to go through our terms and conditions"  (click)="navigatePage()"
              ><span class="b-text">I agree to the</span> <span class="link">Terms and Conditions</span></a>
          </div>


          <ng-container *ngIf="!disabledBtn">

            <button (click)="createServiceRequest()" *ngIf="depositType=='Deposit'" class="sb-primary-btn w-100 mt-2">
              Pay {{depositAmt | currency: currencyCode:'symbol':'1.0-0'}}</button>
            <button (click)="createServiceRequest()" *ngIf="depositType=='Full'" class="sb-primary-btn w-100 mt-2">
              Pay {{(total + taxAmt) | currency: currencyCode:'symbol':'1.0-0'}}</button>
          </ng-container>
          <button *ngIf="disabledBtn" class="sb-primary-btn w-100 mt-2">Processing &nbsp;&nbsp;<i
              class="fa fa-circle-o-notch fa-spin"></i></button>

          <div class="paymenttype-logo">
            <div class="razorpay-logo"><img *ngIf="paymentGateway=='razorpay'" src="assets/images/Razorpay.svg"
                alt="Razorpay"></div>
            <div class="stripe-logo"><img *ngIf="paymentGateway=='stripe'" src="assets/images/Stripe.svg" alt="Stripe">
            </div>
          </div>

          <div class="online-mode-data d-none" *ngIf="showOrderDetails">
            <div class="order-details-data">
              <div class="left-title bold-text">Order Total</div>
              <div class="amount-title">{{total | currency : currencyCode}}</div>
            </div>
            <div class="order-details-data">
              <div class="left-title">TAX ({{taxPercent}}%)</div>
              <div class="amount-title">{{taxAmt | currency : currencyCode}}</div>
            </div>
            <div class="order-details-data mt-4">
              <div class="left-title bold-text">Total Payable</div>
              <div class="amount-title">{{total + taxAmt | currency : currencyCode}}</div>
            </div>
            <button class="sb-primary-btn w-100 mt-2" (click)="createServiceRequest()"
              [disabled]="disabledBtn">Continue</button>
          </div>
          <div class="radio-data-block d-none">
            <div class="radio-custom-sb" (click)="closeDetails()">
              <input type="radio" name="payonlinevist" id="payonvisit" value="location" class="radio-custom">
              <label for="payonvisit" class="radio-custom-label">Pay on visit</label>
            </div>
            <div class="protip-info">To avoid spam on bookings, we are charging {{depositPercentage}}% of booking amount
              [Pay {{depositAmt | currency : currencyCode}} ] in advance</div>
            <button *ngIf="showContinue" class="sb-primary-btn w-100 mt-3" (click)="createServiceRequest()"
              [disabled]="disabledBtn">Continue</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>