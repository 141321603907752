// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  // apiUrl: 'http://165.22.210.246:5000/',
  // apiUrl:'http://127.0.0.1:8000/',
   apiUrl: 'https://serveu-api.accenflairapps.com/',
 // apiUrl:'https://api.scheduleitnow.co.uk/',
  oneSignalAppId: "48ed05ef-9a93-4a48-a2fc-e9a0a7f385ef",
  mediaApiurl: 'https://sgp1.digitaloceanspaces.com/servu/images/',
  stripeKey: 'pk_test_51J5Po1CvHc2Arxc1fbVNB060IdTYkHGm2v2UYK4VesW2kb1w9FOMEEJkrZctxJ7j9Jbuj5JPwCi0LM0S3WDtfDz300lKVDSFQ3',
  gridPageSize: 10,
  production: false,
  googleAPIKey: "AIzaSyCDD-yuztbp1BhkGQHzpiTbxHS8Ve1H7WE", // G-Maps
  cookieDomain: 'servu.accenflairapps.com',
  FacebookAppId: "593930501543033",
  GoogleClientId: "153378903831-o0kr8a8e3p17hbms643nn6ba6l98f2do.apps.googleusercontent.com",
  razorPayKey: 'rzp_test_4e8zub5GRLFzNF',
  stripKey: 'pk_test_XsdJRvTDnT07dk2rZ4KPrpj100IXyWB2Ry',//'sk_test_12gTRkMH1oXEONHnsgnn3WBP00q8IjUNMS',
  paymentMode: 'stripe',
  // paymentMode: 'razorpay',
  countryCode: '+44',
  currencyCode:'GBP',
  recaptchaSiteKey: '6LdwcE0gAAAAAHdkMqN7nSbLHuWvliqTnfWbR_IF',
  ukAddress: '8, Marlin House, Hornbeam Business Park, HG2 8PA',
  // indiaAddress: 'Plot # 682, 5th Floor, Babukhan Rasheed Plaza, Rd Number 36, Jubilee Hills, Hyderabad, Telangana 500033',
  facebookLink: 'https://www.facebook.com/GSWindowCleaningHarrogate',
  siteLogo: '/assets/images/sit.png',
  productName: 'ScheduleItNow.',

  // By Mohitha
  pinCodeLabelName :'PIN Code',
  countryList: 'United Kingdom',
  defaultCategoryId:316,
  // By Mohitha
  
  trialPlan: {
    priceId: "price_1J5RkyCvHc2Arxc1GtyP8Rnr",
    validity: "14",
    validityInDays: "Days",
    empCount: 3,
    bookingLimit: 50
  },
  basicMonthly: {
    priceId: "price_1J5Ri4CvHc2Arxc1RIsoJ3F5",
    empCount: 5,
    bookingLimit: 100,
    money: 29
  },
basicYearly : {
    priceId: "price_1J5RibCvHc2Arxc1uVc6ugjo",
    empCount: 5,
    bookingLimit: 100,
    money: 299
  },
  standardMonthly : {
    priceId: "price_1J5Rj0CvHc2Arxc1I6ST3lHH",
    empCount: 15,
    bookingLimit: 150,
    money: 49
  },
  standardYearly : {
    priceId: "price_1J5RjUCvHc2Arxc1w5lizfAE",
    empCount: 15,
    bookingLimit: 150,
    money: 499
  },
  premiumMonthly : {
    priceId: "price_1J5RjnCvHc2Arxc1mWDVH6ve",
    empCount: 30,
    bookingLimit: 200,
    money: 79
  },
  premiumYearly : {
    priceId: "price_1J5RkKCvHc2Arxc1TlISikds",
    empCount: 30,
    bookingLimit: 200,
    money: 849
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
