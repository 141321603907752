<div class="bg-color-of-pages">
    <div class="container">
        <div class="inner-dataprofile">
            <div class="heading-page">My Profile</div>
            <div class="box-profile-form">
                <div class="profile-img-block">
                    <form [formGroup]="myForm" class="position-relative">

                        <div class="img-block">

                            <ng-container *ngIf="!userInfo?.is_facebook_google">
                                <input formControlName="file" id="file" type="file" multiple accept=".png"
                                    class="form-control" (change)="onFileChange($event)">
                                <img [src]="profileDetails?.profile_pic" *ngIf="!fileName" alt="1" />
                                <img [src]="imgURL" *ngIf="fileName" alt="2" />
                                <div class="profile-name">{{profileDetails?.user?.first_name}}</div>
                                <div class="overlay-edit">
                                    <i class="bi bi-pencil-square"></i>
                                </div>
                                <span class="remove" (click)="removefile()" *ngIf="fileName">
                                    <i class="bi bi-trash3-fill"></i>
                                </span>
                            </ng-container>
                            <ng-container *ngIf="userInfo?.is_facebook_google">
                                <img [src]="profileDetails?.profile_pic" *ngIf="!fileName" alt="1" />
                                <div class="profile-name">{{profileDetails?.user?.first_name}}</div>
                            </ng-container>
                        </div>
                        <!-- <span class="file-name">{{fileName}}</span>
                        <div class="file-dummy">
                            <i class="bi bi-cloud-arrow-up-fill"></i>
                            <div class="title-drog-drop">Drag & Drop</div>
                            <div class="default">(Recommended size : 1080pxX1080px)</div>
                        </div> -->
                    </form>
                </div>

                <!-- Profile new design Starts -->

                <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink class="profile-tab-tilte">Basic information</a>
                        <ng-template ngbNavContent>
                            <div class="form-data-profile mt-4">
                                <!-- <div class="hading-text">Basic information</div> -->
                                <form [formGroup]="profileForm" (ngSubmit)="onSubmit($event)">
                                    <div class="col-md-9 col-lg-9 col-sm-12 col-12 row-form-inputs row p-0">
                                        <div class="sg-input-text col-md-6 col-sm-6 col-lg-6 col-12 mb-3">
                                            <div class="label-text">Full Name<sup>*</sup></div>
                                            <input type="text" placeholder="Robert" class="input-text"
                                                formControlName="fullName">
                                            <div class="error-text" *ngIf="fullNameControls.invalid && formSubmited">
                                                Full Name required</div>
                                        </div>
                                        <div class="sg-input-text col-md-6 col-sm-6 col-lg-6 col-12 mb-3">
                                            <div class="label-text">Email<sup>*</sup></div>
                                            <input type="text" placeholder="robert@gmail.com" class="input-text"
                                                formControlName="email">
                                            <div class="error-text" *ngIf="emailControls.invalid && formSubmited">Email
                                                required</div>
                                        </div>
                                        <div class="sg-input-text col-md-6 col-sm-6 col-lg-6 col-12 mb-3">
                                            <div class="label-text">Phone<sup>*</sup></div>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">{{countryCode}}</div>
                                                </div>
                                                <input type="text" placeholder="2071234567" class="input-text"
                                                    formControlName="phoneNum">
                                            </div>
                                            <div class="error-text" *ngIf="phoneNumControls.invalid && formSubmited">
                                                Phone Number required</div>
                                        </div>
                                        <div class="sg-input-text col-md-6 col-sm-6 col-lg-6 col-12 mb-3">
                                            <div class="label-text">Date of birth<sup>*</sup></div>
                                            <input type="date" placeholder="Date of birth" class="input-text"
                                                formControlName="dob">
                                            <div class="error-text" *ngIf="dobControls.invalid && formSubmited">Date of
                                                birth required</div>
                                        </div>
                                        <div class="sg-input-text col-md-6 col-sm-6 col-lg-6 col-12 mb-3">
                                            <div class="label-text">Gender<sup>*</sup></div>
                                            <!-- <input type="radio" placeholder="Gender" class="input-text"  formControlName="gender" value="Male">Male
                                        <input type="radio" placeholder="Gender" class="input-text"  formControlName="gender" value="Female">Female
                                        <span class="error-text" *ngIf="genderControls.invalid && formSubmited">Gender required</span> -->
                                            <div class="radio-custom-sb">
                                                <input type="radio" formControlName="gender" value="Male" id="male"
                                                    class="radio-custom">
                                                <label for="male" class="radio-custom-label">Male</label>
                                            </div>
                                            <div class="radio-custom-sb">
                                                <input type="radio" formControlName="gender" value="Female" id="female"
                                                    class="radio-custom">
                                                <label for="female" class="radio-custom-label">Female</label>
                                            </div>
                                            <div class="error-text" *ngIf="genderControls.invalid && formSubmited">
                                                Gender required</div>
                                        </div>
                                        <!-- <input type="date" id="birthday" name="birthday" formControlName="dob" [min]="minDate"> -->
                                        <div class="col-md-12 col-sm-12 col-lg-12 col-12">
                                            <button class="sb-primary-btn" type="submit">Update Changes</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink class="profile-tab-tilte" id="manage-address">Manage Addresses</a>
                        <ng-template ngbNavContent *ngIf="addresses">

                            <div class="d-flex justify-content-end">
                                <div class="add-address sb-continue-btn" (click)="editAddress()">Add Address</div>
                            </div>
                            <div class="manage-address-wrapper mt-2">
                                <div *ngFor="let address of addresses" class="manage-address-card">
                                    <div class="address-card-inner">
                                        <div class="type-of-address-icon">
                                            <img *ngIf="address.address_type=='Home'" src="assets/icons/home-icon.svg"
                                                alt="Home">
                                            <img *ngIf="address.address_type=='Work'" src="assets/icons/briefcase.svg"
                                                alt="Work">
                                            <img *ngIf="address.address_type!='Home' && address.address_type!='Work'"
                                                src="assets/icons/location-icon.svg" alt="Other">
                                        </div>
                                    </div>
                                    <div class="address-card-inner ml-3">
                                       <div class="d-flex justify-content-between">
                                            <div class="manage-address-title font-weight-bold">{{address.address_type}}</div>
                                            <div class="address-card-inner">
                                                <a [ngClass]="{'set-as-default': address.address.is_default , 'set-as-default-main': !address.address.is_default}"
                                                (click)="setAsDefault(address)">
                                                    {{ address.address.is_default ? 'Default' : 'Set as default' }}
                                                </a>
                                            </div>
                                       </div>
                                        <!-- <div class="manage-address-title font-weight-bold">{{address.Work}}</div> 
                                   <div class="manage-address-title font-weight-bold">{{address.Other}}</div>  -->
                                        <div class="manage-complete-address">{{buildAddress(address?.address)}}</div>
                                        <div class="address-action-btn">
                                            <a (click)="editAddress(address)">Edit</a>
                                            <a (click)="deleteAddress(address)">Delete</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--  -->
                            <div class="text-center" *ngIf="addresses?.length == 0">
                                <div class="container no-data-wrapper text-center">
                                    <div class="empty-cart-img text-center">
                                        <img src="assets/images/No data-rafiki.svg" alt="No data available">
                                    </div>
                                    <div class="text-center no-data-text">No data available</div>
                                </div>
                            </div>
                            <!--  -->
                        </ng-template>
                    </li>
                </ul>

                <div [ngbNavOutlet]="nav" class="mt-2"></div>
                <!-- Profile new design Ends -->

                <div class="form-data-profile d-none">
                    <div class="hading-text">Basic information</div>
                    <form [formGroup]="profileForm" (ngSubmit)="onSubmit($event)">
                        <div class="col-md-9 col-lg-9 col-sm-12 col-12 row-form-inputs row p-0">
                            <div class="sg-input-text col-md-6 col-sm-6 col-lg-6 col-12 mb-3">
                                <div class="label-text">Full Name<sup>*</sup></div>
                                <input type="text" placeholder="Full Name" class="input-text"
                                    formControlName="fullName">
                                <div class="error-text" *ngIf="fullNameControls.invalid && formSubmited">Full Name
                                    required</div>
                            </div>
                            <div class="sg-input-text col-md-6 col-sm-6 col-lg-6 col-12 mb-3">
                                <div class="label-text">Email<sup>*</sup></div>
                                <input type="text" placeholder="Email" class="input-text" formControlName="email">
                                <div class="error-text" *ngIf="emailControls.invalid && formSubmited">Email required
                                </div>
                            </div>
                            <div class="sg-input-text col-md-6 col-sm-6 col-lg-6 col-12 mb-3">
                                <div class="label-text">Phone<sup>*</sup></div>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">{{countryCode}}</div>
                                    </div>
                                    <input type="text" placeholder="Phone" class="input-text"
                                        formControlName="phoneNum">
                                </div>
                                <div class="error-text" *ngIf="phoneNumControls.invalid && formSubmited">Phone Number
                                    required</div>
                            </div>
                            <div class="sg-input-text col-md-6 col-sm-6 col-lg-6 col-12 mb-3">
                                <div class="label-text">Date of birth<sup>*</sup></div>
                                <input type="date" placeholder="Date of birth" class="input-text" formControlName="dob">
                                <div class="error-text" *ngIf="dobControls.invalid && formSubmited">Date of birth
                                    required</div>
                            </div>
                            <div class="sg-input-text col-md-6 col-sm-6 col-lg-6 col-12 mb-3">
                                <div class="label-text">Gender<sup>*</sup></div>
                                <!-- <input type="radio" placeholder="Gender" class="input-text"  formControlName="gender" value="Male">Male
                                <input type="radio" placeholder="Gender" class="input-text"  formControlName="gender" value="Female">Female
                                <span class="error-text" *ngIf="genderControls.invalid && formSubmited">Gender required</span> -->
                                <div class="radio-custom-sb">
                                    <input type="radio" formControlName="gender" value="Male" id="male"
                                        class="radio-custom">
                                    <label for="male" class="radio-custom-label">Male</label>
                                </div>
                                <div class="radio-custom-sb">
                                    <input type="radio" formControlName="gender" value="Female" id="female"
                                        class="radio-custom">
                                    <label for="female" class="radio-custom-label">Female</label>
                                </div>
                                <div class="error-text" *ngIf="genderControls.invalid && formSubmited">Gender required
                                </div>
                            </div>
                            <!-- <input type="date" id="birthday" name="birthday" formControlName="dob" [min]="minDate"> -->
                            <div class="col-md-12 col-sm-12 col-lg-12 col-12">
                                <button class="sb-primary-btn" type="submit">Update Changes</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>