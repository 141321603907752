<div class="add-recurring-section">
    <p-sidebar [(visible)]="showAddRecurring" position="right">
        <div class="row pt-4">
            <div class="col-md-10">
                <h3 class="s-bar-title mb-4">
                    {{serviceDetails?.plans && serviceDetails.plans?.length > 0 && !selectBookAService ? 'Add Recurring'
                    : 'Schedule Service'}}</h3>
            </div>
            <div class="col-md-2 text-right" id="close-recurring-bar">
                <img src="assets/icons/close-bar.svg" alt="" (click)="closePopup()">
            </div>
        </div>

        <!-- new form -->
        <form [formGroup]="recurringForm">


            <div class="row">
                <div class="col-md-12">

                    <div class="ends-main-wrapper mt-3">
                        <div class="date-calendar1">
                            <label for="startDate" class="label-grey mt-2 mb-2">Start Date<sup
                                    class="mandatory">*</sup></label>
                            <div class="col--md-12">
                                <div class="input-group">
                                    <input placeholder="DD-MM-YYYY" id="birthday" name="birthday"
                                        formControlName="startDate" ngbDatepicker #sd="ngbDatepicker"
                                        [placement]="'bottom'" (dateSelect)="onBookingDateChange()"
                                        [markDisabled]="markDisabled" [minDate]="minDate" [maxDate]="maxDate"
                                        autocomplete="off" class="form-control" (click)="sd.toggle()" />
                                    <button class="btn btn-outline-secondary bi bi-calendar3" (click)="sd.toggle()"
                                        type="button"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <label for="selectedStaff" class="label-grey mt-4 mb-2">Preferred Staff (optional)</label>
                    <ng-select [items]="staffList" bindLabel="user.first_name" bindValue="employee_id"
                        [multiple]="false" [searchable]="false" [clearable]="false" formControlName="staff"
                        placeholder="Select Staff" class="mt-1">
                        <ng-template ng-option-tmp let-item="item">
                            {{ item.user.first_name }} ({{item.employee_id}})
                        </ng-template>
                    </ng-select>
                </div>

                <div class="col-md-12">
                    <label for="preferredTime" class="label-grey mt-4 mb-2">Preferred Time<sup
                            class="mandatory">*</sup></label>
                    <ng-select [items]="timeSlotsList" formControlName="preferredTime" [multiple]="false"
                        [searchable]="false" [clearable]="false" placeholder="Select Time Slot" class="mt-1"
                        bindLabel="">
                    </ng-select>
                </div>



                <!-- <div class="col-md-12" *ngIf="selectedPlan?.name == 'Limited Bookings'"> -->
                <div class="col-md-12"
                    *ngIf="selectedPlan !== 'daily' && selectedPlan !== 'weekly' && selectedPlan !== 'monthly' && isRecurringIntervalAvail && isRecurringIntervalAvail?.length > 0">
                    <label for="hi" class="label-grey mt-4 mb-2">Recurring Interval<sup
                            class="mandatory">*</sup></label>
                    <ng-select [items]="recuringIntervalList" formControlName="selectedRecurringInterval"
                        bindLabel="name" bindValue="recurring_interval" [multiple]="false" [searchable]="false"
                        [clearable]="false" placeholder="Select Recurring Interval" class="mt-1">
                    </ng-select>
                </div>
                <div class="choose-location-wrapper mt-3">
                    <div class="c-address-start-inner text-center">
                      <img src="assets/icons/default-location-icon.svg" alt="">
                    </div>
                    <div class="c-address-middle-inner">
                      <p class="d-location"><p class="d-location">Service Delivery Location</p>
                      <!-- <p class="main-default-address">{{ buildAddress(addresses[0]?.address) }}</p> -->
                      <p class="main-default-address">{{ buildAddress(serviceDeliveryAddress) }}</p>
                    </div>
                    <div class="c-address-right-inner text-center" *ngIf="serviceDetails.avail_at === 'home_only' || serviceDetails.avail_at === 'both'">
                      <p class="change-location" (click)="openAddressPopup()">Change</p>
                    </div>
                  </div>
            </div>


            <div class="s-btn-wrapper mt-3">
                <button type="submit" class="text-white" (click)="showItemCount()">Next</button>
            </div>
        </form>
    </p-sidebar>
</div>

<div class="add-address-section">
    <p-sidebar [(visible)]="showCustomerAddressList" position="bottom">
      <div class="address-bar-header">
        <div class="a-bar-inner-left">
          <div class="close-icon">
            <img src="assets/icons/close-mark.svg" alt="" (click)="closeCustomerAddressBar()">
          </div>
        </div>
        <div class="a-bar-center-inner">
          <p class="s-address-title">Select Service Delivery Address</p>
        </div>
        <div class="a-bar-inner-right">
          <div class="add-address mr-2" (click)="editAddress()">+ Add Address</div> 
          <div class="add-address" (click)="closeCustomerAddressBar()">Done</div>
        </div>
      </div>
      <!-- buildAddress(serviceDeliveryAddress) -->
      <div class="customer-all-address" *ngIf="serviceDetails.avail_at === 'both'">
        <h4 class="s-address-title">Our address</h4>
        <div class="c-address-list mt-4">
          <div  [class.selected-card]="isServiceAddressSelected"  class="manage-address-card-main-wrapper">
            <div (click)="onSelectDeliveryAddress('address')"
              class="manage-address-card">
              <div class="address-card-inner text-center">
                <div class="type-of-address-icon">
                  <img src="assets/icons/location-icon.svg" alt="Other">
                </div>
              </div>
              <div class="address-card-inner ml-3">
                <div class="manage-address-title font-weight-bold">{{'Center'}}</div>
                <div class="manage-complete-address">{{ buildAddress(serviceDetails?.serviceprovider_branch?.address) }}</div>
              </div>
  
            </div>
  
          </div>
        </div>
      </div>
      <div class="customer-all-address">
        <h4 class="s-address-title">Your address</h4>
        <div class="c-address-list mt-4">
          <div *ngFor="let address of addresses" class="manage-address-card-main-wrapper" [class.selected-card]="address === selectedAddress">
            <div (click)="onSelectDeliveryAddress(address)"
              class="manage-address-card">
              <div class="address-card-inner text-center">
                <div class="type-of-address-icon">
                  <img *ngIf="address.address_type=='Home'" src="assets/icons/home-icon.svg" alt="Home">
                  <img *ngIf="address.address_type=='Work'" src="assets/icons/briefcase.svg" alt="Work">
                  <img *ngIf="address.address_type!='Home' && address.address_type!='Work'"
                    src="assets/icons/location-icon.svg" alt="Other">
                </div>
              </div>
              <div class="address-card-inner ml-3">
                <div class="manage-address-title font-weight-bold">{{address.address_type}}
                </div>
                <div class="manage-complete-address">{{buildAddress(address?.address)}}</div>
              </div>
  
              <div class="address-card-inner text-right">
                <div class="sad">
                  <p class="set-as-default-main" *ngIf="!address.address.is_default" (click)="setAsDefault(address)">
                    Set as default
                  </p>
                </div>
                <p class="set-as-default" *ngIf="address.address.is_default">
                  Default
                </p>
              </div>
            </div>
  
            <div class="address-footer-actions mt-4">
              <div class="a-footer-left-actions text-center">
                <p class="address-edit" (click)="editAddress(address)">
                  <!-- <img src="assets/icons/edit-address.svg" alt=""> -->
                  Edit
                </p>
              </div>
              <div class="vertical-divider mt-2"></div>
              <div class="a-footer-actions text-center">
                <p class="address-remove" (click)="deleteAddress(address)">
                  <!-- <img src="assets/icons/delete-cart-item.svg" alt=""> -->
                  Remove
                </p>
              </div>
            </div>
  
          </div>
        </div>
      </div>
  
    </p-sidebar>
  </div>