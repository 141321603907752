import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-primary-student-signup',
  templateUrl: './primary-student-signup.component.html',
  styleUrls: ['./primary-student-signup.component.scss']
})
export class PrimaryStudentSignupComponent implements OnInit {
  signUpForm: any;
  formSubmited = false;
 
  errMsg: any;
  showPwdLabel = "password";
  showSuccessPage = false;
  countryCode = environment.countryCode;
  changePWDForm: any;
  selectedGender='female';
  constructor(
    public router: Router,
    private socialAuthService: SocialAuthService) { }
    gender:any=[
      {name:'Male'},
      {name:'Female'},
      {name:'Others'}
    ]
    ngOnInit(): void {
      this.signUpForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        mobile: new FormControl('', [Validators.required, Validators.pattern(new RegExp("[0-9 ]{10}")), Validators.maxLength(10)]),
        pwd: new FormControl('', [Validators.required]),
        fname: new FormControl('', [Validators.required]),
        lname: new FormControl('', [Validators.required]),
        sfname: new FormControl('', [Validators.required]),
        slname: new FormControl('', [Validators.required]),
        sstandard:new FormControl('',[Validators.required]),
        agree: new FormControl(false, [Validators.requiredTrue])
      });
    }
    get emailControls() { return this.signUpForm.get('email'); }
  get phoneNumControls() { return this.signUpForm.controls;}
  // get pwdControls() { return this.changePWDForm.controls; }
  private passwordsShouldMatch(fGroup: FormGroup) {
    return this?.changePWDForm?.get('newPWD')?.value === this.changePWDForm?.get('cnfrmPWD')?.value
      ? null : { 'mismatch': true };
  } 
  isVisible = () => {
    this.showPwdLabel = this.showPwdLabel === 'password' ? 'text' : 'password';
  }
  get pwdControls() { return this.signUpForm.get('pwd'); }
  get fnameControls() { return this.signUpForm.get('fname'); }
  get lnameControls() { return this.signUpForm.get('lname'); }
  get studentfnameControls() { return this.signUpForm.get('sfname'); }
  get studentlnameControls() { return this.signUpForm.get('slname'); }
  get agreeControls() { return this.signUpForm.get('agree'); }
  get studyControl(){return this.signUpForm.get('sstandard')}

  // onSubmit = (event: any) => {
  //   event.preventDefault();
  //   this.formSubmited = true;
  //   this.errMsg ='';  
  //   if (this.signUpForm.valid) {
  //     this.signUpForm.value.phoneNum = environment.countryCode+this.signUpForm.value.mobile;
   
  //   }

  onSubmit = (event: any) => {
    event.preventDefault();
    this.formSubmited = true;
    this.errMsg ='';
  
    if (this.signUpForm.valid) {
      this.signUpForm.value.phoneNum = environment.countryCode+this.signUpForm.value.mobile;
      // Perform form submission here
      // ...
      // Navigate to the next page after successful form submission
      this.router.navigate(['/view-tutors']);
    }
  }
}

