<div class="home-page">
  <!-- <app-running-offers></app-running-offers> -->
  <div class="container">
    <div class="location-withseach-home">
      <div class="sb-dropdonws address-drpdown" ngbDropdown placement="bottom-left" [autoClose]="true">
        <button class="dropdown-input" ngbDropdownToggle>
          <i class="bi bi-geo-alt-fill"></i>
          <span>{{cityName}}</span>
          <i class="bi bi-caret-down-fill"></i>
        </button>
        <ul class="" ngbDropdownMenu id="enter-location-manually">
          <div class="location-manually">

            <div class="row" id="addr-popup-block">
              <div class="col-md-6 col-6">
                <!-- <button (click)="gotoManageAddresses()" class="saved-addr-popup">Saved Address</button> -->
                <button (click)="addresssidepanel = true" class="saved-addr-popup">Saved Address</button>
              </div>
              <div class="col-md-6  col-6 add-new-addr-btn">
                <!-- <div class="add-address"  (click)="checkUserLogin()">Add Address</div> -->
                <button class="add-address" (click)="checkUserLogin()">Add Address</button>
              </div>
            </div>
            <div class="gps-address" (click)="getCurrentLocation()">
              <div><img src="assets/images/locate.png"> Get current location Using GPS</div>
              <!-- <div>Using GPS</div> -->
            </div>
            <div class="location pt-2">{{showLocation ? userLocation : ''}}</div>
            <div class="title-h pt-3">Enter location manually</div>

            <div class="sb-dropdonws" ngbDropdown placement="bottom">
              <!-- <button class="dropdown-input" ngbDropdownToggle></button> -->
              <i class="seach-icon bi bi-search"></i>
              <input #mapSearchFiled_4 placeholder="ex. Central London" type="text" class="search-data" />
            </div>
          </div>
        </ul>
      </div>

      <div class="sb-dropdonws services-drpdown" id="home-location-dropdown">
        <ng-container class="dropdown-input"></ng-container>
        <!-- <i class="seach-icon bi bi-search"></i> -->
        <ng-select [items]="catList" bindLabel="title" [closeOnSelect]="true" [multiple]="false"
          placeholder="Search for Services" [hideSelected]="true" [typeahead]="input$" class="dropdown-input">
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <a [routerLink]="'../providers/'+ item.slug_title">
              <img height="15" width="15" [src]="item.image" />
              <b>{{item.title}}</b>
            </a>
          </ng-template>
        </ng-select>

      </div>
    </div>
  </div>
  <!--  -->
  <p-sidebar [(visible)]="addresssidepanel" [baseZIndex]="10000">

    <div class="row add-panel-close">
      <div class="col-md-10">
        <h6 class="s-add-text">Saved Address List</h6>
      </div>
      <!-- <div class="col-md-2 text-right">
        <a (click)="addresssidepanel = false">
          <img src="assets/icons/cross.svg" alt="">
        </a>
      </div> -->
    </div>

    <!-- <div class="sb-dropdonws mt-5" ngbDropdown placement="bottom">
      <i class="seach-icon bi bi-search"></i>
      <input #mapSearchFiled_4  placeholder="ex. Hyderabad" type="text" class="search-data" />
    </div> -->

    <div class="saved-addr-list">
      <div class="manage-address-wrapper">


        <!-- <ng-container *ngFor="let address of addresses;let i = index;">
          <div class="manage-address-card" [class.checkd-address]="address.selected" (click)="homeAddressSelection(address?.address?.address_id, addressList[i].address_type)">
            <div class="check-loaction-icon" *ngIf="address.selected">
              <i class="bi bi-check-circle-fill"></i>
            </div>
            <div class="address-card-inner">
              <div class="type-of-address-icon">
                <img *ngIf="address.address_type=='Home'" src="assets/icons/home-icon.svg" alt="Home">
                <img *ngIf="address.address_type=='Work'" src="assets/icons/briefcase.svg" alt="Work">
                <img *ngIf="address.address_type!='Home' && address.address_type!='Work'"
                  src="assets/icons/location-icon.svg" alt="Other">
              </div>
            </div>
  
            <div class="address-card-inner ml-3">
              <div class="manage-address-title font-weight-bold">{{address.address_type}}</div>
              <div class="manage-address-title font-weight-bold">{{address.Work}}</div>
                <div class="manage-address-title font-weight-bold">{{address.Other}}</div> 
              <div class="manage-complete-address">{{buildAddress(address?.address)}}</div>
              <div class="address-action-btn">
                <a (click)="editAddress(address)">Edit</a>
                <a (click)="deleteAddress(address)">Delete</a>
              </div>
            </div>
          </div>
        </ng-container> -->
        <!--  -->

        <!-- <ng-container *ngFor="let item of addresses;let i = index;">
          <div class="location-branch" [class.checkd-address]="item.selected" (click)="homeAddressSelection(item.address.address_id, item.address_type)">
            <div class="check-loaction-icon" *ngIf="item.selected">
              <i class="bi bi-check-circle-fill"></i>
            </div>
            <div class="details-address">
              <div class="home-address-title font-weight-bold mb-2">{{item.address_type}}</div>
              <div class="address-gray">{{item.address.address1}}</div>
              <div class="address-gray">{{item.address.address2}}</div>
              <div class="address-gray">{{item.address.land_mark}}</div>
              <div class="address-gray">{{item.address.city}}</div>
              <div class="address-gray">{{item.address.state}}, {{item.address.country}},
                {{item.address.post_code}}
              </div>
            </div>
          </div>
        </ng-container> -->

        <!-- radio cards -->
        <div class="container">
          <div class="grid-wrapper grid-col-auto">

            <label for="radio-card-1" class="radio-card" *ngFor="let address of addresses;let i =index;" (click)="onSelectAddress(address)"  [class.selected-card]="address === selectedAddress">
              <input type="radio" name="radio-card2" [id]="i"  [checked]="address === selectedAddress"  [value]="address"/>

              <div class="card-content-wrapper">
                <span class="check-icon"></span>
                <div class="card-content manage-address-card">
                  <!-- address card -->
                  <div class="address-card-inner">
                    <div class="type-of-address-icon">
                      <img *ngIf="address.address_type=='Home'" src="assets/icons/home-icon.svg" alt="Home">
                      <img *ngIf="address.address_type=='Work'" src="assets/icons/briefcase.svg" alt="Work">
                      <img *ngIf="address.address_type!='Home' && address.address_type!='Work'"
                        src="assets/icons/location-icon.svg" alt="Other">
                    </div>
                  </div>
        
                  <div class="address-card-inner ml-3">
                    <div class="manage-address-title font-weight-bold">{{address.address_type}}</div>
                    <div class="manage-complete-address">{{buildAddress(address?.address)}}</div>
                    <div class="address-action-btn">
                      <a (click)="editAddress(address)">Edit</a>
                      <a (click)="deleteAddress(address)">Delete</a>
                    </div>
                  </div>
                  <!-- address card -->
                </div>
              </div>
            </label>
            

            <!-- <label for="radio-card-2" class="radio-card">
              <input type="radio" name="radio-card" id="radio-card-2" />
              <div class="card-content-wrapper">
                <span class="check-icon"></span>
                <div class="card-content">
                  
                </div>
              </div>
            </label> -->
            
          </div>
          
        </div>
        
        <!-- radio cards -->

        <div *ngFor="let address of addresses" class="manage-address-card d-none">
          <div class="address-card-inner">
            <div class="type-of-address-icon">
              <img *ngIf="address.address_type=='Home'" src="assets/icons/home-icon.svg" alt="Home">
              <img *ngIf="address.address_type=='Work'" src="assets/icons/briefcase.svg" alt="Work">
              <img *ngIf="address.address_type!='Home' && address.address_type!='Work'"
                src="assets/icons/location-icon.svg" alt="Other">
            </div>
          </div>

          <div class="address-card-inner ml-3">
            <div class="manage-address-title font-weight-bold">{{address.address_type}}</div>
            <div class="manage-complete-address">{{buildAddress(address?.address)}}</div>
            <div class="address-action-btn">
              <a (click)="editAddress(address)">Edit</a>
              <a (click)="deleteAddress(address)">Delete</a>
            </div>
          </div>
        </div>
      </div>
    </div>


  </p-sidebar>
  <!--  -->

  <app-popular-categories></app-popular-categories>
  <div class="divider-border"></div>
  <app-limited-offers></app-limited-offers>
  <!-- <app-top-bookings></app-top-bookings> -->
  <!-- <app-stay-home></app-stay-home> -->
  <!-- <div class="divider-border"></div> -->
  <!-- <app-what-customers-say></app-what-customers-say> -->
  <!-- <div class="divider-border"></div> -->
  <app-register-business *ngIf="!showAccount"></app-register-business>
  <app-footer></app-footer>