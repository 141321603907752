
<app-tutor-header ></app-tutor-header>
<div class="main-login-wrapper mt-5">
<div class="container header-session">
    <div class="row ">
        <div class="col-md-9 col-9">
            <h1>Log in</h1>
        </div>
        <div class="col-md-3 col-3">
            <img src="assets/icons/t-cross.svg" alt="" class="arrow-i" [routerLink]="['/onlinetutor-theme']">
        </div>
    </div>
</div>
<section>
<div class="login-wrapper container">
    <div class="parent-wrap">
<img src="assets/images/t-parent.png" alt="" class="tutor-img">
<h2>I am a Parent</h2>

<p>Manage payments or lessons for your child</p>
<button class="btn-tutor" (click)="setOption('parent')" type="button" [routerLink]="['/tutor/login']">{{ optionLabel }}</button>
    </div>
    <div class="parent-wrap">
        <img src="assets/images/t-student.png" alt=""class="tutor-img">
        <h2>I am a Student</h2>
<p>Have lessons, message your tutor or watch your lessons back</p>
<button class="btn-tutor" type="button" (click)="setOption('student')" [routerLink]="['/tutor/login']">{{ optionLabel }}</button>
    </div>

    <div class="parent-wrap">
        <img src="assets/images/t-pic.png" alt=""class="tutor-img">
        <h2>I am a Tutor</h2>
<p>Give lessons or manage bookings with your customers</p>
<button class="btn-tutor" (click)="setOption('tutor')" type="button" [routerLink]="['/tutor/login']"> {{ optionLabel }}</button>
    </div>
</div>
</section>
<section class="container mt-5">
<div class="row footer-wrap mt-5">
    <div class="col-md-4 col-12">
<p class="help-wr">Need help? Call us on<b> +44 (0) 203 773 6020</b> or email us</p>
    </div>
    <div class="col-md-4 col-12">
        <p >Help! I’m an <b>adult learner</b></p>
    </div>
    <div class="col-md-4  col-12 text-right">
        <button class="btn-signup" type="button"[routerLink]="['/mytutor/signup']">Sign up</button>
    </div>
</div>
</section>
</div>


