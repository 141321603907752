import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import { RouteConstants } from "../../model/route-constants";
import { environment } from 'src/environments/environment';
declare var jQuery: any;
@Component({
  selector: 'app-providers-list-card',
  templateUrl: './providers-list-card.component.html',
  styleUrls: ['./providers-list-card.component.scss']
})
export class ProvidersListCardComponent implements OnInit {
  @Input() providerListObj: any;
  branchList: any = [];
  selectedBranchName: any;
  currencyCode = environment.currencyCode;
  constructor(private router: Router, private dataService: DataService, private storageService: StorageService) { }


  ngOnInit(): void {
    this.providerListObj.distance = Math.round(this.providerListObj?.distance);
    this.storageService.removeItem("branchsDropdown");
  }

  gotoProviderDetailsPage(branchSlugName?: any,branchTd?:any) {
    jQuery('body, html').animate({ scrollTop: 0 }, 100)
    this.router.navigate([RouteConstants.PROVIDER_DETAILS], {
      queryParams: {        
        title: this.providerListObj.provider_slug_legal_name,
        providerId: this.providerListObj.provider_id,
        bId: !this.selectedBranchName ? this.providerListObj.branch_id : branchTd,
        branch_slug_title: !this.selectedBranchName ? this.providerListObj.branch_slug_name : branchSlugName
      }
    })
  }
  
  changeDropdownValue = (branchName?: any, branchSlugName?:any,branchTd?:any) => {
    let val: any = +this.providerListObj.business_category_id;
    let data = JSON.parse(<string>this.storageService.getItem("branchsDropdown"));
    data = data == null || data == "" ? [] : data;
    if (data?.indexOf(val) > -1) {
      console.log('new');
      this.selectedBranchName = branchName;
      this.gotoProviderDetailsPage(branchSlugName, branchTd);
    } else {
      console.log('else')
      data.push(val);
      this.storageService.setItem("branchsDropdown", JSON.stringify(data));

      this.getBranchAddressList();
    }

  }

  getBranchAddressList = () => {
    this.dataService.getBranchAddressList(this.providerListObj.provider_slug_legal_name).subscribe((res: any) => {
      if (res) {
        this.branchList = res.results;
      }
    });
  }

}