<div class="customer-reviews-container">
 <div class="container">
    <div class="inner-data-sec">
      <div class="header-titles">
        <div class="title-main">WHAT OUR CUSTOMERS SAY</div>
        <div class="sub-title">What makes us so big is our customer, we care for our customer and customer satisfaction is what our main focus is all around.</div>
      </div>


      <!-- Customer Carousel Wrapper -->
      <div id="multi-item-example" class="carousel slide carousel-multi-item" data-ride="carousel">
         
        
        <!-- Controls -->
        <div class="controls-top d-none">
          <a class="btn-floating" href="#multi-item-example" data-slide="prev"><i class="fa fa-chevron-left"></i></a>
          <a class="btn-floating" href="#multi-item-example" data-slide="next"><i class="fa fa-chevron-right"></i></a>
        </div>
        <!--/.Controls-->
        
         <!--Indicators-->
         <ol class="carousel-indicators">
          <li data-target="#multi-item-example" data-slide-to="0" class="active" ></li>
          <li data-target="#multi-item-example" data-slide-to="1"></li>
          <li data-target="#multi-item-example" data-slide-to="2"></li>
        </ol>
        <!--/.Indicators-->
        
        <!--Slides-->
        <div class="carousel-inner" role="listbox">
          <!--First slide-->
          <div class="carousel-item active">
            
            <!-- <div class="row row-feedback"> -->
              <div class="caurosal-wrapper">
                <div class= "caurosal-background">
                <div class="inner-data ">
                  <div class="desc-box"> You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service!#sitindiaI would recommend it to my friends and family! Cheer! 😍</div>
                  <div class="user-info-block">
                    <div class="img-block">
                      <img src="assets/icons/feedback/w-c-s-1.PNG">
                    </div>
                    <div class="user-details">
                      <div class="u-name">Kritika</div>
                      <!-- <div class="occupation">Housewife</div> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="clearfix d-none d-md-block caurosal-background">
                <div class="inner-data" >
                  <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service!#sitindiaI would recommend it to my friends and family! Cheer! 😍</div>
                  <div class="user-info-block">
                    <div class="img-block">
                      <img src="assets/icons/feedback/w-c-s-2.PNG">
                    </div>
                    <div class="user-details">
                      <div class="u-name">Rahul</div>
                      <!-- <div class="occupation">Student</div> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="clearfix d-none d-md-block caurosal-background">
                <div class="inner-data">
                  <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service!#sitindiaI would recommend it to my friends and family! Cheer! 😍</div>
                  <div class="user-info-block">
                    <div class="img-block">
                      <img src="assets/icons/feedback/w-c-s-3.png">
                    </div>
                    <div class="user-details">
                      <div class="u-name">Manpreet Singh</div>
                      <!-- <div class="occupation">Retail Shop Owner</div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
          </div>

          <!--/.First slide-->
          






 <!--Second slide-->
          <div class="carousel-item">
            
            <div class="caurosal-wrapper">

              <div class="caurosal-background">
                <div class="inner-data">
                  <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service!#sitindiaI would recommend it to my friends and family! Cheer! 😍</div>
                  <div class="user-info-block">
                    <div class="img-block">
                      <img src="assets/icons/feedback/w-c-s-4.png">
                    </div>
                    <div class="user-details">
                      <div class="u-name">Sakshhi</div>
                      <!-- <div class="occupation">Model</div> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="clearfix d-none d-md-block caurosal-background">
                <div class="inner-data">
                  <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service!#sitindiaI would recommend it to my friends and family! Cheer! 😍</div>
                  <div class="user-info-block">
                    <div class="img-block">
                      <img src="assets/icons/feedback/w-c-s-2.PNG">
                    </div>
                    <div class="user-details">
                      <div class="u-name">Rahul</div>
                      <!-- <div class="occupation">Student</div> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="clearfix d-none d-md-block caurosal-background">
                <div class="inner-data">
                  <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service!#sitindiaI would recommend it to my friends and family! Cheer! 😍</div>
                  <div class="user-info-block">
                    <div class="img-block">
                      <img src="assets/icons/feedback/w-c-s-3.png">
                    </div>
                    <div class="user-details">
                      <div class="u-name">Manpreet Singh</div>
                      <!-- <div class="occupation">Retail Shop Owner</div> -->
                    </div>
                  </div>
                </div>
             
              </div>
            </div>
          </div>
          <!--/.Second slide-->
          
          <!--Third slide-->
          <div class="carousel-item">
            
            <div class="caurosal-wrapper">

              <div class="caurosal-background">
                <div class="inner-data">
                  <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service!#sitindiaI would recommend it to my friends and family! Cheer! 😍</div>
                  <div class="user-info-block">
                    <div class="img-block">
                      <img src="assets/icons/feedback/w-c-s-4.png">
                    </div>
                    <div class="user-details">
                      <div class="u-name">Sakshhi</div>
                      <!-- <div class="occupation">Model</div> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="clearfix d-none d-md-block caurosal-background">
                <div class="inner-data">
                  <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service!#sitindiaI would recommend it to my friends and family! Cheer! 😍</div>
                  <div class="user-info-block">
                    <div class="img-block">
                      <img src="assets/icons/feedback/w-c-s-2.PNG">
                    </div>
                    <div class="user-details">
                      <div class="u-name">Rahul</div>
                      <!-- <div class="occupation">Student</div> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="clearfix d-none d-md-block caurosal-background">
                <div class="inner-data">
                  <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service!#sitindiaI would recommend it to my friends and family! Cheer! 😍</div>
                  <div class="user-info-block">
                    <div class="img-block">
                      <img src="assets/icons/feedback/w-c-s-3.png">
                    </div>
                    <div class="user-details">
                      <div class="u-name">Manpreet Singh</div>
                      <!-- <div class="occupation">Retail Shop Owner</div> -->
                    </div>
                  </div>
                </div>
             
              </div>
            </div>
          </div>
          <!--/.Third slide-->
        </div>
        <!--/.Slides-->
      </div>
      <!--/.Carousel Wrapper-->

      <!-- <div id="feedback" class="carousel slide" data-ride="carousel">

        
        <ul class="carousel-indicators">
          <li data-target="#feedback" data-slide-to="0" class="active"></li>
          <li data-target="#feedback" data-slide-to="1"></li>
          <li data-target="#feedback" data-slide-to="2"></li>
        </ul>
        
       
        <div class="carousel-inner">
          <div class="carousel-item active">
              <div class="row row-feedback">
                <div class="col-feedback col-12 col-sm-6 sol-md-3 col-lg-3 card-1">
                  <div class="inner-data">
                    <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service! <br/><span class="tag">#sitindia</span> <br/><br/> I would recommend it to my friends and family! Cheer! 😍</div>
                    <div class="user-info-block">
                      <div class="img-block">
                        <img src="assets/icons/feedback/w-c-s-1.PNG">
                      </div>
                      <div class="user-details">
                        <div class="u-name">Kritika</div>
                        <div class="occupation">Housewife</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-feedback col-12 col-sm-6 sol-md-3 col-lg-3 card-2">
                  <div class="inner-data">
                    <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service! <br/><span class="tag">#sitindia</span> <br/><br/> I would recommend it to my friends and family! Cheer! 😍</div>
                    <div class="user-info-block">
                      <div class="img-block">
                        <img src="assets/icons/feedback/w-c-s-2.PNG">
                      </div>
                      <div class="user-details">
                        <div class="u-name">Rahul</div>
                        <div class="occupation">Student</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-feedback col-12 col-sm-6 sol-md-3 col-lg-3 card-3">
                  <div class="inner-data">
                    <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service! <br/><span class="tag">#sitindia</span> <br/><br/> I would recommend it to my friends and family! Cheer! 😍</div>
                    <div class="user-info-block">
                      <div class="img-block">
                        <img src="assets/icons/feedback/w-c-s-3.png">
                      </div>
                      <div class="user-details">
                        <div class="u-name">Manpreet Singh</div>
                        <div class="occupation">Retail Shop Owner</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-feedback col-12 col-sm-6 sol-md-3 col-lg-3 card-4">
                  <div class="inner-data">
                    <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service! <br/><span class="tag">#sitindia</span> <br/><br/> I would recommend it to my friends and family! Cheer! 😍</div>
                    <div class="user-info-block">
                      <div class="img-block">
                        <img src="assets/icons/feedback/w-c-s-4.png">
                      </div>
                      <div class="user-details">
                        <div class="u-name">Sakshhi</div>
                        <div class="occupation">Model</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="carousel-item">
            <div class="row row-feedback">
              <div class="col-feedback col-12 col-sm-6 sol-md-3 col-lg-3 card-1">
                <div class="inner-data">
                  <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service! <br/><span class="tag">#sitindia</span> <br/><br/> I would recommend it to my friends and family! Cheer! 😍</div>
                  <div class="user-info-block">
                    <div class="img-block">
                      <img src="assets/icons/feedback/w-c-s-1.PNG">
                    </div>
                    <div class="user-details">
                      <div class="u-name">Kritika</div>
                      <div class="occupation">Housewife</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-feedback col-12 col-sm-6 sol-md-3 col-lg-3 card-2">
                <div class="inner-data">
                  <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service! <br/><span class="tag">#sitindia</span> <br/><br/> I would recommend it to my friends and family! Cheer! 😍</div>
                  <div class="user-info-block">
                    <div class="img-block">
                      <img src="assets/icons/feedback/w-c-s-2.PNG">
                    </div>
                    <div class="user-details">
                      <div class="u-name">Rahul</div>
                      <div class="occupation">Student</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-feedback col-12 col-sm-6 sol-md-3 col-lg-3 card-3">
                <div class="inner-data">
                  <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service! <br/><span class="tag">#sitindia</span> <br/><br/> I would recommend it to my friends and family! Cheer! 😍</div>
                  <div class="user-info-block">
                    <div class="img-block">
                      <img src="assets/icons/feedback/w-c-s-3.png">
                    </div>
                    <div class="user-details">
                      <div class="u-name">Manpreet Singh</div>
                      <div class="occupation">Retail Shop Owner</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-feedback col-12 col-sm-6 sol-md-3 col-lg-3 card-4">
                <div class="inner-data">
                  <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service! <br/><span class="tag">#sitindia</span> <br/><br/> I would recommend it to my friends and family! Cheer! 😍</div>
                  <div class="user-info-block">
                    <div class="img-block">
                      <img src="assets/icons/feedback/w-c-s-4.png">
                    </div>
                    <div class="user-details">
                      <div class="u-name">Sakshhi</div>
                      <div class="occupation">Model</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row row-feedback">
              <div class="col-feedback col-12 col-sm-6 sol-md-3 col-lg-3 card-1">
                <div class="inner-data">
                  <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service! <br/><span class="tag">#sitindia</span> <br/><br/> I would recommend it to my friends and family! Cheer! 😍</div>
                  <div class="user-info-block">
                    <div class="img-block">
                      <img src="assets/icons/feedback/w-c-s-1.PNG">
                    </div>
                    <div class="user-details">
                      <div class="u-name">Kritika</div>
                      <div class="occupation">Housewife</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-feedback col-12 col-sm-6 sol-md-3 col-lg-3 card-2">
                <div class="inner-data">
                  <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service! <br/><span class="tag">#sitindia</span> <br/><br/> I would recommend it to my friends and family! Cheer! 😍</div>
                  <div class="user-info-block">
                    <div class="img-block">
                      <img src="assets/icons/feedback/w-c-s-2.PNG">
                    </div>
                    <div class="user-details">
                      <div class="u-name">Rahul</div>
                      <div class="occupation">Student</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-feedback col-12 col-sm-6 sol-md-3 col-lg-3 card-3">
                <div class="inner-data">
                  <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service! <br/><span class="tag">#sitindia</span> <br/><br/> I would recommend it to my friends and family! Cheer! 😍</div>
                  <div class="user-info-block">
                    <div class="img-block">
                      <img src="assets/icons/feedback/w-c-s-3.png">
                    </div>
                    <div class="user-details">
                      <div class="u-name">Manpreet Singh</div>
                      <div class="occupation">Retail Shop Owner</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-feedback col-12 col-sm-6 sol-md-3 col-lg-3 card-4">
                <div class="inner-data">
                  <div class="desc-box">You guys are the best, I was worried about my haircut during this unexpected future, Thanks to one of the social media posts I came across and found your website, I booked a haircut service and in the same day I had my interview, the service guy came by and cut my hair with professional touch, the entire process was hygienic and really liked the service! <br/><span class="tag">#sitindia</span> <br/><br/> I would recommend it to my friends and family! Cheer! 😍</div>
                  <div class="user-info-block">
                    <div class="img-block">
                      <img src="assets/icons/feedback/w-c-s-4.png">
                    </div>
                    <div class="user-details">
                      <div class="u-name">Sakshhi</div>
                      <div class="occupation">Model</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="feature-data">
        <div class="f-title">Featured on</div>
        <div class="featured-on-img">
          <img src="assets/icons/feedback/featured.png">
        </div>
      </div> -->
    </div>
 </div>
</div>








