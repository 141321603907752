<div class="container" id="main-page-wrapper">
    <div class="top_content_wrapper">
        <h2 class="top-con">Simple Transparent Pricing</h2>
        <p>No Contracts, No Surprise Fees</p>
    </div>
    <div class="pricing_tabs">
        <p-tabView>
            <p-tabPanel header="Monthly">

                <div class="content-wrapper">
                    <div class="column-wrapper-one table-column-wrapper">
                        <img src="assets/images/Mask Group 6.png" class="img-wrapper" alt="">
                        <!-- <p class="odd_line">Included bookings </p>
                <p class="even_line"></p> -->
                        <table class="table table-striped mt-5">
                            <tbody>
                                <tr>
                                    <td>Included bookings </td>
                                </tr>
                                <tr>
                                    <td>Custom Features</td>
                                </tr>
                                <tr>
                                    <td>Users/Providers*</td>
                                </tr>
                                <tr>
                                    <td>Admin App </td>
                                </tr>
                                <tr>
                                    <td>Client App </td>
                                </tr>
                                <tr>
                                    <td>Booking Websites</td>
                                </tr>
                                <tr>
                                    <td>Booking Widgets </td>
                                </tr>
                                <tr>
                                    <td>Directory Listing </td>
                                </tr>
                                <tr>
                                    <td>Coupons & Gift Cards</td>
                                </tr>
                                <tr>
                                    <td>Sales (POS)</td>
                                </tr>
                                <tr>
                                    <td> Link Removal</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>



                    <!--------------------------------------------------------------->

                    <!----------------trail-->
                    <div class="column-wrapper">
                        <div class="row  table-column-wrapper mt-2">
                            <div class="col-md-4 pr-5">
                                <img src="assets/icons/free trial.svg" class="icon_free">
                            </div>
                            <div class="col-md-8">
                                <p class="top-heading">Free trail</p>
                                <p> <b class="price-wrap">£ 0</b><span class="month-wrapper">/Month</span></p>
                            </div>

                        </div>

                        <button type="button" class="trial-btn"> Star Free Trial</button>
                        <table class="table table-striped trial-wrapper mt-5">
                            <tbody>
                                <tr>
                                    <td>50 </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-------------------Basic card-->
                    <div class="column-wrapper">


                        <div class="row  table-column-wrapper mt-2">
                            <div class="col-md-4 pr-5">
                                <img src="assets/icons/Basic icon.svg" class="icon_free">
                            </div>
                            <div class="col-md-8">
                                <p class="top-heading">Basic</p>
                                <p><b class="price-wrap">£ 700</b><span class="month-wrapper">/Month</span></p>
                            </div>

                        </div>

                        <button type="button" class="trial-btn">Get Started</button>
                        <table class="table table-striped trial-wrapper mt-5">
                            <tbody>
                                <tr>
                                    <td>100 </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!---------Standard------------->
                    <div class="column-wrapper">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="bestSellerRgt">Popular</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <img src="assets/icons/standard-icon.svg" class="icon_free">
                            </div>
                            <div class="col-md-8">
                                <p class="top-heading">Standard</p>
                                <p> <b class="price-wrap">£ 1499</b><span class="month_wrapper">/Month</span></p>
                            </div>

                        </div>


                        <button type="button" class="trial-btn">Get Started</button>
                        <table class="table table-striped trial-wrapper mt-5">
                            <tbody>
                                <tr>
                                    <td>500 </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!----------------premium-->
                    <div class="column-wrapper">
                        <div class="row  table-column-wrapper mt-2">
                            <div class="col-md-4 pr-5">
                                <img src="assets/icons/premium-icon.svg" class="icon_free">
                            </div>
                            <div class="col-md-8">
                                <p class="top-heading">Premium</p>
                                <p> <b class="price-wrap">£2,499</b><span class="month-wrapper">/Month</span></p>
                            </div>

                        </div>
                        <button type="button" class="trial-btn">Get Started</button>
                        <table class="table table-striped trial-wrapper mt-5">
                            <tbody>
                                <tr>
                                    <td>2000 </td>
                                </tr>
                                <tr>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>50</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!----------------------code for mobile view-->
                <!--------------------free-trail---------->
                <div class="row  mobile-top-wrapper mt-5">
                    <div class="col-md-12">
                        <p class="mobile-free-trail-wrapper">Free trail</p>
                        <p class="plan__header-price-value">£ &nbsp;0
                            <span class="plan-header-month">/Month</span>

                        </p>
                    </div>
                </div>
                <div class="mobile-content-wrapper">
                    <div class="mobile-view-content-wrapper">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>Included bookings </td>
                                </tr>
                                <tr>
                                    <td>Custom Features</td>
                                </tr>
                                <tr>
                                    <td>Users/Providers*</td>
                                </tr>
                                <tr>
                                    <td>Admin App </td>
                                </tr>
                                <tr>
                                    <td>Client App </td>
                                </tr>
                                <tr>
                                    <td>Booking Websites</td>
                                </tr>
                                <tr>
                                    <td>Booking Widgets </td>
                                </tr>
                                <tr>
                                    <td>Directory Listing </td>
                                </tr>
                                <tr>
                                    <td>Coupons & Gift Cards</td>
                                </tr>
                                <tr>
                                    <td>Sales (POS)</td>
                                </tr>
                                <tr>
                                    <td> Link Removal</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="mobile-view-mobile">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>50/month</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
                <!-------------------------Mobileview------------->
                <!--------------------basic-trail--------->
                <div class="row  mobile-top-wrapper">
                    <div class="col-md-12">
                        <p class="mobile-free-trail-wrapper">Basic</p>
                        <p class="plan__header-price-value">£ &nbsp;700
                            <span class="plan-header-month">/Month</span>

                        </p>
                    </div>
                </div>
                <div class="mobile-content-wrapper">
                    <div class="mobile-view-content-wrapper">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>Included bookings </td>
                                </tr>
                                <tr>
                                    <td>Custom Features</td>
                                </tr>
                                <tr>
                                    <td>Users/Providers*</td>
                                </tr>
                                <tr>
                                    <td>Admin App </td>
                                </tr>
                                <tr>
                                    <td>Client App </td>
                                </tr>
                                <tr>
                                    <td>Booking Websites</td>
                                </tr>
                                <tr>
                                    <td>Booking Widgets </td>
                                </tr>
                                <tr>
                                    <td>Directory Listing </td>
                                </tr>
                                <tr>
                                    <td>Coupons & Gift Cards</td>
                                </tr>
                                <tr>
                                    <td>Sales (POS)</td>
                                </tr>
                                <tr>
                                    <td> Link Removal</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="mobile-view-mobile">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>100/month</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
                <!--------Standard mobile-wrapper-------->
                <div class="row  mobile-top-wrapper">
                    <div class="col-md-12">
                        <p class="mobile-free-trail-wrapper">Standard</p>
                        <p class="plan__header-price-value">£ &nbsp;1499
                            <span class="plan-header-month">/Month</span>

                        </p>
                    </div>
                </div>
                <div class="mobile-content-wrapper">
                    <div class="mobile-view-content-wrapper">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>Included bookings </td>
                                </tr>
                                <tr>
                                    <td>Custom Features</td>
                                </tr>
                                <tr>
                                    <td>Users/Providers*</td>
                                </tr>
                                <tr>
                                    <td>Admin App </td>
                                </tr>
                                <tr>
                                    <td>Client App </td>
                                </tr>
                                <tr>
                                    <td>Booking Websites</td>
                                </tr>
                                <tr>
                                    <td>Booking Widgets </td>
                                </tr>
                                <tr>
                                    <td>Directory Listing </td>
                                </tr>
                                <tr>
                                    <td>Coupons & Gift Cards</td>
                                </tr>
                                <tr>
                                    <td>Sales (POS)</td>
                                </tr>
                                <tr>
                                    <td> Link Removal</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="mobile-view-mobile">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>500/month</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
                <!------------------mobile-view-premium-->
                <div class="row  mobile-top-wrapper">
                    <div class="col-md-12">
                        <p class="mobile-free-trail-wrapper">Premium</p>
                        <p class="plan__header-price-value">£ &nbsp;2499
                            <span class="plan-header-month">/Month</span>

                        </p>
                    </div>
                </div>
                <div class="mobile-content-wrapper">
                    <div class="mobile-view-content-wrapper">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>Included bookings </td>
                                </tr>
                                <tr>
                                    <td>Custom Features</td>
                                </tr>
                                <tr>
                                    <td>Users/Providers*</td>
                                </tr>
                                <tr>
                                    <td>Admin App </td>
                                </tr>
                                <tr>
                                    <td>Client App </td>
                                </tr>
                                <tr>
                                    <td>Booking Websites</td>
                                </tr>
                                <tr>
                                    <td>Booking Widgets </td>
                                </tr>
                                <tr>
                                    <td>Directory Listing </td>
                                </tr>
                                <tr>
                                    <td>Coupons & Gift Cards</td>
                                </tr>
                                <tr>
                                    <td>Sales (POS)</td>
                                </tr>
                                <tr>
                                    <td> Link Removal</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="mobile-view-mobile">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>2000/month</td>
                                </tr>
                                <tr>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>5o</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
                <!------------------------------------>
            </p-tabPanel>
            <!--------------------------------------->
            <p-tabPanel header="Annual">
                <div class="content-wrapper">
                    <div class="column-wrapper-one table-column-wrapper">
                        <img src="assets/images/Mask Group 6.png" class="img-wrapper" alt="">
                        <!-- <p class="odd_line">Included bookings </p>
                <p class="even_line"></p> -->
                        <table class="table table-striped mt-5">
                            <tbody>
                                <tr>
                                    <td>Included bookings </td>
                                </tr>
                                <tr>
                                    <td>Custom Features</td>
                                </tr>
                                <tr>
                                    <td>Users/Providers*</td>
                                </tr>
                                <tr>
                                    <td>Admin App </td>
                                </tr>
                                <tr>
                                    <td>Client App </td>
                                </tr>
                                <tr>
                                    <td>Booking Websites</td>
                                </tr>
                                <tr>
                                    <td>Booking Widgets </td>
                                </tr>
                                <tr>
                                    <td>Directory Listing </td>
                                </tr>
                                <tr>
                                    <td>Coupons & Gift Cards</td>
                                </tr>
                                <tr>
                                    <td>Sales (POS)</td>
                                </tr>
                                <tr>
                                    <td> Link Removal</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!----------------trail-->
                    <div class="column-wrapper">
                        <div class="row  table-column-wrapper mt-2">
                            <div class="col-md-4 pr-5">
                                <img src="assets/icons/free trial.svg" class="icon_free">
                            </div>
                            <div class="col-md-8">
                                <p class="top-heading">Free trail</p>
                                <p> <b class="price-wrap">£ 0</b><span class="month-wrapper">/Month</span></p>
                            </div>

                        </div>

                        <button type="button" class="trial-btn"> Star Free Trial</button>
                        <table class="table table-striped trial-wrapper mt-5">
                            <tbody>
                                <tr>
                                    <td>50 </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-------------------Basic card-->
                    <div class="column-wrapper">


                        <div class="row  table-column-wrapper mt-2">
                            <div class="col-md-4 pr-5">
                                <img src="assets/icons/Basic icon.svg" class="icon_free">
                            </div>
                            <div class="col-md-8">
                                <p class="top-heading">Get Started</p>
                                <p><b class="price-wrap">£ 700</b><span class="month-wrapper">/Month</span></p>
                            </div>

                        </div>

                        <button type="button" class="trial-btn">Get Started</button>
                        <table class="table table-striped trial-wrapper mt-5">
                            <tbody>
                                <tr>
                                    <td>100 </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!---------Standard------------->
                    <div class="column-wrapper">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="bestSellerRgt">Popular</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <img src="assets/icons/standard-icon.svg" class="icon_free">
                            </div>
                            <div class="col-md-8">
                                <p class="top-heading">Standard</p>
                                <p> <b class="price-wrap">£ 1499</b><span class="month_wrapper">/Month</span></p>
                            </div>

                        </div>


                        <button type="button" class="trial-btn">Get Started</button>
                        <table class="table table-striped trial-wrapper mt-5">
                            <tbody>
                                <tr>
                                    <td>500 </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!----------------premium-->
                    <div class="column-wrapper">
                        <div class="row  table-column-wrapper mt-2">
                            <div class="col-md-4 pr-5">
                                <img src="assets/icons/premium-icon.svg" class="icon_free">
                            </div>
                            <div class="col-md-8">
                                <p class="top-heading">Premium</p>
                                <p> <b class="price-wrap">£2,499</b><span class="month-wrapper">/Month</span></p>
                            </div>

                        </div>
                        <button type="button" class="trial-btn">Get Started</button>
                        <table class="table table-striped trial-wrapper mt-5">
                            <tbody>
                                <tr>
                                    <td>2000 </td>
                                </tr>
                                <tr>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>50</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!---------Annual -------------------------->
            </p-tabPanel>

        </p-tabView>
    </div>


    <!-------------------->
    