import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.scss']
})
export class StudentProfileComponent implements OnInit {

  defaultLocation = 'Mumbai';

  constructor() { }

  ngOnInit(): void {
  }

  schoolsLocation: any = [
    {name: 'Hyderabad'},
    {name: 'Mumbai'},
    {name: 'Delhi'}
  ]

}
