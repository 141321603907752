import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactUsForm!: any;
  constructor(public dataService: DataService) { }
  formSubmitted = false;
  ngOnInit(): void {
    this.contactUsForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required, Validators.pattern(new RegExp("[0-9 ]{10}")), Validators.maxLength(10)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl(''),

    });
  }

  get contactUsFormControls() { return this.contactUsForm.controls; }

  // onSubmit = (event: any) => {
  //   event.preventDefault();
  //   this.formSubmitted = true;
  //   if (this.contactUsForm.valid) {
  //     const reqObj = {
  //         first_name: this.contactUsForm.value.firstName,
  //         last_name: this.contactUsForm.value.lastName,
  //         email: this.contactUsForm.value.email,
  //         mobile: this.contactUsForm.value.phone,
  //         message: this.contactUsForm.value.message
  //     }
  //     this.dataService.saveContact(reqObj).subscribe((res: any) => {
  //       if (res && res.success) {
  //         Swal.fire({
  //           text: res.message || "Request created successfully",
  //           icon: 'success',
  //           confirmButtonText: 'Ok',
  //           allowOutsideClick: false,
  //           allowEscapeKey: false
  //         }).then((data: any) => {
  //           if (data.isConfirmed) {
  //             this.contactUsForm.reset();
  //             this.formSubmitted = false;
  //           }
  //         });
  //       } else {
  //         Swal.fire({
  //           text: 'Error in contact us.',
  //           icon: 'error',
  //           confirmButtonText: 'Ok',
  //           allowOutsideClick: false,
  //           allowEscapeKey: false
  //         });
  //       }
  //     });
  //   }else{
  //     Swal.fire({
  //       text: 'Please fill Mandatory Fields',
  //       icon: 'error',
  //       confirmButtonText: 'Ok',
  //       allowOutsideClick: false,
  //       allowEscapeKey: false
  //     });
  //   }
  // }

  onSubmit = (event: any) => {
    event.preventDefault();
    this.formSubmitted = true;
    if (this.contactUsForm.valid) {
      const reqObj = {
        first_name: this.contactUsForm.value.firstName,
        last_name: this.contactUsForm.value.lastName,
        email: this.contactUsForm.value.email,
        mobile: this.contactUsForm.value.phone,
        message: this.contactUsForm.value.message,
      };
      this.dataService.saveContact(reqObj).subscribe(
        (res: any) => {
          if (res && res.success) {
            // Handle success message with SweetAlert
            Swal.fire({
              text: res.message || "Request created successfully",
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(() => {
              this.contactUsForm.reset();
              this.formSubmitted = false;
            });
          }
        }
      );
    }
  };


}
