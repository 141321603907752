 <div class="register-business-container d-none">
  <div class="container">
    <div class="inner-data-sec">
      <div class="header-titles">
        <div class="title-main">Register Your Business</div>
        <div class="sub-title">Join and provide service to various parts of the country, <br/>click on the below to get started</div>
      </div>
      <button class="sb-primary-btn" (click)="registerNow()">Register Now</button>
    </div>
  </div>
</div> 


<div class="register-wrapper container">
  <div class="left-wrap">
    <img src="assets/icons/register-img.svg" alt="" class="register-img">
  </div>
  <div class="right-wrap">
    <p class="register-cont">Register Your Business</p>
    <p class="join-cpntent">Join and provide service to various parts of the country, <br />click on the below to get
      started</p>
    <!-- 
<button (click)="registerNow()" class="register-btn">Register Now</button> -->
    <button class="register-btn" (click)="registerNow()">Register Now</button>
  </div>
</div>
<div class="container mt-5 mb-5" *ngIf="false">
  <img src="assets/icons/trusted-by.svg" alt="" class="trusted-wrap">
</div>