<div class="parent-signup-wrapper container">
    <img src="assets/images/t-pic.png" alt="" class="t-img">
    <h1>Welcome to the Tutor Application Process</h1>
   <p>Are you prepared to begin, despite it taking longer than 10 minutes to finish? 👋</p>
<div class="s-wrap" > <img src="assets/icons/sign-in-alt.svg" alt=""><a [routerLink]="['/tutor-form-steps']">SignUp</a></div>
<div class="s-wrap">  <img src="assets/icons/checkbox.svg" alt=""><a href="">Submit Application</a></div>
<div class="s-wrap"> <img src="assets/icons/checkbox.svg" alt=""> <a href="">complete Interview</a></div>
    </div>

                     
    