<div class="main-wrapper">
  <section class="hero-section">
    <nav>
      <div class="logo">My Logo</div>
      <input type="checkbox" id="click">
      <label for="click" class="menu-btn">
        <i class="fas fa-bars"></i>
      </label>
      <ul>
        <li><a [routerLink]="['/view-tutors']">Find a tutor</a></li>
        <li><a href="#">How it Works</a></li>

        <li><a [routerLink]="['/student-bookings']">Bookings</a></li>
        <li><a [routerLink]="['/tutor-signup']">Become a tutor</a></li>
        <li><a href="#">Blog</a></li>
      </ul>
      <div class="cart-wrapper">

        <ul>
          <a href="#" *ngIf="showLogin" class="login-btn" [routerLink]="['/mytutor/signup']">Signup</a>
          <a href="#" *ngIf="showSignup" class="sign-up" [routerLink]="['/tutor-login']">Login</a>


          <!-- <div class="dropdown" *ngIf="showSignout d-none">
            <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
            <img src="assets/images/avatar-img.jpg" alt=" " class="signout-img">
            </a>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <a class="dropdown-item" href="#"> <img src="assets/icons/s-user.svg" alt="">
                Your Profile</a>
 
              <a class="dropdown-item" href="#"><img src="assets/icons/s-gift.svg" alt="">
                Get Free Lessons</a>
              <a class="dropdown-item" href="#"><img src="assets/icons/s-monitor.svg" alt="">
                Trail Lesson Space</a>
              <a class="dropdown-item" href="#"><img src="assets/icons/s-mail.svg" alt="">
                Contact Us</a>
              <a class="dropdown-item" href="#"><img src="assets/icons/s-log-out.svg" alt="">
                Log Out</a>
            </div>
          </div> -->
          <div class="dropdown"  *ngIf="showSignout">
            <div class="dropdown-btn">
            <a> <img src="assets/images/avatar-img.jpg" alt=" " class="signout-img"></a>
              <!-- <span>Username</span> -->
            </div>
            <div class="dropdown-content">
              <a class="dropdown-item" [routerLink]="['/student_personal_details']"> <img src="assets/icons/s-user.svg" alt="">
                Your Profile</a>
 
              <a class="dropdown-item" href="#" [routerLink]="['/student/Changepassword']"><img src="assets/icons/h-lock.svg" alt="">
               Change Password</a>
               
                <a class="dropdown-item" href="#"><img src="assets/icons/h-settings.svg" alt="">
               Settings</a>
                
              <!-- <a class="dropdown-item" href="#"><img src="assets/icons/s-monitor.svg" alt="">
                Trail Lesson Space</a>
              <a class="dropdown-item" href="#"><img src="assets/icons/s-mail.svg" alt="">
                Contact Us</a> -->
              <a class="dropdown-item" href="#"><img src="assets/icons/s-log-out.svg" alt="">
                Log Out</a>
            </div>
          </div>
        </ul>
      </div>
    </nav>

  </section>
</div>