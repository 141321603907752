<div class="bg-color-of-pages">
    <app-breadcrumb></app-breadcrumb>
    <div class="container" *ngIf="!showSuccessBlock">
        <div class="inner-data-quotes">
            <div class="row data-quotes">
                <div class="col-lg-4 col-md-4 col-sm-6 col-12 quote-service-data">
                    <div class="service-data-box">
                        <div class="img-blcok">
                            <img [src]="detailsObj.providerLogoUrl">
                        </div>
                        <div class="pad-data">
                            <div class="title">{{detailsObj.providerName}}</div>
                            <div class="service-compnay">
                                <div class="s-img">
                                    <img [src]="detailsObj.serviceLogoUrl">
                                </div>
                                <div class="s-title">{{detailsObj.serviceName}}</div>
                            </div>
                            <div class="contact-title">Address</div>
                            <div class="details-address">
                                {{ removeEmptyFields([detailsObj?.branchAddress.address1, detailsObj?.branchAddress.address2, detailsObj?.branchAddress.land_mark,
                                    detailsObj?.branchAddress.city, detailsObj?.branchAddress.state, detailsObj?.branchAddress.contry,
                                    detailsObj?.branchAddress.post_code]) 
                                }}
                                 
                                <!-- {{detailsObj.branchAddress?.address1}}, {{detailsObj.branchAddress?.address2}},
                                {{detailsObj.branchAddress?.land_mark}}, {{detailsObj.branchAddress?.city}},
                                {{detailsObj.branchAddress?.state}},
                                {{detailsObj.branchAddress?.country}}, {{detailsObj.branchAddress?.post_code}} -->
                            </div>
                            <!-- <div class="address-gray">{{detailsObj.branchAddress.address1}}</div>
                            <div class="address-gray">{{detailsObj.branchAddress.address2}}</div>
                            <div class="address-gray">{{detailsObj.branchAddress.land_mark}}</div>
                            <div class="address-gray">{{detailsObj.branchAddress.city}}</div>
                            <div class="address-gray">{{detailsObj.branchAddress.state}}, {{detailsObj.branchAddress.country}},
                              {{detailsObj.branchAddress.post_code}}</div> -->
                            <!-- <div class="call-whats-app-info">
                                <div class="whatsapp">
                                <i class="bi bi-whatsapp"></i>
                                </div>
                                <div class="call">
                                <i class="bi bi-telephone"></i>
                                <span class="call-number">+91 99382776323</span>
                                </div>
                            </div> -->
                        </div>
                        <div class="availble-time">
                            <div class="title">Starting from
                                <!-- <span *ngIf="detailsObj.availability === 'home_only'">Home</span>
                                <span *ngIf="detailsObj.availability === 'center_only'">Center</span>
                                <span *ngIf="detailsObj.availability === 'both'">Both</span> -->
                            </div>
                            <div class="amount">{{detailsObj.price | currency : currencyCode}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-6 col-12 quote-form-data">
                    <ng-container *ngIf="true">
                        <div class="heading-text">Request Quote</div>
                        <div class="desc-text">Please fill in the below details so that we could get in touch </div>
                        <form [formGroup]="requestQuoteForm" (ngSubmit)="onSubmit($event)">
                            <div class="sg-input-text mb-3">
                                <div class="label-text f-w-600">Full Name<sup>*</sup></div>
                                <input type="text" placeholder="Full Name" class="input-text"
                                    formControlName="fullName">
                                <span class="error-text"
                                    *ngIf="formSubmited && requestQuoteFormControls.fullName.errors?.required">Full Name
                                    required</span>
                            </div>

                            <div class="sg-input-text mb-3">
                                <div class="label-text f-w-600">Email<sup>*</sup></div>
                                <input type="email" placeholder="Email" class="input-text" formControlName="email">
                                <span class="error-text"
                                *ngIf="formSubmited && requestQuoteFormControls.email.invalid">EMAIL
                                    required</span>
                            </div>

                            <div class="sg-input-text mb-3">
                                <div class="label-text f-w-600">Phone<sup>*</sup></div>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">{{countryCode}}</div>
                                    </div>
                                    <input class="input-text form-input" placeholder="Phone Number"
                                        formControlName="mobile" maxlength="10">
                                </div>
                                <span class="error-text"
                                    *ngIf="formSubmited && requestQuoteFormControls.mobile.errors?.pattern">Phone Number
                                    requires minimum 10 Characters</span>
                                <span class="error-text"
                                    *ngIf="formSubmited && requestQuoteFormControls.mobile.errors?.required">Phone
                                    required</span>
                            </div>


                            <div class="sg-input-text-area mb-3">
                                <div class="label-text f-w-600">Quote Details<sup>*</sup></div>
                                <textarea class="textarea-input" formControlName="quote"></textarea>
                                <span class="error-text"
                                    *ngIf="formSubmited && requestQuoteFormControls.quote.errors?.required">Quote
                                    Details required</span>
                            </div>

                            <div class="sg-input-text-area mb-3">
                                <re-captcha id="recaptcha" name="recaptcha" formControlName="captcha"></re-captcha>
                                <span class="error-text"
                                    *ngIf="formSubmited && requestQuoteFormControls.captcha.errors?.required">Recaptcha
                                    required</span>
                            </div>

                            <span class="error-text" *ngIf="errMsg">{{errMsg}}</span>

                            <div class="btn-continue row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <button class="sb-back-btn w-100" type="button"
                                        [routerLink]="'/home'">Cancel</button>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <button class="sb-primary-btn w-100" type="submit">Submit</button>
                                </div>
                            </div>
                        </form>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="thanks-page-container-data mb-4" *ngIf="showSuccessBlock">
        <!-- <div class="container">
            <div class="breadcrumb-data">
                <li class="breadcrumb-item" [routerLink]="'/home'">Home</li>
                <li class="breadcrumb-item">Request Quote</li>
            </div>
        </div> -->
        <div class="container p-0">
            <div class="thanks-message-info">
                <div class="img-block">
                    <img src="assets/icons/success-gif.gif">
                </div>
                <div class="content-block">
                    <div>
                        <div class="title">Thank you for your time</div>
                        <div class="title">Inquiry ID <b>{{inquiry_id}}</b></div>
                        <div class="info-text">We will get in touch with you after reviewing your <br /> quote request
                        </div>
                        <div class="back-link" [routerLink]="'/home'">Go to HomeScreen</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<app-footer></app-footer>