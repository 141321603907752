import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { RouteConstants } from 'src/app/model/route-constants';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
 
  signInForm: any;
 
  formSubmited = false;
  errMsg: any
  showPwdLabel = "password";
  productName = environment.productName;
  siteLogo = environment.siteLogo;
  constructor(
    public router: Router,
    private socialAuthService: SocialAuthService,
    public authService: AuthService, public storageService: StorageService) {
  }

  ngOnInit(): void {
    this.signInForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      pwd: new FormControl('', [Validators.required])
    });
  }

  get emailControls() { return this.signInForm.get('email'); }
  get pwdControls() { return this.signInForm.get('pwd'); }
  get agreeControls() { return this.signInForm.get('agree'); }

  onSubmit = (event: any) => {
    event.preventDefault();
    this.formSubmited = true;
    if (this.signInForm.valid) {
      this.authService.signIn(this.signInForm.value).subscribe((res: any) => {
        if (res && !res.errorCode) {
          if(res.role !== "CUSTOMER"){
            this.errMsg = "You dont have permissions to login.";
            return ;
          }
          console.log('success', res);
          this.redirectPage();
        } else {
          console.log('fail');
          this.errMsg = res.message;
        }
      });
    }
  }

  isVisible = () => {
    this.showPwdLabel = this.showPwdLabel === 'password' ? 'text' : 'password';
  }


  signInWithGoogle = () => {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data: any) => {
      if (data) {
        this.signInWithGoogle_FB(data);
      }
    });
  }


  signInWithFB = () => {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((data: any) => {
      if (data) {
        this.signInWithGoogle_FB(data);
      }
    });
  }

  signInWithGoogle_FB = (data: any) => {
    const reqObj = {
      name: data.name,
      email: data.email,
      google_token: data.authToken,
      google_id: data.id,
      gender: '',
      profile_pic: data.photoUrl,
      provider: data.provider
    }
    this.authService.signInWithGoogle_FB(reqObj).subscribe((res: any) => {
      if (res && !res.errorCode) {
        console.log('success', res);
        this.redirectPage(res);
      } else {
        console.log('fail');
        this.errMsg = res.message;
      }
      console.log(res);
    });
  }

  redirectPage = (fromState?: any) => {
    const redirectURL = JSON.parse(<string>this.storageService.getItem("redirectURL"));
    this.getLocation();
    if (fromState && fromState?.is_facebook_google && (fromState?.email === null || fromState?.email === undefined ||  fromState?.email !== "") && (fromState?.mobile === null || fromState?.mobile === undefined ||  fromState?.mobile === "")) {
      this.router.navigate([RouteConstants.PROFILE]);
    }else
    if (redirectURL !== null || redirectURL !== undefined) {
      this.router.navigateByUrl(redirectURL);
      this.storageService.removeItem("redirectURL");
    } else {
      this.router.navigate(['/home']);
    }
  }
  // GoToAdminURL
  goToAdminLogin() {
    window.location.href='https://sbos.accenflairapps.com/sign-in';
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        if (position) {
          console.log("Latitude: " + position.coords.latitude +
            "Longitude: " + position.coords.longitude);
          let lat = position.coords.latitude;
          let lng = position.coords.longitude;
          sessionStorage.setItem('latitude', String(lat));
          sessionStorage.setItem('longitude', String(lng));
        }
      },
        (error: any) => {
           console.log(error)
           sessionStorage.setItem('latitude', String('17.432576'));
           sessionStorage.setItem('longitude', String('78.4498688'));
        });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }
  

}
