import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })

export class StorageService {
  private storage: Storage;

  constructor() {
    if (localStorage.getItem('rememberMe') == 'true') {
      this.storage = localStorage;
    }
    else {
      this.storage = sessionStorage;
    }
  }

  rememberMe() {
    return localStorage.getItem('rememberMe') == 'true' ? true : false;
  }

  clearAll() {
    this.storage.clear();
  }

  isAuthenticated() {
    return this.storage.getItem('user_info') != null ? true : false;
  }

  setRememberMe() {
    localStorage.setItem('rememberMe', 'true');
  }

  setItem(key: string, value: any) {
    var data: any = value;
    if (typeof value == 'object') {
      data = JSON.stringify(value);
    }
    this.storage.setItem(key, data);
  }

  getItem(key: string) {
    return this.storage.getItem(key);
  }

  removeItem(key: string) {
    return this.storage.removeItem(key);
  }

}
