import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import * as moment from 'moment';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressPopupComponent } from 'src/app/components/address-popup/address-popup.component';
import { RouteConstants } from 'src/app/model/route-constants';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  cartItems: any;
  total = 0;
  taxAmt = 0;
  timeSlotsList: any;
  isSelectSlots = false;
  isSelectStaff = false;
  staffList: any = [];
  date = new Date();
  selectedAddress: any = null;
  addDaysToDate = () => {
    var res = new Date();
    res.setDate(res.getDate() + 15);
    return res;
  }
  minDate = { "year": this.date.getFullYear(), "month": this.date.getMonth() + 1, "day": this.date.getDate() };
  maxDate = { "year": this.addDaysToDate().getFullYear(), "month": this.addDaysToDate().getMonth() + 1, "day": this.addDaysToDate().getDate() };
  markDisabled: any;
  isSelectBookingDate = false;
  weekList: any;
  currencyCode = environment.currencyCode;
  isCartValid: boolean = false;
  showSelectedData: boolean = false;
  showAddRecurring: boolean = false;
  recurringForm!: FormGroup;
  dateSelected: any;
  selectedServiceOn: any;
  selectedRepeat: any;
  customRepeatText: string | undefined;
  customEndText: string | undefined;
  selectedEnd: any;
  repeatsEvery!: number;
  numServices!: number;
  selectedCustomRepeat: any;
  addresses: any = [];
  showCustomerAddressList: boolean = false;
  serviceDeliveryAddress: any;
  constructor(private dataService: DataService, private storageService: StorageService,
    public bs: BroadcastService, private router: Router, public calendar: NgbCalendar,
    public config: NgbDatepickerConfig, public formatter: NgbDateParserFormatter,
    private modalService: NgbModal) { }


  repeatList = ['Every Week', 'Every 2 Weeks', 'Every Month', 'Custom'];
  customRepeatList = ['Days', 'Weeks', 'Months', 'Years'];
  endsList = ['Never', 'On', 'After'];

  repeatServiceOn = [
    { serviceOn: 'Mon', value: 'Monday' },
    { serviceOn: 'Tue', value: 'Tuesday' },
    { serviceOn: 'Wed', value: 'Wednesday' },
    { serviceOn: 'Thu', value: 'Thursday' },
    { serviceOn: 'Fri', value: 'Friday' },
    { serviceOn: 'Sat', value: 'Saturday' },
    { serviceOn: 'Sun', value: 'Sunday' }
  ];

  closeCustomerAddressBar() {
    this.showCustomerAddressList = false;
  }

  selectDayOn(item: any) {
    this.selectedServiceOn = item;
  }

  onRepeatChange(event: any) {
    this.selectedRepeat = event;
    if (event !== 'Custom') {
      this.customRepeatText = '';
    }
  }

  onEndChange(event: any) {
    this.selectedEnd = event;
    if (event === 'On' || event === 'After') {
      this.customEndText = '';
    }
  }

  onSaveRecurring() {
    this.showSelectedData = true;
  }

  fetchDateselected() {
    console.log("Selected date is :", this.dateSelected);
  }

  getCurrentDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  ngOnInit(): void {
    this.getCartItems();
    this.getAddresses();

    this.recurringForm = new FormGroup({
      repeat: new FormControl('', Validators.required),
      repeatsEvery: new FormControl(),
      customRepeat: new FormControl(),
      ends: new FormControl('', Validators.required),
      endDate: new FormControl(),
      // selectedServiceOn: new FormControl('', Validators.required),
      numServices: new FormControl()
    });

  }


  getCartItems = () => {
    // debugger;
    this.total = 0;
    this.taxAmt = 0;
    let cartItems: any = this.storageService.getItem('cartItems');
    cartItems = (cartItems != null && cartItems != undefined) ? JSON.parse(cartItems).results : [];
    // this.isCartValid = cartItems.filter((item: any) => item.service.valid == undefined).length == 0;
    cartItems.forEach((item: any) => {
      if (item && item.service != null) {
        this.total = Math.round(this.total + (item.quantity * item.service.min_charge));
        this.taxAmt = Math.round(this.taxAmt + (item.quantity * item.service.min_charge * item.service.tax_percent / 100));
        item.service.schedule_date_temp = moment(item.service.schedule_date, "YYYY-MM-DD").toDate();
      }
      if (item && item.plan != null) {
        this.total = Math.round(this.total + (item.quantity * item.plan.amount));
        this.taxAmt = Math.round(this.taxAmt + (item.quantity * item.plan.amount * item.plan.service.tax_percent / 100));
        // item.plan.schedule_date_temp = moment(item.service.schedule_date, "YYYY-MM-DD").toDate();
      }
    });
    this.cartItems = cartItems;
    this.storageService.setItem('cartItems', { "results": this.cartItems });
  }

  deleteCart = (cart_id: any) => {
    // return;
    this.dataService.deleteCart(cart_id).subscribe((data: any) => {
      if (data) {
        this.storageService.removeItem('selectedPlan');
        this.getCart();
      }
    });
  }

  addToCart = (cartObj: any, fromState?: any, quantityVal?: any) => {
    let quantity = 0;
    if (fromState === 'add') {
      quantity = cartObj.quantity + 1;
    } else if (fromState === 'delete') {
      quantity = cartObj.quantity - 1;
    } else if (fromState === 'onChange') {
      quantity = +quantityVal;
    }
    if (quantity > 5) {
      Swal.fire({
        text: 'Quantity should not exceed 5',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        allowEscapeKey: false
      });
      return;
    }
    const reqObj = {
      address: cartObj?.address?.address_id,
      quantity: quantity,
      customer: JSON.parse(<string>this.storageService.getItem("user_info")).customer_id,
      service: cartObj.service ? cartObj?.service?.service_id : null,
      plan: cartObj.plan ? cartObj.plan.plan_id : null,
      booking_time: cartObj.booking_time,
      booking_date: cartObj.booking_date,
      staff: cartObj.staff?.employee_id || null
    }
    this.dataService.addToCart(reqObj).subscribe((data: any) => {
      if (data) {
        if (quantity === 0) {
          this.storageService.removeItem('selectedPlan');
        }
        this.getCart();
      }
    })
  }

  getCart = () => {
    this.dataService.cartItems().subscribe(() => {
      this.getCartItems()
      this.bs.broadcast('REFRESH_CART', 'count');
    }
    );
  }

  onFocusFn = (cartObj: any, event: any,) => {
    this.addToCart(cartObj, 'onChange', event.target.value);
  }

  deleteTotalCart = () => {
    this.dataService.deleteTotalCart().subscribe((data: any) => {
      if (data) {
        this.storageService.removeItem('cartItems');
        this.storageService.removeItem('selectedPlan');
        this.bs.broadcast('REFRESH_CART', 'count');
        this.getCartItems();
      }
    })
  }

  ChangeSlotList = (event: any, currentCartObj?: any) => {
    console.log("Time Slots Selection :", currentCartObj);
    // debugger;
    currentCartObj.service.schedule_time = event;
    let convertedTime = moment(event, 'hh:mm A').format('HH:mm')
    currentCartObj.service.schedule_time_hhmm = convertedTime;
    currentCartObj.service.lead_staff_id = currentCartObj.service.lead_staff_id === undefined ? 0 : currentCartObj.service.lead_staff_id;

    let cartItems: any = this.storageService.getItem('cartItems');
    cartItems = JSON.parse(cartItems);
    let serviceObj: any = cartItems.results.find((item: any) => item.cart_id == currentCartObj.cart_id);
    serviceObj.service.schedule_date = currentCartObj.service.schedule_date;
    serviceObj.service.schedule_time = currentCartObj.service.schedule_time;
    serviceObj.service.valid = true;
    this.storageService.setItem('cartItems', cartItems);
    let itemsCount = cartItems.results.length;

    this.isCartValid = cartItems.results.filter((item: any) => item.service.valid == true).length == itemsCount
    this.isSelectSlots = true;
  }

  getStaffList = (cObj: any) => {
    const req = {
      serviceId: cObj?.service?.service_id,
      branchId: cObj?.service?.serviceprovider_branch?.branch_id
    }
    this.dataService.getStaffList(req).subscribe((res: any) => {
      if (res) {
        this.staffList = res.results;
        console.log("Staff List:", this.staffList)
      }
    });
  }

  onBookingDateChange = (currentCartObj?: any, id?: any) => {
    // debugger;
    console.log("currentCartObj:", currentCartObj);
    let year = currentCartObj.service.schedule_date_temp.year;
    let month = currentCartObj.service.schedule_date_temp.month <= 9 ? '0' + currentCartObj.service.schedule_date_temp.month : currentCartObj.service.schedule_date_temp.month;
    let day = currentCartObj.service.schedule_date_temp.day <= 9 ? '0' + currentCartObj.service.schedule_date_temp.day : currentCartObj.service.schedule_date_temp.day;;
    let finalDate = day + "-" + month + "-" + year; // dd-MM-YYYY
    currentCartObj.service.schedule_date = year + "-" + month + "-" + day; // YYYY-MM-dd
    // currentCartObj.service.schedule_date_temp= finalDate;
    currentCartObj.service.schedule_time = undefined;
    let cartItems: any = this.storageService.getItem('cartItems');
    cartItems = JSON.parse(cartItems);
    let serviceObj: any = cartItems.results.find((item: any) => item.cart_id == currentCartObj.cart_id);
    serviceObj.service.schedule_date = currentCartObj.service.schedule_date;
    serviceObj.service.schedule_time = undefined;
    serviceObj.service.valid = false;
    this.storageService.setItem('cartItems', cartItems);
    this.getTimeSlots(currentCartObj);
    this.isSelectBookingDate = true;
    this.isCartValid = false;
  }

  getWeekSchedule = (cObj: any) => {
    const req = {
      branchId: cObj?.service?.serviceprovider_branch?.branch_id
    }
    this.dataService.getWeekSchedule(req).subscribe((res: any) => {
      if (res) {
        this.weekList = res.week_schedule;
        this.markDisabled = (date: NgbDate) => {
          let currentDay = this.calendar.getWeekday(date);
          switch (currentDay) {
            case 1: return !this.weekList.Monday.isOpen;
              break
            case 2: return !this.weekList.Tuesday.isOpen;
              break;
            case 3: return !this.weekList.Wednesday.isOpen;
              break;
            case 4: return !this.weekList.Thursday.isOpen;
              break;
            case 5: return !this.weekList.Thursday.isOpen;
              break;
            case 6: return !this.weekList.Saturday.isOpen;
              break;
            case 7: return !this.weekList.Sunday.isOpen;
              break;
            default: return []
          }
        };
      }
    });
  }

  ChangeStaffList = (event: any, currentCartObj?: any) => {
    this.isSelectStaff = true;
    currentCartObj.service.lead_staff = event?.user?.first_name + ' ' + event?.user?.last_name;
    currentCartObj.service.lead_staff_id = event?.employee_id;
    this.getTimeSlots(currentCartObj);
  }

  getTimeSlots = (cObj: any) => {
    this.timeSlotsList = [];
    console.log("Time Slots:", this.timeSlotsList)
    const reqObj = {
      branchId: cObj?.service?.serviceprovider_branch?.branch_id,
      bookingDate: cObj.service.schedule_date,
      staffId: cObj?.service.lead_staff_id === undefined ? 0 : cObj?.service.lead_staff_id
    }
    this.dataService.getAvailableTimeSlots(reqObj).subscribe((res: any) => {
      if (res) {
        const timeSlotsList = res.available_slots;

        let momentTimeSlots: any = [];
        let today = moment();
        if (today.format('YYYY-MM-DD') === cObj.service.schedule_date) {
          for (let timeObj of timeSlotsList) {
            let time = moment(timeObj, "hh:mm a").format('hh:mm a');
            let time1 = moment(time, "hh:mm a").format('HH');
            if (this.checkTime(time1)) {
              momentTimeSlots.push(time);
            }
          }
          this.timeSlotsList = momentTimeSlots
        } else {
          this.timeSlotsList = res.available_slots
        }

      }
    })

  }

  checkTime = (a: any) => {
    const now = moment();
    const hourToCheck = +a;
    const dateToCheck = now.hour(hourToCheck).minute(30);
    return moment().isBefore(dateToCheck);
  }

  submitRecurringForm() {
    if (this.recurringForm.valid) {
      this.showAddRecurring = false;
    }
    // if (this.recurringForm.valid) {
    //   Swal.fire('Success!', 'Recurring successfully added', 'success')
    //   this.showAddRecurring = false;
    // } else {
    //   Swal.fire('Error!', 'Please fill in all required fields!', 'error')
    // }
  }

  buildAddress(address: any) {
    let result: string = '';
    if (address !== null && address !== undefined && address !== '') {
      if (address.address1) {
        result = address.address1 + ", ";
      }

      if (address.address2) {
        result += address.address2 + ", ";
      }

      if (address.land_mark) {
        result += address.land_mark + ", ";
      }

      if (address.post_code) {
        result += address.post_code + ", ";
      }

      if (address.state) {
        result += address.state + ", ";
      }

      if (address.city) {
        result += address.city + ", ";
      }

      if (address.country) {
        result += address.country + ", ";
      }

      // Remove the last comma and space from the result
      if (result.length > 0) {
        result = result.slice(0, -2);
      }

      return result;
    }
    return
  }


  deleteAddress = (address: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to delete your address",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        this.dataService.deleteCustomerAddress(address.address.address_id).subscribe((resp: any) => {
          if (resp.success) {
            Swal.fire({
              text: resp.status,
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then((res: any) => {
              if (res.isConfirmed) {
                this.getAddresses();
              }
            });
          } else {
            Swal.fire({
              text: resp.error,
              icon: 'error',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            });
          }
        });
      }
    });
  }
  editAddress(address?: any) {
    this.showCustomerAddressList = false;
    const modalRef = this.modalService.open(AddressPopupComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    modalRef.componentInstance.addressObj = { address: address?.address, fromState: address ? 'Edit' : 'new ' };

    modalRef.result.then((result) => {
      if (result) {
        this.getAddresses();
        this.showCustomerAddressList = true;
      }
    });
  }

  getAddresses() {
    const userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
    this.dataService.getCustomerAddressList(userDetails?.customer_id).subscribe((resp: any) => {
      this.addresses = resp.address;
      let tempAddressList = this.addresses.filter((obj: any) => {
        if (obj?.address && obj?.address?.is_default) {
          return obj
        }
      });
      // if (this.addresses && tempAddressList?.length > 0) {
      //   this.serviceDeliveryAddress = tempAddressList[0];
      //   this.addAddressToCartObj(tempAddressList[0])
      // }
    });
  }

  // addAddressToCartObj = (addressObj: any) => {
  //   let cartItems: any = this.storageService.getItem('cartItems');
  //   cartItems = JSON.parse(cartItems);
  //   if (cartItems?.results?.length > 0) {
  //     cartItems?.results?.forEach((data: any) => {
  //       if (data) {
  //         data.address = addressObj;
  //       }
  //     })
  //   }
  //   this.storageService.setItem('cartItems', cartItems);
  // }
  onSelectDeliveryAddress(address: any) {
    this.serviceDeliveryAddress = address;
    this.selectedAddress = address;
    // this.addAddressToCartObj(address)
  }
  setAsDefault(address: any) {
    const payload = {
      new_address_id: address.address.address_id
    };

    this.dataService.setAsDefaultAddress(payload).subscribe(
      (response: any) => {
        if (response) {
          console.log('Address set as default:', response);

          const userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
          this.dataService.getCustomerAddressList(userDetails?.customer_id).subscribe((resp: any) => {
            this.addresses = resp.address;


            Swal.fire({
              text: 'Address has been successfully set as the default.',
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            });
          });
        }
      },
      (error: any) => {
        console.error('Error setting address as default:', error);

        Swal.fire({
          text: 'Error setting address as default.',
          icon: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        });
      }
    );
  }

  onCheckout() {
    // check if cart items has schedules being selected
    if (this.cartItems)
      this.router.navigate(['payment']);
  }

  onDateChange = (currentCartObj?: any, id?: any) => {
    if (currentCartObj?.service !== null) {

      let finalDate = moment(new Date(currentCartObj?.service?.schedule_date_temp), "YYYY-MM-DD").format('YYYY-MM-DD');
      let time = moment(new Date(currentCartObj?.service?.schedule_date_temp), "hh:mm a").format('hh:mm a');
      let time2 = moment(new Date(currentCartObj?.service?.schedule_date_temp), "HH:MM A").format('HH:MM A');
      console.log(finalDate, time, time2)
      let cartItems: any = this.storageService.getItem('cartItems');
      cartItems = JSON.parse(cartItems);
      let serviceObj: any = cartItems.results.find((item: any) => item.cart_id == currentCartObj.cart_id);
      serviceObj.service.schedule_date = finalDate;
      serviceObj.service.schedule_time = time;
      this.storageService.setItem('cartItems', cartItems);
    } else if (currentCartObj?.plan !== null) {
      console.log(new Date(currentCartObj?.plan?.schedule_date_temp))
      let finalDate = moment(new Date(currentCartObj?.plan?.schedule_date_temp), "YYYY-MM-DD").format('YYYY-MM-DD');
      let time = moment(new Date(currentCartObj?.plan?.schedule_date_temp), "hh:mm a").format('hh:mm a');
      let time2 = moment(new Date(currentCartObj?.plan?.schedule_date_temp), "HH:MM A").format('HH:MM A');
      let cartItems: any = this.storageService.getItem('cartItems');
      cartItems = JSON.parse(cartItems);
      let serviceObj: any = cartItems.results.find((item: any) => item.cart_id == currentCartObj.cart_id);
      if (serviceObj.plan !== null) {
        serviceObj.plan.schedule_date = finalDate;
        serviceObj.plan.schedule_time = time;
      }
      this.storageService.setItem('cartItems', cartItems);

    }

  }

  onChangePlanSelection = (currentPlan: any) => {
    currentPlan.plan.planSelection = true;
    currentPlan.selectedPlan = currentPlan.plan.name;
    this.getServiceDetails(currentPlan?.plan?.service?.service_id);
  }
  planList: any;
  getServiceDetails(service_id: any) {
    this.dataService.getServiceDetail(service_id).subscribe(
      (res: any) => {
        if (res && res.plans) {
          this.planList = res.plans;
        } else {
          console.error('Unexpected API response format:', res);
        }
      }
    );
  }

  setPlanSelection = (currentPlan: any) => {
    currentPlan.plan.planSelection = false;

    currentPlan.plan.amount = currentPlan.selectedPlan.amount;
    currentPlan.plan.name = currentPlan.selectedPlan.name;
    currentPlan.plan.expire_in_months = currentPlan.selectedPlan.expire_in_months;
    currentPlan.plan.discount_percentage = currentPlan.selectedPlan.discount_percentage;
    currentPlan.plan.expire_in_months = currentPlan.selectedPlan.expire_in_months;
    currentPlan.plan.is_deleted = currentPlan.selectedPlan.is_deleted;
    currentPlan.plan.min_bookings = currentPlan.selectedPlan.min_bookings;
    currentPlan.plan.planSelection = currentPlan.selectedPlan.planSelection;
    currentPlan.plan.plan_id = currentPlan.selectedPlan.plan_id;
    currentPlan.plan.recurring_interval = currentPlan.selectedPlan.recurring_interval;


    let cartItems: any = this.storageService.getItem('cartItems');
    cartItems = JSON.parse(cartItems);
    let serviceObj: any = cartItems.results.find((item: any) => item.cart_id == currentPlan.cart_id);
    if (serviceObj.plan !== null) {
      serviceObj.plan.amount = currentPlan.selectedPlan.amount;
      serviceObj.plan.name = currentPlan.selectedPlan.name;
      serviceObj.plan.expire_in_months = currentPlan.selectedPlan.expire_in_months;
      serviceObj.plan.discount_percentage = currentPlan.selectedPlan.discount_percentage;
      serviceObj.plan.expire_in_months = currentPlan.selectedPlan.expire_in_months;
      serviceObj.plan.is_deleted = currentPlan.selectedPlan.is_deleted;
      serviceObj.plan.min_bookings = currentPlan.selectedPlan.min_bookings;
      serviceObj.plan.planSelection = currentPlan.selectedPlan.planSelection;
      serviceObj.plan.plan_id = currentPlan.selectedPlan.plan_id;
      serviceObj.plan.recurring_interval = currentPlan.selectedPlan.recurring_interval;
      this.storageService.setItem('cartItems', cartItems);
    }
  }

  gotoServiceDetailsPage = (serviceObj: any) => {
    //jQuery('body, html').animate({ scrollTop: 0 }, 100)
    console.log("service obj:", serviceObj);
    if (window.location.hostname.toLowerCase()==environment.cookieDomain.toLowerCase()) {
      this.router.navigate([RouteConstants.PROVIDER_DETAILS], {
        queryParams: {
          title: serviceObj.service.service_provider.slug_legal_name,
          providerId: serviceObj.service.service_provider.service_provider_id,
          bId: serviceObj.service.serviceprovider_branch.branch_id,
          branch_slug_title: serviceObj.service.serviceprovider_branch.slug_service_branch_name,
          fromState: 'cart'
        }
      });
    } else {
      if (serviceObj?.service === null) {
        this.router.navigate([RouteConstants.SERVICE_DETAILS, serviceObj?.plan?.service?.service_category, serviceObj?.plan?.service?.slug_title], {
          queryParams: {
            fromState: 'cart'
          }
        });
      } else {
        this.router.navigate([RouteConstants.SERVICE_DETAILS, serviceObj?.service?.service_category?.service_category_id, serviceObj?.service?.slug_title], {
          queryParams: {
            fromState: 'cart'
          }
        });
      }
    }
  }


  gotoServicesPage = () => {
    if (window.location.hostname.toLowerCase()==environment.cookieDomain.toLowerCase()) {
      this.router.navigate([RouteConstants.HOME_PAGE]);
    } else {
      this.router.navigate([RouteConstants.ALL_SERVICES]);
    }
  }

}
