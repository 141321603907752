import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressPopupComponent } from 'src/app/components/address-popup/address-popup.component';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profileDetails: any;
  profileForm: any;
  formSubmited = false;
  countryCode = environment.countryCode;
  myFiles: string[] = [];
  myForm = new FormGroup({
    file: new FormControl('', [Validators.required])
  });
  fileName: any;
  imgURL: any;
  userInfo: any;
  addresses: any = [];
  activeTab:any=1;
  constructor(public dataService: DataService, public storageService: StorageService, public router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    
    this.userInfo = JSON.parse(<string>this.storageService.getItem('user_info'));
    this.getProfileDetails();
    this.getAddresses();
    this.profileForm = new FormGroup({
      fullName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phoneNum: new FormControl('', [Validators.required, Validators.pattern(new RegExp("[0-9 ]{10}")), Validators.maxLength(10)]),
      dob: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required])
    });

    this.route.queryParams.subscribe((params:any)=>{
      if(params.tab)
      {
        this.activeTab = JSON.parse(params.tab);
      }
    });
  }

  get emailControls() { return this.profileForm.get('email'); }
  get phoneNumControls() { return this.profileForm.get('phoneNum'); }
  get fullNameControls() { return this.profileForm.get('fullName'); }
  get dobControls() { return this.profileForm.get('dob'); }
  get genderControls() { return this.profileForm.get('gender'); }

  getProfileDetails = () => {
    const userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
    if (userDetails) {
      this.dataService.getCustomerProfile(userDetails?.customer_id).subscribe((res: any) => {
        if (res) {
          this.profileDetails = res;
          this.imgURL = this.profileDetails.profile_pic;
          this.profileDetails.profile_pic = this.profileDetails.profile_pic === null || this.profileDetails.profile_pic === '' || this.profileDetails.profile_pic === undefined ?  "assets/images/profile.png" : this.profileDetails.profile_pic;
          this.profileForm.setValue({
            fullName: this.profileDetails.user.first_name,
            email: this.profileDetails.user.email,
            phoneNum: this.profileDetails.mobile === null ? '' : this.profileDetails.mobile.replace('+44', ''),
            dob: this.profileDetails.date_of_birth,
            gender: this.profileDetails.gender
          });
        }
      })
    }
  }

  deleteAddress = (address: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to delete your address",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        this.dataService.deleteCustomerAddress(address.address.address_id).subscribe((resp: any) => {
          if (resp.success) {
            Swal.fire({
              text: resp.status,
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then((res: any) => {
              if (res.isConfirmed) {
                this.getAddresses();
              }
            });
          } else {
            Swal.fire({
              text: resp.error,
              icon: 'error',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            });
          }
        });
      }
    });
  }
  editAddress(address?: any) {
    const modalRef = this.modalService.open(AddressPopupComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    modalRef.componentInstance.addressObj = {address: address?.address, fromState: address ? 'Edit' :'new '};

    modalRef.result.then((result) => {
      if (result) {
        this.getAddresses();
      }
    });
  }
  getAddresses() {
    const userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
    this.dataService.getCustomerAddressList(userDetails?.customer_id).subscribe((resp: any) => {
      this.addresses = resp.address;
    });
  }

  onSubmit = (event: any) => {
    event.preventDefault();
    this.formSubmited = true;
    if (this.profileForm.valid) {
      const reqObj = {
        customer_id: this.profileDetails.customer_id,
        profile_pic: this.fileName ? environment.mediaApiurl + this.fileName : this.imgURL,
        user: {
          first_name: this.profileForm.value.fullName,
          last_name: '',
          email: this.profileForm.value.email
        },
        mobile: environment.countryCode + this.profileForm.value.phoneNum,
        date_of_birth: this.profileForm.value.dob,
        gender: this.profileForm.value.gender
      }
      this.dataService.updateCustomerProfile(reqObj).subscribe((res: any) => {
        if (res) {
          Swal.fire({
            text: 'Profile Updated Successfully',
            icon: 'success',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((res: any) => {
            if (res.isConfirmed) {
              this.userInfo = JSON.parse(<string>this.storageService.getItem('user_info'));
              if (!this.userInfo?.is_facebook_google && (this.userInfo?.email === null || this.userInfo?.email === undefined || this.userInfo?.email !== '') && (this.userInfo?.mobile === null || this.userInfo?.mobile === undefined || this.userInfo?.mobile === '')) {
                this.router.navigate(["/home"]);
              }
              this.getProfileDetails();
              this.formSubmited = false;
              this.removefile();
            }
          })
        }
      });
    }
  }

  removefile = () => {
    this.fileName = '';
    this.myFiles = [];
  }

  buildAddress(address: any) {
    let result: string = '';
    if (address.address1) {
      result = result + address.address1 + ",";
    }
    if (address.address2) {
      result = result + address.address2 + ",";
    }
    if (address.land_mark) {
      result =
        result + address.land_mark + ",";
    }
    if (address.post_code) {
      result =
        result + address.post_code + ",";
    }
    if (address.state) {
      result = result + address.state + ",";
    }
    if (address.city) {
      result = result + address.city + ",";
    }
    if (address.country) {
      result = result + address.country + ",";
    }
    return result;
  }
  onFileChange(event: any) {

    for (var i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
    }
    const formData = new FormData();
    for (var i = 0; i < this.myFiles.length; i++) {
      formData.append("image_url", this.myFiles[i]);
    }
    this.dataService.uploadFile(formData).subscribe((res: any) => {
      if (res) {
        let data = Object.assign({}, { res });
        this.myFiles = [];
        event.target.value = '';
        this.fileName = data.res;
        this.imgURL = environment.mediaApiurl + this.fileName
      }
    });
  }

  setAsDefault(address: any) {
    const payload = {
      new_address_id: address.address.address_id
    };

    this.dataService.setAsDefaultAddress(payload).subscribe(
      (response: any) => {
        if (response) {
          console.log('Address set as default:', response);

          const userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
          this.dataService.getCustomerAddressList(userDetails?.customer_id).subscribe((resp: any) => {
            this.addresses = resp.address;


            Swal.fire({
              text: 'Address has been successfully set as the default.',
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            });
          });
        }
      },
      (error: any) => {
        console.error('Error setting address as default:', error);

        Swal.fire({
          text: 'Error setting address as default.',
          icon: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        });
      }
    );
  }
}
