<!-- <div>
    <h2>Thank You! Please check your email.</h2>
    <p>We have sent a random password to your email ID, you can login <br />
        with your random password and you can reset it after login.</p>

    <button [routerLink]="['/sign-in']">Login</button>

</div> -->

<div class="thanks-page-container-data">
    <app-breadcrumb></app-breadcrumb>
    <div class="container">
        <div class="thanks-message-info">
            <div class="img-block">
                <img src="assets/icons/success-gif.gif">
            </div>
            <div class="content-block">
                <div *ngIf="fromState === 'register'">
                    <div class="title">Registration Successful</div>
                    <div class="info-text">We have sent a random password to your email ID, you can login with your
                        random password and you can reset it after login.</div>
                    <div class="back-link" [routerLink]="'/home'">Back to Home</div>
                </div>
                <div *ngIf="fromState === 'frgtpswd'">
                    <div class="title">New Password has been sent to your email.</div>
                    <div class="back-link" [routerLink]="'/home'">Back to Login</div>
                </div>
            </div>
        </div>
    </div>
</div>