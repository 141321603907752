import { Component, OnInit, Input} from '@angular/core';
import { RouteConstants } from "../../model/route-constants";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from 'src/app/services/data.service';
import { HttpErrorResponse } from '@angular/common/http';
declare var jQuery:any;
@Component({
  selector: 'app-top-services',
  templateUrl: './top-services.component.html',
  styleUrls: ['./top-services.component.scss']
})
export class TopServicesComponent implements OnInit {
  selectedCategory: string | undefined;
  serviceCategoryList: any;
  @Input() cardObj: any;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: any) => {
      this.selectedCategory = params.get('selectedCategory');
    });
    this.getCategories();
  }

  gotoRespectivePage(title: string) {
    let params = {
      selectedCategory: this.selectedCategory,
      serviceName: title
    }
    this.router.navigate([RouteConstants.PROVIDERS_LIST, params])
  }

  cardClick = () => {

    jQuery('body, html').animate({scrollTop: 0}, 100)
    // this.router.navigate([RouteConstants.PROVIDERS_LIST],{
    //   queryParams: {
    //     title: this.cardObj.slug_title
    //   }
    // });
    this.router.navigate([RouteConstants.PROVIDERS_LIST, this.cardObj.slug_title],{
      queryParams: {
        title: this.cardObj.slug_title
      }
    });
  }

  getCategories() {
    this.dataService.getCategories().subscribe((resp: any) => {
      console.log(resp);
      if (resp instanceof HttpErrorResponse) {
        if (resp.status == 0) {
          // this.toaster.error("could not reach to the servers,please try after some time");
        } else {
          // this.toaster.error("You are Unauthorized");
        }
      }
      this.serviceCategoryList = resp.results;
      console.log("service category", this.serviceCategoryList);

    });
  }
}