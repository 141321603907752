<div class="parent-signup-wrapper">
    <h1>What are your Personal Details</h1>
    <div class="tutor-signup container">
        <div class="login-container-page">
            <div class="login-form-section">
                <form>

                    <div id="input-wrap">
                        <div class="input-block">
                            <div class="label-text">Date of Birth</div>
                            <input type="date" class="input-form" id="" placeholder="select">
                        </div>
                    </div>

                    <div id="input-wrap">
                        <div class="input-block">
                            <div class="label-text">Phone Number</div>
                            <input type="Number" class="input-form" id="" placeholder="Enter your phone number" >
                    
                        </div>
                    </div>
                    <div id="input-wrap">
                        <div class="input-block">
                            <div class="label-text">Gender</div>
                    <ng-select
                    [items]="Gender"
                    bindLabel="name"
   
                    [(ngModel)]="selectedGender"
                    [multiple]="false">
    
                    </ng-select>
                        </div>
                    </div>

                    <div id="input-wrap">
                        <div class="input-block">
                            <div class="label-text">Nationality</div>
                    <ng-select
                    [items]="Nationality"
                    bindLabel="name"
                    [(ngModel)]="selectedNationality"
                    [multiple]="false">
    
                    </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <button class="btn-tutor" [routerLink]="['tutor/enough/details']">Back</button>
                        </div>
                        <div class="col-md-6">
                    <button class="btn-tutor" type="submit" [routerLink]="['/tutor/graduation/certificates']">Next</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>




    </div>
</div>