<div class="limited-offers-container">
  <div class="container">
    <div class="limited-border">
      <div class="header-titles">
        <div class="title-main">Limited Offers</div>
        <div class="sub-title">Terms & Conditions Apply*</div>
      </div>
      <div class="offers-container col-12 row">
        <div class="card-offers-limited col-sm-6 col-md-3 col-lg-3 col-12">
          <div class="img-block">
            <img src="assets/icons/limited-offers/limited-1.PNG">
          </div>
          <div class="title">Fridge Repair Services</div>
          <div class="offer-info">Upto 30% off till April 20th</div>
        </div>
        <div class="card-offers-limited col-sm-6 col-md-3 col-lg-3 col-12">
          <div class="img-block">
            <img src="assets/icons/limited-offers/limited-2.PNG">
          </div>
          <div class="title">Plumbing Services</div>
          <div class="offer-info">Upto 30% off till April 20th</div>
        </div>
        <div class="card-offers-limited col-sm-6 col-md-3 col-lg-3 col-12">
          <div class="img-block">
            <img src="assets/icons/limited-offers/limited-3.PNG">
          </div>
          <div class="title">Massage Services</div>
          <div class="offer-info">Upto 30% off till April 20th</div>
        </div>
        <div class="card-offers-limited col-sm-6 col-md-3 col-lg-3 col-12">
          <div class="img-block">
            <img src="assets/icons/limited-offers/limited-4.PNG">
          </div>
          <div class="title">AC Repair Services</div>
          <div class="offer-info">Upto 30% off till April 20th</div>
        </div>
      </div>
    </div>
  </div>
</div>
