<div class="popular-cat-container">
    <div class="container">
        <div class="popular-services" *ngIf="serviceCategoryList">
            <div class="inner-padding" *ngFor="let cat of serviceCategoryList" (click)="cardClick(cat)">
                <div class="card-service-p">
                    <div class="img-block">
                        <img [src]="cat.image">
                    </div>
                    <div class="service-title" title="{{cat.title}}">{{cat.title}}</div>
                </div>
            </div>
        </div>
    </div>
</div>