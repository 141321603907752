<div class="bg-container">
    <app-breadcrumb></app-breadcrumb>
    <div class="container service-details-container">
        <div class="serach-sort-block row">
            <div class="search-with-btn col-12 col-md-8 col-lg-8 col-sm-8 p-0">
                <div class="sb-dropdonws">
                    <div class="tabs-home-loactions">
                        <div class="tab-name" (click)="onSelectTabs('home_only')" [ngClass]="{'active-tab-data' :selectedTab === 'home_only'}"><i class="bi bi-check-circle-fill"></i> Home</div>
                        <div class="tab-name" (click)="onSelectTabs('center_only')"  [ngClass]="{'active-tab-data' :selectedTab === 'center_only' }"><i class="bi bi-check-circle-fill"></i> Center</div>
                        <div class="tab-name" (click)="onSelectTabs('both')"  [ngClass]="{'active-tab-data' : selectedTab === 'both' }"><i class="bi bi-check-circle-fill"></i> Both</div>
                    </div>
                    <button class="dropdown-input"></button>
                    <i class="seach-icon bi bi-search"></i>
                    <input  placeholder="Search Services" type="text" class="search-data" [(ngModel)]="searchKeyword" (keyup.enter)="onSearch()"/>
                </div>
                <button class="sb-primary-btn" id="provider-search-btn" (click)="onSearch()">Search</button>
                <button class="sb-primary-btn" id="provider-search-btn-1" (click)="onSearch()"><i class="seach-icon bi bi-search"></i></button>
            </div>
            <div class="sort-data col-12 col-md-4 col-lg-4 col-sm-4 p-0">
                <div class="label-text">Sort By :</div>
                <div class="sb-dropdonws" ngbDropdown placement="bottom">
                    <button class="dropdown-input" ngbDropdownToggle>{{defaultSortBy}}<i class="bi bi-chevron-down"></i></button>
                    <ul class="dropdown-menu" ngbDropdownMenu>
                        <li ngbDropdownItem>
                            <a class="dropdown_item" (click)="ChangeSortByFn('distance')">Distance</a>
                        </li>
                        <li ngbDropdownItem>
                            <a class="dropdown_item" (click)="ChangeSortByFn('rating')">Rating</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="heading-page" *ngIf="providerTitle">Top {{providerTitle}} in your area</div>
        <div class="sub-text" *ngIf="providerTitle">Based on your location</div>
        <div class="services-cards-col row" *ngIf="providersList" infinite-scroll (scrolled)="onScrollDown($event)" (scrolledUp)="onScrollUp($event)"
        [immediateCheck]="false">
            <div class="col-12 column-card-parent" *ngFor="let item of providersList">
                <app-providers-list-card [providerListObj]="item"></app-providers-list-card>
            </div>
        </div>
        <div *ngIf="providersList?.length === 0" class="text-center">

            <!-- <div class="provider-not-available text-center">
                <img src="assets/icons/Providers not Available.png" alt="" style="width: 25%;">
            </div> -->

            No Providers available for this category
        </div>
    </div>
</div>
<app-footer></app-footer>