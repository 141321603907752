import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {
  amount: any;
  buttonName: any;
  orderId: any;
  wallet: any;
  currencyCode = environment.currencyCode;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.amount = params.amt;
      this.orderId = params.bookingId;
      this.wallet = params.wallet === 'true' ? true : false;
      this.cdr.detectChanges();
    });

    if (this.wallet) {
      this.buttonName = 'View My Transactions';
       Swal.fire({
        title: 'Money Added',
        text: `Added £${this.amount} in wallet, check you email for receipt`,
        icon: 'success',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        allowEscapeKey: false
      });
    } else {
      this.buttonName = 'View My Bookings';
      // Swal.fire({
      //   title: 'Payment Success',
      //   text: `Thank you, we confirm payment of £${this.amount}, Please check you email for receipt/confirmation .`,
      //   icon: 'success',
      //   confirmButtonText: 'Ok',
      //   allowOutsideClick: false,
      //   allowEscapeKey: false
      // });
    }
  }

  goToBookingsPage = () => {
    if (this.wallet) {
      this.router.navigate(['/wallet'], { relativeTo: this.activatedRoute });
    }else {
    this.router.navigate(['/my-bookings'], { relativeTo: this.activatedRoute,   queryParams: {
      status: 'Pending'
    } });
    }
  }
}
