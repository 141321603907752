import { Component, OnInit } from '@angular/core';
import {NgWizardConfig, NgWizardService, STEP_STATE, StepChangedArgs, StepValidationArgs, THEME} from "ng-wizard";
import {of} from "rxjs";
declare var jQuery: any;

@Component({
  selector: 'app-register-free-trail',
  templateUrl: './register-free-trail.component.html',
  styleUrls: ['./register-free-trail.component.scss']
})

export class RegisterFreeTrailComponent implements OnInit {
  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };

  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.circles,
  };
  isValidTypeBoolean: boolean = true;

  constructor(private ngWizardService: NgWizardService) {

  }

  ngOnInit(): void {
  }

  showPreviousStep(event?: Event) {
    jQuery('body, html').animate({scrollTop: 200}, 100)
    this.ngWizardService.previous();
  }

  showNextStep(event?: Event) {
    jQuery('body, html').animate({scrollTop: 200}, 100)
    this.ngWizardService.next();
  }

  resetWizard(event?: Event) {
    this.ngWizardService.reset();
  }

  setTheme(theme: THEME) {
    this.ngWizardService.theme(theme);
  }

  stepChanged(args: StepChangedArgs) {
    console.log(args.step);
  }

  isValidFunctionReturnsBoolean(args: StepValidationArgs) {
    return true;
  }

  isValidFunctionReturnsObservable(args: StepValidationArgs) {
    return of(true);
  }

}
