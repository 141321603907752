<div class="parent-signup-wrapper">
    <h1>Do you have additional Qualifications?</h1>

    <div class="tutor-signup container">
        <!-- <div class="card"> -->
            <P>This could include university addmission test</P>
        <div class="input-block">


            <div class="label-text">Please select an option:</div>

            <input type="radio" name="select" value="yes" (click)="showTextInput = !showTextInput">&nbsp;
            <label for="male">yes</label>
            <div>
                <div class="y-wrap" *ngIf="showTextInput">
                    <label class="label-t" *ngIf="showTextInput"> HTML and CSS</label>
                    <input type="checkbox" *ngIf="showTextInput">

                    <div>
                        <label *ngIf="showTextInput" class="label-t"> Python</label>
                        <input type="checkbox" *ngIf="showTextInput">

                    </div>
                    <div>
                        <label *ngIf="showTextInput" class="label-t">Angular</label>

                        <input type="checkbox" *ngIf="showTextInput">
                    </div>


                </div>
            </div>

            <div> <input type="radio" name="select" value="no">&nbsp;
                <label for="female">no</label>
            </div>

            <div class="button-wrapper">
                <button class="btn-next" type="button" Next [routerLink]="['/tutor/enough/details']">Next
                    <!-- <img src="assets/icons/arrow-right-n.svg" alt="">     -->
                </button>
            </div>









        </div>
    </div>