import {Component, OnInit, Input} from '@angular/core';
import {environment} from "../../../environments/environment";
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from "../../services/data.service";
import {HttpErrorResponse} from "@angular/common/http";
import {CustomEventEmitter} from "../../services/customeventemitter.service";
import swal from "sweetalert2";
import {RouteConstants} from "../../model/route-constants";
declare var jQuery:any;

@Component({
  selector: 'app-popular-categories',
  templateUrl: './popular-categories.component.html',
  styleUrls: ['./popular-categories.component.scss']
})
export class PopularCategoriesComponent implements OnInit {
  serviceCategoryList: any;
  @Input() cardObj: any;
  maxFileSize = 0.5 * 1024 * 1024; // modify this to your desired max file size
  env_value: any = environment.apiUrl + 'media/';

  constructor(private dataService: DataService,
              private router: Router,
              private route: ActivatedRoute,
              private eventService: CustomEventEmitter,) {
  }

  ngOnInit() {
    this. getCategories();
  }

  getCategories() {
    this.dataService.getCategories().subscribe((res: any) => {
      if(res){
        this.serviceCategoryList = res
      };
    });
  }

  cardClick = (catObj:any) => {
    jQuery('body, html').animate({scrollTop: 0}, 100)
    // this.router.navigate([RouteConstants.PROVIDERS_LIST],{
    //   queryParams: {
    //     title: catId.slug_title
    //   }
    // })
console.log("card click: ");
    sessionStorage.setItem("SELECTED_BUSSINESS_CATEGORY", JSON.stringify(catObj));
    this.router.navigate([RouteConstants.PROVIDERS_LIST, catObj.slug_title]).catch((e:any)=>{
      console.log("route error:", e);
    }).then(()=>{
      console.log("route then:");
    })
  }

  editCategory(eve: any) {

    this.router.navigate(['/edit-category']).then(() => {
      let eventMessageObject = {
        service_id: eve,
        navigatedScreen: "service-list",
      };
      console.log("oneditservice", eventMessageObject);
      this.eventService.publish(JSON.stringify(eventMessageObject));
    })
    console.log("oneditService", eve);

  }


}