import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { RouteConstants } from "../../model/route-constants";
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from "@angular/router";
import { FormBuilder, Validators } from "@angular/forms";
import { MapsAPILoader } from '@agm/core';
import { AuthService } from "../../services/auth.service";
import { HttpErrorResponse } from "@angular/common/http";
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { filter } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { NavigationStart, Event as NavigationEvent } from '@angular/router';
import { environment } from 'src/environments/environment';
// import * as jQuery from 'jquery';

declare var jQuery: any;
// declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  userDetails: any
  currentRoute: any;
  @ViewChild('mapSearchFiled', { static: true }) searchFiled: ElementRef | undefined;
  isHeaderVisible = true;
  showAccount = false;
  isHome: boolean = false;
  userLocation: any = "";
  geoCoder: any;
  cartCount = 0;
  selectedAddress: any;
  addressLabel: any = '';
  walletAmt: any;
  routeEvents$: any;
  currencyCode = environment.currencyCode;
  siteLogo = environment.siteLogo;
  isProfileMenuOpen: boolean = false;
  isMainMenuOpen: boolean = false;
  isServiceProvider: boolean = false;

  constructor(private router: Router,
    public authService: AuthService,
    public storageService: StorageService,
    public bs: BroadcastService,
    public dataService: DataService,
    private cdr: ChangeDetectorRef
  ) {
    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(
      (event: any) => {
        this.currentRoute = event.url;
      }
    );
  }

  ngAfterViewInit() {
    this.bs.on("REFRESH_CART").subscribe((data: any) => {
      if (data === 'count') {
        let cartItems: any = this.storageService.getItem('cartItems');
        this.cartCount = cartItems === null || cartItems === undefined ? 0 : JSON.parse(cartItems).results?.length;
        this.cdr.detectChanges()
      } else if (data === 'wallet') {
        this.getWalletBalance();
        this.walletAmt = parseInt(JSON.parse(<string>this.storageService.getItem('walletAmt')));
        this.cdr.detectChanges()
      }
    });
    this.bs.on("userLocation").subscribe((data: any) => {
      if (data) {
        this.userLocation = data;
        this.cdr.detectChanges();
      }
    });
    if (this.showAccount) {
      this.getCartItems();
      this.getWalletBalance();
    }
  }

  ngOnInit(): void {
    this.routeEvents$ = this.router.events
      .subscribe(
        (event: NavigationEvent) => {
          if (event instanceof NavigationStart) {
            if (event.url.includes("/home")) {
              this.isHome = true;
            }
            else {
              this.isHome = false;
            }
          }
        });


    // @ts-ignore
    $(window).scroll(function () {
      const selfWindow = jQuery(window);
      if (selfWindow.scrollTop() >= 100) {
        // @ts-ignore
        document.getElementsByClassName('bottom-bar')[0].classList.add('fixed-top');
      } else {
        document.getElementsByClassName('bottom-bar')[0].classList.remove('fixed-top');
      }
    })


    this.authService.onUserLogin.subscribe((data: any) => {
      this.showAccount = data;
      this.userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
      if (this.showAccount) {
        this.getCartItems();
        this.getWalletBalance();
      }
    });
    this.showAccount = this.authService.isAuthenticated();
    this.userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
    this.bs.on("REFRESH_CART").subscribe((data: any) => {
      if (data === 'count') {
        let cartItems: any = this.storageService.getItem('cartItems');
        cartItems = JSON.parse(cartItems).results;
        this.cartCount = cartItems === null || cartItems === undefined ? 0 : cartItems?.length;
        this.cdr.detectChanges()
      } else if (data === 'wallet') {
        this.getWalletBalance();
        this.walletAmt = parseInt(JSON.parse(<string>this.storageService.getItem('walletAmt')));
        this.cdr.detectChanges()
      }
    });
    if (this.showAccount) {
      this.getCartItems();
      this.getWalletBalance();
    }
    this.userLocation = sessionStorage.getItem('location')
    jQuery(document).click(function (event: any) {
      if (jQuery(event.target).parents("#demo").length < 1) {
        var clickover = jQuery(event.target);
        var $navbar = jQuery(".down-inner-data");
        var _opened = $navbar.hasClass("show");
        if (_opened && !clickover.hasClass("show")) {
          $navbar.collapse('hide');
        }
      }
    });
    this.router.events.subscribe(event => {
      // if (event instanceof NavigationEnd) {
      //   this.isHeaderVisible = event.url !== '/tutor/signup/form';
      // }
      // if (event instanceof NavigationEnd) {
      //   this.isHeaderVisible = event.url !== '/tutor-login';
      // }
      // if (event instanceof NavigationEnd) {
      //   this.isHeaderVisible = event.url !== '/mytutor/signup';
      // }
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.isHeaderVisible = !['/tutor/signup/form', '/tutor-login', '/mytutor/signup', '/tutor/login', '/student/signup', '/parent/signup', '/tutor/qualification', '/tutor/school/qualification', '/tutor/subject/level', '/tutor/study/subjects', '/tutor/additional/qualification', '/tutor/enough/details', '/tutor/persoal-details', '/tutor/graduation/certificates', '/tutor/application'].includes(event.url);
        }
      });
    });

    this.isServiceProvider = (window.location.hostname.toLowerCase()==environment.cookieDomain.toLowerCase()) ? false : true
  }

  getCartItems = () => {
    this.dataService.cartItems().subscribe((res: any) => {
      if (res) {
        this.storageService.setItem('cartItems', JSON.stringify({ results: res.length === 0 ? [] : res }));
        this.cartCount = res?.length;
      }
    });
  }

  getWalletBalance = () => {
    this.dataService.getWalletBalance().subscribe((res: any) => {
      if (res) {
        this.walletAmt = parseInt(res.wallet_balance);
        this.storageService.setItem('walletAmt', JSON.stringify(res.wallet_balance));
      }
    });
  }

  goToRegisterBusiness() {
    this.router.navigate([RouteConstants.REGISTER_YOUR_BUSINESS])
  }

  goToCartPage() {
    this.router.navigate([RouteConstants.CART])
  }

  logout = () => {
    this.authService.logout();
  }

  onScroll = () => {
    jQuery('body, html').animate({ scrollTop: 0 }, 100);
  }
  // hideHamburgerMenu = () => {
  //   console.log("scrolled down!",);
  //   jQuery('#demo').collapse("hide");
  // }

  profileMenu() {
    this.isProfileMenuOpen = !this.isProfileMenuOpen;
  }

  toggleMenu() {
    this.isMainMenuOpen = !this.isMainMenuOpen;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (
      !target.closest('.hamberger-menu') &&
      !target.closest('.mobile-main-menu') &&
      !target.closest('.my-profile-menu') &&
      !target.closest('#profile-main-menu')
    ) {
      this.closeMenu();
    }
  }
  

  closeMenu() {
    this.isMainMenuOpen = false;
    this.isProfileMenuOpen = false;
  }

  navigateTo(route: string) {
    this.closeMenu();
    this.router.navigateByUrl(route);
  }

}