<div class="bg-color-of-pages">
  <!-- <app-breadcrumb></app-breadcrumb> -->
  <div class="container mt-5">
    <div class="my-bookings-container-data">
      <div class="heading-page">My Bookings</div>
      <div class="sort-data">
        <div class="label-text" id="mb-label-hide">Sort By :</div>
        <div class="sb-dropdonws" ngbDropdown placement="bottom">
          <button class="dropdown-input" ngbDropdownToggle>{{defaultSortBy}}<i class="bi bi-chevron-down"></i></button>
          <ul class="dropdown-menu" ngbDropdownMenu>
            <li ngbDropdownItem *ngFor="let item of sortList">
              <a class="dropdown_item" (click)="ChangeSortByFn(item)">{{item}}</a>
            </li>
          </ul>
        </div>
        <div class="label-text" id="mb-label-hide">&nbsp;&nbsp;&nbsp;&nbsp; Time Period  :</div>
        <div class="sb-dropdonws" ngbDropdown placement="bottom">
          <button class="dropdown-input" ngbDropdownToggle>{{defaultDate}}<i class="bi bi-chevron-down"></i></button>
          <ul class="dropdown-menu" ngbDropdownMenu>
            <li ngbDropdownItem *ngFor="let item of datesList">
              <a class="dropdown_item" (click)="ChangeDateFn(item)">{{item.name}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div infinite-scroll (scrolled)="onScrollDown($event)" (scrolledUp)="onScrollUp($event)">
        <div class="booking-data-block" *ngFor="let bObj of bookingsList;let  i = index;">
          <div class="top-sec">
            <div class="order-details">
              <!-- <span class="order-title">Order</span> -->
              <span class="order-title">Booking ID : </span>
              <span class="orderid">{{bObj.service_request_id}}</span>
            </div>
            <!-- <div class="overall-status">Success</div> -->
            <!-- <div class="order-text">Order placed : {{bObj.created_on}}</div> -->
            <div class="order-text">{{bObj?.created_on?.replace('T', ' ')?.replace('Z', ' ') | date :'dd MMMM  y h:mm a'}}</div>
          </div>
          <div class="sec-top">
            <div class="p-name">{{bObj?.service_provider?.legal_name}}</div>
            <div class="overall-status">{{bObj?.status}}</div>
            <!-- <div class="payment-due" *ngIf="bObj?.payment_status === 'AmountDue'"> -->
              <ng-container *ngIf="bObj.status == 'Pending' || bObj.status == 'InProgress'">
                <div class="payment-due" *ngIf="bObj?.payment_status?.toLowerCase() !== 'paid'">
                   <!-- <button class="btn" >Pay Due</button> -->
                   <span class="payment-due-btn" data-toggle="modal" data-target="#PayDueModal" (click)="onPayDue(bObj)">Pay Due</span>
                </div>
              </ng-container>
            <!-- <div class="order-text">Order placed : {{bObj.created_on | date :'dd MMMM  y h:mm a'}}</div> By Mohitha -->
          </div>
          <div class="booking-details">
            <div class="header-titles row col-12 d-none">
              <div class="col-8 title">Service(s)</div>
              <div class="col-2 title">Status</div>
              <div class="col-2 title">Service Date & Time</div>
            </div>
            <ng-container *ngIf="bObj.service_request_id === selectedCancelReqId  &&  bObj.cancel">
              <div class="sb-checkbox select-all">
                <input id="checkbox1" type="checkbox" class="checkbox-custom" name={{bObj.service_request_id}}
                  [checked]="bObj.isChecked" (change)="changeSelection(bObj, -1)">
                <label for="checkbox1" class="checkbox-custom-label">Select All</label>
              </div>
            </ng-container>

            <ng-container *ngIf="bObj?.recurring_service_plan !== null">
              <ng-container>
                <div class="body-table-data row col-12 if-cancel-booking">
                  <div class="col-12 col-md-12 col-sm-12 col-lg-12 services-block">     
                      <div class="sb-checkbox" *ngIf="bObj.service_request_id === selectedCancelReqId  &&  bObj.cancel">
                        <input id="tt_{{bObj?.booking_id}}" type="checkbox" class="checkbox-custom"
                          name="tt_{{bObj?.booking_id}}" [checked]="bObj.isChecked"
                          (change)="changeSelection(bObj, bObj?.recurring_service_plan?.service)">
                        <label for="tt_{{bObj.recurring_service_plan?.service?.booking_id}}" class="checkbox-custom-label"></label>
                      </div>
                    <div class="service-data">
                      <div class="img-block">
                        <img [src]="bObj.recurring_service_plan?.service?.image"/>
                      </div>
                      <div class="content-sec">
                        <div class="s-name">{{bObj.recurring_service_plan?.service?.title}}</div>
                        <div class="plan-name">Plan : {{bObj?.recurring_service_plan?.name}} &nbsp; | &nbsp; Validity : {{bObj?.recurring_service_plan?.expire_in_months}} month </div>
                        <!-- <div class="plan-name">Validity : {{bObj?.recurring_service_plan?.expire_in_months}} month  </div> -->
                        <div class="price-quantity">
                          <div class="price-q-title"></div>
                          <div class="tag-service"><span>{{bObj.service_delivery_location}}</span></div>
                          <div class="service-price text-right">Price: {{bObj.recurring_service_plan?.amount |currency:currencyCode}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="above-footer-sec">
                  <div class="status-title">{{bObj.recurring_service_plan?.service.status}}</div>
                  <div class="col-6 col-6 col-lg-2 col-md-2 col-sm-3 date-time text-right">{{bObj.schedule_start_datetime | date :'mediumDate' }} & {{bObj.preferred_time}}</div>
                </div>

              </ng-container>
            </ng-container>

            <ng-container *ngIf="bObj?.recurring_service_plan === null">
              <ng-container *ngFor="let item of bObj?.services">
                <div class="body-table-data row col-12 if-cancel-booking">
                  <div class="col-12 col-md-12 col-sm-12 col-lg-12 services-block">
                    <ng-container *ngIf="item.status != 'Cancelled'">
                      <div class="sb-checkbox" *ngIf="bObj.service_request_id === selectedCancelReqId  &&  bObj.cancel">
                        <input id="tt_{{item?.booking_id}}" type="checkbox" class="checkbox-custom"
                          name="tt_{{item?.booking_id}}" [checked]="item.isChecked"
                          (change)="changeSelection(bObj, item)">
                        <label for="tt_{{item?.booking_id}}" class="checkbox-custom-label"></label>
                      </div>
                    </ng-container>
                    <div class="service-data">
                      <div class="img-block">
                        <!-- <img src="assets/icons/staff.png"> -->
                        <img [src]="item.service?.image"/>
                      </div>
                      <div class="content-sec">
                        <div class="s-name">{{item?.service?.title}}</div>
                        <!-- <div class="p-name">{{bObj?.service_provider?.legal_name}}</div> -->
                        <div class="price-quantity">
                          <div class="price-q-title">Qty : {{item.quantity}}</div>
                          <div class="tag-service"><span>{{item?.service_delivery_location}}</span></div>
                           <!-- <span *ngIf="item?.service?.avail_at === 'home_only'">Home</span>
                          <span *ngIf="item?.service?.avail_at === 'center_only'">Location</span>
                          <span *ngIf="item?.service?.avail_at === 'both'">Both</span> -->
                            
                          <div class="service-price text-right">Price: {{item.service.min_charge |currency:currencyCode}}</div>
                        </div>
                      </div>
                    </div>
                      <!-- <div class="tag-service">
                    <span *ngIf="item?.service?.avail_at === 'home_only'">Home</span>
                    <span *ngIf="item?.service?.avail_at === 'center_only'">Location</span>
                    <span *ngIf="item?.service?.avail_at === 'both'">Both</span>
                  </div> -->
                  <!-- <div class="price-q-title" id="service-price">Price: {{item.service.min_charge |currency:currencyCode}}</div> -->
                  </div>
                    <!-- <div class="tag-service"><span>{{item?.service_delivery_location}}</span>
                  </div>
                </div>
                <div class="status-title col-6 col-lg-2 col-md-2 col-sm-3">{{item.status}}</div>
                <div class="col-6 col-6 col-lg-2 col-md-2 col-sm-3 date-time">{{item.schedule_date | date :'dd MMMM  y' }} &{{item.schedule_time}}</div> By Mohitha -->
                </div>

                <div class="above-footer-sec">
                  <div class="status-title">{{item.status}}</div>
                  <div class="col-6 col-6 col-lg-2 col-md-2 col-sm-3 date-time text-right">{{item.schedule_date | date :'mediumDate' }} & {{item.schedule_time}}</div>
                  <!-- <div class="date-time text-right">{{dataService.getLocalDateTime(item?.schedule_date+'T'+item?.schedule_time)}}</div> -->
                </div>

              </ng-container>
            </ng-container>

            <div class="bottom-footer">
              <div class="left-title write-review" *ngIf="bObj.status == 'Completed' && bObj.review === null"
                (click)="gotoReviewPage(bObj)">
                <span>Write A Review</span>
              </div>
                <div class="rating-data-with-name" *ngIf="bObj.status == 'Completed' && bObj.review !== null">
                  <div class="rating-sec">
                    <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  1 <= bObj?.rating}"></i>
                    <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  2 <= bObj?.rating}"></i>
                    <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  3 <= bObj?.rating}"></i>
                    <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  4 <= bObj?.rating}"></i>
                    <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  5 <= bObj?.rating}"></i>
                  </div>
                </div>
              <div class="left-title" *ngIf="bObj.status == 'Pending'  &&  !bObj?.cancel"
                (click)="cancelBooking(bObj, i)">
                <i class="bi bi-x-lg"></i>
                <span>Cancel Booking</span>
              </div>
              <div class="d-flex align-items-center cancel-back" *ngIf="bObj.service_request_id === selectedCancelReqId &&  bObj.cancel">
                <ng-container>
                  <div class="back-btn" (click)="onBackFn(bObj)">
                    <i class="bi bi-arrow-left"></i>
                    <span>Back</span>
                  </div>
                  <div class="cancel-btn ml-3" (click)="onCancelFn(bObj)">
                    <i class="bi bi-x-lg"></i>
                    <span>Cancel</span><br><br>
                  </div>
                  <span class="note-text pl-3"><i class="fa fa-info-circle" aria-hidden="true"></i> Deposit amount will get credited to wallet </span>
                </ng-container>
              </div>
              <div class="right-data"  [ngClass]="{'add':  bObj.status !== 'Pending' || bObj.status !== 'Completed' && bObj.review !== null }">
                <div class="view-download-invoice" *ngIf="bObj.status === 'Completed'" (click)="downloadInvoice(bObj)">
                  <ng-container >
                    <i class="bi bi-download"></i>
                    <span>Download invoice</span>
                  </ng-container>
                </div>
                <div class="booking-total text-right" *ngIf="!bObj.cancel">Booking Total : 
                  {{ bObj.payment_status === "Paid" ? ((bObj.amount + bObj?.taxes_amount)|currency:currencyCode) : 
                  bObj.payment_type === 'Deposit' ? (bObj?.deposit_amount|currency:currencyCode): ((bObj.amount + bObj?.taxes_amount)|currency:currencyCode)}}</div>
                <div class="booking-total" *ngIf="bObj.service_request_id === selectedCancelReqId  &&  bObj.cancel">Refund(inc. Tax) : 
                  {{selectedData?.length == 0 ? (0 | currency : currencyCode) : (refundAmount | currency : currencyCode)}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- New Order Design -->
      <!-- <div class="my-orders-main-section">
        <div class="my-order-main-card" *ngFor="let bObj of bookingsList;let  i = index;">

          <div *ngFor="let item of bObj?.services.slice(0, 1);" class="my-order-t-section">
            <div class="my-order-t-sec-left">
              <img [src]="item.service?.image" [alt]="item.service.title">
            </div>
            <div class="my-order-t-sec-middle">
              <p class="service-name">{{item.service.title}} &nbsp; {{bObj.services.length-1}}
                <span class="service-type">
                    <span *ngIf="item?.service?.avail_at === 'home_only'">Home</span>
                    <span *ngIf="item?.service?.avail_at === 'center_only'">Location</span>
                    <span *ngIf="item?.service?.avail_at === 'both'">Both</span>
                </span>
            </p>
              <p class="s-provider-name">{{bObj?.service_provider?.legal_name}}</p>
            </div>
            <div class="my-order-t-sec-right">
              
              <div class="dropdown">
                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="20" height="16"><circle cx="12" cy="2" r="2"/><circle cx="12" cy="12" r="2"/><circle cx="12" cy="22" r="2"/></svg>
                </button>

                <div class="dropdown-menu" id="dropdwn-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="#">Order Details</a>
                  <a class="dropdown-item" href="#">Download Invoice</a>
                </div>
              </div>
             
            </div>
          </div>

          

          <div class="my-order-details mt-3">
            <div class="my-order-inner-details">
              <p class="order-date" id="grey-text">Booking Period</p>
              <p class="order-value">{{bObj.created_on| date:'dd-MM-YYYY  hh:mm a'}}</p>
            </div>
            <div class="my-order-inner-details-right">
              <p class="order-id" id="grey-text">Booking ID</p>
              <p class="order-value">{{bObj.service_request_id}}</p>
            </div>
          </div>

          <div class="my-order-details mt-2" *ngFor="let item of bObj?.services">
            <div class="my-order-inner-details">
              <p class="order-qty" id="grey-text">Quantity</p>
              <p class="order-value">{{item.quantity}}</p>
            </div>
            <div class="my-order-inner-details-right">
              <p class="order-price" id="grey-text">Price</p>
              <p class="order-value">{{item.service.min_charge |currency:currencyCode}}</p>
            </div>
          </div>

          <div class="my-order-details" id="my-order-last-section">
            <div class="my-order-inner-details">
              <div class="rate-us" *ngIf="bObj.status == 'Completed'"
              (click)="gotoReviewPage(bObj)">
                <a href="#">Write A Review</a>
              </div>
            </div>
            <div class="my-order-inner-details-right">
              <div class="cancel-my-order text-right" *ngIf="bObj.status != 'Completed'">
                <a href="#">Cancel</a>
              </div>
            </div>
          </div>

        </div>
      </div> -->
      <!-- New Order Design -->

      <div class="text-center" *ngIf="bookingsList?.length == 0">
        
        <div class="container no-data-wrapper text-center">
          <div class="empty-cart-img text-center">
            <img src="assets/images/No data-rafiki.svg" alt="No data available">
          </div>
          <div class="text-center no-data-text">No data available</div>
        </div>

      </div>
      
    </div>
  </div>
</div>


<!-- Modal -->
<!--service selection-->

<div class="modal fade show model-custom w-600 service-selection-dialog d-block" id="PayDueModal" *ngIf="showPayDuePopup">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content p-4">
      <div class="modal-header">
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="showPayDuePopup=false">
          <i class="bi bi-x"></i>
        </button>
      </div>
      <h6 class="s-payment-method">Select Payment Method</h6>   
      
      <!--  -->
      <div class="radio-data-block">
        <div class="radio-custom-sb" [ngClass]="{'blurEffect': !showWallet}">
          <input type="radio" [(ngModel)]="paymentSource"  name="payonlinevist" id="Wallet" value="wallet" class="radio-custom" [disabled]="!showWallet">
          <label for="Wallet" class="radio-custom-label" >Pay Through Wallet</label>
          <div class="wallet-info">
            <i class="bi bi-wallet-fill"></i> 
            <span class="w-amount">{{+walletAmt}}</span>
          </div>
        </div>
      
      </div>
    
      <div class="radio-data-block">
        <div class="radio-custom-sb">
          <input type="radio" [(ngModel)]="paymentSource"  name="payonlinevist" id="payonline" value="online" class="radio-custom" checked="!showWallet">
          <label for="payonline" class="radio-custom-label" >Pay Online</label>
        </div>
      </div>

      <button (click)="onPay()" [disabled]="disabledBtn" *ngIf="depositType=='Deposit'"  class="sb-primary-btn w-100 mt-2">Pay {{depositAmt | currency: currencyCode:'symbol':'1.0-0'}}</button>
      <button (click)="onPay()" [disabled]="disabledBtn" *ngIf="depositType=='Full'"  class="sb-primary-btn w-100 mt-2">Pay {{(total + taxAmt) | currency: currencyCode:'symbol':'1.0-0'}}</button>

      <div class="paymenttype-logo">
        <div class="razorpay-logo"><img *ngIf="paymentGateway=='razorpay'" src="assets/images/Razorpay.svg" alt="Razorpay"></div>
        <div class="stripe-logo"><img *ngIf="paymentGateway=='stripe'" src="assets/images/Stripe.svg" alt="Stripe"></div>
      </div>

      <!--  -->
      

    </div>
  </div>
</div>
<div class="modal-backdrop fade show" *ngIf="showPayDuePopup"></div>


<div class="modal fade show model-custom w-600 service-selection-dialog d-block" id="PayDueModal" *ngIf="showRefundPopup">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content p-4">
      <div class="modal-header">
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="showRefundPopup=false">
          <i class="bi bi-x"></i>
        </button>
      </div>
      <!-- <h6 class="s-payment-method">Choose Deposit amount will get credited to wallet/account</h6> -->
      <h6 class="s-payment-method">Please select your preference</h6>  
      
      <!--  -->
     <div class="d-flex">
      <div class="radio-data-block mr-3">
        <div class="radio-custom-sb">
          <input type="radio" [(ngModel)]="refundType"  name="payonlinevist" id="Wallet" value="wallet" class="radio-custom">
          <label for="Wallet" class="radio-custom-label" >Refund to wallet</label>
        </div>
      
      </div>
    
      <div class="radio-data-block">
        <div class="radio-custom-sb">
          <input type="radio" [(ngModel)]="refundType"  name="payonlinevist" id="payonline" value="account" class="radio-custom">
          <label for="payonline" class="radio-custom-label" >Refund to your account</label>
        </div>
      </div>
     </div>
     <div class="form-group">
      <label>Reason for cancellation</label>
      <input type="text" [(ngModel)]="cancellationReason"  name="payonlinevist" id="payonline" value="account" class="form-control" placeholder="Reason for cancellation">
     </div>

      <button (click)="onRefundMethod()" class="sb-primary-btn w-100 mt-2">OK</button>

      <!--  -->
      

    </div>
  </div>
</div>
<div class="modal-backdrop fade show" *ngIf="showRefundPopup"></div>

<app-footer></app-footer>