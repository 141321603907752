<app-tutor-header></app-tutor-header>
<div class="session-wrap">
    <section class="schedule-wrap">
        <h1 class="page-title">Subject tutors that fit your schedule</h1>
        <p class="mt-4 mb-5">Find your perfect A Level tutor and arrange a Free Video Meeting. Then book one-to-one
            Online Lessons to fit
            your
            schedule.</p>
        <!-- <img src="assets/images/trustpilot-logo.svg" alt="" class="img-trust"> -->

    </section>
    <section class="subject-wrp">
        <div>
            <label for="">Subject</label>

            <ng-select [items]="subjects" bindLabel="name" [multiple]="false" [(ngModel)]="selectedSubjects">

            </ng-select>
        </div>
        <div>
            <label for="">Levels</label>

            <ng-select [items]="levels" bindLabel="name" [multiple]="false" [(ngModel)]="selectedLevel">

            </ng-select>
        </div>
        <div>
            <label for="">Price</label>

            <ng-select [items]="price" bindLabel="name" [multiple]="false" [(ngModel)]="selectedprice">

            </ng-select>
        </div>
        <div>
            <label for="">Gender</label>

            <ng-select [items]="gender" bindLabel="name" [multiple]="false" [(ngModel)]="selectedGender">

            </ng-select>

        </div>

    </section>

    <h2> Physics tutors available</h2>
    <section>
        <div class="tutors-session mt-5">
            <div class="session-right" [routerLink]="['/tutor/profile']">
                <div class="tutor-wrap"  [routerLink]="['/tutor-profile']"*ngFor="let tutor of tutorsList; let i = index;">

                    <div class="img">
                        <img [src]="tutor.tutorimg" alt="" class="tutor-img">
                      </div>
                    <div class="content-wrap">
                        <div class="row">
                            <div class="col-md-6 col-12">
                                <p class="tutormeta__name">{{tutor.tutorname}}</p>
                            </div>
                            <div class="col-md-6 text-right d-none">

                                <!-- <img src="assets/icons/f-heart.svg" alt=""></div> -->
                                <div (click)="toggleFavorite()">
                                    <i [ngClass]="{'fas': isFavorited, 'far': !isFavorited}" class="fa-heart"></i>
                                </div>
                                <i [ngStyle]="{'color': isFavorited ? 'red' : 'gray'}" (click)="toggleFavorite()"></i>
                            </div>
                        </div>
                        <!-- <img src="assets/icons/f-heart.svg" alt=""> -->
                        <p class="tutor-card-uni"> {{tutor.tutoreducation}}</p>
                        <p class="tutor-card-uni">{{tutor.qualification}} </p>
                        <!-- <p class="tutor-card-uni">Hi! My name’s Will and I’m studying an MPhil in European History at
                            Cambridge, specializing in the history of Germany.

                        </p> -->
                    </div>
                    <div class="content-wrap-review">

                        <p class="tutormeta__name">{{tutor.tutorfee}} <small> {{tutor.perhr}}</small></p>
                        <p><img [src]="tutor.ratingicon" alt=""> <span class="star-cont">{{tutor.rating}}</span></p>
                        <p class="tutor-card-info mt-3">{{tutor.review}}</p>
                        <p class="tutor-card-info">56 Lessons</p>
                        <button class="free-trail-btn" type="button" >View
                            Profile</button>

                    </div>
                </div>
            </div>
             <!--------------mobile-view-tutor-profile-->
    <section class="d-none">
        <div class="view-turor-wrap" *ngFor="let tutor of tutorsList; let i = index;">
 
                <div class="img">
                    <img [src]="tutor.tutorimg" alt="" class="tutor-m-img">
                  </div>
            <div class="right">
                <div class="row">
    <div class=" col-5">
        <p class="tutormeta__name">{{tutor.tutorname}}</p>
    </div>
    <div class="col-7">
        <p class="tutormeta__name">{{tutor.tutorfee}} <small> {{tutor.perhr}}</small></p>
    </div>
</div> 
<p class="truncate">{{tutor.tutoreducation}}</p>
<div class="r-wraper">
    <div class="">
  <img [src]="tutor.ratingicon" alt="" class="m-img"> &nbsp;<span class="tutor-r">{{tutor.rating}}</span>

    </div>
    <div class="">
        <p class="tutor-r">{{tutor.review}}</p>
    </div>
    <div class="">
        <p class="tutor-r">56 Lessons</p>
    </div>
</div> 
<div class="v-btn">
    <button class="btn-view" type="button"> View Profile</button></div>
            </div>
 </div>

       
                   
    </section>
    <!---------------------->
            <div class="specific-wrap ">
                <img src="assets/icons/tutor-to.svg" alt="">
                <h2 class="luk-wrap">Looking for something specific?</h2>
                <p>Fill out the online form and one of our tutor experts will be in touch with a shortlist of tutors for
                    you.</p>
                <!-- <button class="btn-tutor" type="button"> Find me aTutor</button> -->
                <p class="pt-3">Or call <span class="num-w">+44 (0) 203 773 6024 to</span></p>
                <p>speak to a tutor expert</p>
            </div>
        </div>
    </section>
   