<app-breadcrumb></app-breadcrumb>
<div class="container">
  <div class="inner-data-service-ratings">
    <app-about-service></app-about-service>
    <div class="reviews-list-block">
      <app-review-card></app-review-card>
      <app-review-card></app-review-card>
      <app-review-card></app-review-card>
      <app-review-card></app-review-card>
      <app-review-card></app-review-card>
      <app-review-card></app-review-card>
    </div>
  </div>
</div>

<app-footer></app-footer>
