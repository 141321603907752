<!-- <div class="pop">
  <div class="container">
<div class="service">Sugar Waxing Combo Pack</div>
<div class="right">
    <div class="cost">£500</div>
    <button class="btn btn-primary view" routerLink="/cart">View Cart</button>
</div>
  </div>
</div> -->


<div class="fixed-cart-data" *ngIf="cartDetails?.selectedList?.length !==0">
  <div class="container" id="cart-popup">
    <ng-container>
      <div class="data-content">
        <div class="cart-title">{{cartDetails.serviceName}}<span class="plus-items">&nbsp; +{{cartDetails?.selectedList?.length > 1 ? cartDetails?.selectedList?.length  - 1: ''}}</span><span class="more-link" *ngIf="cartDetails?.selectedList?.length > 2" routerLink="/cart">&nbsp; More ....</span></div>
        <div class="right-sec">
          <div class="count-fixed-width text-right">
            <div class="cart-count"><span class="qty-title">Qty : &nbsp; </span>  {{quantity}}</div>
          </div>
          <div class="amount">{{amount | currency: currencyCode:'symbol':'1.0-0' }}</div>
          <div class="view-cart-sec text-right">
            <button class="sb-primary-btn float-right" routerLink="/cart">View Cart</button>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- <span class="more-link" *ngIf="cartDetails?.selectedList?.length > 2" routerLink="/cart">More ....</span> -->
    <!-- <button class="sb-primary-btn float-right" routerLink="/cart">View Cart</button> -->
  </div>
</div>