import { Component, OnInit } from '@angular/core';

import Swal from 'sweetalert2';


@Component({
  selector: 'app-tutor-profile',
  templateUrl: './tutor-profile.component.html',
  styleUrls: ['./tutor-profile.component.scss']
})
export class TutorProfileComponent implements OnInit {
  showText = false;
  showDiv=false;
  selectedSubject = 'Social';
  editProfile: any;
  addNewSubject: any;
  addQualification: any;
  addCourse:any;
  addSkills:any;
  addOneOnOne: any;
  items:any;
  addExperience:any;
  addbookClass:any;
  val2: number = 3;
  fileList: any;
  formData: any;
  maxFileSize: any;
  config:any;
  //for table//
  Degree:string | undefined;
  Specialization: string | undefined;
  Acedamic_Year:number | undefined;
  Grade:String | undefined;
  selectedFile: any;
  public isUploading: boolean = false;
  public file: File | null = null;
  dropzoneConfig:any;
  dropzone: any;
  constructor() { }

  ngOnInit(): void {
  }
  grade:any=[
    {name:'A+'},
    {name:'A'},
    {name:'B'},
    {name:'B'}
  ]
  subjects: any = [
    { name: 'Social' },
    { name: 'Science' },
    { name: 'Mathematics' }
  ]

  subjectsList = [
    {subject: 'BSc Biochemistry'},
    {subject: 'Economics'},
    {subject: 'Chemistry'},
    {subject: 'Maths'},
    {subject: 'Physics'},
    {subject: 'BSc Biochemistry'},
    {subject: 'Economics'},
    {subject: 'Chemistry'},
    {subject: 'Maths'},

  ]
  grades:any=[
    {name:'A+'},
    {name:'A'},
    {name:'B'},
    {name:'B+'} 
   ]
 
 
   onSelect(event: { addedFiles: any[]; }) {
    this.selectedFile = event.addedFiles[0];
  }
  deleteSubject() {
    
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this subject",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your subject has been deleted.',
          'success'
        )
      }
    })
  }

 deleteQualification () {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this qualification",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your qualification has been deleted.',
          'success'
        )
      }
    })
  }
  deleteSkill () {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this Skill",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your Skill has been deleted.',
          'success'
        )
      }
    })
  }
  onFileChange(eve: any) {
    if(eve && eve.addedFiles.length>0){   
    if(eve.addedFiles[0].size > this.maxFileSize)
    {
      Swal.fire('','Image size should not exceed 0.5MB');
      return;
    }
    this.fileList = eve.addedFiles;
    console.log(this.fileList);
    let file: File = this.fileList[0];
    this.formData.set("image", file);
    // console.log(this.formData.get(this.serviceObj.image));
  }
  }
  //Method to remove files
  onRemove(event: any) {
    console.log(event);
    //event.File.splice(event.File.indexOf(event), 1);
    this.fileList.splice(this.fileList.indexOf(event), 1);
  }
  onFileSelected(event: any) {
    const files: File[] = event.target.files;
    this.dropzone.addFiles(files);
  }

  onUploadSuccess(event: any) {
    console.log('File uploaded successfully', event);
  }

}
