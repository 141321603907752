import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  signUpForm: any;
  formSubmited = false;
  errMsg: any;
  showSuccessPage = false;
  countryCode = environment.countryCode;
  productName = environment.productName;
  constructor(public authService: AuthService,
    public router: Router,
    private socialAuthService: SocialAuthService) { }

  ngOnInit(): void {
    this.signUpForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      mobile: new FormControl('', [Validators.required, Validators.pattern(new RegExp("[0-9 ]{10}")), Validators.maxLength(10)]),
      name: new FormControl('', [Validators.required]),
      agree: new FormControl(false, [Validators.requiredTrue])
    });
  }


  get emailControls() { return this.signUpForm.get('email'); }
  get phoneNumControls() { return this.signUpForm.controls;}
  // get phoneNumControls() { return this.signUpForm.get('phoneNum'); }
  get nameControls() { return this.signUpForm.get('name'); }
  get agreeControls() { return this.signUpForm.get('agree'); }

  onSubmit = (event: any) => {
    event.preventDefault();
    this.formSubmited = true;
    this.errMsg ='';  
    if (this.signUpForm.valid) {
      this.signUpForm.value.phoneNum = environment.countryCode+this.signUpForm.value.mobile;
      this.authService.signUp({...this.signUpForm.value, domain: window.location.hostname}).subscribe((res: any) => {
        if (res && res.Error === undefined) {
          this.showSuccessPage = true;
        } else {
          this.errMsg = res.Error;
        }
      });
    }
  }

  signInWithGoogle = () => {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data: any) => {
      if (data) {
        const reqObj = {
          name: data.name,
          email: data.email,
          google_token: data.authToken,
          google_id: data.id,
          gender: '',
          profile_pic: data.photoUrl,
          provider: data.provider
        }
        this.authService.signInWithGoogle_FB(reqObj).subscribe((res: any) => {
          if (res && !res.errorCode) {
            console.log('success', res);
            this.router.navigate(['/home']);
          } else {
            console.log('fail');
            this.errMsg = res.message;
          }
        })
        console.log(data);
      }
    });
  }


signInWithFB =() =>  {
  this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((data: any) =>{
    if (data) {
      const reqObj = {
        name: data.name,
        email: data.email,
        google_token: data.authToken,
        google_id: data.id,
        gender: '',
        profile_pic: data.photoUrl,
        provider: data.provider
      }
      this.authService.signInWithGoogle_FB(reqObj).subscribe((res: any) => {
        if (res && !res.errorCode) {
          console.log('success', res);
          this.router.navigate(['/home']);
        } else {
          console.log('fail');
          this.errMsg = res.message;
        }
      })
      console.log(data);
    }
  });
}


}
