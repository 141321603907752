<div class="container">
  <h2>Please select age </h2>
  <div class="grid-wrapper grid-col-auto">
   
    <label for="radio-card-1" class="radio-card">
      <input type="radio" name="radio-card" id="radio-card-1" (click)="showText()" checked />
      <div class="card-content-wrapper">
        <span class="check-icon"></span>
        <div class="card-content">
          <img src="assets/icons/childrens-s.svg" alt="" />
          <h4>Below 18</h4>
          <h5>Please provide your student information below, as well as your parent or guardian's information</h5>
        </div>
      </div>
    </label>
  
    <label for="radio-card-2" class="radio-card">
      <input type="radio" name="radio-card" id="radio-card-2" (click)="showTextInput()" />
      <div class="card-content-wrapper">
        <span class="check-icon"></span>
        <div class="card-content">
          <img src="assets/icons/students-s.svg" alt="" />
          <h4>Above 18</h4>
          <h5>Please provide your student information below</h5>
        </div>
      </div>
    </label>
  

    <!-- /.grid-wrapper -->
  </div>

  <div class=""*ngIf="isUnder18Selected" (click)="goBack()">
    <button type="button" class="c-wrap" [routerLink]="['/primary/student/signup']">Continue</button>
    <button type="button" class="back-wrap" [routerLink]="['/mytutor/signup']">back</button>
   </div>
    <div class="cl"*ngIf="!isUnder18Selected" (click)="continue()">
      <button  class="c-wrap" type="button" [routerLink]="['/student/signup']">Continue</button>
      <button type="button" class="back-wrap" [routerLink]="['/mytutor/signup']">back</button>

  </div>
  </div>
