<div class="container bg-color-of-pages">
    <div class="row">
        <div class="col-md-6">
            <h3 class="page-title">Contact Us</h3>
            <p class="page-desc mt-3">Reach out to us by any means you are comfortable with. We will address your
                queries
                within a few hours, if not instantly</p>
        </div>
    </div>
    <div class="contact-card-wrapper">
        <div class="contact-card-item text-center">
            <img src="assets/icons/contact/c-one.svg" alt="">
            <p class="contact-card-text">Ask us anything</p>
        </div>
        <div class="contact-card-item text-center">
            <img src="assets/icons/contact/c-two.svg" alt="">
            <p class="contact-card-text">Reach us anytime</p>
        </div>
        <div class="contact-card-item text-center">
            <img src="assets/icons/contact/c-three.svg" alt="">
            <p class="contact-card-text">Let an expert guide you</p>
        </div>
    </div>

    <div class="row contact-form-wrapper">
        <div class="col-md-6">
            <h2 class="mt-4">Get in touch with us</h2>
            <p class="page-desc mt-3">Reach out to us by any means you are comfortable with. We will address your
                queries within a few hours, if not instantly</p>

            <div class="contact-ways">
                <span>
                    <img src="assets/icons/contact/c-four.svg" alt="">
                </span>
                <span class="ml-3">
                    <a href="tel:+447761209908">
                        +44 7761 209908
                    </a>
                </span>
            </div>

            <div class="contact-ways mt-4">
                <span>
                    <img src="assets/icons/contact/c-five.svg" alt="">
                </span>
                <span class="ml-3">
                    <a href="mailto:contact@scheduleitnow.co.uk">
                        contact@scheduleitnow.co.uk
                    </a>
                </span>
            </div>

            <div class="contact-ways mt-4">
                <span>
                    <img src="assets/icons/contact/c-six.svg" alt="">
                </span>
                <span class="ml-3">
                    <a href="https://wa.me/+447761209908" target="_blank">
                        +44 7761 209908
                    </a>
                </span>
            </div>

            <!-- <div class="schedule-now-btn">
                <a href="https://calendly.com/test-accenflair/accenflair-team" target="_blank">Schedule Now</a>
            </div> -->

        </div>
        <div class="col-md-6">
            <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit($event)">
                <div class="row mt-4">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" id="firstName" placeholder="First name"
                                formControlName="firstName"
                                [ngClass]="{'invalid': contactUsFormControls.firstName.invalid && formSubmitted}">
                            <div *ngIf="contactUsFormControls.firstName.invalid && formSubmitted" class="error-message">
                                First name is required.
                            </div>
                        </div>

                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <!-- <label for="">Last name</label> -->
                            <input type="text" class="form-control" id="" placeholder="Last name"
                                formControlName="lastName"
                                [ngClass]="{'invalid':  contactUsFormControls.lastName.invalid &&  formSubmitted}">
                                <div *ngIf="contactUsFormControls.lastName.invalid && formSubmitted" class="error-message">
                                    Last name is required.
                                </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <!-- <label for="">Email</label> -->
                            <input type="text" class="form-control" id="" placeholder="Email address"
                                formControlName="email"
                                [ngClass]="{'invalid':  contactUsFormControls.email.invalid &&  formSubmitted}">
                                <div *ngIf="contactUsFormControls.email.invalid && formSubmitted" class="error-message">
                                    Email is required.
                                </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" id="phone" placeholder="Phone number"
                                   formControlName="phone"
                                   [ngClass]="{'invalid': contactUsFormControls.phone.invalid && formSubmitted}"
                                   maxlength="10">
                            
                            <div *ngIf="contactUsFormControls.phone.invalid && formSubmitted" class="error-message">
                                Phone number is required and should be 10 digits or less.
                            </div>
                        </div>                        
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <!-- <label for="">Your message</label> -->
                            <textarea class="form-control" placeholder="Your message" id="" rows="3"
                                formControlName="message"></textarea>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <button class="btn btn-block" id="submit-button" type="submit">Submit your message</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>

<app-footer></app-footer>