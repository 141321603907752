import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-tutor-dashboard',
  templateUrl: './tutor-dashboard.component.html',
  styleUrls: ['./tutor-dashboard.component.scss']
})
export class TutorDashboardComponent implements OnInit {
  data: any;
  dataone:any;
  selectedButton: string | undefined;

  options:any;
 
  chartOptions: any;
  dataBookings:any
  value:any;
  datapie:any;
  values:any;
  dataenrollment:any;
  selectedBox: string = 'box1';
  basicDataone: any;
  bsInlineValue = new Date();
  bsInlineRangeValue: Date[];
  maxDate = new Date();
  datepicker: any;
  inputVisible: boolean | undefined;
  constructor() {
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.bsInlineRangeValue = [this.bsInlineValue, this.maxDate];
   }
  selectBox(box: string) {
    this.selectedBox = box;
  }
  hideInput() {
    this.inputVisible = false;
  }

  showInput() {
    this.inputVisible = true;
  }
  ngOnInit(): void {
    this.data = {
      labels: ['1','2','3', '4', '5','6','7','8','9','10','11','12','1'],
      datasets: [
        {
          label: 'Total Courses',
          backgroundColor: '#42A5F5',
          data: [65, 59, 80, 81, 56, 55]
        }
      ]
    };
    this.dataenrollment = {
      labels: ['1','2','3', '4', '5','6','7','8','9','10','11','12','1'],
      datasets: [
        {
          label: 'Total Enrollment',
          backgroundColor: '#42A5F5',
          data: [65, 59, 80, 81, 56, 55]
        }
      ]
    };
    this.dataBookings = {
      labels: ['1','2','3', '4', '5','6','7','8','9','10','11','12','1'],
      datasets: [
        {
          label: 'Total Bookings',
          backgroundColor: '#42A5F5',
          data: [65, 59, 80, 81, 56, 55]
        }
      ]
    };
  
    this.options = {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            stepSize: 20,
            callback: function(value: string, index: any, values: any) {
              return value + '%'; // Customize the format of Y-axis labels
            }
          }
        }]
      }
    };
  this.dataone = {
    labels: ['1','2','3', '4', '5','6','7','8','9','10','11','12','1'],
    datasets: [
      {
        type: 'line',
        label: 'Dataset 1',
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: '#4bc0c0'
      },
      {
        type: 'bar',
        label: 'Dataset 2',
        data: [28, 48, 40, 19, 86, 27, 90],
        backgroundColor: '#42A5F5',
        hoverBackgroundColor: '#64B5F6'
      }
    ]
  };

  this.options = {
    responsive: true,
    title: {
      display: true,
      text: 'Combo Chart'
    },
    tooltips: {
      mode: 'index',
      intersect: true
    }
  };


this.datapie = {
  labels: ['Maths','English','Chemistry'],
  datasets: [
      {
          data: [30, 50, 10],
          backgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56"
          ],
          hoverBackgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56"
          ]
      }]    
  };
  this.basicDataone = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug','Sep','Oct','Nov','Dec'],
    datasets: [
        {
            label: 'First Dataset',
            data: [65, 59, 80, 81, 56, 55, 40,90,50,70],
            fill: false,
            borderColor: '#42A5F5',
            tension: .4
        }
        // {
        //     label: 'Second Dataset',
        //     data: [28, 48, 40, 19, 86, 27, 90],
        //     fill: false,
        //     borderColor: '#FFA726',
        //     tension: .4
        // }
    ]
};
  }
}
  


