import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cart-pop',
  templateUrl: './cart-pop.component.html',



  styleUrls: ['./cart-pop.component.scss']
})
export class CartPopComponent implements OnInit, OnChanges {
  @Input() cartDetails: any
  tempCartDetails: any = [];
  amount = 0;
  quantity = 0;
  currencyCode = environment.currencyCode;
  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.cdr.detectChanges();
    if (this.tempCartDetails) {
      this.tempCartDetails = this.cartDetails.selectedList.slice(0, 1);
    }
  }
  
  ngOnChanges() {
    if (this.tempCartDetails) {
      this.tempCartDetails = this.cartDetails.selectedList.slice(0, 1);
    }
    this.amount = 0;
    this.quantity = 0;
    for (let obj of this.cartDetails.selectedList) {
        this.amount = this.amount + (obj.quantity * obj.price);
        this.quantity = this.quantity + obj.quantity;
    }
  }
}
