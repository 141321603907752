import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-category-card',
  templateUrl: './category-card.component.html',
  styleUrls: ['./category-card.component.scss']
})
export class CategoryCardComponent implements OnInit {
category:any;
  constructor() { }

  ngOnInit(): void {

    this.category=[{
      id:"28",
      type: "Men Haircut"

    },
      {
        id:"65",
        type: "Women Haircut"
      },
      {
        id:"18",
        type: "Facial"
      },
      {
        id:"4",
        type: "Plumbing"

      },
      {
        id:"5",
        type: "Men Haircut"

      }
    ]

  }
  selected(id:any){
    const itemIndex = this.category.findIndex((item: { id: any; }) => item.id== id )
    this.category[itemIndex].isSelected =  !this.category[itemIndex].isSelected;
  }

}
