import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import moment = require('moment');
import { ToastrService } from 'ngx-toastr';
import { RouteConstants } from 'src/app/model/route-constants';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
declare var Razorpay: any;
declare var Stripe: any;
declare var $: any;
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  showOrderDetails = false;
  showContinue = false;
  showWallet = false;
  hideWallet = false;
  total: any;
  cartItems: any;
  taxAmt: any;
  taxPercent: any;
  serviceProviderId: any;
  serviceProviderBranch: any;
  servicesList: any = [];
  totalPayableAmt: any;
  depositType: any = 'Full';
  depositAmt: any;
  depositPercentage: any;
  stripe: any;
  paymentGateway: any=environment.paymentMode;
  paymentSource:any='wallet';
  currencyCode = environment.currencyCode;

  service_request_id: any;
  walletAmt: any;
  disabledBtn = false;
  isPlanSelected = false;
  agreeTermsAndCondition = false;
  addressList: any;
  constructor(public storageService: StorageService, public dataService: DataService, public router: Router,
    private bs: BroadcastService, private cd: ChangeDetectorRef,private toastr: ToastrService) {
      this.walletAmt = parseInt(JSON.parse(<string>this.storageService.getItem('walletAmt')));
  }

  ngOnInit(): void {
    // this.stripe = Stripe(environment.stripKey);
    this.getCustomerAddressList();
    

    if (this.walletAmt <= this.totalPayableAmt) {
      this.showWallet = false;
      this.hideWallet = true;
      this.showOrderDetails = true;
      this.paymentSource='online';
    } else if (this.walletAmt <= this.depositAmt) {
      this.showWallet = false;
      this.hideWallet = true;
      this.showOrderDetails = true;
      this.paymentSource='online';
    } else {
      this.showWallet = true;
      this.hideWallet = false;
    }
  }

  paymentOption(option:any)
  {
    this.depositType = option;
     if (this.walletAmt >= this.depositAmt && this.depositType === 'Deposit') {
      this.showWallet = true;
      this.hideWallet = false;
      this.showOrderDetails = true;
      this.paymentSource='wallet';
    }
    else if (this.walletAmt <= this.totalPayableAmt) {
      this.showWallet = false;
      this.hideWallet = true;
      this.showOrderDetails = true;
      this.paymentSource='online';
    }  else {
      this.showWallet = true;
      this.hideWallet = false;
    }
  }
  showDetails(e?: any) {
    if (e === "wallet") {
      this.showWallet = this.hideWallet ? false : true;
      this.showOrderDetails = false;
      this.showContinue = false;
      this.depositType = 'Full';
    } else if (e === "payonline") {
      this.showOrderDetails = true;
      this.showContinue = false;
      this.depositType = 'Full';
      this.showWallet = false;
    }

  }

  closeDetails() {
    this.showOrderDetails = false;
    this.showContinue = true;
    this.depositType = 'Deposit';
  }
  

  getCartItems = () => {
    this.total = 0;
    this.taxAmt = 0;
    this.totalPayableAmt = 0;
    let cartItems: any = this.storageService.getItem('cartItems');
    this.cartItems = cartItems !== null && cartItems !== undefined ? JSON.parse(cartItems).results : [];
    this.isPlanSelected = false;
    

    this.cartItems.forEach((item: any) => {
      if (item && item?.service !== null) {
        this.isPlanSelected = false;
        this.serviceProviderId = item?.service?.service_provider?.service_provider_id
        this.serviceProviderBranch = item?.service?.serviceprovider_branch?.branch_id
        this.total = Math.round(this.total + (item?.quantity * item?.service?.min_charge));
        this.taxAmt = Math.round(this.taxAmt + (item?.quantity * item?.service?.min_charge * item?.service?.tax_percent / 100));
        this.taxPercent = Math.round(item?.service?.tax_percent);
        this.totalPayableAmt = Math.round(this.total + this.taxAmt);
        this.depositPercentage = Math.round(item?.service?.service_provider.deposit_percent);
        this.depositAmt = Math.round((this.total * item?.service?.service_provider.deposit_percent) / 100);
        this.servicesList.push({
          service: item?.service?.service_id,
          schedule_date: item?.booking_date,
          schedule_time: moment(item?.booking_time, "hh:mm a").format('HH:mm'),
          lead_staff : item.staff === undefined  || item.staff === null ? 0 : item?.staff?.employee_id,
          quantity: item?.quantity,
          service_delivery_location: this.checkAddressType(item?.address),
          address: item?.address?.address_id
        });
      }
      if (item && item.plan !== null) {
        this.isPlanSelected = true;
        this.serviceProviderId = item.plan.service_provider.service_provider_id
        this.serviceProviderBranch = item.plan.serviceprovider_branch?.branch_id
        this.total = Math.round(this.total + (item?.quantity * item?.plan.amount));
        this.taxAmt = Math.round(this.taxAmt + (item?.quantity * item?.plan.amount * item?.plan?.service?.tax_percent / 100));
        this.taxPercent = Math.round(item?.plan?.service?.tax_percent);
        this.totalPayableAmt = Math.round(this.total + this.taxAmt);
        this.servicesList.push({
          service: item.plan.service.service_id,
          schedule_date: item?.booking_date,
          schedule_time: moment(item?.booking_time, "hh:mm a").format('HH:mm'),
          lead_staff : item.staff === undefined  || item.staff === null ? 0 : item?.staff?.employee_id,
          quantity: item.quantity,
          service_delivery_location: this.checkAddressType(item?.address),
          address: item?.address?.address_id
        });
      }
    });
  } 


  checkAddressType = (address: any) => {
    // item?.address?.address_type
    let x = 'BUSINESS';
    if(address){
      let x: any = this.addressList.find((data: any) => {
        if(data.address_id === address?.address_id){
          x = 'HOME' 
        }
      })
    }
    return x
  }


  createServiceRequest = () => {
    if(!this.agreeTermsAndCondition){
      this.toastr.warning('please agree Terms & Conditios.');
      return
    }
    this.disabledBtn = true;
    // const reqObj = {
    //   service_provider: this.serviceProviderId,
    //   serviceprovider_branch: this.serviceProviderBranch,
    //   estimated_rounds: 0,
    //   services: this.servicesList,
    //   payment_type: this.depositType,
    //   plan_id: 2,
    //   is_wallet_payment: this.paymentSource === 'wallet' ? true : false
    // }
    if(this.isPlanSelected){
      this.onSaveRecurring();
    }else {
    const reqObj = {
      service_provider: this.serviceProviderId,
      serviceprovider_branch: this.serviceProviderBranch,
      estimated_rounds: 0,
      services: this.servicesList,
      payment_type: this.depositType,
      // plan_id: 2,
      is_wallet_payment: this.paymentSource === 'wallet' ? true : false
    };
    
    
    this.dataService.servicerequest(reqObj).subscribe((res: any) => {
      if (res && res.error === undefined) {
        this.disabledBtn = false;
        this.service_request_id = res.service_request_id
        // if (this.depositType === 'Full' && this.walletAmt <= this.totalPayableAmt && fromState !== 'wallet') {
        //   this.checkPaymentMode(res);
        // } else if (this.depositType === 'Deposit' && this.walletAmt <= this.depositAmt && fromState !== 'wallet') {
        //   this.checkPaymentMode(res);
        // } else
        if (this.paymentSource === 'wallet') {
          this.goToSuccessPage();
        } else {
          this.checkPaymentMode(res);
        }
      } else if (res && res.Error || res.error) {
        this.disabledBtn = false;
        Swal.fire({
          text: res.error.Error || res.Error,
          icon: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      }
    })
   }
  }

  checkPaymentMode = (res: any) => {
    if (environment.paymentMode === 'stripe') {
      // $('#stripePopup').modal('show');
      this.service_request_id = res.service_request_id
      this.createStripeCheckoutSession();
     
    } else {
      this.createRzpayOrder(res);
    }
  }
  // call api to create order_id
  createRzpayOrder(data: any) {
    const reqObj = {
      amount: this.depositType === 'Full' ? this.totalPayableAmt : this.depositAmt,
      for_wallet: false,
      service_request: data.service_request_id
    }
    this.dataService.createPaymentOrder(reqObj).subscribe((res: any) => {
      if (res) {
        this.payWithRazor(res);
      }
    })
  }

  payWithRazor = (val: any) => {
    const payOptions: any = {
      'key': environment.razorPayKey,
      'amount': this.depositType === 'Full' ? this.totalPayableAmt * 100 : this.depositAmt * 100, // 2000 paise = INR 20
      'name': 'ScheduleItNow',
      'description': 'Quein Wallet',
      'image': 'assets/icons/login-signup/logo-serve.svg',
      'handler': (response: any) => {
        this.completeTransaction(response);
      },
      'order_id': val.order_id,
      'prefill': {
        'name': JSON.parse(<string>this.storageService.getItem('user_info')).name,
        'email': JSON.parse(<string>this.storageService.getItem('user_info')).email,
        'contact': JSON.parse(<string>this.storageService.getItem('user_info')).mobile,
        'method': 'wallet'
      },
      'theme': {
        'color': '#1fa1ed'
      }
    };

    var rzp1 = new Razorpay(payOptions);
    rzp1.open();

  }

  completeTransaction = (data: any) => {
    if (data) {
      const reqObj = {
        pg_order_id: data.razorpay_order_id,
        pg_payment_id: data.razorpay_payment_id,
        pg_signature: data.razorpay_signature
      }
      this.dataService.verifyPaymentCheckout(reqObj).subscribe((res: any) => {
        if (res) {
          this.goToSuccessPage();
        }
      })
    }
  }

  goToSuccessPage = () => {
    // $('#stripePopup').modal('hide');
    this.storageService.removeItem('cartItems');
    this.bs.broadcast('REFRESH_CART', 'count');
    this.bs.broadcast('REFRESH_CART', 'wallet')
    $("#submit-button").attr("disabled", false);;
    this.router.navigate([RouteConstants.PAYMENT_SUCCESS], {
      queryParams: {
        amt: this.depositType === 'Full' ? this.totalPayableAmt : this.depositAmt,
        bookingId: this.service_request_id
      }
    })
  }

  createStripeCheckoutSession(data?: any) {
    const reqObj = {
      amount: this.depositType === 'Full' ? this.totalPayableAmt : this.depositAmt,
      for_wallet: false,
      service_request: this.service_request_id,
      email: JSON.parse(<string>this.storageService.getItem('user_info')).email,
      domain: window.location.hostname,
      customer_id: JSON.parse(<string>this.storageService.getItem("user_info")).customer_id
    }
    this.dataService.checkoutSession(reqObj).subscribe((res1: any) => {
      if (res1 && res1.Error === undefined) {
        window.location.href = res1.url;
      }
    });
  }

  onSaveRecurring() {
      let  recurring_service_plan  :any;
      let address: any;
      let service_delivery_location: any;
      let schedule_date: any;
      let schedule_time: any;
      let lead_staff: any;

      this.cartItems.forEach((item: any) => {
        if (item && item.plan !== null) {
          recurring_service_plan = item.plan.plan_id;
          service_delivery_location = this.checkAddressType(item?.address);
          address = item?.address?.address_id;

          schedule_date = item?.booking_date;
          schedule_time =moment(item?.booking_time, "hh:mm a").format('HH:mm');
          lead_staff = item.staff === undefined  || item.staff === null ? 0 : item?.staff?.employee_id;
        }
      });

      const payload = {
        service_provider: this.serviceProviderId,
        serviceprovider_branch: this.serviceProviderBranch,
        payment_type: this.depositType,
        is_wallet_payment: false,
        // payment_type: this.depositType,
        // is_wallet_payment: this.paymentSource === 'wallet' ? true : false,
        recurring_service_plan: recurring_service_plan,
        address: address,
        // service_delivery_location: service_delivery_location
        service_delivery_location: "HOME",
       
        lead_staff: lead_staff,
        schedule_start_datetime:  `${schedule_date}T12:00:00`,
        preferred_time: schedule_time,
     
      };

      console.log('Form Payload:', payload);

      this.dataService.submitRecurringBooking(payload).subscribe((res: any) => {
          if (res && res.Error === undefined && res.errorCode === undefined) {
            this.disabledBtn = false;
            this.service_request_id = res.service_request_id
          
            if (this.paymentSource === 'wallet') {
              this.goToSuccessPage();
            } else {
              this.checkPaymentMode(res);
            }
          } else if (res && res.Error|| res.errorCode) {
            this.disabledBtn = false;
            Swal.fire({
              text: res?.error?.Error || res?.Error || res?.errorCode,
              icon: 'error',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
      );
    }


    getCustomerAddressList = () => {
      const userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
      if (userDetails) {
        this.dataService.getCustomerAddressList(userDetails?.customer_id).subscribe((res: any) => {
          if (res) {
            this.addressList = res.address;
            this.getCartItems();
          }
        });
      }
    }


    navigatePage =  () => {
      window.open("../terms-conditions/", '_blank');
  }
  

}
