<div class="thanks-page-container-data">
    <div class="container">
        <div class="breadcrumb-data">
            <li class="breadcrumb-item"  [routerLink]="'/home'">Home</li>
            <li class="breadcrumb-item" [routerLink]="['/my-bookings']">My Bookings</li>
            <li class="breadcrumb-item">Cancel Bookings</li>
        </div>
    </div>
    <div class="container">
        <div class="thanks-message-info">
            <div class="img-block">
                <img src="assets/icons/cancel.svg">
            </div>
            <div class="content-block">
                <div>
                    <div class="title text-danger">Booking Cancelled!</div>
                    <div class="info-text" *ngIf="orderCount === 1">We wanted to confirm that you have successfully cancelled <b>{{serviceNames}}</b> 
                        Services in your order with ID<b> # {{orderId}}</b><br/>
                        Your refund for the canceled service will be initiated and processed to your original payment method within 3-4 business days.</div>
                    <div class="info-text"  *ngIf="orderCount > 1">We wanted to confirm that you have successfully cancelled one of the services <b>({{serviceNames}})</b>
                        Services in your order with ID<b> # {{orderId}}</b><br/>
                        Your refund for the canceled service will be initiated and processed to your original payment method within 3-4 business days.</div>
                    <div class="back-link" [routerLink]="['/my-bookings']">Back to My Bookings</div>
                </div>
            </div>
        </div>
    </div>
</div>