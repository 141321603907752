<div class="login-container-page row d-none">
  <div class="login-form-section col-lg-6 col-md-6 col-sm-12 col-12">

    <!-- LogIn form -->
    <ng-container *ngIf="true">
      <div class="welcome-heading">Welcome Back!</div>
      <div class="heading-text">Sign In</div>
      <div class="desc-text">Please enter your details</div>
      <form [formGroup]="signInForm" (ngSubmit)="onSubmit($event)">
        <div class="input-block">
          <div class="label-text">EMAIL</div>
          <input type="email" placeholder="Please enter your email" class="input-form" formControlName="email">
          <div class="valid-check">
            <i class="bi bi-check-circle-fill success" *ngIf="emailControls.valid  && formSubmited"></i>
          </div>
          <span class="error-text" *ngIf="emailControls.invalid  && formSubmited">Email required</span>
        </div>
        <div class="input-block">
          <div class="label-text">PASSWORD</div>
          <input [type]="showPwdLabel" placeholder="Please enter your password" class="input-form"
            formControlName="pwd">
          <div class="show-pswd">
            <i class="bi bi-eye" (click)="isVisible()" *ngIf="showPwdLabel === 'text'"></i>
            <i class="bi bi-eye-slash" (click)="isVisible()" *ngIf="showPwdLabel === 'password'"></i>
          </div>
          <span class="error-text" *ngIf="pwdControls.invalid  && formSubmited">Password required</span>
        </div>
        <div class="remebaer-block">
          <label class="check-label-mark">
            <div class="name-label">Remember me</div>
            <input type="checkbox" checked="checked">
            <span class="checkmark"></span>
          </label>
          <div class="forgot-pswd" [routerLink]="['../forgot-pin']">Forgot Password</div>
        </div>
        <span class="error-text" *ngIf="errMsg">{{errMsg}}</span>
        <button class="sb-primary-btn w-100" type="submit">Login</button>
        <div class="or-divider">
          <span>OR</span>
        </div>
        <button class="sign-in-with-google mb-3 w-100" type="button" (click)="signInWithGoogle()"><img
            src="assets/icons/login-signup/google.png">Sign in with Google</button>
        <button class="sign-in-with-google w-100 mb-3" type="button" (click)="signInWithFB()"><img
            src="assets/icons/login-signup/fb.png">Sign in with Facebook</button>
        <div class="dont-have-acc">
          <span class="normal-text">Don't have an account?</span>
          <a class="link-text" [routerLink]="['../sign-up']">Sign Up for free</a>
        </div>
      </form>
    </ng-container>
  </div>
  <div class="info-block-with-img col-lg-6 col-md-6 col-sm-6 col-12">
    <div class="img-block-wrapper">
      <img src="assets/icons/login-signup/login-bg.png">
      <!-- <div class="content-text-data">
          <div class="title">Business account SignIn?</div>
          <button class="sb-primary-btn">Click Here</button>
        </div> -->
    </div>
  </div>
</div>






<!-- New Design -->

<div class="login-container-page row">
  <div class="login-form-section col-lg-6 col-md-6 col-sm-12 col-12">

    <!-- LogIn form -->
    <ng-container *ngIf="true">

      <div class="signin-logo">
        <a [routerLink]="['/home']">
          <!-- <img src="assets/icons/login-signup/logo-serve.svg" alt=""> -->
          <img src="{{siteLogo}}" alt="site logo" />
        </a>
      </div>

      <div class="welcome-new-heading mt-5">Hi, Welcome Back!</div>
      <!-- <div class="heading-text">Sign In</div> -->
      <div class="desc-text">Enter your credentials to access your account</div>
      <form [formGroup]="signInForm" (ngSubmit)="onSubmit($event)">
        <div class="input-block mt-5">
          <div class="label-color">Email Address</div>
          <input type="email" placeholder="Please enter your email" class="input-form" formControlName="email">
          <div class="valid-check">
            <i class="bi bi-check-circle-fill success" *ngIf="emailControls.valid  && formSubmited"></i>
          </div>
          <span class="error-text" *ngIf="emailControls.invalid  && formSubmited">Email required</span>
        </div>
        <div class="input-block">
          <div class="label-color">Password</div>
          <input [type]="showPwdLabel" placeholder="Please enter your password" class="input-form"
            formControlName="pwd">
          <div class="show-pswd">
            <i class="bi bi-eye" (click)="isVisible()" *ngIf="showPwdLabel === 'text'"></i>
            <i class="bi bi-eye-slash" (click)="isVisible()" *ngIf="showPwdLabel === 'password'"></i>
          </div>
          <span class="error-text" *ngIf="pwdControls.invalid  && formSubmited">Password required</span>
        </div>
        <div class="remebaer-block">
          <label class="check-label-mark">
            <div class="name-label">Remember me</div>
            <input type="checkbox" checked="checked">
            <span class="checkmark"></span>
          </label>
          <div class="forgot-pswd" [routerLink]="['../forgot-pin']">Forgot Password</div>
        </div>
        <span class="error-text" *ngIf="errMsg">{{errMsg}}</span>
        <button class="sb-primary-btn w-100" type="submit">Login</button>
        <div class="or-divider">
          <span>OR</span>
        </div>
        <div class="row">
          <div class="col-md-6 m-0">
            <button class="sign-in-with-google mb-3 w-100" type="button" (click)="signInWithGoogle()"><img
                src="assets/icons/login-signup/google.png">Sign In with Google</button>
          </div>
          <div class="col-md-6 m-0">
            <button class="sign-in-with-google w-100 mb-3" type="button" (click)="signInWithFB()"><img
                src="assets/icons/login-signup/fb.png">Sign In with Facebook</button>
          </div>
        </div>
        <div class="dont-have-acc">
          <span class="normal-text">Don't have an account?</span>
          <a class="link-text" [routerLink]="['../sign-up']">Sign Up for free</a>
        </div>
      </form>
    </ng-container>
  </div>
  <div class="info-block-with-img col-lg-6 col-md-6 col-sm-6 col-12 text-center">
    <div class="img-bg-wrapper">
      <img src="assets/icons/login-signup/login.png">
      <!-- <div class="content-text-data mt-4">
        <div class="title">Business account SignIn?</div>
        <button class="sb-primary-btn mt-2" (click)="goToAdminLogin()">Click Here</button>
      </div> -->
    </div>
  </div>
</div>


<div class="bottom-footer-logo row">
  <div class="logo-footer-data col-lg-7 col-md-6 col-sm-6 col-12">
    <div class="logo">
      <!-- <img src="assets/icons/login-signup/logo-serve.svg"> -->
      <img src="{{siteLogo}}" alt="site logo" />
    </div>
    <div class="social-icons">
      <img src="assets/icons/footer/fb.png">
      <img src="assets/icons/footer/insta.png">
      <img src="assets/icons/footer/twitter.png">
    </div>
  </div>
  <div class="copy-rights col-lg-5 col-md-6 col-sm-6 col-12">
    <div class="copy-right-text">
      <span>&#169;</span> 2023 {{productName}} All Rights Reserved
    </div>
  </div>
</div>