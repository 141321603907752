
<div class=" container student-signup">
<h2>Hi <span class="student-name">Rakhi </span>, Thank You for signing up</h2>
<h4>Let's now search for an Excellent new tutor</h4>
<div class="text-wrap">
<img src="assets/images/tutors-img.png" alt="" class="tutor-p">
<p class="c-wrap">We conduct interviews with all of our tutors, and only 1 out of every 8 candidates meet our high standards.</p>
<button class="let-btn" type="button" [routerLink]="['/view-tutors']"> Let's Start</button>
</div>
</div>

