import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-student-bookings',
  templateUrl: './student-bookings.component.html',
  styleUrls: ['./student-bookings.component.scss']
})
export class StudentBookingsComponent implements OnInit {
  inputValue: string = '';
  showInputone:false | undefined;
  // displayBasic: boolean | undefined;
  defaultUpcomingStatus = 'Show All';
  defaultPreviousStatus = 'Completed';
  openEditBookingPanel: any;
  groupopenEditBookingPanel:any
  openCancelBookingPanel: any;
  submittedValue: string = '';

  displayDialog: boolean | undefined;
firstName: string | undefined;
  lastName!: string;
email: string | undefined;
showTextFlag = true; // Set the default button to "Show Text"
showInputFlag = false;

  upcomingStatusList = [
    { name: 'Show All' },
    { name: 'Awaiting Confirmation' },
    { name: 'Awaiting Payment' },
    { name: 'Confirmed' }
  ]

  previousStatusList = [
    { name: 'Show All' },
    { name: 'Completed' },
    { name: 'Refunded' },
    { name: 'Cancelled' },
    { name: 'Declined' },
    { name: 'Expired' }
  ]
  signUpForm: any;
  displayBasic!: boolean;
  static inputValue: any;
  StudentBookingsComponent: any;

  // displayDialog: boolean | undefined;
  showBasicDialog() {
    this.displayBasic = true;
}

showDialog() {
  this.displayDialog = true;
}

onSubmit() {
  // handle form submission logic here
  
  this.inputValue = this.submittedValue;
  this.displayDialog = false;
}
oneshowInput = false;

toggleInput() {
  this.oneshowInput = !this.oneshowInput;
}
  bookingsList = [
    {tutorname: 'Troy-Francis B.', subject: 'Extended Project', date: 'feb 15 to march 20',day:'Every Tue', status: 'Tutor needs to accept', success: 'Completed', statustext: 'text-success', statusicon: 'assets/icons/contact/success.svg',time:'20.00PM',meetingicon:'assets/icons/contact/t-user.svg',meeting:'Free Session'},
    {tutorname: 'Anjali Sharma M..', subject: 'English Literature', date: 'feb 15 to march 20', status: 'Add payment details', success: 'Completed', statustext: 'text-info', statusicon: 'assets/icons/contact/waiting.svg',day:'Every Mon,sun',time:'7.00AM',meeting:'Group Session',meetingicon:'assets/icons/contact/t-users.svg'},
    {tutorname: 'Sarwanch S.', subject: 'Personal Statements', date: 'feb 15 to march 20', status: 'Tutor needs to accept', success: 'Completed',  statustext: 'text-success', statusicon: 'assets/icons/contact/success.svg',day:'Every Tue',time:'9.00AM',meeting:'Group session',meetingicon:'assets/icons/contact/t-user.svg'},
    // {tutorname: 'Zaynab A..', subject: 'Religious Studies',  date: 'feb 15 to march 20', status: 'Needs to accept', success: 'Completed',  statustext: 'text-info', statusicon: 'assets/icons/contact/waiting.svg',day:'Every Sat',time:'17.00PM'},
    // {tutorname: 'Troy-Francis B.', subject: 'Extended Project Qualification', date: 'feb 15 to march 20', status: 'Payment scheduled', success: 'Completed', statustext: 'text-success', statusicon: 'assets/icons/contact/success.svg',day:'Every Thu',time:'14.00PM'}
  ]

  constructor() {
    this.showTextFlag = true;
    this.showInputFlag = false;
  }

  showText() {
    this.showTextFlag = true;
    this.showInputFlag = false;
  }

  showInput() {
    this.showTextFlag = false;
    this.showInputFlag = true;
  }
   

  ngOnInit(): void {


    this.signUpForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
 
    });
  }


  deleteBookingLesson() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this lesson",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your lesson has been deleted.',
          'success'
        )
      }
    })
  }

  accepetLessonBooking() {
    Swal.fire(
      'Success!',
      'Your Booking Confirmed',
      'success'
    )
  }

  // onSubmit() {
  //   // handle form submission logic here
  //   console.log(this.firstName, this.lastName, this.email);
  //   this.displayDialog = false;
  // }

}
