<app-breadcrumb></app-breadcrumb>
<div class="cancelled">
  <div class="container">
    <div class="box">
<div class="title">
  <i class="bi bi-info-circle"></i><span class="text">Booking Cancelled!</span>
</div>
      <div class="sub">
        You have cancelled (1 ) Service - Sugar Waxing - Combo Pack.<br> Refund will be initiated to your original payment method in 3-4 business days.
      </div>
      <div class="back">Back to My Bookings</div>
    </div>
  </div>
</div>

