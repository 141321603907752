<div class="bg-color-of-pages">
    <app-breadcrumb></app-breadcrumb>
    <!-- <app-provider-card></app-provider-card> -->
    <div class="container provider-details-cards" *ngIf="providersDetails">
        <div class="service-provider-details">
            <div class="img-block">
                <img [src]="providersDetails?.service_provider?.logo_url">
            </div>
            <div class="content-sec">
                <div class="service-name">{{providersDetails?.service_provider?.legal_name}}</div>
                <div class="branch-name">{{providersDetails?.service_branch_name}}</div>
                <div class="rating-sec" (click)="gotoReviewPage()">
                    <i class="bi bi-star-fill disable-rating"
                        [ngClass]="{'disable-rating' :  1 <= providersDetails?.avg_rating}"></i>
                    <i class="bi bi-star-fill disable-rating"
                        [ngClass]="{'disable-rating' :  2 <= providersDetails?.avg_rating}"></i>
                    <i class="bi bi-star-fill disable-rating"
                        [ngClass]="{'disable-rating' :  3 <= providersDetails?.avg_rating}"></i>
                    <i class="bi bi-star-fill disable-rating"
                        [ngClass]="{'disable-rating' :  4 <= providersDetails?.avg_rating}"></i>
                    <i class="bi bi-star-fill disable-rating"
                        [ngClass]="{'disable-rating' :  5 <= providersDetails?.avg_rating}"></i>
                    <span>{{providersDetails?.avg_rating}}
                        ({{providersDetails?.review_count}} Reviews)</span>
                </div>
            </div>
        </div>
    </div>




    <div class="providerd-top-details container mb-5">
        <!-- <div class="popular-cat">
            <div class="heading">Popular Categories</div>
            <div class="p-cat-list" *ngIf="categoriesList">
                <div class="chip-list" *ngFor="let item of categoriesList" (click)="selected(item.service_category_id)" [class.active-chip-list]="getStatus(item)">{{item.title}}</div>
            </div>
        </div> -->
        <!-- <div class="serach-sort-block row">
            <div class="search-with-btn col-5 p-0">
                <div class="sb-dropdonws" ngbDropdown placement="bottom">
                    <button class="dropdown-input" ngbDropdownToggle></button>
                    <i class="seach-icon bi bi-search"></i>
                    <input ngbDropdownToggle placeholder="Search Services" type="text" class="search-data">
                    <ul class="dropdown-menu" ngbDropdownMenu>
                        <li ngbDropdownItem>
                            <a class="dropdown_item">Option1</a>
                        </li>
                        <li ngbDropdownItem>
                            <a class="dropdown_item">Option1</a>
                        </li>
                        <li ngbDropdownItem>
                            <a class="dropdown_item">Option1</a>
                        </li>
                        <li ngbDropdownItem>
                            <a class="dropdown_item">Option1</a>
                        </li>
                        <li ngbDropdownItem>
                            <a class="dropdown_item">Option1</a>
                        </li>
                        <li ngbDropdownItem>
                            <a class="dropdown_item">Option1</a>
                        </li>
                    </ul>
                </div>
                <button class="sb-primary-btn ml-2">Search</button>
            </div>
            <div class="sort-data col-7 p-0">
                <div class="label-text">Sort By :</div>
                <div class="sb-dropdonws" ngbDropdown placement="bottom">
                    <button class="dropdown-input" ngbDropdownToggle>Packs<i class="bi bi-chevron-down"></i></button>
                    <ul class="dropdown-menu" ngbDropdownMenu>
                        <li ngbDropdownItem>
                            <a class="dropdown_item">Provider</a>
                        </li>
                        <li ngbDropdownItem>
                            <a class="dropdown_item">Low-High</a>
                        </li>
                        <li ngbDropdownItem>
                            <a class="dropdown_item">High-Low</a>
                        </li>
                        <li ngbDropdownItem>
                            <a class="dropdown_item">Top Rated</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->

        <!-- <div class="booking-title">Popular Bookings</div>
        <div class="booking-sub">Based on your location</div>

        <div class="services-cards-col row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 column-card-parent" *ngFor="let item of serviceList">
                <app-providers-service-list-card [serviceListObj]="item" [serviceProviderId]="providersDetails?.service_provider_id" (updateSelection)="updateItem($event)"></app-providers-service-list-card>
            </div>
        </div> -->

        <ul ngbNav #nav="ngbNav" class="nav-tabs" id="service-details-tabs">
            <li ngbNavItem>
                <a ngbNavLink>Services</a>
                <ng-template ngbNavContent>
                    <!-- Services tab starts here -->
                    <div id="description" class="tab-pane fade show active">
                        <div class="desc-text">{{ (providersDetails?.service_provider?.description?.length>196)?
                            (providersDetails?.service_provider?.description |
                            slice:0:50)+'...':(providersDetails?.service_provider?.description) }}</div>
                        <div class="serach-sort-block row">
                            <div class="search-with-btn col-lg-7 col-md-7 col-xs-6 col-12 p-0">
                                <!-- <div class="sb-dropdonws" ngbDropdown placement="bottom">
                                    <button class="dropdown-input" ngbDropdownToggle></button>
                                    <i class="seach-icon bi bi-search"></i>
                                    <input placeholder="Search Services" type="text"
                                    class="search-data" [(ngModel)]="searchKeyword" (keyup.enter)="onSearch()"/>
                                </div>
                                <button class="sb-primary-btn ml-2"  (click)="onSearch()">Search</button> -->
                                <div class="search-with-btn col-12 col-md-8 col-lg-8 col-sm-8 p-0">
                                    <div class="sb-dropdonws">
                                        <div class="tabs-home-loactions">
                                            <div class="tab-name" (click)="onSelectTabs('home_only')"
                                                [ngClass]="{'active-tab-data' :selectedTab === 'home_only'}"><i
                                                    class="bi bi-check-circle-fill"></i> Home</div>
                                            <div class="tab-name" (click)="onSelectTabs('center_only')"
                                                [ngClass]="{'active-tab-data' :selectedTab === 'center_only' }"><i
                                                    class="bi bi-check-circle-fill"></i> Center</div>
                                            <div class="tab-name" (click)="onSelectTabs('both')"
                                                [ngClass]="{'active-tab-data' : selectedTab === 'both' }"><i
                                                    class="bi bi-check-circle-fill"></i> Both</div>
                                        </div>
                                        <button class="dropdown-input"></button>
                                        <i class="seach-icon bi bi-search"></i>
                                        <input placeholder="Search Services" type="text" class="search-data"
                                            [(ngModel)]="searchKeyword" (keyup.enter)="onSearch()" />
                                    </div>
                                    <button class="sb-primary-btn" id="provider-search-btn"
                                        (click)="onSearch()">Search</button>
                                    <button class="sb-primary-btn" id="provider-search-btn-1" (click)="onSearch()"><i
                                            class="seach-icon bi bi-search"></i></button>
                                </div>
                            </div>
                            <div class="sort-data col-12 col-md-4 col-lg-4 col-sm-4 p-0">
                                <div class="label-text">Sort By :</div>
                                <div class="sb-dropdonws" ngbDropdown placement="bottom">
                                    <button class="dropdown-input" ngbDropdownToggle>{{defaultSortBy}}<i
                                            class="bi bi-chevron-down"></i></button>
                                    <ul class="dropdown-menu" ngbDropdownMenu>
                                        <li ngbDropdownItem>
                                            <a class="dropdown_item" (click)="ChangeSortByFn('Low-High')">Low-High</a>
                                        </li>
                                        <li ngbDropdownItem>
                                            <a class="dropdown_item" (click)="ChangeSortByFn('High-Low')">High-Low</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>



                        <!-- New design ends here -->
                        <div class="services-main-wrapper" *ngIf="false">


                            <!-- <div *ngIf="categoriesList.length>0" class="services-navigation">
                                <div class="service-nav-item" *ngFor="let catObj of categoriesList"
                                    (click)="loadServicesForCategory(catObj)">
                                    <div class="text-center">
                                        <img [src]="catObj.image" alt="" class="cat-image">
                                    </div>
                                    <p class="nav-service-name">{{catObj.title}}</p>
                                </div>
                            </div> -->


                            <div class="sticky-cat-bar pt-3">
                                <carousel *ngIf="categoriesList.length>0">
                                    <div class="carousel-cell" id="service-nav-items"
                                        *ngFor="let catObj of categoriesList" (click)="loadServicesForCategory(catObj)">
                                        <div class="text-center">
                                            <img [src]="catObj.image" alt="" class="cat-image">
                                            <p class="nav-service-name">{{catObj.title}}</p>
                                        </div>
                                    </div>
                                </carousel>
                            </div>



                            <div class="inner-services-items mt-4">
                                <p class="s-cat-title">{{selectedCategory?.title}}</p>
                                <p class="booking-sub">{{ (selectedCategory?.description.length>196)?
                                    (selectedCategory?.description | slice:0:196)+'...':(selectedCategory?.description)
                                    }}
                                </p>

                                <div class="s-inner-main-wrapper">
                                    <div class="ser-main-card-wrapper" *ngFor="let item of filteredServices$|async">
                                        <div class="s-card-details">
                                            <div class="s-card-inner-data">
                                                <p class="service-name">{{item.title}}</p>
                                                <div class="row">
                                                    <div class="col-md-1 col-3">
                                                        <p class="s-fare">{{item.min_charge | currency : currencyCode}}
                                                        </p>
                                                    </div>
                                                    <div class="col-md-11 col-9">
                                                        <p class="s-duration pl-3">• {{item.min_time}} min</p>
                                                    </div>
                                                </div>
                                                <p class="s-availability">
                                                    Availability : {{item.avail_at}}
                                                </p>
                                                <hr>

                                                <div class="s-features">
                                                    {{item.description}}
                                                </div>
                                                <div class="more-text">
                                                    <button (click)="gotoServiceDetailsPage()">View more</button>
                                                </div>
                                            </div>
                                            <div class="s-card-inner-data text-center">
                                                <div class="s-image-block">
                                                    <img [src]="item.image" alt="" class="s-img">


                                                    <div>

                                                        <div class="add-cart-btn text-center" *ngIf='!item.inputshow'>
                                                            <!-- <button (click)="item.inputshow = !item.inputshow" class="s-add-btn">ADD</button> -->
                                                            <button (click)="openPopup(item)"
                                                                class="s-add-btn">ADD</button>
                                                        </div>


                                                        <div class="inc-dec-data" *ngIf='item.inputshow'>
                                                            <div class="qty-inner-section">
                                                                <button class="text-center" (click)="decrementQty()">
                                                                    <img src="assets/icons/minus.svg" alt="">
                                                                </button>
                                                            </div>

                                                            <div class="qty-inner-section text-center">
                                                                <input type="text" [(ngModel)]="inputnumber">
                                                            </div>

                                                            <div class="qty-inner-section">
                                                                <button class="text-center" (click)="incrementQty()">
                                                                    <img src="assets/icons/plus.svg" alt="">
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- New design ends here -->

                        <div class="d-block mb-4 mt-5" *ngFor="let sObj of serviceList">
                            <ng-container *ngIf="sObj?.results">
                                <div class="booking-title">{{sObj.catName}}</div>
                                <div class="booking-sub">{{ (sObj.catDesc.length>196)? (sObj.catDesc |
                                    slice:0:196)+'...':(sObj.catDesc) }}</div>
                            </ng-container>
                            <div class="services-cards-col row" *ngIf="sObj?.results">
                                <div class="col-10 col-sm-6 col-md-4 col-lg-3 column-card-parent"
                                    [class.col-12]="sObj.results.length <= 1" *ngFor="let item of sObj?.results">

                                    <app-providers-service-list-card [serviceListObj]="item"
                                        [serviceProviderDetails]="{provider_slug_itle : catId, providerId: providerId,logo_url: providersDetails?.service_provider?.logo_url,
                                        providerName:providersDetails?.service_provider?.legal_name, address: providersDetails?.address }"
                                        [branchDetails]="providersDetails?.address"
                                        (updateSelection)="updateItem($event)">
                                    </app-providers-service-list-card>
                                </div>
                            </div>
                            <!-- <div *ngIf="!sObj?.results" class="text-center">No Serivces are available for this Category</div>+ -->
                        </div>
                        <div *ngIf="serviceList.length == 0" class="text-center">No Serivces are available</div>
                    </div>
                    <!-- Services tab ends here -->
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Staff</a>
                <ng-template ngbNavContent>
                    <!-- <div id="staff" class="tab-pane fade"> -->
                    <div class="protip-info">We assure you and your family safety, Our entire staff is fully
                        vaccinated
                        and undergo regular temperature checks.</div>
                    <div class="staff-cards-data row">
                        <div class="staff-info-column col-lg-4 col-md-4 col-sm-4 col-12"
                            *ngFor="let item of staffDetails">
                            <div class="inner-content-data" *ngIf="item.role !== 'Branch Manager'">
                                <div class="img-with-text-content">
                                    <div class="img_block">
                                        <!-- <img [src]="item.image"> -->
                                        <img src="assets/icons/staff-avatar.png" alt="Staff avatar">
                                    </div>
                                    <div class="content-info">
                                        <!-- <div class="staff-name">{{item.user.first_name + item.user.last_name}}</div> -->
                                        <div class="staff-name">
                                            {{item.user.first_name}}&nbsp;{{item.user.last_name}}
                                        </div>
                                        <div class="experience">{{item.designation}}.</div>
                                        <!-- <div class="rating-sec">
                                                <i class="bi bi-star-fill"
                                                [ngClass]="{'disable-rating' :  1 <= item.service_provider.avg_rating}"></i>
                                            <i class="bi bi-star-fill"
                                                [ngClass]="{'disable-rating' :  1 <= item.service_provider.avg_rating}"></i>
                                            <i class="bi bi-star-fill"
                                                [ngClass]="{'disable-rating' :  1 <= item.service_provider.avg_rating}"></i>
                                            <i class="bi bi-star-fill"
                                                [ngClass]="{'disable-rating' :  1 <= item.service_provider.avg_rating}"></i>
                                            <i class="bi bi-star-fill"
                                                [ngClass]="{'disable-rating' :  1 <= item.service_provider.avg_rating}"></i>
                                                <span>{{item.service_provider.review_count}} ratings</span>
                                            </div> -->
                                    </div>
                                </div>
                                <div class="details-of-staff">
                                    <div class="count-of-job">{{item.service_provider.legal_name}} :
                                        {{item.serviceNames?.join(',')}}</div>
                                    <div class="vaccinec-certificate">
                                        <i class="bi bi-eye"></i>Vaccine Certificate
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="gallery-column col-lg-12 col-md-12 col-sm-12 col-12"
                        *ngIf="providersDetails?.images.length== 0 ">
                        <!-- No data available -->
                        <!-- <div class="text-center" *ngIf="bookingsList?.length == 0"> -->

                        <!-- <div class="container no-data-wrapper text-center">
                            <div class="empty-cart-img text-center">
                                <img src="assets/images/No data-rafiki.svg" alt="No data available">
                            </div>
                            <div class="text-center no-data-text">No data available</div>
                        </div> -->

                        <!-- </div> -->
                    </div>

                    <!-- </div> -->
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Gallery</a>
                <ng-template ngbNavContent>
                    <!-- <div id="gallery" class="tab-pane fade"> -->
                    <div class="booking-title">Uploads by owner</div>
                    <div class="gallery-data row">
                        <div class="gallery-column col-lg-3 col-md-3 col-sm-3 col-10"
                            *ngFor="let obj of providersDetails?.images">
                            <div class="img-data">
                                <img [src]="obj">
                            </div>
                        </div>
                        <div class="gallery-column col-lg-12 col-md-12 col-sm-12 col-12"
                            *ngIf="providersDetails?.images.length== 0 ">
                            <!-- No data available -->
                            <!-- <div class="text-center" *ngIf="bookingsList?.length == 0"> -->

                            <!-- <div class="container no-data-wrapper text-center">
                                <div class="empty-cart-img text-center">
                                    <img src="assets/images/No data-rafiki.svg" alt="No data available">
                                </div>
                                <div class="text-center no-data-text">No data available</div>
                            </div> -->

                            <!-- </div> -->
                        </div>
                    </div>
                    <!-- </div> -->
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>



        <div class="booking-providers-list-cards-info" *ngIf="false">
            <ul class="nav nav-pills">
                <li><a data-toggle="pill" href="#description" class="active">Services</a></li>
                <li><a data-toggle="pill" href="#staff">Staff</a></li>
                <li><a data-toggle="pill" href="#gallery">Gallery</a></li>
            </ul>

            <div class="tab-content">
                <div id="description" class="tab-pane fade show active">
                    <div class="desc-text">{{ (providersDetails?.service_provider?.description?.length>196)?
                        (providersDetails?.service_provider?.description |
                        slice:0:50)+'...':(providersDetails?.service_provider?.description) }}</div>
                    <div class="serach-sort-block row">
                        <div class="search-with-btn col-lg-7 col-md-7 col-xs-6 col-12 p-0">
                            <!-- <div class="sb-dropdonws" ngbDropdown placement="bottom">
                                <button class="dropdown-input" ngbDropdownToggle></button>
                                <i class="seach-icon bi bi-search"></i>
                                <input placeholder="Search Services" type="text"
                                class="search-data" [(ngModel)]="searchKeyword" (keyup.enter)="onSearch()"/>
                            </div>
                            <button class="sb-primary-btn ml-2"  (click)="onSearch()">Search</button> -->
                            <div class="search-with-btn col-12 col-md-8 col-lg-8 col-sm-8 p-0">
                                <div class="sb-dropdonws">
                                    <div class="tabs-home-loactions">
                                        <div class="tab-name" (click)="onSelectTabs('home_only')"
                                            [ngClass]="{'active-tab-data' :selectedTab === 'home_only'}"><i
                                                class="bi bi-check-circle-fill"></i> Home</div>
                                        <div class="tab-name" (click)="onSelectTabs('center_only')"
                                            [ngClass]="{'active-tab-data' :selectedTab === 'center_only' }"><i
                                                class="bi bi-check-circle-fill"></i> Center</div>
                                        <div class="tab-name" (click)="onSelectTabs('both')"
                                            [ngClass]="{'active-tab-data' : selectedTab === 'both' }"><i
                                                class="bi bi-check-circle-fill"></i> Both</div>
                                    </div>
                                    <button class="dropdown-input"></button>
                                    <i class="seach-icon bi bi-search"></i>
                                    <input placeholder="Search Services" type="text" class="search-data"
                                        [(ngModel)]="searchKeyword" (keyup.enter)="onSearch()" />
                                </div>
                                <button class="sb-primary-btn" id="provider-search-btn"
                                    (click)="onSearch()">Search</button>
                                <button class="sb-primary-btn" id="provider-search-btn-1" (click)="onSearch()"><i
                                        class="seach-icon bi bi-search"></i></button>
                            </div>
                        </div>
                        <div class="sort-data col-12 col-md-4 col-lg-4 col-sm-4 p-0">
                            <div class="label-text">Sort By :</div>
                            <div class="sb-dropdonws" ngbDropdown placement="bottom">
                                <button class="dropdown-input" ngbDropdownToggle>{{defaultSortBy}}<i
                                        class="bi bi-chevron-down"></i></button>
                                <ul class="dropdown-menu" ngbDropdownMenu>
                                    <li ngbDropdownItem>
                                        <a class="dropdown_item" (click)="ChangeSortByFn('Low-High')">Low-High</a>
                                    </li>
                                    <li ngbDropdownItem>
                                        <a class="dropdown_item" (click)="ChangeSortByFn('High-Low')">High-Low</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>



                    <!-- New design ends here -->
                    <div class="services-main-wrapper" *ngIf="false">


                        <!-- <div *ngIf="categoriesList.length>0" class="services-navigation">
                            <div class="service-nav-item" *ngFor="let catObj of categoriesList"
                                (click)="loadServicesForCategory(catObj)">
                                <div class="text-center">
                                    <img [src]="catObj.image" alt="" class="cat-image">
                                </div>
                                <p class="nav-service-name">{{catObj.title}}</p>
                            </div>
                        </div> -->


                        <div class="sticky-cat-bar pt-3">
                            <carousel *ngIf="categoriesList.length>0">
                                <div class="carousel-cell" id="service-nav-items" *ngFor="let catObj of categoriesList"
                                    (click)="loadServicesForCategory(catObj)">
                                    <div class="text-center">
                                        <img [src]="catObj.image" alt="" class="cat-image">
                                        <p class="nav-service-name">{{catObj.title}}</p>
                                    </div>
                                </div>
                            </carousel>
                        </div>



                        <div class="inner-services-items mt-4">
                            <p class="s-cat-title">{{selectedCategory?.title}}</p>
                            <p class="booking-sub">{{ (selectedCategory?.description.length>196)?
                                (selectedCategory?.description | slice:0:196)+'...':(selectedCategory?.description) }}
                            </p>

                            <div class="s-inner-main-wrapper">
                                <div class="ser-main-card-wrapper" *ngFor="let item of filteredServices$|async">
                                    <div class="s-card-details">
                                        <div class="s-card-inner-data">
                                            <p class="service-name">{{item.title}}</p>
                                            <div class="row">
                                                <div class="col-md-1 col-3">
                                                    <p class="s-fare">{{item.min_charge | currency : currencyCode}}</p>
                                                </div>
                                                <div class="col-md-11 col-9">
                                                    <p class="s-duration pl-3">• {{item.min_time}} min</p>
                                                </div>
                                            </div>
                                            <p class="s-availability">
                                                Availability : {{item.avail_at}}
                                            </p>
                                            <hr>

                                            <div class="s-features">
                                                {{item.description}}
                                            </div>
                                            <div class="more-text">
                                                <button (click)="gotoServiceDetailsPage()">View more</button>
                                            </div>
                                        </div>
                                        <div class="s-card-inner-data text-center">
                                            <div class="s-image-block">
                                                <img [src]="item.image" alt="" class="s-img">


                                                <div>

                                                    <div class="add-cart-btn text-center" *ngIf='!item.inputshow'>
                                                        <!-- <button (click)="item.inputshow = !item.inputshow" class="s-add-btn">ADD</button> -->
                                                        <button (click)="openPopup(item)" class="s-add-btn">ADD</button>
                                                    </div>


                                                    <div class="inc-dec-data" *ngIf='item.inputshow'>
                                                        <div class="qty-inner-section">
                                                            <button class="text-center" (click)="decrementQty()">
                                                                <img src="assets/icons/minus.svg" alt="">
                                                            </button>
                                                        </div>

                                                        <div class="qty-inner-section text-center">
                                                            <input type="text" [(ngModel)]="inputnumber">
                                                        </div>

                                                        <div class="qty-inner-section">
                                                            <button class="text-center" (click)="incrementQty()">
                                                                <img src="assets/icons/plus.svg" alt="">
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- New design ends here -->

                    <div class="d-block mb-4 mt-5" *ngFor="let sObj of serviceList">
                        <ng-container *ngIf="sObj?.results">
                            <div class="booking-title">{{sObj.catName}}</div>
                            <div class="booking-sub">{{ (sObj.catDesc.length>196)? (sObj.catDesc |
                                slice:0:196)+'...':(sObj.catDesc) }}</div>
                        </ng-container>
                        <div class="services-cards-col row" *ngIf="sObj?.results">
                            <div class="col-10 col-sm-6 col-md-4 col-lg-3 column-card-parent"
                                [class.col-12]="sObj.results.length <= 1" *ngFor="let item of sObj?.results">

                                <app-providers-service-list-card [serviceListObj]="item"
                                    [serviceProviderDetails]="{provider_slug_itle : catId, providerId: providerId,logo_url: providersDetails?.service_provider?.logo_url,
                                    providerName:providersDetails?.service_provider?.legal_name, address: providersDetails?.address }"
                                    [branchDetails]="providersDetails?.address" (updateSelection)="updateItem($event)">
                                </app-providers-service-list-card>
                            </div>
                        </div>
                        <!-- <div *ngIf="!sObj?.results" class="text-center">No Serivces are available for this Category</div>+ -->
                    </div>
                    <div *ngIf="serviceList.length == 0" class="text-center">No Serivces are available</div>
                </div>
                <div id="staff" class="tab-pane fade">
                    <div class="protip-info">We assure you and your family safety, Our entire staff is fully vaccinated
                        and undergo regular temperature checks.</div>
                    <div class="staff-cards-data row">
                        <div class="staff-info-column col-lg-4 col-md-4 col-sm-4 col-12"
                            *ngFor="let item of staffDetails">
                            <div class="inner-content-data" *ngIf="item.role !== 'Branch Manager'">
                                <div class="img-with-text-content">
                                    <div class="img_block">
                                        <!-- <img [src]="item.image"> -->
                                        <img src="assets/icons/staff-avatar.png" alt="Staff avatar">
                                    </div>
                                    <div class="content-info">
                                        <!-- <div class="staff-name">{{item.user.first_name + item.user.last_name}}</div> -->
                                        <div class="staff-name">{{item.user.first_name}}&nbsp;{{item.user.last_name}}
                                        </div>
                                        <div class="experience">{{item.designation}}.</div>
                                        <!-- <div class="rating-sec">
                                            <i class="bi bi-star-fill"
                                            [ngClass]="{'disable-rating' :  1 <= item.service_provider.avg_rating}"></i>
                                        <i class="bi bi-star-fill"
                                            [ngClass]="{'disable-rating' :  1 <= item.service_provider.avg_rating}"></i>
                                        <i class="bi bi-star-fill"
                                            [ngClass]="{'disable-rating' :  1 <= item.service_provider.avg_rating}"></i>
                                        <i class="bi bi-star-fill"
                                            [ngClass]="{'disable-rating' :  1 <= item.service_provider.avg_rating}"></i>
                                        <i class="bi bi-star-fill"
                                            [ngClass]="{'disable-rating' :  1 <= item.service_provider.avg_rating}"></i>
                                            <span>{{item.service_provider.review_count}} ratings</span>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="details-of-staff">
                                    <div class="count-of-job">{{item.service_provider.legal_name}} :
                                        {{item.serviceNames?.join(',')}}</div>
                                    <div class="vaccinec-certificate">
                                        <i class="bi bi-eye"></i>Vaccine Certificate
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="gallery-column col-lg-12 col-md-12 col-sm-12 col-12"
                        *ngIf="providersDetails?.images.length== 0 ">
                        <!-- No data available -->
                        <!-- <div class="text-center" *ngIf="bookingsList?.length == 0"> -->

                        <div class="container no-data-wrapper text-center">
                            <div class="empty-cart-img text-center">
                                <img src="assets/images/No data-rafiki.svg" alt="No data available">
                            </div>
                            <div class="text-center no-data-text">No data available</div>
                        </div>

                        <!-- </div> -->
                    </div>

                </div>
                <div id="gallery" class="tab-pane fade">
                    <div class="booking-title">Uploads by owner</div>
                    <div class="gallery-data row">
                        <div class="gallery-column col-lg-3 col-md-3 col-sm-3 col-10"
                            *ngFor="let obj of providersDetails?.images">
                            <div class="img-data">
                                <img [src]="obj">
                            </div>
                        </div>
                        <div class="gallery-column col-lg-12 col-md-12 col-sm-12 col-12"
                            *ngIf="providersDetails?.images.length== 0 ">
                            <!-- No data available -->
                            <!-- <div class="text-center" *ngIf="bookingsList?.length == 0"> -->

                            <div class="container no-data-wrapper text-center">
                                <div class="empty-cart-img text-center">
                                    <img src="assets/images/No data-rafiki.svg" alt="No data available">
                                </div>
                                <div class="text-center no-data-text">No data available</div>
                            </div>

                            <!-- </div> -->
                        </div>
                    </div>
                </div>
            </div>`
        </div>
    </div>
</div>


<app-footer></app-footer>
<section *ngIf="pop.value">
    <app-cart-pop [cartDetails]="pop"></app-cart-pop>
</section>