<div class="bg-color-of-pages">
    <div class="container">
        <div class="inner-data-whishlist">
            <div class="heading-page">My Wishlist</div>
            <div class="services-cards-col row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 column-card-parent">
                    <app-providers-service-list-card></app-providers-service-list-card>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 column-card-parent">
                    <app-providers-service-list-card></app-providers-service-list-card>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 column-card-parent">
                    <app-providers-service-list-card></app-providers-service-list-card>
                </div>
            </div>
        </div>
    </div>
</div>
  
<app-footer></app-footer>
  
