<div class="running-offers-container">
  <div class="container">
    <div id="crf" class="carousel slide" data-ride="carousel">

      <!-- Indicators -->
      <ul class="carousel-indicators">
        <li data-target="#crf" data-slide-to="0" class="active"></li>
        <li data-target="#crf" data-slide-to="1"></li>
        <li data-target="#crf" data-slide-to="2"></li>
      </ul>
      
      <!-- The slideshow -->
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="inner-data-sec">
            <div class="img-block">
              <img src="assets/icons/mans.png">
            </div>
            <div class="content-sec">
              <div class="offer-heading">Get 20% Off</div>
              <div class="sub-heading">On All Plumbing Services!</div>
              <button class="sb-primary-btn">Book Today</button>
              <div class="terms-info">Terms & Conditions Apply*</div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="inner-data-sec">
            <div class="img-block">
              <img src="assets/icons/mans.png">
            </div>
            <div class="content-sec">
              <div class="offer-heading">Get 20% Off</div>
              <div class="sub-heading">On All Plumbing Services!</div>
              <button class="sb-primary-btn">Book Today</button>
              <div class="terms-info">Terms & Conditions Apply*</div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="inner-data-sec">
            <div class="img-block">
              <img src="assets/icons/mans.png">
            </div>
            <div class="content-sec">
              <div class="offer-heading">Get 20% Off</div>
              <div class="sub-heading">On All Plumbing Services!</div>
              <button class="sb-primary-btn">Book Today</button>
              <div class="terms-info">Terms & Conditions Apply*</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
