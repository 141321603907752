import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { param } from 'jquery';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-request-quote',
  templateUrl: './request-quote.component.html',
  styleUrls: ['./request-quote.component.scss']
})
export class RequestQuoteComponent implements OnInit, OnDestroy {
  requestQuoteForm: any;
  countryCode = environment.countryCode;
  formSubmited = false;
  errMsg = '';
  providerId: any;
  serviceId: any;
  showSuccessBlock = false;
  detailsObj: any;
  serviceDetails: any;
  currencyCode = environment.currencyCode;
  inquiry_id: any;
  branchId: any;
  constructor(private dataService: DataService, private storageService: StorageService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.providerId = params.providerId;
      this.serviceId = params.serviceId;
      this.branchId = params.branchId;
    });
    this.requestQuoteForm = new FormGroup({
      fullName: new FormControl(JSON.parse(<string>this.storageService.getItem('user_info')).name, [Validators.required, Validators.maxLength(25)]),
      mobile: new FormControl(JSON.parse(<string>this.storageService.getItem('user_info')).mobile.replace('+44', ''), [Validators.required, Validators.pattern(new RegExp("[0-9 ]{10}")), Validators.maxLength(10)]),
      email: new FormControl(JSON.parse(<string>this.storageService.getItem('user_info')).email, [Validators.required, Validators.email]),
      quote: new FormControl('', [Validators.required, Validators.maxLength(500)]),
      captcha: new FormControl(null, [Validators.required])
    });

    this.detailsObj = JSON.parse(<string>this.storageService.getItem('quoteDetails'));

  }

  get requestQuoteFormControls() {
    return this.requestQuoteForm.controls;
  }

  onSubmit = (event: any) => {
    event.preventDefault();
    this.formSubmited = true;
    this.errMsg = '';
    if (this.requestQuoteForm.valid) {
      const reqObj = {
        customer_name: this.requestQuoteForm.value.fullName,
        customer_phone: this.countryCode + this.requestQuoteForm.value.mobile,
        customer_email: this.requestQuoteForm.value.email,
        existing_customer: JSON.parse(<string>this.storageService.getItem('user_info')).customer_id,
        message: this.requestQuoteForm.value.quote,
        service_type: this.serviceId,
        service_provider: this.providerId,
        serviceprovider_branch: this.branchId,
        preferred_days: ["Mon", "Tue", "fri"]
      }
      this.dataService.requestQuote(reqObj).subscribe((res: any) => {
        if (res && res.status === undefined) {
          this.formSubmited = false;
          this.showSuccessBlock = true;
          this.inquiry_id = res.inquiry_id;
        } else {
          this.errMsg = res?.error?.Error;
        }
      });
    }
  }

  ngOnDestroy(): void {
    if(this.detailsObj){
      this.storageService.removeItem("quoteDetails");
    }
  }

  removeEmptyFields(data: string[]): string {
    return data.filter(field => !!field).join(', ');
  }

}
