import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-tutor-personal-details',
  templateUrl: './my-tutor-personal-details.component.html',
  styleUrls: ['./my-tutor-personal-details.component.scss']
})
export class MyTutorPersonalDetailsComponent implements OnInit {
  selectedGender='male';
  selectedNationality='Inida';
  constructor() { }
 
Gender : any = [
    {name: 'Female'},
    {name: 'Male'},
    {name:'Others'}
 
  ];
  Nationality:any=[
    {name:'Inida'},
    {name:'America'},
    {name:'Australia'}
  ]
  

  ngOnInit(): void {
  }

}
