import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
// import { FileSaverService } from 'ngx-filesaver';
import { RouteConstants } from 'src/app/model/route-constants';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
declare var jQuery: any;
declare var Razorpay: any;
declare var Stripe: any;
declare var $: any;
@Component({
  selector: 'app-my-bookings',
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.scss']
})
export class MyBookingsComponent implements OnInit {
  bookingsList: any = [];
  selectedCancelReqId: any;
  tempIndex = -1;
  total: any;
  taxAmt: any;
  selectedData: any = [];
  hideWallet = false;
  providers_title: any;
  serviceProviderId: any;
  serviceProviderBranch: any;
  servicesList: any = [];
  depositType: any = 'Full';
  pageNo = 1;
  tempBookingList: any = [];
  refundAmount: any = 0;
  currencyCode: any;
  paymentSource: any = 'wallet';
  service_request_id: any;
  disabledBtn = false;
  depositAmt: any;
  paymentGateway: any = environment.paymentMode;
  totalPayableAmt: any;
  selectedBooking: any;
  walletAmt: any;
  // By Mohitha

  // refundAmount: any = 0;
  sortList = ['Accepted', 'Pending', 'InProgress', 'Completed', 'Cancelled', 'Rejected', 'Rescheduled'];
  defaultSortBy = 'InProgress';
  datesList = [{ name: '30 days', value: 30 },
  { name: '60 days', value: 60 },
  { name: '90 days', value: 90 }];
  defaultDate = '30 days';
  endDateValue = 30;
  startDate = moment(new Date(), 'MM-DD-YYYY').format('YYYYMMDD');
  // By Mohitha

  constructor(public dataService: DataService, public router: Router, private cdr: ChangeDetectorRef, private bs: BroadcastService, public storageService: StorageService, private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    // private fileSaverService: FileSaverService
    ) {
    this.walletAmt = parseInt(JSON.parse(<string>this.storageService.getItem('walletAmt')));
   }

  ngOnInit(): void {
    this.currencyCode = environment.currencyCode;
    this.cdr.detectChanges()

    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.defaultSortBy = params.status !== undefined ? params.status : this.defaultSortBy ;
      this.getBookingsList();
    });
  }

  getBookingsList = () => {
    const reqObj = {
      pageId: this.pageNo,
      query: this.defaultSortBy,
      startDate: moment().subtract(this.endDateValue, 'days').format('YYYYMMDD'),
      endDate: this.startDate
    }
    this.dataService.getBookingsList(reqObj).subscribe((res: any) => {
      if (res) {
        this.tempBookingList = res.results;
        this.tempBookingList.forEach((data: any) => {
          data.cancel = false;
          data.isChecked = false;
          this.providers_title = data.service_provider.slug_legal_name;
            if(data?.services !== null){
              data.services.forEach((item: any) => {
                item.isChecked = false;
              });
            }
        });
        for (let i = 0; i < this.tempBookingList.length; i++) {
          this.bookingsList.push(this.tempBookingList[i]);
        }
      }
    });
  }

  // 
  createServiceRequest = () => {
    this.disabledBtn = true;
    const reqObj = {
      service_provider: this.serviceProviderId,
      serviceprovider_branch: this.serviceProviderBranch,
      estimated_rounds: 0,
      services: this.servicesList,
      payment_type: this.depositType,
      is_wallet_payment: this.paymentSource === 'wallet' ? "True" : "False"
    }
    this.dataService.servicerequest(reqObj).subscribe((res: any) => {
      if (res && res.Error === undefined) {
        this.disabledBtn = false;
        this.service_request_id = res.service_request_id
        // if (this.depositType === 'Full' && this.walletAmt <= this.totalPayableAmt && fromState !== 'wallet') {
        //   this.checkPaymentMode(res);
        // } else if (this.depositType === 'Deposit' && this.walletAmt <= this.depositAmt && fromState !== 'wallet') {
        //   this.checkPaymentMode(res);
        // } else
        if (this.paymentSource === 'wallet') {
          this.goToPaymentSuccessPage();
        } else {
          this.checkPaymentMode(res);
        }
      }
    })
  }

  goToPaymentSuccessPage = () => {
    $('#stripePopup').modal('hide');
    this.storageService.removeItem('cartItems');
    this.storageService.removeItem('selectedPlan');
    this.bs.broadcast('REFRESH_CART', 'count');
    this.bs.broadcast('REFRESH_CART', 'wallet')
    $("#submit-button").attr("disabled", false);;
    this.router.navigate([RouteConstants.PAYMENT_SUCCESS], {
      queryParams: {
        amt: this.depositType === 'Full' ? this.totalPayableAmt : this.depositAmt,
        bookingId: this.service_request_id
      }
    })
  }

  cancelBooking = (data: any, id: any) => {
    this.refundAmount = 0;
    if (this.tempIndex !== -1) {
      this.bookingsList[this.tempIndex].cancel = false;
      this.selectedData = [];
    }
    this.selectedCancelReqId = data.service_request_id;
    data.cancel = true;
    this.tempIndex = id;
  }

  onBackFn = (data: any) => {
    data.cancel = false;
  }

  changeSelection = (parent: any, child: any) => {
    if (this.selectedData.length === 0) {
      this.refundAmount = 0;
    }
    if (child !== -1) {
      child.isChecked = !child.isChecked;
      const newId = child.booking_id;

      if (!this.selectedData.includes(newId)) {
        this.selectedData.push(newId);
        this.refundAmount = this.refundAmount + (child.total);
      } else {
        this.selectedData.splice(this.selectedData.indexOf(newId), 1);
        this.refundAmount = this.refundAmount - (child.total);
      }
      let list  = parent.services.filter((obj: any) => obj.status !== 'Cancelled');
      parent.isChecked = this.selectedData.length === list?.length;
    } else if (child === -1) {
      parent.isChecked = !parent.isChecked;
      if (parent.isChecked) {
        this.refundAmount = 0;
        parent.services.forEach((item: any) => {
          item.isChecked = true;
          if(item.status !== 'Cancelled'){
            this.refundAmount = this.refundAmount + (item.total)
            this.selectedData.push(item.booking_id);
          }
        });
      } else {
        parent.services.forEach((item: any) => {
          item.isChecked = false;
          this.refundAmount = 0;
        });
        this.selectedData = [];
      }
    }
  }


  gotoReviewPage = (data: any) => {
    jQuery('body, html').animate({ scrollTop: 0 }, 100)
    this.router.navigate([RouteConstants.RATINGS_AND_REVIEWS], {
      queryParams: {
        providerId: data.service_provider.service_provider_id,
        branch_slug_title: data.serviceprovider_branch.slug_service_branch_name,
        wrt: 'true',
        reqId: data.service_request_id,
        branchId: data.serviceprovider_branch.branch_id
      }
    })
  }

   inputOptions = {
        'wallet': 'Refund to wallet',
        'account': 'Refund to your account',
      }

  callInputValidatorFn = (value:  any): any => {
      if (!value) {
        return 'You need to choose something!'
      }
    }

    showRefundPopup = false;
    refundType: any;
    selectedBookingIdForCancel : any;

    onRefundMethod = () => {
      if (this.refundType === undefined) {
        Swal.fire({
          text: 'You need to choose something!',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      }else if (this.cancellationReason === '') {
        Swal.fire({
          text: 'please enter cancelation reason',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        });
        return;
      }else if(this.selectedBookingIdForCancel !== undefined && this.selectedBookingIdForCancel !== null && this.selectedBookingIdForCancel !== ''){
        this.cancelBookingAPI(this.selectedBookingIdForCancel, this.refundType)
      }
    }

  onCancelFn = async (data: any) => {
    this.showRefundPopup = false;
    if(data.is_wallet_payment === false && data.payment_status === "Paid"){
      if (this.selectedData?.length === 0) {
        Swal.fire({
          text: 'select any one service',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        });
        return;
      }
      this.showRefundPopup = true; 
      this.selectedBookingIdForCancel = data;
      // const { value: selectedVal } = await Swal.fire({
      //   text: 'choose Deposit amount will get credited to wallet/account',
      //   input: 'radio',
      //   inputOptions: this.inputOptions,
      //   inputValidator: (value:  any): any => {
      //     if (!value) {
      //       return 'You need to choose something!'
      //     }
      //   }
      // })
      
      // if (selectedVal) {
      //   this.cancelBookingAPI(data, selectedVal)
      // }
    }else{
      this.cancelBookingAPI(data)
    }
  
  }
  serviceNames: any =[];
  cancellationReason: any ='';
  cancelBookingAPI = (data: any, state?: any) =>{
    const obj: any = [];
    this.serviceNames = []; 
    let reqObj: any = {
      service_request_id: data.service_request_id,
    }
    this.refundAmount = 0;
    let apiName :any;
    // apiName=  this.dataService.cancelBooking(reqObj)
    if (this.selectedData?.length > 0) {
      data.services.forEach((a: any) => {
        if (this.selectedData.includes(a.booking_id)) {
          obj.push({subrequest_id: a.booking_id, cancellation_reason: this.cancellationReason});
          this.serviceNames.push(a.service.title)
        }
      });
      reqObj.sub_requests = obj;
      if(state !== undefined){
        reqObj.refund_payment_method = state === 'wallet' ? false : true
      } 
      reqObj.cancellation_reason = this.cancellationReason;
      apiName = this.dataService.cancelBookingBySelectedServices(reqObj)
    }else{
      Swal.fire({
        text: 'select any one service',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        allowEscapeKey: false
      });
      return
    }
    apiName.subscribe((res: any) => {
      if (res && res.Error === undefined) {
        // if (environment.paymentMode === 'stripe' && data.order_id !== null && !data.is_wallet_payment) {
        //   const req = {
        //     payment_intent: data.order_id
        //   }
        //   const apiName = this.dataService.stripePaymentRefund(req);
        //   this.paymentRefund(apiName);
        // } else if (!data.is_wallet_payment) {
        //   const req = {
        //     amount: res.refund_amount,
        //     paymentId: data.order_id
        //   }
        //   const apiName = this.dataService.paymentRefund(req);
        //   this.paymentRefund(apiName);
        // } else if (data.is_wallet_payment) {
          this.bs.broadcast('REFRESH_CART', 'wallet');
          jQuery('body, html').animate({ scrollTop: 0 }, 100)
          this.router.navigate([RouteConstants.CANCEL_BOOKINGS], {
            queryParams: {
              orderId: this.selectedCancelReqId,
              orderCount: this.selectedData?.length,
              sn: this.serviceNames.join(',')
            }
          })
          this.cancellationReason = '';
        // }
      } else if (res && res.Error) {
        Swal.fire({
          text: res.Error || res.error.Error,
          icon: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      }
    });
  }

  paymentRefund = (apiNameObj: any) => {
    apiNameObj.subscribe((res: any) => {
      if (res && res.Error === undefined) {
        this.bs.broadcast('REFRESH_CART', 'wallet');
        jQuery('body, html').animate({ scrollTop: 0 }, 100)
        this.router.navigate([RouteConstants.CANCEL_BOOKINGS], {
          queryParams: {
            orderId: this.selectedCancelReqId,
            orderCount: this.selectedData?.length,
            sn: this.serviceNames[0]
          }
        })
      }
    });
  }

  onScrollUp(ev: any) {
    console.log("scrolled up!", ev);
  }
  onScrollDown(ev: any) {
    console.log("scrolled down!", ev);
    this.pageNo += 1;
    this.getBookingsList();
  }

  ChangeSortByFn = (e: any) => {
    this.defaultSortBy = e;
    this.pageNo = 1;
    this.bookingsList = [];
    this.getBookingsList();
  }

  ChangeDateFn = (e: any) => {
    this.defaultDate = e.name;
    this.endDateValue = e.value;
    this.pageNo = 1;
    this.bookingsList = [];
    this.getBookingsList();
  }

  showWallet = false;
  showPayDuePopup = false;
  onPayDue = (cObj: any) => {
    this.selectedBooking = cObj;
    this.showPayDuePopup = true;
    if (cObj.payment_type === "Full") {
   
      this.total = cObj.amount + cObj.taxes_amount;
      this.taxAmt = 0;
    }else  if (cObj.payment_type === "Deposit") {
   
      this.total = (cObj.amount + cObj.taxes_amount) - cObj.deposit_amount;
      this.taxAmt = 0;
    }

    if (this.walletAmt <= this.total) {
      this.showWallet = false;
      this.paymentSource='online';
    } else if (this.walletAmt <= this.total) {
      this.showWallet = false;
      this.paymentSource='online';
    } else {
      this.showWallet = true;
    }
  }


  onPay = () => {
      this.createStripeCheckoutSession(this.selectedBooking, this.paymentSource);
  }

  checkPaymentMode = (res: any) => {
    if (environment.paymentMode === 'stripe') {
      // $('#stripePopup').modal('show');
      this.createStripeCheckoutSession(res);

    } else {
      this.createRzpayOrder(res);
    }
  }
  // call api to create order_id
  createRzpayOrder(data: any) {
    const reqObj = {
      amount: data.amount,
      for_wallet: false,
      service_request: data.service_request_id
    }
    this.dataService.createPaymentOrder(reqObj).subscribe((res: any) => {
      if (res) {
        this.payWithRazor(res);
      }
    })
  }

  payWithRazor = (val: any) => {
    const payOptions: any = {
      'key': environment.razorPayKey,
      'amount': val.amount * 100, // 2000 paise = INR 20
      'name': 'ScheduleItNow',
      'description': 'Quein Wallet',
      'image': 'assets/icons/login-signup/logo-serve.svg',
      'handler': (response: any) => {
        this.completeTransaction(response);
      },
      'order_id': val.order_id,
      'prefill': {
        'name': JSON.parse(<string>this.storageService.getItem('user_info')).name,
        'email': JSON.parse(<string>this.storageService.getItem('user_info')).email,
        'contact': JSON.parse(<string>this.storageService.getItem('user_info')).mobile,
        'method': 'wallet'
      },
      'theme': {
        'color': '#1fa1ed'
      }
    };

    var rzp1 = new Razorpay(payOptions);
    rzp1.open();

  }

  completeTransaction = (data: any) => {
    if (data) {
      const reqObj = {
        pg_order_id: data.razorpay_order_id,
        pg_payment_id: data.razorpay_payment_id,
        pg_signature: data.razorpay_signature
      }
      this.dataService.verifyPaymentCheckout(reqObj).subscribe((res: any) => {
        if (res) {
          this.goToSuccessPage(data);
        }
      })
    }
  }

  goToSuccessPage = (data: any) => {
    $('#stripePopup').modal('hide');
    $("#submit-button").attr("disabled", false);;
    this.router.navigate([RouteConstants.PAYMENT_SUCCESS], {
      queryParams: {
        amt: data.amount,
        bookingId: data.service_request_id
      }
    })
  }

  createStripeCheckoutSession(data?: any, fromState?: any) {
    const reqObj = {
      amount: data.amount,
      for_wallet: false,
      service_request: data.service_request_id,
      email: JSON.parse(<string>this.storageService.getItem('user_info')).email,
      wallet_payment: fromState === 'wallet' ? true : false,
      domain: window.location.hostname,
      customer_id: JSON.parse(<string>this.storageService.getItem("user_info")).customer_id

    }
    this.dataService.checkoutSession(reqObj).subscribe((res1: any) => {
      if(res1 && res1.info !== undefined){
        Swal.fire({
          text: res1.info,
          icon: 'success',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((res: any) => {
          if (res.isConfirmed) {
            this.bookingsList = [];
            this.defaultSortBy = 'InProgress';
            this.cdr.detectChanges()
            this.getBookingsList();
            this.bs.broadcast('REFRESH_CART', 'wallet');
            $('#PayDueModal').removeClass('show');
            $('#PayDueModal').modal('hide');
            $('.modal-backdrop').remove();
            this.showPayDuePopup = false;
          }
        });
      }else
      if (res1 && res1.Error === undefined) {
        window.location.href = res1.url;
      }
    });
  }

  downloadInvoice = (data: any) => {
   let reqObj ={
       service_request: data.service_request_id,
    }
  
    // this.downloadPDF(reqObj, true);
  //   this.downloadPDF(reqObj).subscribe((res1: any) => {
  //     if(res1 && res1.info !== undefined){
  //       Swal.fire({
  //         text: res1.info,
  //         icon: 'success',
  //         confirmButtonText: 'Ok',
  //         allowOutsideClick: false,
  //         allowEscapeKey: false
  //       }).then((res: any) => {
  //         if (res.isConfirmed) {
  //           this.bookingsList = [];
  //           this.defaultSortBy = 'InProgress';
  //           this.cdr.detectChanges()
  //           this.getBookingsList();
  //         }
  //       });
  // }
    // });'
}

// text = "hello"
// downloadPDF(reqObj: any, fromRemote: boolean) {
//   let targetUrl = environment.apiUrl + `invoice/by_service_request`;
//   let httpParams = new HttpParams();
    
//     let headers = new HttpHeaders();
//     headers= headers.append('content-type', 'application/pdf');

//     if (reqObj.service_request !== undefined && reqObj.service_request !== '') {
//       httpParams = httpParams.append('service_request', reqObj.service_request)
//     }
//   const fileName = `save.${'type'}`;
//       if (fromRemote) {
//         this.httpClient.get(targetUrl, {
//           params: httpParams,
//           observe: 'response',
//           responseType: 'blob'
//         }).subscribe(res => {
//           this.fileSaverService.save(res.body, fileName);
//         });
//         return;
//       }
//       const fileType = this.fileSaverService.genType(fileName);
//       const txtBlob = new Blob([this.text], { type: fileType });
//       this.fileSaverService.save(txtBlob, fileName);

//   }


}
