import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-tutor-subject-level',
  templateUrl: './tutor-subject-level.component.html',
  styleUrls: ['./tutor-subject-level.component.scss']
})
export class TutorSubjectLevelComponent implements OnInit {
  showInput: boolean | undefined;
  displayBasic: boolean | undefined;

  selectedGrade: string = 'a-g';
    constructor(private primengConfig: PrimeNGConfig) {}
 
    showTextInput = false;

  
 

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }
  qualifications : any = [
    {name: 'Biology'},
    {name: 'Science'},
    {name:'Music'},
    {name:'Urdu'}
  ]
  grade:any=[
    {name:'A+'},
    {name:'A'},
    {name:'B+'},
    {name:'B'},
    {name:'C+'},
    {name:'C'}
  ]
  showBasicDialog() {
    this.displayBasic = true;
}
addTextInput() {
  this.showTextInput = !this.showTextInput;
}
}
