import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-tutor-header',
  templateUrl: './tutor-header.component.html',
  styleUrls: ['./tutor-header.component.scss']
})
export class TutorHeaderComponent implements OnInit {
  // showButtons =true;
  // showLogin: boolean | undefined;
  // showSignup: boolean | undefined;
  showButtons: boolean = true;
  loggedIn: boolean = false;
  activatedRoute: any;
  currentRoute!: string;
  hideButtons = false;
  showLogin: boolean | undefined;
  showSignup: boolean | undefined;
  showSignout: boolean | undefined;


  constructor(private route: ActivatedRoute, private router: Router,private authService: AuthService) { }

  ngOnInit(): void {
 /*
    this.route.url.subscribe(url => {
      this.showLogin = !url.some(segment => segment.path === 'view-tutors');
      this.showSignup = !url.some(segment => segment.path === 'view-tutors');
    });
    */


    // this.route.url.subscribe(url => {
    //   this.showLogin = !url.some(segment => segment.path === 'view-tutors' || segment.path === 'student-bookings');
    //   this.showSignup = !url.some(segment => segment.path === 'view-tutors' || segment.path === 'student-bookings');
    // });



    this.route.url.subscribe(url => {
      this.showLogin = !url.some(segment => ['view-tutors', 'student-bookings', 'book-lessons','tutor-profile','tutor-bookings','tutor-signup','studentprofile','tutor-dashboard','student_personal_details'].includes(segment.path));
      this.showSignup = !url.some(segment => ['view-tutors', 'student-bookings', 'book-lessons','tutor-profile','tutor-bookings','tutor-login','tutor-dashboard','student_personal_details'].includes(segment.path));
      this.showSignout= !url.some(segment =>['tutor-signup','tutor-theme','tutor-login'].includes(segment.path))
    })

   /*
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.url.split('');
        this.showLogin = !url.some(segment => segment === 'login');
        this.showSignup = !url.some(segment => segment === 'signup');
      }
    });
*/

    //


    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     const path = event.url;
    //     if (path.includes('/student-bookings') || path.includes('/view-tutors')) {
    //       this.hideButtons = true;
    //     } else {
    //       this.hideButtons = false;
    //     }
    //   }
    // });
  }
  }








