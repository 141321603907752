
<app-tutor-header></app-tutor-header>
<div class="container-fluid student-profile-wrapper">
    <h1 class="page-title">Personal Details</h1>

    <div class="student-details-wrapper">

        <div class="s-details-inner">
            <form>
                <h5 class="g-details mb-3">
                    General details
                </h5>

                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="">First name</label>
                            <input type="text" class="form-control" id="" placeholder="Enter first name">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="">Last name</label>
                            <input type="text" class="form-control" id="" placeholder="Enter last name">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="">Email address</label>
                            <input type="email" class="form-control" id="" placeholder="Enter email">
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="">Date of birth</label>
                            <input type="date" class="form-control" id="" placeholder="">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="">Parent name</label>
                            <input type="text" class="form-control" id="" placeholder="Enter last name" value="Naveen Kumar" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="">Parent email</label>
                            <input type="email" class="form-control" id="" placeholder="Enter email" value="naveen.accenflair@gmail.com" readonly>
                        </div>
                    </div>
                </div>

                <h5 class="g-details mt-3 mb-3">
                    Additional details
                </h5>

                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="">School name</label>
                            <input type="text" class="form-control" id="" placeholder="Enter school name">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="">School location</label>
                            <ng-select
                            [items]="schoolsLocation"
                            bindLabel="name"
                            [multiple]="false"
                            [(ngModel)]="defaultLocation"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="">School year</label>
                            <input type="text" class="form-control" id="" placeholder="Enter school year">
                        </div>
                    </div>
                </div>

                <button type="submit" class="btn">Submit</button>
            </form>
        </div>

    </div>

</div>