import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tutor-graducation-certificate',
  templateUrl: './tutor-graducation-certificate.component.html',
  styleUrls: ['./tutor-graducation-certificate.component.scss']
})
export class TutorGraducationCertificateComponent implements OnInit {
  selectedFile!: File;
  selectedVideoFile: any;
  filenames: string | undefined;
  selectedImageFile: any;

  constructor(private http: HttpClient) {}


  ngOnInit(): void {
  }

  onImageSelected(event: any) {
    this.selectedImageFile = event.target.files[0];
    this.updateFilenames();
  }
  
  onVideoSelected(event: any) {
    this.selectedVideoFile = event.target.files[0];
    this.updateFilenames();
  }
  
  updateFilenames() {
    let filenames = '';
    if (this.selectedImageFile) {
      filenames += this.selectedImageFile.name;
    }
    if (this.selectedVideoFile) {
      if (filenames.length > 0) {
        filenames += ', ';
      }
      filenames += this.selectedVideoFile.name;
    }
    this.filenames = filenames;
  }
  formData: FormData = new FormData();
  fileList: any[] = [];
  maxFileSize = 0.5 * 1024 * 1024; // modify this to your desired max file size
  onFileChange(eve: any) {
    if(eve && eve.addedFiles.length>0){   
    if(eve.addedFiles[0].size > this.maxFileSize)
    {
      Swal.fire('','Image size should not exceed 0.5MB');
      return;
    }
    this.fileList = eve.addedFiles;
    console.log(this.fileList);
    let file: File = this.fileList[0];
    this.formData.set("image", file);
    // console.log(this.formData.get(this.serviceObj.image));
  }
  }
  //Method to remove files
  onRemove(event: any) {
    console.log(event);
    //event.File.splice(event.File.indexOf(event), 1);
    this.fileList.splice(this.fileList.indexOf(event), 1);
  }
}




