<img src="https://safaiwale.in/wp-content/themes/clenix/assets/img/banner.jpg" alt="" style="width: 100%;">
<!-- Temporary content -->
<div class="about-provider" id="about">
    <h6 class="text-center">The best window cleaning company</h6>
    <h3 class="text-center text-bold mt-2">About G S Window Cleaning</h3>

    <div class="row mt-5">
        <div class="col-md-6">
            <img src="assets/images/gsw.png" alt="" style="width: 100%;">
        </div>
        <div class="col-md-6 gs-right-sec">
            <p>When it comes to maintaining your property it is important to choose a trusted company to make
                sure the cleaning tasks are done properly. We pride ourselves on being safe contractors with all
                the relevant training and insurances, so you can have peace of mind that we will do a great job.
                We are your local external cleaning company and have been established for over 15 years.</p>
            <p>We are best known for our 5* window cleaning in St Neots. We provide regular and reliable window
                cleaning in St Neots to a long list of happy customers, take a look at our reviews to see for
                yourself what our customers have to say. We use the latest water fed pole technology with pure
                water when window cleaning in St Neots, we can clean your windows safely from the ground and
                keep them sparkling all year round.</p>
            <p class="d-none">We also offer a whole range of external cleaning services such as gutter cleaning,
                roof cleaning, jet washing, etc. We are your one stop shop when it comes to taking care of your
                property. We welcome both domestic and commercial clients and have experience cleaning all
                different sorts of buildings. We offer fantastic rates for all of our services, if you would
                like a quote, please get in touch and we would be happy to give you a price for our work.</p>
            
            <div class="cert-wrapper">
                <div class="cert-item text-center">
                    <img src="assets/images/cert-one.png" alt="">
                </div>
                <div class="cert-item text-center">
                    <img src="assets/images/cert-two.jpg" alt="">
                </div>
                <div class="cert-item text-center">
                    <img src="assets/images/cert-three.jpg" alt="">
                </div>
                <div class="cert-item text-center">
                    <img src="assets/images/cert-four.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Temporary content -->

<app-footer></app-footer>