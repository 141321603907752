import {  Component,  OnInit } from '@angular/core';
import { RouteConstants } from 'src/app/model/route-constants';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  servicesList: any = [];
  currencyCode = environment.currencyCode;

  constructor(public dataService: DataService, 
     public router: Router) {
  }

  
  ngOnInit(): void {
    this.getProviderServiceList();
  }

  getProviderServiceList = () => {
    const reqObj = {
      providerId: sessionStorage.getItem("SERVICE_PROVIDER")
    }
    this.dataService.getCustomProviderServicesList(reqObj).subscribe((data: any) => {
      if (data) {
        this.servicesList = data.results;
      }
    });
  }

  gotoServiceDetailsPage = (serviceObj:any) => {
    this.router.navigate([RouteConstants.SERVICE_DETAILS, serviceObj.service_category, serviceObj.slug_title]);
  }

  

  
}

