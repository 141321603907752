import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  subscribeUsForm: any;
  formSubmited = false;
  ukAddress = environment.ukAddress;
  facebookLink = environment.facebookLink;
  siteLogo = environment.siteLogo;
  productName = environment.productName;
  isShowServices = false;
  constructor(private dataService: DataService) {
   }

  ngOnInit(): void {
    this.subscribeUsForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
    this.isShowServices =(window.location.hostname.toLowerCase()==environment.cookieDomain.toLowerCase())
  }

  get emailControls() { return this.subscribeUsForm.get('email'); }

  onSubmit = (event: any) => {
    event.preventDefault();
    this.formSubmited = true;
    if (this.subscribeUsForm.valid) {
      this.dataService.subscribeUs(this.subscribeUsForm.value).subscribe((res: any) => {
        if (res && !res.error) {
          Swal.fire({
            text: res.Info,
            icon: 'success',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((data: any) => {
            this.subscribeUsForm.reset();
            this.formSubmited = false;
          });
        } else {
          Swal.fire({
            text: res?.error?.Error,
            icon: 'error',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
        }
      });
    }
  }
}
