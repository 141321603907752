
<app-tutor-header></app-tutor-header>
<div class="student-wrapper container">
    <div class="left-wraper">
<p [routerLink]="['/tutor-profile']"> <img src="assets/icons/t-arrow-left.svg" alt="">
    My tutor</p>

   <div class="cart-wrapper">
    <div>
      <img src="https://cdn.mytutor.co.uk/images/tutor-profiles/26009485.360_1-1_8.jpg?v=2" alt=""
      class="t-img">
    </div>
    <div>
      <p class="tutor-a">A-Level Physics exam prep </p>
      <p class="name-w">With Zaynah H</p>
    </div>
     <div>
      <p class="group-class-p">£135.00</p>
</div>
    

    </div>
   
        <hr>
        <div class="cart-wrapper">
            <div>
     
            </div>
            <div>
       <b>Sub Total</b>
            </div>
             <div>
              <p class="group-class-p">$270.00</p>
        </div>
            
        
            </div>
            <hr>
            <div class="cart-wrapper">
                <div>
         
                </div>
                <div>
           <b>Total due</b>
                </div>
                 <div>
                  <p class="group-class-p">$270.00</p>
            </div>
                
            
                </div>
    <div class="right-wrapper">

    </div>
</div>
