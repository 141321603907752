import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressPopupComponent } from 'src/app/components/address-popup/address-popup.component';
import { RouteConstants } from 'src/app/model/route-constants';
import { AuthService } from 'src/app/services/auth.service';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import moment = require('moment');
import { ToastrService } from 'ngx-toastr';

declare var $: any
@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.scss']
})
export class ServiceDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild('modelTemplate', { static: false }) modelTemplate: ElementRef;
  @ViewChild('mapSearchFiled2', { static: true }) searchFiled: ElementRef | undefined;
  userDetails: any
  lat = 0;
  lng = 0;
  zoom = 12;
  userLocation = "";
  geoCoder: any;
  selectedAddress: any;
  addressLabel: any = '';
  cityName: any;
  showAccount: any = false;
  serviceId: any;
  branchId: any;
  serviceDetails: any;
  branchList: any;
  showBranchModalPopup = false;
  showHomeModalPopup = false;
  showServicesPopup = false;
  showMainModalPopup = false;
  address_type: any;
  address_id: any;
  addressList: any;
  location: any;
  pop: any = {
    value: false
  };
  distance: any;
  showAddAddress = false;
  isSubmitted = false;
  categoryId: any;
  serviceTitle: any;
  currencyCode = environment.currencyCode;
  providersDetails: any;
  catId: string | undefined;
  branch_slug_title: string | undefined;
  providerId: any;
  showSelectedData: boolean = false;
  showAddRecurring: boolean = false;
  recurringForm!: any;
  dateSelected: any;
  selectedServiceOn: any;
  selectedRepeat: any;
  customRepeatText: string | undefined;
  customEndText: string | undefined;
  selectedEnd: any;
  repeatsEvery!: number;
  numServices!: number;
  selectedCustomRepeat: any;
  selectedDays = new Set<any>();
  staffList: any = [];
  // employees: any[];
  selectedStaff: any;
  timeSlotsList: any;
  isSelectSlots = false;
  formGroup: any;
  selectedTimeSlot!: string;
  // timeSlotsList: string[] = [];

  selectedSubscription: any;
  recurringIntervals: any[] = [];
  selectedPlan: any;
  selectBookAService = false;
  fromState: any;
  holidayList: any;


  date = new Date();
  addDaysToDate = () => {
    var res = new Date();
    res.setDate(res.getDate() + 15);
    return res;
  }
  minDate = { "year": this.date.getFullYear(), "month": this.date.getMonth() + 1, "day": this.date.getDate() };
  maxDate = { "year": this.addDaysToDate().getFullYear(), "month": this.addDaysToDate().getMonth() + 1, "day": this.addDaysToDate().getDate() };
  markDisabled: any;
  weekList: any;
  recuringIntervalList = [
    {
        name: "Monthly Plan",
        recurring_interval: "monthly"
    },
    {
        name: "Weekly Plan",
        recurring_interval: "weekly"
    },
    {
        name: "Daily Plan",
        recurring_interval: "daily"
    }
]

  // selectSubscription(item: any) {
  //   this.selectedSubscription = item;
  // }

  // showAddRecurring = false; // Initially hide the recurring interval

  constructor(private bs: BroadcastService, private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public dataService: DataService, public storageService: StorageService,
    public authService: AuthService,
    private cdr: ChangeDetectorRef,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private fb: FormBuilder,
    public calendar: NgbCalendar,
    private modalService: NgbModal, private sanitizer: DomSanitizer) {
    // Initialize the property
    this.modelTemplate = {} as ElementRef;

  }

  ngOnInit(): void {
    this.userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
    this.location = sessionStorage.getItem('location');
    this.activatedRoute.paramMap.subscribe((routeObj: any) => {
      this.categoryId = routeObj.params.categoryId;
      this.serviceTitle = routeObj.params.serviceTitle;
      this.getServiceDetails();
     

    });
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.fromState = params.fromState;
    });

    
    // this.getProvidersDetails();
    this.storageService.removeItem("branchsDropdown");
    this.recurringForm = new FormGroup({
      staff: new FormControl(null),
      preferredTime: new FormControl(null, [Validators.required]),
      startDate: new FormControl('', [Validators.required]),
      selectedRecurringInterval: new FormControl(null)
    });
  
      this.recurringForm?.get('startDate').valueChanges.subscribe(() => this.onBookingDateChange());
      this.recurringForm?.get('staff').valueChanges.subscribe(() => this.onChangeStaffSelection());


    // const cObj = {
    //   service: {
    //     service_id: 551,
    //     serviceprovider_branch: {
    //       branch_id: 1
    //     },
    //   }
    // };
    // this.getTimeSlots(cObj);

    
    // this.initForm();


  }

  initForm() {
    this.recurringForm = this.fb.group({
      startDate: ['', Validators.required],
      preferredTime: [null],
      selectedStaff: [null],
      selectedRecurringInterval: [null]
      // Add more form controls here
    });
  }



  repeatList = ['Every Week', 'Every 2 Weeks', 'Every Month', 'Custom'];
  customRepeatList = ['Days', 'Weeks', 'Months', 'Years'];
  endsList = ['Never', 'On', 'After'];

  repeatServiceOn = [
    { serviceOn: 'Mon', value: 'Monday' },
    { serviceOn: 'Tue', value: 'Tuesday' },
    { serviceOn: 'Wed', value: 'Wednesday' },
    { serviceOn: 'Thu', value: 'Thursday' },
    { serviceOn: 'Fri', value: 'Friday' },
    { serviceOn: 'Sat', value: 'Saturday' },
    { serviceOn: 'Sun', value: 'Sunday' }
  ];

  subscriptionList: string[] = ['One Time', 'Weekly', 'Monthly'];
  // subscriptionList: string[] = ['One Time', 'Weekly', 'Monthly', 'Custom'];
  // selectedSubscription: string = 'One Time';

  // onSubscriptionChange(item: string) {
  //   this.selectedSubscription = item;
  // }


  selectDayOn(item: any) {
    this.selectedServiceOn = item;
  }

  selectSubscription(item: any) {
    this.selectedSubscription = item;
  }

  onRepeatChange(event: any) {
    this.selectedRepeat = event;
    if (event !== 'Custom') {
      this.customRepeatText = '';
    }
  }

  onEndChange(event: any) {
    this.selectedEnd = event;
    if (event === 'On' || event === 'After') {
      this.customEndText = '';
    }
  }
  selectPlan(plan: any): void {
    this.selectedPlan = plan;
    this.showAddRecurring = true;
    this.selectBookAService = false;
  }

  // onSaveRecurring() {
  //   this.showSelectedData = true;
  // }

  onSaveRecurring() {
    if (this.recurringForm.valid) {
      const selectedRecurringInterval = this.recurringForm.value.selectedRecurringInterval;
      const selectedPlan = this.serviceDetails.plans.find((plan: any) => plan.recurring_interval === selectedRecurringInterval);

      if (!selectedPlan) {
        console.error('No plan found for the selected recurring interval');
        return;
      }

      const payload = {
        service_provider: 1,
        serviceprovider_branch: 1,
        payment_type: "Deposit",
        is_wallet_payment: false,
        lead_staff: this.recurringForm.value.selectedStaff || null,
        schedule_start_datetime: `${this.recurringForm.value.startDate}T12:00:00`,
        preferred_time: this.recurringForm.value.preferredTime || null,
        recurring_service_plan: selectedPlan.plan_id, // Use the selected plan_id
        address: 2,
        service_delivery_location: "HOME"
      };

      console.log('Form Payload:', payload);

      this.dataService.submitRecurringBooking(payload).subscribe(
        (response) => {
          console.log('API Response:', response);
        },
        (error) => {
          console.error('API Error:', error);
        }
      );
    }
  }



  fetchDateselected() {
    console.log("Selected date is :", this.dateSelected);
  }

  getCurrentDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // getProvidersDetails = () => {
  //   const req = {
  //     providerId: this.providerId,
  //     branch_slug_title: this.branch_slug_title
  //   }
  //   this.dataService.getServiceCategoryDetails(req).subscribe((data: any) => {
  //     if (data) {
  //       this.providersDetails = data;
  //     }
  //   })
  // }

  closeRecurringPopup() {
    this.showAddRecurring = false;
  }

  isDaySelected(item: any): boolean {
    return this.selectedDays.has(item);
  }

  toggleDaySelection(item: any): void {
    if (this.selectedDays.has(item)) {
      this.selectedDays.delete(item);
    } else {
      this.selectedDays.add(item);
    }
  }

  gotoReviewPage = (fromState?: any) => {
    jQuery('body, html').animate({ scrollTop: 0 }, 100)
    this.router.navigate([RouteConstants.RATINGS_AND_REVIEWS], {
      queryParams: {
        title: this.catId,
        providerId: this.providerId,
        branch_slug_title: this.branch_slug_title,
        wrt: "false",
        branchId: this.providersDetails.branch_id
      }
    })
  }

  sanitizeHTML(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  isRecurringIntervalAvail :any;
  // getServiceDetails = () => {
  //   this.dataService.getServiceDetails({ categoryId: this.categoryId, serviceTitle: this.serviceTitle }).subscribe((res: any) => {
  //     if (res) {
  //       this.serviceDetails = res?.data;
  //       this.distance = Math.round(res?.distance);
  //       this.refreshQuantiy();
  //     }
  //   })
  // }

  getServiceDetails() {
    this.dataService.getServiceDetails({ categoryId: this.categoryId, serviceTitle: this.serviceTitle }).subscribe(
      (res: any) => {
        if (res && res.data && res.data.plans) {
          this.serviceDetails = res.data;


          // if(this.serviceDetails?.plans && this.serviceDetails.plans?.length > 0){

          //   this.isRecurringIntervalAvail = this.serviceDetails.plans.filter((obj: any) => {
          //       return obj.recurring_interval === null 
          //   });

          //   if(this.isRecurringIntervalAvail && this.isRecurringIntervalAvail?.length > 0){
          //     this.recurringForm = new FormGroup({
          //       staff: new FormControl(null),
          //       preferredTime: new FormControl(null, [Validators.required]),
          //       startDate: new FormControl('', [Validators.required]),
          //       selectedRecurringInterval: new FormControl(null,[Validators.required])
          //     });
          //   }
           
          //       //  Time Slots
          //     const startTime = 0;
          //     const endTime = 24 * 60;
          //     const interval = 15;
          //     this.timeSlotsList = [];
          //     for (let time = startTime; time < endTime; time += interval) {
          //       const hours = Math.floor(time / 60);
          //       const minutes = time % 60;
          //       const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
          //       this.timeSlotsList.push(formattedTime);
          //     }
          //     console.log(this.timeSlotsList)
          // }else{
           
          // }

          // this.getHolidayList();
          // this.getStaffList();
          

          let cartItems: any = this.storageService.getItem('cartItems');
            cartItems = (cartItems != null && cartItems != undefined) ? JSON.parse(cartItems).results : [];
  
           if(cartItems?.length > 0 && this.fromState === 'cart'){
            this.showAddRecurring = true;
           }
          //     for(let i = 0 ; i<cartItems?.length; i++){
          //       if(cartItems[i].service?.service_id === this.serviceDetails.service_id || 
          //         cartItems[i]?.plan?.service?.service_id === this.serviceDetails.service_id){
          //         this.showAddRecurring = true;
          //         this.schedule_date = cartItems[i].booking_date;
          //         this.selectBookAService = false;

          //         this.selectedPlan =  this.serviceDetails.plans.find((obj: any) => {
          //           if(obj.plan_id === cartItems[i]?.plan?.plan_id){
          //             return obj;
          //           }
          //       });
          //         if(this.serviceDetails?.plans && this.serviceDetails.plans?.length > 0){
          //           this.recurringForm.setValue({
          //             staff: cartItems[i]?.staff?.employee_id,
          //             preferredTime: cartItems[i]?.booking_time,
          //             startDate: { "year": new Date(cartItems[i].booking_date).getFullYear(), "month": new Date(cartItems[i].booking_date).getMonth() + 1, "day": new Date(cartItems[i].booking_date).getDate() },
          //             selectedRecurringInterval: this.isRecurringIntervalAvail && this.isRecurringIntervalAvail?.length > 0 ? this.selectedPlan.recurring_service_plan : null
          //           });
          //         }else{
          //           this.selectBookAService = true;
          //           this.getTimeSlots();
          //           this.recurringForm.setValue({
          //             staff: cartItems[i]?.staff?.employee_id,
          //             preferredTime: cartItems[i]?.booking_time,
          //             startDate: { "year": new Date(cartItems[i].booking_date).getFullYear(), "month": new Date(cartItems[i].booking_date).getMonth() + 1, "day": new Date(cartItems[i].booking_date).getDate() },
          //             selectedRecurringInterval: ''
          //           });
          //         }
          //       }
          //     }
          //  }

     

          this.distance = Math.round(res.distance);
          this.selectedSubscription = this.serviceDetails.plans[0]?.name;
          if (Array.isArray(this.serviceDetails.plans)) {
            this.recurringIntervals = this.serviceDetails.plans.map((plan: any) => plan.recurring_interval);
          } else {
            console.error('Plans is not an array in the API response:', this.serviceDetails.plans);
          }
        } else {
          console.error('Unexpected API response format:', res);
        }
      },
      (error) => {
        console.error('Error fetching service details:', error);
      }
    );

    
  
  }


  changeDropdownValue = () => {
    let val: any = +this.serviceDetails.service_provider.service_provider_id;
    let data = JSON.parse(<string>this.storageService.getItem("branchsDropdown"));
    data = data == null || data == "" ? [] : data;
    if (data?.indexOf(val) > -1) {
      console.log('new');
    } else {
      console.log('else')
      data.push(val);
      this.storageService.setItem("branchsDropdown", JSON.stringify(data));

      this.getBranchAddressList();
    }

  }

  openPopup = () => {

    this.showAddAddress = false;
    if (!this.authService.isAuthenticated()) {
      this.storageService.setItem("redirectURL", JSON.stringify(this.router.url))
      this.router.navigate(['sign-in']);
      return;
    }
    if (this.serviceDetails.quote_only) {
      this.router.navigate([RouteConstants.REQUEST_QUOTE], {
        queryParams: {
          providerId: this.serviceDetails?.service_provider.service_provider_id,
          serviceId: this.serviceDetails.service_id,
          branchId: this.serviceDetails.serviceprovider_branch.branch_id
        }
      });
      const data = {
        providerName: this.serviceDetails.service_provider.legal_name,
        providerLogoUrl: this.serviceDetails.service_provider.logo_url,
        serviceName: this.serviceDetails.title,
        serviceLogoUrl: this.serviceDetails.image,
        availability: this.serviceDetails.avail_at,
        price: this.serviceDetails.min_charge,
        contact: this.serviceDetails.avail_at,
        branchAddress: this.serviceDetails.service_provider.register_address
      };
      this.storageService.setItem("quoteDetails", JSON.stringify(data));
    }
    else if (this.serviceDetails.avail_at === 'home_only') {
      this.getCustomerAddressList();
    } else if (this.serviceDetails.avail_at === 'center_only') {
      this.getBranchAddressList();
    } else {
      this.showBranchModalPopup = false;
      this.showHomeModalPopup = false;
      this.showServicesPopup = true;
    }
    this.modalService.open(this.modelTemplate, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
  }

  decrement = () => {
    this.router.navigate([RouteConstants.CART]);
  }

  checkQuantity = () => {
    let val = 1;
    let cartItems: any = this.storageService.getItem('cartItems');
    cartItems = cartItems !== null && cartItems !== undefined ? JSON.parse(cartItems).results : [];
    for (var i = 0; i < cartItems.length; i++) {
      if (cartItems[i]?.booking_time === this.recurringForm.value.preferredTime &&
        cartItems[i]?.booking_date === this.schedule_date  ) {
        val = cartItems[i]?.quantity + 1
        return val;
      }
    }
    return val;
  }

  closeModal() {

  }

  showItemCount = () => {
    // if (!this.address_id) {
    //   Swal.fire({
    //     text: "Please select one address",
    //     icon: 'warning',
    //     confirmButtonText: 'Ok',
    //     allowOutsideClick: false,
    //     allowEscapeKey: false
    //   });
    //   return;
    // }
    if (this.recurringForm.valid) {
        let providerId: any = this.serviceDetails?.service_provider?.slug_legal_name;
        let branchId: any = this.serviceDetails.serviceprovider_branch.branch_id;
        let providerName: any = this.serviceDetails.service_provider.legal_name;
        let isServiceAdded = true;
        let isPlanAdded = true;
        let cartItems: any = this.storageService.getItem('cartItems');
        let exitingProviderName = '';
        let msg = "You are allowed to subscribe one service at a time. DO you want to discard the selection and add plan";

        cartItems = cartItems !== null && cartItems !== undefined ? JSON.parse(cartItems).results : [];
        for (var i = 0; i < cartItems.length; i++) {
          branchId = branchId === 0 ? +'1' : branchId;
          let cartItem = cartItems[i];
          if (cartItem.service && (cartItem.service.service_provider?.slug_legal_name != providerId ||
            cartItem.service.serviceprovider_branch.branch_id != branchId)) {
            exitingProviderName = cartItem.service.service_provider?.legal_name;
            msg = `Your cart contains services from "${exitingProviderName}". Do you want to discard the selection and add services from "${providerName}" ?`
              isServiceAdded = false;
          }else if (cartItem.service && this.selectedPlan) {
            isServiceAdded = false;
          } else if (cartItem.service === null && cartItem.plan) {
            isPlanAdded = false;
          }
          
        }
        if (isServiceAdded && isPlanAdded) {
          this.addToCart();
        } else {
          Swal.fire({
            title: 'Replace cart item?',
            text: msg,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Save',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((res: any) => {
            if (res.isConfirmed) {
              this.deleteTotalCart();
            }
          });
        }
      }else{
        Swal.fire({
          text: 'Please fill mandatory fields',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      }

  }

  addToCart = () => {
    // debugger;
    // const tempList: any = [];
    // if (!this.selectedPlan && this.serviceDetails.plans?.length > 0) {
    //   console.error('No plan found for the selected recurring interval');
    //   return;
    // }

    const selectedRecurringInterval = this.recurringForm.value.selectedRecurringInterval;
    // this.selectedPlan = this.serviceDetails.plans.find((plan: any) => plan.plan_id === selectedRecurringInterval);

    const itemObj = {
      // value: true,
      quantity: this.checkQuantity(),
      service: this.selectedPlan && !this.selectBookAService ? null : this.serviceDetails.service_id,
      address: null,
      customer: JSON.parse(<string>this.storageService.getItem("user_info")).customer_id,
      plan: this.selectedPlan && !this.selectBookAService ? this.selectedPlan.plan_id : null,
      booking_time: this.recurringForm.value.preferredTime,
      booking_date: this.schedule_date,
      staff: this.recurringForm.value.staff || null
    };


    this.dataService.addToCart(itemObj).subscribe((data: any) => {
      if (data) {
        this.toastr.success('service added to cart.');
        this.serviceDetails.quantity = this.serviceDetails.quantity === undefined ? 1 : this.serviceDetails.quantity + 1;
        this.serviceDetails.isSelected = true;
        // this.bs.broadcast("SHOW_BOTTOM_CART", 'pop');
        this.dataService.cartItems().subscribe(() => {
          this.bs.broadcast('REFRESH_CART', 'count');
          this.refreshQuantiy();
          // let selectedPlan:any;
          // let selectedPlanFromStorage: any = this.storageService.getItem('selectedPlan');
          // selectedPlanFromStorage = JSON.parse(selectedPlanFromStorage);
 
          // if(selectedPlanFromStorage !== undefined  && selectedPlanFromStorage !== null && selectedPlanFromStorage !== ''){
          //    if(selectedPlanFromStorage.serviceId === this.serviceDetails.service_id){
          //     this.storageService.removeItem('selectedPlan');
          //    }
          //   }
          // if (this.isRecurringIntervalAvail && this.isRecurringIntervalAvail?.length > 0) {
          //    const selectedRecurringInterval = this.recurringForm.value.selectedRecurringInterval;
          //    selectedPlan = this.serviceDetails.plans.find((plan: any) => plan.plan_id === selectedRecurringInterval);
          // } else {
          //   selectedPlan = this.selectedPlan;
          // }
          //   this.storageService.setItem('selectedPlan', JSON.stringify({
          //     lead_staff : this.recurringForm.value.staff || null,
          //     schedule_start_datetime : this.schedule_date,
          //     preferred_time : this.recurringForm.value.preferredTime || null,
          //     recurring_service_plan: selectedPlan?.plan_id,
          //     serviceId: this.serviceDetails.service_id
          //   }));
            this.router.navigate(['/cart']);
        });
       



        // tempList.push({
        //   serviceName: this.serviceDetails.title,
        //   quantity: this.serviceDetails.quantity,
        //   price: this.serviceDetails.min_charge
        // });
        // this.pop = {
        //   selectedList: tempList,
        //   value: true
        // };
        this.cdr.detectChanges();
        // $('#test' + this.serviceDetails.service_id).removeClass('show');
        // $('#test' + this.serviceDetails.service_id).modal('hide');
        // this.showServicesPopup = false;
        // this.showBranchModalPopup = false;
        // this.showHomeModalPopup = false;
        // this.showAddAddress = false;
        // $('.modal-backdrop').remove();
        // $('#test' + this.serviceDetails.service_id).modal('hide');


      }
    });
  }


  deleteTotalCart = () => {
    this.dataService.deleteTotalCart().subscribe((data: any) => {
      if (data) {
        this.storageService.removeItem('cartItems');
        this.bs.broadcast('REFRESH_CART', 'count');
        this.addToCart();
      }
    })
  }

  homeAddressSelection = (address_id: any, address_type: any) => {
    let index = -1;
    this.addressList.forEach((p: any, i: any) => {
      if (+p?.address?.address_id == +address_id) {
        index = i;
      }
    });
    this.addressList.forEach((item: any) => {
      item.selected = false
    });
    this.addressList[index].selected = true;
    this.address_id = address_id;
    this.address_type = address_type;
  }

  branchAddressSelection = (address_id: any) => {
    let index = -1;
    this.branchList.forEach((p: any, i: any) => {
      if (+p?.branch?.address?.address_id == +address_id) {
        index = i;
      }
    });
    this.branchList.forEach((item: any) => {
      item.selected = false
    });
    this.branchList[index].selected = true;
    this.address_id = address_id;
    this.address_type = '';
  }


  getCustomerAddressList = () => {
    this.showBranchModalPopup = false;
    this.showServicesPopup = false;
    this.showHomeModalPopup = true;
    this.showAddAddress = false;
    const userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
    if (userDetails) {
      this.dataService.getCustomerAddressList(userDetails?.customer_id).subscribe((res: any) => {
        if (res) {
          this.addressList = res.address;
        }
      });
    }
  }

  getBranchAddressList = () => {
    this.showBranchModalPopup = true;
    this.showHomeModalPopup = false;
    this.showServicesPopup = true;
    this.showAddAddress = false;
    this.serviceDetails.selected = true;
    this.address_id = this.serviceDetails?.serviceprovider_branch?.address?.address_id;
    // this.address_id = this.branchDetails.address_id;
    // this.dataService.getBranchAddressList(this.serviceDetails?.service_provider?.slug_legal_name).subscribe((res: any) => {
    //   if (res) {
    //     this.branchList = res.results;
    //   }
    // });
  }



  backFn = () => {
    this.showAddAddress = false;
    if (this.serviceDetails.avail_at === 'home_only') {
      this.showHomeModalPopup = true;
      this.showServicesPopup = false;
      this.showBranchModalPopup = false;
    } else if (this.showBranchModalPopup) {
      this.showHomeModalPopup = false;
      this.showServicesPopup = true;
      this.showBranchModalPopup = false;
    } else if (this.showHomeModalPopup) {
      this.showHomeModalPopup = false;
      this.showBranchModalPopup = false;
      this.showServicesPopup = true;
    } else {
      this.showAddAddress = false;
      this.showHomeModalPopup = true;
      this.showServicesPopup = false;
      this.showBranchModalPopup = false;
    }
  }

  refreshQuantiy = () => {
    // added quantity count
    const tempList: any = [];
    let cartItems: any = this.storageService.getItem('cartItems');
    cartItems = cartItems !== null && cartItems !== undefined ? JSON.parse(cartItems).results : [];
    if (cartItems.length !== 0) {
      let count = 0;
      cartItems.forEach((data: any) => {
        if (data.service) {
          if (data?.service?.service_id === this.serviceDetails?.service_id) {
            count = count + data?.quantity;
            this.serviceDetails.quantities = count;
            this.serviceDetails.quantity = data?.quantity;
            tempList.push({
              serviceName: data?.service?.title,
              quantity: data?.quantity,
              price: data?.service?.min_charge,
            });
          }
        }

        if (this.serviceDetails.quantity) {
          this.pop = {
            selectedList: tempList,
            value: true,
            serviceName: this.serviceDetails?.title
          }
        }
      });
    } else {
      this.serviceDetails.quantity = '';
    }

    this.serviceDetails.isSelected = false;
    if (this.serviceDetails.quantity) {
      this.serviceDetails.isSelected = true;
    }
  }


  // constructor(  ) { }


  ngAfterViewInit() {

  }

  getAddress() {
    this.geoCoder.geocode({
      'location': {
        lat: this.lat,
        lng: this.lng
      }
    }, (results: { formatted_address: string; }[], status: string) => {
      if (status === 'OK') {
        if (results[0]) {
          this.userLocation = results[0].formatted_address;
          this.selectedAddress = this.parse_place(results[0]);
          this.updateLocationInSession();
        } else {
          Swal.fire({
            text: 'No results found',
            icon: 'warning',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
        }
      } else {
        // Swal.fire({
        //   text: 'Geocoder failed due to: ' + status,
        //   icon: 'error',
        //   confirmButtonText: 'Ok',
        //   allowOutsideClick: false,
        //   allowEscapeKey: false
        // });
      }
    });
  }

  updateLocationInSession() {
    sessionStorage.setItem('location', this.userLocation);
    sessionStorage.setItem('latitude', String(this.lat));
    sessionStorage.setItem('longitude', String(this.lng));
  }

  markerDragEnd(e: any) {
    this.lat = e.latLng.lat();
    this.lng = e.latLng.lng();
    this.zoom = 16;
    this.getAddress();
  }

  getStatus(add: any) {
    return add.selected;
  }

  saveAddress = () => {
    this.isSubmitted = false;
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['sign-in']);
      return;
    }
    if (this.addressLabel === "") {
      this.isSubmitted = true;
      return;
    }
    if (this.selectedAddress !== undefined && this.selectedAddress !== null) {

      const x = {
        customer_id: this.userDetails.customer_id,
        address: {
          address1: this.selectedAddress.address1,
          address2: this.selectedAddress.address2,
          post_code: this.selectedAddress.post_code,
          land_mark: this.selectedAddress.landmark,
          latitude: this.lat,
          longitude: this.lng,
          city: this.selectedAddress.city,
          state: this.selectedAddress.state,
          country: this.selectedAddress.country,
          address_type: this.addressLabel
        }
      }

      this.dataService.createCustomer(x).subscribe((res: any) => {
        if (res && res.error === undefined) {
          Swal.fire({
            text: res.status,
            icon: 'success',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((data: any) => {
            if (data.isConfirmed) {
              this.showAddAddress = false;
              this.showHomeModalPopup = true;
              this.showServicesPopup = false;
              this.showBranchModalPopup = false;
              this.getCustomerAddressList();
            }
          });
        } else if (res && res.error) {
          Swal.fire({
            text: res.error.Error,
            icon: 'error',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }
      });
    }
  }

  parse_place = (place: any) => {
    let location: any = [];
    for (var ac = 0; ac < place.address_components.length; ac++) {
      var component = place.address_components[ac];
      switch (component.types[0]) {
        case 'locality':
          location['city'] = component.long_name;
          this.cityName = component.long_name;
          break;
        case 'administrative_area_level_1':
          location['state'] = component.long_name;
          break;
        case 'country':
          location['country'] = component.long_name;
          break;
        case 'postal_code':
          location['post_code'] = component.long_name;
          break;
        case 'political':
          location['address1'] = component.long_name;
          break;
        case 'premise' || 'neighborhood':
          location['address2'] = component.long_name;
          break;
        case 'landmark':
          location['landmark'] = component.long_name;
          break;
      }
    };
    return location;
  }


  openLocationPopup = () => {
    // this.showAddAddress = true;
    this.showHomeModalPopup = false;
    this.addressLabel = '';
    const modalRef = this.modalService.open(AddressPopupComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    modalRef.componentInstance.addressObj = { fromState: 'new' };

    modalRef.result.then((result: any) => {
      if (result) {
        this.getCustomerAddressList();
      }
    });
  }

  submitRecurringForm() {
    // if (this.recurringForm.valid) {
    //   this.showAddRecurring = false;
    // }
    // if (this.recurringForm.valid) {
    //   Swal.fire('Success!', 'Recurring successfully added', 'success')
    //   this.showAddRecurring = false;
    // } else {
    //   Swal.fire('Error!', 'Please fill in all required fields!', 'error')
    // }
  }

  getStaffList = () => {
    console.log("Staff list:", this.getStaffList);
    const req = {
      serviceId: this.serviceDetails.service_id,
      branchId: this.serviceDetails.serviceprovider_branch.branch_id
    }
    let apiName :any;
    if(this.serviceDetails?.plans && this.serviceDetails.plans?.length === 0){
      apiName = this.dataService.getStaffDetails(req);
     }else{
      apiName =  this.dataService.getStaffList(req);
     }
    apiName.subscribe((res: any) => {
      if (res) {
        this.staffList = res.results;
        console.log("Staff List:", this.staffList)
      }
    });
  }

  schedule_date: any;
  getTimeSlots = () => {
    this.timeSlotsList = [];
    console.log("Time Slots:", this.timeSlotsList)
    const reqObj = {
      branchId: this.serviceDetails.serviceprovider_branch.branch_id,
      bookingDate: this.schedule_date,
      staffId: this.selectedStaff === undefined ? 0 : this.selectedStaff
    }
    this.dataService.getAvailableTimeSlots(reqObj).subscribe((res: any) => {
      if (res) {
        const timeSlotsList = res.available_slots;

        let momentTimeSlots: any = [];
        let today = moment();
        if (today.format('YYYY-MM-DD') === this.schedule_date) {
          for (let timeObj of timeSlotsList) {
            let time = moment(timeObj, "hh:mm a").format('hh:mm a');
            let time1 = moment(time, "hh:mm a").format('HH');
            if (this.checkTime(time1)) {
              momentTimeSlots.push(time);
            }
          }
          this.timeSlotsList = momentTimeSlots
        } else {
          this.timeSlotsList = res.available_slots
        }

      }
    })

  }

  checkTime = (a: any) => {
    const now = moment();
    const hourToCheck = +a;
    const dateToCheck = now.hour(hourToCheck).minute(30);
    return moment().isBefore(dateToCheck);
  }

 
  
  onBookingDateChange = () => {
    let schedule_date = this.recurringForm.get('startDate').value;
   
    let year = schedule_date.year;
    let month = schedule_date.month <= 9 ? '0' + schedule_date.month : schedule_date.month;
    let day = schedule_date.day <= 9 ? '0' + schedule_date.day : schedule_date.day;;
    let finalDate =  year + "-" +  month + "-" + day; // YYYY-MM-dd
    this.schedule_date = finalDate;
    if(this.serviceDetails?.plans && this.serviceDetails.plans?.length === 0){
     this.getTimeSlots();
     this.recurringForm.controls['preferredTime'].setValue(null);
     this.recurringForm.get('preferredTime').updateValueAndValidity();
     
    }
    
  }

  onChangeStaffSelection = () => {
    this.selectedStaff = this.recurringForm.get('staff').value;
    if(this.serviceDetails?.plans && this.serviceDetails.plans?.length === 0){
      this.getTimeSlots();
     }
  }

  getWeekSchedule = () => {
    const req = {
      branchId: this.serviceDetails.serviceprovider_branch.branch_id
    }
    this.dataService.getWeekSchedule(req).subscribe((res: any) => {
      if (res) {
        this.weekList = res.week_schedule;
        for(let i =0 ;i <this.holidayList?.length; i++){
          const disabledDate: any =new Date(this.holidayList[i].date) ;
          const day = disabledDate.getDay()
          const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
          const index = weekday[day];
          this.weekList[index].isOpen = false;
        }
        this.markDisabled = (date: NgbDate) => {
          let currentDay = this.calendar.getWeekday(date);
          switch (currentDay) {
            case 1: return !this.weekList.Monday.isOpen;
              break
            case 2: return !this.weekList.Tuesday.isOpen;
              break;
            case 3: return !this.weekList.Wednesday.isOpen;
              break;
            case 4: return !this.weekList.Thursday.isOpen;
              break;
            case 5: return !this.weekList.Thursday.isOpen;
              break;
            case 6: return !this.weekList.Saturday.isOpen;
              break;
            case 7: return !this.weekList.Sunday.isOpen;
              break;
            default: return []
          }
        };
      }
    });
  }

  bookService = () =>{
    if (!this.authService.isAuthenticated()) {
      this.storageService.setItem("redirectURL", JSON.stringify(this.router.url))
      this.router.navigate(['sign-in']);
      return;
    }
    if (this.serviceDetails.quote_only) {
      this.router.navigate([RouteConstants.REQUEST_QUOTE], {
        queryParams: {
          providerId: this.serviceDetails?.service_provider.service_provider_id,
          serviceId: this.serviceDetails.service_id,
          branchId: this.serviceDetails.serviceprovider_branch.branch_id
        }
      });
      const data = {
        providerName: this.serviceDetails.service_provider.legal_name,
        providerLogoUrl: this.serviceDetails.service_provider.logo_url,
        serviceName: this.serviceDetails.title,
        serviceLogoUrl: this.serviceDetails.image,
        availability: this.serviceDetails.avail_at,
        price: this.serviceDetails.min_charge,
        contact: this.serviceDetails.avail_at,
        branchAddress: this.serviceDetails.serviceprovider_branch?.address
      };
      this.storageService.setItem("quoteDetails", JSON.stringify(data));
    }
    
    this.showAddRecurring = true;
    this.selectBookAService = true;
    // this.selectBookAService = this.serviceDetails?.plans && this.serviceDetails?.plans?.length  ? true : false;
   }

   
   getHolidayList = () => {
    this.dataService.getHolidayList({ branchId: this.serviceDetails.serviceprovider_branch.branch_id }).subscribe((res: any) => {
      if (res && res.error === undefined) {
        this.holidayList = res.results;
        this.getWeekSchedule();
      }
    });
  }

  closePopup = () => {
    this.showAddRecurring = false;
  }

}
