<div class="parent-signup-wrapper ">
    <h1>What Subjects did you take at A-level ?</h1>
     <div class="tutor-signup container">
 
        
         <!-- <div class="card"> -->
             <div class="input-block">
                 <div class="label-text">Subject</div>
                 <ng-select
                 [items]="qualifications"
                 bindLabel="name"
                 [multiple]="false">
 
                 </ng-select>
             </div>
             
             <div class="input-block">
                <div class="label-text">Grade</div>
                <ng-select
                [items]="grade"
                bindLabel="name"
                [multiple]="false">

                </ng-select>
            </div>
      
                <div class="input-block mt-3">
                    <div class="label-text" *ngIf="showTextInput">Subject</div>
                    <ng-select
                    [items]="qualifications"
                     bindLabel="name"
                    [multiple]="false"
                    *ngIf="showTextInput">
    
                    </ng-select>
                </div>
                <div class="input-block">
                    <div class="label-text"  *ngIf="showTextInput">Grade</div>
                <ng-select
              
                [items]="grade"
                bindLabel="name"
                [multiple]="false"
                *ngIf="showTextInput">
           
                </ng-select>
                </div>
                
<!-- <ng-template #other_content>
 
</ng-template> -->
<div class="mt-3">
    <button class="btn-add" (click)="showTextInput = !showTextInput"type="button"  [routerLink]="['/tutor/subject/level']">Add another
   
    </button>
</div>
  
             <div class="row button-wrapper mt-2">
                <div class="col-md-6">
                    <button class="btn-back" type="button" [routerLink]="['/tutor/school/qualification']">back</button>
                </div>
         
       <div class="col-md-6">           
          <div class="signup-dialog">
                <p-button (click)="showBasicDialog()" icon="pi pi-external-link" label="Next"></p-button>
    <p-dialog header="Have you filled out all your grades" [(visible)]="displayBasic" [style]="{width: '28vw'}" [baseZIndex]="10000">
      
        <p class="dialog-p">It's crucial that you fill out this section accurately as it is used to determine your eligibility to tutor.</p>
        <p class="dialog-p">you won't be able to come back to this page again!</p>
        <button class="btn-next" type="button" >Submit My grades</button>
        <a class="edting-k"[routerLink]="['/tutor/study/subjects']">Keep editing</a>
        <!-- <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" (click)="displayBasic=false" label="Yes" styleClass="p-button-text"></p-button>
            <p-button icon="pi pi-times" (click)="displayBasic=false" label="No"></p-button>
        </ng-template> -->
    </p-dialog>
</div>


                </div>
    
    
 
 
                   
               
        
 </div>
     </div>
 
                      
     