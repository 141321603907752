import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from 'src/app/services/auth.service';
import { RouteConstants } from "../../model/route-constants";

@Component({
  selector: 'app-register-business',
  templateUrl: './register-business.component.html',
  styleUrls: ['./register-business.component.scss']
})
export class RegisterBusinessComponent implements OnInit {
  showAccount = false;
  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.showAccount = this.authService.isAuthenticated();
  }

  registerNow = () => {
    if (this.showAccount === false) {
      this.router.navigate([RouteConstants.REGISTER_YOUR_BUSINESS]);
    }
  }
}
