
<div class="parent-signup-wrapper">
    <h1>Sign up</h1>
    <div class="tutor-signup container">
        <!-- <div class="card"> -->




        <div class="login-container-page" *ngIf="!showSuccessPage">
            <div class="login-form-section">

                <ng-container *ngIf="true">


                    <form [formGroup]="signUpForm" (ngSubmit)="onSubmit($event)">
                        <div class="input-block">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="label-text">First Name<sup>*</sup></div>
                                    <input type="text" placeholder="Please enter First Name" class="input-form"
                                        formControlName="name">
                                    <div class="valid-check">
                                        <i class="bi bi-check-circle-fill success"
                                            *ngIf="nameControls.valid  && formSubmited"></i>
                                        <!-- <i class="bi bi-exclamation-circle-fill error"
                                  *ngIf="nameControls.invalid  && formSubmited"></i> -->
                                    </div>
                                    <span class="error-text-wrap" *ngIf="nameControls.invalid && formSubmited">First
                                        name is
                                        Required</span>
                                </div>

                                <div class="col-md-6">
                                    <div class="input-block">
                                        <div class="label-text">Last Name<sup>*</sup></div>
                                        <input type="text" placeholder="Please enter Last Name" class="input-form"
                                            formControlName="name">
                                        <div class="valid-check">
                                            <i class="bi bi-check-circle-fill success"
                                                *ngIf="nameControls.valid  && formSubmited"></i>
                                            <!-- <i class="bi bi-exclamation-circle-fill error"
                                  *ngIf="nameControls.invalid  && formSubmited"></i> -->
                                        </div>
                                        <span class="error-text" *ngIf="nameControls.invalid && formSubmited">Last Name
                                            is
                                            Required</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="input-wrap">
                            <div class="input-block">
                                <div class="label-text">Email<sup>*</sup></div>
                                <input type="email" placeholder="Please enter your your email" class="input-form"
                                    formControlName="email">
                                <div class="valid-check">

                                </div>
                                <span class="error-text" *ngIf="emailControls.invalid  && formSubmited">Email
                                    is Required
                                </span>
                            </div>
                            <div class="input-block">
                                <div class="label-text">Date of Birth</div>
                                <input type="date" class="input-form" id="" placeholder="">
                            </div>
                            <div class="input-block">
                                <div class="label-text">Confirm Password</div>
                                <input [type]="showPwdLabel" placeholder="Please enter your password" class="input-form"
                                    formControlName="pwd">
                                <div class="show-pswd">
                                    <i class="bi bi-eye pass" (click)="isVisible()" *ngIf="showPwdLabel === 'text'"></i>
                                    <i class="bi bi-eye-slash pass" (click)="isVisible()"
                                        *ngIf="showPwdLabel === 'password'"></i>
                                </div>

                                <span class="error-text" *ngIf="nameControls.invalid && formSubmited">Password is
                                    Required</span>
                            </div>

                        </div>

                        <span class="errorMsg" *ngIf="errMsg">{{errMsg}}</span>
                        <button class="btn-tutor" type="submit" [routerLink]="['/mytutor/login/page']">Signup</button>

                    </form>
                </ng-container>
            </div>
        </div>




    </div>
</div>