<div class="parent-signup-wrapper">
    <h1>Which of these Subjects did you study at GCSE ?</h1>
    <P>We take all your grades into account for your application,so please add so many that you have.</P>
    <div class="tutor-signup container">
        <!-- <div class="card"> -->
        <div class="input-block">


            <label class="g-wrap">My Grading system is:</label> 
<span class="radio-wrapper">
    <input type="radio" name="grade" [(ngModel)]="selectedGrade" value="a-g">
            <label for="male">A* G</label> &nbsp; &nbsp;
            <input type="radio" name="grade" [(ngModel)]="selectedGrade" value="1-9">&nbsp;
                <label for="female">9-1</label>
            </span>
                <div class="input-block">
                    <div class="label-text">Subject</div>
                    <ng-select
                    [items]="qualifications"
                    bindLabel="name"
                    [multiple]="false">
    
                    </ng-select>
                </div>
                <div *ngIf="selectedGrade==='a-g'">
                    <div class="label-text">Enter Grade (A-G):</div>
                    <ng-select
                    [items]="grade"
                    bindLabel="name"
                    [multiple]="false"
                  >
                    </ng-select>
                  </div>
                  <div *ngIf="selectedGrade==='1-9'">
                    <div class="label-text">Enter Grade (1-9):</div>
                    <ng-select
                    [items]="grade"
                    bindLabel="name"
                    [multiple]="false"
                   >
                    </ng-select>
                  </div>
          
                <div class="input-block mt-3">
                    <div class="label-text" *ngIf="showTextInput">Subject</div>
                    <ng-select
                    [items]="qualifications"
                    bindLabel="name"
                    *ngIf="showTextInput"
                    [multiple]="false">
    
                    </ng-select>
                </div>
          
                <div *ngIf="selectedGrade==='a-g'" >
                    <div class="label-text" *ngIf="showTextInput">Enter Grade (A-G):</div>
                    <ng-select
                    [items]="grade"
                    bindLabel="name"
                    [multiple]="false"
                    *ngIf="showTextInput">
                    </ng-select>
                  </div>
                  <div *ngIf="selectedGrade==='1-9'">
                    <div class="label-text"*ngIf="showTextInput">Enter Grade (1-9):</div>
                    <ng-select
                    [items]="grade"
                    bindLabel="name"
                    [multiple]="false"
                    *ngIf="showTextInput">
                    </ng-select>
                  </div>
               <div class=" mt-3">
                <button class="btn-add" (click)="addTextInput()" type="button">Add another
               
                </button>
               </div>
           
                <div class="row button-wrapper mt-3">
                    <div class="col-md-6">
                        <button class="btn-back" type="button" routerLink="['/tutor/subject/level']">Back</button>
                    </div>
    <div class="col-md-6">
   
            <div class="signup-dialog">
                <p-button (click)="showBasicDialog()" icon="pi pi-external-link" label="Next"></p-button>
    <p-dialog header="Have you filled out all your grades" [(visible)]="displayBasic" [style]="{width: '28vw'}" [baseZIndex]="10000">
      
        <p class="dialog-p">It's crucial that you fill out this section accurately as it is used to determine your eligibility to tutor.</p>
        <p class="dialog-p">you won't be able to come back to this page again!</p>
        <button class="btn-next" type="button"[routerLink]="['/tutor/additional/qualification']" >Submit My grades</button>
        <a class="edting-k"[routerLink]="['/tutor/study/subjects']">Keep editing</a>
       
    </p-dialog>
            </div>
                </div>









        </div>
    </div>
