import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RouteConstants } from 'src/app/model/route-constants';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressPopupComponent } from 'src/app/components/address-popup/address-popup.component';
import { AuthService } from 'src/app/services/auth.service';
import { BroadcastService } from "../../services/broadcast.service";
import { environment } from 'src/environments/environment';

declare var jQuery: any;
declare var $ : any;

@Component({
  selector: 'app-provider-details',
  templateUrl: './provider-details.component.html',
  styleUrls: ['./provider-details.component.scss']
})
export class ProviderDetailsComponent implements OnInit {
  pop: any = {
    value: false
  };
  selectedTab = 'both';

  providersDetails: any;
  categoriesList: any = [];
  serviceList: any = [];
  catId: string | undefined;
  branch_slug_title: string | undefined;
  providerId: any;
  searchKeyword = '';
  tempServicesList: any = [];
  defaultSortBy = 'Low-High';
  branchId: any;
  location: any;
  inputnumber = 0;
  @Input() serviceListObj: any;
  @Input() sObj: any;
  @Input() serviceProviderDetails: any;
  filteredServices$: Observable<any[]> = new Observable<any[]>();

  selectedCategory: any;
  showBranchModalPopup = false;
  showHomeModalPopup = false;
  showServicesPopup = false;
  showAddAddress = false;
  address_type: any;
  address_id: any;
  addressList: any = [];
  addressList1:any=[];
  addressList2:any=[];
  @Input() branchDetails: any;
  addressLabel: any = '';
  currencyCode = environment.currencyCode;

  // selectedTab = 'both';
  constructor(private bs: BroadcastService, public dataService: DataService, public activatedRoute: ActivatedRoute,  public authService: AuthService, private modalService: NgbModal,
    public router: Router, private storageService: StorageService, private cdr: ChangeDetectorRef) {
    this.bs.on("SHOW_BOTTOM_CART").subscribe(data => {
      if (data === 'pop') {
        this.pop = {
          value: false
        };
        this.dataService.cartItems().subscribe(() => {
          this.bs.broadcast('REFRESH_CART', 'count');
          this.refreshQuantiy()
        });
      }
    });
    
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.catId = params.title;
      this.providerId = params.providerId;
      this.branch_slug_title = params.branch_slug_title;
      this.branchId = params.bId;
    });
    this.GetServiceCategories();
    this.getProvidersDetails();
    this.getStaffDetails();
    // this.serviceListObj.isSelected = false;
    // if (this.serviceListObj.quantities) {
    //   this.serviceListObj.isSelected = true;
    // }
  }

  getProviderServiceList = () => {
    let sortBy = '';
    if (this.defaultSortBy === 'Low-High') {
      sortBy = 'lowtohigh';
    } else if (this.defaultSortBy === 'High-Low') {
      sortBy = 'hightolow';
    }
    let selectedTab ='';
    if(this.selectedTab  === 'center_only'){
      selectedTab ="center";
    }else  if(this.selectedTab  === 'home_only'){
      selectedTab ="home";
    }else {
      selectedTab ="both";
    }
    const reqObj = {
      branch_slug_title: this.branch_slug_title,
      providerId: this.providerId,
      query: this.searchKeyword,
      sort: sortBy,
      availability: selectedTab 
    }
    this.dataService.getProviderServicesList(reqObj).subscribe((data: any) => {
      if (data) {
        this.tempServicesList = data.results;
        this.refreshQuantiy();
        this.loadServicesForCategory(this.selectedCategory);
        console.log("service list:", this.tempServicesList);
      }
    });
  }

  GetServiceCategories = () => {
    this.dataService.getProviderCategoriesList({ branch_slug_title: this.branch_slug_title, providerId: this.providerId }).subscribe((data: any) => {
      if (data) {
        this.categoriesList = data.results;
        this.getProviderServiceList();
        console.log("cat list:", this.categoriesList);
        if (this.categoriesList.length > 0) {
          this.selectedCategory = this.categoriesList[0];
        }

      }
    });
  }

  getProvidersDetails = () => {
    const req = {
      providerId: this.providerId,
      branch_slug_title: this.branch_slug_title
    }
    this.dataService.getServiceCategoryDetails(req).subscribe((data: any) => {
      if (data) {
        this.providersDetails = data;
        console.log('Received data from getServiceCategoryDetails:', data);
      }
    })
  }

  checkQuantity = (emitObj: any) => {
    let val = 1;
    let cartItems: any = this.storageService.getItem('cartItems');
    cartItems = cartItems !== null && cartItems !== undefined ? JSON.parse(cartItems).results : [];
    for (var i = 0; i < cartItems.length; i++) {
      if (cartItems[i]?.address?.address_id === emitObj.address_id) {
        val = cartItems[i]?.quantity + 1
        return val;
      }
    }
    return val;
  }

  updateItem = (emitObj: any) => {
    const reqObj = {
      // service: emitObj.id,
      // address: emitObj.address_id,
      // address_type: emitObj.address_type,
      quantity: this.checkQuantity(emitObj),
      ...emitObj
    }
    this.dataService.addToCart(reqObj).subscribe((data: any) => {
      if (data) {
        emitObj.quantity = emitObj.quantity === undefined ? 1 : emitObj.quantity + 1;
        this.dataService.cartItems().subscribe(() => {
          this.bs.broadcast('REFRESH_CART', 'count');
          this.refreshQuantiy()
        });
      }
    })
  }
  selected(id: any) {
    // const itemIndex = this.category.findIndex((item: { id: any; }) => item.id== id )
    // // @ts-ignore
    // this.category[itemIndex]['isSelected'] =  !this.category[itemIndex]['isSelected'];

    // // @ts-ignore
    // const filtered = this.category.filter(item=> item['isSelected'] == true);
    // let dd: any[] = [];
    //  for(let card of this.backUpServices){
    //    for(let pp of filtered){
    //      // @ts-ignore
    //      if(pp.id == card.service_id){
    //        dd.push(card);
    //      }
    //    }
    //  }
    //  // @ts-ignore
    // this.services = dd.length>0 ? dd : [...this.backUpServices]

  }

  gotoReviewPage = (fromState?: any) => {
    jQuery('body, html').animate({ scrollTop: 0 }, 100)
    this.router.navigate([RouteConstants.RATINGS_AND_REVIEWS], {
      queryParams: {
        title: this.catId,
        providerId: this.providerId,
        branch_slug_title: this.branch_slug_title,
        wrt: "false",
        branchId: this.providersDetails.branch_id
      }
    })
  }

  refreshQuantiy = (fromState?: any) => {
    let list: any = [];

    // structure the categories related services
    for (let i = 0; i < this.categoriesList.length; i++) {
      list.push({
        catName: this.categoriesList[i].title,
        catDesc: this.categoriesList[i].description,
      });
      let servicesList: any = [];
      this.tempServicesList.forEach((data: any) => {
        if (data?.service_category === this.categoriesList[i]?.service_category_id) {
          data.serviceprovider_branch = {}
          data.serviceprovider_branch.address = this.providersDetails?.address;
          servicesList.push(data);
          list[list.length - 1].results = servicesList;
        }
      });
    }
    const tempList: any = [];
    let serviceName: any;
    // added quantity count
    let cartItems: any = this.storageService.getItem('cartItems');
    cartItems = cartItems !== null && cartItems !== undefined ? JSON.parse(cartItems).results : [];
    for (var i = 0; i < this.tempServicesList.length; i++) {
      if (cartItems.length !== 0) {
        let count = 0;
        serviceName = cartItems[0].service.title;
        cartItems.forEach((data: any) => {
          if (data?.service?.service_id === this.tempServicesList[i]?.service_id) {
            count = count + data?.quantity;
            this.tempServicesList[i].quantities = count;
            this.tempServicesList[i].quantity = data?.quantity;
            tempList.push({
              serviceName: data?.service?.title,
              quantity: data?.quantity,
              price: data?.service?.min_charge,
            });
          }
        });
        this.cdr.detectChanges();
      } else {
        this.tempServicesList[i].quantity = '';
      }
    }
    if (tempList.length !== 0)
      this.pop = {
        selectedList: tempList,
        value: true,
        serviceName: serviceName
      }
    this.serviceList = list;
    console.log('serviceList', this.serviceList)
  }

  staffDetails: any;
  getStaffDetails = () => {
    this.dataService.getStaffDetails({branchId: this.branchId}).subscribe((data: any) => {
      if (data) {
        this.staffDetails = data.results;
        this.staffDetails?.forEach((p: any, i: any) => {
          let serviceName: any = [];
          if (p?.services) {
            for (let obj of p.services) {
              serviceName.push(obj.title)
            }
            p.serviceNames = serviceName;
          }
        });
      }
    });
  }



  ChangeSortByFn = (e: any) => {
    this.defaultSortBy = e;
    this.tempServicesList = [];
    this.getProviderServiceList();
  }

  onSearch = () => {
    this.tempServicesList = [];
    this.getProviderServiceList();
  }

  onSelectTabs = (e: any) => {
    this.selectedTab = e;
    this.GetServiceCategories();
    this.refreshQuantiy('fromTab');
  }

  // decrementQty($event: any) {
  //   $event.preventDefault();
  // }
  // incrementQty($event: any) {
  //   $event.preventDefault();
  // }

  serviceQuantity() {

  }

  incrementQty() {
    this.inputnumber = this.inputnumber + 1;
    console.log("Increment:", this.inputnumber);
  }
  decrementQty() {
    if (this.inputnumber != 0) {
      this.inputnumber = this.inputnumber - 1;
      console.log("Decrement:", this.inputnumber)
    }

  }

  gotoServiceDetailsPage = () => {
    jQuery('body, html').animate({ scrollTop: 0 }, 100)
    this.router.navigate([RouteConstants.SERVICE_DETAILS], {
      queryParams: {
        title: this.sObj.slug_title,
        branch_name: this.sObj.service_category
      }
    })
  }

  loadServicesForCategory(catObj: any) {
    console.log("selected cat:", catObj);
    this.selectedCategory = catObj;
    this.filteredServices$ = of([]);
    this.filteredServices$ = of(this.tempServicesList.filter((item: any) => item.service_category == catObj.service_category_id));
    console.log("filtered services:" ,this.tempServicesList.filter((item: any)=>item.service_category==catObj.service_category_id));
  }

  getCustomerAddressList = (fromState?: any) => {
    this.showBranchModalPopup = false;
    this.showHomeModalPopup = true;
    this.showServicesPopup = false;
    this.showAddAddress = false;
    const userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
    if (userDetails) {
      this.dataService.getCustomerAddressList(userDetails?.customer_id).subscribe((res: any) => {
        if (res) {
          this.addressList = res.address;
          if(this.addressList?.length === 0 && fromState === 'no_address'){
            this.openLocationPopup();
          }
          this.addressList1 = res.address.slice(0,4);
          this.addressList2 = res.address.slice(4,res.address.length);
        }
      });
    }
  }

  getBranchAddressList = () => {
    this.showBranchModalPopup = true;
    this.showHomeModalPopup = false;
    this.showAddAddress = false;
    this.showServicesPopup = true;
    this.branchDetails.selected = true;
    this.address_id = this.branchDetails.address_id;
    // this.dataService.getBranchAddressList(this.serviceProviderDetails?.provider_slug_itle).subscribe((res: any) => {
    //   if (res) {
    //     this.branchList = res.results;
    //   }
    // });
  }

  openPopup = (sObj: any) => {
    console.log("service object:", sObj);
    this.showAddAddress = false;
    if (!this.authService.isAuthenticated()) {
      this.storageService.setItem("redirectURL", JSON.stringify(this.router.url));
      this.router.navigate(['sign-in']);
      return;
    }
    if (sObj.quote_only) {
      this.router.navigate([RouteConstants.REQUEST_QUOTE], {
        queryParams: {
          providerId: this.serviceProviderDetails?.providerId,
          serviceId: this.sObj.service_id
        }
      });
      const data = {
        providerName: this.serviceProviderDetails.providerName,
        providerLogoUrl: this.serviceProviderDetails.logo_url,
        serviceName: this.sObj.title,
        serviceLogoUrl: this.sObj.image,
        availability: this.sObj.avail_at,
        price: this.sObj.min_charge,
        contact: this.sObj.avail_at,
        branchAddress: this.serviceProviderDetails.address
      };
      this.storageService.setItem("quoteDetails", JSON.stringify(data));
    } else if (sObj.avail_at === 'home_only') {
      this.getCustomerAddressList();
    } else if (sObj.avail_at === 'center_only') {
      this.getBranchAddressList();  
    } else {
      this.showBranchModalPopup = false;
      this.showHomeModalPopup = false;
      this.showServicesPopup = true;
    }
  }

  backFn = () => {
    this.showAddAddress = false;
    if (this.serviceListObj.avail_at === 'home_only') {
      this.showHomeModalPopup = true;
      this.showServicesPopup = false;
      this.showBranchModalPopup = false;
    } else if (this.showBranchModalPopup) {
      this.showHomeModalPopup = false;
      this.showServicesPopup = true;
      this.showBranchModalPopup = false;
    } else if (this.showHomeModalPopup) {
      this.showHomeModalPopup = false;
      this.showBranchModalPopup = false;
      this.showServicesPopup = true;
    } else {
      this.showAddAddress = false;
      this.showHomeModalPopup = true;
      this.showServicesPopup = false;
      this.showBranchModalPopup = false;
    }
  }

  openLocationPopup = () =>{
    // this.showAddAddress = true;
    this.showHomeModalPopup=false;
    this.addressLabel = '';
    const modalRef = this.modalService.open(AddressPopupComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    modalRef.componentInstance.addressObj = {fromState: 'new'};

    modalRef.result.then((result: any) => {
      if (result) {
        this.getCustomerAddressList();
        $('.modal-backdrop').remove();
      }
    });
  }

}
