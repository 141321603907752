<app-tutor-header></app-tutor-header>
<div class="container-fluid student-profile-wrapper">
    <h1 class="page-title">Messages</h1>

    <div class="messages-main-wrapper mt-5">

        <div class="tutors-list">
            <div class="t-list">
                <div class="t-profile">
                    <div class="text-center">
                        <img src="assets/images/tutor-profile.jpg" alt="">
                    </div>
                </div>
                <div class="t-name-desig">
                    <p class="tutor-name">Robert Grabiner</p>
                    <p class="tutor-desig">We want you to find the best tut</p>
                </div>
                <div class="last-chat">
                    <p class="last-chat-time">21:30</p>
                </div>
            </div>
            <div class="t-list">
                <div class="t-profile">
                    <div class="text-center">
                        <img src="assets/images/tutor-ptwo.jpg" alt="">
                    </div>
                </div>
                <div class="t-name-desig">
                    <p class="tutor-name">Thanusan Ram</p>
                    <p class="tutor-desig">We want you to find the best tut</p>
                </div>
                <div class="last-chat">
                    <p class="last-chat-time">21:30</p>
                </div>
            </div>
            <div class="t-list">
                <div class="t-profile">
                    <div class="text-center">
                        <img src="assets/images/tutor-pone.jpg" alt="">
                    </div>
                </div>
                <div class="t-name-desig">
                    <p class="tutor-name">Hasnain </p>
                    <p class="tutor-desig">We want you to find the best tut</p>
                </div>
                <div class="last-chat">
                    <p class="last-chat-time">05:30</p>
                </div>
            </div>
            <div class="t-list">
                <div class="t-profile">
                    <div class="text-center">
                        <img src="assets/images/tutor-p.jpg" alt="">
                    </div>
                </div>
                <div class="t-name-desig">
                    <p class="tutor-name">Vidhi Mohan</p>
                    <p class="tutor-desig">We want you to find the best tut</p>
                </div>
                <div class="last-chat">
                    <p class="last-chat-time">09:30</p>
                </div>
            </div>
            <div class="t-list">
                <div class="t-profile">
                    <div class="text-center">
                        <img src="assets/images/tutor-pone.jpg" alt="">
                    </div>
                </div>
                <div class="t-name-desig">
                    <p class="tutor-name">Rohit Kumar</p>
                    <p class="tutor-desig">We want you to find the best tut</p>
                </div>
                <div class="last-chat">
                    <p class="last-chat-time">20:30</p>
                </div>
            </div>
            <div class="t-list">
                <div class="t-profile">
                    <div class="text-center">
                        <img src="assets/images/tutor-ptwo.jpg" alt="">
                    </div>
                </div>
                <div class="t-name-desig">
                    <p class="tutor-name">Thanusan Ram</p>
                    <p class="tutor-desig">We want you to find the best tut</p>
                </div>
                <div class="last-chat">
                    <p class="last-chat-time">21:30</p>
                </div>
            </div>
            <div class="t-list">
                <div class="t-profile">
                    <div class="text-center">
                        <img src="assets/images/tutor-pone.jpg" alt="">
                    </div>
                </div>
                <div class="t-name-desig">
                    <p class="tutor-name">Hasnain </p>
                    <p class="tutor-desig">We want you to find the best tut</p>
                </div>
                <div class="last-chat">
                    <p class="last-chat-time">05:30</p>
                </div>
            </div>
        </div>
        <div class="tutor-conversation">
            <div class="tutor-name-top text-center">
                Robert Grabiner
            </div>

            <div class="chat-history-wrapper">
                <div class="chat-history-right">
                    <div class="dummy-block"></div>
                    <div class="student-message">
                        <p class="student-msg">Hi sir, I'm not sure about the answer to question two above.</p>
                    </div>
                </div>
                <div class="chat-history-left">
                    <div class="inivigilator-message">
                        <p class="invigilator-msg">Hi sir, I'm not sure about the answer to question two above.</p>
                    </div>
                    <div class="dummy-block"></div>
                </div>
                <div class="chat-history-right">
                    <div class="dummy-block"></div>
                    <div class="student-message">
                        <p class="student-msg">Hi sir, I'm not sure about the answer to question two above.</p>
                    </div>
                </div>
                <div class="chat-history-left">
                    <div class="inivigilator-message">
                        <p class="invigilator-msg">Hi sir, I'm not sure about the answer to question two above.</p>
                    </div>
                    <div class="dummy-block"></div>
                </div>
                <div class="chat-history-right">
                    <div class="dummy-block"></div>
                    <div class="student-message">
                        <p class="student-msg">Hi sir, I'm not sure about the answer to question two above.</p>
                    </div>
                </div>
                <div class="chat-history-left">
                    <div class="inivigilator-message">
                        <p class="invigilator-msg">Hi sir, I'm not sure about the answer to question two above.</p>
                    </div>
                    <div class="dummy-block"></div>
                </div>
            </div>

            <div class="chat-input-wrapper">
                <div class="f-upload-section">
                    <div class="text-center">
                        <img src="assets/icons/file-add.svg" alt="">
                    </div>
                </div>
                <div class="m-input-wrapper">
                    <input type="text" placeholder="type your message">
                </div>
                <div class="send-message-wrapper text-right">
                    <button>send</button>
                </div>
            </div>

        </div>
    </div>
    
</div>