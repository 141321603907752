import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutor-qualification',
  templateUrl: './tutor-qualification.component.html',
  styleUrls: ['./tutor-qualification.component.scss']
})
export class TutorQualificationComponent implements OnInit {
  date10: Date | undefined;
  date11:Date | undefined;
  date12:Date |undefined;
  date13:Date |undefined;
  showTextInput: boolean = false;
  displayBasic: boolean | undefined;
  constructor() { }

  ngOnInit(): void {
  }
  qualifications : any = [
    {name: 'Masters'},
    {name: 'Bachelors'},
    {name:'Phd'},
    {name:'Gradute'}
  ]
  grade:any=[
    {name:'a+'},
    {name:'B+'}
  ]

}
