import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tutor-bookings',
  templateUrl: './tutor-bookings.component.html',
  styleUrls: ['./tutor-bookings.component.scss']
})
export class TutorBookingsComponent implements OnInit {

  defaultUpcomingStatus = 'Show All';
  defaultPreviousStatus = 'Completed';
  openEditBookingPanel: any;
  groupopenEditBookingPanel: any;
  openCancelBookingPanel: any;
  showTextFlag = true; // Set the default button to "Show Text"
  showInputFlag = false;
  upcomingStatusList = [
    { name: 'Show All' },
    { name: 'Awaiting Confirmation' },
    { name: 'Awaiting Payment' },
    { name: 'Confirmed' }
  ]

  previousStatusList = [
    { name: 'Show All' },
    { name: 'Completed' },
    { name: 'Refunded' },
    { name: 'Cancelled' },
    { name: 'Declined' },
    { name: 'Expired' }
  ]
  
  bookingsList = [
    {studentname: 'Troy-Francis B.', subject: 'Extended Project Qualification', date: 'feb 15 to march 20',day:'Every Tue',  status: 'Accept time', statustext: 'text-success', statusicon: 'assets/icons/contact/success.svg',time:'7.00AM',bookingtype:'Free Session',meetingicon:'assets/icons/contact/t-user.svg'},
    {studentname: 'Anjali Sharma M..', subject: 'English Literature',date: 'feb 15 to march 20',day:'Every Tue', status: 'Payment Pending', statustext: 'text-warning', statusicon: 'assets/icons/contact/clock.svg',time:'7.00AM', bookingtype:'Group Session',meetingicon:'assets/icons/contact/t-users.svg'},
    {tutorname: 'Sarwanch S.', subject: 'Personal Statements', date: 'feb 15 to march 20',day:'Every Tue', status: 'Payment scheduled',  statustext: 'text-info', statusicon: 'assets/icons/contact/success.svg',time:'7.00AM',bookingtype:'Group Session',meetingicon:'assets/icons/contact/t-users.svg'},
    // {studentname: 'Zaynab A..', subject: 'Religious Studies', date: 'feb 15 to march 20',day:'Every Tue',status: 'Accept time',  statustext: 'text-info', statusicon: 'assets/icons/contact/waiting.svg',time:'7.00AM'},
    // {studentname: 'Troy-Francis B.', subject: 'Extended Project Qualification', date: 'Thu 9 Feb, 14:30', status: 'Payment scheduled',  statustext: 'text-success', statusicon: 'assets/icons/contact/success.svg',time:'7.00AM'}
  ]


  constructor() {
    this.showTextFlag = true;
    this.showInputFlag = false;
  }

  showText() {
    this.showTextFlag = true;
    this.showInputFlag = false;
  }

  showInput() {
    this.showTextFlag = false;
    this.showInputFlag = true;
  }

   

  ngOnInit(): void {
  }


  deleteBookingLesson() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this lesson",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your lesson has been deleted.',
          'success'
        )
      }
    })
  }

  accepetLessonBooking() {
    Swal.fire(
      'Success!',
      'Your Booking Confirmed',
      'success'
    )
  }

}

