<app-tutor-header></app-tutor-header>

<div class="container edit-wrap">
    <div class="row">
        <div class="col-md-6">
            <h3>Personal Details</h3>

            <div><label for="">First Name</label>
                <input type="text" class="form-control">
            </div>


            <div>
                <label for="">Last Name</label>
                <input type="text" class="form-control">
            </div>


            <div>
                <label for="">Email</label>
                <input type="text" class="form-control">
            </div>
            <div>
                <label for="#">Phone Number</label>
        <input type="Phone Number" class="form-control">
            </div>
            <div>
                <label for="">Date Of Birth</label>
                <input type="date" class="form-control">
            </div>

            <form #form="ngForm" class="d-none">
                <label for="age">Age</label>
                <div class="row">
                    <div class="col-md-4">
                        <input type="radio" id="above18" name="age" value="above18" required checked>
                        <label for="above18" class="label-text">Above 18</label>
                    </div>
                    <div class="col-md-8"> <input type="radio" id="below18" name="age" value="below18" required>
                        <label for="below18" class="label-text">Below 18</label>
                    </div>

                </div>

            </form>

            <div>
                <label for="#">Gender</label>
                <ng-select [items]="gender" bindLabel="name" [(ngModel)]="selectedGender"
                    [multiple]="false"></ng-select>
            </div>

        </div>

        <div class="col-md-6">
            <h3>Education Details</h3>
            <!-- <div>
                <label for="#">Exam Board</label>
                <ng-select [items]="examboard" bindLabel="name" [(ngModel)]="selectedBoard"
                    [multiple]="false"></ng-select>
            </div>
            <div>
              <label for="#">Education Level</label>
                    <ng-select [items]="['Primary', 'Secondary', 'Graduation']" [(ngModel)]="selectedEducationLevel"></ng-select>
                    
                    <label for="#">Please Select Subjects</label>
                    <ng-select [items]="options" bindLabel="name" [(ngModel)]="selectedOption" [multiple]="true"></ng-select>
                    
                    
                    
            </div> -->
<div>
    <label for="">Education<span class="level-wrap">(Level 1)</span></label>
    <input type="text" class="form-control">
</div>
<div>
    <label for="">Education<span class="level-wrap">(Level 2)</span></label>
    <input type="text" class="form-control">
</div>
<div>
    <label for="">Education<span class="level-wrap">(Level 3)</span></label>
    <input type="text" class="form-control">
</div>
<div>
    <label for="">Subjects</label>
    <tag-input [ngModel]="['hardocoded-item']" [placeholder]="placeholderText">
        <tag-input-dropdown [autocompleteItems]="items" [showDropdownIfEmpty]="true" [dynamicUpdate]="false">
        </tag-input-dropdown>
    </tag-input>
</div>
<!-- <div class="search-container">
    <input type="text" placeholder="Search...">
    <button type="submit"><i class="fa fa-search"></i></button>
  </div> -->

   



        </div>
    </div>
</div>