
<div class="container review-card-component p-0">
  <div class="inner-data-reviews">
    <div class="img-block">
      <img src="assets/icons/reviews/avatar-profile.png">
    </div>
    <div class="content-block">
      <div class="rating-data-with-name">
        <div class="rating-icons">
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill disabled-rate"></i>
        </div>
        <div class="u-name">Rakesh Kumar</div>
        <div class="time">- 9 months</div>
      </div>
      <div class="review-desc">Service was good, overall liked the hygiene followed by Ajay, one of the hair stylist, he has got 7 years of experience and he is trained well, and also worked for celebs. I would recommend this service to my family and friends. 👍🏼</div>
    </div>
  </div>
</div>
