<div class="service-card-data" *ngIf="providerListObj">
    <div class="top-sec">
        <div class="img-blcok">
            <img [src]="providerListObj.provider_logo">
            <!-- <span>JK</span> -->
        </div>
        <div class="content-block">
            <div class="service-name">{{providerListObj.provider_legal_name}}</div>
            <div class="sb-dropdonws" #myDrop ngbDropdown placement="bottom-left">
                <button class="dropdown-input" ngbDropdownToggle (click)="changeDropdownValue()">
                    {{!selectedBranchName ? providerListObj?.service_branch_name : selectedBranchName}}<i class="bi bi-caret-down-fill ml-2"></i></button>
                <ul class="dropdown-menu" ngbDropdownMenu>
                    <!-- {{providerListObj?.register_address?.address1}} -->
                    <li ngbDropdownItem *ngFor="let item of branchList">
                        <a class="dropdown_item"  (click)="changeDropdownValue(item?.service_branch_name, item?.slug_service_branch_name, item?.branch_id)">{{item?.service_branch_name}}  <div class="kminfo"><img src="assets/icons/map-location.svg">
                            {{item?.distance}} miles</div></a>
                    </li>
                </ul>
            </div>
            <div class="rating-sec">
                <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  1 <= providerListObj?.rating}"></i>
                <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  2 <= providerListObj?.rating}"></i>
                <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  3 <= providerListObj?.rating}"></i>
                <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  4 <= providerListObj?.rating}"></i>
                <i class="bi bi-star-fill disable-rating" [ngClass]="{'disable-rating' :  5 <= providerListObj?.rating}"></i>
                <span>{{providerListObj?.rating}} ({{providerListObj.review_count}} Reviews)</span>
            </div>
            <!-- <div class="km-info"><img src="assets/icons/map-location.svg"> {{providerListObj?.distance}} km</div> -->
            <div class="book-now-block">
                <button class="sb-primary-btn" (click)="gotoProviderDetailsPage()">Book Now</button>
                <!-- <div class="info-tag">10% OFF ON WOMEN HAIRCUT</div> -->
                <!-- <div class="info-tag"><img src="assets/icons/tag.svg" alt="Price-tag">&nbsp; {{providerListObj.discount_percentage}}% OFF</div> -->
            </div>
        </div>
    </div>
    <div class="bottom-sec">
        <div class="left-sec">
            <div class="price-tag">Starting from : {{providerListObj.price_starting_from | currency : currencyCode}}</div>
            <!-- <div class="tab-name-click">Home</div>
            <div class="tab-name-click">Location</div> -->
        </div>
        <div class="right-sec">
            <div class="explore-more"  (click)="gotoProviderDetailsPage()">
                <span>Our Services</span>
                <i class="bi bi-arrow-right-short"></i>
            </div>
        </div>
    </div>
    <div class="book-now-block-show-mobile">
        <button class="sb-primary-btn" (click)="gotoProviderDetailsPage()">Book Now</button>
        <div class="info-tag">{{providerListObj.discount_percentage}}% OFF</div>
    </div>
</div>