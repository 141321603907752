import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-school-qualification',
  templateUrl: './school-qualification.component.html',
  styleUrls: ['./school-qualification.component.scss']
})
export class SchoolQualificationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  qualifications : any = [
    {name: 'A-level'},
    {name: 'International Baccalaurate(IB)'},
    {name:'Cambridge Pre U'},
    {name:'Hungairan Baccalaureate'}
  ]
}
