import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SocialLoginModule, SocialAuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';

import { GoogleLoginProvider } from 'angularx-social-login';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgOtpInputModule } from "ng-otp-input";
import { AgmCoreModule } from '@agm/core';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {CalendarModule} from 'primeng/calendar';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { HeaderComponent } from './shared/header/header.component';
import { RunningOffersComponent } from './components/running-offers/running-offers.component';
import { PopularCategoriesComponent } from './components/popular-categories/popular-categories.component';
import { CustomEventEmitter } from "./services/customeventemitter.service";
import { LimitedOffersComponent } from './components/limited-offers/limited-offers.component';
import { TopBookingsComponent } from './components/top-bookings/top-bookings.component';
import { StayHomeComponent } from './components/stay-home/stay-home.component';
import { RegisterBusinessComponent } from './components/register-business/register-business.component';
import { WhatCustomersSayComponent } from './components/what-customers-say/what-customers-say.component';
import { RatingsAndReviewsComponent } from './pages/ratings-and-reviews/ratings-and-reviews.component';
import { FooterComponent } from './shared/footer/footer.component';
import { RegisterYourBusinessComponent } from './pages/register-your-business/register-your-business.component';
import { ProviderDetailsComponent } from './pages/provider-details/provider-details.component';
import { ProviderServiceListCardComponent } from './shared/providers-service-list-card/providers-service-list-card.component';
import { ProvidersListComponent } from './pages/providers-list/providers-list.component';
import { ReviewCardComponent } from './shared/review-card/review-card.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { CategoryCardComponent } from './shared/category-card/category-card.component';
import { ProvidersListCardComponent } from './shared/providers-list-card/providers-list-card.component';
import { TopServicesComponent } from './pages/top-services/top-services.component';
import { AboutServiceComponent } from './shared/about-service/about-service.component';
import { ServiceRatingsComponent } from './pages/service-ratings/service-ratings.component';
import { RegisterFreeTrailComponent } from './pages/register-free-trail/register-free-trail.component';
import { MyBookingsComponent } from './pages/my-bookings/my-bookings.component';
import { CartComponent } from './pages/cart/cart.component';
import { CancelBookingComponent } from './pages/cancel-booking/cancel-booking.component';
import { AvailabilityComponent } from './pages/availability/availability.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { CancelledComponent } from './pages/cancelled/cancelled.component';
import { CartPopComponent } from './shared/cart-pop/cart-pop.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { environment } from 'src/environments/environment';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { ServiceDetailsComponent } from './pages/service-details/service-details.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { WishlistComponent } from './pages/wishlist/wishlist.component';
import { WalletComponent } from './pages/wallet/wallet.component';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { RequestQuoteComponent } from './pages/request-quote/request-quote.component';
import { AboutusComponent } from './pages/footer/aboutus/aboutus.component';
import { CareersComponent } from './pages/footer/careers/careers.component';
import { ContactUsComponent } from './pages/footer/contact-us/contact-us.component';
import { TermsAndConditionsComponent } from './pages/footer/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './pages/footer/privacy-policy/privacy-policy.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NgbDateCustomParserFormatter } from './services/data-formatter.service';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { DialogModule } from 'primeng/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { PaymentFailComponent } from './pages/payment-fail/payment-fail.component';
import { AddressPopupComponent } from './components/address-popup/address-popup.component';
import { MyTransactionsComponent } from './pages/my-transactions/my-transactions.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { PasswordModule } from "primeng/password";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { RatingModule } from 'primeng/rating';
import { PlansComponent } from './components/plans/plans.component';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

import { SportsBasedThemComponent } from './components/sports-based-them/sports-based-them.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { BeautyBasedThemeComponent } from './components/beauty-based-theme/beauty-based-theme.component';
import { BookingThemeComponent } from './components/booking-theme/booking-theme.component';
import { OnlineTutorComponent } from './components/online-tutor/online-tutor.component';
import { ViewTutorsComponent } from './components/view-tutors/view-tutors.component';
import { TutorProfileComponent } from './components/tutor-profile/tutor-profile.component';
import { TutorSignupComponent } from './components/tutor-signup/tutor-signup.component';
import { StudentProfileComponent } from './components/student-profile/student-profile.component';
import { StudentMessagesComponent } from './components/student-messages/student-messages.component';
import { TutorLoginComponent } from './components/tutor-login/tutor-login.component';
import { TutorLoginPageComponent } from './components/tutor-login-page/tutor-login-page.component';
import { StudentBookingsComponent } from './components/student-bookings/student-bookings.component';
import { BookLessonsComponent } from './components/book-lessons/book-lessons.component';
import {StepsModule} from 'primeng/steps';
import {ToggleButtonModule} from 'primeng/togglebutton';
import { MytutorSignupComponent } from './components/mytutor-signup/mytutor-signup.component';
import { ParentsSignupComponent } from './components/parents-signup/parents-signup.component';
import { StudentSignupComponent } from './components/student-signup/student-signup.component';
import { TutorSignupFormComponent } from './components/tutor-signup-form/tutor-signup-form.component';
import { TutorQualificationComponent } from './components/tutor-qualification/tutor-qualification.component';
import { SchoolQualificationComponent } from './components/school-qualification/school-qualification.component';
import { TutorSubjectComponent } from './components/tutor-subject/tutor-subject.component';
import { TutorAddQualificationComponent } from './components/tutor-add-qualification/tutor-add-qualification.component';
import { TutorSubjectLevelComponent } from './components/tutor-subject-level/tutor-subject-level.component';
import { TutorGraducationCertificateComponent } from './components/tutor-graducation-certificate/tutor-graducation-certificate.component';
import { MyTutorLoginPageComponent } from './components/my-tutor-login-page/my-tutor-login-page.component';
import { MyTutorPersonalDetailsComponent } from './components/my-tutor-personal-details/my-tutor-personal-details.component';
import { TutorEnoughDetailsComponent } from './components/tutor-enough-details/tutor-enough-details.component';
import { RecivedApplicationComponent } from './components/recived-application/recived-application.component';
import { TutorApplicationProcessComponent } from './components/tutor-application-process/tutor-application-process.component';
import { TutorAppointmentComponent } from './components/tutor-appointment/tutor-appointment.component';
import { ParentProfileComponent } from './components/parent-profile/parent-profile.component';
import { StudentsListComponent } from './components/students-list/students-list.component';
import { ContactTutorComponent } from './components/contact-tutor/contact-tutor.component';
import { TutorHeaderComponent } from './components/tutor-header/tutor-header.component';
import { StudentHomeComponent } from './components/student-home/student-home.component';
import { ParentHomeComponent } from './components/parent-home/parent-home.component';
import { TutorBookingsComponent } from './components/tutor-bookings/tutor-bookings.component';

// import {MultiSelectModule} from 'primeng/multiselect';

import { StudentCheckoutComponent } from './components/student-checkout/student-checkout.component';
import { TutorAddSubjectComponent } from './components/tutor-add-subject/tutor-add-subject.component';

import { Editor, EditorModule } from 'primeng/editor';
import { TutorDashboardComponent } from './components/tutor-dashboard/tutor-dashboard.component';
import {ChartModule} from 'primeng/chart';
import { NgChartsModule } from 'ng2-charts';
import { TutorFormSetpsComponent } from './components/tutor-form-setps/tutor-form-setps.component';
import { PersonalDetailsComponent } from './components/personal-details/personal-details.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { TagInputModule } from 'ngx-chips';
import { StudentPersonalDetailsComponent } from './components/student-personal-details/student-personal-details.component';
import { StudentSignupSubmitComponent } from './components/student-signup-submit/student-signup-submit.component';
import { StudentChangePasswordComponent } from './components/student-change-password/student-change-password.component';
import { ChooseSubjectComponent } from './components/choose-subject/choose-subject.component';
import { ChooseLevelComponent } from './components/choose-level/choose-level.component';
import { StudentAgeComponent } from './components/student-age/student-age.component';
import { PrimaryStudentSignupComponent } from './components/primary-student-signup/primary-student-signup.component';
import { ServicesComponent } from './shared/services/services.component';
import { ToastrModule } from 'ngx-toastr';
import { ProviderPageComponent } from './pages/provider-page/provider-page.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SchedulePopupComponent } from './pages/schedule-popup/schedule-popup.component';
import { DomainInterceptor } from './interceptor/domain.interceptor';
import { ServiceInfoComponent } from './pages/service-info/service-info.component';


const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain// it is recommended to set your domain, for cookies to work properly
  },
  palette: {
    popup: {
      background: '#fff'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messagelink}}{{compliance}}'
  },
  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank" rel="noopener">{{cookiePolicyLink}}</a>, 
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank" rel="noopener">{{privacyPolicyLink}}</a> and our 
      <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank" rel="noopener">{{tosLink}}</a>
    </span>
    `,
  },
  content:{
    message: 'This website uses cookies to ensure you get the best experience on our website. ',
    
    cookiePolicyLink: 'Cookie Policy',
    cookiePolicyHref: 'https://scheduleitnow.co.uk/privacypolicy',

    privacyPolicyLink: 'Privacy Policy',
    privacyPolicyHref: 'https://scheduleitnow.co.uk/privacypolicy',

    tosLink: 'Terms of Service',
    tosHref: 'https://scheduleitnow.co.uk/termsandconditions',
  }
};
@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    HeaderComponent,
    RunningOffersComponent,
    PopularCategoriesComponent,
    LimitedOffersComponent,
    TopBookingsComponent,
    StayHomeComponent,
    RegisterBusinessComponent,
    WhatCustomersSayComponent,
    RatingsAndReviewsComponent,
    FooterComponent,
    RegisterYourBusinessComponent,
    ProviderDetailsComponent,
    ProviderServiceListCardComponent,
    ProvidersListComponent,
    ReviewCardComponent,
    BreadcrumbComponent,
    CategoryCardComponent,
    ProvidersListCardComponent,
    TopServicesComponent,
    AboutServiceComponent,
    ServiceRatingsComponent,
    RegisterFreeTrailComponent,
    MyBookingsComponent,
    CartComponent,
    CancelBookingComponent,
    AvailabilityComponent,
    PaymentComponent,
    CancelledComponent,
    CartPopComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ThankYouComponent,
    ServiceDetailsComponent,
    ProfileComponent,
    ChangePasswordComponent,
    WishlistComponent,
    WalletComponent,
    PaymentSuccessComponent,
    RequestQuoteComponent,
    AboutusComponent,
    CareersComponent,
    ContactUsComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    LoaderComponent,
    PaymentFailComponent,
    MyTransactionsComponent,
    AddressPopupComponent,
    PlansComponent,
    SportsBasedThemComponent,
    BeautyBasedThemeComponent,
    BookingThemeComponent,
    OnlineTutorComponent,
    ViewTutorsComponent,
    TutorProfileComponent,
    TutorSignupComponent,
    StudentProfileComponent,
    StudentMessagesComponent,
    TutorLoginComponent,
    TutorLoginPageComponent,
    StudentBookingsComponent,
    BookLessonsComponent,
    MytutorSignupComponent,
    ParentsSignupComponent,
    StudentSignupComponent,
    TutorSignupFormComponent,
    TutorQualificationComponent,
    SchoolQualificationComponent,
    TutorSubjectComponent,
    TutorAddQualificationComponent,
    TutorSubjectLevelComponent,
    TutorGraducationCertificateComponent,
    MyTutorLoginPageComponent,
    MyTutorPersonalDetailsComponent,
    TutorEnoughDetailsComponent,
    RecivedApplicationComponent,
    TutorApplicationProcessComponent,
    TutorAppointmentComponent,

    ParentProfileComponent,
    StudentsListComponent,
    ContactTutorComponent,
    TutorHeaderComponent,
    StudentHomeComponent,
    ParentHomeComponent,
    TutorBookingsComponent,
    ProviderPageComponent,
    StudentCheckoutComponent,
    TutorAddSubjectComponent,
    TutorDashboardComponent,
    TutorFormSetpsComponent,
    PersonalDetailsComponent,
    StudentPersonalDetailsComponent,
    StudentSignupSubmitComponent,
    StudentChangePasswordComponent,
    ChooseSubjectComponent,
    ChooseLevelComponent,
    StudentAgeComponent,
    PrimaryStudentSignupComponent,
    ServicesComponent,
    SchedulePopupComponent,
    ServiceInfoComponent
  ],
  imports: [
    EditorModule,

    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgOtpInputModule,
    ReactiveFormsModule,
    NgxHideOnScrollModule,
    RatingModule,
    ChartModule,
    TagInputModule,
    NgbNavModule,
// DatepickerModule,
  
    
    // MultiSelectModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgWizardModule.forRoot(ngWizardConfig),
    AgmCoreModule.forRoot({
      apiKey: environment.googleAPIKey,
      libraries: ["places"],
      language: 'en'
    }),
    NgbModule,
    PasswordModule,
    PerfectScrollbarModule,
    SocialLoginModule,
    NgxStarRatingModule,
    FormsModule,
    InfiniteScrollModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    NgSelectModule,
    NgxDropzoneModule,
    SidebarModule,
    TabViewModule,
    ButtonModule,
    StepsModule,
    CalendarModule,
    DialogModule,
    BsDatepickerModule.forRoot(),
    ToggleButtonModule,
    IvyCarouselModule,
    ToastModule,
    ToastrModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [CustomEventEmitter, MessageService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.GoogleClientId),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.FacebookAppId)
          }
        ],
      } as SocialAuthServiceConfig,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DomainInterceptor,
      multi: true,
    },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptchaSiteKey,
      } as RecaptchaSettings,
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [AddressPopupComponent]
})
export class AppModule {
}