<div class="container">
    <div class="password-word row">
        <div class="col-md-4">
            <img src="assets/images/t-pic.png" alt="" class="t-img">
        </div>
        <div class="col-md-8">
           
            <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
<div>
    <label for="">Current Password</label>
    <input type="Password" class="form-control">
</div>
              <div>
                <label for="password">Password</label>
                <input type="password" formControlName="password" class="form-control">
              </div>
              <div>
                <label for="confirmPassword">Confirm Password</label>
                <input type="password" formControlName="confirmPassword"class="form-control">
                <div *ngIf="passwordForm.hasError('passwordsDoNotMatch')" class="error-w">
                  Passwords do not match.
                </div>
              </div>
              <button type="submit" [disabled]="passwordForm.invalid" class="btn-s">Submit</button>
            </form>
              
              
        </div>
    </div>
</div>


<form [formGroup]="passwordForm" (ngSubmit)="onSubmit()" class="d-none">
    <div>
      <label for="password">Password</label>
      <input type="password" formControlName="password">
      <div *ngIf="passwordForm.get('password')?.invalid && (passwordForm?.get('password')?.dirty || passwordForm.get('password')?.touched)">
        <div *ngIf="passwordForm.get('password')?.hasError('required')">Password is required.</div>

        <div *ngIf="passwordForm.get('password')?.hasError('minlength')">Password must be at least 8 characters long.</div>

        <!-- <div *ngIf="passwordForm.get('password').hasError('maxlength')">Password cannot be more than 20 characters long.</div> -->
        <div *ngIf="passwordForm.get('password')?.hasError('maxlength')">Password cannot be more than 20 characters long.</div>

        <div *ngIf="passwordForm.get('password')?.hasError('pattern')">Password must contain at least one uppercase letter, one lowercase letter, and one number.</div>

      </div>
      
      

   
      <label for="confirmPassword">Confirm Password</label>
      <input type="password" formControlName="confirmPassword">
      <div *ngIf="passwordForm.hasError('passwordsDoNotMatch')">
        Passwords do not match.
      </div>
    </div>
    <button type="submit" [disabled]="passwordForm.invalid">Submit</button>
  </form>






