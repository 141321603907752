<!--footer component-->


<div class="footer-sec">
  <div class="container">
    <div class="footer-inner-data">
      <div class="quickinks-section">
        <div class="col-12 col-md-12 col-lg-12 col-sm-12 row row-links">
          <div class="col-md-3 col-12 links-column">
            <div class="inner-data-links">
              <div class="heading">Address</div>

              <div class="link-go just-text">{{ ukAddress }}</div>
              <!-- <div class="link-go just-text">
                <p>8, Marlin House <br>
                Hornbeam Business Park <br>
                HG2 8PA </p> 
              </div> -->
              <div class="call-whats-app-info">
                <div class="whatsapp">
                  <i class="bi bi-whatsapp"></i>
                </div>
                <div class="call">
                  <i class="bi bi-telephone"></i>
                  <!-- <span class="call-number">+91 99382776323</span> -->
                  <span class="call-number">+44 7761 209908</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-6 links-column" id="mb-footer-padding">
            <div class="inner-data-links">
              <div class="heading">Company</div>
              <div class="link-go" [routerLink]="'/home'">Home</div>
              <div class="link-go" [routerLink]="'/services'" *ngIf="!isShowServices">Services</div>
              <div class="link-go" [routerLink]="'/about-us'">About Us</div>
              <!-- <div class="link-go" [routerLink]="'/careers'">Careers</div> -->
              <div class="link-go" [routerLink]="'/contact-us'">Contact Us</div>
            </div>
          </div>
          <!-- <div class="col-md-2 col-6 links-column" id="mb-footer-padding">
            <div class="inner-data-links">
              <div class="heading">Customers</div>
              <div class="link-go">Business</div>
              <div class="link-go">Suppliers</div>
            </div>
          </div> -->
          <div class="col-md-3 col-6 links-column">
            <div class="inner-data-links">
              <div class="heading">Further Information</div>
              <div class="link-go" [routerLink]="'/terms-conditions'">Terms & Conditions</div>
              <div class="link-go" [routerLink]="'/privacy-policy'">Privacy Policy</div>
            </div>
          </div>
          <div class="col-md-3 col-12 col-lg-3 links-column" id="mb-footer-padding">
            <div class="inner-data-links">
              <div class="heading">Subscribe Us!</div>
              <div class="link-go just-text">You will be getting updates and monthly blog releases straight to your
                email account!</div>
              <form [formGroup]="subscribeUsForm" (ngSubmit)="onSubmit($event)">
                <div class="your-email-text">
                  <input type="email" placeholder="Your email" class="input-form" formControlName="email">
                  <span class="error-text" *ngIf="emailControls.invalid  && formSubmited">Email required</span>
                  <button class="submit-btn" type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-footer-logo- row">
        <div class="logo-footer-data col-lg-7 col-md-6 col-sm-6 col-12">
          <div class="logo">
            <!-- <img src="assets/icons/login-signup/logo-serve.svg"> -->
            <img src="{{siteLogo}}" alt="site logo" />
          </div>
          <div class="social-icons">
            <a [href]="facebookLink" target="_blank">
              <img src="assets/icons/footer/fb.png">
            </a>
            <img src="assets/icons/footer/insta.png">
            <img src="assets/icons/footer/twitter.png">
          </div>
        </div>
        <div class="copy-rights col-lg-5 col-md-6 col-sm-6 col-12">
          <div class="copy-right-text">
            <span>&#169;</span> 2023 <span class="product-name"> {{productName}}</span> All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  </div>
</div>