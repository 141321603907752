import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit {
  isPersonalDetailsFormValid: boolean = false;
  email: any;
  name: any;
  constructor() { }

  ngOnInit(): void {
  }
  onSubmit(form: NgForm) {
    if (form.form.valid) {
      this.isPersonalDetailsFormValid = true;
    }
  }
}
